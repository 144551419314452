if (typeof window !== 'undefined') {

/*
   TimelineViewer.View - the timeline viewer view
*/
bcViewer.TimelineViewer.View = bcViewer.BaseViewer.View.extend({
    construct: function() {
        this._super.apply(this, arguments);

        this._minBlockWidth = 40;
        this._minSegmentWidth = this._minBlockWidth/2; // Also force at least two segments per block
        this._allowedBlockDurations = [1, 5, 10, 30, 60, 300, 600, 1800];

        this._tooltipIsInitialized = false;
    },

    /*
        Start listen to UI events
    */
    _registerToEvents: function() {
        $$('#bc_viewer .bcv_event_viewer_canvases').on('click',  $.proxy(this.onEventViewerCanvasesClicked, this));
        $$('#bc_viewer .bcv_timeline_canvas').on('mousemove', $.proxy(this.onViewerMouseMove, this));
    },

    /*
        Calculate the timeline and draw the ticks

        Terminology:
        Tick - one tick on the timeline
        Major tick - tick that shows the time above it
        Segment - space between two ticks
        Block - space between two major ticks, each block contains few segments
    */
    drawTimeline: function(metadata, width) {
        $$('#bc_viewer .bcv_timeline_header').text('(' + this._utils.formatTimeDuration(metadata.Duration, bcGlobals.locale.timeFormat) + ')');

        // Start time and duration in seconds
        var startTime = Math.floor(metadata.StartTime/1000);
        var duration = metadata.Duration/1000;

        var onePixelDuration = duration / width;
        var blockDuration = this._selectBlockDuration(duration, onePixelDuration);
        var blockWidth    = blockDuration/onePixelDuration;
        var numOfSegmentsPerBlock = Math.floor(blockWidth/this._minSegmentWidth);
        var segmentWidth    = blockWidth / numOfSegmentsPerBlock;
        var segmentDuration = segmentWidth * onePixelDuration;

        // Pick the time for the first major tick
        var idealFirstMajorTickTime = startTime + this._minSegmentWidth*onePixelDuration;
        var firstMajorTickTime = idealFirstMajorTickTime + blockDuration - ((idealFirstMajorTickTime + blockDuration) % blockDuration);
        var firstMajorTickLocation  = (firstMajorTickTime - startTime) / onePixelDuration;

        var startDrawingAt = firstMajorTickLocation - blockWidth;

        this._canvas.drawTimeline({
            'startTime': startTime,
            'onePixelDuration': onePixelDuration,
            'startDrawingAt': startDrawingAt,
            'segmentWidth': segmentWidth,
            'numOfSegmentsPerBlock': numOfSegmentsPerBlock
        });

        this.drawUserMarks();
    },

    drawUserMarks: function() {
      var userMarks = $.extend(true, [], this._viewer._state.userMarks);
      for (var i=0; i<userMarks.length; i++) {
        userMarks[i].x = this._viewer.convertTimeToPixels(userMarks[i].x);
      }
      this._canvas.drawUserMarks(userMarks);
    },

    /*
        Select the best block duration according to the record duration and the _allowedBlockDurations
    */
    _selectBlockDuration: function(duration, onePixelDuration) {
        var selectedBlockDuration;
        var idealBlockDuration = onePixelDuration * this._minBlockWidth;
        var allowedBlockDurationsItems = this._allowedBlockDurations.length - 1;
        // If idealBlockDuration is bigger than all the allowed block durations
        // just return the closest bigger round hour (3600 = 1 hour)
        if (idealBlockDuration > this._allowedBlockDurations[this._allowedBlockDurations.length-1]) {
            selectedBlockDuration = Math.ceil(idealBlockDuration / 3600) * 3600;
        } else {
            // Pick the first allowed duration the is bigger than the idealBlockDuration
            for (i=0; i<this._allowedBlockDurations.length; i++) {
                if (idealBlockDuration < this._allowedBlockDurations[i]) {
                    selectedBlockDuration = this._allowedBlockDurations[i];
                    break;
                }
            }
        }
        return selectedBlockDuration;
    },

    /*
        Move the current time indicator according to the time position
    */
    setCurrentTime: function(timePosition) {
        var x = this._viewer.convertTimeToPixels(timePosition);
        $$('#bc_viewer .bcv_time_indicator').css('left', x);
        if (!this._tooltipIsInitialized) {
            this._canvas.initTooltip({
                content: this._viewer.getTimeFromStartString(timePosition),
                track: true
            });
            this._tooltipIsInitialized = true;
        }
    },

    /*
        Handle canvas clicks
    */
    onEventViewerCanvasesClicked: function(event) {
        var xOffset = event.pageX - $$('#bc_viewer .bcv_event_viewer_canvases').offset().left;
        var timePosition = this._viewer.convertPixelsToTime(xOffset);
        this._viewer.onStateChangeRequest({'timePosition': timePosition});
    },

    /*
        Return array of elements selectors to hide
    */
    getElementsToHide: function() {
        return ['.bcv_timeline_header', '.bcv_timeline_canvas'];
    },

    /*
        Handle drag move
    */
    onDragMove: function(timePosition) {
        this.setCurrentTime(timePosition);
    },

    /*
        Handle mouse movement on viewer:
        Change the tooltip to the time position we're jumping to
    */
    onViewerMouseMove: function(event) {
        var xOffset = event.pageX - $$('#bc_viewer .bcv_event_viewer_canvases').offset().left;
        var timePosition = this._viewer.convertPixelsToTime(xOffset);

        var timeStr = this._viewer.getTimeFromStartString(timePosition);
        this._canvas.changeTooltip(timeStr);
    },

    /*
        Disable the viewer
    */
    disableViewer: function() {
        $$('.bcv_event_viewer_canvases').addClass('bcv_event_viewer_canvases_disabled');

        this._super.apply(this, arguments);
    },

    /*
        Enable the viewer
    */
    enableViewer: function() {
        $$('.bcv_event_viewer_canvases').removeClass('bcv_event_viewer_canvases_disabled');

        this._super.apply(this, arguments);
    }
});

}

if (typeof window !== 'undefined') {

/*
    BaseWavesViewer - base class for all the wave viewers (ECG, context, etc)
*/
bcViewer.BaseWavesViewer = bcViewer.BaseViewer.extend({
    construct: function() {
        this._super.apply(this, arguments);
        
        this._state.zoomX = this.config.DEFAULT_ZOOM.x;
        this._state.zoomY = this.config.DEFAULT_ZOOM.y;
    },

    /*
        Initiate the model and the view

        canvasSelector - selector for the view's canvas
    */
    _init: function(modelClass, viewClass, canvasSelector, canvasClass) {       
        var width  = this._state.width;
        var height = this._state.height;
       
        this._model = new modelClass(this._globals, this._state);
        this._view  = new viewClass(this._globals, canvasSelector, canvasClass, width, height);
    },
    
    /*
        Apply state changes
    */
    applyStateChanges: function(stateChanges, isInitCycle) {
       var callback =  $.proxy(function() {
                this._view.onStateChange(stateChanges);
                if (!isInitCycle) {
                    this._view.onGotECGData.apply(this._view, arguments);
                } else {
                    // Crazy mobile bug, without this the context viewer won't draw at all 
                    this._view._canvas.clear();
                    this._view.onInitCycle && this._view.onInitCycle.apply(this._view, arguments);
                }
            }, this);
        
       this._model.onStateChange(stateChanges, callback, isInitCycle);
    },

    /*
        Return the model
    */
    getModel: function() {
        return this._model;
    },
    
    /*
        Handle the metadata
    */
    onGotMetadata: function(metadata) {
        this._super.apply(this, arguments);
        this._model.updateDuration(this._metadata.Duration);
    }
});

}
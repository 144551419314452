if (typeof window !== 'undefined') {

/*
   HRViewer.View - the heart-rate viewer view
*/
bcViewer.HRViewer.View = bcViewer.BaseViewer.View.extend({
    onGotHRData: function(data) {
        if (data.graph) {
            this._viewer.enableViewer();
            this._canvas.draw(data.graph);
        }
        !this._viewer.isViewerReady && this._viewer._onLoadingEnd();
    },

    /*
        Return array of elements selectors to hide
    */
    getElementsToHide: function() {
        return ['.bcv_hr_header', '.bcv_hr_canvas'];
    },

    /*
        Disable the viewer
    */
    disableViewer: function() {
        $$('.bcv_hr_header').addClass('bcv_disabled_header');
        
        this._super.apply(this, arguments);
    },
    
    /*
        Enable the viewer
    */
    enableViewer: function() {
        $$('.bcv_hr_header').removeClass('bcv_disabled_header');
        
        this._super.apply(this, arguments);
    }
});

}
import React, { useState } from 'react'
import { useQuery } from '@apollo/client'
import { GET_REFERRALS_QUEUES_CONFIG } from '../queries'
import Loader from '../Loader'
import { REFERRAL_TYPES_CONVERTER } from '../../../utils/utils'
import { useLocalization } from '../LocalizationProvider'
import AddToReferralsQueue from './AddToReferralsQueue'
import RemoveFromReferralsQueue from './RemoveFromReferralsQueue'

const AdminReferralsQueues = () => {
  const { locale } = useLocalization()
  const [selectedQueueIndex, setSelectedQueueIndex] = useState(-1)
  const [isAddToReferralsQueueMode, setAddToReferralsQueueMode] =
    useState(false)
  const [removeFromReferralsQueueData, setRemoveFromReferralsQueueData] =
    useState(null)

  const {
    loading: loading,
    error: error,
    data: data
  } = useQuery(GET_REFERRALS_QUEUES_CONFIG, {})

  return (
    <div className='mt-5'>
      {error && <div> error... </div>}

      {isAddToReferralsQueueMode && (
        <AddToReferralsQueue
          queuesData={data}
          onComplete={() => setAddToReferralsQueueMode(false)}
          onExit={() => setAddToReferralsQueueMode(false)}
        />
      )}

      {removeFromReferralsQueueData && (
        <RemoveFromReferralsQueue
          queueData={data.referralsQueues[selectedQueueIndex]}
          toRemoveData={removeFromReferralsQueueData}
          onComplete={() => setRemoveFromReferralsQueueData(null)}
          onExit={() => setRemoveFromReferralsQueueData(null)}
        />
      )}

      {loading ? (
        <Loader />
      ) : (
        <div className='level'>
          <div className='level-left'>
            <div className='level-item'>
              <div className='select'>
                <select
                  value={selectedQueueIndex}
                  onChange={(e) => setSelectedQueueIndex(e.target.value)}
                >
                  <option disabled='disabled' value='-1'>
                    Select queue
                  </option>
                  {data.referralsQueues.map(({ name }, index) => (
                    <option value={index} key={name}>
                      {name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className='level-right'>
            <div className='level-item'>
              <a
                className='button is-primary'
                href='#'
                onClick={() => {
                  setAddToReferralsQueueMode(true)
                }}
              >
                Add
              </a>
            </div>
          </div>
        </div>
      )}
      {selectedQueueIndex !== -1 && (
        <div className='mt-5'>
          <article className='panel'>
            <p className='panel-heading'>Admins</p>

            <div className='panel-block'>
              <table className='table is-fullwidth'>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Remove</th>
                  </tr>
                </thead>
                <tbody>
                  {data.referralsQueues[selectedQueueIndex].queue.admins.map(
                    (adminData) => (
                      <tr key={adminData.id + selectedQueueIndex}>
                        <td>{`${adminData.name} (${adminData.email})`}</td>
                        <td>
                          <a
                            className='button'
                            href='#'
                            onClick={() => {
                              setRemoveFromReferralsQueueData({
                                data: adminData,
                                isAdmin: true
                              })
                            }}
                          >
                            Remove
                          </a>
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </div>
          </article>

          <article className='panel'>
            <p className='panel-heading'>caregivers</p>

            <div className='panel-block'>
              <table className='table is-fullwidth'>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Referral type</th>
                    <th>Remove</th>
                  </tr>
                </thead>
                <tbody>
                  {data.referralsQueues[
                    selectedQueueIndex
                  ].queue.caregivers.map((caregiverData) => (
                    <tr
                      key={
                        caregiverData.id +
                        caregiverData.referralType +
                        selectedQueueIndex
                      }
                    >
                      <td>{`${caregiverData.name} (${caregiverData.email})`}</td>
                      <td>
                        {
                          locale.studies.reports[
                            REFERRAL_TYPES_CONVERTER[caregiverData.referralType]
                          ]
                        }
                      </td>
                      <td>
                        <a
                          className='button'
                          href='#'
                          onClick={() => {
                            setRemoveFromReferralsQueueData({
                              data: caregiverData,
                              isAdmin: false
                            })
                          }}
                        >
                          Remove
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </article>
        </div>
      )}
    </div>
  )
}

export default AdminReferralsQueues

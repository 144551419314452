import React, { useContext } from 'react'
import { navigate } from '@reach/router' // eslint-disable-line import/no-unresolved
import dayjs from 'dayjs'
import AuthContext from './AuthContext'
import {
  durationToHuman,
  WEIGHT_CONVERTER,
  HEIGHT_CONVERTER,
  ANIMAL_TYPE_NEW_TO_LEGACY_STRING,
  getAgeUnitsLocalizationKey,
  adjustTimeToTimezone,
  localizePatientName,
  DATE_FORMAT_CONVERTER
} from '../../utils/utils'
import { useLocalization } from './LocalizationProvider'

const PatientDetails = ({
  patient,
  anonymousPatientData,
  studies,
  currentStudyId,
  dateFormat,
  caregiverUserType
}) => {
  const { locale, interpolate } = useLocalization()
  const currentUser = useContext(AuthContext)
  return (
    <div className='columns'>
      <div className='column'>
        <table className='table is-bordered is-fullwidth'>
          <tbody>
            <tr>
              <td className='has-background-light'>
                {locale.study_header.patient_name}
              </td>
              <td>{localizePatientName(patient.name, locale)}</td>
            </tr>
            <tr>
              <td className='has-background-light'>
                {locale.study_header.patient_id}
              </td>
              <td>{patient.externalId}</td>
            </tr>
            <tr>
              <td className='has-background-light'>
                {locale.study_header.gender}
              </td>
              <td>{locale[anonymousPatientData.gender.toLowerCase()]}</td>
            </tr>
            {caregiverUserType === 'Physician' && (
              <tr>
                <td className='has-background-light'>
                  {locale.study_header.height}
                </td>
                <td className='paragraph'>
                  {typeof anonymousPatientData.height === 'number' &&
                  anonymousPatientData.height > 0
                    ? interpolate(locale.unit_tmpl, {
                        value: anonymousPatientData.height,
                        type: locale[
                          HEIGHT_CONVERTER[anonymousPatientData.unitSystem]
                        ]
                      })
                    : ''}
                </td>
              </tr>
            )}
            <tr>
              <td className='has-background-light'>
                {locale.study_header.weight}
              </td>
              <td className='paragraph'>
                {typeof anonymousPatientData.weight === 'number' &&
                anonymousPatientData.weight > 0
                  ? interpolate(locale.unit_tmpl, {
                      value: anonymousPatientData.weight,
                      type: locale[
                        WEIGHT_CONVERTER[anonymousPatientData.unitSystem]
                      ]
                    })
                  : ''}
              </td>
            </tr>
            {caregiverUserType === 'Veterinarian' && (
              <>
                <tr>
                  <td className='has-background-light'>
                    {locale.study_header.animal_type}
                  </td>
                  <td className='paragraph'>
                    {anonymousPatientData.animal &&
                      locale.animals[
                        ANIMAL_TYPE_NEW_TO_LEGACY_STRING[
                          anonymousPatientData.animal.type
                        ]
                      ]}
                  </td>
                </tr>
                <tr>
                  <td className='has-background-light'>
                    {locale.study_header.animal_breed}
                  </td>
                  <td className='paragraph'>
                    {anonymousPatientData.animal &&
                      anonymousPatientData.animal.breed}
                  </td>
                </tr>
              </>
            )}
            <tr>
              <td className='has-background-light'>
                {caregiverUserType === 'Veterinarian'
                  ? locale.study_header.history
                  : locale.study_header.anamnesis}
              </td>
              <td className='paragraph'>{anonymousPatientData.anamnesis}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className='column'>
        <table className='table is-bordered is-fullwidth'>
          <tbody>
            <tr>
              <td
                className='has-background-light'
                style={{ verticalAlign: 'middle' }}
              >
                {locale.studies_history}
              </td>
              <td>
                {' '}
                {studies.edges.length > 0 && (
                  <div className='field'>
                    <div className='control'>
                      <div className='select'>
                        <select
                          value={currentStudyId}
                          onChange={(e) => {
                            if (e.target.value === currentStudyId) return
                            navigate(`/study/${e.target.value}`)
                          }}
                        >
                          {studies.edges.map(({ node: study }) => (
                            <option value={study.studyId} key={study.studyId}>
                              {`${adjustTimeToTimezone(
                                study.studyCreatedAt,
                                study.timezoneOffset
                              ).format(dateFormat)} (${durationToHuman(
                                study.duration
                              )})`}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                )}
              </td>
            </tr>
            <tr>
              <td className='has-background-light'>
                {locale.study_header.age}
              </td>
              <td>
                {Number.isInteger(anonymousPatientData.age) &&
                anonymousPatientData.age > 0
                  ? caregiverUserType === 'Veterinarian'
                    ? interpolate(locale.unit_tmpl, {
                        value: anonymousPatientData.age,
                        type: locale.study_header[
                          getAgeUnitsLocalizationKey(
                            anonymousPatientData.age,
                            anonymousPatientData.ageUnits
                              ? anonymousPatientData.ageUnits.toLowerCase()
                              : 'years'
                          )
                        ]
                      })
                    : interpolate(locale.age_with_birthdate, {
                        age: interpolate(locale.unit_tmpl, {
                          value: anonymousPatientData.age,
                          type: locale.study_header[
                            getAgeUnitsLocalizationKey(
                              anonymousPatientData.age,
                              anonymousPatientData.ageUnits
                                ? anonymousPatientData.ageUnits.toLowerCase()
                                : 'years'
                            )
                          ]
                        }),
                        birthdate: dayjs(patient.birthdate).format(
                          `${
                            DATE_FORMAT_CONVERTER[
                              currentUser.settings.dateFormat
                            ]
                          }`
                        )
                      })
                  : ''}
              </td>
            </tr>
            <tr>
              <td className='has-background-light'>
                {caregiverUserType === 'Veterinarian'
                  ? locale.study_header.icd_code_veterinarian
                  : locale.study_header.icd_code}
              </td>
              <td className='paragraph'>{anonymousPatientData.icdCode}</td>
            </tr>
            <tr>
              <td className='has-background-light'>
                {locale.study_header.email}
              </td>
              <td className='paragraph'>{patient.email}</td>
            </tr>
            <tr>
              <td className='has-background-light'>
                {locale.study_header.phone}
              </td>
              <td className='paragraph'>{patient.phone}</td>
            </tr>
            {caregiverUserType === 'Veterinarian' && (
              <>
                <tr>
                  <td className='has-background-light'>
                    {locale.study_header.animal_owner}
                  </td>
                  <td className='paragraph'>
                    {patient.animal && patient.animal.owner}
                  </td>
                </tr>
                <tr>
                  <td className='has-background-light'>
                    {locale.study_header.animal_castrated}
                  </td>
                  <td className='paragraph'>
                    {anonymousPatientData.animal &&
                    typeof anonymousPatientData.animal.castrated == 'boolean'
                      ? anonymousPatientData.animal.castrated
                        ? locale.study_header.animal_castrated_yes
                        : locale.study_header.animal_castrated_no
                      : ''}
                  </td>
                </tr>
              </>
            )}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default PatientDetails

if (typeof window !== 'undefined') {

bcViewer.ECGUnpacking = {
    _DATA_PACK_FORMAT_MIT212: 2,
    _DATA_PACK_FORMAT_DDT12: 3,
    _DATA_PACK_FORMAT_DDT16: 6,

    _MASK_BITS: [0, 0x0001, 0x0003, 0x0007, 0x000F,
                       0x001F, 0x003F, 0x007F, 0x00FF,
                       0x01FF, 0x03FF, 0x07FF, 0x0FFF,
                       0x1FFF, 0x3FFF, 0x7FFF, 0xFFFF],

    _HUFFMAN_MASK0: [0x0001, 0x0002, 0x0004, 0x0008,
                           0x0010, 0x0020, 0x0040],

    _HUFFMAN_MASK1: [0xFFFE, 0xFFFC, 0xFFF8, 0xFFF0,
                           0xFFE0, 0xFFC0, 0xFF80],

    unpackBlock: function(header, data) {
        var nSamples = header.samplesInBlock;
        var nChannels = header.numberOfChannels;
        var unPackedData;
        switch (header.dataFormat) {
            case this._DATA_PACK_FORMAT_DDT12:
                unPackedData = this._unPackDDT(data, nSamples, nChannels, 12);					
                break;
            case this._DATA_PACK_FORMAT_DDT16:
                unPackedData = this._unPackDDT(data, nSamples, nChannels, 16);
                break;
            case this._DATA_PACK_FORMAT_MIT212:
                unPackedData = this._unPackMIT(data, nSamples, nChannels);
                break;
            default:
                console.log("ERROR: unknown packing method", header.dataFormat);
        }
        return unPackedData && (new Int16Array(unPackedData));
    },

    _readBits: function(packData, nBits) {
        var result;
        if (nBits <= packData.bitsLeft) {
            result = this._getUint16(packData.bitsBuf) & this._MASK_BITS[nBits];
            packData.bitsBuf >>= nBits;
            packData.bitsLeft -= nBits;
        } else {
            result = this._bitsBufferFill(packData, nBits);
        }
        return result;
    },

    _getEasyHuffman: function(packData) {
        var cc;
        if (this._readBits(packData, 1) === 0) return 0;
        for (var i=0; i<this._HUFFMAN_MASK0.length; i++) {
            if (this._readBits(packData, 1) === 0) {
                cc = this._getInt16(this._readBits(packData, i+1));	
                if ((cc & this._HUFFMAN_MASK0[i]) === 0) {
                    cc = (cc | this._HUFFMAN_MASK1[i]) + 1;
                }
                return cc;
            }
        }
        if (this._readBits(packData, 1) === 0) {
            cc = this._getInt16(this._readBits(packData, 16));
            if (cc < 0) { 
                cc++;
            }
            return cc;
        }

        return 0;
    },
    
    _unPackDDT: function(data, nSamples, nChannels, adcBits) {
        var result = [];
        var xm = new Array(3);
        xm[0] = new Int16Array(nChannels);
        xm[1] = new Int16Array(nChannels);
        xm[2] = new Int16Array(nChannels);

        var x0, i; 
        var packData = {
            bitsBuf:0, 
            bitsLeft: 0,
            dataBlock: data,
            dataView: new DataView(data),
            dataBlockInd: 0
        };
        var index = 0;
        while (index < nSamples) {
            switch (index) {
                case 0:
                    for (i=0; i<nChannels; i++) {
                        x0 = this._readBits(packData, adcBits);
                        if ((adcBits === 12) && (x0 & 0x800)) {
                            x0 |= 0xf800;
                        }			
                        xm[0][i] = x0;
                    }	
                    break;
                case 1:
                    for (i=0; i<nChannels; i++) {
                        xm[0][i] = this._getEasyHuffman(packData) + xm[1][i];
                    }
                    break;
                default:
                    for (i=0; i<nChannels; i++) {
                        xm[0][i] = (this._getEasyHuffman(packData) + (xm[1][i] << 1) - xm[2][i]);
                    }
                    break;
            }

            xm[2] = xm[1].slice();
            xm[1] = xm[0].slice();

            for (i=0; i<nChannels; i++) {
                result.push(xm[0][i]);
            }
            index++;
        }

        return result;
    },
    
    _unPackMIT: function(data, nSamples, nChannels) {
        var j, index=0, val;
        var result = [];
        var packData = {
            bitsBuf:0,
            bitsLeft: 0,
            dataBlock: data,
            dataView: new DataView(data),
            dataBlockInd: 0
        };
        while (index < nSamples) {
            for (j=0; j<nChannels; j++) {
                val = this._getInt16(this._readBits(packData, 12)); 	
                if (val & 0x800) val |= 0xf800;
                result.push(val);
            }
            index++;
        }
        return result;
    },

    _unPackRaw16: function(data, nSamples, nChannels) {
        var index = 0;
        var dv = new DataView(data);
        var result = [];
        while (index < nSamples) {
            for (var i = 0; i < nChannels; i++) {
               result.push(dv.getInt16(index, true));
               index += 2;
            }
        }
        return result;
    },

    _getUint16: function(num) { 
        return new Uint16Array([num])[0];
    },

    _getInt16: function(num) {
        return new Int16Array([num])[0];
    },
    
    _bitsBufferFill: function(packData, nBits) {
        var result = this._getUint16(packData.bitsBuf);
        var sBits = this._getUint16(packData.bitsLeft);
        nBits -= sBits;

        packData.bitsBuf = packData.dataView.getUint16(packData.dataBlockInd, true); 
        packData.dataBlockInd +=2;
        packData.bitsLeft = 16;

        result = result | this._getUint16((packData.bitsBuf & this._MASK_BITS[nBits]) << sBits);

        packData.bitsBuf = this._getUint16(packData.bitsBuf >> nBits);
        packData.bitsLeft -= nBits;
        return result;
    }
};

}
import React from 'react'
import dayjs from 'dayjs'
import { DATE_FORMAT_CONVERTER } from '../../utils/utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUpRightFromSquare } from '@fortawesome/free-solid-svg-icons'

const StudyAudits = ({
  loading,
  data,
  studyAuditsFetchMore,
  studyId,
  currentUser
}) => {
  return (
    <div>
      {loading && 'Loading...'}
      {data && (
        <div>
          {data.study.auditStream.edges.map(({ node: audit }) => (
            <article className='media' key={audit.id}>
              <div className='media-content'>
                <div className='content'>
                  <p>
                    <span className='mr-2'>Audit time:</span>
                    <span className='is-size-6'>
                      {dayjs(audit.persistedAt).format(
                        `${
                          DATE_FORMAT_CONVERTER[currentUser.settings.dateFormat]
                        } HH:mm:ss`
                      )}
                    </span>
                    <span className='ml-2 mr-2'>|</span>
                    <span className='mr-2'>Event time:</span>
                    <span className='is-size-6'>
                      {dayjs(audit.createdAt).format(
                        `${
                          DATE_FORMAT_CONVERTER[currentUser.settings.dateFormat]
                        } HH:mm:ss`
                      )}
                    </span>
                    <span className='ml-2 mr-2'>-</span>
                    <span className='has-text-weight-bold'>{audit.event}</span>

                    <a
                      href={audit.url}
                      className='ml-4'
                      target='_blank'
                      rel='noreferrer'
                      title='Open in cloudwatch'
                    >
                      <FontAwesomeIcon icon={faUpRightFromSquare} />
                    </a>
                    <span className='is-block is-size-6'>{`${audit.name} <${audit.email}> - ${audit.creator}`}</span>
                  </p>
                  <div className='accordion'>
                    <input
                      type='checkbox'
                      id={`accordion_${audit.id}`}
                      hidden
                    />
                    <label
                      className='accordion-header'
                      htmlFor={`accordion_${audit.id}`}
                    >
                      <a className='button is-text has-text-grey'>details</a>
                    </label>
                    <div className='accordion-body'>
                      <code style={{ whiteSpace: 'pre-wrap', color: 'black' }}>
                        {JSON.stringify(JSON.parse(audit.data), null, '\t')}
                      </code>
                    </div>
                  </div>
                </div>
              </div>
            </article>
          ))}
          <button
            className='button is-primary mt-5'
            disabled={!data.study.auditStream.pageInfo.hasNextPage}
            onClick={() => {
              if (data.study.auditStream.pageInfo.hasNextPage) {
                studyAuditsFetchMore({
                  variables: {
                    id: studyId,
                    after: data.study.auditStream.pageInfo.endCursor
                  }
                })
              }
            }}
          >
            Load more
          </button>
        </div>
      )}
    </div>
  )
}

export default StudyAudits

import React, { useState } from 'react'
import { GET_MOBILE_APP_LOGS, GET_MOBILE_APP_LOG_LINK } from '../queries'
import { useQuery } from '@apollo/client'
import useMemoryPagination, {
  prev,
  next,
  init
} from '../../hooks/useMemoryPagination'
import { useLazyQuery } from '@apollo/client'
import dayjs from 'dayjs'
import clsx from 'clsx'

const FIRST = 5

const MobileAppLogs = () => {
  const [logType, setLogType] = useState('report')
  const [paginationState, dispatch] = useMemoryPagination()

  const { loading, error, data } = useQuery(GET_MOBILE_APP_LOGS, {
    variables: {
      type: logType,
      first: FIRST,
      after: paginationState
    }
  })

  const [requestedLogKey, setRequestedLogKey] = useState(null)

  const [
    getLogLink,
    { loading: getLogLinkLoading, error: getLogLinkError, data: getLogLinkData }
  ] = useLazyQuery(GET_MOBILE_APP_LOG_LINK)

  const descriptionStyle = {
    width: '350px',
    height: '100px',
    overflowY: 'auto',
    wordBreak: 'break-word'
  }

  return (
    <div className='mt-5'>
      {loading && <p>Loading...</p>}
      {error && <div> error... </div>}
      {getLogLinkError && <div> getLogLinkError... </div>}

      {data && (
        <div>
          <div className='level'>
            <div className='level-left'>
              <div className='level-item'>
                <a
                  className={clsx(
                    'button',
                    'mb-5',
                    { 'is-success': getLogLinkData },
                    { 'is-loading': getLogLinkLoading }
                  )}
                  href={
                    getLogLinkData && getLogLinkData.admin.mobileAppLogsLink
                  }
                  target='_blank'
                  rel='noopener noreferrer'
                  disabled={!getLogLinkData}
                >
                  {getLogLinkData
                    ? `Download ${requestedLogKey}`
                    : 'No log is requested'}
                </a>
              </div>
            </div>
            <div className='level-right'>
              <div className='level-item'>
                <div className='field'>
                  <div className='control'>
                    <label className='radio'>
                      <input
                        type='radio'
                        name='logType'
                        onChange={() => {
                          dispatch(init())
                          setLogType('report')
                        }}
                        checked={logType === 'report'}
                      />{' '}
                      Report
                    </label>
                    <label className='radio'>
                      <input
                        type='radio'
                        name='logType'
                        onChange={() => {
                          dispatch(init())
                          setLogType('crash')
                        }}
                        checked={logType === 'crash'}
                      />{' '}
                      Crash
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <table className='table is-fullwidth is-hoverable'>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Email</th>
                  <th>Name</th>
                  <th>App version</th>
                  <th>Device</th>
                  <th>In log time</th>
                  <th>Description</th>
                  <th>Reported time</th>
                  <th>Key</th>
                </tr>
              </thead>
              <tbody>
                {data.admin.mobileAppLogs.edges.map(({ node: log }) => (
                  <tr key={log.timestamp}>
                    <td>
                      {dayjs(new Date(log.timestamp.split('_')[0])).format(
                        'YYYY-MM-DD HH:mm:ss'
                      )}
                    </td>
                    <td>{log.email}</td>
                    <td>{log.name}</td>
                    <td>{log.appVersion}</td>
                    <td>{log.device}</td>
                    <td>{log.issueInLogTime}</td>
                    <td>
                      <div style={descriptionStyle}>{log.description}</div>
                    </td>
                    <td>{log.issueTime}</td>
                    <td>
                      <a
                        className='button'
                        href='#'
                        onClick={() => {
                          setRequestedLogKey(log.key)
                          getLogLink({
                            variables: {
                              key: log.key
                            }
                          })
                        }}
                      >
                        Request link
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <nav
              className='pagination'
              role='navigation'
              aria-label='pagination'
            >
              <a
                className='pagination-previous'
                disabled={!data.admin.mobileAppLogs.pageInfo.hasPreviousPage}
                onClick={() => {
                  if (data.admin.mobileAppLogs.pageInfo.hasPreviousPage) {
                    dispatch(prev())
                  }
                }}
              >
                Previous
              </a>
              <a
                className='pagination-next'
                disabled={!data.admin.mobileAppLogs.pageInfo.hasNextPage}
                onClick={() => {
                  if (data.admin.mobileAppLogs.pageInfo.hasNextPage) {
                    dispatch(next(data.admin.mobileAppLogs.pageInfo.endCursor))
                  }
                }}
              >
                Next
              </a>
            </nav>
          </div>
        </div>
      )}
    </div>
  )
}

export default MobileAppLogs

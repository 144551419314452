import React, { useState } from 'react'
import clsx from 'clsx'
import UserAudit from './UserAudit'
import UserActivityAudit from './UserActivityAudit'
import ClinicAudit from './ClinicAudit'
import UserLog from './UserLog'
import UserActivityLog from './UserActivityLog'
import ClinicLog from './ClinicLog'

const AdminDebugData = ({ userId, clinic, rawData }) => {
  const [tab, setTab] = useState(null)

  return (
    <div>
      <div className='tabs'>
        <ul>
          <li
            className={clsx({
              'is-active': tab === 'audit'
            })}
          >
            <a onClick={() => setTab('audit')}>Audit</a>
          </li>

          <li
            className={clsx({
              'is-active': tab === 'activityAudit'
            })}
          >
            <a onClick={() => setTab('activityAudit')}>Activity audit</a>
          </li>

          <li
            className={clsx({
              'is-active': tab === 'clinicAudit'
            })}
          >
            <a onClick={() => setTab('clinicAudit')}>Clinic audit</a>
          </li>

          <li
            className={clsx({
              'is-active': tab === 'log'
            })}
          >
            <a onClick={() => setTab('log')}>User log</a>
          </li>

          <li
            className={clsx({
              'is-active': tab === 'activityLog'
            })}
          >
            <a onClick={() => setTab('activityLog')}>User activity log</a>
          </li>

          <li
            className={clsx({
              'is-active': tab === 'clinicLog'
            })}
          >
            <a onClick={() => setTab('clinicLog')}>Clinic log</a>
          </li>

          <li
            className={clsx({
              'is-active': tab === 'rawdata'
            })}
          >
            <a onClick={() => setTab('rawdata')}>Raw data</a>
          </li>
        </ul>
      </div>
      <div className='mt-5'>
        {tab == 'audit' && (
          <div>
            <UserAudit userId={userId}></UserAudit>
          </div>
        )}

        {tab == 'activityAudit' && (
          <div>
            <UserActivityAudit userId={userId}></UserActivityAudit>
          </div>
        )}

        {tab == 'clinicAudit' && (
          <div>
            <ClinicAudit clinic={clinic}></ClinicAudit>
          </div>
        )}

        {tab == 'log' && (
          <div>
            <UserLog userId={userId}></UserLog>
          </div>
        )}

        {tab == 'activityLog' && (
          <div>
            <UserActivityLog userId={userId}></UserActivityLog>
          </div>
        )}

        {tab == 'clinicLog' && (
          <div>
            <ClinicLog clinic={clinic}></ClinicLog>
          </div>
        )}

        {tab == 'rawdata' && (
          <div style={{ whiteSpace: 'pre-wrap' }}>{rawData}</div>
        )}
      </div>
    </div>
  )
}

export default AdminDebugData

import React from 'react'

const SomethingWentWrong = () => {
  return (
    <div
      className='is-flex is-justify-content-center is-align-items-center'
      style={{ height: '100%' }}
    >
      <div className='has-text-centered'>
        <div className='is-size-2'>Something went wrong</div>
        <div className='is-size-4'>
          Try refreshing the page, or try again later
        </div>
      </div>
    </div>
  )
}

export default SomethingWentWrong

if (typeof window !== 'undefined') {

/*
    ContextViewer - the ecg context viewer controller
*/
bcViewer.ContextViewer = bcViewer.BaseWavesViewer.extend({
    construct: function() {
        this._super.apply(this, arguments);

        // Context viewer cares about selectedChannel
        this._supportedStateChanges.push('selectedChannel', 'baselineCorrection', 'smooth', 'doesMainViewerGotScrollbars', 'expand3to7', 'expand3to12', 'expand8to12', 'expand2to6', 'magnify', 'userMarks');
        this._state.selectedChannel = this.config.DEFAULT_SELECTED_CHANNEL;

        this._init(bcViewer.BaseWavesViewer.Model, bcViewer.ContextViewer.View, '#bc_viewer .bcv_context_canvas');

        // Main and context viewers width ratio
        this._widthRatio = 1;
    },

    /*
        Handle state changes
    */
    onStateChange: function(stateChanges, isInitCycle) {
        // Do nothing if the viewer is hidden
        if (this.config.HIDDEN) {
            isInitCycle && this._onLoadingEnd();
            return;
        }

        // ContextViewer doesn't support zoomX changes but we need
        // to update the highlight window width accordingly
        if ((typeof stateChanges.zoomX !== 'undefined') && (!isInitCycle)) {
              var state = this.getState();

              // zoomX is factorized by the old width ratio, i.e. zoomX*widthRatio.
              // the view need to calculate zoomX/mainZoomX where zoomX is the original ZoomX (5)
              // zoomX/widthRatio gives us the original ZoomX
              this._view.updateZoomXRatio(stateChanges.zoomX, state.zoomX / this._widthRatio);
              this._view.updateHighlightWidth(state);
        }

        if (typeof stateChanges.expand2to6 !== 'undefined') {
            this._handleChannelsExpanding(stateChanges.expand2to6, 6);
        }

        if (typeof stateChanges.expand3to7 !== 'undefined') {
            this._handleChannelsExpanding(stateChanges.expand3to7, 7);
        }

        if (typeof stateChanges.expand3to12 !== 'undefined') {
            this._handleChannelsExpanding(stateChanges.expand3to12, 12);
        }

        if (typeof stateChanges.expand8to12 !== 'undefined') {
            this._handleChannelsExpanding(stateChanges.expand8to12, 12);
        }
        this._super(stateChanges, isInitCycle);
    },

    /*
        Rebuild channles dropdown according to expand state
    */
    _handleChannelsExpanding: function(expand, expandedNumberOfChannels) {
        var metadata = this._metadata;

        if (metadata) {
            if (expand) {
                this._metadata.NumberOfChannels = expandedNumberOfChannels;
            }
            if ((metadata.NumberOfChannels === expandedNumberOfChannels) && !expand) {
                this._metadata.NumberOfChannels = 3;
            }
            this._view.buildChannelsDropdown(this._metadata.NumberOfChannels, this._state.selectedChannel);
        }
    },

    /*
        apply the state changes
    */
    applyStateChanges: function(stateChanges, isInitCycle) {
        if (typeof stateChanges.width !== 'undefined') {
            var mainZoomX = this._bcViewer.getViewerState('main').zoomX;

            // zoomX is in the state factorized by the old width ratio, i.e. zoomX*widthRatio.
            // the view need to calculate zoomX/mainZoomX where zoomX is the original ZoomX (5)
            // zoomX/widthRatio gives us the original ZoomX
            var zoomX = this.getState().zoomX / this._widthRatio;

            this._view.updateZoomXRatio(mainZoomX, zoomX)

            // get width from this._state and not from this._getState() because the model didn't update the state yet
            var newWidthRatio  = this.getContextAndMainWidthRatio(this._state.width);

            this._model.updateState({'zoomX': newWidthRatio * zoomX});

            this._widthRatio = newWidthRatio;
        }

        this._super(stateChanges, isInitCycle);
    },

    /*
        return context and main viewers width ratio
    */
    getContextAndMainWidthRatio: function(width){
        // Ugly - done because the state in the ECGViewerModel isn't updated untill the data returns
        // but we neeed the updated width before we got the data
        var mainWidth = this._bcViewer.getMainWidth();

        if  (mainWidth === 0) {
                return 1;
        }

        return width/mainWidth || 1;
    },

    /*
        Handle the metadata
    */
    onGotMetadata: function(metadata) {
        this._super.apply(this, arguments);
        this._view.buildChannelsDropdown(this._metadata.NumberOfChannels, this._state.selectedChannel);


        // the model checks if the record duration is too small to fit the viewer width
        // if so, it updates the zoomX and we need to let the view know about the new zoomX ratio
        // and to update the highlight width appropriately
        var isZoomXChanged = this._model.updateZoomXIfNeeded();
        if (isZoomXChanged) {
            var state = this.getState();
            var mainZoomX = this._bcViewer.getViewerState('main').zoomX;
            var zoomX = state.zoomX / this._widthRatio;
            this._view.updateZoomXRatio(mainZoomX, zoomX)
            this._view.updateHighlightWidth(state);
        }
    }
});

}

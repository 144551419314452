if (typeof window !== 'undefined') {

/*
    ViewerState - describe a viewer state (width, height, time, zoom, etc)
*/
bcViewer.ViewerState = function(state) {
    $.extend(this, state);
};

/*
    Clone to a new state object
*/
bcViewer.ViewerState.prototype.clone = function() {
    return new bcViewer.ViewerState(this);
};

/*
    Return a new state based on the current one + changes
*/
bcViewer.ViewerState.prototype.getNewState = function(stateChanges) {
    var newState = this.clone();
    $.extend(newState, stateChanges);
    return newState;
};

/*
    Return true if any of the stateChanges actually affect this state
*/
bcViewer.ViewerState.prototype.affectedBy = function(stateChanges) {
    for (var prop in stateChanges) {
        if (this[prop] !== stateChanges[prop]) {
            return true;
        }
    }
    return false;
};

/*
    Return true if this state is equal to the other state
*/
bcViewer.ViewerState.prototype.equalTo = function(state) {
    for (var prop in state) {
        if (state.hasOwnProperty(prop) && (this[prop] !== state[prop])) {
            return false;
        }
    }
    return true;
};

}
if (typeof window !== 'undefined') {

/*
    NHANESIIIPredData - tables for predicted values.
    The tables structure is:
    NHANESIIIPredData[<calculation name>][gender][height][age]
    
    Where calculation name is one of the spirometer calculated values (FVC, FEV1...)
*/
var NHANESIIIPredData = {};

NHANESIIIPredData.FVC = {};

NHANESIIIPredData.FVC.male =
[[3.10, 3.23, 3.38, 3.55, 3.74, 3.73, 3.72, 3.71, 3.70, 3.68, 3.67, 3.66, 3.64, 3.63, 3.61, 3.60, 3.58, 3.56, 3.55, 3.53, 3.51, 3.49, 3.47, 3.45, 3.43, 3.41, 3.39, 3.37, 3.34, 3.32, 3.30, 3.27, 3.25, 3.22, 3.19, 3.17, 3.14, 3.11, 3.09, 3.06, 3.03, 3.00, 2.97, 2.94, 2.91, 2.87, 2.84, 2.81, 2.77, 2.74, 2.71, 2.67, 2.63, 2.60, 2.56, 2.52, 2.49, 2.45, 2.41, 2.37, 2.33, 2.29, 2.25, 2.21, 2.16],
[3.15, 3.28, 3.43, 3.60, 3.80, 3.78, 3.77, 3.76, 3.75, 3.74, 3.72, 3.71, 3.70, 3.68, 3.67, 3.65, 3.64, 3.62, 3.60, 3.58, 3.56, 3.55, 3.53, 3.51, 3.49, 3.46, 3.44, 3.42, 3.40, 3.37, 3.35, 3.33, 3.30, 3.28, 3.25, 3.22, 3.20, 3.17, 3.14, 3.11, 3.08, 3.05, 3.02, 2.99, 2.96, 2.93, 2.90, 2.86, 2.83, 2.80, 2.76, 2.73, 2.69, 2.65, 2.62, 2.58, 2.54, 2.50, 2.46, 2.42, 2.38, 2.34, 2.30, 2.26, 2.22],
[3.21, 3.34, 3.49, 3.66, 3.85, 3.84, 3.83, 3.82, 3.81, 3.79, 3.78, 3.77, 3.75, 3.74, 3.72, 3.71, 3.69, 3.67, 3.66, 3.64, 3.62, 3.60, 3.58, 3.56, 3.54, 3.52, 3.50, 3.48, 3.45, 3.43, 3.41, 3.38, 3.36, 3.33, 3.30, 3.28, 3.25, 3.22, 3.20, 3.17, 3.14, 3.11, 3.08, 3.05, 3.02, 2.98, 2.95, 2.92, 2.88, 2.85, 2.82, 2.78, 2.75, 2.71, 2.67, 2.63, 2.60, 2.56, 2.52, 2.48, 2.44, 2.40, 2.36, 2.32, 2.28],
[3.26, 3.39, 3.54, 3.72, 3.91, 3.90, 3.89, 3.87, 3.86, 3.85, 3.84, 3.82, 3.81, 3.79, 3.78, 3.76, 3.75, 3.73, 3.71, 3.69, 3.68, 3.66, 3.64, 3.62, 3.60, 3.58, 3.55, 3.53, 3.51, 3.49, 3.46, 3.44, 3.41, 3.39, 3.36, 3.33, 3.31, 3.28, 3.25, 3.22, 3.19, 3.16, 3.13, 3.10, 3.07, 3.04, 3.01, 2.97, 2.94, 2.91, 2.87, 2.84, 2.80, 2.76, 2.73, 2.69, 2.65, 2.61, 2.58, 2.54, 2.50, 2.46, 2.41, 2.37, 2.33],
[3.32, 3.45, 3.60, 3.77, 3.96, 3.95, 3.94, 3.93, 3.92, 3.91, 3.89, 3.88, 3.86, 3.85, 3.83, 3.82, 3.80, 3.79, 3.77, 3.75, 3.73, 3.71, 3.69, 3.67, 3.65, 3.63, 3.61, 3.59, 3.56, 3.54, 3.52, 3.49, 3.47, 3.44, 3.42, 3.39, 3.36, 3.34, 3.31, 3.28, 3.25, 3.22, 3.19, 3.16, 3.13, 3.10, 3.06, 3.03, 3.00, 2.96, 2.93, 2.89, 2.86, 2.82, 2.78, 2.75, 2.71, 2.67, 2.63, 2.59, 2.55, 2.51, 2.47, 2.43, 2.39],
[3.38, 3.51, 3.66, 3.83, 4.02, 4.01, 4.00, 3.99, 3.97, 3.96, 3.95, 3.93, 3.92, 3.91, 3.89, 3.88, 3.86, 3.84, 3.82, 3.81, 3.79, 3.77, 3.75, 3.73, 3.71, 3.69, 3.67, 3.64, 3.62, 3.60, 3.57, 3.55, 3.52, 3.50, 3.47, 3.45, 3.42, 3.39, 3.36, 3.34, 3.31, 3.28, 3.25, 3.22, 3.18, 3.15, 3.12, 3.09, 3.05, 3.02, 2.98, 2.95, 2.91, 2.88, 2.84, 2.80, 2.77, 2.73, 2.69, 2.65, 2.61, 2.57, 2.53, 2.49, 2.44],
[3.43, 3.56, 3.71, 3.88, 4.08, 4.07, 4.05, 4.04, 4.03, 4.02, 4.01, 3.99, 3.98, 3.96, 3.95, 3.93, 3.92, 3.90, 3.88, 3.86, 3.85, 3.83, 3.81, 3.79, 3.77, 3.74, 3.72, 3.70, 3.68, 3.65, 3.63, 3.61, 3.58, 3.56, 3.53, 3.50, 3.48, 3.45, 3.42, 3.39, 3.36, 3.33, 3.30, 3.27, 3.24, 3.21, 3.18, 3.14, 3.11, 3.08, 3.04, 3.01, 2.97, 2.93, 2.90, 2.86, 2.82, 2.78, 2.74, 2.71, 2.67, 2.62, 2.58, 2.54, 2.50],
[3.49, 3.62, 3.77, 3.94, 4.13, 4.12, 4.11, 4.10, 4.09, 4.08, 4.06, 4.05, 4.03, 4.02, 4.00, 3.99, 3.97, 3.96, 3.94, 3.92, 3.90, 3.88, 3.86, 3.84, 3.82, 3.80, 3.78, 3.76, 3.74, 3.71, 3.69, 3.66, 3.64, 3.61, 3.59, 3.56, 3.53, 3.51, 3.48, 3.45, 3.42, 3.39, 3.36, 3.33, 3.30, 3.27, 3.23, 3.20, 3.17, 3.13, 3.10, 3.06, 3.03, 2.99, 2.95, 2.92, 2.88, 2.84, 2.80, 2.76, 2.72, 2.68, 2.64, 2.60, 2.56],
[3.55, 3.68, 3.83, 4.00, 4.19, 4.18, 4.17, 4.16, 4.15, 4.13, 4.12, 4.11, 4.09, 4.08, 4.06, 4.05, 4.03, 4.01, 4.00, 3.98, 3.96, 3.94, 3.92, 3.90, 3.88, 3.86, 3.84, 3.82, 3.79, 3.77, 3.75, 3.72, 3.70, 3.67, 3.64, 3.62, 3.59, 3.56, 3.54, 3.51, 3.48, 3.45, 3.42, 3.39, 3.36, 3.32, 3.29, 3.26, 3.22, 3.19, 3.16, 3.12, 3.09, 3.05, 3.01, 2.97, 2.94, 2.90, 2.86, 2.82, 2.78, 2.74, 2.70, 2.66, 2.62],
[3.61, 3.74, 3.89, 4.06, 4.25, 4.24, 4.23, 4.22, 4.20, 4.19, 4.18, 4.16, 4.15, 4.14, 4.12, 4.10, 4.09, 4.07, 4.05, 4.04, 4.02, 4.00, 3.98, 3.96, 3.94, 3.92, 3.90, 3.87, 3.85, 3.83, 3.80, 3.78, 3.75, 3.73, 3.70, 3.68, 3.65, 3.62, 3.59, 3.56, 3.54, 3.51, 3.48, 3.44, 3.41, 3.38, 3.35, 3.32, 3.28, 3.25, 3.21, 3.18, 3.14, 3.11, 3.07, 3.03, 3.00, 2.96, 2.92, 2.88, 2.84, 2.80, 2.76, 2.72, 2.67],
[3.66, 3.79, 3.95, 4.12, 4.31, 4.30, 4.29, 4.27, 4.26, 4.25, 4.24, 4.22, 4.21, 4.19, 4.18, 4.16, 4.15, 4.13, 4.11, 4.09, 4.08, 4.06, 4.04, 4.02, 4.00, 3.98, 3.95, 3.93, 3.91, 3.89, 3.86, 3.84, 3.81, 3.79, 3.76, 3.73, 3.71, 3.68, 3.65, 3.62, 3.59, 3.56, 3.53, 3.50, 3.47, 3.44, 3.41, 3.37, 3.34, 3.31, 3.27, 3.24, 3.20, 3.17, 3.13, 3.09, 3.05, 3.01, 2.98, 2.94, 2.90, 2.86, 2.82, 2.77, 2.73],
[3.72, 3.85, 4.00, 4.17, 4.37, 4.36, 4.34, 4.33, 4.32, 4.31, 4.30, 4.28, 4.27, 4.25, 4.24, 4.22, 4.21, 4.19, 4.17, 4.15, 4.13, 4.12, 4.10, 4.08, 4.06, 4.03, 4.01, 3.99, 3.97, 3.94, 3.92, 3.90, 3.87, 3.85, 3.82, 3.79, 3.77, 3.74, 3.71, 3.68, 3.65, 3.62, 3.59, 3.56, 3.53, 3.50, 3.47, 3.43, 3.40, 3.37, 3.33, 3.30, 3.26, 3.22, 3.19, 3.15, 3.11, 3.07, 3.03, 3.00, 2.96, 2.91, 2.87, 2.83, 2.79],
[3.78, 3.91, 4.06, 4.23, 4.42, 4.41, 4.40, 4.39, 4.38, 4.37, 4.35, 4.34, 4.33, 4.31, 4.30, 4.28, 4.26, 4.25, 4.23, 4.21, 4.19, 4.18, 4.16, 4.14, 4.11, 4.09, 4.07, 4.05, 4.03, 4.00, 3.98, 3.96, 3.93, 3.91, 3.88, 3.85, 3.83, 3.80, 3.77, 3.74, 3.71, 3.68, 3.65, 3.62, 3.59, 3.56, 3.53, 3.49, 3.46, 3.42, 3.39, 3.35, 3.32, 3.28, 3.25, 3.21, 3.17, 3.13, 3.09, 3.05, 3.01, 2.97, 2.93, 2.89, 2.85],
[3.84, 3.97, 4.12, 4.29, 4.48, 4.47, 4.46, 4.45, 4.44, 4.43, 4.41, 4.40, 4.39, 4.37, 4.36, 4.34, 4.32, 4.31, 4.29, 4.27, 4.25, 4.23, 4.21, 4.19, 4.17, 4.15, 4.13, 4.11, 4.09, 4.06, 4.04, 4.01, 3.99, 3.96, 3.94, 3.91, 3.88, 3.86, 3.83, 3.80, 3.77, 3.74, 3.71, 3.68, 3.65, 3.62, 3.58, 3.55, 3.52, 3.48, 3.45, 3.41, 3.38, 3.34, 3.31, 3.27, 3.23, 3.19, 3.15, 3.11, 3.07, 3.03, 2.99, 2.95, 2.91],
[3.90, 4.03, 4.18, 4.35, 4.54, 4.53, 4.52, 4.51, 4.50, 4.49, 4.47, 4.46, 4.45, 4.43, 4.42, 4.40, 4.38, 4.37, 4.35, 4.33, 4.31, 4.29, 4.27, 4.25, 4.23, 4.21, 4.19, 4.17, 4.15, 4.12, 4.10, 4.07, 4.05, 4.02, 4.00, 3.97, 3.94, 3.92, 3.89, 3.86, 3.83, 3.80, 3.77, 3.74, 3.71, 3.68, 3.64, 3.61, 3.58, 3.54, 3.51, 3.47, 3.44, 3.40, 3.37, 3.33, 3.29, 3.25, 3.21, 3.17, 3.13, 3.09, 3.05, 3.01, 2.97],
[3.96, 4.09, 4.24, 4.41, 4.60, 4.59, 4.58, 4.57, 4.56, 4.55, 4.53, 4.52, 4.51, 4.49, 4.48, 4.46, 4.44, 4.43, 4.41, 4.39, 4.37, 4.35, 4.33, 4.31, 4.29, 4.27, 4.25, 4.23, 4.21, 4.18, 4.16, 4.13, 4.11, 4.08, 4.06, 4.03, 4.01, 3.98, 3.95, 3.92, 3.89, 3.86, 3.83, 3.80, 3.77, 3.74, 3.70, 3.67, 3.64, 3.60, 3.57, 3.53, 3.50, 3.46, 3.43, 3.39, 3.35, 3.31, 3.27, 3.23, 3.19, 3.15, 3.11, 3.07, 3.03],
[4.02, 4.15, 4.30, 4.47, 4.66, 4.65, 4.64, 4.63, 4.62, 4.61, 4.59, 4.58, 4.57, 4.55, 4.54, 4.52, 4.50, 4.49, 4.47, 4.45, 4.43, 4.42, 4.40, 4.38, 4.35, 4.33, 4.31, 4.29, 4.27, 4.24, 4.22, 4.20, 4.17, 4.15, 4.12, 4.09, 4.07, 4.04, 4.01, 3.98, 3.95, 3.92, 3.89, 3.86, 3.83, 3.80, 3.77, 3.73, 3.70, 3.66, 3.63, 3.60, 3.56, 3.52, 3.49, 3.45, 3.41, 3.37, 3.33, 3.29, 3.25, 3.21, 3.17, 3.13, 3.09],
[4.08, 4.21, 4.36, 4.53, 4.73, 4.72, 4.70, 4.69, 4.68, 4.67, 4.66, 4.64, 4.63, 4.61, 4.60, 4.58, 4.57, 4.55, 4.53, 4.51, 4.50, 4.48, 4.46, 4.44, 4.42, 4.39, 4.37, 4.35, 4.33, 4.30, 4.28, 4.26, 4.23, 4.21, 4.18, 4.15, 4.13, 4.10, 4.07, 4.04, 4.01, 3.98, 3.95, 3.92, 3.89, 3.86, 3.83, 3.79, 3.76, 3.73, 3.69, 3.66, 3.62, 3.58, 3.55, 3.51, 3.47, 3.43, 3.39, 3.36, 3.32, 3.28, 3.23, 3.19, 3.15],
[4.14, 4.27, 4.43, 4.60, 4.79, 4.78, 4.77, 4.75, 4.74, 4.73, 4.72, 4.70, 4.69, 4.67, 4.66, 4.64, 4.63, 4.61, 4.59, 4.57, 4.56, 4.54, 4.52, 4.50, 4.48, 4.46, 4.43, 4.41, 4.39, 4.37, 4.34, 4.32, 4.29, 4.27, 4.24, 4.21, 4.19, 4.16, 4.13, 4.10, 4.07, 4.04, 4.01, 3.98, 3.95, 3.92, 3.89, 3.85, 3.82, 3.79, 3.75, 3.72, 3.68, 3.65, 3.61, 3.57, 3.53, 3.50, 3.46, 3.42, 3.38, 3.34, 3.30, 3.25, 3.21],
[4.21, 4.34, 4.49, 4.66, 4.85, 4.84, 4.83, 4.82, 4.80, 4.79, 4.78, 4.76, 4.75, 4.74, 4.72, 4.71, 4.69, 4.67, 4.65, 4.64, 4.62, 4.60, 4.58, 4.56, 4.54, 4.52, 4.50, 4.47, 4.45, 4.43, 4.40, 4.38, 4.35, 4.33, 4.30, 4.28, 4.25, 4.22, 4.19, 4.17, 4.14, 4.11, 4.08, 4.05, 4.01, 3.98, 3.95, 3.92, 3.88, 3.85, 3.81, 3.78, 3.74, 3.71, 3.67, 3.63, 3.60, 3.56, 3.52, 3.48, 3.44, 3.40, 3.36, 3.32, 3.27],
[4.27, 4.40, 4.55, 4.72, 4.91, 4.90, 4.89, 4.88, 4.87, 4.85, 4.84, 4.83, 4.81, 4.80, 4.78, 4.77, 4.75, 4.73, 4.72, 4.70, 4.68, 4.66, 4.64, 4.62, 4.60, 4.58, 4.56, 4.54, 4.51, 4.49, 4.47, 4.44, 4.42, 4.39, 4.37, 4.34, 4.31, 4.28, 4.26, 4.23, 4.20, 4.17, 4.14, 4.11, 4.08, 4.04, 4.01, 3.98, 3.94, 3.91, 3.88, 3.84, 3.81, 3.77, 3.73, 3.70, 3.66, 3.62, 3.58, 3.54, 3.50, 3.46, 3.42, 3.38, 3.34],
[4.33, 4.46, 4.61, 4.78, 4.97, 4.96, 4.95, 4.94, 4.93, 4.92, 4.90, 4.89, 4.88, 4.86, 4.85, 4.83, 4.81, 4.80, 4.78, 4.76, 4.74, 4.72, 4.70, 4.68, 4.66, 4.64, 4.62, 4.60, 4.58, 4.55, 4.53, 4.50, 4.48, 4.45, 4.43, 4.40, 4.37, 4.35, 4.32, 4.29, 4.26, 4.23, 4.20, 4.17, 4.14, 4.11, 4.07, 4.04, 4.01, 3.97, 3.94, 3.90, 3.87, 3.83, 3.79, 3.76, 3.72, 3.68, 3.64, 3.60, 3.56, 3.52, 3.48, 3.44, 3.40],
[4.39, 4.52, 4.67, 4.85, 5.04, 5.03, 5.01, 5.00, 4.99, 4.98, 4.97, 4.95, 4.94, 4.92, 4.91, 4.89, 4.88, 4.86, 4.84, 4.82, 4.81, 4.79, 4.77, 4.75, 4.73, 4.71, 4.68, 4.66, 4.64, 4.62, 4.59, 4.57, 4.54, 4.52, 4.49, 4.46, 4.44, 4.41, 4.38, 4.35, 4.32, 4.29, 4.26, 4.23, 4.20, 4.17, 4.14, 4.10, 4.07, 4.04, 4.00, 3.97, 3.93, 3.89, 3.86, 3.82, 3.78, 3.74, 3.71, 3.67, 3.63, 3.59, 3.54, 3.50, 3.46],
[4.46, 4.59, 4.74, 4.91, 5.10, 5.09, 5.08, 5.07, 5.05, 5.04, 5.03, 5.02, 5.00, 4.99, 4.97, 4.96, 4.94, 4.92, 4.91, 4.89, 4.87, 4.85, 4.83, 4.81, 4.79, 4.77, 4.75, 4.72, 4.70, 4.68, 4.65, 4.63, 4.61, 4.58, 4.55, 4.53, 4.50, 4.47, 4.44, 4.42, 4.39, 4.36, 4.33, 4.30, 4.26, 4.23, 4.20, 4.17, 4.13, 4.10, 4.06, 4.03, 3.99, 3.96, 3.92, 3.88, 3.85, 3.81, 3.77, 3.73, 3.69, 3.65, 3.61, 3.57, 3.52],
[4.52, 4.65, 4.80, 4.97, 5.16, 5.15, 5.14, 5.13, 5.12, 5.11, 5.09, 5.08, 5.06, 5.05, 5.03, 5.02, 5.00, 4.99, 4.97, 4.95, 4.93, 4.91, 4.89, 4.87, 4.85, 4.83, 4.81, 4.79, 4.77, 4.74, 4.72, 4.69, 4.67, 4.64, 4.62, 4.59, 4.56, 4.54, 4.51, 4.48, 4.45, 4.42, 4.39, 4.36, 4.33, 4.30, 4.26, 4.23, 4.20, 4.16, 4.13, 4.09, 4.06, 4.02, 3.98, 3.95, 3.91, 3.87, 3.83, 3.79, 3.75, 3.71, 3.67, 3.63, 3.59],
[4.58, 4.71, 4.87, 5.04, 5.23, 5.22, 5.21, 5.19, 5.18, 5.17, 5.16, 5.14, 5.13, 5.11, 5.10, 5.08, 5.07, 5.05, 5.03, 5.01, 5.00, 4.98, 4.96, 4.94, 4.92, 4.90, 4.87, 4.85, 4.83, 4.81, 4.78, 4.76, 4.73, 4.71, 4.68, 4.65, 4.63, 4.60, 4.57, 4.54, 4.51, 4.48, 4.45, 4.42, 4.39, 4.36, 4.33, 4.29, 4.26, 4.23, 4.19, 4.16, 4.12, 4.09, 4.05, 4.01, 3.97, 3.93, 3.90, 3.86, 3.82, 3.78, 3.74, 3.69, 3.65],
[4.65, 4.78, 4.93, 5.10, 5.29, 5.28, 5.27, 5.26, 5.25, 5.23, 5.22, 5.21, 5.19, 5.18, 5.16, 5.15, 5.13, 5.11, 5.10, 5.08, 5.06, 5.04, 5.02, 5.00, 4.98, 4.96, 4.94, 4.92, 4.89, 4.87, 4.85, 4.82, 4.80, 4.77, 4.75, 4.72, 4.69, 4.66, 4.64, 4.61, 4.58, 4.55, 4.52, 4.49, 4.46, 4.42, 4.39, 4.36, 4.33, 4.29, 4.26, 4.22, 4.19, 4.15, 4.11, 4.08, 4.04, 4.00, 3.96, 3.92, 3.88, 3.84, 3.80, 3.76, 3.72],
[4.71, 4.84, 4.99, 5.16, 5.36, 5.35, 5.33, 5.32, 5.31, 5.30, 5.29, 5.27, 5.26, 5.24, 5.23, 5.21, 5.20, 5.18, 5.16, 5.14, 5.13, 5.11, 5.09, 5.07, 5.05, 5.02, 5.00, 4.98, 4.96, 4.93, 4.91, 4.89, 4.86, 4.84, 4.81, 4.78, 4.76, 4.73, 4.70, 4.67, 4.64, 4.61, 4.58, 4.55, 4.52, 4.49, 4.46, 4.42, 4.39, 4.36, 4.32, 4.29, 4.25, 4.21, 4.18, 4.14, 4.10, 4.06, 4.03, 3.99, 3.95, 3.91, 3.86, 3.82, 3.78],
[4.78, 4.91, 5.06, 5.23, 5.42, 5.41, 5.40, 5.39, 5.38, 5.36, 5.35, 5.34, 5.32, 5.31, 5.29, 5.28, 5.26, 5.24, 5.23, 5.21, 5.19, 5.17, 5.15, 5.13, 5.11, 5.09, 5.07, 5.05, 5.02, 5.00, 4.98, 4.95, 4.93, 4.90, 4.88, 4.85, 4.82, 4.79, 4.77, 4.74, 4.71, 4.68, 4.65, 4.62, 4.59, 4.55, 4.52, 4.49, 4.45, 4.42, 4.39, 4.35, 4.32, 4.28, 4.24, 4.21, 4.17, 4.13, 4.09, 4.05, 4.01, 3.97, 3.93, 3.89, 3.85],
[4.84, 4.97, 5.12, 5.30, 5.49, 5.48, 5.47, 5.45, 5.44, 5.43, 5.42, 5.40, 5.39, 5.37, 5.36, 5.34, 5.33, 5.31, 5.29, 5.27, 5.26, 5.24, 5.22, 5.20, 5.18, 5.16, 5.13, 5.11, 5.09, 5.07, 5.04, 5.02, 4.99, 4.97, 4.94, 4.91, 4.89, 4.86, 4.83, 4.80, 4.77, 4.74, 4.71, 4.68, 4.65, 4.62, 4.59, 4.55, 4.52, 4.49, 4.45, 4.42, 4.38, 4.34, 4.31, 4.27, 4.23, 4.19, 4.16, 4.12, 4.08, 4.04, 3.99, 3.95, 3.91],
[4.91, 5.04, 5.19, 5.36, 5.55, 5.54, 5.53, 5.52, 5.51, 5.49, 5.48, 5.47, 5.45, 5.44, 5.42, 5.41, 5.39, 5.38, 5.36, 5.34, 5.32, 5.30, 5.28, 5.26, 5.24, 5.22, 5.20, 5.18, 5.15, 5.13, 5.11, 5.08, 5.06, 5.03, 5.01, 4.98, 4.95, 4.93, 4.90, 4.87, 4.84, 4.81, 4.78, 4.75, 4.72, 4.69, 4.65, 4.62, 4.59, 4.55, 4.52, 4.48, 4.45, 4.41, 4.37, 4.34, 4.30, 4.26, 4.22, 4.18, 4.14, 4.10, 4.06, 4.02, 3.98],
[4.98, 5.11, 5.26, 5.43, 5.62, 5.61, 5.60, 5.59, 5.57, 5.56, 5.55, 5.53, 5.52, 5.51, 5.49, 5.47, 5.46, 5.44, 5.42, 5.41, 5.39, 5.37, 5.35, 5.33, 5.31, 5.29, 5.27, 5.24, 5.22, 5.20, 5.17, 5.15, 5.12, 5.10, 5.07, 5.05, 5.02, 4.99, 4.96, 4.93, 4.91, 4.88, 4.85, 4.81, 4.78, 4.75, 4.72, 4.69, 4.65, 4.62, 4.58, 4.55, 4.51, 4.48, 4.44, 4.40, 4.36, 4.33, 4.29, 4.25, 4.21, 4.17, 4.13, 4.08, 4.04],
[5.04, 5.17, 5.32, 5.49, 5.68, 5.67, 5.66, 5.65, 5.64, 5.63, 5.61, 5.60, 5.59, 5.57, 5.56, 5.54, 5.52, 5.51, 5.49, 5.47, 5.45, 5.44, 5.42, 5.40, 5.37, 5.35, 5.33, 5.31, 5.29, 5.26, 5.24, 5.22, 5.19, 5.17, 5.14, 5.11, 5.09, 5.06, 5.03, 5.00, 4.97, 4.94, 4.91, 4.88, 4.85, 4.82, 4.79, 4.75, 4.72, 4.68, 4.65, 4.62, 4.58, 4.54, 4.51, 4.47, 4.43, 4.39, 4.35, 4.31, 4.27, 4.23, 4.19, 4.15, 4.11],
[5.11, 5.24, 5.39, 5.56, 5.75, 5.74, 5.73, 5.72, 5.71, 5.69, 5.68, 5.67, 5.65, 5.64, 5.62, 5.61, 5.59, 5.57, 5.56, 5.54, 5.52, 5.50, 5.48, 5.46, 5.44, 5.42, 5.40, 5.38, 5.35, 5.33, 5.31, 5.28, 5.26, 5.23, 5.21, 5.18, 5.15, 5.13, 5.10, 5.07, 5.04, 5.01, 4.98, 4.95, 4.92, 4.88, 4.85, 4.82, 4.79, 4.75, 4.72, 4.68, 4.65, 4.61, 4.57, 4.54, 4.50, 4.46, 4.42, 4.38, 4.34, 4.30, 4.26, 4.22, 4.18],
[5.18, 5.31, 5.46, 5.63, 5.82, 5.81, 5.80, 5.79, 5.77, 5.76, 5.75, 5.74, 5.72, 5.71, 5.69, 5.68, 5.66, 5.64, 5.62, 5.61, 5.59, 5.57, 5.55, 5.53, 5.51, 5.49, 5.47, 5.44, 5.42, 5.40, 5.37, 5.35, 5.32, 5.30, 5.27, 5.25, 5.22, 5.19, 5.16, 5.14, 5.11, 5.08, 5.05, 5.02, 4.98, 4.95, 4.92, 4.89, 4.85, 4.82, 4.78, 4.75, 4.71, 4.68, 4.64, 4.60, 4.57, 4.53, 4.49, 4.45, 4.41, 4.37, 4.33, 4.29, 4.24],
[5.24, 5.37, 5.52, 5.70, 5.89, 5.88, 5.87, 5.85, 5.84, 5.83, 5.82, 5.80, 5.79, 5.77, 5.76, 5.74, 5.73, 5.71, 5.69, 5.67, 5.66, 5.64, 5.62, 5.60, 5.58, 5.56, 5.53, 5.51, 5.49, 5.47, 5.44, 5.42, 5.39, 5.37, 5.34, 5.31, 5.29, 5.26, 5.23, 5.20, 5.17, 5.14, 5.11, 5.08, 5.05, 5.02, 4.99, 4.95, 4.92, 4.89, 4.85, 4.82, 4.78, 4.75, 4.71, 4.67, 4.63, 4.59, 4.56, 4.52, 4.48, 4.44, 4.40, 4.35, 4.31],
[5.31, 5.44, 5.59, 5.76, 5.95, 5.94, 5.93, 5.92, 5.91, 5.90, 5.88, 5.87, 5.86, 5.84, 5.83, 5.81, 5.79, 5.78, 5.76, 5.74, 5.72, 5.71, 5.69, 5.67, 5.64, 5.62, 5.60, 5.58, 5.56, 5.53, 5.51, 5.49, 5.46, 5.44, 5.41, 5.38, 5.36, 5.33, 5.30, 5.27, 5.24, 5.21, 5.18, 5.15, 5.12, 5.09, 5.06, 5.02, 4.99, 4.95, 4.92, 4.89, 4.85, 4.81, 4.78, 4.74, 4.70, 4.66, 4.62, 4.58, 4.54, 4.50, 4.46, 4.42, 4.38],
[5.38, 5.51, 5.66, 5.83, 6.02, 6.01, 6.00, 5.99, 5.98, 5.97, 5.95, 5.94, 5.93, 5.91, 5.90, 5.88, 5.86, 5.85, 5.83, 5.81, 5.79, 5.77, 5.75, 5.73, 5.71, 5.69, 5.67, 5.65, 5.63, 5.60, 5.58, 5.55, 5.53, 5.50, 5.48, 5.45, 5.42, 5.40, 5.37, 5.34, 5.31, 5.28, 5.25, 5.22, 5.19, 5.16, 5.12, 5.09, 5.06, 5.02, 4.99, 4.95, 4.92, 4.88, 4.84, 4.81, 4.77, 4.73, 4.69, 4.65, 4.61, 4.57, 4.53, 4.49, 4.45],
[5.45, 5.58, 5.73, 5.90, 6.09, 6.08, 6.07, 6.06, 6.05, 6.03, 6.02, 6.01, 5.99, 5.98, 5.96, 5.95, 5.93, 5.92, 5.90, 5.88, 5.86, 5.84, 5.82, 5.80, 5.78, 5.76, 5.74, 5.72, 5.69, 5.67, 5.65, 5.62, 5.60, 5.57, 5.55, 5.52, 5.49, 5.47, 5.44, 5.41, 5.38, 5.35, 5.32, 5.29, 5.26, 5.23, 5.19, 5.16, 5.13, 5.09, 5.06, 5.02, 4.99, 4.95, 4.91, 4.88, 4.84, 4.80, 4.76, 4.72, 4.68, 4.64, 4.60, 4.56, 4.52],
[5.52, 5.65, 5.80, 5.97, 6.16, 6.15, 6.14, 6.13, 6.12, 6.10, 6.09, 6.08, 6.06, 6.05, 6.03, 6.02, 6.00, 5.98, 5.97, 5.95, 5.93, 5.91, 5.89, 5.87, 5.85, 5.83, 5.81, 5.79, 5.76, 5.74, 5.72, 5.69, 5.67, 5.64, 5.62, 5.59, 5.56, 5.53, 5.51, 5.48, 5.45, 5.42, 5.39, 5.36, 5.33, 5.29, 5.26, 5.23, 5.20, 5.16, 5.13, 5.09, 5.06, 5.02, 4.98, 4.95, 4.91, 4.87, 4.83, 4.79, 4.75, 4.71, 4.67, 4.63, 4.59],
[5.59, 5.72, 5.87, 6.04, 6.23, 6.22, 6.21, 6.20, 6.19, 6.17, 6.16, 6.15, 6.13, 6.12, 6.10, 6.09, 6.07, 6.05, 6.04, 6.02, 6.00, 5.98, 5.96, 5.94, 5.92, 5.90, 5.88, 5.86, 5.83, 5.81, 5.79, 5.76, 5.74, 5.71, 5.69, 5.66, 5.63, 5.60, 5.58, 5.55, 5.52, 5.49, 5.46, 5.43, 5.40, 5.36, 5.33, 5.30, 5.26, 5.23, 5.20, 5.16, 5.13, 5.09, 5.05, 5.02, 4.98, 4.94, 4.90, 4.86, 4.82, 4.78, 4.74, 4.70, 4.66],
[5.66, 5.79, 5.94, 6.11, 6.30, 6.29, 6.28, 6.27, 6.26, 6.24, 6.23, 6.22, 6.20, 6.19, 6.17, 6.16, 6.14, 6.12, 6.11, 6.09, 6.07, 6.05, 6.03, 6.01, 5.99, 5.97, 5.95, 5.93, 5.90, 5.88, 5.86, 5.83, 5.81, 5.78, 5.76, 5.73, 5.70, 5.67, 5.65, 5.62, 5.59, 5.56, 5.53, 5.50, 5.47, 5.43, 5.40, 5.37, 5.33, 5.30, 5.27, 5.23, 5.20, 5.16, 5.12, 5.08, 5.05, 5.01, 4.97, 4.93, 4.89, 4.85, 4.81, 4.77, 4.73],
[5.73, 5.86, 6.01, 6.18, 6.37, 6.36, 6.35, 6.34, 6.33, 6.31, 6.30, 6.29, 6.27, 6.26, 6.24, 6.23, 6.21, 6.19, 6.18, 6.16, 6.14, 6.12, 6.10, 6.08, 6.06, 6.04, 6.02, 6.00, 5.97, 5.95, 5.93, 5.90, 5.88, 5.85, 5.83, 5.80, 5.77, 5.74, 5.72, 5.69, 5.66, 5.63, 5.60, 5.57, 5.54, 5.50, 5.47, 5.44, 5.40, 5.37, 5.34, 5.30, 5.27, 5.23, 5.19, 5.16, 5.12, 5.08, 5.04, 5.00, 4.96, 4.92, 4.88, 4.84, 4.80],
[5.80, 5.93, 6.08, 6.25, 6.44, 6.43, 6.42, 6.41, 6.40, 6.38, 6.37, 6.36, 6.34, 6.33, 6.31, 6.30, 6.28, 6.26, 6.25, 6.23, 6.21, 6.19, 6.17, 6.15, 6.13, 6.11, 6.09, 6.07, 6.04, 6.02, 6.00, 5.97, 5.95, 5.92, 5.90, 5.87, 5.84, 5.81, 5.79, 5.76, 5.73, 5.70, 5.67, 5.64, 5.61, 5.57, 5.54, 5.51, 5.48, 5.44, 5.41, 5.37, 5.34, 5.30, 5.26, 5.23, 5.19, 5.15, 5.11, 5.07, 5.03, 4.99, 4.95, 4.91, 4.87],
[5.87, 6.00, 6.15, 6.32, 6.51, 6.50, 6.49, 6.48, 6.47, 6.46, 6.44, 6.43, 6.41, 6.40, 6.38, 6.37, 6.35, 6.34, 6.32, 6.30, 6.28, 6.26, 6.24, 6.22, 6.20, 6.18, 6.16, 6.14, 6.11, 6.09, 6.07, 6.04, 6.02, 5.99, 5.97, 5.94, 5.91, 5.89, 5.86, 5.83, 5.80, 5.77, 5.74, 5.71, 5.68, 5.65, 5.61, 5.58, 5.55, 5.51, 5.48, 5.44, 5.41, 5.37, 5.33, 5.30, 5.26, 5.22, 5.18, 5.14, 5.10, 5.06, 5.02, 4.98, 4.94],
[5.94, 6.07, 6.22, 6.39, 6.58, 6.57, 6.56, 6.55, 6.54, 6.53, 6.51, 6.50, 6.49, 6.47, 6.46, 6.44, 6.42, 6.41, 6.39, 6.37, 6.35, 6.33, 6.31, 6.29, 6.27, 6.25, 6.23, 6.21, 6.19, 6.16, 6.14, 6.11, 6.09, 6.06, 6.04, 6.01, 5.98, 5.96, 5.93, 5.90, 5.87, 5.84, 5.81, 5.78, 5.75, 5.72, 5.68, 5.65, 5.62, 5.58, 5.55, 5.51, 5.48, 5.44, 5.41, 5.37, 5.33, 5.29, 5.25, 5.21, 5.17, 5.13, 5.09, 5.05, 5.01],
[6.01, 6.14, 6.29, 6.46, 6.66, 6.65, 6.63, 6.62, 6.61, 6.60, 6.59, 6.57, 6.56, 6.54, 6.53, 6.51, 6.50, 6.48, 6.46, 6.44, 6.43, 6.41, 6.39, 6.37, 6.35, 6.32, 6.30, 6.28, 6.26, 6.23, 6.21, 6.19, 6.16, 6.14, 6.11, 6.08, 6.06, 6.03, 6.00, 5.97, 5.94, 5.91, 5.88, 5.85, 5.82, 5.79, 5.76, 5.72, 5.69, 5.66, 5.62, 5.59, 5.55, 5.51, 5.48, 5.44, 5.40, 5.36, 5.32, 5.29, 5.25, 5.21, 5.16, 5.12, 5.08],
[6.09, 6.22, 6.37, 6.54, 6.73, 6.72, 6.71, 6.70, 6.68, 6.67, 6.66, 6.64, 6.63, 6.62, 6.60, 6.58, 6.57, 6.55, 6.53, 6.52, 6.50, 6.48, 6.46, 6.44, 6.42, 6.40, 6.38, 6.35, 6.33, 6.31, 6.28, 6.26, 6.23, 6.21, 6.18, 6.16, 6.13, 6.10, 6.07, 6.04, 6.02, 5.99, 5.96, 5.92, 5.89, 5.86, 5.83, 5.80, 5.76, 5.73, 5.69, 5.66, 5.62, 5.59, 5.55, 5.51, 5.47, 5.44, 5.40, 5.36, 5.32, 5.28, 5.24, 5.19, 5.15],
[6.16, 6.29, 6.44, 6.61, 6.80, 6.79, 6.78, 6.77, 6.76, 6.74, 6.73, 6.72, 6.70, 6.69, 6.67, 6.66, 6.64, 6.62, 6.61, 6.59, 6.57, 6.55, 6.53, 6.51, 6.49, 6.47, 6.45, 6.43, 6.40, 6.38, 6.36, 6.33, 6.31, 6.28, 6.25, 6.23, 6.20, 6.17, 6.15, 6.12, 6.09, 6.06, 6.03, 6.00, 5.97, 5.93, 5.90, 5.87, 5.83, 5.80, 5.77, 5.73, 5.69, 5.66, 5.62, 5.58, 5.55, 5.51, 5.47, 5.43, 5.39, 5.35, 5.31, 5.27, 5.22],
[6.23, 6.36, 6.51, 6.68, 6.87, 6.86, 6.85, 6.84, 6.83, 6.82, 6.80, 6.79, 6.78, 6.76, 6.75, 6.73, 6.71, 6.70, 6.68, 6.66, 6.64, 6.62, 6.60, 6.58, 6.56, 6.54, 6.52, 6.50, 6.48, 6.45, 6.43, 6.40, 6.38, 6.35, 6.33, 6.30, 6.27, 6.25, 6.22, 6.19, 6.16, 6.13, 6.10, 6.07, 6.04, 6.01, 5.97, 5.94, 5.91, 5.87, 5.84, 5.80, 5.77, 5.73, 5.69, 5.66, 5.62, 5.58, 5.54, 5.50, 5.46, 5.42, 5.38, 5.34, 5.30],
[6.30, 6.43, 6.58, 6.76, 6.95, 6.94, 6.93, 6.91, 6.90, 6.89, 6.88, 6.86, 6.85, 6.83, 6.82, 6.80, 6.79, 6.77, 6.75, 6.73, 6.72, 6.70, 6.68, 6.66, 6.64, 6.62, 6.59, 6.57, 6.55, 6.53, 6.50, 6.48, 6.45, 6.43, 6.40, 6.37, 6.35, 6.32, 6.29, 6.26, 6.23, 6.20, 6.17, 6.14, 6.11, 6.08, 6.05, 6.01, 5.98, 5.95, 5.91, 5.88, 5.84, 5.80, 5.77, 5.73, 5.69, 5.65, 5.62, 5.58, 5.54, 5.50, 5.45, 5.41, 5.37],
[6.38, 6.51, 6.66, 6.83, 7.02, 7.01, 7.00, 6.99, 6.98, 6.96, 6.95, 6.94, 6.92, 6.91, 6.89, 6.88, 6.86, 6.84, 6.83, 6.81, 6.79, 6.77, 6.75, 6.73, 6.71, 6.69, 6.67, 6.65, 6.62, 6.60, 6.58, 6.55, 6.53, 6.50, 6.47, 6.45, 6.42, 6.39, 6.37, 6.34, 6.31, 6.28, 6.25, 6.22, 6.19, 6.15, 6.12, 6.09, 6.05, 6.02, 5.99, 5.95, 5.91, 5.88, 5.84, 5.80, 5.77, 5.73, 5.69, 5.65, 5.61, 5.57, 5.53, 5.49, 5.44],
[6.45, 6.58, 6.73, 6.90, 7.09, 7.08, 7.07, 7.06, 7.05, 7.04, 7.02, 7.01, 7.00, 6.98, 6.97, 6.95, 6.93, 6.92, 6.90, 6.88, 6.86, 6.84, 6.83, 6.80, 6.78, 6.76, 6.74, 6.72, 6.70, 6.67, 6.65, 6.62, 6.60, 6.57, 6.55, 6.52, 6.50, 6.47, 6.44, 6.41, 6.38, 6.35, 6.32, 6.29, 6.26, 6.23, 6.19, 6.16, 6.13, 6.09, 6.06, 6.02, 5.99, 5.95, 5.92, 5.88, 5.84, 5.80, 5.76, 5.72, 5.68, 5.64, 5.60, 5.56, 5.52],
[6.53, 6.66, 6.81, 6.98, 7.17, 7.16, 7.15, 7.14, 7.12, 7.11, 7.10, 7.08, 7.07, 7.06, 7.04, 7.02, 7.01, 6.99, 6.97, 6.96, 6.94, 6.92, 6.90, 6.88, 6.86, 6.84, 6.82, 6.79, 6.77, 6.75, 6.72, 6.70, 6.67, 6.65, 6.62, 6.60, 6.57, 6.54, 6.51, 6.48, 6.46, 6.43, 6.40, 6.36, 6.33, 6.30, 6.27, 6.24, 6.20, 6.17, 6.13, 6.10, 6.06, 6.03, 5.99, 5.95, 5.92, 5.88, 5.84, 5.80, 5.76, 5.72, 5.68, 5.64, 5.59],
[6.60, 6.73, 6.88, 7.05, 7.24, 7.23, 7.22, 7.21, 7.20, 7.19, 7.17, 7.16, 7.15, 7.13, 7.12, 7.10, 7.08, 7.07, 7.05, 7.03, 7.01, 6.99, 6.97, 6.95, 6.93, 6.91, 6.89, 6.87, 6.85, 6.82, 6.80, 6.77, 6.75, 6.72, 6.70, 6.67, 6.64, 6.62, 6.59, 6.56, 6.53, 6.50, 6.47, 6.44, 6.41, 6.38, 6.34, 6.31, 6.28, 6.24, 6.21, 6.17, 6.14, 6.10, 6.06, 6.03, 5.99, 5.95, 5.91, 5.87, 5.83, 5.79, 5.75, 5.71, 5.67],
[6.68, 6.81, 6.96, 7.13, 7.32, 7.31, 7.30, 7.29, 7.27, 7.26, 7.25, 7.23, 7.22, 7.21, 7.19, 7.17, 7.16, 7.14, 7.12, 7.11, 7.09, 7.07, 7.05, 7.03, 7.01, 6.99, 6.97, 6.94, 6.92, 6.90, 6.87, 6.85, 6.82, 6.80, 6.77, 6.75, 6.72, 6.69, 6.66, 6.63, 6.61, 6.58, 6.55, 6.51, 6.48, 6.45, 6.42, 6.39, 6.35, 6.32, 6.28, 6.25, 6.21, 6.18, 6.14, 6.10, 6.06, 6.03, 5.99, 5.95, 5.91, 5.87, 5.83, 5.79, 5.74],
[6.75, 6.88, 7.03, 7.20, 7.39, 7.38, 7.37, 7.36, 7.35, 7.34, 7.32, 7.31, 7.30, 7.28, 7.27, 7.25, 7.23, 7.22, 7.20, 7.18, 7.16, 7.14, 7.12, 7.10, 7.08, 7.06, 7.04, 7.02, 7.00, 6.97, 6.95, 6.92, 6.90, 6.87, 6.85, 6.82, 6.79, 6.77, 6.74, 6.71, 6.68, 6.65, 6.62, 6.59, 6.56, 6.53, 6.49, 6.46, 6.43, 6.39, 6.36, 6.32, 6.29, 6.25, 6.22, 6.18, 6.14, 6.10, 6.06, 6.02, 5.98, 5.94, 5.90, 5.86, 5.82],
[6.83, 6.96, 7.11, 7.28, 7.47, 7.46, 7.45, 7.44, 7.43, 7.41, 7.40, 7.39, 7.37, 7.36, 7.34, 7.33, 7.31, 7.29, 7.28, 7.26, 7.24, 7.22, 7.20, 7.18, 7.16, 7.14, 7.12, 7.09, 7.07, 7.05, 7.02, 7.00, 6.98, 6.95, 6.92, 6.90, 6.87, 6.84, 6.81, 6.79, 6.76, 6.73, 6.70, 6.67, 6.63, 6.60, 6.57, 6.54, 6.50, 6.47, 6.44, 6.40, 6.36, 6.33, 6.29, 6.25, 6.22, 6.18, 6.14, 6.10, 6.06, 6.02, 5.98, 5.94, 5.89],
[6.90, 7.03, 7.18, 7.36, 7.55, 7.54, 7.52, 7.51, 7.50, 7.49, 7.48, 7.46, 7.45, 7.43, 7.42, 7.40, 7.39, 7.37, 7.35, 7.33, 7.32, 7.30, 7.28, 7.26, 7.24, 7.22, 7.19, 7.17, 7.15, 7.13, 7.10, 7.08, 7.05, 7.03, 7.00, 6.97, 6.95, 6.92, 6.89, 6.86, 6.83, 6.80, 6.77, 6.74, 6.71, 6.68, 6.65, 6.61, 6.58, 6.55, 6.51, 6.48, 6.44, 6.40, 6.37, 6.33, 6.29, 6.25, 6.22, 6.18, 6.14, 6.10, 6.05, 6.01, 5.97],
[6.98, 7.11, 7.26, 7.43, 7.62, 7.61, 7.60, 7.59, 7.58, 7.57, 7.55, 7.54, 7.52, 7.51, 7.49, 7.48, 7.46, 7.45, 7.43, 7.41, 7.39, 7.37, 7.35, 7.33, 7.31, 7.29, 7.27, 7.25, 7.22, 7.20, 7.18, 7.15, 7.13, 7.10, 7.08, 7.05, 7.02, 7.00, 6.97, 6.94, 6.91, 6.88, 6.85, 6.82, 6.79, 6.76, 6.72, 6.69, 6.66, 6.62, 6.59, 6.55, 6.52, 6.48, 6.44, 6.41, 6.37, 6.33, 6.29, 6.25, 6.21, 6.17, 6.13, 6.09, 6.05],
[7.06, 7.19, 7.34, 7.51, 7.70, 7.69, 7.68, 7.67, 7.66, 7.64, 7.63, 7.62, 7.60, 7.59, 7.57, 7.56, 7.54, 7.52, 7.51, 7.49, 7.47, 7.45, 7.43, 7.41, 7.39, 7.37, 7.35, 7.32, 7.30, 7.28, 7.25, 7.23, 7.21, 7.18, 7.15, 7.13, 7.10, 7.07, 7.04, 7.02, 6.99, 6.96, 6.93, 6.90, 6.86, 6.83, 6.80, 6.77, 6.73, 6.70, 6.67, 6.63, 6.59, 6.56, 6.52, 6.48, 6.45, 6.41, 6.37, 6.33, 6.29, 6.25, 6.21, 6.17, 6.12]];

NHANESIIIPredData.FVC.female =
[[2.97, 2.98, 2.98, 2.98, 2.99, 2.99, 2.99, 2.99, 2.99, 2.99, 2.98, 2.98, 2.98, 2.97, 2.97, 2.96, 2.95, 2.95, 2.94, 2.93, 2.92, 2.91, 2.90, 2.88, 2.87, 2.86, 2.84, 2.83, 2.81, 2.79, 2.78, 2.76, 2.74, 2.72, 2.70, 2.68, 2.65, 2.63, 2.61, 2.58, 2.56, 2.53, 2.51, 2.48, 2.45, 2.42, 2.39, 2.36, 2.33, 2.30, 2.26, 2.23, 2.20, 2.16, 2.12, 2.09, 2.05, 2.01, 1.97, 1.93, 1.89, 1.85, 1.81],
[3.01, 3.02, 3.02, 3.03, 3.03, 3.03, 3.03, 3.03, 3.03, 3.03, 3.03, 3.02, 3.02, 3.01, 3.01, 3.00, 3.00, 2.99, 2.98, 2.97, 2.96, 2.95, 2.94, 2.93, 2.91, 2.90, 2.89, 2.87, 2.85, 2.84, 2.82, 2.80, 2.78, 2.76, 2.74, 2.72, 2.70, 2.67, 2.65, 2.63, 2.60, 2.58, 2.55, 2.52, 2.49, 2.46, 2.43, 2.40, 2.37, 2.34, 2.31, 2.27, 2.24, 2.20, 2.17, 2.13, 2.09, 2.06, 2.02, 1.98, 1.94, 1.90, 1.85],
[3.06, 3.06, 3.07, 3.07, 3.07, 3.07, 3.07, 3.07, 3.07, 3.07, 3.07, 3.07, 3.06, 3.06, 3.05, 3.05, 3.04, 3.03, 3.02, 3.01, 3.00, 2.99, 2.98, 2.97, 2.96, 2.94, 2.93, 2.91, 2.90, 2.88, 2.86, 2.84, 2.83, 2.81, 2.78, 2.76, 2.74, 2.72, 2.69, 2.67, 2.64, 2.62, 2.59, 2.56, 2.54, 2.51, 2.48, 2.45, 2.42, 2.38, 2.35, 2.32, 2.28, 2.25, 2.21, 2.17, 2.14, 2.10, 2.06, 2.02, 1.98, 1.94, 1.90],
[3.10, 3.11, 3.11, 3.11, 3.12, 3.12, 3.12, 3.12, 3.12, 3.12, 3.11, 3.11, 3.11, 3.10, 3.10, 3.09, 3.08, 3.08, 3.07, 3.06, 3.05, 3.04, 3.03, 3.01, 3.00, 2.99, 2.97, 2.96, 2.94, 2.92, 2.91, 2.89, 2.87, 2.85, 2.83, 2.81, 2.78, 2.76, 2.74, 2.71, 2.69, 2.66, 2.64, 2.61, 2.58, 2.55, 2.52, 2.49, 2.46, 2.43, 2.39, 2.36, 2.33, 2.29, 2.26, 2.22, 2.18, 2.14, 2.10, 2.06, 2.02, 1.98, 1.94],
[3.15, 3.15, 3.15, 3.16, 3.16, 3.16, 3.16, 3.16, 3.16, 3.16, 3.16, 3.15, 3.15, 3.15, 3.14, 3.13, 3.13, 3.12, 3.11, 3.10, 3.09, 3.08, 3.07, 3.06, 3.04, 3.03, 3.02, 3.00, 2.98, 2.97, 2.95, 2.93, 2.91, 2.89, 2.87, 2.85, 2.83, 2.81, 2.78, 2.76, 2.73, 2.71, 2.68, 2.65, 2.62, 2.60, 2.57, 2.53, 2.50, 2.47, 2.44, 2.40, 2.37, 2.34, 2.30, 2.26, 2.23, 2.19, 2.15, 2.11, 2.07, 2.03, 1.98],
[3.19, 3.19, 3.20, 3.20, 3.20, 3.21, 3.21, 3.21, 3.21, 3.20, 3.20, 3.20, 3.19, 3.19, 3.18, 3.18, 3.17, 3.16, 3.16, 3.15, 3.14, 3.13, 3.11, 3.10, 3.09, 3.08, 3.06, 3.05, 3.03, 3.01, 2.99, 2.98, 2.96, 2.94, 2.92, 2.90, 2.87, 2.85, 2.83, 2.80, 2.78, 2.75, 2.72, 2.70, 2.67, 2.64, 2.61, 2.58, 2.55, 2.52, 2.48, 2.45, 2.41, 2.38, 2.34, 2.31, 2.27, 2.23, 2.19, 2.15, 2.11, 2.07, 2.03],
[3.23, 3.24, 3.24, 3.25, 3.25, 3.25, 3.25, 3.25, 3.25, 3.25, 3.25, 3.24, 3.24, 3.23, 3.23, 3.22, 3.22, 3.21, 3.20, 3.19, 3.18, 3.17, 3.16, 3.15, 3.13, 3.12, 3.11, 3.09, 3.07, 3.06, 3.04, 3.02, 3.00, 2.98, 2.96, 2.94, 2.92, 2.89, 2.87, 2.85, 2.82, 2.80, 2.77, 2.74, 2.71, 2.68, 2.65, 2.62, 2.59, 2.56, 2.53, 2.49, 2.46, 2.42, 2.39, 2.35, 2.31, 2.28, 2.24, 2.20, 2.16, 2.12, 2.07],
[3.28, 3.28, 3.29, 3.29, 3.29, 3.29, 3.30, 3.30, 3.29, 3.29, 3.29, 3.29, 3.28, 3.28, 3.27, 3.27, 3.26, 3.25, 3.24, 3.24, 3.23, 3.22, 3.20, 3.19, 3.18, 3.16, 3.15, 3.13, 3.12, 3.10, 3.08, 3.07, 3.05, 3.03, 3.01, 2.98, 2.96, 2.94, 2.92, 2.89, 2.87, 2.84, 2.81, 2.79, 2.76, 2.73, 2.70, 2.67, 2.64, 2.60, 2.57, 2.54, 2.50, 2.47, 2.43, 2.40, 2.36, 2.32, 2.28, 2.24, 2.20, 2.16, 2.12],
[3.32, 3.33, 3.33, 3.34, 3.34, 3.34, 3.34, 3.34, 3.34, 3.34, 3.34, 3.33, 3.33, 3.32, 3.32, 3.31, 3.31, 3.30, 3.29, 3.28, 3.27, 3.26, 3.25, 3.24, 3.22, 3.21, 3.20, 3.18, 3.16, 3.15, 3.13, 3.11, 3.09, 3.07, 3.05, 3.03, 3.01, 2.98, 2.96, 2.94, 2.91, 2.89, 2.86, 2.83, 2.80, 2.77, 2.74, 2.71, 2.68, 2.65, 2.62, 2.58, 2.55, 2.51, 2.48, 2.44, 2.40, 2.37, 2.33, 2.29, 2.25, 2.21, 2.16],
[3.37, 3.37, 3.38, 3.38, 3.38, 3.39, 3.39, 3.39, 3.39, 3.38, 3.38, 3.38, 3.37, 3.37, 3.36, 3.36, 3.35, 3.34, 3.34, 3.33, 3.32, 3.31, 3.29, 3.28, 3.27, 3.26, 3.24, 3.23, 3.21, 3.19, 3.17, 3.16, 3.14, 3.12, 3.10, 3.08, 3.05, 3.03, 3.01, 2.98, 2.96, 2.93, 2.90, 2.88, 2.85, 2.82, 2.79, 2.76, 2.73, 2.70, 2.66, 2.63, 2.59, 2.56, 2.52, 2.49, 2.45, 2.41, 2.37, 2.33, 2.29, 2.25, 2.21],
[3.42, 3.42, 3.42, 3.43, 3.43, 3.43, 3.43, 3.43, 3.43, 3.43, 3.43, 3.42, 3.42, 3.42, 3.41, 3.40, 3.40, 3.39, 3.38, 3.37, 3.36, 3.35, 3.34, 3.33, 3.31, 3.30, 3.29, 3.27, 3.26, 3.24, 3.22, 3.20, 3.18, 3.16, 3.14, 3.12, 3.10, 3.08, 3.05, 3.03, 3.00, 2.98, 2.95, 2.92, 2.89, 2.87, 2.84, 2.80, 2.77, 2.74, 2.71, 2.67, 2.64, 2.61, 2.57, 2.53, 2.50, 2.46, 2.42, 2.38, 2.34, 2.30, 2.25],
[3.46, 3.47, 3.47, 3.47, 3.48, 3.48, 3.48, 3.48, 3.48, 3.48, 3.47, 3.47, 3.47, 3.46, 3.46, 3.45, 3.44, 3.44, 3.43, 3.42, 3.41, 3.40, 3.39, 3.37, 3.36, 3.35, 3.33, 3.32, 3.30, 3.28, 3.27, 3.25, 3.23, 3.21, 3.19, 3.17, 3.15, 3.12, 3.10, 3.07, 3.05, 3.02, 3.00, 2.97, 2.94, 2.91, 2.88, 2.85, 2.82, 2.79, 2.75, 2.72, 2.69, 2.65, 2.62, 2.58, 2.54, 2.50, 2.46, 2.42, 2.38, 2.34, 2.30],
[3.51, 3.51, 3.52, 3.52, 3.52, 3.52, 3.52, 3.52, 3.52, 3.52, 3.52, 3.52, 3.51, 3.51, 3.50, 3.50, 3.49, 3.48, 3.47, 3.46, 3.45, 3.44, 3.43, 3.42, 3.41, 3.39, 3.38, 3.36, 3.35, 3.33, 3.31, 3.29, 3.28, 3.26, 3.24, 3.21, 3.19, 3.17, 3.14, 3.12, 3.10, 3.07, 3.04, 3.02, 2.99, 2.96, 2.93, 2.90, 2.87, 2.83, 2.80, 2.77, 2.73, 2.70, 2.66, 2.63, 2.59, 2.55, 2.51, 2.47, 2.43, 2.39, 2.35],
[3.56, 3.56, 3.56, 3.57, 3.57, 3.57, 3.57, 3.57, 3.57, 3.57, 3.57, 3.56, 3.56, 3.56, 3.55, 3.54, 3.54, 3.53, 3.52, 3.51, 3.50, 3.49, 3.48, 3.47, 3.45, 3.44, 3.43, 3.41, 3.39, 3.38, 3.36, 3.34, 3.32, 3.30, 3.28, 3.26, 3.24, 3.22, 3.19, 3.17, 3.14, 3.12, 3.09, 3.06, 3.03, 3.00, 2.97, 2.94, 2.91, 2.88, 2.85, 2.81, 2.78, 2.74, 2.71, 2.67, 2.63, 2.60, 2.56, 2.52, 2.48, 2.44, 2.39],
[3.60, 3.61, 3.61, 3.61, 3.62, 3.62, 3.62, 3.62, 3.62, 3.62, 3.61, 3.61, 3.61, 3.60, 3.60, 3.59, 3.58, 3.58, 3.57, 3.56, 3.55, 3.54, 3.53, 3.51, 3.50, 3.49, 3.47, 3.46, 3.44, 3.42, 3.41, 3.39, 3.37, 3.35, 3.33, 3.31, 3.29, 3.26, 3.24, 3.21, 3.19, 3.16, 3.14, 3.11, 3.08, 3.05, 3.02, 2.99, 2.96, 2.93, 2.89, 2.86, 2.83, 2.79, 2.76, 2.72, 2.68, 2.64, 2.60, 2.56, 2.52, 2.48, 2.44],
[3.65, 3.65, 3.66, 3.66, 3.66, 3.66, 3.67, 3.67, 3.66, 3.66, 3.66, 3.66, 3.65, 3.65, 3.64, 3.64, 3.63, 3.62, 3.61, 3.61, 3.60, 3.58, 3.57, 3.56, 3.55, 3.53, 3.52, 3.50, 3.49, 3.47, 3.45, 3.44, 3.42, 3.40, 3.38, 3.35, 3.33, 3.31, 3.29, 3.26, 3.24, 3.21, 3.18, 3.16, 3.13, 3.10, 3.07, 3.04, 3.01, 2.97, 2.94, 2.91, 2.87, 2.84, 2.80, 2.77, 2.73, 2.69, 2.65, 2.61, 2.57, 2.53, 2.49],
[3.70, 3.70, 3.71, 3.71, 3.71, 3.71, 3.71, 3.71, 3.71, 3.71, 3.71, 3.71, 3.70, 3.70, 3.69, 3.69, 3.68, 3.67, 3.66, 3.65, 3.64, 3.63, 3.62, 3.61, 3.60, 3.58, 3.57, 3.55, 3.54, 3.52, 3.50, 3.48, 3.46, 3.44, 3.42, 3.40, 3.38, 3.36, 3.33, 3.31, 3.28, 3.26, 3.23, 3.20, 3.18, 3.15, 3.12, 3.09, 3.05, 3.02, 2.99, 2.96, 2.92, 2.89, 2.85, 2.81, 2.78, 2.74, 2.70, 2.66, 2.62, 2.58, 2.54],
[3.74, 3.75, 3.75, 3.76, 3.76, 3.76, 3.76, 3.76, 3.76, 3.76, 3.76, 3.75, 3.75, 3.74, 3.74, 3.73, 3.73, 3.72, 3.71, 3.70, 3.69, 3.68, 3.67, 3.66, 3.64, 3.63, 3.62, 3.60, 3.58, 3.57, 3.55, 3.53, 3.51, 3.49, 3.47, 3.45, 3.43, 3.40, 3.38, 3.36, 3.33, 3.31, 3.28, 3.25, 3.22, 3.19, 3.16, 3.13, 3.10, 3.07, 3.04, 3.00, 2.97, 2.93, 2.90, 2.86, 2.82, 2.79, 2.75, 2.71, 2.67, 2.63, 2.58],
[3.79, 3.80, 3.80, 3.80, 3.81, 3.81, 3.81, 3.81, 3.81, 3.81, 3.80, 3.80, 3.80, 3.79, 3.79, 3.78, 3.77, 3.77, 3.76, 3.75, 3.74, 3.73, 3.72, 3.70, 3.69, 3.68, 3.66, 3.65, 3.63, 3.62, 3.60, 3.58, 3.56, 3.54, 3.52, 3.50, 3.48, 3.45, 3.43, 3.40, 3.38, 3.35, 3.33, 3.30, 3.27, 3.24, 3.21, 3.18, 3.15, 3.12, 3.09, 3.05, 3.02, 2.98, 2.95, 2.91, 2.87, 2.83, 2.79, 2.76, 2.71, 2.67, 2.63],
[3.84, 3.85, 3.85, 3.85, 3.86, 3.86, 3.86, 3.86, 3.86, 3.86, 3.85, 3.85, 3.85, 3.84, 3.84, 3.83, 3.82, 3.82, 3.81, 3.80, 3.79, 3.78, 3.77, 3.75, 3.74, 3.73, 3.71, 3.70, 3.68, 3.66, 3.65, 3.63, 3.61, 3.59, 3.57, 3.55, 3.52, 3.50, 3.48, 3.45, 3.43, 3.40, 3.38, 3.35, 3.32, 3.29, 3.26, 3.23, 3.20, 3.17, 3.13, 3.10, 3.07, 3.03, 2.99, 2.96, 2.92, 2.88, 2.84, 2.80, 2.76, 2.72, 2.68],
[3.89, 3.89, 3.90, 3.90, 3.90, 3.91, 3.91, 3.91, 3.91, 3.90, 3.90, 3.90, 3.89, 3.89, 3.88, 3.88, 3.87, 3.86, 3.86, 3.85, 3.84, 3.83, 3.81, 3.80, 3.79, 3.78, 3.76, 3.75, 3.73, 3.71, 3.69, 3.68, 3.66, 3.64, 3.62, 3.60, 3.57, 3.55, 3.53, 3.50, 3.48, 3.45, 3.42, 3.40, 3.37, 3.34, 3.31, 3.28, 3.25, 3.22, 3.18, 3.15, 3.11, 3.08, 3.04, 3.01, 2.97, 2.93, 2.89, 2.85, 2.81, 2.77, 2.73],
[3.94, 3.94, 3.95, 3.95, 3.95, 3.95, 3.96, 3.96, 3.95, 3.95, 3.95, 3.95, 3.94, 3.94, 3.93, 3.93, 3.92, 3.91, 3.90, 3.90, 3.89, 3.87, 3.86, 3.85, 3.84, 3.82, 3.81, 3.79, 3.78, 3.76, 3.74, 3.73, 3.71, 3.69, 3.67, 3.64, 3.62, 3.60, 3.58, 3.55, 3.53, 3.50, 3.47, 3.45, 3.42, 3.39, 3.36, 3.33, 3.30, 3.26, 3.23, 3.20, 3.16, 3.13, 3.09, 3.06, 3.02, 2.98, 2.94, 2.90, 2.86, 2.82, 2.78],
[3.99, 3.99, 4.00, 4.00, 4.00, 4.00, 4.00, 4.00, 4.00, 4.00, 4.00, 4.00, 3.99, 3.99, 3.98, 3.98, 3.97, 3.96, 3.95, 3.94, 3.93, 3.92, 3.91, 3.90, 3.89, 3.87, 3.86, 3.84, 3.83, 3.81, 3.79, 3.77, 3.76, 3.74, 3.72, 3.69, 3.67, 3.65, 3.63, 3.60, 3.58, 3.55, 3.52, 3.50, 3.47, 3.44, 3.41, 3.38, 3.35, 3.31, 3.28, 3.25, 3.21, 3.18, 3.14, 3.11, 3.07, 3.03, 2.99, 2.95, 2.91, 2.87, 2.83],
[4.04, 4.04, 4.05, 4.05, 4.05, 4.05, 4.05, 4.05, 4.05, 4.05, 4.05, 4.05, 4.04, 4.04, 4.03, 4.03, 4.02, 4.01, 4.00, 3.99, 3.98, 3.97, 3.96, 3.95, 3.94, 3.92, 3.91, 3.89, 3.88, 3.86, 3.84, 3.82, 3.81, 3.79, 3.76, 3.74, 3.72, 3.70, 3.67, 3.65, 3.62, 3.60, 3.57, 3.54, 3.52, 3.49, 3.46, 3.43, 3.40, 3.36, 3.33, 3.30, 3.26, 3.23, 3.19, 3.15, 3.12, 3.08, 3.04, 3.00, 2.96, 2.92, 2.88],
[4.09, 4.09, 4.10, 4.10, 4.10, 4.10, 4.10, 4.10, 4.10, 4.10, 4.10, 4.10, 4.09, 4.09, 4.08, 4.08, 4.07, 4.06, 4.05, 4.04, 4.03, 4.02, 4.01, 4.00, 3.99, 3.97, 3.96, 3.94, 3.93, 3.91, 3.89, 3.87, 3.86, 3.84, 3.81, 3.79, 3.77, 3.75, 3.72, 3.70, 3.67, 3.65, 3.62, 3.59, 3.57, 3.54, 3.51, 3.48, 3.45, 3.41, 3.38, 3.35, 3.31, 3.28, 3.24, 3.20, 3.17, 3.13, 3.09, 3.05, 3.01, 2.97, 2.93],
[4.14, 4.14, 4.15, 4.15, 4.15, 4.15, 4.15, 4.15, 4.15, 4.15, 4.15, 4.15, 4.14, 4.14, 4.13, 4.13, 4.12, 4.11, 4.10, 4.09, 4.08, 4.07, 4.06, 4.05, 4.04, 4.02, 4.01, 3.99, 3.98, 3.96, 3.94, 3.92, 3.91, 3.89, 3.87, 3.84, 3.82, 3.80, 3.77, 3.75, 3.73, 3.70, 3.67, 3.64, 3.62, 3.59, 3.56, 3.53, 3.50, 3.46, 3.43, 3.40, 3.36, 3.33, 3.29, 3.25, 3.22, 3.18, 3.14, 3.10, 3.06, 3.02, 2.98],
[4.19, 4.19, 4.20, 4.20, 4.20, 4.20, 4.20, 4.20, 4.20, 4.20, 4.20, 4.20, 4.19, 4.19, 4.18, 4.18, 4.17, 4.16, 4.15, 4.14, 4.14, 4.12, 4.11, 4.10, 4.09, 4.07, 4.06, 4.04, 4.03, 4.01, 3.99, 3.98, 3.96, 3.94, 3.92, 3.89, 3.87, 3.85, 3.83, 3.80, 3.78, 3.75, 3.72, 3.70, 3.67, 3.64, 3.61, 3.58, 3.55, 3.51, 3.48, 3.45, 3.41, 3.38, 3.34, 3.31, 3.27, 3.23, 3.19, 3.15, 3.11, 3.07, 3.03],
[4.24, 4.24, 4.25, 4.25, 4.25, 4.25, 4.26, 4.26, 4.25, 4.25, 4.25, 4.25, 4.24, 4.24, 4.23, 4.23, 4.22, 4.21, 4.20, 4.20, 4.19, 4.18, 4.16, 4.15, 4.14, 4.12, 4.11, 4.09, 4.08, 4.06, 4.04, 4.03, 4.01, 3.99, 3.97, 3.94, 3.92, 3.90, 3.88, 3.85, 3.83, 3.80, 3.77, 3.75, 3.72, 3.69, 3.66, 3.63, 3.60, 3.56, 3.53, 3.50, 3.46, 3.43, 3.39, 3.36, 3.32, 3.28, 3.24, 3.20, 3.16, 3.12, 3.08],
[4.29, 4.30, 4.30, 4.30, 4.30, 4.31, 4.31, 4.31, 4.31, 4.30, 4.30, 4.30, 4.30, 4.29, 4.29, 4.28, 4.27, 4.26, 4.26, 4.25, 4.24, 4.23, 4.21, 4.20, 4.19, 4.18, 4.16, 4.15, 4.13, 4.11, 4.10, 4.08, 4.06, 4.04, 4.02, 4.00, 3.97, 3.95, 3.93, 3.90, 3.88, 3.85, 3.82, 3.80, 3.77, 3.74, 3.71, 3.68, 3.65, 3.62, 3.58, 3.55, 3.52, 3.48, 3.44, 3.41, 3.37, 3.33, 3.29, 3.25, 3.21, 3.17, 3.13],
[4.34, 4.35, 4.35, 4.35, 4.36, 4.36, 4.36, 4.36, 4.36, 4.36, 4.35, 4.35, 4.35, 4.34, 4.34, 4.33, 4.32, 4.32, 4.31, 4.30, 4.29, 4.28, 4.27, 4.25, 4.24, 4.23, 4.21, 4.20, 4.18, 4.16, 4.15, 4.13, 4.11, 4.09, 4.07, 4.05, 4.03, 4.00, 3.98, 3.95, 3.93, 3.90, 3.88, 3.85, 3.82, 3.79, 3.76, 3.73, 3.70, 3.67, 3.63, 3.60, 3.57, 3.53, 3.50, 3.46, 3.42, 3.38, 3.34, 3.30, 3.26, 3.22, 3.18],
[4.39, 4.40, 4.40, 4.41, 4.41, 4.41, 4.41, 4.41, 4.41, 4.41, 4.41, 4.40, 4.40, 4.39, 4.39, 4.38, 4.38, 4.37, 4.36, 4.35, 4.34, 4.33, 4.32, 4.31, 4.29, 4.28, 4.26, 4.25, 4.23, 4.22, 4.20, 4.18, 4.16, 4.14, 4.12, 4.10, 4.08, 4.05, 4.03, 4.01, 3.98, 3.95, 3.93, 3.90, 3.87, 3.84, 3.81, 3.78, 3.75, 3.72, 3.69, 3.65, 3.62, 3.58, 3.55, 3.51, 3.47, 3.43, 3.40, 3.36, 3.32, 3.27, 3.23],
[4.45, 4.45, 4.45, 4.46, 4.46, 4.46, 4.46, 4.46, 4.46, 4.46, 4.46, 4.45, 4.45, 4.45, 4.44, 4.43, 4.43, 4.42, 4.41, 4.40, 4.39, 4.38, 4.37, 4.36, 4.34, 4.33, 4.32, 4.30, 4.28, 4.27, 4.25, 4.23, 4.21, 4.19, 4.17, 4.15, 4.13, 4.11, 4.08, 4.06, 4.03, 4.01, 3.98, 3.95, 3.92, 3.90, 3.87, 3.83, 3.80, 3.77, 3.74, 3.70, 3.67, 3.64, 3.60, 3.56, 3.53, 3.49, 3.45, 3.41, 3.37, 3.33, 3.28],
[4.50, 4.50, 4.51, 4.51, 4.51, 4.51, 4.51, 4.51, 4.51, 4.51, 4.51, 4.51, 4.50, 4.50, 4.49, 4.49, 4.48, 4.47, 4.46, 4.45, 4.44, 4.43, 4.42, 4.41, 4.40, 4.38, 4.37, 4.35, 4.34, 4.32, 4.30, 4.28, 4.27, 4.25, 4.22, 4.20, 4.18, 4.16, 4.13, 4.11, 4.08, 4.06, 4.03, 4.00, 3.98, 3.95, 3.92, 3.89, 3.86, 3.82, 3.79, 3.76, 3.72, 3.69, 3.65, 3.61, 3.58, 3.54, 3.50, 3.46, 3.42, 3.38, 3.34],
[4.55, 4.56, 4.56, 4.56, 4.56, 4.57, 4.57, 4.57, 4.57, 4.56, 4.56, 4.56, 4.56, 4.55, 4.55, 4.54, 4.53, 4.52, 4.52, 4.51, 4.50, 4.49, 4.47, 4.46, 4.45, 4.44, 4.42, 4.41, 4.39, 4.37, 4.36, 4.34, 4.32, 4.30, 4.28, 4.26, 4.23, 4.21, 4.19, 4.16, 4.14, 4.11, 4.08, 4.06, 4.03, 4.00, 3.97, 3.94, 3.91, 3.88, 3.84, 3.81, 3.78, 3.74, 3.70, 3.67, 3.63, 3.59, 3.55, 3.51, 3.47, 3.43, 3.39],
[4.60, 4.61, 4.61, 4.62, 4.62, 4.62, 4.62, 4.62, 4.62, 4.62, 4.61, 4.61, 4.61, 4.60, 4.60, 4.59, 4.59, 4.58, 4.57, 4.56, 4.55, 4.54, 4.53, 4.52, 4.50, 4.49, 4.47, 4.46, 4.44, 4.43, 4.41, 4.39, 4.37, 4.35, 4.33, 4.31, 4.29, 4.26, 4.24, 4.22, 4.19, 4.16, 4.14, 4.11, 4.08, 4.05, 4.02, 3.99, 3.96, 3.93, 3.90, 3.86, 3.83, 3.79, 3.76, 3.72, 3.68, 3.64, 3.61, 3.57, 3.53, 3.48, 3.44],
[4.66, 4.66, 4.67, 4.67, 4.67, 4.67, 4.67, 4.67, 4.67, 4.67, 4.67, 4.67, 4.66, 4.66, 4.65, 4.65, 4.64, 4.63, 4.62, 4.61, 4.60, 4.59, 4.58, 4.57, 4.56, 4.54, 4.53, 4.51, 4.50, 4.48, 4.46, 4.44, 4.42, 4.40, 4.38, 4.36, 4.34, 4.32, 4.29, 4.27, 4.24, 4.22, 4.19, 4.16, 4.14, 4.11, 4.08, 4.05, 4.01, 3.98, 3.95, 3.92, 3.88, 3.85, 3.81, 3.77, 3.74, 3.70, 3.66, 3.62, 3.58, 3.54, 3.50],
[4.71, 4.71, 4.72, 4.72, 4.72, 4.73, 4.73, 4.73, 4.73, 4.72, 4.72, 4.72, 4.71, 4.71, 4.70, 4.70, 4.69, 4.68, 4.68, 4.67, 4.66, 4.65, 4.63, 4.62, 4.61, 4.60, 4.58, 4.57, 4.55, 4.53, 4.52, 4.50, 4.48, 4.46, 4.44, 4.42, 4.39, 4.37, 4.35, 4.32, 4.30, 4.27, 4.24, 4.22, 4.19, 4.16, 4.13, 4.10, 4.07, 4.04, 4.00, 3.97, 3.93, 3.90, 3.86, 3.83, 3.79, 3.75, 3.71, 3.67, 3.63, 3.59, 3.55],
[4.76, 4.77, 4.77, 4.78, 4.78, 4.78, 4.78, 4.78, 4.78, 4.78, 4.78, 4.77, 4.77, 4.76, 4.76, 4.75, 4.75, 4.74, 4.73, 4.72, 4.71, 4.70, 4.69, 4.68, 4.66, 4.65, 4.63, 4.62, 4.60, 4.59, 4.57, 4.55, 4.53, 4.51, 4.49, 4.47, 4.45, 4.42, 4.40, 4.38, 4.35, 4.32, 4.30, 4.27, 4.24, 4.21, 4.18, 4.15, 4.12, 4.09, 4.06, 4.02, 3.99, 3.95, 3.92, 3.88, 3.84, 3.81, 3.77, 3.73, 3.69, 3.64, 3.60],
[4.82, 4.82, 4.83, 4.83, 4.83, 4.83, 4.83, 4.83, 4.83, 4.83, 4.83, 4.83, 4.82, 4.82, 4.81, 4.81, 4.80, 4.79, 4.78, 4.77, 4.76, 4.75, 4.74, 4.73, 4.72, 4.70, 4.69, 4.67, 4.66, 4.64, 4.62, 4.60, 4.59, 4.57, 4.54, 4.52, 4.50, 4.48, 4.45, 4.43, 4.40, 4.38, 4.35, 4.32, 4.30, 4.27, 4.24, 4.21, 4.18, 4.14, 4.11, 4.08, 4.04, 4.01, 3.97, 3.93, 3.90, 3.86, 3.82, 3.78, 3.74, 3.70, 3.66],
[4.87, 4.88, 4.88, 4.88, 4.89, 4.89, 4.89, 4.89, 4.89, 4.89, 4.88, 4.88, 4.88, 4.87, 4.87, 4.86, 4.85, 4.85, 4.84, 4.83, 4.82, 4.81, 4.80, 4.78, 4.77, 4.76, 4.74, 4.73, 4.71, 4.69, 4.68, 4.66, 4.64, 4.62, 4.60, 4.58, 4.56, 4.53, 4.51, 4.48, 4.46, 4.43, 4.41, 4.38, 4.35, 4.32, 4.29, 4.26, 4.23, 4.20, 4.16, 4.13, 4.10, 4.06, 4.03, 3.99, 3.95, 3.91, 3.87, 3.83, 3.79, 3.75, 3.71],
[4.93, 4.93, 4.94, 4.94, 4.94, 4.94, 4.94, 4.94, 4.94, 4.94, 4.94, 4.94, 4.93, 4.93, 4.92, 4.92, 4.91, 4.90, 4.89, 4.88, 4.87, 4.86, 4.85, 4.84, 4.83, 4.81, 4.80, 4.78, 4.77, 4.75, 4.73, 4.71, 4.69, 4.67, 4.65, 4.63, 4.61, 4.59, 4.56, 4.54, 4.51, 4.49, 4.46, 4.43, 4.41, 4.38, 4.35, 4.32, 4.28, 4.25, 4.22, 4.19, 4.15, 4.12, 4.08, 4.04, 4.01, 3.97, 3.93, 3.89, 3.85, 3.81, 3.77],
[4.98, 4.99, 4.99, 4.99, 5.00, 5.00, 5.00, 5.00, 5.00, 5.00, 4.99, 4.99, 4.99, 4.98, 4.98, 4.97, 4.96, 4.96, 4.95, 4.94, 4.93, 4.92, 4.91, 4.89, 4.88, 4.87, 4.85, 4.84, 4.82, 4.80, 4.79, 4.77, 4.75, 4.73, 4.71, 4.69, 4.67, 4.64, 4.62, 4.59, 4.57, 4.54, 4.52, 4.49, 4.46, 4.43, 4.40, 4.37, 4.34, 4.31, 4.27, 4.24, 4.21, 4.17, 4.14, 4.10, 4.06, 4.02, 3.98, 3.94, 3.90, 3.86, 3.82],
[5.04, 5.04, 5.05, 5.05, 5.05, 5.05, 5.05, 5.05, 5.05, 5.05, 5.05, 5.05, 5.04, 5.04, 5.03, 5.03, 5.02, 5.01, 5.00, 4.99, 4.98, 4.97, 4.96, 4.95, 4.94, 4.92, 4.91, 4.89, 4.88, 4.86, 4.84, 4.82, 4.80, 4.78, 4.76, 4.74, 4.72, 4.70, 4.67, 4.65, 4.62, 4.60, 4.57, 4.54, 4.52, 4.49, 4.46, 4.43, 4.39, 4.36, 4.33, 4.30, 4.26, 4.23, 4.19, 4.15, 4.12, 4.08, 4.04, 4.00, 3.96, 3.92, 3.88],
[5.09, 5.10, 5.10, 5.10, 5.11, 5.11, 5.11, 5.11, 5.11, 5.11, 5.10, 5.10, 5.10, 5.09, 5.09, 5.08, 5.07, 5.07, 5.06, 5.05, 5.04, 5.03, 5.02, 5.00, 4.99, 4.98, 4.96, 4.95, 4.93, 4.92, 4.90, 4.88, 4.86, 4.84, 4.82, 4.80, 4.78, 4.75, 4.73, 4.70, 4.68, 4.65, 4.63, 4.60, 4.57, 4.54, 4.51, 4.48, 4.45, 4.42, 4.39, 4.35, 4.32, 4.28, 4.25, 4.21, 4.17, 4.13, 4.09, 4.06, 4.01, 3.97, 3.93],
[5.15, 5.15, 5.16, 5.16, 5.16, 5.16, 5.16, 5.16, 5.16, 5.16, 5.16, 5.16, 5.15, 5.15, 5.14, 5.14, 5.13, 5.12, 5.11, 5.11, 5.10, 5.08, 5.07, 5.06, 5.05, 5.03, 5.02, 5.00, 4.99, 4.97, 4.95, 4.94, 4.92, 4.90, 4.88, 4.85, 4.83, 4.81, 4.79, 4.76, 4.74, 4.71, 4.68, 4.66, 4.63, 4.60, 4.57, 4.54, 4.51, 4.47, 4.44, 4.41, 4.37, 4.34, 4.30, 4.27, 4.23, 4.19, 4.15, 4.11, 4.07, 4.03, 3.99]];

NHANESIIIPredData.FEV1 = {};

NHANESIIIPredData.FEV1.male =
[[2.79, 2.90, 3.01, 3.14, 3.27, 3.25, 3.23, 3.21, 3.19, 3.17, 3.14, 3.12, 3.10, 3.08, 3.05, 3.03, 3.01, 2.98, 2.96, 2.93, 2.91, 2.88, 2.86, 2.83, 2.80, 2.78, 2.75, 2.72, 2.69, 2.67, 2.64, 2.61, 2.58, 2.55, 2.52, 2.49, 2.46, 2.43, 2.39, 2.36, 2.33, 2.30, 2.27, 2.23, 2.20, 2.17, 2.13, 2.10, 2.06, 2.03, 1.99, 1.95, 1.92, 1.88, 1.85, 1.81, 1.77, 1.73, 1.69, 1.66, 1.62, 1.58, 1.54, 1.50, 1.46],
[2.83, 2.94, 3.05, 3.18, 3.31, 3.29, 3.27, 3.25, 3.23, 3.21, 3.19, 3.16, 3.14, 3.12, 3.10, 3.07, 3.05, 3.02, 3.00, 2.97, 2.95, 2.92, 2.90, 2.87, 2.85, 2.82, 2.79, 2.76, 2.74, 2.71, 2.68, 2.65, 2.62, 2.59, 2.56, 2.53, 2.50, 2.47, 2.44, 2.40, 2.37, 2.34, 2.31, 2.27, 2.24, 2.21, 2.17, 2.14, 2.10, 2.07, 2.03, 2.00, 1.96, 1.92, 1.89, 1.85, 1.81, 1.77, 1.74, 1.70, 1.66, 1.62, 1.58, 1.54, 1.50],
[2.87, 2.98, 3.10, 3.22, 3.35, 3.33, 3.31, 3.29, 3.27, 3.25, 3.23, 3.21, 3.18, 3.16, 3.14, 3.11, 3.09, 3.07, 3.04, 3.02, 2.99, 2.97, 2.94, 2.91, 2.89, 2.86, 2.83, 2.81, 2.78, 2.75, 2.72, 2.69, 2.66, 2.63, 2.60, 2.57, 2.54, 2.51, 2.48, 2.45, 2.41, 2.38, 2.35, 2.32, 2.28, 2.25, 2.21, 2.18, 2.15, 2.11, 2.07, 2.04, 2.00, 1.97, 1.93, 1.89, 1.85, 1.82, 1.78, 1.74, 1.70, 1.66, 1.62, 1.58, 1.54],
[2.92, 3.02, 3.14, 3.26, 3.40, 3.38, 3.36, 3.33, 3.31, 3.29, 3.27, 3.25, 3.23, 3.20, 3.18, 3.16, 3.13, 3.11, 3.08, 3.06, 3.03, 3.01, 2.98, 2.96, 2.93, 2.90, 2.87, 2.85, 2.82, 2.79, 2.76, 2.73, 2.70, 2.67, 2.64, 2.61, 2.58, 2.55, 2.52, 2.49, 2.46, 2.42, 2.39, 2.36, 2.32, 2.29, 2.26, 2.22, 2.19, 2.15, 2.12, 2.08, 2.04, 2.01, 1.97, 1.93, 1.90, 1.86, 1.82, 1.78, 1.74, 1.70, 1.66, 1.62, 1.58],
[2.96, 3.07, 3.18, 3.31, 3.44, 3.42, 3.40, 3.38, 3.36, 3.33, 3.31, 3.29, 3.27, 3.25, 3.22, 3.20, 3.17, 3.15, 3.13, 3.10, 3.08, 3.05, 3.02, 3.00, 2.97, 2.94, 2.92, 2.89, 2.86, 2.83, 2.80, 2.78, 2.75, 2.72, 2.69, 2.66, 2.63, 2.59, 2.56, 2.53, 2.50, 2.47, 2.43, 2.40, 2.37, 2.33, 2.30, 2.26, 2.23, 2.19, 2.16, 2.12, 2.09, 2.05, 2.01, 1.98, 1.94, 1.90, 1.86, 1.82, 1.78, 1.74, 1.71, 1.67, 1.62],
[3.00, 3.11, 3.22, 3.35, 3.48, 3.46, 3.44, 3.42, 3.40, 3.38, 3.36, 3.33, 3.31, 3.29, 3.27, 3.24, 3.22, 3.19, 3.17, 3.14, 3.12, 3.09, 3.07, 3.04, 3.01, 2.99, 2.96, 2.93, 2.90, 2.88, 2.85, 2.82, 2.79, 2.76, 2.73, 2.70, 2.67, 2.64, 2.61, 2.57, 2.54, 2.51, 2.48, 2.44, 2.41, 2.38, 2.34, 2.31, 2.27, 2.24, 2.20, 2.17, 2.13, 2.09, 2.06, 2.02, 1.98, 1.94, 1.90, 1.87, 1.83, 1.79, 1.75, 1.71, 1.67],
[3.04, 3.15, 3.27, 3.39, 3.52, 3.50, 3.48, 3.46, 3.44, 3.42, 3.40, 3.38, 3.35, 3.33, 3.31, 3.28, 3.26, 3.24, 3.21, 3.19, 3.16, 3.14, 3.11, 3.08, 3.06, 3.03, 3.00, 2.98, 2.95, 2.92, 2.89, 2.86, 2.83, 2.80, 2.77, 2.74, 2.71, 2.68, 2.65, 2.62, 2.58, 2.55, 2.52, 2.49, 2.45, 2.42, 2.38, 2.35, 2.32, 2.28, 2.24, 2.21, 2.17, 2.14, 2.10, 2.06, 2.02, 1.99, 1.95, 1.91, 1.87, 1.83, 1.79, 1.75, 1.71],
[3.09, 3.19, 3.31, 3.43, 3.57, 3.55, 3.53, 3.51, 3.49, 3.46, 3.44, 3.42, 3.40, 3.37, 3.35, 3.33, 3.30, 3.28, 3.26, 3.23, 3.21, 3.18, 3.15, 3.13, 3.10, 3.07, 3.05, 3.02, 2.99, 2.96, 2.93, 2.90, 2.88, 2.85, 2.82, 2.79, 2.75, 2.72, 2.69, 2.66, 2.63, 2.60, 2.56, 2.53, 2.50, 2.46, 2.43, 2.39, 2.36, 2.32, 2.29, 2.25, 2.22, 2.18, 2.14, 2.10, 2.07, 2.03, 1.99, 1.95, 1.91, 1.87, 1.83, 1.79, 1.75],
[3.13, 3.24, 3.35, 3.48, 3.61, 3.59, 3.57, 3.55, 3.53, 3.51, 3.49, 3.46, 3.44, 3.42, 3.39, 3.37, 3.35, 3.32, 3.30, 3.27, 3.25, 3.22, 3.20, 3.17, 3.14, 3.12, 3.09, 3.06, 3.03, 3.01, 2.98, 2.95, 2.92, 2.89, 2.86, 2.83, 2.80, 2.77, 2.74, 2.70, 2.67, 2.64, 2.61, 2.57, 2.54, 2.51, 2.47, 2.44, 2.40, 2.37, 2.33, 2.30, 2.26, 2.22, 2.19, 2.15, 2.11, 2.07, 2.03, 2.00, 1.96, 1.92, 1.88, 1.84, 1.80],
[3.17, 3.28, 3.40, 3.52, 3.66, 3.64, 3.61, 3.59, 3.57, 3.55, 3.53, 3.51, 3.48, 3.46, 3.44, 3.42, 3.39, 3.37, 3.34, 3.32, 3.29, 3.27, 3.24, 3.21, 3.19, 3.16, 3.13, 3.11, 3.08, 3.05, 3.02, 2.99, 2.96, 2.93, 2.90, 2.87, 2.84, 2.81, 2.78, 2.75, 2.72, 2.68, 2.65, 2.62, 2.58, 2.55, 2.52, 2.48, 2.45, 2.41, 2.38, 2.34, 2.30, 2.27, 2.23, 2.19, 2.15, 2.12, 2.08, 2.04, 2.00, 1.96, 1.92, 1.88, 1.84],
[3.22, 3.33, 3.44, 3.57, 3.70, 3.68, 3.66, 3.64, 3.62, 3.60, 3.57, 3.55, 3.53, 3.51, 3.48, 3.46, 3.44, 3.41, 3.39, 3.36, 3.34, 3.31, 3.29, 3.26, 3.23, 3.21, 3.18, 3.15, 3.12, 3.09, 3.07, 3.04, 3.01, 2.98, 2.95, 2.92, 2.89, 2.85, 2.82, 2.79, 2.76, 2.73, 2.69, 2.66, 2.63, 2.59, 2.56, 2.53, 2.49, 2.45, 2.42, 2.38, 2.35, 2.31, 2.27, 2.24, 2.20, 2.16, 2.12, 2.08, 2.04, 2.01, 1.97, 1.93, 1.89],
[3.26, 3.37, 3.49, 3.61, 3.74, 3.72, 3.70, 3.68, 3.66, 3.64, 3.62, 3.60, 3.57, 3.55, 3.53, 3.50, 3.48, 3.46, 3.43, 3.41, 3.38, 3.36, 3.33, 3.30, 3.28, 3.25, 3.22, 3.19, 3.17, 3.14, 3.11, 3.08, 3.05, 3.02, 2.99, 2.96, 2.93, 2.90, 2.87, 2.84, 2.80, 2.77, 2.74, 2.71, 2.67, 2.64, 2.60, 2.57, 2.53, 2.50, 2.46, 2.43, 2.39, 2.36, 2.32, 2.28, 2.24, 2.21, 2.17, 2.13, 2.09, 2.05, 2.01, 1.97, 1.93],
[3.31, 3.41, 3.53, 3.65, 3.79, 3.77, 3.75, 3.73, 3.71, 3.68, 3.66, 3.64, 3.62, 3.60, 3.57, 3.55, 3.52, 3.50, 3.48, 3.45, 3.43, 3.40, 3.37, 3.35, 3.32, 3.29, 3.27, 3.24, 3.21, 3.18, 3.15, 3.13, 3.10, 3.07, 3.04, 3.01, 2.98, 2.94, 2.91, 2.88, 2.85, 2.82, 2.78, 2.75, 2.72, 2.68, 2.65, 2.61, 2.58, 2.54, 2.51, 2.47, 2.44, 2.40, 2.36, 2.33, 2.29, 2.25, 2.21, 2.17, 2.13, 2.09, 2.05, 2.01, 1.97],
[3.35, 3.46, 3.58, 3.70, 3.83, 3.81, 3.79, 3.77, 3.75, 3.73, 3.71, 3.69, 3.66, 3.64, 3.62, 3.59, 3.57, 3.55, 3.52, 3.50, 3.47, 3.45, 3.42, 3.39, 3.37, 3.34, 3.31, 3.28, 3.26, 3.23, 3.20, 3.17, 3.14, 3.11, 3.08, 3.05, 3.02, 2.99, 2.96, 2.93, 2.89, 2.86, 2.83, 2.80, 2.76, 2.73, 2.69, 2.66, 2.62, 2.59, 2.55, 2.52, 2.48, 2.44, 2.41, 2.37, 2.33, 2.29, 2.26, 2.22, 2.18, 2.14, 2.10, 2.06, 2.02],
[3.40, 3.50, 3.62, 3.75, 3.88, 3.86, 3.84, 3.82, 3.80, 3.77, 3.75, 3.73, 3.71, 3.69, 3.66, 3.64, 3.61, 3.59, 3.57, 3.54, 3.52, 3.49, 3.46, 3.44, 3.41, 3.38, 3.36, 3.33, 3.30, 3.27, 3.24, 3.22, 3.19, 3.16, 3.13, 3.10, 3.07, 3.03, 3.00, 2.97, 2.94, 2.91, 2.87, 2.84, 2.81, 2.77, 2.74, 2.70, 2.67, 2.63, 2.60, 2.56, 2.53, 2.49, 2.45, 2.42, 2.38, 2.34, 2.30, 2.26, 2.22, 2.18, 2.15, 2.11, 2.06],
[3.44, 3.55, 3.67, 3.79, 3.92, 3.90, 3.88, 3.86, 3.84, 3.82, 3.80, 3.78, 3.75, 3.73, 3.71, 3.68, 3.66, 3.64, 3.61, 3.59, 3.56, 3.54, 3.51, 3.48, 3.46, 3.43, 3.40, 3.38, 3.35, 3.32, 3.29, 3.26, 3.23, 3.20, 3.17, 3.14, 3.11, 3.08, 3.05, 3.02, 2.98, 2.95, 2.92, 2.89, 2.85, 2.82, 2.78, 2.75, 2.72, 2.68, 2.64, 2.61, 2.57, 2.54, 2.50, 2.46, 2.42, 2.39, 2.35, 2.31, 2.27, 2.23, 2.19, 2.15, 2.11],
[3.49, 3.60, 3.71, 3.84, 3.97, 3.95, 3.93, 3.91, 3.89, 3.87, 3.84, 3.82, 3.80, 3.78, 3.75, 3.73, 3.71, 3.68, 3.66, 3.63, 3.61, 3.58, 3.56, 3.53, 3.50, 3.48, 3.45, 3.42, 3.39, 3.36, 3.34, 3.31, 3.28, 3.25, 3.22, 3.19, 3.16, 3.13, 3.09, 3.06, 3.03, 3.00, 2.96, 2.93, 2.90, 2.86, 2.83, 2.80, 2.76, 2.73, 2.69, 2.65, 2.62, 2.58, 2.54, 2.51, 2.47, 2.43, 2.39, 2.35, 2.32, 2.28, 2.24, 2.20, 2.16],
[3.54, 3.64, 3.76, 3.88, 4.02, 4.00, 3.98, 3.95, 3.93, 3.91, 3.89, 3.87, 3.85, 3.82, 3.80, 3.78, 3.75, 3.73, 3.70, 3.68, 3.65, 3.63, 3.60, 3.58, 3.55, 3.52, 3.49, 3.47, 3.44, 3.41, 3.38, 3.35, 3.32, 3.29, 3.26, 3.23, 3.20, 3.17, 3.14, 3.11, 3.08, 3.04, 3.01, 2.98, 2.94, 2.91, 2.88, 2.84, 2.81, 2.77, 2.74, 2.70, 2.66, 2.63, 2.59, 2.55, 2.52, 2.48, 2.44, 2.40, 2.36, 2.32, 2.28, 2.24, 2.20],
[3.58, 3.69, 3.80, 3.93, 4.06, 4.04, 4.02, 4.00, 3.98, 3.96, 3.94, 3.91, 3.89, 3.87, 3.85, 3.82, 3.80, 3.77, 3.75, 3.73, 3.70, 3.67, 3.65, 3.62, 3.60, 3.57, 3.54, 3.51, 3.49, 3.46, 3.43, 3.40, 3.37, 3.34, 3.31, 3.28, 3.25, 3.22, 3.19, 3.15, 3.12, 3.09, 3.06, 3.02, 2.99, 2.96, 2.92, 2.89, 2.85, 2.82, 2.78, 2.75, 2.71, 2.67, 2.64, 2.60, 2.56, 2.52, 2.49, 2.45, 2.41, 2.37, 2.33, 2.29, 2.25],
[3.63, 3.74, 3.85, 3.98, 4.11, 4.09, 4.07, 4.05, 4.03, 4.01, 3.98, 3.96, 3.94, 3.92, 3.89, 3.87, 3.85, 3.82, 3.80, 3.77, 3.75, 3.72, 3.69, 3.67, 3.64, 3.62, 3.59, 3.56, 3.53, 3.50, 3.48, 3.45, 3.42, 3.39, 3.36, 3.33, 3.30, 3.26, 3.23, 3.20, 3.17, 3.14, 3.10, 3.07, 3.04, 3.00, 2.97, 2.93, 2.90, 2.86, 2.83, 2.79, 2.76, 2.72, 2.68, 2.65, 2.61, 2.57, 2.53, 2.49, 2.45, 2.42, 2.38, 2.34, 2.30],
[3.68, 3.78, 3.90, 4.02, 4.16, 4.14, 4.12, 4.09, 4.07, 4.05, 4.03, 4.01, 3.99, 3.96, 3.94, 3.92, 3.89, 3.87, 3.84, 3.82, 3.79, 3.77, 3.74, 3.72, 3.69, 3.66, 3.63, 3.61, 3.58, 3.55, 3.52, 3.49, 3.46, 3.43, 3.40, 3.37, 3.34, 3.31, 3.28, 3.25, 3.22, 3.18, 3.15, 3.12, 3.08, 3.05, 3.02, 2.98, 2.95, 2.91, 2.88, 2.84, 2.80, 2.77, 2.73, 2.69, 2.66, 2.62, 2.58, 2.54, 2.50, 2.46, 2.42, 2.38, 2.34],
[3.72, 3.83, 3.95, 4.07, 4.20, 4.18, 4.16, 4.14, 4.12, 4.10, 4.08, 4.06, 4.03, 4.01, 3.99, 3.96, 3.94, 3.92, 3.89, 3.87, 3.84, 3.82, 3.79, 3.76, 3.74, 3.71, 3.68, 3.65, 3.63, 3.60, 3.57, 3.54, 3.51, 3.48, 3.45, 3.42, 3.39, 3.36, 3.33, 3.30, 3.26, 3.23, 3.20, 3.17, 3.13, 3.10, 3.06, 3.03, 2.99, 2.96, 2.92, 2.89, 2.85, 2.81, 2.78, 2.74, 2.70, 2.66, 2.63, 2.59, 2.55, 2.51, 2.47, 2.43, 2.39],
[3.77, 3.88, 3.99, 4.12, 4.25, 4.23, 4.21, 4.19, 4.17, 4.15, 4.13, 4.10, 4.08, 4.06, 4.03, 4.01, 3.99, 3.96, 3.94, 3.91, 3.89, 3.86, 3.84, 3.81, 3.78, 3.76, 3.73, 3.70, 3.67, 3.65, 3.62, 3.59, 3.56, 3.53, 3.50, 3.47, 3.44, 3.41, 3.37, 3.34, 3.31, 3.28, 3.25, 3.21, 3.18, 3.15, 3.11, 3.08, 3.04, 3.01, 2.97, 2.94, 2.90, 2.86, 2.83, 2.79, 2.75, 2.71, 2.67, 2.64, 2.60, 2.56, 2.52, 2.48, 2.44],
[3.82, 3.92, 4.04, 4.17, 4.30, 4.28, 4.26, 4.24, 4.22, 4.19, 4.17, 4.15, 4.13, 4.11, 4.08, 4.06, 4.03, 4.01, 3.99, 3.96, 3.94, 3.91, 3.88, 3.86, 3.83, 3.80, 3.78, 3.75, 3.72, 3.69, 3.66, 3.64, 3.61, 3.58, 3.55, 3.52, 3.49, 3.45, 3.42, 3.39, 3.36, 3.33, 3.29, 3.26, 3.23, 3.19, 3.16, 3.12, 3.09, 3.05, 3.02, 2.98, 2.95, 2.91, 2.87, 2.84, 2.80, 2.76, 2.72, 2.68, 2.64, 2.60, 2.57, 2.53, 2.48],
[3.87, 3.97, 4.09, 4.21, 4.35, 4.33, 4.31, 4.29, 4.26, 4.24, 4.22, 4.20, 4.18, 4.15, 4.13, 4.11, 4.08, 4.06, 4.03, 4.01, 3.98, 3.96, 3.93, 3.91, 3.88, 3.85, 3.83, 3.80, 3.77, 3.74, 3.71, 3.68, 3.65, 3.62, 3.59, 3.56, 3.53, 3.50, 3.47, 3.44, 3.41, 3.37, 3.34, 3.31, 3.27, 3.24, 3.21, 3.17, 3.14, 3.10, 3.07, 3.03, 2.99, 2.96, 2.92, 2.88, 2.85, 2.81, 2.77, 2.73, 2.69, 2.65, 2.61, 2.57, 2.53],
[3.91, 4.02, 4.14, 4.26, 4.39, 4.37, 4.35, 4.33, 4.31, 4.29, 4.27, 4.25, 4.22, 4.20, 4.18, 4.16, 4.13, 4.11, 4.08, 4.06, 4.03, 4.01, 3.98, 3.95, 3.93, 3.90, 3.87, 3.85, 3.82, 3.79, 3.76, 3.73, 3.70, 3.67, 3.64, 3.61, 3.58, 3.55, 3.52, 3.49, 3.46, 3.42, 3.39, 3.36, 3.32, 3.29, 3.26, 3.22, 3.19, 3.15, 3.12, 3.08, 3.04, 3.01, 2.97, 2.93, 2.89, 2.86, 2.82, 2.78, 2.74, 2.70, 2.66, 2.62, 2.58],
[3.96, 4.07, 4.19, 4.31, 4.44, 4.42, 4.40, 4.38, 4.36, 4.34, 4.32, 4.30, 4.27, 4.25, 4.23, 4.20, 4.18, 4.16, 4.13, 4.11, 4.08, 4.06, 4.03, 4.00, 3.98, 3.95, 3.92, 3.89, 3.87, 3.84, 3.81, 3.78, 3.75, 3.72, 3.69, 3.66, 3.63, 3.60, 3.57, 3.54, 3.50, 3.47, 3.44, 3.41, 3.37, 3.34, 3.30, 3.27, 3.23, 3.20, 3.16, 3.13, 3.09, 3.06, 3.02, 2.98, 2.94, 2.91, 2.87, 2.83, 2.79, 2.75, 2.71, 2.67, 2.63],
[4.01, 4.12, 4.23, 4.36, 4.49, 4.47, 4.45, 4.43, 4.41, 4.39, 4.37, 4.34, 4.32, 4.30, 4.28, 4.25, 4.23, 4.20, 4.18, 4.16, 4.13, 4.10, 4.08, 4.05, 4.03, 4.00, 3.97, 3.94, 3.92, 3.89, 3.86, 3.83, 3.80, 3.77, 3.74, 3.71, 3.68, 3.65, 3.62, 3.58, 3.55, 3.52, 3.49, 3.45, 3.42, 3.39, 3.35, 3.32, 3.28, 3.25, 3.21, 3.18, 3.14, 3.10, 3.07, 3.03, 2.99, 2.95, 2.92, 2.88, 2.84, 2.80, 2.76, 2.72, 2.68],
[4.06, 4.17, 4.28, 4.41, 4.54, 4.52, 4.50, 4.48, 4.46, 4.44, 4.42, 4.39, 4.37, 4.35, 4.33, 4.30, 4.28, 4.25, 4.23, 4.20, 4.18, 4.15, 4.13, 4.10, 4.07, 4.05, 4.02, 3.99, 3.96, 3.94, 3.91, 3.88, 3.85, 3.82, 3.79, 3.76, 3.73, 3.70, 3.67, 3.63, 3.60, 3.57, 3.54, 3.50, 3.47, 3.44, 3.40, 3.37, 3.33, 3.30, 3.26, 3.23, 3.19, 3.15, 3.12, 3.08, 3.04, 3.00, 2.97, 2.93, 2.89, 2.85, 2.81, 2.77, 2.73],
[4.11, 4.22, 4.33, 4.46, 4.59, 4.57, 4.55, 4.53, 4.51, 4.49, 4.47, 4.44, 4.42, 4.40, 4.37, 4.35, 4.33, 4.30, 4.28, 4.25, 4.23, 4.20, 4.18, 4.15, 4.12, 4.10, 4.07, 4.04, 4.01, 3.99, 3.96, 3.93, 3.90, 3.87, 3.84, 3.81, 3.78, 3.75, 3.72, 3.68, 3.65, 3.62, 3.59, 3.55, 3.52, 3.49, 3.45, 3.42, 3.38, 3.35, 3.31, 3.28, 3.24, 3.20, 3.17, 3.13, 3.09, 3.05, 3.01, 2.98, 2.94, 2.90, 2.86, 2.82, 2.78],
[4.16, 4.27, 4.38, 4.51, 4.64, 4.62, 4.60, 4.58, 4.56, 4.54, 4.52, 4.49, 4.47, 4.45, 4.42, 4.40, 4.38, 4.35, 4.33, 4.30, 4.28, 4.25, 4.23, 4.20, 4.17, 4.15, 4.12, 4.09, 4.06, 4.04, 4.01, 3.98, 3.95, 3.92, 3.89, 3.86, 3.83, 3.80, 3.77, 3.73, 3.70, 3.67, 3.64, 3.60, 3.57, 3.54, 3.50, 3.47, 3.43, 3.40, 3.36, 3.33, 3.29, 3.25, 3.22, 3.18, 3.14, 3.10, 3.06, 3.03, 2.99, 2.95, 2.91, 2.87, 2.83],
[4.21, 4.32, 4.43, 4.56, 4.69, 4.67, 4.65, 4.63, 4.61, 4.59, 4.57, 4.54, 4.52, 4.50, 4.47, 4.45, 4.43, 4.40, 4.38, 4.35, 4.33, 4.30, 4.28, 4.25, 4.22, 4.20, 4.17, 4.14, 4.11, 4.09, 4.06, 4.03, 4.00, 3.97, 3.94, 3.91, 3.88, 3.85, 3.82, 3.78, 3.75, 3.72, 3.69, 3.65, 3.62, 3.59, 3.55, 3.52, 3.48, 3.45, 3.41, 3.38, 3.34, 3.30, 3.27, 3.23, 3.19, 3.15, 3.11, 3.08, 3.04, 3.00, 2.96, 2.92, 2.88],
[4.26, 4.37, 4.48, 4.61, 4.74, 4.72, 4.70, 4.68, 4.66, 4.64, 4.62, 4.59, 4.57, 4.55, 4.53, 4.50, 4.48, 4.45, 4.43, 4.40, 4.38, 4.35, 4.33, 4.30, 4.27, 4.25, 4.22, 4.19, 4.16, 4.14, 4.11, 4.08, 4.05, 4.02, 3.99, 3.96, 3.93, 3.90, 3.87, 3.83, 3.80, 3.77, 3.74, 3.70, 3.67, 3.64, 3.60, 3.57, 3.53, 3.50, 3.46, 3.43, 3.39, 3.35, 3.32, 3.28, 3.24, 3.20, 3.16, 3.13, 3.09, 3.05, 3.01, 2.97, 2.93],
[4.31, 4.42, 4.53, 4.66, 4.79, 4.77, 4.75, 4.73, 4.71, 4.69, 4.67, 4.64, 4.62, 4.60, 4.58, 4.55, 4.53, 4.50, 4.48, 4.45, 4.43, 4.40, 4.38, 4.35, 4.32, 4.30, 4.27, 4.24, 4.22, 4.19, 4.16, 4.13, 4.10, 4.07, 4.04, 4.01, 3.98, 3.95, 3.92, 3.88, 3.85, 3.82, 3.79, 3.75, 3.72, 3.69, 3.65, 3.62, 3.58, 3.55, 3.51, 3.48, 3.44, 3.40, 3.37, 3.33, 3.29, 3.25, 3.22, 3.18, 3.14, 3.10, 3.06, 3.02, 2.98],
[4.36, 4.47, 4.58, 4.71, 4.84, 4.82, 4.80, 4.78, 4.76, 4.74, 4.72, 4.70, 4.67, 4.65, 4.63, 4.60, 4.58, 4.55, 4.53, 4.51, 4.48, 4.45, 4.43, 4.40, 4.38, 4.35, 4.32, 4.29, 4.27, 4.24, 4.21, 4.18, 4.15, 4.12, 4.09, 4.06, 4.03, 4.00, 3.97, 3.94, 3.90, 3.87, 3.84, 3.80, 3.77, 3.74, 3.70, 3.67, 3.63, 3.60, 3.56, 3.53, 3.49, 3.45, 3.42, 3.38, 3.34, 3.30, 3.27, 3.23, 3.19, 3.15, 3.11, 3.07, 3.03],
[4.41, 4.52, 4.64, 4.76, 4.89, 4.87, 4.85, 4.83, 4.81, 4.79, 4.77, 4.75, 4.72, 4.70, 4.68, 4.65, 4.63, 4.61, 4.58, 4.56, 4.53, 4.51, 4.48, 4.45, 4.43, 4.40, 4.37, 4.35, 4.32, 4.29, 4.26, 4.23, 4.20, 4.17, 4.14, 4.11, 4.08, 4.05, 4.02, 3.99, 3.95, 3.92, 3.89, 3.86, 3.82, 3.79, 3.75, 3.72, 3.68, 3.65, 3.61, 3.58, 3.54, 3.51, 3.47, 3.43, 3.39, 3.36, 3.32, 3.28, 3.24, 3.20, 3.16, 3.12, 3.08],
[4.47, 4.57, 4.69, 4.81, 4.95, 4.93, 4.90, 4.88, 4.86, 4.84, 4.82, 4.80, 4.78, 4.75, 4.73, 4.71, 4.68, 4.66, 4.63, 4.61, 4.58, 4.56, 4.53, 4.51, 4.48, 4.45, 4.42, 4.40, 4.37, 4.34, 4.31, 4.28, 4.25, 4.22, 4.19, 4.16, 4.13, 4.10, 4.07, 4.04, 4.01, 3.97, 3.94, 3.91, 3.87, 3.84, 3.81, 3.77, 3.74, 3.70, 3.67, 3.63, 3.59, 3.56, 3.52, 3.48, 3.45, 3.41, 3.37, 3.33, 3.29, 3.25, 3.21, 3.17, 3.13],
[4.52, 4.62, 4.74, 4.86, 5.00, 4.98, 4.96, 4.94, 4.91, 4.89, 4.87, 4.85, 4.83, 4.80, 4.78, 4.76, 4.73, 4.71, 4.68, 4.66, 4.63, 4.61, 4.58, 4.56, 4.53, 4.50, 4.48, 4.45, 4.42, 4.39, 4.36, 4.33, 4.30, 4.28, 4.25, 4.21, 4.18, 4.15, 4.12, 4.09, 4.06, 4.03, 3.99, 3.96, 3.93, 3.89, 3.86, 3.82, 3.79, 3.75, 3.72, 3.68, 3.65, 3.61, 3.57, 3.53, 3.50, 3.46, 3.42, 3.38, 3.34, 3.30, 3.26, 3.22, 3.18],
[4.57, 4.68, 4.79, 4.92, 5.05, 5.03, 5.01, 4.99, 4.97, 4.95, 4.92, 4.90, 4.88, 4.86, 4.83, 4.81, 4.79, 4.76, 4.74, 4.71, 4.69, 4.66, 4.64, 4.61, 4.58, 4.56, 4.53, 4.50, 4.47, 4.44, 4.42, 4.39, 4.36, 4.33, 4.30, 4.27, 4.24, 4.20, 4.17, 4.14, 4.11, 4.08, 4.04, 4.01, 3.98, 3.94, 3.91, 3.88, 3.84, 3.80, 3.77, 3.73, 3.70, 3.66, 3.62, 3.59, 3.55, 3.51, 3.47, 3.43, 3.39, 3.36, 3.32, 3.28, 3.24],
[4.62, 4.73, 4.84, 4.97, 5.10, 5.08, 5.06, 5.04, 5.02, 5.00, 4.98, 4.95, 4.93, 4.91, 4.89, 4.86, 4.84, 4.81, 4.79, 4.76, 4.74, 4.71, 4.69, 4.66, 4.63, 4.61, 4.58, 4.55, 4.52, 4.50, 4.47, 4.44, 4.41, 4.38, 4.35, 4.32, 4.29, 4.26, 4.23, 4.19, 4.16, 4.13, 4.10, 4.06, 4.03, 4.00, 3.96, 3.93, 3.89, 3.86, 3.82, 3.79, 3.75, 3.71, 3.68, 3.64, 3.60, 3.56, 3.52, 3.49, 3.45, 3.41, 3.37, 3.33, 3.29],
[4.67, 4.78, 4.90, 5.02, 5.15, 5.13, 5.11, 5.09, 5.07, 5.05, 5.03, 5.01, 4.98, 4.96, 4.94, 4.91, 4.89, 4.87, 4.84, 4.82, 4.79, 4.77, 4.74, 4.71, 4.69, 4.66, 4.63, 4.61, 4.58, 4.55, 4.52, 4.49, 4.46, 4.43, 4.40, 4.37, 4.34, 4.31, 4.28, 4.25, 4.21, 4.18, 4.15, 4.12, 4.08, 4.05, 4.01, 3.98, 3.95, 3.91, 3.87, 3.84, 3.80, 3.77, 3.73, 3.69, 3.65, 3.62, 3.58, 3.54, 3.50, 3.46, 3.42, 3.38, 3.34],
[4.73, 4.83, 4.95, 5.07, 5.21, 5.19, 5.17, 5.15, 5.12, 5.10, 5.08, 5.06, 5.04, 5.01, 4.99, 4.97, 4.94, 4.92, 4.89, 4.87, 4.84, 4.82, 4.79, 4.77, 4.74, 4.71, 4.69, 4.66, 4.63, 4.60, 4.57, 4.54, 4.51, 4.48, 4.45, 4.42, 4.39, 4.36, 4.33, 4.30, 4.27, 4.23, 4.20, 4.17, 4.14, 4.10, 4.07, 4.03, 4.00, 3.96, 3.93, 3.89, 3.86, 3.82, 3.78, 3.74, 3.71, 3.67, 3.63, 3.59, 3.55, 3.51, 3.47, 3.43, 3.39],
[4.78, 4.89, 5.00, 5.13, 5.26, 5.24, 5.22, 5.20, 5.18, 5.16, 5.13, 5.11, 5.09, 5.07, 5.04, 5.02, 5.00, 4.97, 4.95, 4.92, 4.90, 4.87, 4.85, 4.82, 4.79, 4.77, 4.74, 4.71, 4.68, 4.65, 4.63, 4.60, 4.57, 4.54, 4.51, 4.48, 4.45, 4.42, 4.38, 4.35, 4.32, 4.29, 4.26, 4.22, 4.19, 4.15, 4.12, 4.09, 4.05, 4.02, 3.98, 3.94, 3.91, 3.87, 3.83, 3.80, 3.76, 3.72, 3.68, 3.64, 3.61, 3.57, 3.53, 3.49, 3.45],
[4.83, 4.94, 5.06, 5.18, 5.31, 5.29, 5.27, 5.25, 5.23, 5.21, 5.19, 5.17, 5.14, 5.12, 5.10, 5.07, 5.05, 5.03, 5.00, 4.98, 4.95, 4.93, 4.90, 4.87, 4.85, 4.82, 4.79, 4.76, 4.74, 4.71, 4.68, 4.65, 4.62, 4.59, 4.56, 4.53, 4.50, 4.47, 4.44, 4.41, 4.37, 4.34, 4.31, 4.28, 4.24, 4.21, 4.17, 4.14, 4.10, 4.07, 4.03, 4.00, 3.96, 3.93, 3.89, 3.85, 3.81, 3.78, 3.74, 3.70, 3.66, 3.62, 3.58, 3.54, 3.50],
[4.89, 4.99, 5.11, 5.23, 5.37, 5.35, 5.33, 5.31, 5.28, 5.26, 5.24, 5.22, 5.20, 5.17, 5.15, 5.13, 5.10, 5.08, 5.05, 5.03, 5.00, 4.98, 4.95, 4.93, 4.90, 4.87, 4.85, 4.82, 4.79, 4.76, 4.73, 4.70, 4.67, 4.65, 4.62, 4.58, 4.55, 4.52, 4.49, 4.46, 4.43, 4.40, 4.36, 4.33, 4.30, 4.26, 4.23, 4.19, 4.16, 4.12, 4.09, 4.05, 4.02, 3.98, 3.94, 3.90, 3.87, 3.83, 3.79, 3.75, 3.71, 3.67, 3.63, 3.59, 3.55],
[4.94, 5.05, 5.16, 5.29, 5.42, 5.40, 5.38, 5.36, 5.34, 5.32, 5.30, 5.27, 5.25, 5.23, 5.20, 5.18, 5.16, 5.13, 5.11, 5.08, 5.06, 5.03, 5.01, 4.98, 4.95, 4.93, 4.90, 4.87, 4.84, 4.82, 4.79, 4.76, 4.73, 4.70, 4.67, 4.64, 4.61, 4.58, 4.55, 4.51, 4.48, 4.45, 4.42, 4.38, 4.35, 4.32, 4.28, 4.25, 4.21, 4.18, 4.14, 4.11, 4.07, 4.03, 4.00, 3.96, 3.92, 3.88, 3.84, 3.81, 3.77, 3.73, 3.69, 3.65, 3.61],
[5.00, 5.10, 5.22, 5.34, 5.48, 5.46, 5.44, 5.41, 5.39, 5.37, 5.35, 5.33, 5.31, 5.28, 5.26, 5.24, 5.21, 5.19, 5.16, 5.14, 5.11, 5.09, 5.06, 5.04, 5.01, 4.98, 4.95, 4.93, 4.90, 4.87, 4.84, 4.81, 4.78, 4.75, 4.72, 4.69, 4.66, 4.63, 4.60, 4.57, 4.54, 4.50, 4.47, 4.44, 4.40, 4.37, 4.34, 4.30, 4.27, 4.23, 4.20, 4.16, 4.12, 4.09, 4.05, 4.01, 3.98, 3.94, 3.90, 3.86, 3.82, 3.78, 3.74, 3.70, 3.66],
[5.05, 5.16, 5.27, 5.40, 5.53, 5.51, 5.49, 5.47, 5.45, 5.43, 5.40, 5.38, 5.36, 5.34, 5.31, 5.29, 5.27, 5.24, 5.22, 5.19, 5.17, 5.14, 5.12, 5.09, 5.06, 5.04, 5.01, 4.98, 4.95, 4.92, 4.90, 4.87, 4.84, 4.81, 4.78, 4.75, 4.72, 4.69, 4.65, 4.62, 4.59, 4.56, 4.53, 4.49, 4.46, 4.42, 4.39, 4.36, 4.32, 4.29, 4.25, 4.21, 4.18, 4.14, 4.10, 4.07, 4.03, 3.99, 3.95, 3.91, 3.88, 3.84, 3.80, 3.76, 3.72],
[5.10, 5.21, 5.33, 5.45, 5.58, 5.56, 5.54, 5.52, 5.50, 5.48, 5.46, 5.44, 5.41, 5.39, 5.37, 5.35, 5.32, 5.30, 5.27, 5.25, 5.22, 5.20, 5.17, 5.14, 5.12, 5.09, 5.06, 5.04, 5.01, 4.98, 4.95, 4.92, 4.89, 4.86, 4.83, 4.80, 4.77, 4.74, 4.71, 4.68, 4.65, 4.61, 4.58, 4.55, 4.51, 4.48, 4.45, 4.41, 4.38, 4.34, 4.31, 4.27, 4.23, 4.20, 4.16, 4.12, 4.08, 4.05, 4.01, 3.97, 3.93, 3.89, 3.85, 3.81, 3.77],
[5.16, 5.27, 5.38, 5.51, 5.64, 5.62, 5.60, 5.58, 5.56, 5.54, 5.51, 5.49, 5.47, 5.45, 5.42, 5.40, 5.38, 5.35, 5.33, 5.30, 5.28, 5.25, 5.23, 5.20, 5.17, 5.15, 5.12, 5.09, 5.06, 5.03, 5.01, 4.98, 4.95, 4.92, 4.89, 4.86, 4.83, 4.80, 4.76, 4.73, 4.70, 4.67, 4.64, 4.60, 4.57, 4.53, 4.50, 4.47, 4.43, 4.40, 4.36, 4.32, 4.29, 4.25, 4.21, 4.18, 4.14, 4.10, 4.06, 4.02, 3.99, 3.95, 3.91, 3.87, 3.83],
[5.22, 5.32, 5.44, 5.56, 5.70, 5.68, 5.65, 5.63, 5.61, 5.59, 5.57, 5.55, 5.53, 5.50, 5.48, 5.46, 5.43, 5.41, 5.38, 5.36, 5.33, 5.31, 5.28, 5.26, 5.23, 5.20, 5.17, 5.15, 5.12, 5.09, 5.06, 5.03, 5.00, 4.97, 4.94, 4.91, 4.88, 4.85, 4.82, 4.79, 4.76, 4.72, 4.69, 4.66, 4.62, 4.59, 4.56, 4.52, 4.49, 4.45, 4.42, 4.38, 4.34, 4.31, 4.27, 4.23, 4.20, 4.16, 4.12, 4.08, 4.04, 4.00, 3.96, 3.92, 3.88],
[5.27, 5.38, 5.49, 5.62, 5.75, 5.73, 5.71, 5.69, 5.67, 5.65, 5.63, 5.60, 5.58, 5.56, 5.53, 5.51, 5.49, 5.46, 5.44, 5.41, 5.39, 5.36, 5.34, 5.31, 5.28, 5.26, 5.23, 5.20, 5.17, 5.15, 5.12, 5.09, 5.06, 5.03, 5.00, 4.97, 4.94, 4.91, 4.88, 4.84, 4.81, 4.78, 4.75, 4.71, 4.68, 4.65, 4.61, 4.58, 4.54, 4.51, 4.47, 4.44, 4.40, 4.36, 4.33, 4.29, 4.25, 4.21, 4.17, 4.14, 4.10, 4.06, 4.02, 3.98, 3.94],
[5.33, 5.43, 5.55, 5.67, 5.81, 5.79, 5.77, 5.75, 5.72, 5.70, 5.68, 5.66, 5.64, 5.61, 5.59, 5.57, 5.54, 5.52, 5.49, 5.47, 5.44, 5.42, 5.39, 5.37, 5.34, 5.31, 5.29, 5.26, 5.23, 5.20, 5.17, 5.14, 5.11, 5.09, 5.06, 5.02, 4.99, 4.96, 4.93, 4.90, 4.87, 4.84, 4.80, 4.77, 4.74, 4.70, 4.67, 4.63, 4.60, 4.56, 4.53, 4.49, 4.46, 4.42, 4.38, 4.34, 4.31, 4.27, 4.23, 4.19, 4.15, 4.11, 4.07, 4.03, 3.99],
[5.38, 5.49, 5.61, 5.73, 5.86, 5.84, 5.82, 5.80, 5.78, 5.76, 5.74, 5.72, 5.69, 5.67, 5.65, 5.62, 5.60, 5.58, 5.55, 5.53, 5.50, 5.48, 5.45, 5.42, 5.40, 5.37, 5.34, 5.31, 5.29, 5.26, 5.23, 5.20, 5.17, 5.14, 5.11, 5.08, 5.05, 5.02, 4.99, 4.96, 4.92, 4.89, 4.86, 4.83, 4.79, 4.76, 4.72, 4.69, 4.65, 4.62, 4.58, 4.55, 4.51, 4.47, 4.44, 4.40, 4.36, 4.33, 4.29, 4.25, 4.21, 4.17, 4.13, 4.09, 4.05],
[5.44, 5.55, 5.66, 5.79, 5.92, 5.90, 5.88, 5.86, 5.84, 5.82, 5.79, 5.77, 5.75, 5.73, 5.70, 5.68, 5.66, 5.63, 5.61, 5.58, 5.56, 5.53, 5.51, 5.48, 5.45, 5.43, 5.40, 5.37, 5.34, 5.31, 5.29, 5.26, 5.23, 5.20, 5.17, 5.14, 5.11, 5.08, 5.04, 5.01, 4.98, 4.95, 4.91, 4.88, 4.85, 4.81, 4.78, 4.75, 4.71, 4.68, 4.64, 4.60, 4.57, 4.53, 4.49, 4.46, 4.42, 4.38, 4.34, 4.30, 4.27, 4.23, 4.19, 4.15, 4.11],
[5.50, 5.60, 5.72, 5.84, 5.98, 5.96, 5.94, 5.92, 5.89, 5.87, 5.85, 5.83, 5.81, 5.78, 5.76, 5.74, 5.71, 5.69, 5.66, 5.64, 5.61, 5.59, 5.56, 5.54, 5.51, 5.48, 5.46, 5.43, 5.40, 5.37, 5.34, 5.31, 5.28, 5.25, 5.22, 5.19, 5.16, 5.13, 5.10, 5.07, 5.04, 5.00, 4.97, 4.94, 4.91, 4.87, 4.84, 4.80, 4.77, 4.73, 4.70, 4.66, 4.62, 4.59, 4.55, 4.51, 4.48, 4.44, 4.40, 4.36, 4.32, 4.28, 4.24, 4.20, 4.16],
[5.55, 5.66, 5.78, 5.90, 6.03, 6.01, 5.99, 5.97, 5.95, 5.93, 5.91, 5.89, 5.86, 5.84, 5.82, 5.79, 5.77, 5.75, 5.72, 5.70, 5.67, 5.65, 5.62, 5.59, 5.57, 5.54, 5.51, 5.48, 5.46, 5.43, 5.40, 5.37, 5.34, 5.31, 5.28, 5.25, 5.22, 5.19, 5.16, 5.13, 5.09, 5.06, 5.03, 5.00, 4.96, 4.93, 4.89, 4.86, 4.82, 4.79, 4.75, 4.72, 4.68, 4.65, 4.61, 4.57, 4.53, 4.50, 4.46, 4.42, 4.38, 4.34, 4.30, 4.26, 4.22],
[5.61, 5.72, 5.83, 5.96, 6.09, 6.07, 6.05, 6.03, 6.01, 5.99, 5.97, 5.94, 5.92, 5.90, 5.87, 5.85, 5.83, 5.80, 5.78, 5.75, 5.73, 5.70, 5.68, 5.65, 5.62, 5.60, 5.57, 5.54, 5.51, 5.49, 5.46, 5.43, 5.40, 5.37, 5.34, 5.31, 5.28, 5.25, 5.22, 5.18, 5.15, 5.12, 5.09, 5.05, 5.02, 4.99, 4.95, 4.92, 4.88, 4.85, 4.81, 4.78, 4.74, 4.70, 4.67, 4.63, 4.59, 4.55, 4.51, 4.48, 4.44, 4.40, 4.36, 4.32, 4.28],
[5.67, 5.78, 5.89, 6.02, 6.15, 6.13, 6.11, 6.09, 6.07, 6.05, 6.02, 6.00, 5.98, 5.96, 5.93, 5.91, 5.89, 5.86, 5.84, 5.81, 5.79, 5.76, 5.73, 5.71, 5.68, 5.65, 5.63, 5.60, 5.57, 5.54, 5.51, 5.49, 5.46, 5.43, 5.40, 5.37, 5.34, 5.30, 5.27, 5.24, 5.21, 5.18, 5.14, 5.11, 5.08, 5.04, 5.01, 4.97, 4.94, 4.90, 4.87, 4.83, 4.80, 4.76, 4.72, 4.69, 4.65, 4.61, 4.57, 4.53, 4.49, 4.46, 4.42, 4.38, 4.34],
[5.73, 5.83, 5.95, 6.07, 6.21, 6.19, 6.17, 6.15, 6.12, 6.10, 6.08, 6.06, 6.04, 6.01, 5.99, 5.97, 5.94, 5.92, 5.89, 5.87, 5.84, 5.82, 5.79, 5.77, 5.74, 5.71, 5.69, 5.66, 5.63, 5.60, 5.57, 5.54, 5.51, 5.48, 5.45, 5.42, 5.39, 5.36, 5.33, 5.30, 5.27, 5.23, 5.20, 5.17, 5.14, 5.10, 5.07, 5.03, 5.00, 4.96, 4.93, 4.89, 4.85, 4.82, 4.78, 4.74, 4.71, 4.67, 4.63, 4.59, 4.55, 4.51, 4.47, 4.43, 4.39],
[5.78, 5.89, 6.01, 6.13, 6.27, 6.24, 6.22, 6.20, 6.18, 6.16, 6.14, 6.12, 6.09, 6.07, 6.05, 6.03, 6.00, 5.98, 5.95, 5.93, 5.90, 5.88, 5.85, 5.82, 5.80, 5.77, 5.74, 5.72, 5.69, 5.66, 5.63, 5.60, 5.57, 5.54, 5.51, 5.48, 5.45, 5.42, 5.39, 5.36, 5.33, 5.29, 5.26, 5.23, 5.19, 5.16, 5.13, 5.09, 5.06, 5.02, 4.99, 4.95, 4.91, 4.88, 4.84, 4.80, 4.76, 4.73, 4.69, 4.65, 4.61, 4.57, 4.53, 4.49, 4.45]];

NHANESIIIPredData.FEV1.female =
[[2.72, 2.71, 2.70, 2.69, 2.68, 2.66, 2.65, 2.64, 2.63, 2.61, 2.60, 2.58, 2.57, 2.55, 2.54, 2.52, 2.50, 2.49, 2.47, 2.45, 2.43, 2.41, 2.40, 2.38, 2.36, 2.34, 2.32, 2.30, 2.27, 2.25, 2.23, 2.21, 2.18, 2.16, 2.14, 2.11, 2.09, 2.06, 2.04, 2.01, 1.99, 1.96, 1.94, 1.91, 1.88, 1.85, 1.82, 1.80, 1.77, 1.74, 1.71, 1.68, 1.65, 1.62, 1.58, 1.55, 1.52, 1.49, 1.46, 1.42, 1.39, 1.35, 1.32],
[2.76, 2.75, 2.73, 2.72, 2.71, 2.70, 2.69, 2.67, 2.66, 2.64, 2.63, 2.62, 2.60, 2.59, 2.57, 2.55, 2.54, 2.52, 2.50, 2.48, 2.47, 2.45, 2.43, 2.41, 2.39, 2.37, 2.35, 2.33, 2.31, 2.29, 2.26, 2.24, 2.22, 2.20, 2.17, 2.15, 2.12, 2.10, 2.07, 2.05, 2.02, 2.00, 1.97, 1.94, 1.91, 1.89, 1.86, 1.83, 1.80, 1.77, 1.74, 1.71, 1.68, 1.65, 1.62, 1.59, 1.55, 1.52, 1.49, 1.46, 1.42, 1.39, 1.35],
[2.79, 2.78, 2.77, 2.76, 2.74, 2.73, 2.72, 2.71, 2.69, 2.68, 2.66, 2.65, 2.63, 2.62, 2.60, 2.59, 2.57, 2.55, 2.54, 2.52, 2.50, 2.48, 2.46, 2.44, 2.42, 2.40, 2.38, 2.36, 2.34, 2.32, 2.30, 2.27, 2.25, 2.23, 2.21, 2.18, 2.16, 2.13, 2.11, 2.08, 2.06, 2.03, 2.00, 1.98, 1.95, 1.92, 1.89, 1.86, 1.83, 1.80, 1.77, 1.74, 1.71, 1.68, 1.65, 1.62, 1.59, 1.56, 1.52, 1.49, 1.46, 1.42, 1.39],
[2.82, 2.81, 2.80, 2.79, 2.78, 2.77, 2.75, 2.74, 2.73, 2.71, 2.70, 2.68, 2.67, 2.65, 2.64, 2.62, 2.60, 2.59, 2.57, 2.55, 2.53, 2.52, 2.50, 2.48, 2.46, 2.44, 2.42, 2.40, 2.37, 2.35, 2.33, 2.31, 2.29, 2.26, 2.24, 2.22, 2.19, 2.17, 2.14, 2.12, 2.09, 2.06, 2.04, 2.01, 1.98, 1.95, 1.93, 1.90, 1.87, 1.84, 1.81, 1.78, 1.75, 1.72, 1.69, 1.65, 1.62, 1.59, 1.56, 1.52, 1.49, 1.46, 1.42],
[2.86, 2.85, 2.84, 2.82, 2.81, 2.80, 2.79, 2.77, 2.76, 2.75, 2.73, 2.72, 2.70, 2.69, 2.67, 2.66, 2.64, 2.62, 2.60, 2.59, 2.57, 2.55, 2.53, 2.51, 2.49, 2.47, 2.45, 2.43, 2.41, 2.39, 2.37, 2.34, 2.32, 2.30, 2.27, 2.25, 2.22, 2.20, 2.17, 2.15, 2.12, 2.10, 2.07, 2.04, 2.02, 1.99, 1.96, 1.93, 1.90, 1.87, 1.84, 1.81, 1.78, 1.75, 1.72, 1.69, 1.66, 1.62, 1.59, 1.56, 1.52, 1.49, 1.46],
[2.89, 2.88, 2.87, 2.86, 2.85, 2.83, 2.82, 2.81, 2.79, 2.78, 2.77, 2.75, 2.74, 2.72, 2.71, 2.69, 2.67, 2.66, 2.64, 2.62, 2.60, 2.58, 2.57, 2.55, 2.53, 2.51, 2.49, 2.46, 2.44, 2.42, 2.40, 2.38, 2.35, 2.33, 2.31, 2.28, 2.26, 2.23, 2.21, 2.18, 2.16, 2.13, 2.10, 2.08, 2.05, 2.02, 1.99, 1.97, 1.94, 1.91, 1.88, 1.85, 1.82, 1.79, 1.75, 1.72, 1.69, 1.66, 1.62, 1.59, 1.56, 1.52, 1.49],
[2.93, 2.92, 2.90, 2.89, 2.88, 2.87, 2.86, 2.84, 2.83, 2.82, 2.80, 2.79, 2.77, 2.76, 2.74, 2.72, 2.71, 2.69, 2.67, 2.66, 2.64, 2.62, 2.60, 2.58, 2.56, 2.54, 2.52, 2.50, 2.48, 2.46, 2.43, 2.41, 2.39, 2.37, 2.34, 2.32, 2.29, 2.27, 2.24, 2.22, 2.19, 2.17, 2.14, 2.11, 2.08, 2.06, 2.03, 2.00, 1.97, 1.94, 1.91, 1.88, 1.85, 1.82, 1.79, 1.76, 1.73, 1.69, 1.66, 1.63, 1.59, 1.56, 1.52],
[2.96, 2.95, 2.94, 2.93, 2.92, 2.90, 2.89, 2.88, 2.86, 2.85, 2.84, 2.82, 2.81, 2.79, 2.78, 2.76, 2.74, 2.73, 2.71, 2.69, 2.67, 2.65, 2.63, 2.62, 2.60, 2.58, 2.55, 2.53, 2.51, 2.49, 2.47, 2.45, 2.42, 2.40, 2.38, 2.35, 2.33, 2.30, 2.28, 2.25, 2.23, 2.20, 2.17, 2.15, 2.12, 2.09, 2.06, 2.04, 2.01, 1.98, 1.95, 1.92, 1.89, 1.86, 1.82, 1.79, 1.76, 1.73, 1.69, 1.66, 1.63, 1.59, 1.56],
[3.00, 2.99, 2.97, 2.96, 2.95, 2.94, 2.93, 2.91, 2.90, 2.89, 2.87, 2.86, 2.84, 2.83, 2.81, 2.79, 2.78, 2.76, 2.74, 2.73, 2.71, 2.69, 2.67, 2.65, 2.63, 2.61, 2.59, 2.57, 2.55, 2.53, 2.50, 2.48, 2.46, 2.44, 2.41, 2.39, 2.36, 2.34, 2.31, 2.29, 2.26, 2.24, 2.21, 2.18, 2.15, 2.13, 2.10, 2.07, 2.04, 2.01, 1.98, 1.95, 1.92, 1.89, 1.86, 1.83, 1.79, 1.76, 1.73, 1.70, 1.66, 1.63, 1.59],
[3.03, 3.02, 3.01, 3.00, 2.99, 2.97, 2.96, 2.95, 2.93, 2.92, 2.91, 2.89, 2.88, 2.86, 2.85, 2.83, 2.81, 2.80, 2.78, 2.76, 2.74, 2.72, 2.70, 2.69, 2.67, 2.65, 2.63, 2.60, 2.58, 2.56, 2.54, 2.52, 2.49, 2.47, 2.45, 2.42, 2.40, 2.37, 2.35, 2.32, 2.30, 2.27, 2.24, 2.22, 2.19, 2.16, 2.13, 2.11, 2.08, 2.05, 2.02, 1.99, 1.96, 1.93, 1.89, 1.86, 1.83, 1.80, 1.76, 1.73, 1.70, 1.66, 1.63],
[3.07, 3.06, 3.05, 3.03, 3.02, 3.01, 3.00, 2.98, 2.97, 2.96, 2.94, 2.93, 2.91, 2.90, 2.88, 2.86, 2.85, 2.83, 2.81, 2.80, 2.78, 2.76, 2.74, 2.72, 2.70, 2.68, 2.66, 2.64, 2.62, 2.60, 2.57, 2.55, 2.53, 2.51, 2.48, 2.46, 2.43, 2.41, 2.38, 2.36, 2.33, 2.31, 2.28, 2.25, 2.23, 2.20, 2.17, 2.14, 2.11, 2.08, 2.05, 2.02, 1.99, 1.96, 1.93, 1.90, 1.87, 1.83, 1.80, 1.77, 1.73, 1.70, 1.66],
[3.10, 3.09, 3.08, 3.07, 3.06, 3.05, 3.03, 3.02, 3.01, 2.99, 2.98, 2.96, 2.95, 2.93, 2.92, 2.90, 2.88, 2.87, 2.85, 2.83, 2.81, 2.80, 2.78, 2.76, 2.74, 2.72, 2.70, 2.68, 2.65, 2.63, 2.61, 2.59, 2.57, 2.54, 2.52, 2.49, 2.47, 2.45, 2.42, 2.39, 2.37, 2.34, 2.32, 2.29, 2.26, 2.23, 2.21, 2.18, 2.15, 2.12, 2.09, 2.06, 2.03, 2.00, 1.97, 1.93, 1.90, 1.87, 1.84, 1.80, 1.77, 1.74, 1.70],
[3.14, 3.13, 3.12, 3.11, 3.09, 3.08, 3.07, 3.06, 3.04, 3.03, 3.01, 3.00, 2.98, 2.97, 2.95, 2.94, 2.92, 2.90, 2.89, 2.87, 2.85, 2.83, 2.81, 2.79, 2.77, 2.75, 2.73, 2.71, 2.69, 2.67, 2.65, 2.62, 2.60, 2.58, 2.55, 2.53, 2.51, 2.48, 2.46, 2.43, 2.40, 2.38, 2.35, 2.32, 2.30, 2.27, 2.24, 2.21, 2.18, 2.15, 2.12, 2.09, 2.06, 2.03, 2.00, 1.97, 1.94, 1.90, 1.87, 1.84, 1.81, 1.77, 1.74],
[3.18, 3.16, 3.15, 3.14, 3.13, 3.12, 3.10, 3.09, 3.08, 3.06, 3.05, 3.04, 3.02, 3.00, 2.99, 2.97, 2.96, 2.94, 2.92, 2.90, 2.89, 2.87, 2.85, 2.83, 2.81, 2.79, 2.77, 2.75, 2.73, 2.70, 2.68, 2.66, 2.64, 2.61, 2.59, 2.57, 2.54, 2.52, 2.49, 2.47, 2.44, 2.41, 2.39, 2.36, 2.33, 2.31, 2.28, 2.25, 2.22, 2.19, 2.16, 2.13, 2.10, 2.07, 2.04, 2.01, 1.97, 1.94, 1.91, 1.87, 1.84, 1.81, 1.77],
[3.21, 3.20, 3.19, 3.18, 3.17, 3.15, 3.14, 3.13, 3.11, 3.10, 3.09, 3.07, 3.06, 3.04, 3.03, 3.01, 2.99, 2.98, 2.96, 2.94, 2.92, 2.90, 2.88, 2.87, 2.85, 2.83, 2.81, 2.78, 2.76, 2.74, 2.72, 2.70, 2.67, 2.65, 2.63, 2.60, 2.58, 2.55, 2.53, 2.50, 2.48, 2.45, 2.42, 2.40, 2.37, 2.34, 2.31, 2.29, 2.26, 2.23, 2.20, 2.17, 2.14, 2.11, 2.07, 2.04, 2.01, 1.98, 1.94, 1.91, 1.88, 1.84, 1.81],
[3.25, 3.24, 3.23, 3.21, 3.20, 3.19, 3.18, 3.16, 3.15, 3.14, 3.12, 3.11, 3.09, 3.08, 3.06, 3.05, 3.03, 3.01, 2.99, 2.98, 2.96, 2.94, 2.92, 2.90, 2.88, 2.86, 2.84, 2.82, 2.80, 2.78, 2.76, 2.73, 2.71, 2.69, 2.66, 2.64, 2.62, 2.59, 2.57, 2.54, 2.51, 2.49, 2.46, 2.43, 2.41, 2.38, 2.35, 2.32, 2.29, 2.26, 2.23, 2.20, 2.17, 2.14, 2.11, 2.08, 2.05, 2.01, 1.98, 1.95, 1.91, 1.88, 1.85],
[3.29, 3.27, 3.26, 3.25, 3.24, 3.23, 3.21, 3.20, 3.19, 3.17, 3.16, 3.15, 3.13, 3.11, 3.10, 3.08, 3.07, 3.05, 3.03, 3.01, 3.00, 2.98, 2.96, 2.94, 2.92, 2.90, 2.88, 2.86, 2.84, 2.81, 2.79, 2.77, 2.75, 2.72, 2.70, 2.68, 2.65, 2.63, 2.60, 2.58, 2.55, 2.52, 2.50, 2.47, 2.44, 2.42, 2.39, 2.36, 2.33, 2.30, 2.27, 2.24, 2.21, 2.18, 2.15, 2.12, 2.08, 2.05, 2.02, 1.98, 1.95, 1.92, 1.88],
[3.32, 3.31, 3.30, 3.29, 3.28, 3.26, 3.25, 3.24, 3.23, 3.21, 3.20, 3.18, 3.17, 3.15, 3.14, 3.12, 3.10, 3.09, 3.07, 3.05, 3.03, 3.01, 3.00, 2.98, 2.96, 2.94, 2.92, 2.90, 2.87, 2.85, 2.83, 2.81, 2.78, 2.76, 2.74, 2.71, 2.69, 2.66, 2.64, 2.61, 2.59, 2.56, 2.54, 2.51, 2.48, 2.45, 2.42, 2.40, 2.37, 2.34, 2.31, 2.28, 2.25, 2.22, 2.18, 2.15, 2.12, 2.09, 2.06, 2.02, 1.99, 1.95, 1.92],
[3.36, 3.35, 3.34, 3.33, 3.31, 3.30, 3.29, 3.28, 3.26, 3.25, 3.23, 3.22, 3.20, 3.19, 3.17, 3.16, 3.14, 3.12, 3.11, 3.09, 3.07, 3.05, 3.03, 3.01, 2.99, 2.97, 2.95, 2.93, 2.91, 2.89, 2.87, 2.84, 2.82, 2.80, 2.78, 2.75, 2.73, 2.70, 2.68, 2.65, 2.63, 2.60, 2.57, 2.55, 2.52, 2.49, 2.46, 2.43, 2.40, 2.37, 2.35, 2.31, 2.28, 2.25, 2.22, 2.19, 2.16, 2.13, 2.09, 2.06, 2.03, 1.99, 1.96],
[3.40, 3.39, 3.38, 3.36, 3.35, 3.34, 3.33, 3.31, 3.30, 3.29, 3.27, 3.26, 3.24, 3.23, 3.21, 3.19, 3.18, 3.16, 3.14, 3.13, 3.11, 3.09, 3.07, 3.05, 3.03, 3.01, 2.99, 2.97, 2.95, 2.93, 2.91, 2.88, 2.86, 2.84, 2.81, 2.79, 2.76, 2.74, 2.71, 2.69, 2.66, 2.64, 2.61, 2.58, 2.56, 2.53, 2.50, 2.47, 2.44, 2.41, 2.38, 2.35, 2.32, 2.29, 2.26, 2.23, 2.20, 2.16, 2.13, 2.10, 2.06, 2.03, 1.99],
[3.44, 3.42, 3.41, 3.40, 3.39, 3.38, 3.36, 3.35, 3.34, 3.32, 3.31, 3.30, 3.28, 3.26, 3.25, 3.23, 3.22, 3.20, 3.18, 3.16, 3.15, 3.13, 3.11, 3.09, 3.07, 3.05, 3.03, 3.01, 2.99, 2.96, 2.94, 2.92, 2.90, 2.87, 2.85, 2.83, 2.80, 2.78, 2.75, 2.73, 2.70, 2.67, 2.65, 2.62, 2.59, 2.57, 2.54, 2.51, 2.48, 2.45, 2.42, 2.39, 2.36, 2.33, 2.30, 2.27, 2.23, 2.20, 2.17, 2.13, 2.10, 2.07, 2.03],
[3.47, 3.46, 3.45, 3.44, 3.43, 3.42, 3.40, 3.39, 3.38, 3.36, 3.35, 3.33, 3.32, 3.30, 3.29, 3.27, 3.25, 3.24, 3.22, 3.20, 3.18, 3.17, 3.15, 3.13, 3.11, 3.09, 3.07, 3.05, 3.02, 3.00, 2.98, 2.96, 2.94, 2.91, 2.89, 2.86, 2.84, 2.82, 2.79, 2.77, 2.74, 2.71, 2.69, 2.66, 2.63, 2.60, 2.58, 2.55, 2.52, 2.49, 2.46, 2.43, 2.40, 2.37, 2.34, 2.30, 2.27, 2.24, 2.21, 2.17, 2.14, 2.11, 2.07],
[3.51, 3.50, 3.49, 3.48, 3.47, 3.45, 3.44, 3.43, 3.41, 3.40, 3.39, 3.37, 3.36, 3.34, 3.33, 3.31, 3.29, 3.28, 3.26, 3.24, 3.22, 3.20, 3.18, 3.17, 3.15, 3.13, 3.10, 3.08, 3.06, 3.04, 3.02, 3.00, 2.97, 2.95, 2.93, 2.90, 2.88, 2.85, 2.83, 2.80, 2.78, 2.75, 2.72, 2.70, 2.67, 2.64, 2.61, 2.59, 2.56, 2.53, 2.50, 2.47, 2.44, 2.41, 2.37, 2.34, 2.31, 2.28, 2.24, 2.21, 2.18, 2.14, 2.11],
[3.55, 3.54, 3.53, 3.52, 3.50, 3.49, 3.48, 3.47, 3.45, 3.44, 3.42, 3.41, 3.40, 3.38, 3.36, 3.35, 3.33, 3.31, 3.30, 3.28, 3.26, 3.24, 3.22, 3.20, 3.18, 3.16, 3.14, 3.12, 3.10, 3.08, 3.06, 3.04, 3.01, 2.99, 2.97, 2.94, 2.92, 2.89, 2.87, 2.84, 2.82, 2.79, 2.76, 2.74, 2.71, 2.68, 2.65, 2.62, 2.59, 2.57, 2.54, 2.51, 2.47, 2.44, 2.41, 2.38, 2.35, 2.32, 2.28, 2.25, 2.22, 2.18, 2.15],
[3.59, 3.58, 3.57, 3.56, 3.54, 3.53, 3.52, 3.51, 3.49, 3.48, 3.46, 3.45, 3.43, 3.42, 3.40, 3.39, 3.37, 3.35, 3.34, 3.32, 3.30, 3.28, 3.26, 3.24, 3.22, 3.20, 3.18, 3.16, 3.14, 3.12, 3.10, 3.07, 3.05, 3.03, 3.00, 2.98, 2.96, 2.93, 2.91, 2.88, 2.85, 2.83, 2.80, 2.77, 2.75, 2.72, 2.69, 2.66, 2.63, 2.60, 2.57, 2.54, 2.51, 2.48, 2.45, 2.42, 2.39, 2.35, 2.32, 2.29, 2.25, 2.22, 2.19],
[3.63, 3.62, 3.61, 3.59, 3.58, 3.57, 3.56, 3.54, 3.53, 3.52, 3.50, 3.49, 3.47, 3.46, 3.44, 3.43, 3.41, 3.39, 3.37, 3.36, 3.34, 3.32, 3.30, 3.28, 3.26, 3.24, 3.22, 3.20, 3.18, 3.16, 3.14, 3.11, 3.09, 3.07, 3.04, 3.02, 3.00, 2.97, 2.95, 2.92, 2.89, 2.87, 2.84, 2.81, 2.79, 2.76, 2.73, 2.70, 2.67, 2.64, 2.61, 2.58, 2.55, 2.52, 2.49, 2.46, 2.43, 2.39, 2.36, 2.33, 2.29, 2.26, 2.23],
[3.67, 3.66, 3.65, 3.63, 3.62, 3.61, 3.60, 3.58, 3.57, 3.56, 3.54, 3.53, 3.51, 3.50, 3.48, 3.46, 3.45, 3.43, 3.41, 3.40, 3.38, 3.36, 3.34, 3.32, 3.30, 3.28, 3.26, 3.24, 3.22, 3.20, 3.17, 3.15, 3.13, 3.11, 3.08, 3.06, 3.03, 3.01, 2.98, 2.96, 2.93, 2.91, 2.88, 2.85, 2.83, 2.80, 2.77, 2.74, 2.71, 2.68, 2.65, 2.62, 2.59, 2.56, 2.53, 2.50, 2.47, 2.43, 2.40, 2.37, 2.33, 2.30, 2.26],
[3.71, 3.70, 3.68, 3.67, 3.66, 3.65, 3.64, 3.62, 3.61, 3.60, 3.58, 3.57, 3.55, 3.54, 3.52, 3.50, 3.49, 3.47, 3.45, 3.44, 3.42, 3.40, 3.38, 3.36, 3.34, 3.32, 3.30, 3.28, 3.26, 3.24, 3.21, 3.19, 3.17, 3.15, 3.12, 3.10, 3.07, 3.05, 3.02, 3.00, 2.97, 2.95, 2.92, 2.89, 2.86, 2.84, 2.81, 2.78, 2.75, 2.72, 2.69, 2.66, 2.63, 2.60, 2.57, 2.54, 2.50, 2.47, 2.44, 2.41, 2.37, 2.34, 2.30],
[3.75, 3.74, 3.72, 3.71, 3.70, 3.69, 3.68, 3.66, 3.65, 3.64, 3.62, 3.61, 3.59, 3.58, 3.56, 3.54, 3.53, 3.51, 3.49, 3.47, 3.46, 3.44, 3.42, 3.40, 3.38, 3.36, 3.34, 3.32, 3.30, 3.28, 3.25, 3.23, 3.21, 3.19, 3.16, 3.14, 3.11, 3.09, 3.06, 3.04, 3.01, 2.99, 2.96, 2.93, 2.90, 2.88, 2.85, 2.82, 2.79, 2.76, 2.73, 2.70, 2.67, 2.64, 2.61, 2.58, 2.54, 2.51, 2.48, 2.45, 2.41, 2.38, 2.34],
[3.79, 3.78, 3.76, 3.75, 3.74, 3.73, 3.72, 3.70, 3.69, 3.67, 3.66, 3.65, 3.63, 3.62, 3.60, 3.58, 3.57, 3.55, 3.53, 3.51, 3.50, 3.48, 3.46, 3.44, 3.42, 3.40, 3.38, 3.36, 3.34, 3.32, 3.29, 3.27, 3.25, 3.23, 3.20, 3.18, 3.15, 3.13, 3.10, 3.08, 3.05, 3.03, 3.00, 2.97, 2.94, 2.92, 2.89, 2.86, 2.83, 2.80, 2.77, 2.74, 2.71, 2.68, 2.65, 2.62, 2.58, 2.55, 2.52, 2.49, 2.45, 2.42, 2.38],
[3.83, 3.82, 3.80, 3.79, 3.78, 3.77, 3.76, 3.74, 3.73, 3.72, 3.70, 3.69, 3.67, 3.66, 3.64, 3.62, 3.61, 3.59, 3.57, 3.55, 3.54, 3.52, 3.50, 3.48, 3.46, 3.44, 3.42, 3.40, 3.38, 3.36, 3.33, 3.31, 3.29, 3.27, 3.24, 3.22, 3.19, 3.17, 3.14, 3.12, 3.09, 3.07, 3.04, 3.01, 2.98, 2.96, 2.93, 2.90, 2.87, 2.84, 2.81, 2.78, 2.75, 2.72, 2.69, 2.66, 2.62, 2.59, 2.56, 2.53, 2.49, 2.46, 2.42],
[3.87, 3.86, 3.84, 3.83, 3.82, 3.81, 3.80, 3.78, 3.77, 3.76, 3.74, 3.73, 3.71, 3.70, 3.68, 3.66, 3.65, 3.63, 3.61, 3.60, 3.58, 3.56, 3.54, 3.52, 3.50, 3.48, 3.46, 3.44, 3.42, 3.40, 3.37, 3.35, 3.33, 3.31, 3.28, 3.26, 3.23, 3.21, 3.18, 3.16, 3.13, 3.11, 3.08, 3.05, 3.02, 3.00, 2.97, 2.94, 2.91, 2.88, 2.85, 2.82, 2.79, 2.76, 2.73, 2.70, 2.66, 2.63, 2.60, 2.57, 2.53, 2.50, 2.46],
[3.91, 3.90, 3.89, 3.87, 3.86, 3.85, 3.84, 3.82, 3.81, 3.80, 3.78, 3.77, 3.75, 3.74, 3.72, 3.70, 3.69, 3.67, 3.65, 3.64, 3.62, 3.60, 3.58, 3.56, 3.54, 3.52, 3.50, 3.48, 3.46, 3.44, 3.41, 3.39, 3.37, 3.35, 3.32, 3.30, 3.27, 3.25, 3.22, 3.20, 3.17, 3.15, 3.12, 3.09, 3.07, 3.04, 3.01, 2.98, 2.95, 2.92, 2.89, 2.86, 2.83, 2.80, 2.77, 2.74, 2.71, 2.67, 2.64, 2.61, 2.57, 2.54, 2.50],
[3.95, 3.94, 3.93, 3.91, 3.90, 3.89, 3.88, 3.86, 3.85, 3.84, 3.82, 3.81, 3.79, 3.78, 3.76, 3.75, 3.73, 3.71, 3.69, 3.68, 3.66, 3.64, 3.62, 3.60, 3.58, 3.56, 3.54, 3.52, 3.50, 3.48, 3.46, 3.43, 3.41, 3.39, 3.36, 3.34, 3.32, 3.29, 3.27, 3.24, 3.21, 3.19, 3.16, 3.13, 3.11, 3.08, 3.05, 3.02, 2.99, 2.96, 2.93, 2.90, 2.87, 2.84, 2.81, 2.78, 2.75, 2.71, 2.68, 2.65, 2.61, 2.58, 2.55],
[3.99, 3.98, 3.97, 3.96, 3.94, 3.93, 3.92, 3.91, 3.89, 3.88, 3.86, 3.85, 3.83, 3.82, 3.80, 3.79, 3.77, 3.75, 3.74, 3.72, 3.70, 3.68, 3.66, 3.64, 3.62, 3.60, 3.58, 3.56, 3.54, 3.52, 3.50, 3.47, 3.45, 3.43, 3.40, 3.38, 3.36, 3.33, 3.31, 3.28, 3.25, 3.23, 3.20, 3.17, 3.15, 3.12, 3.09, 3.06, 3.03, 3.00, 2.97, 2.94, 2.91, 2.88, 2.85, 2.82, 2.79, 2.75, 2.72, 2.69, 2.65, 2.62, 2.59],
[4.03, 4.02, 4.01, 4.00, 3.98, 3.97, 3.96, 3.95, 3.93, 3.92, 3.90, 3.89, 3.88, 3.86, 3.84, 3.83, 3.81, 3.79, 3.78, 3.76, 3.74, 3.72, 3.70, 3.68, 3.66, 3.64, 3.62, 3.60, 3.58, 3.56, 3.54, 3.52, 3.49, 3.47, 3.45, 3.42, 3.40, 3.37, 3.35, 3.32, 3.30, 3.27, 3.24, 3.22, 3.19, 3.16, 3.13, 3.10, 3.07, 3.05, 3.02, 2.99, 2.95, 2.92, 2.89, 2.86, 2.83, 2.80, 2.76, 2.73, 2.70, 2.66, 2.63],
[4.07, 4.06, 4.05, 4.04, 4.03, 4.01, 4.00, 3.99, 3.97, 3.96, 3.95, 3.93, 3.92, 3.90, 3.89, 3.87, 3.85, 3.84, 3.82, 3.80, 3.78, 3.76, 3.74, 3.73, 3.71, 3.69, 3.67, 3.64, 3.62, 3.60, 3.58, 3.56, 3.53, 3.51, 3.49, 3.46, 3.44, 3.41, 3.39, 3.36, 3.34, 3.31, 3.28, 3.26, 3.23, 3.20, 3.17, 3.15, 3.12, 3.09, 3.06, 3.03, 3.00, 2.97, 2.93, 2.90, 2.87, 2.84, 2.80, 2.77, 2.74, 2.70, 2.67],
[4.11, 4.10, 4.09, 4.08, 4.07, 4.06, 4.04, 4.03, 4.02, 4.00, 3.99, 3.97, 3.96, 3.94, 3.93, 3.91, 3.89, 3.88, 3.86, 3.84, 3.82, 3.81, 3.79, 3.77, 3.75, 3.73, 3.71, 3.69, 3.66, 3.64, 3.62, 3.60, 3.58, 3.55, 3.53, 3.50, 3.48, 3.46, 3.43, 3.41, 3.38, 3.35, 3.33, 3.30, 3.27, 3.24, 3.22, 3.19, 3.16, 3.13, 3.10, 3.07, 3.04, 3.01, 2.98, 2.94, 2.91, 2.88, 2.85, 2.81, 2.78, 2.75, 2.71],
[4.16, 4.14, 4.13, 4.12, 4.11, 4.10, 4.08, 4.07, 4.06, 4.04, 4.03, 4.02, 4.00, 3.98, 3.97, 3.95, 3.94, 3.92, 3.90, 3.88, 3.87, 3.85, 3.83, 3.81, 3.79, 3.77, 3.75, 3.73, 3.71, 3.68, 3.66, 3.64, 3.62, 3.59, 3.57, 3.55, 3.52, 3.50, 3.47, 3.45, 3.42, 3.39, 3.37, 3.34, 3.31, 3.29, 3.26, 3.23, 3.20, 3.17, 3.14, 3.11, 3.08, 3.05, 3.02, 2.99, 2.95, 2.92, 2.89, 2.85, 2.82, 2.79, 2.75],
[4.20, 4.19, 4.18, 4.16, 4.15, 4.14, 4.13, 4.11, 4.10, 4.09, 4.07, 4.06, 4.04, 4.03, 4.01, 3.99, 3.98, 3.96, 3.94, 3.93, 3.91, 3.89, 3.87, 3.85, 3.83, 3.81, 3.79, 3.77, 3.75, 3.73, 3.71, 3.68, 3.66, 3.64, 3.61, 3.59, 3.56, 3.54, 3.51, 3.49, 3.46, 3.44, 3.41, 3.38, 3.36, 3.33, 3.30, 3.27, 3.24, 3.21, 3.18, 3.15, 3.12, 3.09, 3.06, 3.03, 3.00, 2.96, 2.93, 2.90, 2.86, 2.83, 2.79],
[4.24, 4.23, 4.22, 4.21, 4.19, 4.18, 4.17, 4.16, 4.14, 4.13, 4.11, 4.10, 4.08, 4.07, 4.05, 4.04, 4.02, 4.00, 3.99, 3.97, 3.95, 3.93, 3.91, 3.89, 3.87, 3.85, 3.83, 3.81, 3.79, 3.77, 3.75, 3.73, 3.70, 3.68, 3.66, 3.63, 3.61, 3.58, 3.56, 3.53, 3.51, 3.48, 3.45, 3.43, 3.40, 3.37, 3.34, 3.31, 3.28, 3.26, 3.23, 3.20, 3.16, 3.13, 3.10, 3.07, 3.04, 3.01, 2.97, 2.94, 2.91, 2.87, 2.84],
[4.28, 4.27, 4.26, 4.25, 4.24, 4.22, 4.21, 4.20, 4.19, 4.17, 4.16, 4.14, 4.13, 4.11, 4.10, 4.08, 4.06, 4.05, 4.03, 4.01, 3.99, 3.97, 3.96, 3.94, 3.92, 3.90, 3.88, 3.86, 3.83, 3.81, 3.79, 3.77, 3.74, 3.72, 3.70, 3.67, 3.65, 3.63, 3.60, 3.57, 3.55, 3.52, 3.50, 3.47, 3.44, 3.41, 3.38, 3.36, 3.33, 3.30, 3.27, 3.24, 3.21, 3.18, 3.14, 3.11, 3.08, 3.05, 3.02, 2.98, 2.95, 2.91, 2.88],
[4.33, 4.31, 4.30, 4.29, 4.28, 4.27, 4.25, 4.24, 4.23, 4.21, 4.20, 4.19, 4.17, 4.15, 4.14, 4.12, 4.11, 4.09, 4.07, 4.05, 4.04, 4.02, 4.00, 3.98, 3.96, 3.94, 3.92, 3.90, 3.88, 3.86, 3.83, 3.81, 3.79, 3.76, 3.74, 3.72, 3.69, 3.67, 3.64, 3.62, 3.59, 3.57, 3.54, 3.51, 3.48, 3.46, 3.43, 3.40, 3.37, 3.34, 3.31, 3.28, 3.25, 3.22, 3.19, 3.16, 3.12, 3.09, 3.06, 3.03, 2.99, 2.96, 2.92],
[4.37, 4.36, 4.35, 4.34, 4.32, 4.31, 4.30, 4.28, 4.27, 4.26, 4.24, 4.23, 4.21, 4.20, 4.18, 4.17, 4.15, 4.13, 4.12, 4.10, 4.08, 4.06, 4.04, 4.02, 4.00, 3.98, 3.96, 3.94, 3.92, 3.90, 3.88, 3.85, 3.83, 3.81, 3.78, 3.76, 3.74, 3.71, 3.69, 3.66, 3.63, 3.61, 3.58, 3.55, 3.53, 3.50, 3.47, 3.44, 3.41, 3.38, 3.35, 3.32, 3.29, 3.26, 3.23, 3.20, 3.17, 3.13, 3.10, 3.07, 3.03, 3.00, 2.97],
[4.41, 4.40, 4.39, 4.38, 4.37, 4.35, 4.34, 4.33, 4.31, 4.30, 4.29, 4.27, 4.26, 4.24, 4.23, 4.21, 4.19, 4.18, 4.16, 4.14, 4.12, 4.10, 4.08, 4.07, 4.05, 4.03, 4.01, 3.98, 3.96, 3.94, 3.92, 3.90, 3.87, 3.85, 3.83, 3.80, 3.78, 3.75, 3.73, 3.70, 3.68, 3.65, 3.62, 3.60, 3.57, 3.54, 3.51, 3.49, 3.46, 3.43, 3.40, 3.37, 3.34, 3.31, 3.27, 3.24, 3.21, 3.18, 3.14, 3.11, 3.08, 3.04, 3.01]];

NHANESIIIPredData.PEF = {};

NHANESIIIPredData.PEF.male =
[[6.18, 6.49, 6.83, 7.19, 7.58, 7.61, 7.64, 7.66, 7.68, 7.70, 7.72, 7.73, 7.74, 7.75, 7.76, 7.76, 7.76, 7.76, 7.75, 7.75, 7.74, 7.73, 7.71, 7.69, 7.67, 7.65, 7.63, 7.60, 7.57, 7.53, 7.50, 7.46, 7.42, 7.38, 7.33, 7.28, 7.23, 7.18, 7.12, 7.06, 7.00, 6.93, 6.87, 6.80, 6.73, 6.65, 6.57, 6.49, 6.41, 6.33, 6.24, 6.15, 6.06, 5.96, 5.86, 5.76, 5.66, 5.55, 5.44, 5.33, 5.22, 5.10, 4.98, 4.86, 4.74],
[6.26, 6.57, 6.90, 7.27, 7.65, 7.68, 7.71, 7.73, 7.76, 7.77, 7.79, 7.80, 7.82, 7.82, 7.83, 7.83, 7.83, 7.83, 7.83, 7.82, 7.81, 7.80, 7.78, 7.77, 7.75, 7.72, 7.70, 7.67, 7.64, 7.61, 7.57, 7.53, 7.49, 7.45, 7.40, 7.35, 7.30, 7.25, 7.19, 7.13, 7.07, 7.01, 6.94, 6.87, 6.80, 6.72, 6.65, 6.57, 6.49, 6.40, 6.31, 6.22, 6.13, 6.03, 5.94, 5.83, 5.73, 5.63, 5.52, 5.41, 5.29, 5.18, 5.06, 4.94, 4.81],
[6.33, 6.64, 6.98, 7.34, 7.73, 7.76, 7.78, 7.81, 7.83, 7.85, 7.87, 7.88, 7.89, 7.90, 7.90, 7.91, 7.91, 7.91, 7.90, 7.90, 7.89, 7.87, 7.86, 7.84, 7.82, 7.80, 7.77, 7.75, 7.72, 7.68, 7.65, 7.61, 7.57, 7.52, 7.48, 7.43, 7.38, 7.32, 7.27, 7.21, 7.15, 7.08, 7.02, 6.95, 6.87, 6.80, 6.72, 6.64, 6.56, 6.47, 6.39, 6.30, 6.20, 6.11, 6.01, 5.91, 5.81, 5.70, 5.59, 5.48, 5.37, 5.25, 5.13, 5.01, 4.89],
[6.41, 6.72, 7.05, 7.41, 7.80, 7.83, 7.86, 7.88, 7.90, 7.92, 7.94, 7.95, 7.96, 7.97, 7.98, 7.98, 7.98, 7.98, 7.98, 7.97, 7.96, 7.95, 7.93, 7.92, 7.90, 7.87, 7.85, 7.82, 7.79, 7.76, 7.72, 7.68, 7.64, 7.60, 7.55, 7.50, 7.45, 7.40, 7.34, 7.28, 7.22, 7.16, 7.09, 7.02, 6.95, 6.87, 6.80, 6.72, 6.63, 6.55, 6.46, 6.37, 6.28, 6.18, 6.08, 5.98, 5.88, 5.77, 5.67, 5.55, 5.44, 5.32, 5.21, 5.08, 4.96],
[6.48, 6.79, 7.13, 7.49, 7.88, 7.91, 7.93, 7.96, 7.98, 8.00, 8.02, 8.03, 8.04, 8.05, 8.05, 8.06, 8.06, 8.06, 8.05, 8.05, 8.04, 8.02, 8.01, 7.99, 7.97, 7.95, 7.92, 7.90, 7.86, 7.83, 7.80, 7.76, 7.72, 7.67, 7.63, 7.58, 7.53, 7.47, 7.42, 7.36, 7.30, 7.23, 7.17, 7.10, 7.02, 6.95, 6.87, 6.79, 6.71, 6.62, 6.54, 6.45, 6.35, 6.26, 6.16, 6.06, 5.96, 5.85, 5.74, 5.63, 5.52, 5.40, 5.28, 5.16, 5.04],
[6.56, 6.87, 7.20, 7.56, 7.95, 7.98, 8.01, 8.03, 8.06, 8.07, 8.09, 8.10, 8.12, 8.12, 8.13, 8.13, 8.13, 8.13, 8.13, 8.12, 8.11, 8.10, 8.08, 8.07, 8.05, 8.02, 8.00, 7.97, 7.94, 7.91, 7.87, 7.83, 7.79, 7.75, 7.70, 7.65, 7.60, 7.55, 7.49, 7.43, 7.37, 7.31, 7.24, 7.17, 7.10, 7.02, 6.95, 6.87, 6.78, 6.70, 6.61, 6.52, 6.43, 6.33, 6.24, 6.13, 6.03, 5.93, 5.82, 5.71, 5.59, 5.48, 5.36, 5.23, 5.11],
[6.63, 6.94, 7.28, 7.64, 8.03, 8.06, 8.09, 8.11, 8.13, 8.15, 8.17, 8.18, 8.19, 8.20, 8.21, 8.21, 8.21, 8.21, 8.20, 8.20, 8.19, 8.18, 8.16, 8.14, 8.12, 8.10, 8.07, 8.05, 8.02, 7.98, 7.95, 7.91, 7.87, 7.83, 7.78, 7.73, 7.68, 7.63, 7.57, 7.51, 7.45, 7.38, 7.32, 7.25, 7.18, 7.10, 7.02, 6.94, 6.86, 6.78, 6.69, 6.60, 6.50, 6.41, 6.31, 6.21, 6.11, 6.00, 5.89, 5.78, 5.67, 5.55, 5.43, 5.31, 5.19],
[6.71, 7.02, 7.36, 7.72, 8.11, 8.14, 8.16, 8.19, 8.21, 8.23, 8.24, 8.26, 8.27, 8.28, 8.28, 8.29, 8.29, 8.29, 8.28, 8.27, 8.26, 8.25, 8.24, 8.22, 8.20, 8.18, 8.15, 8.12, 8.09, 8.06, 8.02, 7.99, 7.95, 7.90, 7.86, 7.81, 7.76, 7.70, 7.65, 7.59, 7.52, 7.46, 7.39, 7.32, 7.25, 7.18, 7.10, 7.02, 6.94, 6.85, 6.76, 6.67, 6.58, 6.49, 6.39, 6.29, 6.18, 6.08, 5.97, 5.86, 5.74, 5.63, 5.51, 5.39, 5.26],
[6.79, 7.10, 7.43, 7.79, 8.18, 8.21, 8.24, 8.26, 8.29, 8.30, 8.32, 8.33, 8.35, 8.35, 8.36, 8.36, 8.36, 8.36, 8.36, 8.35, 8.34, 8.33, 8.31, 8.30, 8.28, 8.25, 8.23, 8.20, 8.17, 8.14, 8.10, 8.06, 8.02, 7.98, 7.93, 7.88, 7.83, 7.78, 7.72, 7.66, 7.60, 7.54, 7.47, 7.40, 7.33, 7.25, 7.18, 7.10, 7.01, 6.93, 6.84, 6.75, 6.66, 6.56, 6.46, 6.36, 6.26, 6.15, 6.05, 5.94, 5.82, 5.71, 5.59, 5.46, 5.34],
[6.86, 7.17, 7.51, 7.87, 8.26, 8.29, 8.32, 8.34, 8.36, 8.38, 8.40, 8.41, 8.42, 8.43, 8.44, 8.44, 8.44, 8.44, 8.44, 8.43, 8.42, 8.41, 8.39, 8.37, 8.35, 8.33, 8.31, 8.28, 8.25, 8.21, 8.18, 8.14, 8.10, 8.06, 8.01, 7.96, 7.91, 7.86, 7.80, 7.74, 7.68, 7.62, 7.55, 7.48, 7.41, 7.33, 7.25, 7.17, 7.09, 7.01, 6.92, 6.83, 6.74, 6.64, 6.54, 6.44, 6.34, 6.23, 6.12, 6.01, 5.90, 5.78, 5.66, 5.54, 5.42],
[6.94, 7.25, 7.59, 7.95, 8.34, 8.37, 8.40, 8.42, 8.44, 8.46, 8.48, 8.49, 8.50, 8.51, 8.52, 8.52, 8.52, 8.52, 8.51, 8.51, 8.50, 8.48, 8.47, 8.45, 8.43, 8.41, 8.38, 8.36, 8.33, 8.29, 8.26, 8.22, 8.18, 8.13, 8.09, 8.04, 7.99, 7.93, 7.88, 7.82, 7.76, 7.69, 7.63, 7.56, 7.48, 7.41, 7.33, 7.25, 7.17, 7.09, 7.00, 6.91, 6.81, 6.72, 6.62, 6.52, 6.42, 6.31, 6.20, 6.09, 5.98, 5.86, 5.74, 5.62, 5.50],
[7.02, 7.33, 7.67, 8.03, 8.42, 8.45, 8.47, 8.50, 8.52, 8.54, 8.56, 8.57, 8.58, 8.59, 8.59, 8.60, 8.60, 8.60, 8.59, 8.59, 8.58, 8.56, 8.55, 8.53, 8.51, 8.49, 8.46, 8.44, 8.40, 8.37, 8.34, 8.30, 8.26, 8.21, 8.17, 8.12, 8.07, 8.01, 7.96, 7.90, 7.84, 7.77, 7.71, 7.64, 7.56, 7.49, 7.41, 7.33, 7.25, 7.16, 7.08, 6.99, 6.89, 6.80, 6.70, 6.60, 6.50, 6.39, 6.28, 6.17, 6.06, 5.94, 5.82, 5.70, 5.58],
[7.10, 7.41, 7.75, 8.11, 8.50, 8.53, 8.55, 8.58, 8.60, 8.62, 8.63, 8.65, 8.66, 8.67, 8.67, 8.68, 8.68, 8.68, 8.67, 8.66, 8.65, 8.64, 8.63, 8.61, 8.59, 8.57, 8.54, 8.51, 8.48, 8.45, 8.42, 8.38, 8.34, 8.29, 8.25, 8.20, 8.15, 8.09, 8.04, 7.98, 7.92, 7.85, 7.78, 7.71, 7.64, 7.57, 7.49, 7.41, 7.33, 7.24, 7.16, 7.06, 6.97, 6.88, 6.78, 6.68, 6.57, 6.47, 6.36, 6.25, 6.14, 6.02, 5.90, 5.78, 5.65],
[7.18, 7.49, 7.83, 8.19, 8.58, 8.61, 8.63, 8.66, 8.68, 8.70, 8.71, 8.73, 8.74, 8.75, 8.75, 8.76, 8.76, 8.76, 8.75, 8.74, 8.73, 8.72, 8.71, 8.69, 8.67, 8.65, 8.62, 8.59, 8.56, 8.53, 8.49, 8.46, 8.42, 8.37, 8.33, 8.28, 8.23, 8.17, 8.12, 8.06, 7.99, 7.93, 7.86, 7.79, 7.72, 7.65, 7.57, 7.49, 7.41, 7.32, 7.23, 7.14, 7.05, 6.96, 6.86, 6.76, 6.65, 6.55, 6.44, 6.33, 6.21, 6.10, 5.98, 5.86, 5.73],
[7.26, 7.57, 7.91, 8.27, 8.66, 8.69, 8.71, 8.74, 8.76, 8.78, 8.79, 8.81, 8.82, 8.83, 8.83, 8.84, 8.84, 8.84, 8.83, 8.82, 8.81, 8.80, 8.79, 8.77, 8.75, 8.73, 8.70, 8.67, 8.64, 8.61, 8.57, 8.54, 8.50, 8.45, 8.41, 8.36, 8.31, 8.25, 8.20, 8.14, 8.08, 8.01, 7.94, 7.87, 7.80, 7.73, 7.65, 7.57, 7.49, 7.40, 7.32, 7.22, 7.13, 7.04, 6.94, 6.84, 6.73, 6.63, 6.52, 6.41, 6.29, 6.18, 6.06, 5.94, 5.81],
[7.34, 7.65, 7.99, 8.35, 8.74, 8.77, 8.79, 8.82, 8.84, 8.86, 8.87, 8.89, 8.90, 8.91, 8.91, 8.92, 8.92, 8.92, 8.91, 8.90, 8.90, 8.88, 8.87, 8.85, 8.83, 8.81, 8.78, 8.75, 8.72, 8.69, 8.66, 8.62, 8.58, 8.53, 8.49, 8.44, 8.39, 8.33, 8.28, 8.22, 8.16, 8.09, 8.02, 7.96, 7.88, 7.81, 7.73, 7.65, 7.57, 7.48, 7.40, 7.31, 7.21, 7.12, 7.02, 6.92, 6.81, 6.71, 6.60, 6.49, 6.38, 6.26, 6.14, 6.02, 5.89],
[7.42, 7.73, 8.07, 8.43, 8.82, 8.85, 8.87, 8.90, 8.92, 8.94, 8.96, 8.97, 8.98, 8.99, 9.00, 9.00, 9.00, 9.00, 8.99, 8.99, 8.98, 8.96, 8.95, 8.93, 8.91, 8.89, 8.86, 8.84, 8.81, 8.77, 8.74, 8.70, 8.66, 8.61, 8.57, 8.52, 8.47, 8.41, 8.36, 8.30, 8.24, 8.17, 8.11, 8.04, 7.96, 7.89, 7.81, 7.73, 7.65, 7.56, 7.48, 7.39, 7.29, 7.20, 7.10, 7.00, 6.90, 6.79, 6.68, 6.57, 6.46, 6.34, 6.22, 6.10, 5.98],
[7.50, 7.81, 8.15, 8.51, 8.90, 8.93, 8.96, 8.98, 9.00, 9.02, 9.04, 9.05, 9.06, 9.07, 9.08, 9.08, 9.08, 9.08, 9.07, 9.07, 9.06, 9.05, 9.03, 9.01, 8.99, 8.97, 8.95, 8.92, 8.89, 8.85, 8.82, 8.78, 8.74, 8.70, 8.65, 8.60, 8.55, 8.50, 8.44, 8.38, 8.32, 8.25, 8.19, 8.12, 8.05, 7.97, 7.89, 7.81, 7.73, 7.65, 7.56, 7.47, 7.38, 7.28, 7.18, 7.08, 6.98, 6.87, 6.76, 6.65, 6.54, 6.42, 6.30, 6.18, 6.06],
[7.59, 7.90, 8.23, 8.59, 8.98, 9.01, 9.04, 9.06, 9.08, 9.10, 9.12, 9.13, 9.14, 9.15, 9.16, 9.16, 9.16, 9.16, 9.16, 9.15, 9.14, 9.13, 9.11, 9.10, 9.08, 9.05, 9.03, 9.00, 8.97, 8.94, 8.90, 8.86, 8.82, 8.78, 8.73, 8.68, 8.63, 8.58, 8.52, 8.46, 8.40, 8.34, 8.27, 8.20, 8.13, 8.05, 7.98, 7.90, 7.81, 7.73, 7.64, 7.55, 7.46, 7.36, 7.26, 7.16, 7.06, 6.95, 6.85, 6.73, 6.62, 6.50, 6.39, 6.26, 6.14],
[7.67, 7.98, 8.31, 8.68, 9.06, 9.09, 9.12, 9.15, 9.17, 9.19, 9.20, 9.22, 9.23, 9.24, 9.24, 9.24, 9.25, 9.24, 9.24, 9.23, 9.22, 9.21, 9.20, 9.18, 9.16, 9.14, 9.11, 9.08, 9.05, 9.02, 8.98, 8.94, 8.90, 8.86, 8.81, 8.77, 8.71, 8.66, 8.60, 8.54, 8.48, 8.42, 8.35, 8.28, 8.21, 8.14, 8.06, 7.98, 7.90, 7.81, 7.72, 7.63, 7.54, 7.44, 7.35, 7.25, 7.14, 7.04, 6.93, 6.82, 6.70, 6.59, 6.47, 6.35, 6.22],
[7.75, 8.06, 8.40, 8.76, 9.15, 9.18, 9.20, 9.23, 9.25, 9.27, 9.29, 9.30, 9.31, 9.32, 9.32, 9.33, 9.33, 9.33, 9.32, 9.32, 9.31, 9.29, 9.28, 9.26, 9.24, 9.22, 9.19, 9.17, 9.13, 9.10, 9.07, 9.03, 8.99, 8.94, 8.90, 8.85, 8.80, 8.74, 8.69, 8.63, 8.57, 8.50, 8.44, 8.37, 8.29, 8.22, 8.14, 8.06, 7.98, 7.89, 7.81, 7.72, 7.62, 7.53, 7.43, 7.33, 7.23, 7.12, 7.01, 6.90, 6.79, 6.67, 6.55, 6.43, 6.31],
[7.83, 8.14, 8.48, 8.84, 9.23, 9.26, 9.29, 9.31, 9.33, 9.35, 9.37, 9.38, 9.39, 9.40, 9.41, 9.41, 9.41, 9.41, 9.41, 9.40, 9.39, 9.38, 9.36, 9.34, 9.32, 9.30, 9.28, 9.25, 9.22, 9.19, 9.15, 9.11, 9.07, 9.03, 8.98, 8.93, 8.88, 8.83, 8.77, 8.71, 8.65, 8.59, 8.52, 8.45, 8.38, 8.30, 8.23, 8.15, 8.06, 7.98, 7.89, 7.80, 7.71, 7.61, 7.51, 7.41, 7.31, 7.20, 7.09, 6.98, 6.87, 6.75, 6.63, 6.51, 6.39],
[7.92, 8.23, 8.56, 8.93, 9.32, 9.35, 9.37, 9.40, 9.42, 9.44, 9.45, 9.47, 9.48, 9.49, 9.49, 9.50, 9.50, 9.49, 9.49, 9.48, 9.47, 9.46, 9.45, 9.43, 9.41, 9.39, 9.36, 9.33, 9.30, 9.27, 9.23, 9.20, 9.15, 9.11, 9.07, 9.02, 8.97, 8.91, 8.85, 8.80, 8.73, 8.67, 8.60, 8.53, 8.46, 8.39, 8.31, 8.23, 8.15, 8.06, 7.97, 7.88, 7.79, 7.70, 7.60, 7.50, 7.39, 7.29, 7.18, 7.07, 6.95, 6.84, 6.72, 6.60, 6.47],
[8.00, 8.31, 8.65, 9.01, 9.40, 9.43, 9.46, 9.48, 9.50, 9.52, 9.54, 9.55, 9.56, 9.57, 9.58, 9.58, 9.58, 9.58, 9.57, 9.57, 9.56, 9.55, 9.53, 9.51, 9.49, 9.47, 9.45, 9.42, 9.39, 9.35, 9.32, 9.28, 9.24, 9.20, 9.15, 9.10, 9.05, 9.00, 8.94, 8.88, 8.82, 8.75, 8.69, 8.62, 8.55, 8.47, 8.39, 8.31, 8.23, 8.15, 8.06, 7.97, 7.88, 7.78, 7.68, 7.58, 7.48, 7.37, 7.26, 7.15, 7.04, 6.92, 6.80, 6.68, 6.56],
[8.09, 8.40, 8.73, 9.10, 9.49, 9.51, 9.54, 9.57, 9.59, 9.61, 9.62, 9.64, 9.65, 9.66, 9.66, 9.67, 9.67, 9.66, 9.66, 9.65, 9.64, 9.63, 9.62, 9.60, 9.58, 9.56, 9.53, 9.50, 9.47, 9.44, 9.40, 9.37, 9.32, 9.28, 9.23, 9.19, 9.13, 9.08, 9.02, 8.97, 8.90, 8.84, 8.77, 8.70, 8.63, 8.56, 8.48, 8.40, 8.32, 8.23, 8.14, 8.05, 7.96, 7.87, 7.77, 7.67, 7.56, 7.46, 7.35, 7.24, 7.12, 7.01, 6.89, 6.77, 6.64],
[8.17, 8.48, 8.82, 9.18, 9.57, 9.60, 9.63, 9.65, 9.67, 9.69, 9.71, 9.72, 9.73, 9.74, 9.75, 9.75, 9.75, 9.75, 9.75, 9.74, 9.73, 9.72, 9.70, 9.68, 9.66, 9.64, 9.62, 9.59, 9.56, 9.52, 9.49, 9.45, 9.41, 9.37, 9.32, 9.27, 9.22, 9.17, 9.11, 9.05, 8.99, 8.93, 8.86, 8.79, 8.72, 8.64, 8.56, 8.48, 8.40, 8.32, 8.23, 8.14, 8.05, 7.95, 7.85, 7.75, 7.65, 7.54, 7.43, 7.32, 7.21, 7.09, 6.97, 6.85, 6.73],
[8.26, 8.57, 8.91, 9.27, 9.66, 9.69, 9.71, 9.74, 9.76, 9.78, 9.79, 9.81, 9.82, 9.83, 9.83, 9.84, 9.84, 9.84, 9.83, 9.82, 9.82, 9.80, 9.79, 9.77, 9.75, 9.73, 9.70, 9.67, 9.64, 9.61, 9.58, 9.54, 9.50, 9.45, 9.41, 9.36, 9.31, 9.25, 9.20, 9.14, 9.08, 9.01, 8.94, 8.87, 8.80, 8.73, 8.65, 8.57, 8.49, 8.40, 8.32, 8.23, 8.13, 8.04, 7.94, 7.84, 7.73, 7.63, 7.52, 7.41, 7.30, 7.18, 7.06, 6.94, 6.81],
[8.35, 8.66, 8.99, 9.36, 9.74, 9.77, 9.80, 9.82, 9.85, 9.86, 9.88, 9.89, 9.91, 9.91, 9.92, 9.92, 9.92, 9.92, 9.92, 9.91, 9.90, 9.89, 9.87, 9.86, 9.84, 9.81, 9.79, 9.76, 9.73, 9.70, 9.66, 9.62, 9.58, 9.54, 9.49, 9.44, 9.39, 9.34, 9.28, 9.22, 9.16, 9.10, 9.03, 8.96, 8.89, 8.81, 8.74, 8.66, 8.57, 8.49, 8.40, 8.31, 8.22, 8.12, 8.03, 7.92, 7.82, 7.72, 7.61, 7.50, 7.38, 7.27, 7.15, 7.03, 6.90],
[8.43, 8.74, 9.08, 9.44, 9.83, 9.86, 9.89, 9.91, 9.93, 9.95, 9.97, 9.98, 9.99, 10.00, 10.01, 10.01, 10.01, 10.01, 10.01, 10.00, 9.99, 9.98, 9.96, 9.94, 9.92, 9.90, 9.88, 9.85, 9.82, 9.78, 9.75, 9.71, 9.67, 9.63, 9.58, 9.53, 9.48, 9.43, 9.37, 9.31, 9.25, 9.19, 9.12, 9.05, 8.98, 8.90, 8.82, 8.74, 8.66, 8.58, 8.49, 8.40, 8.31, 8.21, 8.11, 8.01, 7.91, 7.80, 7.69, 7.58, 7.47, 7.35, 7.23, 7.11, 6.99],
[8.52, 8.83, 9.17, 9.53, 9.92, 9.95, 9.97, 10.00, 10.02, 10.04, 10.06, 10.07, 10.08, 10.09, 10.10, 10.10, 10.10, 10.10, 10.09, 10.09, 10.08, 10.06, 10.05, 10.03, 10.01, 9.99, 9.96, 9.94, 9.91, 9.87, 9.84, 9.80, 9.76, 9.71, 9.67, 9.62, 9.57, 9.51, 9.46, 9.40, 9.34, 9.27, 9.21, 9.14, 9.06, 8.99, 8.91, 8.83, 8.75, 8.66, 8.58, 8.49, 8.39, 8.30, 8.20, 8.10, 8.00, 7.89, 7.78, 7.67, 7.56, 7.44, 7.32, 7.20, 7.08],
[8.61, 8.92, 9.26, 9.62, 10.01, 10.04, 10.06, 10.09, 10.11, 10.13, 10.14, 10.16, 10.17, 10.18, 10.18, 10.19, 10.19, 10.19, 10.18, 10.17, 10.16, 10.15, 10.14, 10.12, 10.10, 10.08, 10.05, 10.02, 9.99, 9.96, 9.92, 9.89, 9.85, 9.80, 9.76, 9.71, 9.66, 9.60, 9.55, 9.49, 9.43, 9.36, 9.29, 9.22, 9.15, 9.08, 9.00, 8.92, 8.84, 8.75, 8.67, 8.57, 8.48, 8.39, 8.29, 8.19, 8.08, 7.98, 7.87, 7.76, 7.64, 7.53, 7.41, 7.29, 7.16],
[8.70, 9.01, 9.34, 9.71, 10.10, 10.12, 10.15, 10.18, 10.20, 10.22, 10.23, 10.25, 10.26, 10.27, 10.27, 10.28, 10.28, 10.27, 10.27, 10.26, 10.25, 10.24, 10.23, 10.21, 10.19, 10.17, 10.14, 10.11, 10.08, 10.05, 10.01, 9.98, 9.93, 9.89, 9.84, 9.80, 9.74, 9.69, 9.63, 9.58, 9.51, 9.45, 9.38, 9.31, 9.24, 9.17, 9.09, 9.01, 8.93, 8.84, 8.75, 8.66, 8.57, 8.47, 8.38, 8.28, 8.17, 8.07, 7.96, 7.85, 7.73, 7.62, 7.50, 7.38, 7.25],
[8.79, 9.10, 9.43, 9.80, 10.18, 10.21, 10.24, 10.26, 10.29, 10.31, 10.32, 10.34, 10.35, 10.36, 10.36, 10.36, 10.37, 10.36, 10.36, 10.35, 10.34, 10.33, 10.32, 10.30, 10.28, 10.25, 10.23, 10.20, 10.17, 10.14, 10.10, 10.06, 10.02, 9.98, 9.93, 9.89, 9.83, 9.78, 9.72, 9.66, 9.60, 9.54, 9.47, 9.40, 9.33, 9.26, 9.18, 9.10, 9.02, 8.93, 8.84, 8.75, 8.66, 8.56, 8.47, 8.37, 8.26, 8.16, 8.05, 7.94, 7.82, 7.71, 7.59, 7.47, 7.34],
[8.88, 9.19, 9.52, 9.89, 10.27, 10.30, 10.33, 10.35, 10.38, 10.39, 10.41, 10.42, 10.44, 10.44, 10.45, 10.45, 10.45, 10.45, 10.45, 10.44, 10.43, 10.42, 10.40, 10.39, 10.37, 10.34, 10.32, 10.29, 10.26, 10.23, 10.19, 10.15, 10.11, 10.07, 10.02, 9.97, 9.92, 9.87, 9.81, 9.75, 9.69, 9.63, 9.56, 9.49, 9.42, 9.34, 9.27, 9.19, 9.11, 9.02, 8.93, 8.84, 8.75, 8.65, 8.56, 8.45, 8.35, 8.25, 8.14, 8.03, 7.91, 7.80, 7.68, 7.56, 7.43],
[8.97, 9.28, 9.61, 9.98, 10.36, 10.39, 10.42, 10.44, 10.47, 10.48, 10.50, 10.52, 10.53, 10.53, 10.54, 10.54, 10.54, 10.54, 10.54, 10.53, 10.52, 10.51, 10.49, 10.48, 10.46, 10.43, 10.41, 10.38, 10.35, 10.32, 10.28, 10.24, 10.20, 10.16, 10.11, 10.06, 10.01, 9.96, 9.90, 9.84, 9.78, 9.72, 9.65, 9.58, 9.51, 9.43, 9.36, 9.28, 9.20, 9.11, 9.02, 8.93, 8.84, 8.74, 8.65, 8.54, 8.44, 8.34, 8.23, 8.12, 8.00, 7.89, 7.77, 7.65, 7.52],
[9.06, 9.37, 9.70, 10.07, 10.45, 10.48, 10.51, 10.54, 10.56, 10.58, 10.59, 10.61, 10.62, 10.63, 10.63, 10.63, 10.64, 10.63, 10.63, 10.62, 10.61, 10.60, 10.59, 10.57, 10.55, 10.53, 10.50, 10.47, 10.44, 10.41, 10.37, 10.33, 10.29, 10.25, 10.20, 10.16, 10.10, 10.05, 9.99, 9.93, 9.87, 9.81, 9.74, 9.67, 9.60, 9.53, 9.45, 9.37, 9.29, 9.20, 9.11, 9.02, 8.93, 8.83, 8.74, 8.64, 8.53, 8.43, 8.32, 8.21, 8.09, 7.98, 7.86, 7.74, 7.61],
[9.15, 9.46, 9.79, 10.16, 10.55, 10.58, 10.60, 10.63, 10.65, 10.67, 10.68, 10.70, 10.71, 10.72, 10.72, 10.73, 10.73, 10.72, 10.72, 10.71, 10.70, 10.69, 10.68, 10.66, 10.64, 10.62, 10.59, 10.56, 10.53, 10.50, 10.46, 10.43, 10.38, 10.34, 10.30, 10.25, 10.20, 10.14, 10.08, 10.03, 9.96, 9.90, 9.83, 9.76, 9.69, 9.62, 9.54, 9.46, 9.38, 9.29, 9.20, 9.11, 9.02, 8.93, 8.83, 8.73, 8.62, 8.52, 8.41, 8.30, 8.18, 8.07, 7.95, 7.83, 7.70],
[9.24, 9.55, 9.89, 10.25, 10.64, 10.67, 10.69, 10.72, 10.74, 10.76, 10.77, 10.79, 10.80, 10.81, 10.81, 10.82, 10.82, 10.82, 10.81, 10.80, 10.80, 10.78, 10.77, 10.75, 10.73, 10.71, 10.68, 10.65, 10.62, 10.59, 10.56, 10.52, 10.48, 10.43, 10.39, 10.34, 10.29, 10.23, 10.18, 10.12, 10.06, 9.99, 9.92, 9.86, 9.78, 9.71, 9.63, 9.55, 9.47, 9.38, 9.30, 9.21, 9.11, 9.02, 8.92, 8.82, 8.71, 8.61, 8.50, 8.39, 8.28, 8.16, 8.04, 7.92, 7.79],
[9.33, 9.64, 9.98, 10.34, 10.73, 10.76, 10.79, 10.81, 10.83, 10.85, 10.87, 10.88, 10.89, 10.90, 10.91, 10.91, 10.91, 10.91, 10.90, 10.90, 10.89, 10.88, 10.86, 10.84, 10.82, 10.80, 10.77, 10.75, 10.72, 10.68, 10.65, 10.61, 10.57, 10.53, 10.48, 10.43, 10.38, 10.33, 10.27, 10.21, 10.15, 10.08, 10.02, 9.95, 9.88, 9.80, 9.72, 9.64, 9.56, 9.48, 9.39, 9.30, 9.20, 9.11, 9.01, 8.91, 8.81, 8.70, 8.59, 8.48, 8.37, 8.25, 8.13, 8.01, 7.89],
[9.43, 9.73, 10.07, 10.43, 10.82, 10.85, 10.88, 10.90, 10.92, 10.94, 10.96, 10.97, 10.98, 10.99, 11.00, 11.00, 11.00, 11.00, 11.00, 10.99, 10.98, 10.97, 10.95, 10.94, 10.92, 10.89, 10.87, 10.84, 10.81, 10.78, 10.74, 10.70, 10.66, 10.62, 10.57, 10.52, 10.47, 10.42, 10.36, 10.30, 10.24, 10.18, 10.11, 10.04, 9.97, 9.89, 9.82, 9.74, 9.65, 9.57, 9.48, 9.39, 9.30, 9.20, 9.10, 9.00, 8.90, 8.79, 8.69, 8.57, 8.46, 8.34, 8.23, 8.10, 7.98],
[9.52, 9.83, 10.16, 10.53, 10.92, 10.94, 10.97, 11.00, 11.02, 11.04, 11.05, 11.07, 11.08, 11.09, 11.09, 11.10, 11.10, 11.09, 11.09, 11.08, 11.07, 11.06, 11.05, 11.03, 11.01, 10.99, 10.96, 10.93, 10.90, 10.87, 10.83, 10.80, 10.75, 10.71, 10.66, 10.62, 10.56, 10.51, 10.45, 10.40, 10.33, 10.27, 10.20, 10.13, 10.06, 9.99, 9.91, 9.83, 9.75, 9.66, 9.57, 9.48, 9.39, 9.29, 9.20, 9.10, 8.99, 8.89, 8.78, 8.67, 8.55, 8.44, 8.32, 8.20, 8.07],
[9.61, 9.92, 10.26, 10.62, 11.01, 11.04, 11.07, 11.09, 11.11, 11.13, 11.15, 11.16, 11.17, 11.18, 11.19, 11.19, 11.19, 11.19, 11.18, 11.18, 11.17, 11.15, 11.14, 11.12, 11.10, 11.08, 11.05, 11.03, 11.00, 10.96, 10.93, 10.89, 10.85, 10.80, 10.76, 10.71, 10.66, 10.60, 10.55, 10.49, 10.43, 10.36, 10.30, 10.23, 10.15, 10.08, 10.00, 9.92, 9.84, 9.75, 9.67, 9.58, 9.48, 9.39, 9.29, 9.19, 9.09, 8.98, 8.87, 8.76, 8.65, 8.53, 8.41, 8.29, 8.17],
[9.71, 10.02, 10.35, 10.71, 11.10, 11.13, 11.16, 11.18, 11.20, 11.22, 11.24, 11.25, 11.27, 11.27, 11.28, 11.28, 11.28, 11.28, 11.28, 11.27, 11.26, 11.25, 11.23, 11.22, 11.20, 11.17, 11.15, 11.12, 11.09, 11.06, 11.02, 10.98, 10.94, 10.90, 10.85, 10.80, 10.75, 10.70, 10.64, 10.58, 10.52, 10.46, 10.39, 10.32, 10.25, 10.17, 10.10, 10.02, 9.93, 9.85, 9.76, 9.67, 9.58, 9.48, 9.38, 9.28, 9.18, 9.07, 8.97, 8.85, 8.74, 8.62, 8.51, 8.38, 8.26],
[9.80, 10.11, 10.45, 10.81, 11.20, 11.23, 11.25, 11.28, 11.30, 11.32, 11.33, 11.35, 11.36, 11.37, 11.37, 11.38, 11.38, 11.38, 11.37, 11.37, 11.36, 11.34, 11.33, 11.31, 11.29, 11.27, 11.24, 11.21, 11.18, 11.15, 11.12, 11.08, 11.04, 10.99, 10.95, 10.90, 10.85, 10.79, 10.74, 10.68, 10.62, 10.55, 10.48, 10.42, 10.34, 10.27, 10.19, 10.11, 10.03, 9.94, 9.86, 9.77, 9.67, 9.58, 9.48, 9.38, 9.28, 9.17, 9.06, 8.95, 8.84, 8.72, 8.60, 8.48, 8.35],
[9.90, 10.21, 10.54, 10.90, 11.29, 11.32, 11.35, 11.37, 11.39, 11.41, 11.43, 11.44, 11.45, 11.46, 11.47, 11.47, 11.47, 11.47, 11.47, 11.46, 11.45, 11.44, 11.42, 11.41, 11.39, 11.36, 11.34, 11.31, 11.28, 11.25, 11.21, 11.17, 11.13, 11.09, 11.04, 10.99, 10.94, 10.89, 10.83, 10.77, 10.71, 10.65, 10.58, 10.51, 10.44, 10.36, 10.29, 10.21, 10.12, 10.04, 9.95, 9.86, 9.77, 9.67, 9.57, 9.47, 9.37, 9.26, 9.16, 9.04, 8.93, 8.81, 8.70, 8.57, 8.45],
[9.99, 10.30, 10.64, 11.00, 11.39, 11.42, 11.44, 11.47, 11.49, 11.51, 11.53, 11.54, 11.55, 11.56, 11.56, 11.57, 11.57, 11.57, 11.56, 11.56, 11.55, 11.53, 11.52, 11.50, 11.48, 11.46, 11.43, 11.41, 11.38, 11.34, 11.31, 11.27, 11.23, 11.18, 11.14, 11.09, 11.04, 10.98, 10.93, 10.87, 10.81, 10.74, 10.68, 10.61, 10.53, 10.46, 10.38, 10.30, 10.22, 10.13, 10.05, 9.96, 9.86, 9.77, 9.67, 9.57, 9.47, 9.36, 9.25, 9.14, 9.03, 8.91, 8.79, 8.67, 8.55],
[10.09, 10.40, 10.73, 11.10, 11.48, 11.51, 11.54, 11.56, 11.59, 11.61, 11.62, 11.64, 11.65, 11.66, 11.66, 11.66, 11.67, 11.66, 11.66, 11.65, 11.64, 11.63, 11.62, 11.60, 11.58, 11.55, 11.53, 11.50, 11.47, 11.44, 11.40, 11.36, 11.32, 11.28, 11.23, 11.19, 11.13, 11.08, 11.02, 10.96, 10.90, 10.84, 10.77, 10.70, 10.63, 10.56, 10.48, 10.40, 10.32, 10.23, 10.14, 10.05, 9.96, 9.86, 9.77, 9.67, 9.56, 9.46, 9.35, 9.24, 9.12, 9.01, 8.89, 8.77, 8.64],
[10.18, 10.49, 10.83, 11.19, 11.58, 11.61, 11.64, 11.66, 11.68, 11.70, 11.72, 11.73, 11.74, 11.75, 11.76, 11.76, 11.76, 11.76, 11.76, 11.75, 11.74, 11.73, 11.71, 11.69, 11.67, 11.65, 11.63, 11.60, 11.57, 11.53, 11.50, 11.46, 11.42, 11.38, 11.33, 11.28, 11.23, 11.18, 11.12, 11.06, 11.00, 10.94, 10.87, 10.80, 10.73, 10.65, 10.57, 10.49, 10.41, 10.33, 10.24, 10.15, 10.06, 9.96, 9.86, 9.76, 9.66, 9.55, 9.44, 9.33, 9.22, 9.10, 8.98, 8.86, 8.74],
[10.28, 10.59, 10.93, 11.29, 11.68, 11.71, 11.73, 11.76, 11.78, 11.80, 11.82, 11.83, 11.84, 11.85, 11.85, 11.86, 11.86, 11.86, 11.85, 11.85, 11.84, 11.82, 11.81, 11.79, 11.77, 11.75, 11.72, 11.70, 11.67, 11.63, 11.60, 11.56, 11.52, 11.47, 11.43, 11.38, 11.33, 11.27, 11.22, 11.16, 11.10, 11.03, 10.97, 10.90, 10.82, 10.75, 10.67, 10.59, 10.51, 10.42, 10.34, 10.25, 10.15, 10.06, 9.96, 9.86, 9.76, 9.65, 9.54, 9.43, 9.32, 9.20, 9.08, 8.96, 8.84],
[10.38, 10.69, 11.02, 11.39, 11.78, 11.81, 11.83, 11.86, 11.88, 11.90, 11.91, 11.93, 11.94, 11.95, 11.95, 11.96, 11.96, 11.95, 11.95, 11.94, 11.93, 11.92, 11.91, 11.89, 11.87, 11.85, 11.82, 11.79, 11.76, 11.73, 11.69, 11.66, 11.61, 11.57, 11.53, 11.48, 11.43, 11.37, 11.31, 11.26, 11.19, 11.13, 11.06, 10.99, 10.92, 10.85, 10.77, 10.69, 10.61, 10.52, 10.43, 10.34, 10.25, 10.16, 10.06, 9.96, 9.85, 9.75, 9.64, 9.53, 9.41, 9.30, 9.18, 9.06, 8.93],
[10.48, 10.79, 11.12, 11.49, 11.87, 11.90, 11.93, 11.95, 11.98, 11.99, 12.01, 12.02, 12.04, 12.04, 12.05, 12.05, 12.05, 12.05, 12.05, 12.04, 12.03, 12.02, 12.00, 11.99, 11.97, 11.94, 11.92, 11.89, 11.86, 11.83, 11.79, 11.75, 11.71, 11.67, 11.62, 11.57, 11.52, 11.47, 11.41, 11.35, 11.29, 11.23, 11.16, 11.09, 11.02, 10.94, 10.87, 10.79, 10.70, 10.62, 10.53, 10.44, 10.35, 10.25, 10.16, 10.05, 9.95, 9.85, 9.74, 9.63, 9.51, 9.40, 9.28, 9.16, 9.03],
[10.58, 10.89, 11.22, 11.58, 11.97, 12.00, 12.03, 12.05, 12.07, 12.09, 12.11, 12.12, 12.13, 12.14, 12.15, 12.15, 12.15, 12.15, 12.15, 12.14, 12.13, 12.12, 12.10, 12.09, 12.07, 12.04, 12.02, 11.99, 11.96, 11.93, 11.89, 11.85, 11.81, 11.77, 11.72, 11.67, 11.62, 11.57, 11.51, 11.45, 11.39, 11.33, 11.26, 11.19, 11.12, 11.04, 10.97, 10.89, 10.80, 10.72, 10.63, 10.54, 10.45, 10.35, 10.25, 10.15, 10.05, 9.94, 9.84, 9.72, 9.61, 9.49, 9.38, 9.25, 9.13],
[10.67, 10.98, 11.32, 11.68, 12.07, 12.10, 12.13, 12.15, 12.17, 12.19, 12.21, 12.22, 12.23, 12.24, 12.25, 12.25, 12.25, 12.25, 12.25, 12.24, 12.23, 12.22, 12.20, 12.18, 12.16, 12.14, 12.12, 12.09, 12.06, 12.03, 11.99, 11.95, 11.91, 11.87, 11.82, 11.77, 11.72, 11.67, 11.61, 11.55, 11.49, 11.43, 11.36, 11.29, 11.22, 11.14, 11.07, 10.99, 10.90, 10.82, 10.73, 10.64, 10.55, 10.45, 10.35, 10.25, 10.15, 10.04, 9.93, 9.82, 9.71, 9.59, 9.47, 9.35, 9.23],
[10.77, 11.08, 11.42, 11.78, 12.17, 12.20, 12.23, 12.25, 12.27, 12.29, 12.31, 12.32, 12.33, 12.34, 12.35, 12.35, 12.35, 12.35, 12.35, 12.34, 12.33, 12.32, 12.30, 12.28, 12.26, 12.24, 12.22, 12.19, 12.16, 12.12, 12.09, 12.05, 12.01, 11.97, 11.92, 11.87, 11.82, 11.77, 11.71, 11.65, 11.59, 11.53, 11.46, 11.39, 11.32, 11.24, 11.16, 11.08, 11.00, 10.92, 10.83, 10.74, 10.65, 10.55, 10.45, 10.35, 10.25, 10.14, 10.03, 9.92, 9.81, 9.69, 9.57, 9.45, 9.33],
[10.87, 11.18, 11.52, 11.88, 12.27, 12.30, 12.33, 12.35, 12.37, 12.39, 12.41, 12.42, 12.43, 12.44, 12.45, 12.45, 12.45, 12.45, 12.45, 12.44, 12.43, 12.42, 12.40, 12.38, 12.36, 12.34, 12.32, 12.29, 12.26, 12.23, 12.19, 12.15, 12.11, 12.07, 12.02, 11.97, 11.92, 11.87, 11.81, 11.75, 11.69, 11.63, 11.56, 11.49, 11.42, 11.34, 11.26, 11.18, 11.10, 11.02, 10.93, 10.84, 10.75, 10.65, 10.55, 10.45, 10.35, 10.24, 10.13, 10.02, 9.91, 9.79, 9.67, 9.55, 9.43],
[10.97, 11.28, 11.62, 11.98, 12.37, 12.40, 12.43, 12.45, 12.47, 12.49, 12.51, 12.52, 12.53, 12.54, 12.55, 12.55, 12.55, 12.55, 12.55, 12.54, 12.53, 12.52, 12.50, 12.49, 12.46, 12.44, 12.42, 12.39, 12.36, 12.33, 12.29, 12.25, 12.21, 12.17, 12.12, 12.07, 12.02, 11.97, 11.91, 11.85, 11.79, 11.73, 11.66, 11.59, 11.52, 11.44, 11.37, 11.29, 11.20, 11.12, 11.03, 10.94, 10.85, 10.75, 10.65, 10.55, 10.45, 10.34, 10.23, 10.12, 10.01, 9.89, 9.77, 9.65, 9.53],
[11.08, 11.39, 11.72, 12.08, 12.47, 12.50, 12.53, 12.55, 12.57, 12.59, 12.61, 12.62, 12.64, 12.64, 12.65, 12.65, 12.65, 12.65, 12.65, 12.64, 12.63, 12.62, 12.60, 12.59, 12.57, 12.54, 12.52, 12.49, 12.46, 12.43, 12.39, 12.35, 12.31, 12.27, 12.22, 12.17, 12.12, 12.07, 12.01, 11.95, 11.89, 11.83, 11.76, 11.69, 11.62, 11.54, 11.47, 11.39, 11.30, 11.22, 11.13, 11.04, 10.95, 10.85, 10.75, 10.65, 10.55, 10.44, 10.34, 10.22, 10.11, 9.99, 9.88, 9.75, 9.63],
[11.18, 11.49, 11.82, 12.19, 12.57, 12.60, 12.63, 12.65, 12.68, 12.70, 12.71, 12.73, 12.74, 12.75, 12.75, 12.75, 12.76, 12.75, 12.75, 12.74, 12.73, 12.72, 12.71, 12.69, 12.67, 12.65, 12.62, 12.59, 12.56, 12.53, 12.49, 12.45, 12.41, 12.37, 12.32, 12.28, 12.22, 12.17, 12.11, 12.05, 11.99, 11.93, 11.86, 11.79, 11.72, 11.65, 11.57, 11.49, 11.41, 11.32, 11.23, 11.14, 11.05, 10.95, 10.86, 10.76, 10.65, 10.55, 10.44, 10.33, 10.21, 10.10, 9.98, 9.86, 9.73],
[11.28, 11.59, 11.93, 12.29, 12.68, 12.71, 12.73, 12.76, 12.78, 12.80, 12.81, 12.83, 12.84, 12.85, 12.85, 12.86, 12.86, 12.86, 12.85, 12.84, 12.83, 12.82, 12.81, 12.79, 12.77, 12.75, 12.72, 12.69, 12.66, 12.63, 12.59, 12.56, 12.52, 12.47, 12.43, 12.38, 12.33, 12.27, 12.22, 12.16, 12.09, 12.03, 11.96, 11.89, 11.82, 11.75, 11.67, 11.59, 11.51, 11.42, 11.33, 11.24, 11.15, 11.06, 10.96, 10.86, 10.75, 10.65, 10.54, 10.43, 10.31, 10.20, 10.08, 9.96, 9.83],
[11.38, 11.69, 12.03, 12.39, 12.78, 12.81, 12.84, 12.86, 12.88, 12.90, 12.92, 12.93, 12.94, 12.95, 12.96, 12.96, 12.96, 12.96, 12.95, 12.95, 12.94, 12.92, 12.91, 12.89, 12.87, 12.85, 12.82, 12.80, 12.77, 12.73, 12.70, 12.66, 12.62, 12.57, 12.53, 12.48, 12.43, 12.37, 12.32, 12.26, 12.20, 12.13, 12.07, 12.00, 11.92, 11.85, 11.77, 11.69, 11.61, 11.53, 11.44, 11.35, 11.25, 11.16, 11.06, 10.96, 10.86, 10.75, 10.64, 10.53, 10.42, 10.30, 10.18, 10.06, 9.94],
[11.49, 11.80, 12.13, 12.49, 12.88, 12.91, 12.94, 12.96, 12.98, 13.00, 13.02, 13.03, 13.04, 13.05, 13.06, 13.06, 13.06, 13.06, 13.06, 13.05, 13.04, 13.03, 13.01, 13.00, 12.98, 12.95, 12.93, 12.90, 12.87, 12.84, 12.80, 12.76, 12.72, 12.68, 12.63, 12.58, 12.53, 12.48, 12.42, 12.36, 12.30, 12.24, 12.17, 12.10, 12.03, 11.95, 11.88, 11.80, 11.71, 11.63, 11.54, 11.45, 11.36, 11.26, 11.16, 11.06, 10.96, 10.85, 10.75, 10.63, 10.52, 10.40, 10.29, 10.16, 10.04]];

NHANESIIIPredData.PEF.female =
[[5.76, 5.79, 5.82, 5.84, 5.87, 5.89, 5.91, 5.93, 5.95, 5.96, 5.97, 5.98, 5.99, 6.00, 6.00, 6.01, 6.01, 6.00, 6.00, 5.99, 5.99, 5.98, 5.96, 5.95, 5.93, 5.92, 5.89, 5.87, 5.85, 5.82, 5.79, 5.76, 5.73, 5.69, 5.66, 5.62, 5.58, 5.53, 5.49, 5.44, 5.39, 5.34, 5.29, 5.23, 5.18, 5.12, 5.05, 4.99, 4.92, 4.86, 4.79, 4.71, 4.64, 4.56, 4.49, 4.41, 4.32, 4.24, 4.15, 4.06, 3.97, 3.88, 3.79],
[5.81, 5.84, 5.87, 5.90, 5.92, 5.94, 5.97, 5.98, 6.00, 6.02, 6.03, 6.04, 6.05, 6.05, 6.06, 6.06, 6.06, 6.06, 6.05, 6.05, 6.04, 6.03, 6.02, 6.00, 5.99, 5.97, 5.95, 5.93, 5.90, 5.88, 5.85, 5.82, 5.78, 5.75, 5.71, 5.67, 5.63, 5.59, 5.54, 5.50, 5.45, 5.40, 5.34, 5.29, 5.23, 5.17, 5.11, 5.04, 4.98, 4.91, 4.84, 4.77, 4.69, 4.62, 4.54, 4.46, 4.38, 4.29, 4.21, 4.12, 4.03, 3.94, 3.84],
[5.86, 5.90, 5.92, 5.95, 5.98, 6.00, 6.02, 6.04, 6.06, 6.07, 6.08, 6.09, 6.10, 6.11, 6.11, 6.11, 6.11, 6.11, 6.11, 6.10, 6.10, 6.09, 6.07, 6.06, 6.04, 6.02, 6.00, 5.98, 5.96, 5.93, 5.90, 5.87, 5.84, 5.80, 5.77, 5.73, 5.69, 5.64, 5.60, 5.55, 5.50, 5.45, 5.40, 5.34, 5.28, 5.22, 5.16, 5.10, 5.03, 4.97, 4.90, 4.82, 4.75, 4.67, 4.60, 4.51, 4.43, 4.35, 4.26, 4.17, 4.08, 3.99, 3.90],
[5.92, 5.95, 5.98, 6.01, 6.03, 6.05, 6.07, 6.09, 6.11, 6.13, 6.14, 6.15, 6.16, 6.16, 6.17, 6.17, 6.17, 6.17, 6.16, 6.16, 6.15, 6.14, 6.13, 6.11, 6.10, 6.08, 6.06, 6.04, 6.01, 5.99, 5.96, 5.93, 5.89, 5.86, 5.82, 5.78, 5.74, 5.70, 5.65, 5.61, 5.56, 5.51, 5.45, 5.40, 5.34, 5.28, 5.22, 5.15, 5.09, 5.02, 4.95, 4.88, 4.80, 4.73, 4.65, 4.57, 4.49, 4.40, 4.32, 4.23, 4.14, 4.05, 3.95],
[5.97, 6.01, 6.03, 6.06, 6.09, 6.11, 6.13, 6.15, 6.17, 6.18, 6.19, 6.20, 6.21, 6.22, 6.22, 6.23, 6.23, 6.22, 6.22, 6.21, 6.21, 6.20, 6.18, 6.17, 6.15, 6.13, 6.11, 6.09, 6.07, 6.04, 6.01, 5.98, 5.95, 5.91, 5.88, 5.84, 5.80, 5.75, 5.71, 5.66, 5.61, 5.56, 5.51, 5.45, 5.39, 5.33, 5.27, 5.21, 5.14, 5.08, 5.01, 4.93, 4.86, 4.78, 4.71, 4.63, 4.54, 4.46, 4.37, 4.28, 4.19, 4.10, 4.01],
[6.03, 6.06, 6.09, 6.12, 6.14, 6.17, 6.19, 6.20, 6.22, 6.24, 6.25, 6.26, 6.27, 6.27, 6.28, 6.28, 6.28, 6.28, 6.28, 6.27, 6.26, 6.25, 6.24, 6.22, 6.21, 6.19, 6.17, 6.15, 6.12, 6.10, 6.07, 6.04, 6.00, 5.97, 5.93, 5.89, 5.85, 5.81, 5.76, 5.72, 5.67, 5.62, 5.56, 5.51, 5.45, 5.39, 5.33, 5.26, 5.20, 5.13, 5.06, 4.99, 4.92, 4.84, 4.76, 4.68, 4.60, 4.51, 4.43, 4.34, 4.25, 4.16, 4.06],
[6.09, 6.12, 6.15, 6.17, 6.20, 6.22, 6.24, 6.26, 6.28, 6.29, 6.30, 6.32, 6.32, 6.33, 6.33, 6.34, 6.34, 6.34, 6.33, 6.33, 6.32, 6.31, 6.29, 6.28, 6.26, 6.25, 6.23, 6.20, 6.18, 6.15, 6.12, 6.09, 6.06, 6.03, 5.99, 5.95, 5.91, 5.87, 5.82, 5.77, 5.72, 5.67, 5.62, 5.56, 5.51, 5.45, 5.38, 5.32, 5.26, 5.19, 5.12, 5.05, 4.97, 4.90, 4.82, 4.74, 4.65, 4.57, 4.48, 4.40, 4.30, 4.21, 4.12],
[6.14, 6.17, 6.20, 6.23, 6.25, 6.28, 6.30, 6.32, 6.33, 6.35, 6.36, 6.37, 6.38, 6.39, 6.39, 6.39, 6.39, 6.39, 6.39, 6.38, 6.37, 6.36, 6.35, 6.34, 6.32, 6.30, 6.28, 6.26, 6.24, 6.21, 6.18, 6.15, 6.12, 6.08, 6.04, 6.01, 5.96, 5.92, 5.88, 5.83, 5.78, 5.73, 5.68, 5.62, 5.56, 5.50, 5.44, 5.38, 5.31, 5.24, 5.17, 5.10, 5.03, 4.95, 4.87, 4.79, 4.71, 4.63, 4.54, 4.45, 4.36, 4.27, 4.17],
[6.20, 6.23, 6.26, 6.29, 6.31, 6.33, 6.36, 6.37, 6.39, 6.41, 6.42, 6.43, 6.44, 6.44, 6.45, 6.45, 6.45, 6.45, 6.44, 6.44, 6.43, 6.42, 6.41, 6.39, 6.38, 6.36, 6.34, 6.32, 6.29, 6.27, 6.24, 6.21, 6.17, 6.14, 6.10, 6.06, 6.02, 5.98, 5.93, 5.89, 5.84, 5.79, 5.73, 5.68, 5.62, 5.56, 5.50, 5.43, 5.37, 5.30, 5.23, 5.16, 5.08, 5.01, 4.93, 4.85, 4.77, 4.68, 4.60, 4.51, 4.42, 4.33, 4.23],
[6.26, 6.29, 6.32, 6.34, 6.37, 6.39, 6.41, 6.43, 6.45, 6.46, 6.48, 6.49, 6.49, 6.50, 6.50, 6.51, 6.51, 6.51, 6.50, 6.50, 6.49, 6.48, 6.47, 6.45, 6.43, 6.42, 6.40, 6.37, 6.35, 6.32, 6.29, 6.26, 6.23, 6.20, 6.16, 6.12, 6.08, 6.04, 5.99, 5.94, 5.89, 5.84, 5.79, 5.73, 5.68, 5.62, 5.55, 5.49, 5.43, 5.36, 5.29, 5.22, 5.14, 5.07, 4.99, 4.91, 4.83, 4.74, 4.65, 4.57, 4.48, 4.38, 4.29],
[6.31, 6.35, 6.37, 6.40, 6.43, 6.45, 6.47, 6.49, 6.51, 6.52, 6.53, 6.54, 6.55, 6.56, 6.56, 6.56, 6.56, 6.56, 6.56, 6.55, 6.55, 6.54, 6.52, 6.51, 6.49, 6.47, 6.45, 6.43, 6.41, 6.38, 6.35, 6.32, 6.29, 6.25, 6.22, 6.18, 6.14, 6.09, 6.05, 6.00, 5.95, 5.90, 5.85, 5.79, 5.73, 5.67, 5.61, 5.55, 5.48, 5.42, 5.35, 5.27, 5.20, 5.12, 5.05, 4.96, 4.88, 4.80, 4.71, 4.62, 4.53, 4.44, 4.35],
[6.37, 6.40, 6.43, 6.46, 6.48, 6.51, 6.53, 6.55, 6.56, 6.58, 6.59, 6.60, 6.61, 6.62, 6.62, 6.62, 6.62, 6.62, 6.62, 6.61, 6.60, 6.59, 6.58, 6.57, 6.55, 6.53, 6.51, 6.49, 6.46, 6.44, 6.41, 6.38, 6.35, 6.31, 6.27, 6.24, 6.19, 6.15, 6.11, 6.06, 6.01, 5.96, 5.90, 5.85, 5.79, 5.73, 5.67, 5.61, 5.54, 5.47, 5.40, 5.33, 5.26, 5.18, 5.10, 5.02, 4.94, 4.86, 4.77, 4.68, 4.59, 4.50, 4.40],
[6.43, 6.46, 6.49, 6.52, 6.54, 6.57, 6.59, 6.60, 6.62, 6.64, 6.65, 6.66, 6.67, 6.67, 6.68, 6.68, 6.68, 6.68, 6.68, 6.67, 6.66, 6.65, 6.64, 6.62, 6.61, 6.59, 6.57, 6.55, 6.52, 6.50, 6.47, 6.44, 6.40, 6.37, 6.33, 6.29, 6.25, 6.21, 6.16, 6.12, 6.07, 6.02, 5.96, 5.91, 5.85, 5.79, 5.73, 5.66, 5.60, 5.53, 5.46, 5.39, 5.32, 5.24, 5.16, 5.08, 5.00, 4.91, 4.83, 4.74, 4.65, 4.56, 4.46],
[6.49, 6.52, 6.55, 6.58, 6.60, 6.62, 6.64, 6.66, 6.68, 6.69, 6.71, 6.72, 6.73, 6.73, 6.74, 6.74, 6.74, 6.74, 6.73, 6.73, 6.72, 6.71, 6.70, 6.68, 6.67, 6.65, 6.63, 6.61, 6.58, 6.55, 6.53, 6.50, 6.46, 6.43, 6.39, 6.35, 6.31, 6.27, 6.22, 6.18, 6.13, 6.07, 6.02, 5.97, 5.91, 5.85, 5.79, 5.72, 5.66, 5.59, 5.52, 5.45, 5.37, 5.30, 5.22, 5.14, 5.06, 4.97, 4.89, 4.80, 4.71, 4.62, 4.52],
[6.55, 6.58, 6.61, 6.64, 6.66, 6.68, 6.70, 6.72, 6.74, 6.75, 6.77, 6.78, 6.79, 6.79, 6.80, 6.80, 6.80, 6.80, 6.79, 6.79, 6.78, 6.77, 6.76, 6.74, 6.73, 6.71, 6.69, 6.67, 6.64, 6.61, 6.59, 6.55, 6.52, 6.49, 6.45, 6.41, 6.37, 6.33, 6.28, 6.23, 6.19, 6.13, 6.08, 6.03, 5.97, 5.91, 5.85, 5.78, 5.72, 5.65, 5.58, 5.51, 5.43, 5.36, 5.28, 5.20, 5.12, 5.03, 4.95, 4.86, 4.77, 4.67, 4.58],
[6.61, 6.64, 6.67, 6.69, 6.72, 6.74, 6.76, 6.78, 6.80, 6.81, 6.83, 6.84, 6.84, 6.85, 6.86, 6.86, 6.86, 6.86, 6.85, 6.85, 6.84, 6.83, 6.82, 6.80, 6.79, 6.77, 6.75, 6.72, 6.70, 6.67, 6.64, 6.61, 6.58, 6.55, 6.51, 6.47, 6.43, 6.39, 6.34, 6.29, 6.24, 6.19, 6.14, 6.08, 6.03, 5.97, 5.91, 5.84, 5.78, 5.71, 5.64, 5.57, 5.49, 5.42, 5.34, 5.26, 5.18, 5.09, 5.01, 4.92, 4.83, 4.73, 4.64],
[6.67, 6.70, 6.73, 6.75, 6.78, 6.80, 6.82, 6.84, 6.86, 6.87, 6.89, 6.90, 6.90, 6.91, 6.92, 6.92, 6.92, 6.92, 6.91, 6.91, 6.90, 6.89, 6.88, 6.86, 6.85, 6.83, 6.81, 6.78, 6.76, 6.73, 6.70, 6.67, 6.64, 6.61, 6.57, 6.53, 6.49, 6.45, 6.40, 6.35, 6.30, 6.25, 6.20, 6.14, 6.09, 6.03, 5.97, 5.90, 5.84, 5.77, 5.70, 5.63, 5.55, 5.48, 5.40, 5.32, 5.24, 5.15, 5.06, 4.98, 4.89, 4.79, 4.70],
[6.73, 6.76, 6.79, 6.81, 6.84, 6.86, 6.88, 6.90, 6.92, 6.93, 6.95, 6.96, 6.96, 6.97, 6.98, 6.98, 6.98, 6.98, 6.97, 6.97, 6.96, 6.95, 6.94, 6.92, 6.91, 6.89, 6.87, 6.84, 6.82, 6.79, 6.76, 6.73, 6.70, 6.67, 6.63, 6.59, 6.55, 6.51, 6.46, 6.41, 6.36, 6.31, 6.26, 6.20, 6.15, 6.09, 6.03, 5.96, 5.90, 5.83, 5.76, 5.69, 5.61, 5.54, 5.46, 5.38, 5.30, 5.21, 5.13, 5.04, 4.95, 4.85, 4.76],
[6.79, 6.82, 6.85, 6.88, 6.90, 6.92, 6.94, 6.96, 6.98, 6.99, 7.01, 7.02, 7.03, 7.03, 7.04, 7.04, 7.04, 7.04, 7.03, 7.03, 7.02, 7.01, 7.00, 6.98, 6.97, 6.95, 6.93, 6.90, 6.88, 6.85, 6.83, 6.79, 6.76, 6.73, 6.69, 6.65, 6.61, 6.57, 6.52, 6.47, 6.43, 6.37, 6.32, 6.26, 6.21, 6.15, 6.09, 6.02, 5.96, 5.89, 5.82, 5.75, 5.67, 5.60, 5.52, 5.44, 5.36, 5.27, 5.19, 5.10, 5.01, 4.91, 4.82],
[6.85, 6.88, 6.91, 6.94, 6.96, 6.98, 7.00, 7.02, 7.04, 7.05, 7.07, 7.08, 7.09, 7.09, 7.10, 7.10, 7.10, 7.10, 7.09, 7.09, 7.08, 7.07, 7.06, 7.04, 7.03, 7.01, 6.99, 6.97, 6.94, 6.91, 6.89, 6.86, 6.82, 6.79, 6.75, 6.71, 6.67, 6.63, 6.58, 6.54, 6.49, 6.43, 6.38, 6.33, 6.27, 6.21, 6.15, 6.08, 6.02, 5.95, 5.88, 5.81, 5.73, 5.66, 5.58, 5.50, 5.42, 5.33, 5.25, 5.16, 5.07, 4.97, 4.88],
[6.91, 6.94, 6.97, 7.00, 7.02, 7.05, 7.07, 7.08, 7.10, 7.12, 7.13, 7.14, 7.15, 7.15, 7.16, 7.16, 7.16, 7.16, 7.16, 7.15, 7.14, 7.13, 7.12, 7.10, 7.09, 7.07, 7.05, 7.03, 7.00, 6.98, 6.95, 6.92, 6.88, 6.85, 6.81, 6.77, 6.73, 6.69, 6.64, 6.60, 6.55, 6.50, 6.44, 6.39, 6.33, 6.27, 6.21, 6.14, 6.08, 6.01, 5.94, 5.87, 5.80, 5.72, 5.64, 5.56, 5.48, 5.39, 5.31, 5.22, 5.13, 5.04, 4.94],
[6.97, 7.00, 7.03, 7.06, 7.08, 7.11, 7.13, 7.15, 7.16, 7.18, 7.19, 7.20, 7.21, 7.22, 7.22, 7.22, 7.22, 7.22, 7.22, 7.21, 7.20, 7.19, 7.18, 7.17, 7.15, 7.13, 7.11, 7.09, 7.06, 7.04, 7.01, 6.98, 6.95, 6.91, 6.87, 6.83, 6.79, 6.75, 6.71, 6.66, 6.61, 6.56, 6.50, 6.45, 6.39, 6.33, 6.27, 6.21, 6.14, 6.07, 6.00, 5.93, 5.86, 5.78, 5.70, 5.62, 5.54, 5.46, 5.37, 5.28, 5.19, 5.10, 5.00],
[7.03, 7.06, 7.09, 7.12, 7.15, 7.17, 7.19, 7.21, 7.23, 7.24, 7.25, 7.26, 7.27, 7.28, 7.28, 7.28, 7.28, 7.28, 7.28, 7.27, 7.26, 7.25, 7.24, 7.23, 7.21, 7.19, 7.17, 7.15, 7.13, 7.10, 7.07, 7.04, 7.01, 6.97, 6.94, 6.90, 6.86, 6.81, 6.77, 6.72, 6.67, 6.62, 6.57, 6.51, 6.45, 6.39, 6.33, 6.27, 6.20, 6.13, 6.06, 5.99, 5.92, 5.84, 5.76, 5.68, 5.60, 5.52, 5.43, 5.34, 5.25, 5.16, 5.07],
[7.10, 7.13, 7.16, 7.18, 7.21, 7.23, 7.25, 7.27, 7.29, 7.30, 7.31, 7.33, 7.33, 7.34, 7.34, 7.35, 7.35, 7.35, 7.34, 7.34, 7.33, 7.32, 7.30, 7.29, 7.27, 7.26, 7.24, 7.21, 7.19, 7.16, 7.13, 7.10, 7.07, 7.04, 7.00, 6.96, 6.92, 6.88, 6.83, 6.78, 6.73, 6.68, 6.63, 6.57, 6.52, 6.46, 6.39, 6.33, 6.26, 6.20, 6.13, 6.06, 5.98, 5.91, 5.83, 5.75, 5.66, 5.58, 5.49, 5.41, 5.31, 5.22, 5.13],
[7.16, 7.19, 7.22, 7.25, 7.27, 7.29, 7.31, 7.33, 7.35, 7.36, 7.38, 7.39, 7.40, 7.40, 7.41, 7.41, 7.41, 7.41, 7.40, 7.40, 7.39, 7.38, 7.37, 7.35, 7.34, 7.32, 7.30, 7.28, 7.25, 7.22, 7.20, 7.17, 7.13, 7.10, 7.06, 7.02, 6.98, 6.94, 6.89, 6.85, 6.80, 6.74, 6.69, 6.64, 6.58, 6.52, 6.46, 6.39, 6.33, 6.26, 6.19, 6.12, 6.04, 5.97, 5.89, 5.81, 5.73, 5.64, 5.56, 5.47, 5.38, 5.29, 5.19],
[7.22, 7.25, 7.28, 7.31, 7.33, 7.36, 7.38, 7.40, 7.41, 7.43, 7.44, 7.45, 7.46, 7.47, 7.47, 7.47, 7.47, 7.47, 7.47, 7.46, 7.45, 7.44, 7.43, 7.42, 7.40, 7.38, 7.36, 7.34, 7.31, 7.29, 7.26, 7.23, 7.20, 7.16, 7.12, 7.09, 7.04, 7.00, 6.96, 6.91, 6.86, 6.81, 6.75, 6.70, 6.64, 6.58, 6.52, 6.46, 6.39, 6.32, 6.25, 6.18, 6.11, 6.03, 5.95, 5.87, 5.79, 5.71, 5.62, 5.53, 5.44, 5.35, 5.25],
[7.29, 7.32, 7.35, 7.37, 7.40, 7.42, 7.44, 7.46, 7.48, 7.49, 7.50, 7.51, 7.52, 7.53, 7.53, 7.54, 7.54, 7.53, 7.53, 7.52, 7.52, 7.51, 7.49, 7.48, 7.46, 7.45, 7.42, 7.40, 7.38, 7.35, 7.32, 7.29, 7.26, 7.22, 7.19, 7.15, 7.11, 7.06, 7.02, 6.97, 6.92, 6.87, 6.82, 6.76, 6.71, 6.65, 6.58, 6.52, 6.45, 6.39, 6.32, 6.24, 6.17, 6.09, 6.02, 5.94, 5.85, 5.77, 5.68, 5.59, 5.50, 5.41, 5.32],
[7.35, 7.38, 7.41, 7.44, 7.46, 7.48, 7.51, 7.52, 7.54, 7.56, 7.57, 7.58, 7.59, 7.59, 7.60, 7.60, 7.60, 7.60, 7.59, 7.59, 7.58, 7.57, 7.56, 7.54, 7.53, 7.51, 7.49, 7.47, 7.44, 7.42, 7.39, 7.36, 7.32, 7.29, 7.25, 7.21, 7.17, 7.13, 7.08, 7.04, 6.99, 6.94, 6.88, 6.83, 6.77, 6.71, 6.65, 6.58, 6.52, 6.45, 6.38, 6.31, 6.23, 6.16, 6.08, 6.00, 5.92, 5.83, 5.75, 5.66, 5.57, 5.48, 5.38],
[7.41, 7.44, 7.47, 7.50, 7.53, 7.55, 7.57, 7.59, 7.60, 7.62, 7.63, 7.64, 7.65, 7.66, 7.66, 7.66, 7.66, 7.66, 7.66, 7.65, 7.64, 7.63, 7.62, 7.61, 7.59, 7.57, 7.55, 7.53, 7.51, 7.48, 7.45, 7.42, 7.39, 7.35, 7.32, 7.28, 7.24, 7.19, 7.15, 7.10, 7.05, 7.00, 6.95, 6.89, 6.83, 6.77, 6.71, 6.65, 6.58, 6.51, 6.44, 6.37, 6.30, 6.22, 6.14, 6.06, 5.98, 5.90, 5.81, 5.72, 5.63, 5.54, 5.45],
[7.48, 7.51, 7.54, 7.57, 7.59, 7.61, 7.63, 7.65, 7.67, 7.68, 7.70, 7.71, 7.72, 7.72, 7.73, 7.73, 7.73, 7.73, 7.72, 7.72, 7.71, 7.70, 7.69, 7.67, 7.66, 7.64, 7.62, 7.60, 7.57, 7.54, 7.52, 7.48, 7.45, 7.42, 7.38, 7.34, 7.30, 7.26, 7.21, 7.16, 7.12, 7.06, 7.01, 6.96, 6.90, 6.84, 6.78, 6.71, 6.65, 6.58, 6.51, 6.44, 6.36, 6.29, 6.21, 6.13, 6.05, 5.96, 5.88, 5.79, 5.70, 5.60, 5.51],
[7.54, 7.57, 7.60, 7.63, 7.66, 7.68, 7.70, 7.72, 7.73, 7.75, 7.76, 7.77, 7.78, 7.79, 7.79, 7.79, 7.79, 7.79, 7.79, 7.78, 7.77, 7.76, 7.75, 7.74, 7.72, 7.70, 7.68, 7.66, 7.64, 7.61, 7.58, 7.55, 7.52, 7.48, 7.45, 7.41, 7.37, 7.32, 7.28, 7.23, 7.18, 7.13, 7.08, 7.02, 6.96, 6.90, 6.84, 6.78, 6.71, 6.64, 6.57, 6.50, 6.43, 6.35, 6.27, 6.19, 6.11, 6.03, 5.94, 5.85, 5.76, 5.67, 5.57],
[7.61, 7.64, 7.67, 7.70, 7.72, 7.74, 7.76, 7.78, 7.80, 7.81, 7.83, 7.84, 7.85, 7.85, 7.86, 7.86, 7.86, 7.86, 7.85, 7.85, 7.84, 7.83, 7.82, 7.80, 7.79, 7.77, 7.75, 7.73, 7.70, 7.67, 7.65, 7.62, 7.58, 7.55, 7.51, 7.47, 7.43, 7.39, 7.34, 7.30, 7.25, 7.19, 7.14, 7.09, 7.03, 6.97, 6.91, 6.84, 6.78, 6.71, 6.64, 6.57, 6.49, 6.42, 6.34, 6.26, 6.18, 6.09, 6.01, 5.92, 5.83, 5.73, 5.64],
[7.67, 7.71, 7.73, 7.76, 7.79, 7.81, 7.83, 7.85, 7.87, 7.88, 7.89, 7.90, 7.91, 7.92, 7.92, 7.92, 7.93, 7.92, 7.92, 7.91, 7.91, 7.90, 7.88, 7.87, 7.85, 7.83, 7.81, 7.79, 7.77, 7.74, 7.71, 7.68, 7.65, 7.61, 7.58, 7.54, 7.50, 7.45, 7.41, 7.36, 7.31, 7.26, 7.21, 7.15, 7.09, 7.03, 6.97, 6.91, 6.84, 6.78, 6.71, 6.63, 6.56, 6.48, 6.41, 6.33, 6.24, 6.16, 6.07, 5.98, 5.89, 5.80, 5.71],
[7.74, 7.77, 7.80, 7.83, 7.85, 7.88, 7.90, 7.92, 7.93, 7.95, 7.96, 7.97, 7.98, 7.98, 7.99, 7.99, 7.99, 7.99, 7.99, 7.98, 7.97, 7.96, 7.95, 7.93, 7.92, 7.90, 7.88, 7.86, 7.83, 7.81, 7.78, 7.75, 7.71, 7.68, 7.64, 7.60, 7.56, 7.52, 7.47, 7.43, 7.38, 7.33, 7.27, 7.22, 7.16, 7.10, 7.04, 6.98, 6.91, 6.84, 6.77, 6.70, 6.63, 6.55, 6.47, 6.39, 6.31, 6.22, 6.14, 6.05, 5.96, 5.87, 5.77],
[7.81, 7.84, 7.87, 7.89, 7.92, 7.94, 7.96, 7.98, 8.00, 8.01, 8.03, 8.04, 8.04, 8.05, 8.06, 8.06, 8.06, 8.06, 8.05, 8.05, 8.04, 8.03, 8.02, 8.00, 7.99, 7.97, 7.95, 7.92, 7.90, 7.87, 7.84, 7.81, 7.78, 7.75, 7.71, 7.67, 7.63, 7.59, 7.54, 7.49, 7.44, 7.39, 7.34, 7.28, 7.23, 7.17, 7.11, 7.04, 6.98, 6.91, 6.84, 6.77, 6.69, 6.62, 6.54, 6.46, 6.38, 6.29, 6.20, 6.12, 6.03, 5.93, 5.84],
[7.87, 7.90, 7.93, 7.96, 7.99, 8.01, 8.03, 8.05, 8.07, 8.08, 8.09, 8.10, 8.11, 8.12, 8.12, 8.12, 8.12, 8.12, 8.12, 8.11, 8.10, 8.09, 8.08, 8.07, 8.05, 8.03, 8.01, 7.99, 7.97, 7.94, 7.91, 7.88, 7.85, 7.81, 7.78, 7.74, 7.70, 7.65, 7.61, 7.56, 7.51, 7.46, 7.41, 7.35, 7.29, 7.23, 7.17, 7.11, 7.04, 6.97, 6.90, 6.83, 6.76, 6.68, 6.60, 6.52, 6.44, 6.36, 6.27, 6.18, 6.09, 6.00, 5.91],
[7.94, 7.97, 8.00, 8.03, 8.05, 8.08, 8.10, 8.12, 8.13, 8.15, 8.16, 8.17, 8.18, 8.18, 8.19, 8.19, 8.19, 8.19, 8.19, 8.18, 8.17, 8.16, 8.15, 8.14, 8.12, 8.10, 8.08, 8.06, 8.03, 8.01, 7.98, 7.95, 7.91, 7.88, 7.84, 7.80, 7.76, 7.72, 7.67, 7.63, 7.58, 7.53, 7.47, 7.42, 7.36, 7.30, 7.24, 7.18, 7.11, 7.04, 6.97, 6.90, 6.83, 6.75, 6.67, 6.59, 6.51, 6.43, 6.34, 6.25, 6.16, 6.07, 5.97],
[8.01, 8.04, 8.07, 8.10, 8.12, 8.14, 8.16, 8.18, 8.20, 8.21, 8.23, 8.24, 8.25, 8.25, 8.26, 8.26, 8.26, 8.26, 8.25, 8.25, 8.24, 8.23, 8.22, 8.20, 8.19, 8.17, 8.15, 8.13, 8.10, 8.07, 8.05, 8.02, 7.98, 7.95, 7.91, 7.87, 7.83, 7.79, 7.74, 7.70, 7.65, 7.59, 7.54, 7.49, 7.43, 7.37, 7.31, 7.24, 7.18, 7.11, 7.04, 6.97, 6.89, 6.82, 6.74, 6.66, 6.58, 6.49, 6.41, 6.32, 6.23, 6.13, 6.04],
[8.08, 8.11, 8.14, 8.16, 8.19, 8.21, 8.23, 8.25, 8.27, 8.28, 8.30, 8.31, 8.31, 8.32, 8.32, 8.33, 8.33, 8.33, 8.32, 8.32, 8.31, 8.30, 8.29, 8.27, 8.25, 8.24, 8.22, 8.19, 8.17, 8.14, 8.11, 8.08, 8.05, 8.02, 7.98, 7.94, 7.90, 7.86, 7.81, 7.76, 7.71, 7.66, 7.61, 7.55, 7.50, 7.44, 7.37, 7.31, 7.25, 7.18, 7.11, 7.04, 6.96, 6.89, 6.81, 6.73, 6.65, 6.56, 6.47, 6.39, 6.30, 6.20, 6.11],
[8.14, 8.18, 8.21, 8.23, 8.26, 8.28, 8.30, 8.32, 8.34, 8.35, 8.36, 8.37, 8.38, 8.39, 8.39, 8.40, 8.40, 8.39, 8.39, 8.38, 8.38, 8.37, 8.35, 8.34, 8.32, 8.30, 8.28, 8.26, 8.24, 8.21, 8.18, 8.15, 8.12, 8.08, 8.05, 8.01, 7.97, 7.92, 7.88, 7.83, 7.78, 7.73, 7.68, 7.62, 7.56, 7.50, 7.44, 7.38, 7.31, 7.25, 7.18, 7.10, 7.03, 6.95, 6.88, 6.80, 6.71, 6.63, 6.54, 6.45, 6.36, 6.27, 6.18],
[8.21, 8.24, 8.27, 8.30, 8.33, 8.35, 8.37, 8.39, 8.41, 8.42, 8.43, 8.44, 8.45, 8.46, 8.46, 8.46, 8.46, 8.46, 8.46, 8.45, 8.44, 8.43, 8.42, 8.41, 8.39, 8.37, 8.35, 8.33, 8.31, 8.28, 8.25, 8.22, 8.19, 8.15, 8.12, 8.08, 8.04, 7.99, 7.95, 7.90, 7.85, 7.80, 7.75, 7.69, 7.63, 7.57, 7.51, 7.45, 7.38, 7.31, 7.24, 7.17, 7.10, 7.02, 6.94, 6.86, 6.78, 6.70, 6.61, 6.52, 6.43, 6.34, 6.25],
[8.28, 8.31, 8.34, 8.37, 8.39, 8.42, 8.44, 8.46, 8.47, 8.49, 8.50, 8.51, 8.52, 8.53, 8.53, 8.53, 8.53, 8.53, 8.53, 8.52, 8.51, 8.50, 8.49, 8.48, 8.46, 8.44, 8.42, 8.40, 8.38, 8.35, 8.32, 8.29, 8.26, 8.22, 8.18, 8.15, 8.10, 8.06, 8.02, 7.97, 7.92, 7.87, 7.82, 7.76, 7.70, 7.64, 7.58, 7.52, 7.45, 7.38, 7.31, 7.24, 7.17, 7.09, 7.01, 6.93, 6.85, 6.77, 6.68, 6.59, 6.50, 6.41, 6.31],
[8.35, 8.38, 8.41, 8.44, 8.46, 8.49, 8.51, 8.53, 8.54, 8.56, 8.57, 8.58, 8.59, 8.60, 8.60, 8.60, 8.60, 8.60, 8.60, 8.59, 8.58, 8.57, 8.56, 8.55, 8.53, 8.51, 8.49, 8.47, 8.44, 8.42, 8.39, 8.36, 8.33, 8.29, 8.25, 8.22, 8.17, 8.13, 8.09, 8.04, 7.99, 7.94, 7.88, 7.83, 7.77, 7.71, 7.65, 7.59, 7.52, 7.45, 7.38, 7.31, 7.24, 7.16, 7.08, 7.00, 6.92, 6.84, 6.75, 6.66, 6.57, 6.48, 6.38],
[8.42, 8.45, 8.48, 8.51, 8.53, 8.56, 8.58, 8.60, 8.61, 8.63, 8.64, 8.65, 8.66, 8.67, 8.67, 8.67, 8.67, 8.67, 8.67, 8.66, 8.65, 8.64, 8.63, 8.62, 8.60, 8.58, 8.56, 8.54, 8.51, 8.49, 8.46, 8.43, 8.40, 8.36, 8.32, 8.29, 8.24, 8.20, 8.16, 8.11, 8.06, 8.01, 7.95, 7.90, 7.84, 7.78, 7.72, 7.66, 7.59, 7.52, 7.45, 7.38, 7.31, 7.23, 7.15, 7.07, 6.99, 6.91, 6.82, 6.73, 6.64, 6.55, 6.45],
[8.49, 8.52, 8.55, 8.58, 8.60, 8.63, 8.65, 8.67, 8.68, 8.70, 8.71, 8.72, 8.73, 8.74, 8.74, 8.74, 8.74, 8.74, 8.74, 8.73, 8.72, 8.71, 8.70, 8.69, 8.67, 8.65, 8.63, 8.61, 8.58, 8.56, 8.53, 8.50, 8.47, 8.43, 8.39, 8.36, 8.31, 8.27, 8.23, 8.18, 8.13, 8.08, 8.02, 7.97, 7.91, 7.85, 7.79, 7.73, 7.66, 7.59, 7.52, 7.45, 7.38, 7.30, 7.22, 7.14, 7.06, 6.98, 6.89, 6.80, 6.71, 6.62, 6.52]];

window.NHANESIIIPredData = NHANESIIIPredData

}
if (typeof window !== 'undefined') {

/*
    bcSpiroViewer - the main viewer controller
*/
var bcSpiroViewer = function(params) {
    $(document).ready($.proxy(this._init, this, params));
};

/*
    Init the viewer and decide what to do according to the parameters
*/
bcSpiroViewer.prototype._init = function(params) {
    this.DEBUG = true; 
    
    this._params = params;
    
    this.spiroTypes = {
        BEE_SPIRO: 0,
        POND: 1
    },
    
    this._spiroType = this._params.spiroType;
    
    if (this._params.calibration) {
        $('#bc_spiro_viewer').addClass('calibration');
    }
    
    // localize via js if lang is set
    if (typeof params.lang !== 'undefined') {
        this._localize(params.lang);
    }
    
    this._predictor = this._params.predictor;
    
    // Gender enum
    this._gender = {
        'MALE': 1,
        'FEMALE': 2,
        'UNSPECIFIED': 0
    },
    
    this._testRecord = {
        header: {
            patientDetails: {
            },
            predictor: 0,
            mobileDeviceInfo: '',
            swVersion: '',
            calculationsVersion: 1            
        },
        tests: [],
        bestTest: {},
        footer: {}
    };
    
    this._genderNames = [bcGlobals.locale.unspecified, bcGlobals.locale.male, bcGlobals.locale.female];
      
    var recordRef = (this._params.recordHash || this._params.recordURL);
    this._isSavedRecord = recordRef;
    this._readyToPrint = false;

    this._patientDetails = {};

    if (this._params.patientDetails) {
        this._patientDetails = JSON.parse(this._params.patientDetails);
    }

    this.dataFetcher = (this._params.recordURL) ? (new FileDataFetcher()) : (new ServerDataFetcher());
    this.reportMode = this._params.reportMode;
    
    this.spiroViewerApi = new bcSpiroViewerApi(this);
    this._resultsManager = new bcSpiroViewerResultsManager(this);

    if (this._params.calibration || !this._isSavedRecord) {
        this.pitchManager = new pitchManager(this, this.dataFetcher);
    }

    if (this._params.calibration) {
        this._calibrationManager = new calibrationManager(this, this.dataFetcher, this.pitchManager);
        this._calibrationManager.start();
    } else if (!this._isSavedRecord) {
        this._testManager = new bcSpiroViewerTestManager(this, this.dataFetcher, this.pitchManager);
        this._resultsManager.fillPatientDetails(this._patientDetails);
        this._startTest();
    } else {
        this._handleSavedRecord(recordRef);
    }
};

/*
    Start spirometer test
*/
bcSpiroViewer.prototype.getSpiroType = function() {
    return this._spiroType;
};

/*
    Start spirometer test
*/
bcSpiroViewer.prototype._startTest = function() {
    this._resultsManager.hideResults();
    this._testManager.start();
};

/*
    Handle on spirometer test done
*/
bcSpiroViewer.prototype.onTestDone = function(testResults) {
    this._resultsManager.calculateResults(testResults);
    this._resultsManager.showResults(this._predictor, this._patientDetails);
};

/*
    Handle results save
*/
bcSpiroViewer.prototype._handleSavedRecord = function(recordRef) {
    this.dataFetcher.getSpirometerRecordData(recordRef, $.proxy(this.handleSpirometerRecordData, this));
};

/*
    Handle saved record data
*/
bcSpiroViewer.prototype.handleSpirometerRecordData = function(recordData) {
    this._testRecord = recordData;

    if (typeof this._testRecord.header.predictor !== 'undefined') {
        this._predictor = this._testRecord.header.predictor;
    }
        
    // editPatient means we changed something in the record and we want to rewrite the data.
    // For now we only allow changing the patient
    if (this._params.editPatient !== "true") {
        this._patientDetails = this._testRecord.header.patientDetails;
    } else {
        this._testRecord.header.patientDetails = this._patientDetails;
        this.dataFetcher.editSpirometerResults(this._params.recordHash, this._testRecord);
    }
    
    this._resultsManager.fillPatientDetails(this._patientDetails);
    this._resultsManager.showSavedResults(recordData, this._predictor, this._patientDetails);
};

/*
    Handle window unload event
*/
bcSpiroViewer.prototype.onWindowUnload = function() {
    this.writeToLogFile("Spirometer window unload");
    this._testManager.onWindowUnload();
    
    if (this._params.calibration) {
        this._calibrationManager.onWindowUnload();
    }
};

/*
    Handle test timeout event.
    Timeout can occur when pitch is coming from the server and not from JS
*/
bcSpiroViewer.prototype.onTestTimeout = function() {
    var resultsCount = this._resultsManager.getTestsCount();
    if (resultsCount > 0) {
        this._testManager.stop(true);
        this._resultsManager.showResults(this._predictor, this._patientDetails); 
    } else {
        this.spiroViewerApi.onTimeout();
    }
};

/*
    Handle add test request
*/
bcSpiroViewer.prototype.onAddTestRequest = function() {
    this._startTest();
};

/*
    Handle results save
*/
bcSpiroViewer.prototype.save = function(testResults) {
    this._testRecord.header.patientDetails = this._patientDetails;
    this._testRecord.header.predictor = this._params.predictor; // Taking predictor from settings not from the dropdown
    
    if (this._params.appDetails) {
        var appDetails = JSON.parse(this._params.appDetails);
        this._testRecord.header.mobileDeviceInfo = appDetails.mobileDeviceInfo;
        this._testRecord.header.swVersion = appDetails.swVersion;
    }
    
    this._testRecord.tests = testResults.tests;
    this._testRecord.bestTest = testResults.bestTest;
    
    this.dataFetcher.saveSpirometerResults(this._params.patientId, this._testRecord, $.proxy(function(results) {
        this.spiroViewerApi.onFinished(true, results.recId);
    }, this));
   
};

/*
    Handle cancel test request
*/
bcSpiroViewer.prototype.cancelTest = function() {
    if (!this._testManager.isTestActive()) return;
    
    this._testManager.stop(true);
    var resultsCount = this._resultsManager.getTestsCount();
    if (resultsCount === 0) {
        this.spiroViewerApi.onFinished(false);
    } else {
        this._resultsManager.showResults(this._predictor, this._patientDetails); 
    }
};

/*
    Handle predictor changed request
*/
bcSpiroViewer.prototype.onPredictorChanged = function(predictor) {
    this._predictor = predictor;
};

/*
    Write to logfile if DEBUG is on
*/
bcSpiroViewer.prototype.writeToLogFile = function(msg) {
    if (this.DEBUG) {
        this.spiroViewerApi.writeToLogFile(msg);
    }    
};

/*
    Translate strings
*/
bcSpiroViewer.prototype._localize = function(lang) {
    for (var item in bcSpiroViewer.lang[lang]) {
        $(bcSpiroViewer.lang[lang][item].selector).text(bcSpiroViewer.lang[lang][item].string);
    }
    $.extend(true, bcGlobals.locale, bcSpiroViewer.lang[lang].locale);
};

/*
    Handle save
*/
bcSpiroViewer.prototype.onSave = function() {
    this.spiroViewerApi.onSave();
};

/*
     Notify that the viewer is ready to print
 */
bcSpiroViewer.prototype.onReadyToPrint = function() {
    this._readyToPrint = true;
    this.spiroViewerApi.onReadyToPrint();
};

window.bcSpiroViewer = bcSpiroViewer;

}
if (typeof window !== 'undefined') {

/*
    bcSpiroViewerResultsView - Display the spirometer results
*/
var bcSpiroViewerResultsView = function(spiroViewer, resultsManager) {
    this._spiroViewer = spiroViewer;
    this._resultsManager = resultsManager;    
    
    this._testResults = null;
    this._predictor = null;
    this._patientDetails = null;
    this._isSavedRecord = false;
    this._maxTestsAllowed = 5;
    this._disabled = false;
    
    this._flowVolumeChart = new SpiroChart();
    this._registerEventListeners();

    this._reportMode = spiroViewer.reportMode;
    this._reportMode && $('#bc_spiro_viewer').addClass('reportMode');
};

/*
    Register event listeners
*/
bcSpiroViewerResultsView.prototype._registerEventListeners = function() {
    $('#save').on('click', $.proxy(this._onSaveClick,this));
    $('#predictor').on('change', $.proxy(this._onPredictorChanged, this));
};

/*
    Handle predictor change request
*/
bcSpiroViewerResultsView.prototype._onPredictorChanged = function() {
    this._predictor = $('#predictor').val();
    this._spiroViewer.writeToLogFile("Spirometer predictor changed: " + this._predictor);
    this._resultsManager.onPredictorChanged(this._predictor);
    this.showResults(this._testResults, this._predictor, this._isSavedRecord);
    $('#predictor').blur(); // update the dropdown
    this._reportMode && this._showPredictorLabel(predictor); 
};

/*
    Show the patient details
*/
bcSpiroViewerResultsView.prototype.fillPatientDetails = function(patientDetails) { 
    this._patientDetails = patientDetails;
    var gender = (this._patientDetails.gender) ?  this._spiroViewer._genderNames[this._patientDetails.gender] : bcGlobals.locale.noValue;
    var age = (this._patientDetails.age) ?  this._patientDetails.age : bcGlobals.locale.noValue;
    var height = (this._patientDetails.height) ?  $.tmpl(bcGlobals.locale.patientHeight, {height: this._patientDetails.height}).text() : bcGlobals.locale.noValue;
    
    $('#patientGender').text(gender);
    $('#patientAge').text(age);
    $('#patientHeight').text(height);
};

/*
    Show the results
*/
bcSpiroViewerResultsView.prototype.showResults = function(testResults, predictor, isSavedRecord) {
    this._testResults = testResults;
    this._predictor = predictor;
    this._isSavedRecord = isSavedRecord;
                
    $('#flowVolumeChartWrapper').empty();
    $('#resultsSelectPanel').empty();
    $('#resultsTableWrapper').empty();
    
    $('#predictor').val(predictor);
    this._reportMode && this._showPredictorLabel(predictor); 
       
    var needSmallerChart = ((this._reportMode) && (testResults.tests.length > 1));
    var width = (needSmallerChart) ? 300 : 500;
    var height = (needSmallerChart) ? 250 : 300;

    this._drawFlowVolumeChart(this._flowVolumeChart, 'flowVolumeChartWrapper', width, height);

    this._fillResults();
    // Select the best test tab
    $('#selectTest' + this._testResults.tests.length).click();
    
    this.toggleResultsReady(true);

    if (this._reportMode) {
        this._showAllResults();
    }
};

/*
    Build the graph line data
*/
bcSpiroViewerResultsView.prototype._getGraphItemData = function(i, testResult) {
    var flow = testResult.flowArr;
    var volume = testResult.volume;
    
    var lineData = [];
    for (var j=0;j<flow.length; j++) {
        lineData[j] = {
            x: volume[j],
            y: flow[j]
        }
    }

    var data = {
        yAxisIndex: 0,
        data: lineData,
        title: 'Test #' + i
    };
    
    return data;
};

/*
   Draw the Flow-Volume graph
*/
bcSpiroViewerResultsView.prototype._drawFlowVolumeChart = function(chart, wrapperId, width, height, resultIndex) {
    var yAxis = [{title: bcGlobals.locale.flowAxisTitle}];

    var xAxis = {
        title: bcGlobals.locale.volumeAxisTitle,
        dateAxis: false,
        showExactTicks: false 
    };

    var resultsCount = this._testResults.tests.length;
    
    var data = [];
    if (typeof resultIndex === 'undefined') { 
        for (var i=0; i<resultsCount; i++) {
            data[i] = this._getGraphItemData(i, this._testResults.tests[i]);
        }
           
        data.push(this._getGraphItemData(resultsCount, this._testResults.bestTest));
    } else {
        for (var i=0; i<resultsCount; i++) {
            data[i] = this._getGraphItemData(i, this._testResults.tests[i]);
        }
        data.push(this._getGraphItemData(resultIndex, this._testResults.tests[resultIndex]));
    }

    var config = {
        width: width,
        height: height,
        colors: 'MONO',
        showDataPoints: false,
        legend: {
            show: false
        }
    };
    
    chart.showChart(wrapperId, config, xAxis, yAxis, data);
};

/*
    Fill the results table and select UI item
*/
bcSpiroViewerResultsView.prototype._fillResults = function() {
    var resultsCount = this._testResults.tests.length;
    
    for (var i=0; i<resultsCount; i++) {
        var predicted = this._calcPredValues(this._testResults.tests[i]);
        this._addResultsTableItem('#resultsTableWrapper', i, this._testResults.tests[i], predicted);
        
        if (resultsCount > 1) {
            this._addResultSelectItem(i, i+1, resultsCount);
        }
    } 
    var predicted = this._calcPredValues(this._testResults.bestTest);
    this._addResultsTableItem('#resultsTableWrapper', resultsCount, this._testResults.bestTest, predicted);
    this._addResultSelectItem(resultsCount, '&#x2605;', resultsCount);
    
    if ((resultsCount < this._maxTestsAllowed) && !this._isSavedRecord) {
        $('#resultsSelectPanel').append($('#resultSelectTemplate').tmpl({
            'id': 'addTest',
            'title': '+'
        }));
        
        $('#addTest').on('click', $.proxy(this._doAddTest,this));
    }
};

/*
    Add results table item
*/
bcSpiroViewerResultsView.prototype._addResultsTableItem = function(wrapperSelector, i, res, predicted) {
    var FVC = Number(parseFloat(res.FVC).toFixed(2));
    var predictedFVC = (predicted.FVC !== '') ? Number(parseFloat(predicted.FVC).toFixed(2)) : '';
    var FEV1 = Number(parseFloat(res.FEV1).toFixed(2));
    var predictedFEV1 = (predicted.FEV1 !== '') ? Number(parseFloat(predicted.FEV1).toFixed(2)) : '';
    var ratio = Number(parseFloat(res.ratio*100).toFixed(2));
    var predictedRatio = (predicted.ratio !== '') ? Number(parseFloat(predicted.ratio*100).toFixed(2)) : '';
    var duration = Number(parseFloat(res.duration).toFixed(2));
    var PEF = Number(parseFloat(res.PEF).toFixed(2));
    var predictedPEF = (predicted.PEF !== '') ? Number(parseFloat(predicted.PEF).toFixed(2)): '';

    $(wrapperSelector).append($('#resultRemplate').tmpl({
        'id': 'testTable' + i,
        'measuredTitle': bcGlobals.locale.measured,
        'predictedTitle': bcGlobals.locale.predicted,
        'FVCTitle': bcGlobals.locale.FVC,
        'litersUnit': bcGlobals.locale.litersUnit,     
        'FEV1Title': bcGlobals.locale.FEV1,      
        'ratioTitle': bcGlobals.locale.ratio,  
        'PEFTitle': bcGlobals.locale.PEF,  
        'litersSecondsUnit': bcGlobals.locale.litersSecondsUnit,  
        'durationTitle': bcGlobals.locale.duration,  
        'secondsUnit': bcGlobals.locale.secondsUnit,  
        'FVC':  FVC,
        'predictedFVC':  predictedFVC,
        'FEV1':  FEV1,
        'predictedFEV1':  predictedFEV1,
        'ratio':  ratio,
        'predictedRatio':  predictedRatio,
        'duration':  duration,
        'PEF': PEF,
        'predictedPEF': predictedPEF
    }));
};

/*
    Add results select UI item
*/
bcSpiroViewerResultsView.prototype._addResultSelectItem = function(i, title, resultsCount) {
    $('#resultsSelectPanel').append($('#resultSelectTemplate').tmpl({
        'id': 'selectTest' + i,
        'title': title,
        'resultTableId': 'testTable' + i
    }));
    
    var resultsView = this;
    $('#selectTest' + i).click(function() {
        if (resultsView._disabled) return;
             
        $('.selectTestSelected').removeClass('selectTestSelected');
        $('.testResultTable').hide();
        var resultTableId = $(this).attr('data-result-table-id');
        $('#' + resultTableId).show();
        $(this).addClass('selectTestSelected');
        
        for (var k=0;k<resultsCount;k++) {
            resultsView._flowVolumeChart.toggleLineFocus(k, false);
            resultsView._flowVolumeChart.toggleLineVisibility(k, false);
        }
        
        if (i != resultsCount) {
            resultsView._flowVolumeChart.toggleLineVisibility(i, true);
        }
        
        resultsView._flowVolumeChart.toggleLineFocus(resultsCount, false);            
        resultsView._flowVolumeChart.toggleLineFocus(i, true, '#1f77b4');
        
    });
}; 

/*
    Calculate the predictor values
*/
bcSpiroViewerResultsView.prototype._calcPredValues = function(res) {
    var prediction = SpirometerPrediction.getPredictedValues(this._predictor,
                                                             res,
                                                             this._patientDetails);
                                                             
    return prediction;                                            
};

/*
    Handle add test request
*/
bcSpiroViewerResultsView.prototype._doAddTest = function() {
    if (this._disabled) return;
    
    this._spiroViewer.writeToLogFile("Spirometer test add test clicked");
    this._resultsManager.onAddTestRequest();
};

/*
    Handle save resutls request
*/
bcSpiroViewerResultsView.prototype._onSaveClick = function() {
    if (this._disabled) return;
    
    this._spiroViewer.writeToLogFile("Spirometer test save clicked");
    
    this._spiroViewer.onSave();
    this._disableAll(); 
    this._resultsManager.save();
};

/*
    Show saved resutsl (results coming from file not from current test)
*/
bcSpiroViewerResultsView.prototype.showSavedResults = function(testResults, predictor) {    
    $('#bc_spiro_viewer').addClass('savedRecord');
    
    this.showResults(testResults, predictor, true);
};

/*
    Hide the results
*/
bcSpiroViewerResultsView.prototype.hideResults = function(isReady) {
    this.toggleResultsReady(false);
};

/*
    Toggle the results ready state
*/
bcSpiroViewerResultsView.prototype.toggleResultsReady = function(isReady) {
    $('#bc_spiro_viewer').toggleClass('resultsReady', isReady);
};

/*
    Toggle the results ready state
*/
bcSpiroViewerResultsView.prototype._disableAll = function() {
    $('#save').attr('disabled','disabled');
    this._disabled = true;
};

bcSpiroViewerResultsView.prototype._showAllResults = function(isReady) {
    $('#allResults').empty();

    var resultsCount = this._testResults.tests.length;

    if (resultsCount === 1) {
        return;
    }
    
    $('#bc_spiro_viewer').addClass('multipleResults');

    for (var i=0; i<resultsCount; i++) {

        var flowVolumeChart = new SpiroChart();
        $('#allResults').append($('#extraResultTemplate').tmpl({
            'chartWrapperId': 'extraResultChart' + i,
            'tableWrapperId': 'extraResultTable' + i
        }));

        this._drawFlowVolumeChart(flowVolumeChart, 'extraResultChart' + i, 150, 130, i);

        // hide all but the last one - the last one is not inclded here because we add line #i again to the end 
        for (var j=0; j<resultsCount;j++) {
            flowVolumeChart.toggleLineVisibility(j, false);
        }
        flowVolumeChart.toggleLineFocus(resultsCount, true, '#1f77b4', false);
        flowVolumeChart.hideEveryXTick(3);
        
        var predicted = this._calcPredValues(this._testResults.tests[i]);
        this._addResultsTableItem('#extraResultTable' + i, i, this._testResults.tests[i], predicted);
    }
};

bcSpiroViewerResultsView.prototype._showPredictorLabel = function(predictor) {
    $('#predictorName').text($('#predictor')[0].options[$('#predictor').val()].text);
};

window.bcSpiroViewerResultsView = bcSpiroViewerResultsView;

}
import React, { useState, useEffect, useRef } from 'react'
import S3 from 'aws-sdk/clients/s3'
import { currentCredentials } from '../../utils/credentials'
import '../legacy/stylesheets/DigitalSignatureEdit.css'

const SignatureCanvas = ({
  config,
  signatureCanvasRef,
  setSignatureCanvasRef,
  hasSignature,
  setHasSignature,
  currentSignature,
  setHasSignatureCleared,
  disabled,
  doClear
}) => {
  const [isDrawing, setIsDrawing] = useState(false)
  const canvasRef = useRef(null)
  const contextRef = useRef(null)

  useEffect(() => {
    async function callMe() {
      const _config = config || {}
      const context = canvasRef.current.getContext('2d')
      context.strokeStyle = _config.color || '#222222'
      context.lineWidth = _config.width || 2

      contextRef.current = context

      if (currentSignature !== null) {
        const credentials = await currentCredentials()
        const s3 = new S3({ credentials })
        const url = s3.getSignedUrl('getObject', {
          Bucket: currentSignature.bucket,
          Key: currentSignature.key
        })
        const image = new Image()
        image.crossOrigin = 'anonymous'
        image.onload = () => {
          context.drawImage(image, 0, 0)
        }
        image.src = url
      }
    }
    callMe()
  }, [])

  useEffect(() => {
    doClear.current = () => {
      clearCanvas()
    }
  }, [disabled])

  const startDrawing = ({ nativeEvent }) => {
    const { offsetX, offsetY } = nativeEvent
    contextRef.current.beginPath()
    contextRef.current.moveTo(offsetX, offsetY)
    setIsDrawing(true)
  }

  const finishDrawing = () => {
    if (!isDrawing) return

    contextRef.current.closePath()
    setIsDrawing(false)

    if (!hasSignature) {
      setHasSignature(true)
    }

    if (!signatureCanvasRef) {
      setSignatureCanvasRef(canvasRef)
    }
  }

  const draw = ({ nativeEvent }) => {
    if (!isDrawing || disabled) {
      return
    }
    const { offsetX, offsetY } = nativeEvent
    contextRef.current.lineTo(offsetX, offsetY)
    contextRef.current.stroke()
  }

  const clearCanvas = () => {
    if (disabled) return
    const canvas = canvasRef.current
    const context = canvas.getContext('2d')
    context.clearRect(0, 0, canvas.width, canvas.height)
    setHasSignature(false)
    setHasSignatureCleared(true)
  }

  return (
    <>
      <canvas
        height='85'
        width='305'
        id='digital_signature_canvas'
        className='input'
        onMouseDown={startDrawing}
        onMouseUp={finishDrawing}
        onMouseMove={draw}
        onMouseOut={finishDrawing}
        ref={canvasRef}
      ></canvas>
    </>
  )
}

export default SignatureCanvas

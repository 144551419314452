if (typeof window !== 'undefined') {

/*
    HankinsonPredictor - calculate predicted values
    http://warrengoff.com/pft-vim/Hankinson_SpirometricReferenceValues.pdf
*/
var HankinsonPredictor = {
    _factors: {
        male: {
            ageGroupLimit: 20,
            group1: {
                FVC: { 
                    intercept: -0.2584,
                    ageFactor: -0.20415,
                    ageFactor2: 0.010133,
                    htPred2: 0.00018642
                },
                FEV1: {
                    intercept: -0.7453,
                    ageFactor: -0.04106,
                    ageFactor2: 0.004477,
                    htPred2: 0.00014098
                },
                PEF: {
                    intercept: -0.5962,
                    ageFactor: -0.12357,
                    ageFactor2: 0.013135,
                    htPred2: 0.00024962                   
                }
            },
            group2: {
                FVC: { 
                    intercept: -0.1933,
                    ageFactor: 0.00064,
                    ageFactor2: -0.000269,
                    htPred2: 0.00018642
                },
                FEV1: {
                    intercept: 0.5536,
                    ageFactor: -0.01303,
                    ageFactor2: -0.000172,
                    htPred2: 0.00014098 
                },
                PEF: {
                    intercept: 1.0523,
                    ageFactor: 0.08272,
                    ageFactor2: -0.001301,
                    htPred2: 0.00024962                  
                }
            }
        },
        female: {
            ageGroupLimit: 18,
            group1: {
                FVC: { 
                    intercept: -1.2082,
                    ageFactor: 0.05916,
                    ageFactor2: 0,
                    htPred2: 0.00014815
                },
                FEV1: {
                    intercept: -0.8710,
                    ageFactor: 0.06537,
                    ageFactor2: 0,
                    htPred2: 0.00011496
                },
                PEF: {
                    intercept: -3.6181,
                    ageFactor: 0.60644,
                    ageFactor2: -0.016846,
                    htPred2: 0.00018623                    
                }
            },
            group2: {
                FVC: { 
                    intercept: -0.3560,
                    ageFactor: 0.01870,
                    ageFactor2: -0.000382,
                    htPred2: 0.00014815
                },
                FEV1: {
                    intercept: 0.4333,
                    ageFactor: -0.00361,
                    ageFactor2: -0.000194,
                    htPred2: 0.00011496
                },
                PEF: {
                    intercept: 0.9267,
                    ageFactor: 0.06929,
                    ageFactor2: -0.001031,
                    htPred2: 0.00018623                   
                }
            }
        }        
    },
    
    /*
        Calculate prediction value
    */
    _calcPrediction: function(factors, patientDetails) {
        return (factors.intercept + (factors.ageFactor * patientDetails.age) + (factors.ageFactor2 * Math.pow(patientDetails.age, 2)) + (factors.htPred2 * Math.pow(patientDetails.height, 2)));
    },
    
    /*
        Calculate all the predicted values
    */
    getPredictedValues: function(patientDetails) {
        var predicted = {
            FVC: 0,
            FEV1: 0,
            PEF: 0    
        };
        
        if (this.canCalculatePrediction(patientDetails)) {
            var genderKey = SpirometerPrediction.getGenderKey(patientDetails.gender);
            var ageGroup = ((patientDetails.age - this._factors[genderKey].ageGroupLimit) <= 0) ? 'group1' : 'group2';
            var factorsGroups = this._factors[genderKey][ageGroup];
            
            predicted.FVC = this._calcPrediction(factorsGroups.FVC, patientDetails);
            predicted.FEV1 = this._calcPrediction(factorsGroups.FEV1, patientDetails);
            predicted.PEF = this._calcPrediction(factorsGroups.PEF, patientDetails);
        }
        
        return predicted;
    },
    
    /*
        Indicates if the predicted values can be calculated
    */
    canCalculatePrediction: function(patientDetails) {
        if (!(patientDetails.gender ||
              patientDetails.age ||
              patientDetails.height)) {
            return false;
        }
        
        if (patientDetails.height === 0) return false;
        if (patientDetails.gender === spiroViewer._gender.UNSPECIFIED) return false;
        
        return true;
    }
};

window.HankinsonPredictor = HankinsonPredictor;

}
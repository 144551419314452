if (typeof window !== 'undefined') {

/*
    ECGViewer - the ecg viewer controller
*/
bcViewer.ECGViewer = bcViewer.BaseWavesViewer.extend({
    construct: function() {
        this._super.apply(this, arguments);

        // Which state can be changed on this viewer
        this._supportedStateChanges.push('zoomX', 'zoomY', 'baselineCorrection', 'smooth', 'expand3to7', 'expand3to12', 'expand8to12', 'expand2to6', 'requestedChannels', 'magnify', 'qrsMarkValue', 'userMarks');

        // Canvas height is decided by the ratio between the current zoomY and the middle zoomY
        this.MIDDLE_ZOOM_Y = 10;

        this._isCurrentViewDetailsUpdated = false;

        // count data requests on load
        this._countdownRequestsOnLoad = 1;

        var canvasClass = (this._bcViewer.isRealtimeMode) ? bcViewer.RTECGViewerCanvas : bcViewer.ECGViewerCanvas;
        this._init(bcViewer.BaseWavesViewer.Model, bcViewer.ECGViewer.View, '#bc_viewer .bcv_main_canvas', canvasClass);
    },

    /*
        Returns the record start time
    */
    getStartTime: function() {
        return this._metadata.StartTime;
    },

    /*
        Forward fetch start notifications to the view
    */
    onDataFetchStart: function() {
        this._view.onDataFetchStart();
    },

    /*
        Forward fetch end notifications to the view
    */
    onDataFetchEnd: function() {
        this._view.onDataFetchEnd();
    },

    /*
        Forward all viewers ready notification to the view
    */
    onAllViewersReady: function() {
        this._view.onAllViewersReady();
    },

    /*
        Handle the metadata
    */
    onGotMetadata: function(metadata) {
        this._super.apply(this, arguments);
        this._view.updateDate(this._metadata.StartTime);
    },

    /*
        Handle viewer loading end
    */
    _onLoadingEnd: function() {
        this._countdownRequestsOnLoad--;

        if (this._countdownRequestsOnLoad === 0) {
            this._view.onLoadingEnd();
            this._super.apply(this, arguments);
        }
    },

    /*
        Handle state changes
    */
    onStateChange: function(stateChanges, isInitCycle) {
        this._super(stateChanges, isInitCycle);
        this._view.clearMeasurements(false);
    },

    /*
        Prepare viewer for realtime mode
    */
    initRealtimeMode: function() {
        this._super.apply(this, arguments);
        this._view.initRealtimeMode();
    },

    /*
        Adjust viewer to mode
    */
    adjustToMode: function() {
        this._super.apply(this, arguments);
        this._view.adjustToMode();
    },

    /*
        Handle window unload
    */
    onWindowUnload: function() {
        this._view.onWindowUnload();
    },

    /*
        Handle hash params
    */
    onGotHashParams: function(params) {
        this._view.onGotHashParams(params);
    },

	/*
        Stop realtime mode
    */
    stopRealtimeMode: function() {
        this._view.stopRealtimeMode();
    },

    /*
        Start realtime mode
    */
    startRealtimeMode: function() {
        this._view.startRealtimeMode();
    }
});

}

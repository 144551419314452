import React, { useState } from 'react'
import Modal from '../Modal'
import { gql, useMutation } from '@apollo/client'
import clsx from 'clsx'
import { objectToFieldsConverter } from '../../../utils/utils'

const SERVICE_PLAN_UPDATE_AUTO_RENEW = gql`
  mutation SERVICE_PLAN_UPDATE_AUTO_RENEW(
    $input: ServicePlanUpdateAutoRenewInput!
  ) {
    servicePlanUpdateAutoRenew(input: $input) {
      id
    }
  }
`

const UpdateServicePlanAutoRenew = ({ servicePlan, onComplete, onExit }) => {
  const [inProgress, setProgress] = useState(false)

  const [servicePlanUpdateAutoRenew] = useMutation(
    SERVICE_PLAN_UPDATE_AUTO_RENEW
  )

  async function submit() {
    setProgress(true)
    await servicePlanUpdateAutoRenew({
      variables: {
        input: {
          id: servicePlan.id,
          updatedAt: new Date().toISOString(),
          autoRenew: false
        }
      },
      update: (cache) => {
        cache.modify({
          id: cache.identify(servicePlan),
          fields: objectToFieldsConverter({
            autoRenew: false
          })
        })
      }
    })
    setProgress(false)
    onComplete()
  }

  return (
    <Modal onClose={onExit} onSubmit={submit} inProgress={inProgress}>
      <div className='field'>
        <label className='label'>Cancel auto renew</label>
        <div className='has-text-right'>
          <button
            className={clsx('button is-primary mt-4', {
              'is-loading': inProgress
            })}
            disabled={inProgress}
            onClick={submit}
          >
            OK
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default UpdateServicePlanAutoRenew

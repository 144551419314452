import React from 'react'
import clsx from 'clsx'
import Modal from '../Modal'
import AuthScenery from './AuthScenery'
import { useLocalization } from '../LocalizationProvider'

export default function SignUpCore({
  handleInputChange,
  changeState,
  signUp,
  error,
  inProgress
}) {
  const { locale } = useLocalization()
  return (
    <AuthScenery>
      <Modal onSubmit={signUp} standalone={false}>
        <div className='field'>
          <label className='label is-medium'>
            {locale.profile.create_account}
          </label>
        </div>
        <hr />
        <div className='field'>
          <label className='label'>{locale.login.email_forced}</label>
          <div className='control'>
            <input
              key='username'
              type='text'
              name='username'
              className='input'
              onChange={handleInputChange}
              readOnly={inProgress}
            />
          </div>
        </div>
        <div className='field'>
          <label className='label'>{locale.login.password_forced}</label>
          <div className='control'>
            <input
              key='password'
              type='password'
              name='password'
              className='input'
              onChange={handleInputChange}
              readOnly={inProgress}
              autoComplete='new-password'
              id='new-password'
            />
          </div>
        </div>
        <div className={clsx('field', { 'is-hidden': !error })}>
          <p className='help is-danger'>{error}</p>
        </div>
        <hr />
        <div className='field'>
          <div className='level'>
            <div className='level-left'>
              <span className='level-item'>
                {locale.profile.have_an_account}
              </span>
              <button
                className='button is-primary is-inverted level-item'
                onClick={() => changeState('signIn')}
                disabled={inProgress}
              >
                {locale.login.signin}
              </button>
            </div>
            <div className='level-right'>
              <button
                className={clsx('button is-primary level-item', {
                  'is-loading': inProgress
                })}
                onClick={signUp}
                disabled={inProgress}
              >
                {locale.profile.create_account}
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </AuthScenery>
  )
}

import React, { useState } from 'react'
import Modal from '../Modal'
import { gql, useMutation } from '@apollo/client'
import clsx from 'clsx'
import {
  GET_QUESTIONNAIRE_TEMPLATES,
  GET_ADMIN_DATA_FROM_USER_EMAIL
} from '../queries'
import { useQuery } from '@apollo/client'
import Loader from '../Loader'

const UPDATE_USER_QUEQSTIONNAIRE_TEMPLATE = gql`
  mutation UPDATE_USER_QUEQSTIONNAIRE_TEMPLATE($input: UserUpdateInput!) {
    userUpdate(input: $input) {
      id
    }
  }
`

export default function UpdateUserQuestionnaireTemplate({
  user,
  onExit,
  onComplete
}) {
  const [inProgress, setInProgress] = useState(false)
  const [questionnaireTemplateId, setQuestionnaireTemplateId] = useState(
    user.questionnaireTemplate ? user.questionnaireTemplate.id : ''
  )

  const { loading, data } = useQuery(GET_QUESTIONNAIRE_TEMPLATES)

  const [updateUserQuestionnaireTemplate] = useMutation(
    UPDATE_USER_QUEQSTIONNAIRE_TEMPLATE,
    {
      refetchQueries: [
        {
          query: GET_ADMIN_DATA_FROM_USER_EMAIL,
          variables: { email: user.email }
        }
      ],
      awaitRefetchQueries: true
    }
  )

  async function submit() {
    setInProgress(true)
    await updateUserQuestionnaireTemplate({
      variables: {
        input: {
          id: user.id,
          updatedAt: new Date().toISOString(),
          questionnaireTemplateId
        }
      }
    })
    setInProgress(false)
    onComplete()
  }

  return (
    <Modal onClose={onExit} onSubmit={submit} inProgress={inProgress}>
      <label className='label is-medium'>Set user questionnaire tempalte</label>
      <hr />
      {loading ? (
        <Loader />
      ) : (
        <div>
          <div className='field is-horizontal'>
            <div className='field-label'>
              <label className='label has-text-left'>Tempalte:</label>
            </div>
            <div className='field-body'>
              <div className='field'>
                <div className='control'>
                  <div className='select'>
                    <select
                      value={questionnaireTemplateId}
                      onChange={(e) =>
                        setQuestionnaireTemplateId(e.target.value)
                      }
                    >
                      <option value=''>No template</option>
                      {data.questionnaireTemplates.map(({ id }) => (
                        <option value={id} key={id}>
                          {id}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='field'>
            <div className='level'>
              <div className='level-left'>
                <div className='level-item'></div>
              </div>
              <div className='level-right'>
                <div className='level-item'>
                  <button
                    className={clsx('button is-primary level-item', {
                      'is-loading': inProgress
                    })}
                    onClick={async () => submit()}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Modal>
  )
}

import React, { useState } from 'react'
import { gql, useQuery, useMutation } from '@apollo/client'
import clsx from 'clsx'
import { v4 as uuidv4 } from 'uuid'
import { GET_SHARES_MANUAL } from './queries'
import Modal from './Modal'
import { useLocalization } from './LocalizationProvider'
import { interpolate } from '../../locale/LocalizationUtils'
import { isRTL, getCookie } from '../../utils/utils'

const STUDY_SHARE = gql`
  mutation STUDY_SHARE(
    $sourceClinic: ClinicConnectionInput!
    $sourceCaregiver: CaregiverConnectionInput!
    $patient: PatientConnectionInput!
    $email: AWSEmail
    $targetClinic: ClinicConnectionInput
    $targetCaregiver: CaregiverConnectionInput
    $input: StudyShareDataInput!
  ) {
    studyShare(
      sourceClinic: $sourceClinic
      sourceCaregiver: $sourceCaregiver
      patient: $patient
      email: $email
      targetClinic: $targetClinic
      targetCaregiver: $targetCaregiver
      input: $input
    ) {
      id
    }
  }
`

export default function CreateStudyShare({
  study: {
    anonymousPatientData: {
      __typename, // eslint-disable-line no-unused-vars
      animal: _animal,
      ...anonymousPatientData
    },
    ...study
  },
  currentUser,
  onComplete,
  onExit
}) {
  const { locale } = useLocalization()
  const [shareStudy, { loading: inProgress, error }] = useMutation(STUDY_SHARE)
  const [email, setEmail] = useState('')
  const [target, setTarget] = useState(null)
  const [message, setMessage] = useState('')
  const [isShowMenu, setIsShowMenu] = useState(false)
  const [inProgressUpdateLegacyClinic, setInProgressUpdateLegacyClinic] =
    useState(false)
  const { loading, data } = useQuery(GET_SHARES_MANUAL, {
    variables: { id: currentUser.id }
  })
  const { __typename: __typename2, ...animal } = _animal || {} // eslint-disable-line no-unused-vars
  async function share() {
    const createdAt = new Date().toISOString()
    /* TODO: remove after migration */
    try {
      setInProgressUpdateLegacyClinic(true)
      const iframe = document.createElement('iframe')
      iframe.setAttribute(
        'src',
        window.location.origin + '/en/account/edit_profile'
      )
      iframe.setAttribute('hidden', 'hidden')
      document.getElementsByTagName('body')[0].appendChild(iframe)
      await new Promise((resolve) => iframe.addEventListener('load', resolve))
      const csrfToken = iframe.contentWindow.document
        .querySelector("meta[name='csrf-token']")
        .getAttribute('content')
      iframe.parentNode.removeChild(iframe)

      const body = new URLSearchParams({
        hash_id: study.id,
        url: `${window.location.origin}/${
          getCookie(document.cookie, 'locale') || 'en'
        }/account/study?hash_id=${study.id}${window.location.hash}`,
        sender: currentUser.id,
        subject: interpolate(locale.emails.share.subject, {
          name: currentUser.settings.displayName
        }),
        message,
        timestamp: createdAt,
        is_request_from_new_app: true
      })

      if (target) {
        body.append('receiver', target.targetCaregiver.id)
      } else {
        body.append('receiver_email', email)
      }

      const response = await window.fetch(`/api/share_study`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          'X-CSRF-Token': csrfToken
        },
        body
      })

      const result = await response.json()
      console.log('share_study result:', result)
      setInProgressUpdateLegacyClinic(false)
    } catch (e) {
      console.error(e)
      setInProgressUpdateLegacyClinic(false)
    }

    try {
      await shareStudy({
        variables: {
          sourceClinic: {
            id: study.clinic.id,
            name: study.clinic.name
          },
          sourceCaregiver: {
            id: currentUser.id,
            name: currentUser.settings.displayName,
            email: currentUser.email
          },
          patient: {
            id: study.patient.id,
            name: study.patient.name,
            owner: study.patient.animal && study.patient.animal.owner,
            externalId: study.patient.externalId
          },
          email: target ? undefined : email || undefined,
          targetClinic: target
            ? { id: target.targetClinic.id, name: target.targetClinic.name }
            : undefined,
          targetCaregiver: target
            ? {
                id: target.targetCaregiver.id,
                name: target.targetCaregiver.name,
                email: target.targetCaregiver.email
              }
            : undefined,
          input: {
            id: uuidv4(),
            studyId: study.id,
            createdAt: createdAt,
            studyCreatedAt: study.createdAt,
            timezoneOffset: study.timezoneOffset,
            duration: study.duration,
            protocol: study.protocol,
            anonymousPatientData: {
              ...anonymousPatientData,
              ...(animal
                ? {
                    animal: {
                      ...animal
                    }
                  }
                : {})
            },
            shareType: 'ShareManual',
            message
          }
        }
      })
      onComplete()
    } catch {
      void 0
    }
  }
  return (
    <Modal onClose={onExit}>
      <label className='label is-medium'>{locale.ask_second_opinion}</label>
      <hr />
      <div className='field is-horizontal'>
        <div className='field-label is-normal'>
          <label className='label has-text-left'>{locale.share.share}</label>
        </div>
        <div className='field-body'>
          <div
            className={clsx('dropdown', { 'is-active': isShowMenu })}
            style={{ width: '100%' }}
          >
            <div className='dropdown-trigger' style={{ width: '100%' }}>
              <div className='field has-addons'>
                <p
                  className={clsx('control is-expanded', {
                    'is-loading': loading
                  })}
                >
                  <input
                    type='text'
                    className={clsx('input', {
                      'is-danger': error
                    })}
                    value={target ? target.targetCaregiver.name : email}
                    onChange={(e) => setEmail(e.target.value)}
                    readOnly={
                      target || inProgress || inProgressUpdateLegacyClinic
                    }
                  />
                </p>
                {data &&
                  data.clinic.targetSharesManual.edges.length > 0 &&
                  !target && (
                    <p className='control'>
                      <button
                        className='button'
                        onClick={() => setIsShowMenu(!isShowMenu)}
                      >
                        <span className='bulma-arrow-down'></span>
                      </button>
                    </p>
                  )}
                {target && (
                  <p className='control'>
                    <button className='button' onClick={() => setTarget(null)}>
                      <span className='bulma-delete'></span>
                    </button>
                  </p>
                )}
              </div>
            </div>
            {data && (
              <div
                className='dropdown-menu'
                role='menu'
                style={{ width: '100%' }}
              >
                <div className='dropdown-content'>
                  {data.clinic.targetSharesManual.edges.map(
                    ({ node: share }) => (
                      <a
                        key={share.id}
                        className='dropdown-item'
                        onClick={() => {
                          setTarget(share)
                          setIsShowMenu(!isShowMenu)
                        }}
                      >
                        {share.targetCaregiver.name}
                      </a>
                    )
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className='field is-horizontal'>
        <div className='field-label is-normal' />
        <div className='field-body'>
          <p
            className={clsx('help is-danger', {
              'is-hidden': !error
            })}
          >
            {error
              ? error.message === 'ShareExists'
                ? locale.sharing.errors.share_connection_already_exists
                : error.message === 'ForbiddenSelfShare'
                ? locale.sharing.errors.sharing_with_myself
                : locale.something_wrong
              : null}
          </p>
        </div>
      </div>
      <div className='field is-horizontal'>
        <div className='field-label is-normal'>
          <label className='label has-text-left'>{locale.share.message}</label>
        </div>
        <div className='field-body'>
          <div className='field'>
            <div className='control'>
              <textarea
                className='textarea has-fixed-size'
                value={message}
                style={{
                  direction: message && isRTL(message[0]) ? 'rtl' : 'ltr'
                }}
                onChange={(e) => setMessage(e.target.value)}
                readOnly={inProgress || inProgressUpdateLegacyClinic}
              />
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div className='field'>
        <div className='level'>
          <div className='level-left' />
          <div className='level-right'>
            <button
              className={clsx('button is-primary level-item', {
                'is-loading': inProgress || inProgressUpdateLegacyClinic
              })}
              disabled={
                inProgress || inProgressUpdateLegacyClinic || !(target || email)
              }
              onClick={async () => share()}
            >
              {locale.share.share}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

import React, { useState, useEffect } from 'react'
import { createPortal } from 'react-dom'

const Modal = ({
  onClose,
  onSubmit,
  standalone = true,
  inProgress = false,
  children
}) => {
  const [element] = useState(document.createElement('div'))
  useEffect(() => {
    const modalRoot = document.getElementById('___gatsby').parentElement
    modalRoot.appendChild(element)
    return () => {
      modalRoot.removeChild(element)
    }
  }, [])
  useEffect(() => {
    const onKeyDown = async (e) => {
      if (e.keyCode === 27 && onClose) doOnClose()
      if (e.keyCode === 13 && onSubmit) !inProgress && (await onSubmit(e))
    }
    window.addEventListener('keydown', onKeyDown)
    return () => window.removeEventListener('keydown', onKeyDown)
  }, [onClose, onSubmit, inProgress])
  useEffect(() => {
    if (!standalone) return
    window.document.body.classList.add('modal-open')
    return () => {
      window.document.body.classList.remove('modal-open')
    }
  })

  function doOnClose() {
    !inProgress && onClose && onClose()
  }

  return createPortal(
    <div className='modal is-active'>
      {standalone && <div className='modal-background' onClick={doOnClose} />}
      <div className='modal-content'>
        <div className='box'>{children}</div>
      </div>
      {standalone && onClose && (
        <button
          className='modal-close is-large'
          aria-label='close'
          onClick={doOnClose}
        />
      )}
    </div>,
    element
  )
}

export default Modal

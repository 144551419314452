import React, { useState } from 'react'
import Modal from '../Modal'
import { gql, useMutation } from '@apollo/client'
import clsx from 'clsx'
import { objectToFieldsConverter } from '../../../utils/utils'

const SERVICE_PLAN_UPDATE_ACTIVE = gql`
  mutation SERVICE_PLAN_UPDATE_ACTIVE($input: ServicePlanUpdateActiveInput!) {
    servicePlanUpdateActive(input: $input) {
      id
    }
  }
`

const UpdateServicePlanActive = ({ servicePlan, onComplete, onExit }) => {
  const [inProgress, setProgress] = useState(false)

  const [servicePlanUpdateActive] = useMutation(SERVICE_PLAN_UPDATE_ACTIVE)

  async function submit() {
    setProgress(true)
    await servicePlanUpdateActive({
      variables: {
        input: {
          id: servicePlan.id,
          updatedAt: new Date().toISOString(),
          active: !servicePlan.active
        }
      },
      update: (cache) => {
        cache.modify({
          id: cache.identify(servicePlan),
          fields: objectToFieldsConverter({
            active: !servicePlan.active
          })
        })
      }
    })
    setProgress(false)
    onComplete()
  }

  return (
    <Modal onClose={onExit} onSubmit={submit} inProgress={inProgress}>
      <div className='field'>
        <label className='label'>
          {servicePlan.active ? 'Deactivate plan' : 'Activate plan'}
        </label>
        <div className='has-text-right'>
          <button
            className={clsx('button is-primary mt-4', {
              'is-loading': inProgress
            })}
            disabled={inProgress}
            onClick={submit}
          >
            OK
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default UpdateServicePlanActive

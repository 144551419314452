if (typeof window !== 'undefined') {

/*
   BaseWavesViewer.View - the waves viewer view
*/
bcViewer.BaseWavesViewer.View = bcViewer.BaseViewer.View.extend({
    /*
        Handler for getting new ECG data from the model
    */
    onGotECGData: function(response) {
        try {
          if (this._viewer.isDisabled()) {
              this._viewer.enableViewer();
          }

          this._canvas.setHeight(response.height); // due to zoom_y
          this._canvas.setWidth(this._canvas.$.width()); // may change due to scrollbars
          this.drawImage(response.sourceCanvas, response.xOffset, response.yOffset);
          this._onCurrentVisibleWindowChange(response.visibleWindowStartTime, response.visibleWindowEndTime, response.visibleWindowDuration);

          !this._viewer.isViewerReady && this._viewer._onLoadingEnd();
        } catch(e) {
        }
    },

    /*
        Draw image on the canvas from sourceCanvas with x,y offsets
        Notice: wer'e currently not using any Y offsets but I left it there in case will need it in the future
    */
    drawImage: function(sourceCanvas, xOffset, yOffset) {
        try {
          this._canvas.clear();
          this._canvas.context.drawImage(sourceCanvas, xOffset, yOffset);
        } catch(e) {
        }
    },

    /*
        handle current visible window change
    */
    _onCurrentVisibleWindowChange: function(visibleWindowStartTime, visibleWindowEndTime, visibleWindowDuration) {
    }
});

}

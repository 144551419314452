import React from 'react'
import dayjs from 'dayjs'

const AdminUserDevices = ({ clinic }) => {
  let deviceAttachedList = clinic.devices
    ? [...clinic.devices].sort(
        (a, b) =>
          new Date(b.attachedAt).getTime() - new Date(a.attachedAt).getTime()
      )
    : []
  return (
    <div>
      {deviceAttachedList.length === 0 && <span>No device is attached</span>}
      {deviceAttachedList.length > 0 && (
        <div>
          <table className='table mt-5'>
            <thead>
              <tr>
                <th>Attached at</th>
                <th>Device</th>
                <th>Number of studies</th>
              </tr>
            </thead>
            <tbody>
              {deviceAttachedList.map((deviceAttach) => (
                <tr key={deviceAttach.deviceNumber}>
                  <td>
                    {dayjs(deviceAttach.attachedAt).format(
                      'YYYY-MM-DD HH:mm:ss'
                    )}
                  </td>
                  <td>
                    {deviceAttach.deviceClass} {deviceAttach.deviceNumber}
                  </td>
                  <td>{deviceAttach.numOfStudies}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  )
}

export default AdminUserDevices

import React, { useState, useEffect } from 'react'
import { useLazyQuery } from '@apollo/client'
import clsx from 'clsx'
import { GET_PATIENTS_SEARCH } from './queries'
import useDebounce from '../hooks/useDebounce'
import {
  adjustTimeToTimezone,
  localizePatientName,
  ANONYMOUS_PATIENT_NAME,
  DATE_FORMAT_CONVERTER
} from '../../utils/utils'

const PatientSearch = ({
  currentUser,
  locale,
  onPatientSelect,
  ignoreAnonymous,
  showNameAndOwnerOnly
}) => {
  const [patientName, setPatientName] = useState('')
  const debouncedPatientName = useDebounce(patientName, 500)
  const [
    getPatients,
    { loading: isSearchingPatients, data: patients, updateQuery }
  ] = useLazyQuery(GET_PATIENTS_SEARCH)
  useEffect(() => {
    async function callMe() {
      if (
        debouncedPatientName &&
        debouncedPatientName
          .trim()
          .split(' ')
          .filter((value) => value)
          .every((term) => term.length > 1)
      ) {
        const { data } = await getPatients({
          variables: {
            id: currentUser.id,
            prefix: debouncedPatientName,
            first: 50
          }
        })
        if (
          !ignoreAnonymous &&
          data &&
          localizePatientName(ANONYMOUS_PATIENT_NAME, locale)
            .toLowerCase()
            .startsWith(debouncedPatientName.toLowerCase())
        ) {
          updateQuery((data) =>
            data.clinic.patientsSearch.length > 0 &&
            data.clinic.patientsSearch[data.clinic.patientsSearch.length - 1]
              .id === ANONYMOUS_PATIENT_NAME
              ? data
              : {
                  clinic: {
                    __typename: data.clinic.__typename,
                    id: data.clinic.id,
                    patientsSearch: data.clinic.patientsSearch.concat({
                      __typename: 'PatientConnectionLink',
                      id: ANONYMOUS_PATIENT_NAME,
                      name: localizePatientName(ANONYMOUS_PATIENT_NAME, locale),
                      owner: '',
                      externalId: '',
                      lastStudyDate: '',
                      lastStudyDateTimezoneOffset: '',
                      sharedBy: ''
                    })
                  }
                }
          )
        }
      }
    }
    callMe()
  }, [debouncedPatientName])
  return (
    <div
      className={clsx('dropdown is-right', {
        'is-active':
          debouncedPatientName &&
          patients &&
          patients.clinic.patientsSearch.length > 0
      })}
    >
      <div className='dropdown-trigger'>
        <div
          className={clsx('control', {
            'is-loading': isSearchingPatients
          })}
        >
          <input
            type='text'
            className='input'
            value={patientName}
            onChange={(e) => setPatientName(e.target.value)}
          />
        </div>
      </div>
      <div className='dropdown-menu' role='menu'>
        <div
          className='dropdown-content'
          style={{ maxHeight: 270, overflow: 'auto' }}
        >
          {debouncedPatientName &&
            patients &&
            patients.clinic.patientsSearch.map(
              ({
                id,
                name,
                owner,
                externalId,
                lastStudyDate,
                lastStudyDateTimezoneOffset,
                sharedBy
              }) => (
                <a
                  key={id}
                  className='dropdown-item'
                  onClick={async () => {
                    setPatientName('')
                    await onPatientSelect(id)
                  }}
                >
                  {name}
                  {owner ? ` | ${owner}` : ''}
                  {externalId && !showNameAndOwnerOnly
                    ? ` | ${externalId}`
                    : ''}
                  {lastStudyDate && !showNameAndOwnerOnly
                    ? ` | ${adjustTimeToTimezone(
                        lastStudyDate,
                        lastStudyDateTimezoneOffset
                      ).format(
                        DATE_FORMAT_CONVERTER[currentUser.settings.dateFormat]
                      )} `
                    : ''}
                  {sharedBy && !showNameAndOwnerOnly
                    ? ` | ${locale.shared_by} ${sharedBy}`
                    : ''}
                </a>
              )
            )}
        </div>
      </div>
    </div>
  )
}

export default PatientSearch

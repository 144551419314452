if (typeof window !== 'undefined') {

/*
    EventsViewer - thee events viewer class
    TODO: this viewer probably needs its own model but since we need to change a lot of the NP info logic 
    it might be better to write it later
*/
bcViewer.EventsViewer = bcViewer.BaseViewer.extend({
    construct: function() {
        this._super.apply(this, arguments);
        this._currentSelectedEventId; 
        this._selectionUIUpdated = false;
        this._data; // Hold the events data
        this._eventsNP = { // Hold the latest NP info
            'data': {},
            'currentRange': {
                'start':  -1,
                'end'  :  -1
            }
        }; 
    
        this._view = new bcViewer.EventsViewer.View(this._globals, '#bc_viewer .bcv_events_canvas', bcViewer.EventCanvas,
                                                        this._state.width, this._state.height);
    },
    
    /*
        Apply new state: update the event data if dimensions changed, fetch new event NP if needed
    */
    applyStateChanges: function(stateChanges, isInitCycle) {
        this._super(stateChanges);

        if (isInitCycle) return;

        if (typeof stateChanges.width !== 'undefined' || typeof stateChanges.height !== 'undefined') {
            this._dataFetcher.getEventsData(
                this._state.width, this._state.height, $.proxy(this.onGotEventsData, this));
        }

        if (typeof stateChanges.timePosition !== 'undefined') {
            this._fetchNewEventNPInfoIfNeeded(stateChanges.timePosition);
        }
    },
    
    /*
        Handle events data
    */
    onGotEventsData: function(data) {
        this._data = data;
    
        // there are events
        if (! $.isEmptyObject(this._data)) {
            this.enableViewer();              
            // only update the selection UI once
            if (!this._selectionUIUpdated) {
                this._view.updateEventSelection(data);
            
                this._currentSelectedEventId = (typeof this._currentSelectedEventId === 'undefined') ? this.config.DEFAULT_SELECTED_EVENT_ID : this._currentSelectedEventId; 
                this._selectionUIUpdated = true;
            }
       
            this._view.selectEvent(this._currentSelectedEventId);

            // Firing an event that study events are found and are ready to display:
            $(document).trigger($.Event('EventsViewerReady'));
        
            // If we got the date make sure to update the NP range 
            // according to the current selected event
            this._updateEventNPRange(this._currentSelectedEventId);
        }

        !this.isViewerReady && this._onLoadingEnd();
    },

    /*
        Handles requests from the UI to select event
    */
    onEventSelectedRequest: function(eventId) {
        this._currentSelectedEventId = eventId;
        this._view.drawEvent(eventId);
        this._updateEventNPRange(eventId);
        // If the NP info for this event doesn't match the new time range - fetch a new one
        this._fetchNewEventNPInfoIfNeeded(this._state.timePosition);
    },

    /*
        Update the range for the current event
    */
    _updateEventNPRange: function(eventId) {
        if ((typeof this._currentSelectedEventId === 'undefined') ||
            (typeof this._eventsNP.data[eventId] === 'undefined')) {
            return;
        }

        var eventData = this._eventsNP.data[eventId];
        var start = eventData[0];
        this._eventsNP.currentRange.start = eventData[0];
        this._eventsNP.currentRange.end   = eventData[eventData.length-1];
    },

    /*
        Return the time position of the next event
    */
    getNextEventPosition: function() {
        var timePosition = this._state.timePosition;
        var data = this._eventsNP.data[this._currentSelectedEventId];
        for (var i=0; i<data.length; i++) {
            if (timePosition < data[i]) {
               return data[i];
           }
        }
    },

    /*
        Return the time position of the previous event
    */
    getPrevEventPosition: function() {
        var timePosition = this._state.timePosition;
        var data = this._eventsNP.data[this._currentSelectedEventId];
        for (var i=data.length-1; i>=0; i--) {
            if (timePosition > data[i]) {
                return data[i];
            }
        }
    },

    /*
        Handle events NP info
        If you're reading this comment and have any idea what NP stands for please let me know :)
    */
    onGotEventsNPInfo: function(data) {
        this._eventsNP.data = data;

        this._updateEventNPRange(this._currentSelectedEventId);
    },

    /*
        If the time position doesn't fit the range - fetch new info
    */
    _fetchNewEventNPInfoIfNeeded: function(timePosition) {
        // Don't try to fetch data if timePosition is not defined 
        // or we don't have any selected event yet
        if ((typeof timePosition === 'undefined') || 
            (typeof this._currentSelectedEventId === 'undefined')) {
             return;
        }

        if (!this._timePositionIsInNPRange(timePosition)) {
            this._dataFetcher.getEventsNPInfo(this._bcViewer.getRecordHash(), timePosition, 
                    $.proxy(this.onGotEventsNPInfo, this));    
        }
    },

    /*
        Return true if timePosition is within the currentRange bound
    */
    _timePositionIsInNPRange: function(timePosition) {
        return ((timePosition > this._eventsNP.currentRange.start) &&
                (timePosition < this._eventsNP.currentRange.end));
    },

    /*
        Return the events data
    */
    getData: function() {
        return this._data;
    }
});

}
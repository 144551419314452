if (typeof window !== 'undefined') {

/*
   EventsViewer.View - the events viewer view
*/
bcViewer.EventsViewer.View = bcViewer.BaseViewer.View.extend({
    construct: function() {
        this._super.apply(this, arguments);
    },

    /*
        Start listen to UI events
    */
    _registerToEvents: function() {
        // TODO: consider delegation
        $$('#bc_viewer .bcv_events_viewer_header .bcv_event_prev').on('click', $.proxy(this.onPrevEventClicked, this));
        $$('#bc_viewer .bcv_events_viewer_header .bcv_event_next').on('click', $.proxy(this.onNextEventClicked, this));
        
        $$('.bcv_events_list').on('change', $.proxy(this.onEventSelected, this));
    },

    /*
        Update the event selector UI
    */
    updateEventSelection: function(data) {
        this._buildEventsDropdown(this._config.EVENTS_LIST, data); 
    },

    /*
        Select an event in the UI
    */
    selectEvent: function(eventId) {
        $$('.bcv_events_list').val(eventId);
        this.onEventSelected(); // Simulate event selected event
    },

    /*
        return the id of the current selected event
    */
    getSelectedEventId: function() {
        return $$('.bcv_events_list').find(':selected').val();
    },

    /*
        Handle event selection in the UI
    */
    onEventSelected: function() {
        this._viewer.onEventSelectedRequest(this.getSelectedEventId()); 
    },

    /*
        Handle clicking on next event button
    */
    onPrevEventClicked: function(event) {
        var prevEventTime = this._viewer.getPrevEventPosition();
        (typeof prevEventTime !== 'undefined') && this._viewer.onStateChangeRequest({'timePosition': prevEventTime});
    },

    /*
        Handle clicking on previous event button
    */
    onNextEventClicked: function(event) {
        var nextEventTime = this._viewer.getNextEventPosition();
        (typeof nextEventTime !== 'undefined') && this._viewer.onStateChangeRequest({'timePosition': nextEventTime});
    },

    /*
        Draw an event by the event ID
    */
    drawEvent: function(eventId) {
        var data = this._viewer.getData();
        var eventData = data[eventId];
        this._canvas.draw(eventData.lines);
    },

    /*
        Recursive build events dropdown UI
    */  
    _buildEventsDropdown: function(eventsList, data,  parentItem) {
        optionsData = [];
        $.each(data, function(eventId, eventData) {
            var eventsCount = eventData.event_count;
            if (eventsCount > 0) {
                var eventText = bcGlobals.locale.holterEvents['e_'+eventId] + ' (' + eventsCount + ')';    
                optionsData.push({value: eventId, text: eventText});
            }
        });
        
        $$('.bcv_events_list').append($('#bcv_dropdown_option').tmpl(optionsData));
    },

    /*
        Return array of elements selectors to hide
    */
    getElementsToHide: function() {
        return ['.bcv_events_header', '.bcv_events_canvas'];
    },

    /*
        Disable the viewer
    */
    disableViewer: function() {
        $$('.bcv_events_list').prop('disabled', true);
        $$('.bcv_event_button').prop('disabled', true);
        
        this._super.apply(this, arguments);
    },
    
    /*
        Enable the viewer
    */
    enableViewer: function() {
        $$('.bcv_events_list').removeProp('disabled');
        $$('.bcv_event_button').removeProp('disabled');
        
        this._super.apply(this, arguments);
    }
});

}
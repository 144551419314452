import React, { useState } from 'react'
import { gql, useApolloClient } from '@apollo/client'
import clsx from 'clsx'
import Auth from '@aws-amplify/auth'
import Modal from '../Modal'
import { hashPassword } from '../../../utils/utils'
import { useLocalization } from '../LocalizationProvider'

const USER_SET_PASSWORD_AS_CHANGED = gql`
  mutation USER_SET_PASSWORD_AS_CHANGED($id: ID!) {
    userSetPasswordAsChanged(id: $id) {
      id
    }
  }
`

export default function PasswordEdit({ onComplete, onExit }) {
  const [inProgress, setProgress] = useState(false)
  const [oldPassword, setOldPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [error, setError] = useState(false)
  const { locale } = useLocalization()
  const client = useApolloClient()
  async function submit() {
    try {
      setError(false)
      setProgress(true)
      const user = await Auth.currentAuthenticatedUser()
      const oldPasswordHashed = hashPassword(user.attributes.email, oldPassword)
      const newPasswordHashed = hashPassword(user.attributes.email, newPassword)
      await Auth.changePassword(user, oldPasswordHashed, newPasswordHashed)
      await client.mutate({
        mutation: USER_SET_PASSWORD_AS_CHANGED,
        variables: {
          id: user.attributes.preferred_username || user.attributes.sub
        }
      })

      /* TODO: remove after migration */
      try {
        const iframe = document.createElement('iframe')
        iframe.setAttribute(
          'src',
          window.location.origin + '/en/account/edit_profile'
        )
        iframe.setAttribute('hidden', 'hidden')
        document.getElementsByTagName('body')[0].appendChild(iframe)
        await new Promise((resolve) => iframe.addEventListener('load', resolve))
        const csrfToken = iframe.contentWindow.document
          .querySelector("meta[name='csrf-token']")
          .getAttribute('content')
        iframe.parentNode.removeChild(iframe)
        const response = await window.fetch(`/api/update_password`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
            'X-CSRF-Token': csrfToken
          },
          body: new URLSearchParams({
            current_password: oldPasswordHashed,
            new_password: newPasswordHashed,
            arnika_password: newPassword,
            is_request_from_new_app: true
          })
        })
        const result = await response.json()
        console.log('update_password result:', result)
      } catch (e) {
        console.error(e)
      }
      /* TODO: remove after migration */
      setProgress(false)
      onComplete()
    } catch (e) {
      setProgress(false)
      setError(e.code)
    }
  }
  return (
    <Modal onClose={onExit} onSubmit={submit}>
      <div className='field'>
        <label className='label is-medium'>{locale.password}</label>
      </div>
      <hr />
      <div className='field'>
        <label className='label'>{locale.profile.old_password}</label>
        <div className='control'>
          <input
            key='oldPassword'
            type='password'
            name='oldPassword'
            className='input'
            value={oldPassword}
            onChange={(e) => setOldPassword(e.target.value.trim())}
            readOnly={inProgress}
            autoComplete='current-password'
            id='current-password'
          />
        </div>
        {error && (
          <p className='help is-danger'>
            {locale.profile.auth_errors.password_edit[error] || error}
          </p>
        )}
      </div>
      <div className='field'>
        <label className='label'>{locale.profile.new_password}</label>
        <div className='control'>
          <input
            key='newPassword'
            type='password'
            name='newPassword'
            className='input'
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value.trim())}
            readOnly={inProgress}
            autoComplete='new-password'
            id='new-password'
          />
        </div>
      </div>
      <hr />
      <div className='field'>
        <div className='level'>
          <div className='level-left' />
          <div className='level-right'>
            <button
              className={clsx('button is-primary level-item', {
                'is-loading': inProgress
              })}
              onClick={async () => submit()}
              disabled={inProgress}
            >
              {locale.profile.update_password}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

import React, { useState } from 'react'
import Modal from './Modal'
import { useLocalization } from './LocalizationProvider'

export default function ConclusionTemplateEdit({
  onExit,
  onSave,
  templateId,
  name: _name,
  text: _text
}) {
  const [name, setName] = useState(_name)
  const [text, setText] = useState(_text)
  const { locale } = useLocalization()
  function submit() {
    onSave(templateId, name, text)
  }
  return (
    <Modal onClose={onExit}>
      <div className='field'>
        <label className='label'>{locale.report_settings.template_name}</label>
        <div className='control'>
          <input
            type='text'
            className='input'
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
      </div>
      <div className='field'>
        <label className='label'>{locale.report_settings.template}</label>
        <div className='control'>
          <textarea
            className='textarea has-fixed-size'
            value={text}
            placeholder={locale.report_settings.conclusion_template_placeholder}
            onChange={(e) => setText(e.target.value)}
          ></textarea>
        </div>
      </div>
      <div className='field'>
        <button
          className='button is-primary'
          onClick={submit}
          disabled={!name || !text || (_name === name && _text === text)}
        >
          {locale.report_settings.save}
        </button>
      </div>
    </Modal>
  )
}

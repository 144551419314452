import React, { useState } from 'react'
import { GET_SHARES_AUTO, GET_SHARES_MANUAL } from '../queries'
import { gql, useQuery, useMutation } from '@apollo/client'
import CreateAutoShare from '../CreateAutoShare'
import { SHARING_STATUS_CONVERTER } from '../../../utils/utils'

const ADMIN_CLINIC_SHARE_AUTO_DELETE = gql`
  mutation ADMIN_CLINIC_SHARE_AUTO_DELETE($id: ID!) {
    clinicShareAutoDelete(shareId: $id) {
      id
    }
  }
`

const ADMIN_CLINIC_SHARE_AUTO_ACTIVATE = gql`
  mutation ADMIN_CLINIC_SHARE_AUTO_ACTIVATE($input: ShareAutoDataInput!) {
    clinicShareAutoActivate(input: $input) {
      id
    }
  }
`

const AMDIN_CLINIC_SHARE_AUTO_DEACTIVATE = gql`
  mutation AMDIN_CLINIC_SHARE_AUTO_DEACTIVATE($input: ShareAutoDataInput!) {
    clinicShareAutoDeactivate(input: $input) {
      id
    }
  }
`

const AdminUserShares = ({ user }) => {
  const [isNewShareMode, setNewShareMode] = useState(false)

  const {
    loading: autoSharesLoading,
    error: autoSharesError,
    data: autoSharesData
  } = useQuery(GET_SHARES_AUTO, {
    variables: {
      id: user.clinic.id,
      isAuthorizedSourceSharesAuto: true,
      isAuthorizedTargetSharesAuto: true
    }
  })

  const {
    loading: manualSharesLoading,
    error: manualSharesError,
    data: manualSharesData
  } = useQuery(GET_SHARES_MANUAL, {
    variables: {
      id: user.clinic.id
    }
  })

  const [clinicShareAutoDelete, { loading: inProgressDelete }] = useMutation(
    ADMIN_CLINIC_SHARE_AUTO_DELETE,
    {
      refetchQueries: [
        {
          query: GET_SHARES_AUTO,
          variables: {
            id: user.clinic.id,
            isAuthorizedSourceSharesAuto: true,
            isAuthorizedTargetSharesAuto: true
          }
        }
      ],
      awaitRefetchQueries: true
    }
  )
  const [clinicShareAutoActivate, { loading: inProgressActivate }] =
    useMutation(ADMIN_CLINIC_SHARE_AUTO_ACTIVATE, {
      refetchQueries: [
        {
          query: GET_SHARES_AUTO,
          variables: {
            id: user.clinic.id,
            isAuthorizedSourceSharesAuto: true,
            isAuthorizedTargetSharesAuto: true
          }
        }
      ],
      awaitRefetchQueries: true
    })
  const [clinicShareAutoDeactivate, { loading: inProgressDeactivate }] =
    useMutation(AMDIN_CLINIC_SHARE_AUTO_DEACTIVATE, {
      refetchQueries: [
        {
          query: GET_SHARES_AUTO,
          variables: {
            id: user.clinic.id,
            isAuthorizedSourceSharesAuto: true,
            isAuthorizedTargetSharesAuto: true
          }
        }
      ],
      awaitRefetchQueries: true
    })

  function getSharingSource(share) {
    return share.targetCaregiver
      ? `${share.targetCaregiver.name} (${share.targetCaregiver.email})`
      : share.email
  }

  return (
    <div>
      {isNewShareMode && (
        <CreateAutoShare
          sharingUser={{
            id: user.id,
            name: user.settings.displayName,
            email: user.email,
            clinic: {
              id: user.clinic.id,
              name: user.clinic.name
            }
          }}
          userAuthorizedActions={{ allow: ['*'], deny: [] }}
          onComplete={() => setNewShareMode(false)}
          onExit={() => setNewShareMode(false)}
          GET_SHARES_AUTO={GET_SHARES_AUTO}
        />
      )}
      {(autoSharesLoading || manualSharesLoading) && <p>Loading...</p>}
      {(autoSharesError || manualSharesError) && <div> error... </div>}
      <div className='has-text-right'>
        <button
          className='button is-outlined is-primary mb-4'
          onClick={() => setNewShareMode(true)}
          disabled={
            inProgressDelete || inProgressActivate || inProgressDeactivate
          }
        >
          Add new share
        </button>
      </div>
      {autoSharesData &&
        manualSharesData &&
        manualSharesData.clinic.targetSharesManual.edges.length === 0 &&
        autoSharesData.clinic.sourceSharesAuto.edges.length === 0 &&
        autoSharesData.clinic.targetSharesAuto.edges.length === 0 && (
          <h6 className='title is-6'>No shares</h6>
        )}
      {autoSharesData &&
        autoSharesData.clinic.sourceSharesAuto.edges.length > 0 && (
          <div>
            <h6 className='title is-6'>{user.name} auto shares with:</h6>
            <table className='table is-fullwidth is-hoverable'>
              <thead>
                <tr>
                  <th></th>
                  <th>Status</th>
                  <th>Change status</th>
                  <th>Remove</th>
                </tr>
              </thead>
              <tbody>
                {autoSharesData.clinic.sourceSharesAuto.edges.map(
                  ({ node: share }) => (
                    <tr key={share.id}>
                      <td>{getSharingSource(share)}</td>
                      <td>{SHARING_STATUS_CONVERTER[share.status]}</td>
                      <td>
                        <button
                          className='button'
                          disabled={
                            inProgressDelete ||
                            inProgressActivate ||
                            inProgressDeactivate ||
                            share.status === 'WaitingForSignup'
                          }
                          onClick={async () => {
                            if (share.status === 'Active') {
                              await clinicShareAutoDeactivate({
                                variables: {
                                  input: {
                                    id: share.id,
                                    updatedAt: new Date().toISOString()
                                  }
                                }
                              })
                            } else {
                              await clinicShareAutoActivate({
                                variables: {
                                  input: {
                                    id: share.id,
                                    updatedAt: new Date().toISOString()
                                  }
                                }
                              })
                            }
                          }}
                        >
                          {share.status === 'Active'
                            ? 'Deactivate'
                            : 'Activate'}
                        </button>
                      </td>
                      <td>
                        <button
                          className='button'
                          disabled={
                            inProgressDelete ||
                            inProgressActivate ||
                            inProgressDeactivate
                          }
                          onClick={async () => {
                            await clinicShareAutoDelete({
                              variables: { id: share.id }
                            })
                          }}
                        >
                          Remove
                        </button>
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </div>
        )}
      {autoSharesData &&
        autoSharesData.clinic.targetSharesAuto.edges.length > 0 && (
          <div>
            {autoSharesData.clinic.sourceSharesAuto.edges.length > 0 && (
              <div className='my-5'></div>
            )}
            <h6 className='title is-6'>Auto shares with {user.name}:</h6>
            <table className='table is-fullwidth is-hoverable'>
              <thead>
                <tr>
                  <th></th>
                  <th>Status</th>
                  <th>Change status</th>
                  <th>Remove</th>
                </tr>
              </thead>
              <tbody>
                {autoSharesData.clinic.targetSharesAuto.edges.map(
                  ({ node: share }) => (
                    <tr key={share.id}>
                      <td>{`${share.sourceCaregiver.name} (${share.sourceCaregiver.email})`}</td>
                      <td>{SHARING_STATUS_CONVERTER[share.status]}</td>
                      <td>
                        <button
                          className='button'
                          disabled={
                            inProgressDelete ||
                            inProgressActivate ||
                            inProgressDeactivate
                          }
                          onClick={async () => {
                            if (share.status === 'Active') {
                              await clinicShareAutoDeactivate({
                                variables: {
                                  input: {
                                    id: share.id,
                                    updatedAt: new Date().toISOString()
                                  }
                                }
                              })
                            } else {
                              await clinicShareAutoActivate({
                                variables: {
                                  input: {
                                    id: share.id,
                                    updatedAt: new Date().toISOString()
                                  }
                                }
                              })
                            }
                          }}
                        >
                          {share.status === 'Active'
                            ? 'Deactivate'
                            : 'Activate'}
                        </button>
                      </td>
                      <td>
                        <button
                          className='button'
                          disabled={
                            inProgressDelete ||
                            inProgressActivate ||
                            inProgressDeactivate
                          }
                          onClick={async () => {
                            await clinicShareAutoDelete({
                              variables: { id: share.id }
                            })
                          }}
                        >
                          Remove
                        </button>
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </div>
        )}
      {manualSharesData &&
        manualSharesData.clinic.targetSharesManual.edges.length > 0 && (
          <div>
            {autoSharesData &&
              (autoSharesData.clinic.sourceSharesAuto.edges.length > 0 ||
                autoSharesData.clinic.targetSharesAuto.edges.length > 0) && (
                <div className='my-5'></div>
              )}
            <h6 className='title is-6'>{user.name} manual sharing with:</h6>
            <table className='table is-fullwidth is-hoverable'>
              <thead>
                <tr>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {manualSharesData.clinic.targetSharesManual.edges.map(
                  ({ node: share }) => (
                    <tr key={share.id}>
                      <td>{`${share.targetCaregiver.name} (${share.targetCaregiver.email})`}</td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </div>
        )}
    </div>
  )
}

export default AdminUserShares

import React from 'react'
import Modal from '../Modal'
import { gql, useMutation } from '@apollo/client'
import { GET_REFERRALS_QUEUES_CONFIG } from '../queries'
import { REFERRAL_TYPES_CONVERTER } from '../../../utils/utils'
import { useLocalization } from '../LocalizationProvider'
import clsx from 'clsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'

const REMOVE_FROM_REFERRAL_QUEUE = gql`
  mutation REMOVE_FROM_REFERRAL_QUEUE(
    $id: ID!
    $queueId: ID!
    $referralType: ReferralType
    $isAdmin: Boolean!
  ) {
    userRemoveFromReferralsQueue(
      id: $id
      queueId: $queueId
      referralType: $referralType
      isAdmin: $isAdmin
    ) {
      id
    }
  }
`

const RemoveFromReferralsQueue = ({
  queueData,
  toRemoveData,
  onExit,
  onComplete
}) => {
  const { locale } = useLocalization()

  const [userRemoveFromReferralsQueue, { loading: isRemoving }] = useMutation(
    REMOVE_FROM_REFERRAL_QUEUE,
    {
      refetchQueries: [
        {
          query: GET_REFERRALS_QUEUES_CONFIG
        }
      ],
      awaitRefetchQueries: true
    }
  )

  async function submit() {
    let variables = {
      id: toRemoveData.data.id,
      queueId: queueData.id,
      isAdmin: toRemoveData.isAdmin
    }

    if (!toRemoveData.isAdmin) {
      variables.referralType = toRemoveData.data.referralType
    }
    await userRemoveFromReferralsQueue({
      variables
    })
    onComplete()
  }

  return (
    <Modal onClose={onExit} onSubmit={submit} inProgress={isRemoving}>
      <div className='field is-horizontal'>
        <div className='field-label'>
          <label className='label has-text-left'>Queue:</label>
        </div>
        <div className='field-body'>
          <div className='field'>
            <div className='control'>{queueData.name}</div>
          </div>
        </div>
      </div>
      <div className='field is-horizontal'>
        <div className='field-label'>
          <label className='label has-text-left'>Caregiver:</label>
        </div>
        <div className='field-body'>
          <div className='field'>
            <div className='control'>{toRemoveData.data.name}</div>
          </div>
        </div>
      </div>
      {toRemoveData.isAdmin && (
        <div className='field is-horizontal'>
          <div className='field-label'>
            <label className='label has-text-left'>Admin:</label>
          </div>
          <div className='field-body'>
            <div className='field'>
              <div className='control'>
                {toRemoveData.isAdmin && <FontAwesomeIcon icon={faCheck} />}
              </div>
            </div>
          </div>
        </div>
      )}

      {!toRemoveData.isAdmin && (
        <div className='field is-horizontal'>
          <div className='field-label'>
            <label className='label has-text-left'>Referral type:</label>
          </div>
          <div className='field-body'>
            <div className='field'>
              <div className='control'>
                {
                  locale.studies.reports[
                    REFERRAL_TYPES_CONVERTER[toRemoveData.data.referralType]
                  ]
                }
              </div>
            </div>
          </div>
        </div>
      )}

      <div className='level'>
        <div className='level-left'>
          <div className='level-item'></div>
        </div>
        <div className='level-right'>
          <div className='level-item'>
            <button
              className={clsx('button is-primary', {
                'is-loading': isRemoving
              })}
              onClick={async () => submit()}
            >
              ok
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default RemoveFromReferralsQueue

import React, { useEffect } from 'react'
import { gql, useQuery } from '@apollo/client'
import BCViewer from './BCViewer'
import Loader from './Loader'
import { getParams, RECORDS_PRIORITIES } from '../../utils/utils'
import useDynamicStylesheets from '../hooks/useDynamicStylesheets'

const GET_VIEWER_STUDY_DATA = gql`
  query GET_VIEWER_STUDY_DATA($id: ID!) {
    study(id: $id) {
      id
      createdAt
      duration
      protocol
      hasEvents
      records {
        __typename
        id
        ... on RecordEcgRestData {
          file {
            url
          }
        }
      }
    }
  }
`

const ViewerWithStudyData = ({ params, gotoLoginPage }) => {
  const { loading, error, data } = useQuery(GET_VIEWER_STUDY_DATA, {
    variables: { id: params.record_hash }
  })
  useEffect(() => {
    if (error && error.message === 'AccessDenied') {
      gotoLoginPage()
    }
  }, [error])
  if (loading) return <Loader />
  if (error) return null
  const study = {
    ...data.study,
    records: [...data.study.records].sort(
      (e1, e2) =>
        RECORDS_PRIORITIES[e1.__typename] - RECORDS_PRIORITIES[e2.__typename]
    )
  }
  return (
    <BCViewer
      recordHash={study.id}
      recordUrl={study.records[0].file.url}
      recordType={parseInt(params.record_type, 10)}
      studyType={study.protocol === 'Rest' ? 1 : 2}
      hasEvents={study.hasEvents}
      studyAuthorizedActions={study.authorizedActions}
      isCaregiverVeterinarian={
        study.caregiver ? study.caregiver.userType === 'Veterinarian' : false
      }
    />
  )
}

const Viewer = ({ gotoLoginPage, sessionId, location: { search } }) => {
  useEffect(() => {
    document.documentElement.classList.add('standalone_bcviewer')
    return () => {
      document.documentElement.classList.remove('standalone_bcviewer')
    }
  }, [])

  const isCSSLoaded = useDynamicStylesheets(
    [
      () =>
        import(
          /* webpackChunkName: "viewer" */ '../legacy/bcViewer/stylesheets/iframe.css'
        )
    ],
    'viewer'
  )
  if (!isCSSLoaded) return <Loader />
  const params = getParams(search)
  if (params.record_url) {
    return (
      <BCViewer
        recordHash={params.record_hash}
        recordUrl={params.record_url}
        recordType={parseInt(params.record_type, 10)}
        studyType={1}
        hasEvents={false}
        isCaregiverVeterinarian={false}
      />
    )
  } else if (sessionId) {
    return <BCViewer sessionId={sessionId} />
  }
  return <ViewerWithStudyData params={params} gotoLoginPage={gotoLoginPage} />
}

export default Viewer

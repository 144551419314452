import React, { useEffect } from 'react'
import useDynamicStylesheets from '../hooks/useDynamicStylesheets'
import Loader from './Loader'

import '../legacy/lib/jquery'
import '../legacy/lib/wavesurfer.min'

const BCStethoscopeViewer = ({ recordUrl }) => {
  const isCSSLoaded = useDynamicStylesheets(
    [
      () =>
        import(
          /* webpackChunkName: "bc-stethoscope-viewer" */ '../legacy/stylesheets/wavesurfer.css'
        )
    ],
    'bc-stethoscope-viewer'
  )

  useEffect(() => {
    if (!isCSSLoaded) return

    const wavesurfer = window.WaveSurfer.create({
      container: '#audio_player',
      waveColor: 'lightblue',
      progressColor: 'blue'
    })
    wavesurfer.load(recordUrl)
    window.$('#audio_play').on('click', function () {
      wavesurfer.play()
    })
    window.$('#audio_pause').on('click', function () {
      wavesurfer.pause()
    })
    window.$('#audio_stop').on('click', function () {
      wavesurfer.stop()
    })
    return () => {
      wavesurfer.destroy()
    }
  }, [isCSSLoaded])

  if (!isCSSLoaded) return <Loader />

  return (
    <div id='audio_player_container'>
      <div id='audio_player' />
      <button className='audio_control' id='audio_play'>
        <span />
      </button>
      <button className='audio_control' id='audio_pause'>
        <span />
      </button>
      <button className='audio_control' id='audio_stop'>
        <span />
      </button>
    </div>
  )
}

export default BCStethoscopeViewer

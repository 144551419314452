import React from 'react'
import clsx from 'clsx'
import Modal from '../Modal'
import AuthScenery from './AuthScenery'
import { useLocalization } from '../LocalizationProvider'

export default function ForgotPasswordSubmitCore({
  handleInputChange,
  send,
  submit,
  error,
  inProgress
}) {
  const { locale } = useLocalization()

  return (
    <AuthScenery>
      <Modal onSubmit={submit} standalone={false}>
        <div className='field'>
          <label className='label is-medium'>{locale.reset_header_1}</label>
        </div>
        <hr />
        <div className='field'>
          <label className='label'>
            {locale.profile.confirmation_code_sent_to_email}
          </label>
          <label className='label'>
            {locale.profile.check_email_for_confirmation_code}
          </label>
        </div>
        <div className='field'>
          <label className='label'>{locale.profile.confirmation_code}</label>
          <div className='control'>
            <input
              key='code'
              type='text'
              name='code'
              className='input'
              onChange={handleInputChange}
              readOnly={inProgress}
            />
          </div>
        </div>
        <div className='field'>
          <label className='label'>{locale.profile.new_password}</label>
          <div className='control'>
            <input
              key='password'
              type='password'
              name='password'
              className={clsx('input', { 'is-danger': error })}
              onChange={handleInputChange}
              readOnly={inProgress}
              autoComplete='new-password'
              id='new-password'
            />
          </div>
        </div>
        <div className={clsx('field', { 'is-hidden': !error })}>
          <p className='help is-danger'>{error}</p>
        </div>
        <hr />
        <div className='field'>
          <div className='level'>
            <div className='level-left'>
              <button
                className='button is-primary is-inverted level-item'
                onClick={send}
                disabled={inProgress}
              >
                {locale.profile.resend_code}
              </button>
            </div>
            <div className='level-right'>
              <button
                className={clsx('button is-primary level-item', {
                  'is-loading': inProgress
                })}
                onClick={submit}
                disabled={inProgress}
              >
                {locale.profile.do_submit}
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </AuthScenery>
  )
}

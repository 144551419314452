if (typeof window !== 'undefined') {

/*
   BaseViewer.View - the base viewer view
*/
bcViewer.BaseViewer.View = bcViewer.Class.extend({
    construct: function(globals, canvasSelector, canvasClass, width, height) {
        this._globals = globals;
        $.extend(this, globals);

        // TODO: should we allow the view to talk directly to the model?
        this._model = this._viewer.getModel && this._viewer.getModel();

        canvasClass = canvasClass || bcViewer.BaseCanvas
        this._canvas = new canvasClass(this._config, canvasSelector, width, height);
        this._canvasSelector = canvasSelector;
        
        this._registerToEvents && this._registerToEvents();
        
        if (this._viewer.config.HIDDEN) {
            this.hideViewer();
        }
   },

    /*
        Handle state changes
        By convention assuming that we have an handler for each state change
        For example: onZoomXChange, onWidthChange, etc
    */
    onStateChange: function(stateChanges) {
        var capitalizeFirstLetter = this._utils.capitalizeFirstLetter;
        
        for (var key in stateChanges) {
            var handler = 'on' + capitalizeFirstLetter(key) + 'Change';
            this[handler] && this[handler](this._getState());
        }
    },

   
    /*
        Handle width change.
    */
    onWidthChange: function(state) {
        this._canvas.setWidth(state.width);
    },
    
    /*
        Handle height change.
    */
    onHeightChange: function(state) {
        this._canvas.setHeight(state.height);
    },
    
    /*
        Input: bcViewer width, Output: the viewer width
    */
    scaleWidthToViewer: function(stateChanges) {
        return $$(this._canvasSelector).parent().width();
    },
    
    /*
        Input: bcViewer height, Output: the viewer height
    */
    scaleHeightToViewer: function(stateChanges) {
        return $$(this._canvasSelector).parent().height();
    },

    /*
        Utility to get the state
    */
    _getState: function() {
        return this._viewer.getState();
    },
    
    /*
      handle window resizing
    */
    onWindowStartResizing: function(isWidthChanged, isHeightChanged) {
      if (isWidthChanged) {
        this._canvas.clear();
      }
    },

    /*
        Return array of elements selectors to hide
    */
    getElementsToHide: function() {
    },
    
    /*
        Hide the elements in this.elementsToHide
    */
    hideViewer: function() {
        var elementsToHide = this.getElementsToHide();
        for (var i=0; i<elementsToHide.length; i++) {
            $$(elementsToHide[i]).hide();
        }
    },

    /*
        Disable the viewer
    */
    disableViewer: function() {
        $$(this._canvasSelector).addClass('bcv_disabled_canvas');
    },
    
    /*
        Enable the viewer
    */
    enableViewer: function() {
        $$(this._canvasSelector).removeClass('bcv_disabled_canvas');
    }
});

}
import React, { useState } from 'react'
import dayjs from 'dayjs'
import { DATE_FORMAT_CONVERTER } from '../../utils/utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUpRightFromSquare } from '@fortawesome/free-solid-svg-icons'
import LogByLogGroupId from './Admin/LogByLogGroupId'

const StudyLog = ({
  loading,
  data,
  studyLogFetchMore,
  studyId,
  currentUser
}) => {
  const [logGroupId, setLogGroupId] = useState(null)

  return (
    <div>
      {loading && 'Loading...'}

      {logGroupId && (
        <LogByLogGroupId
          key={logGroupId}
          id={logGroupId}
          onExit={() => setLogGroupId(null)}
        />
      )}

      {data && (
        <div>
          {data.study.logStream.edges.map(({ node: log }) => (
            <article className='media' key={log.id}>
              <div className='media-content'>
                <div className='content'>
                  <p>
                    <span className='is-size-6'>
                      {dayjs(log.createdAt).format(
                        `${
                          DATE_FORMAT_CONVERTER[currentUser.settings.dateFormat]
                        } HH:mm:ss`
                      )}
                    </span>
                    <span className='ml-2 mr-2'>-</span>
                    <span className='has-text-weight-bold'>{log.event}</span>

                    <a
                      href={log.url}
                      className='ml-4'
                      target='_blank'
                      rel='noreferrer'
                      title='Open in cloudwatch'
                    >
                      <FontAwesomeIcon icon={faUpRightFromSquare} />
                    </a>
                  </p>

                  <div className='ml-4'>
                    <span className='mr-3'>{log.email}</span>
                    <span>{log.requestOrigin}</span>
                  </div>

                  <div className='accordion mb-3'>
                    <input type='checkbox' id={`accordion_${log.id}`} hidden />
                    <label
                      className='accordion-header'
                      htmlFor={`accordion_${log.id}`}
                    >
                      <a className='button is-text has-text-grey'>details</a>
                    </label>
                    <div className='accordion-body'>
                      <code style={{ whiteSpace: 'pre-wrap', color: 'black' }}>
                        {JSON.stringify(JSON.parse(log.data), null, '\t')}
                      </code>
                    </div>
                  </div>

                  <button
                    className='button is-outlined ml-3'
                    onClick={() => {
                      setLogGroupId(log.logsGroupId)
                    }}
                  >
                    Show log group
                  </button>
                </div>
              </div>
            </article>
          ))}
          <button
            className='button is-primary mt-5'
            disabled={!data.study.logStream.pageInfo.hasNextPage}
            onClick={() => {
              if (data.study.logStream.pageInfo.hasNextPage) {
                studyLogFetchMore({
                  variables: {
                    id: studyId,
                    after: data.study.logStream.pageInfo.endCursor
                  }
                })
              }
            }}
          >
            Load more
          </button>
        </div>
      )}
    </div>
  )
}

export default StudyLog

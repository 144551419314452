import { useReducer } from 'react'

const INIT = 'init'
const PREV = 'prev'
const NEXT = 'next'

export const init = () => ({ type: INIT })
export const prev = () => ({ type: PREV })
export const next = (payload) => ({ type: NEXT, payload })

const initialState = { cursors: [null] }

function reducer(state, action) {
  switch (action.type) {
    case PREV:
      return { cursors: state.cursors.slice(0, -1) }
    case NEXT:
      return { cursors: [...state.cursors, action.payload] }
    default:
      return initialState
  }
}

export default () => {
  const [state, dispatch] = useReducer(reducer, initialState)
  return [state.cursors[state.cursors.length - 1], dispatch]
}

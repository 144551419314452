import React, { useState, useReducer } from 'react'
import { gql, useMutation } from '@apollo/client'
import clsx from 'clsx'
import countries from 'i18n-iso-countries'
import countriesLocaleEn from 'i18n-iso-countries/langs/en.json'
import SignOut from './Auth/SignOut'
import { useLocalization } from './LocalizationProvider'
import {
  generateDefaultName,
  generateDefaultDisplayName
} from '../../utils/utils'

countries.registerLocale(countriesLocaleEn)
const countryNames = countries.getNames('en')

function reducer(state, action) {
  if (action.type === 'INIT') return {}
  const newState = { ...state }
  if (!action.value) {
    delete newState[action.key]
  } else newState[action.key] = action.value
  return newState
}

function getRequiredFieldsIfSet(
  data = {},
  address = {},
  settings = {},
  clinic = {}
) {
  const result = []
  const dataRequiredFields = ['firstName', 'lastName']
  dataRequiredFields.forEach((field) => {
    if (!data[field]) {
      result.push(field)
    }
  })
  const addressRequiredFields = ['country']
  addressRequiredFields.forEach((field) => {
    if (!address[field]) result.push(field)
  })
  const settingsRequiredFields = ['locale', 'unitSystem']
  settingsRequiredFields.forEach((field) => {
    if (!settings[field]) {
      result.push(field)
    }
  })
  const clinicRequiredFields = []
  clinicRequiredFields.forEach((field) => {
    if (!clinic[field]) result.push(field)
  })
  return result
}

const USER_CONFIGURE = gql`
  mutation USER_CONFIGURE($input: UserConfigureInput!, $id: ID!) {
    userConfigure(input: $input) {
      id
    }
    query {
      user(id: $id) {
        id
        status
      }
    }
  }
`

const CreateAccount = ({
  id,
  firstName,
  lastName,
  country,
  locale: _locale = 'en_US',
  isAdminConfigure = false,
  onComplete = null
}) => {
  const [data, dispatchData] = useReducer(reducer, {
    ...(firstName ? { firstName } : {}),
    ...(lastName ? { lastName } : {})
  })
  const { locale } = useLocalization()
  const [address, dispatchAddress] = useReducer(reducer, {
    country: country || ''
  })
  const [settings, dispatchSettings] = useReducer(reducer, {
    locale: _locale,
    unitSystem: country === 'US' ? 'Imperial' : 'Metric',
    dateFormat: country === 'US' ? 'MMDDYYYY' : 'DDMMYYYY',
    reportPaperSize: country === 'US' ? 'Letter' : 'A4'
  })
  const [clinic, dispatchClinic] = useReducer(reducer, {})
  const [requiredFieldsIfSet, setRequiredFieldsIfSet] = useState([])
  const [userConfigure, { loading }] = useMutation(USER_CONFIGURE)
  const hasNewGeneralData =
    Object.keys(data).length +
      Object.keys(address).length +
      Object.keys(settings).length >
    0
  return (
    <div
      className={clsx('', {
        section: !isAdminConfigure
      })}
    >
      <div className='columns is-centered'>
        <div className='column is-narrow'>
          <div className='box'>
            {!isAdminConfigure && (
              <>
                <div className='level'>
                  <div className='level-left'>
                    <div className='level-item'>
                      <div className='field'>
                        <label className='label is-medium'>
                          {locale.new_doctor.welcome}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className='level-right'>
                    <div className='level-item'>
                      <SignOut />
                    </div>
                  </div>
                </div>
                <hr />
              </>
            )}
            <div className='field'>
              <label className='label'>{locale.new_doctor.first_name}</label>
              <div className='control'>
                <input
                  className={clsx('input', {
                    'is-danger': requiredFieldsIfSet.indexOf('firstName') > -1
                  })}
                  type='text'
                  value={data.firstName || ''}
                  onChange={(e) =>
                    dispatchData({
                      key: 'firstName',
                      value: e.target.value
                    })
                  }
                />
              </div>
              <p
                className={clsx('help is-danger', {
                  'is-hidden': requiredFieldsIfSet.indexOf('firstName') === -1
                })}
              >
                {locale.profile.required}
              </p>
            </div>
            <div className='field'>
              <label className='label'>{locale.profile.middle_name}</label>
              <div className='control'>
                <input
                  className='input'
                  type='text'
                  value={data.middleName || ''}
                  onChange={(e) =>
                    dispatchData({
                      key: 'middleName',
                      value: e.target.value
                    })
                  }
                />
              </div>
            </div>
            <div className='field'>
              <label className='label'>{locale.new_doctor.last_name}</label>
              <div className='control'>
                <input
                  className={clsx('input', {
                    'is-danger': requiredFieldsIfSet.indexOf('lastName') > -1
                  })}
                  type='text'
                  value={data.lastName || ''}
                  onChange={(e) =>
                    dispatchData({
                      key: 'lastName',
                      value: e.target.value
                    })
                  }
                />
              </div>
              <p
                className={clsx('help is-danger', {
                  'is-hidden': requiredFieldsIfSet.indexOf('lastName') === -1
                })}
              >
                {locale.profile.required}
              </p>
            </div>
            <div className='field'>
              <label className='label'>{locale.profile.display_name}</label>
              <div className='control'>
                <input
                  className='input'
                  type='text'
                  value={settings.displayName || ''}
                  placeholder={generateDefaultDisplayName(
                    generateDefaultName(
                      data.firstName,
                      data.middleName,
                      data.lastName
                    )
                  )}
                  onChange={(e) =>
                    dispatchSettings({
                      key: 'displayName',
                      value: e.target.value
                    })
                  }
                />
              </div>
              <p className='help'>{locale.profile.display_name_hint}</p>
            </div>
            <div className='field'>
              <label className='label'>{locale.profile.country}</label>
              <div className='control'>
                <div
                  className={clsx('select', {
                    'is-danger': requiredFieldsIfSet.indexOf('country') > -1
                  })}
                >
                  <select
                    value={address.country || ''}
                    onChange={(e) =>
                      dispatchAddress({
                        key: 'country',
                        value: e.target.value
                      })
                    }
                  >
                    <option disabled='disabled' value=''>
                      {locale.profile.select_country}
                    </option>
                    {Object.keys(countryNames).map((c) => (
                      <option value={c} key={c}>
                        {countryNames[c]}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <p
                className={clsx('help is-danger', {
                  'is-hidden': requiredFieldsIfSet.indexOf('country') === -1
                })}
              >
                {locale.profile.required}
              </p>
            </div>
            <div className='field'>
              <label className='label'>{locale.new_doctor.clinic_name}</label>
              <div className='control'>
                <input
                  className={clsx('input', {
                    'is-danger': requiredFieldsIfSet.indexOf('name') > -1
                  })}
                  type='text'
                  value={clinic.name || ''}
                  onChange={(e) =>
                    dispatchClinic({
                      key: 'name',
                      value: e.target.value
                    })
                  }
                />
              </div>
              <p
                className={clsx('help is-danger', {
                  'is-hidden': requiredFieldsIfSet.indexOf('name') === -1
                })}
              >
                {locale.profile.required}
              </p>
            </div>
            <div className='field'>
              <label className='label'>{locale.language}</label>
              <div className='control'>
                <div className='select'>
                  <select
                    value={settings.locale || ''}
                    onChange={(e) =>
                      dispatchSettings({
                        key: 'locale',
                        value: e.target.value
                      })
                    }
                  >
                    <option value='en_US'>{locale.en}</option>
                    <option value='ru_RU'>{locale.ru}</option>
                    <option value='uk_UA'>{locale.uk}</option>
                    <option value='zh_TW'>{locale.zh}</option>
                  </select>
                </div>
              </div>
            </div>
            <div className='field'>
              <label className='label'>{locale.profile.unit_system}</label>
              <div className='control'>
                <div className='select'>
                  <select
                    value={settings.unitSystem || ''}
                    onChange={(e) =>
                      dispatchSettings({
                        key: 'unitSystem',
                        value: e.target.value
                      })
                    }
                  >
                    <option value='Metric'>{locale.profile.metric}</option>
                    <option value='Imperial'>{locale.profile.imperial}</option>
                  </select>
                </div>
              </div>
            </div>
            <div className='field'>
              <label className='label'>{locale.profile.date_format}</label>
              <div className='control'>
                <div className='select'>
                  <select
                    value={settings.dateFormat || ''}
                    onChange={(e) =>
                      dispatchSettings({
                        key: 'dateFormat',
                        value: e.target.value
                      })
                    }
                  >
                    <option value='MMDDYYYY'>
                      {locale.date_formats['mm/dd/yyyy']}
                    </option>
                    <option value='DDMMYYYY'>
                      {locale.date_formats['dd/mm/yyyy']}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div className='field'>
              <label className='label'>{locale.profile.paper_size}</label>
              <div className='control'>
                <label className='radio'>
                  <input
                    type='radio'
                    name='reportPaperSize'
                    value='A4'
                    checked={
                      (settings.reportPaperSize ||
                        data.user.settings.reportPaperSize) === 'A4'
                    }
                    onChange={(e) =>
                      dispatchSettings({
                        key: 'reportPaperSize',
                        value: e.target.value
                      })
                    }
                  />
                  &nbsp; {locale.profile.a4}
                </label>
                <label className='radio'>
                  <input
                    type='radio'
                    name='reportPaperSize'
                    value='Letter'
                    checked={
                      (settings.reportPaperSize ||
                        data.user.settings.reportPaperSize) === 'Letter'
                    }
                    onChange={(e) =>
                      dispatchSettings({
                        key: 'reportPaperSize',
                        value: e.target.value
                      })
                    }
                  />
                  &nbsp; {locale.profile.letter}
                </label>
              </div>
            </div>
            <hr />
            <button
              className={clsx('button is-primary', {
                'is-loading': loading
              })}
              disabled={!hasNewGeneralData}
              onClick={async () => {
                const _requiredFields = getRequiredFieldsIfSet(
                  data,
                  address,
                  settings,
                  clinic
                )
                if (_requiredFields.length > 0) {
                  setRequiredFieldsIfSet(_requiredFields)
                  return
                }
                setRequiredFieldsIfSet([])
                const updatedAt = new Date().toISOString()
                const input = {
                  id,
                  ...data,
                  updatedAt,
                  settings
                }
                if (
                  Object.keys(clinic).length + Object.keys(address).length >
                  0
                ) {
                  input.clinic = {
                    id,
                    address,
                    ...clinic
                  }
                }
                await userConfigure({
                  variables: {
                    id,
                    input
                  }
                })

                if (onComplete) {
                  onComplete()
                }
              }}
            >
              {locale.create_account}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CreateAccount

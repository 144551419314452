import React from 'react'
import { ConfirmSignUp as OriginalConfirmSignUp } from 'aws-amplify-react'
import ConfirmSignUpCore from './ConfirmSignUpCore'

export class ConfirmSignUp extends OriginalConfirmSignUp {
  constructor(props) {
    super(props)
    this._validAuthStates = ['confirmSignUp']
    this.error = this.error.bind(this)
    this.state = {
      error: null,
      inProgress: false,
      inProgressResend: false
    }
  }

  componentDidUpdate() {
    if (
      this.props.authState === 'confirmSignUp' &&
      window.previousAuthState === 'signIn'
    ) {
      delete window.previousAuthState
      this.resend()
    }
  }

  error(err) {
    this.setState({ error: err.message ? err.message : err, inProgress: false })
  }

  showComponent() {
    return (
      <ConfirmSignUpCore
        username={this.usernameFromAuthData()}
        handleInputChange={(evt) => {
          if (evt.target.value) {
            evt.target.value = evt.target.value.trim()
          }
          return this.handleInputChange(evt)
        }}
        changeState={(props) => {
          if (this.state.error) {
            this.setState({ error: null })
          }
          this.changeState(props)
        }}
        resend={(props) => {
          if (this.state.error) {
            this.setState({ error: null })
          }
          this.resend(props)
          this.setState({ inProgressResend: true })
          setTimeout(() => this.setState({ inProgressResend: false }), 1000)
        }}
        confirm={() => {
          if (this.state.error) {
            this.setState({ error: null })
          }
          this.setState({ inProgress: true })
          this.confirm()
          setTimeout(() => this.setState({ inProgress: false }), 10000) // used to remove the loader in case the user signs up, log outs and signs up again
        }}
        error={this.state.error}
        inProgress={this.state.inProgress}
        inProgressResend={this.state.inProgressResend}
      />
    )
  }
}

export default ConfirmSignUp

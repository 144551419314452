if (typeof window !== 'undefined') {

/*
    WebDataFetcher - handles fetching data from the API
*/
bcViewer.WebDataFetcher = bcViewer.BaseDataFetcher.extend({
    construct: function(globals) {
        this._super.apply(this, arguments);

        // Handle requests for ECG data
        this._ecgDataRequests = {};
        this._ecgDataRequestsEnabled = true;
        this._ecgDataRequestsAjax;

        // Handle requests for events
        this._eventsDataRequestAjax;
        this._eventsDataRequestCallbacks = [];

        // How many live ajax requests we currently have
        this._liveAjaxRequests = 0;

        // Emulation mode: set to true to emulate realtime mode using show_signal_data
        // Config the parameters for show_signal_data in _getSimulatedRTDataAjaxParams function
        this._REALTIME_EMULATION_ACTIVE = false;

        // HW emulation: set to true when using device app and HW emulation is needed
        this._REALTIME_HW_EMULATION_ACTIVE = false;

        // Define some auxilary vars for emulation mode
        // rtPos: time position for the show_signal_data
        // rtBlockSize: cx parameter for show_signal_data
        if (this._REALTIME_EMULATION_ACTIVE) {
            this._emulationRecordHash = 'Rec9F178D20AFEC6BEC7E2184A644ED4A5969CC6CD3',
            this._rtPos = 0;
            this._rtBlockSize = 150;
        }
    },

    /*
        Return the current record metadata to callback
    */
    getMetadata: function(callback) {
        // if we got the metadata
        if (typeof this._metadata !== 'undefined') {
            callback && callback(this._metadata);
        }

        this._fetch({
            url: process.env.BinScriptRunnerLambdaFunctionUrl + 'bin-script-runner/read_metadata',
            data: {'record_hash': this._bcViewer.getRecordHash(), 'record_type': this._recordType},
            cache: false,
            type: 'GET',
            context: this,
            success: function(resultData){
                // TODO: handle errors - do we need try/catch?
                this._metadata = resultData;

                // seconds -> milliseconds
                if (this._metadata.StartTime !== 0) {
                    this._metadata.StartTime *= 1000;

                    var timeZoneOffset = 0;

                    if (this._bcViewer.mode === this._bcViewer.modes.IN_APP) {
                        timeZoneOffset = this._metadata.timeZoneOffset;
                    }

                    this._metadata.StartTime = this._utils.adjustTimeToTimezone(this._metadata.StartTime, timeZoneOffset);
                }

                callback && callback(resultData);
            }
        });
    },

    /*
        Return the current record HR data to callback
    */
    getHRData: function(width, height, callback) {
        // resultData.events[0] - the specific HR data
        this._eventsDataRequestCallbacks.push(function(resultData) {
            // Protect from empty HR graph
            var HRData = resultData.events[0];
            var noHRData = true;

            var i=0;
            while ((noHRData) && (HRData.graph) && (i < HRData.graph.length)) {
                if (HRData.graph[i].series.length > 0) {
                    noHRData = false;
                }
                i++;
            }

            var response = (!noHRData) ? HRData : {};
            callback(response);
        });

        this._getEventsData(this._bcViewer.getRecordHash(), width, height);
    },

    /*
        Return all the current record events data to callback
    */
    getEventsData: function(width, height, callback) {
        var _callback = function(resultData) {
            // Changing the structre to be more efficient
            // TODO: do this change on Sasha's side
            var events = resultData.events.slice(1); // Getting rid of resultData.events[0] since it's HR data
            var _events = {};
            for (var i=0; i<events.length; i++) {
                var currentEvent = events[i];

                // if there are no events for the current events
                if (!currentEvent.lines || (currentEvent.lines.length === 0)) continue;

                // I'm currently copying the entire event to save some processing time
                // we need to remove id and maybe other fields as well (tooltip, time, etc)
                _events[currentEvent.id] = currentEvent;
            }
            callback(_events);
        };
        this._eventsDataRequestCallbacks.push(_callback);
        this._getEventsData(this._bcViewer.getRecordHash(), width, height);
    },

    /*
        Execute a request to the get_events API to get all the events in the current record
        and call all the callbacks in this._eventsDataRequestCallbacks
    */
    _getEventsData: function(recordHash, width, height) {
        // We alread have a live request for events data, no need to execute another one
        if (typeof this._eventsDataRequestAjax !== 'undefined') return;

        this._eventsDataRequestAjax = this._fetch({
            url: process.env.BinScriptRunnerLambdaFunctionUrl + 'bin-script-runner/get_events',
            data: {'record_hash': recordHash, 'record_type': this._recordType, 'cx': width, 'cy': height},
            cache: false,
            type: 'GET',
            context: this,
            success: function(resultData) {
                // TODO: handle errors

                delete this._eventsDataRequestAjax;

                var callback;
                while (callback = this._eventsDataRequestCallbacks.pop()) {
                    callback && callback(resultData);
                }
            }
        });
    },

    /*
        Get the events NP info from the API
    */
    getEventsNPInfo: function(recordHash, timePosition, callback) {
        this._fetch({
            url: process.env.BinScriptRunnerLambdaFunctionUrl + 'bin-script-runner/getevent_np_info',
            data: {'record_hash': recordHash, 'startTime': timePosition, 'get_bookmarks': 0},
            cache: true,
            type: 'GET',
            context: this,
            success: function(events) {
                // TODO: handle errors

                // Making an hash out of this data - we should change getevent_np_info to return hash
                var _events = {};
                for (var i=0; i<events.length; i++) {
                    var currentEvent = events[i];
                    _events[currentEvent.id] = currentEvent.all;
                }

                callback && callback(_events);
            }
        });
    },

    /*
        Enable ECG data regquests - send all the cached requests to the server
    */
    enableECGDataRequests: function() {
        this._ecgDataRequestsEnabled = true;
        this._executeECGDataRequest();
    },

    /*
        Disable ECG data regquests - no requests will be sent to server until enableECGDataRequests is called
    */
    disableECGDataRequests: function() {
        this._ecgDataRequestsEnabled = false;
    },

    /*
        Request new ecg data based on the dataParams
        The data itself won't be requested until we call _getECGData
    */
    requestECGData: function(dataParams, name, callback) {
        // Only one request at a time for each viewer
        if (this._ecgDataRequests[name]) {
            delete this._ecgDataRequests[name];
        }

        this._ecgDataRequests[name] = {
            'params': dataParams,
            'callback': callback
        };

        if (this._ecgDataRequestsEnabled) {
            this._executeECGDataRequest();
        }
    },

    /*
        Get ECG data from the server based on this._ecgDataRequests
    */
    _executeECGDataRequest:function() {
        var ecgDataRequests = this._ecgDataRequests;

        // Nothing to execute
        if ($.isEmptyObject(ecgDataRequests)) return;

        this._getECGData(ecgDataRequests, function(ecgData) {
            $.each(ecgDataRequests, function(name, request) {
                var callback = request.callback
                callback(ecgData[name]);
            });
        });

        // Reset after execuing the requests
        this._ecgDataRequests = {};
    },

    /*
        Return the current record ECG data to callback

        ecgDataRequests - hash mapping each viewer to it's params and requested callback
    */
    _getECGData: function(ecgDataRequests, callback) {
        // Only one call a time
        this._ecgDataRequestsAjax && this._ecgDataRequestsAjax.abort();

        this._ecgDataRequestsAjax = this._fetch({
            url: process.env.BinScriptRunnerLambdaFunctionUrl + 'bin-script-runner/read_data',
            data: this._getECGDataAjaxParams(ecgDataRequests),
            cache: true,
            type: 'GET',
            context: this,
            success: function(resultData){
                // TODO: handle errors (resultData.error))

                delete this._ecgDataRequestsAjax;

                // TODO: temp code - add real viewer names instead of data and preview
                // We need to change the API to return an array base on the ecgDataParams array
                var _resultData = this.__TEMP_FUNC_fixResultData(ecgDataRequests, resultData);
                callback && callback(_resultData);
            }
        });
    },

    /*
        Abort ECG data request
    */
    abortECGDataRequest: function() {
        this._ecgDataRequestsAjax && this._ecgDataRequestsAjax.abort();
    },

    /*
        Return the API parameters for ECG data requests from ecgDataRequests
    */
    _getECGDataAjaxParams: function(ecgDataRequests) {
        // TODO: parameter naems convention - either camelCase or underscore, not both
        // shouldExpand - for the app
        // expandChannels - for SSD
        var ajaxParams = {
            record_hash: this._bcViewer.getRecordHash(),
            record_type: this._recordType,
            shouldExpand: (parseInt(this._bcViewer.windowOrientation) === this._bcViewer.windowOrientations.PORTRAIT),
            expandChannels: ((ecgDataRequests.main && ecgDataRequests.main.params.expandChannels) ||
                             (ecgDataRequests.context && ecgDataRequests.context.params.expandChannels)) // we always want to expand (in case this is only preview request)
        };

        var num = 0;
        for (var name in ecgDataRequests) {
            var params = ecgDataRequests[name].params;
            for (var key in params) {
                ajaxParams[key + num] = params[key];
            }
            ajaxParams['name' + num] = name;
            num++;
        }


        // TODO: temp code - remove when we'll fix ruby side
        var num = 0;
        ajaxParams['cx'] = ajaxParams['cy'] = ajaxParams['rx'] = ajaxParams['ry'] = ajaxParams['startTime'] =
        ajaxParams['startPointOffset'] = ajaxParams['endPointOffset'] = 0;

        for (var name in ecgDataRequests) {
            var params = ecgDataRequests[name].params;
            for (var key in params) {
                var newKey = (name === "main") ? key : 'preview' + key.replace(/[a-z]/, function(letter) {
                    return letter.toUpperCase();
                });
                delete ajaxParams[key + num];
                ajaxParams[newKey] = params[key];
            }
            num++;
        }
        // Fake context height to get all channgels to fit
        ajaxParams['previewCy'] = 135;
        ////////////////// End temp code

        // Fix for showsignaldata (see Task #1810)
        if (ajaxParams['ry'] === 0) {
            ajaxParams['ry'] = 1;
        }

        return ajaxParams;
    },

    /*
        TODO: remove this function!!!
        Temp function to fix resultData into the correct format
        1. Create resultData.main and resultData.context (instead of data and preview)
        2. Fix context data to be one channle
        3. Center the context channel correctly using the channel offsets
        4. Fix the QRS data points
        5. Fix the QRS data captions
        6. Remove QRS data from context results
        7. Add lead names list
    */
    __TEMP_FUNC_fixResultData: function(ecgDataRequests, resultData) {
        var _resultData = {};


        _resultData.main = {};
        _resultData.main.series = resultData.data.series;

        // Build lead names list, and add the list to the result
        var leadNames = [];

        var leadNames;
        if (resultData.data.series[0].name !== '') {
            leadNames = [];
            for (var i = 0; i < resultData.data.series.length; i++) {
                leadNames[i] = resultData.data.series[i].name;
            }
        } else {
            var electrodesPosition = this._metadata.electrodesPosition || this._metadata.ElectrodePlacementId;
            if (electrodesPosition !== 0) { // old studies with no electrodes position
                leadNames = this.getLeadNamesByElectrodesPosition(electrodesPosition);
            }
        }

        _resultData.main.leadNames = leadNames;

        // Add channel index
        for (var i = 0; i < resultData.data.series.length; i++) {
            resultData.data.series[i].index = i;
        }

        // Fix QRS format
        _resultData.main.qrs = []
        var qrs = resultData.data.qrs || [];
        for (var i=0; i<qrs.length; i++) {
            var pointX = qrs[i].points[0][0];
            var caption = qrs[i].caption.split(' ');
            // Caption format is '324  ' or '213  A'
            var annotation = caption[2] || caption[0];
            _resultData.main.qrs[i] = {
                'color': qrs[i].color,
                'x': pointX,
		'tooltip': bcGlobals.locale.physioEvents['e_'+annotation],
                'annotations': [ {
                    'annotationType': 'qrs',
                    'caption': annotation,
                    'color': qrs[i].color,
                    'name': ''
                } ]
            };
        }

        if (!resultData.preview) return _resultData;

        var selectedChannel = ecgDataRequests.context && ecgDataRequests.context.params.selectedChannel;

        // Add channel index
        for (var i = 0; i < resultData.preview.series.length; i++) {
            resultData.preview.series[i].index = i;
        }

        // Fake only one channel from ShowSignalData
        _resultData.context = {};
        _resultData.context.series = [];
        _resultData.context.series[0] = resultData.preview.series[selectedChannel];

        // Center context channel
        var points = _resultData.context.series[0].points;
        var selectedChannelOffset = resultData.preview.series[selectedChannel].offset;
        var contextViewerHalfHeight = $$('#bc_viewer .bcv_context_canvas').height()/2;

        for (var i=0; i<points.length; i++) {
            points[i][1] -= selectedChannelOffset - contextViewerHalfHeight;
        }

        // Add lead names list to the result data
        _resultData.context.leadNames = leadNames;

        return _resultData;
    },

    /*
        Toggle realtime mode
    */
    toggleRealtimeMode: function(activateRealtimeMode, realtimeModeType, callback) {
        if (this._REALTIME_EMULATION_ACTIVE) {
            callback && callback(activateRealtimeMode);
            return;
        }

        if (this._REALTIME_HW_EMULATION_ACTIVE && activateRealtimeMode) {
            this._startSimulation(activateRealtimeMode, realtimeModeType, callback);
        } else {
            this._toggleOsc(activateRealtimeMode, realtimeModeType, callback);
        }
    },

    /*
        Toggle OSC
    */
    _toggleOsc: function(activateRealtimeMode, realtimeModeType, callback) {
        this._fetch({
            url: activateRealtimeMode ? '/osc/start' : '/osc/stop',
            data: {mode: realtimeModeType,
                   electrodesPosition: this._bcViewer.electrodesPosition},
            cache: true,
            type: 'GET',
            context: this,
            success: function(result){
                callback && callback(activateRealtimeMode, result.samplingRate, result.unitsPerMV);
            }
        });
    },

    /*
      start reading if simulating HW
    */
    _startSimulation: function(activateRealtimeMode, realtimeModeType, callback) {
        var toggleOSC = $.proxy(this._toggleOsc, this);
        this._fetch({
            url:  '/osc/emulation?type=saw',
            cache: true,
            type: 'GET',
            context: this,
            success: function(){
                toggleOSC(activateRealtimeMode, realtimeModeType, callback);
            }
        });
    },

    /*
        Read realtime data
    */
    getRealtimeData: function(requestParams, callback, errorCallback) {
        var api = '/osc/read_data';
        if (this._REALTIME_EMULATION_ACTIVE) {
            api = '/api/read_data'
        }

        _errorCallback = errorCallback;

        var req = {
            url: api,
            data: (this._REALTIME_EMULATION_ACTIVE) ? this._getSimulatedRTDataAjaxParams(requestParams) : requestParams,
            cache: true,
            type: 'GET',
            context: this,
            tryCount : 0,
            retryLimit : 3,
            timeout: 20000, // 20 seconds. total of 1 minute before showing error
            success: function(resultData){
                if (this._REALTIME_EMULATION_ACTIVE) {
                    resultData.series = resultData.data.series;
                }

                callback && callback(resultData);
            },
            error: function() {
                req.tryCount++;
                if (req.tryCount < req.retryLimit) {
                    this._fetch(req);

                } else {
                    _errorCallback && _errorCallback();
                }
            }
        };

        this._fetch(req);

        if (this._REALTIME_EMULATION_ACTIVE) {
            this._rtPos += this._rtBlockSize;
        }
    },

    /*
        Get simulated realtime parametes
    */
    _getSimulatedRTDataAjaxParams: function(requestParams) {
        return {
            'bl': false,
            'cx': this._rtBlockSize,
            'cy': 586,
            'endPointOffset': 13640,
            'name0': "main",
            'previewCy': 135,
            'record_hash': this._emulationRecordHash,
            'record_type': 0,
            'rx': 10,
            'ry': 25,
            'sm': false,
            'startPointOffset': 0,
            'startTime': this._rtPos
        }
    },

    getLeadNamesByElectrodesPosition: function(electrodesPosition) {
        var leadNames = [];
        var shouldExpand = (parseInt(this._bcViewer.windowOrientation) === this._bcViewer.windowOrientations.PORTRAIT);

        var positions = this._bcViewer.electrodesPositions;
        for (pos in positions) {
            var posItem = positions[pos];
            if (posItem.id === electrodesPosition) {
                leadNames = (shouldExpand) ? posItem.expandedLeadNames : posItem.leadNames;
                break;
            }
        }

        return leadNames;
    }
});

}

if (typeof window !== 'undefined') {

/*
    serverDataFetcher - handles fetching data from the API
*/
var ServerDataFetcher = function() {
};

/*
    Start spirometer - read audio and calculate flow.
    pitchFromServer: should the server play pitch
*/
ServerDataFetcher.prototype.startSpirometer = function(spirometerType, pitchFromServer, callback) {
     DataFetcherUtils._fetch({
        url: '/api/spirometer/start_spirometer',
        data: {
            spirometerType: spirometerType,
            pitchFromServer: pitchFromServer
        },
        cache: false,
        type: 'GET',
        context: this,
        success: function(resultData){                
            callback && callback(resultData);
        }
    });
};
    
/*
    Get the flow data
*/
ServerDataFetcher.prototype.getSpirometerData = function( callback) {
     DataFetcherUtils._fetch({
        url: '/api/spirometer/get_spirometer_data',
        data: {},
        cache: false,
        type: 'GET',
        context: this,
        success: function(resultData){                
            callback && callback(resultData);
        }
    });
};

/*
    Stop the spirometer test: stop reading audio and calculating flow
    If needed, stops the pitch from server
*/
ServerDataFetcher.prototype.stopSpirometer = function(callback) {
    DataFetcherUtils._fetch({
        url: '/api/spirometer/stop_spirometer',
        data: {},
        cache: false,
        type: 'GET',
        context: this,
        success: function(resultData){                
            callback && callback(resultData);
        }
    });
};

/*
    Save the results
*/
ServerDataFetcher.prototype.saveSpirometerResults = function(patId, results, callback) {
    DataFetcherUtils._fetch({
        url: '/api/spirometer/save_spirometer_results',
        data: {
            patId: patId,
            results: JSON.stringify(results)
        },
        cache: false,
        type: 'POST',
        context: this,
        success: function(resultData){                
            callback && callback(resultData);
        }
    });
};

/*
    Edit the results
*/
ServerDataFetcher.prototype.editSpirometerResults = function(recId, results, callback) {
    DataFetcherUtils._fetch({
        url: '/api/spirometer/edit_spirometer_results',
        data: {
            recId: recId,
            results: JSON.stringify(results)
        },
        cache: false,
        type: 'POST',
        context: this,
        success: function(resultData){                
            callback && callback(resultData);
        }
    });
};

/*
    Get the spirometer record data from the the file
*/
 ServerDataFetcher.prototype.getSpirometerRecordData = function(recId, callback) {    
    DataFetcherUtils._fetch({
        url: '/api/spirometer/get_spirometer_record_data',
        data: {
            recId: recId
        },
        cache: false,
        type: 'GET',
        context: this,
        success: function(resultData){                
            callback && callback(resultData);
        }
    });
};

window.ServerDataFetcher = ServerDataFetcher;

}
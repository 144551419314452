import { useEffect } from 'react'
import { navigate } from '@reach/router' // eslint-disable-line import/no-unresolved
import { getParams } from '../../utils/utils'

const Redirect = ({ from, to, location: { pathname, search } }) => {
  useEffect(() => {
    const pathnameParts = pathname.split('/')
    const queryParamsMap = getParams(search)
    const [fromPathname, fromQueryParams] = from.split('?')
    const fromPathnameParts = fromPathname.split('/')
    const fromQueryParamsMap = fromQueryParams
      ? getParams('?' + fromQueryParams)
      : {}
    const [toPathname, toQueryParams] = to.split('?')
    const toQueryParamsMap = toQueryParams ? getParams('?' + toQueryParams) : {}
    const params = {}
    fromPathnameParts.forEach((part, index) => {
      if (part.indexOf(':') === 0) {
        params[part.slice(1)] = pathnameParts[index]
      }
    })
    Object.keys(fromQueryParamsMap).forEach((key) => {
      if (fromQueryParamsMap[key].indexOf(':') === 0 && queryParamsMap[key]) {
        params[fromQueryParamsMap[key].slice(1)] = queryParamsMap[key]
      }
    })
    Object.keys(toQueryParamsMap).forEach((key) => {
      if (toQueryParamsMap[key].indexOf(':') === 0) {
        if (params[toQueryParamsMap[key].slice(1)]) {
          toQueryParamsMap[key] = params[toQueryParamsMap[key].slice(1)]
        } else {
          delete toQueryParamsMap[key]
        }
      }
    })
    const redirectUrl =
      toPathname
        .split('/')
        .map((part) =>
          part.indexOf(':') === 0 ? params[part.slice(1)] || part : part
        )
        .join('/') +
      (Object.keys(toQueryParamsMap).length > 0
        ? '?' + new URLSearchParams(toQueryParamsMap).toString()
        : '')
    navigate(redirectUrl, { replace: true })
  })
  return null
}

export default Redirect

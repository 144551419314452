import React from 'react'
import dayjs from 'dayjs'
import {
  WEIGHT_CONVERTER,
  HEIGHT_CONVERTER,
  ANIMAL_TYPE_NEW_TO_LEGACY_STRING,
  getAgeUnitsLocalizationKey,
  localizePatientName,
  DATE_FORMAT_CONVERTER
} from '../../utils/utils'
import { useLocalization } from './LocalizationProvider'

const PatientDetailsOld = ({
  anonymousPatientData,
  patient,
  isAnimal,
  dateFormat
}) => {
  const { locale, interpolate } = useLocalization()

  return (
    <>
      <td>
        <table className='patient_data_table'>
          <tbody>
            <tr id='patient_name_row'>
              <td className='patient_details_title'>
                {locale.study_header.patient_name}
              </td>
              <td className='patient_details_data'>
                <span id='patient_details_name'>
                  {localizePatientName(patient.name, locale)}
                </span>
              </td>
            </tr>
            <tr>
              <td className='patient_details_title'>
                {locale.study_header.patient_id}
              </td>
              <td className='patient_details_data'>
                <span id='patient_id'>{patient.externalId}</span>
              </td>
            </tr>
            <tr>
              <td className='patient_details_title'>
                {locale.study_header.email}
              </td>
              <td className='patient_details_data'>
                <span id='patient_email'>{patient.email}</span>
              </td>
            </tr>
            <tr>
              <td className='patient_details_title'>
                {locale.study_header.phone}
              </td>
              <td className='patient_details_data'>
                <span id='patient_phone'>{patient.phone}</span>
              </td>
            </tr>
            <tr>
              <td className='patient_details_title'>
                {locale.study_header.gender}
              </td>
              <td className='patient_details_data'>
                <span id='patient_gender'>
                  {locale[anonymousPatientData.gender.toLowerCase()]}
                </span>
              </td>
            </tr>
            <tr>
              <td className='patient_details_title'>
                {locale.study_header.age}
              </td>
              <td className='patient_details_data'>
                <span id='patient_age'>
                  {Number.isInteger(anonymousPatientData.age) &&
                  anonymousPatientData.age > 0
                    ? interpolate(locale.unit_tmpl, {
                        value: anonymousPatientData.age,
                        type: locale.study_header[
                          getAgeUnitsLocalizationKey(
                            anonymousPatientData.age,
                            anonymousPatientData.ageUnits
                              ? anonymousPatientData.ageUnits.toLowerCase()
                              : 'years'
                          )
                        ]
                      })
                    : ''}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </td>
      <td>
        <table className='patient_data_table'>
          <tbody>
            <tr>
              <td className='patient_details_title'>
                {locale.study_header.weight}
              </td>
              <td className='patient_details_data'>
                <span id='patient_weight'>
                  {typeof anonymousPatientData.weight === 'number' &&
                  anonymousPatientData.weight > 0
                    ? interpolate(locale.unit_tmpl, {
                        value: anonymousPatientData.weight,
                        type: locale[
                          WEIGHT_CONVERTER[anonymousPatientData.unitSystem]
                        ]
                      })
                    : null}
                </span>
              </td>
            </tr>
            <tr id='patient_height_row'>
              <td className='patient_details_title'>
                {locale.study_header.height}
              </td>
              <td className='patient_details_data'>
                <span id='patient_height'>
                  {typeof anonymousPatientData.height === 'number' &&
                  anonymousPatientData.height > 0
                    ? interpolate(locale.unit_tmpl, {
                        value: anonymousPatientData.height,
                        type: locale[
                          HEIGHT_CONVERTER[anonymousPatientData.unitSystem]
                        ]
                      })
                    : null}
                </span>
              </td>
            </tr>
            <tr>
              <td className='patient_details_title'>
                <span id='title_anamnesis'>
                  {locale.study_header.anamnesis}
                </span>
                <span id='title_history'>{locale.study_header.history}</span>
              </td>
              <td className='patient_details_data'>
                <span id='patient_anamnesis'>
                  {anonymousPatientData.anamnesis}
                </span>
              </td>
            </tr>
            <tr id='patient_birthdate_row'>
              <td className='patient_details_title'>
                {locale.study_header.birthday}
              </td>
              <td>
                {patient.birthdate
                  ? dayjs(patient.birthdate).format(
                      `${DATE_FORMAT_CONVERTER[dateFormat]}`
                    )
                  : ''}
              </td>
            </tr>
            <tr>
              <td className='patient_details_title'>
                <span id='title_icd_code'>{locale.study_header.icd_code}</span>
                <span id='title_icd_code_veterinarian'>
                  {locale.study_header.icd_code_veterinarian}
                </span>
              </td>
              <td className='patient_details_data'>
                <span id='icd_code'>{anonymousPatientData.icdCode}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </td>
      <td id='animal_remarks'>
        <table className='animal_data_table'>
          <tbody>
            <tr>
              <td>{locale.study_header.animal_type}</td>
              <td className='patient_details_data'>
                <span id='animal_type'>
                  {isAnimal &&
                  anonymousPatientData.animal &&
                  anonymousPatientData.animal.type
                    ? locale.animals[
                        ANIMAL_TYPE_NEW_TO_LEGACY_STRING[
                          anonymousPatientData.animal.type
                        ]
                      ]
                    : null}
                </span>
              </td>
            </tr>
            <tr>
              <td>{locale.study_header.animal_breed}</td>
              <td className='patient_details_data'>
                <span id='animal_breed'>
                  {isAnimal &&
                  anonymousPatientData.animal &&
                  anonymousPatientData.animal.breed
                    ? anonymousPatientData.animal.breed
                    : null}
                </span>
              </td>
            </tr>
            <tr>
              <td>{locale.study_header.animal_owner}</td>
              <td className='patient_details_data'>
                <span id='animal_owner'>
                  {isAnimal && patient.animal && patient.animal.owner}
                </span>
              </td>
            </tr>
            <tr id='castrated_row'>
              <td className='patient_details_title'>
                {locale.study_header.animal_castrated}
              </td>
              <td className='patient_details_data'>
                {isAnimal &&
                anonymousPatientData.animal &&
                typeof anonymousPatientData.animal.castrated == 'boolean'
                  ? anonymousPatientData.animal.castrated
                    ? locale.study_header.animal_castrated_yes
                    : locale.study_header.animal_castrated_no
                  : ''}
              </td>
            </tr>
          </tbody>
        </table>
      </td>
    </>
  )
}

export default PatientDetailsOld

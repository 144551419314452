import React, { useState } from 'react'
import { gql, useMutation } from '@apollo/client'
import { useQuery } from '@apollo/client'
import { GET_QUESTIONNAIRE_TEMPLATES } from '../queries'
import Loader from '../Loader'
import clsx from 'clsx'

const QUESTIONNAIRE_TEMPLATE_CREATE = gql`
  mutation QUESTIONNAIRE_TEMPLATE_CREATE(
    $id: ID!
    $createdAt: AWSDateTime!
    $template: [QuestionnaireItemInput!]!
    $isForced: Boolean!
    $isCanBeStandaloneStudy: Boolean!
  ) {
    questionnaireTemplateCreate(
      id: $id
      createdAt: $createdAt
      template: $template
      isForced: $isForced
      isCanBeStandaloneStudy: $isCanBeStandaloneStudy
    ) {
      id
    }
  }
`

const QUESTIONNAIRE_TEMPLATE_UPDATE = gql`
  mutation QUESTIONNAIRE_TEMPLATE_UPDATE(
    $id: ID!
    $updatedAt: AWSDateTime!
    $template: [QuestionnaireItemInput!]!
    $isForced: Boolean!
    $isCanBeStandaloneStudy: Boolean!
  ) {
    questionnaireTemplateUpdate(
      id: $id
      updatedAt: $updatedAt
      template: $template
      isForced: $isForced
      isCanBeStandaloneStudy: $isCanBeStandaloneStudy
    ) {
      id
    }
  }
`

const AdminQuestionnaireTemplates = () => {
  const [inProgress, setProgress] = useState(false)
  const [selectedTempalteIndex, setSelectedTemplateIndex] = useState(-1)
  const [templateIdInput, setTemplateIdInput] = useState('')
  const [template, setTemplate] = useState('')
  const [isForcedInput, setIsForcedInput] = useState(false)
  const [isCanBeStandaloneStudyInput, setIsCanBeStandaloneStudy] =
    useState(false)
  const { loading, error, data } = useQuery(GET_QUESTIONNAIRE_TEMPLATES, {})

  const [questionnaireTemplateCreate, { loading: isCreating }] = useMutation(
    QUESTIONNAIRE_TEMPLATE_CREATE,
    {
      refetchQueries: [
        {
          query: GET_QUESTIONNAIRE_TEMPLATES
        }
      ],
      awaitRefetchQueries: true
    }
  )

  const [questionnaireTemplateUpdate, { loading: isUpdating }] = useMutation(
    QUESTIONNAIRE_TEMPLATE_UPDATE,
    {
      refetchQueries: [
        {
          query: GET_QUESTIONNAIRE_TEMPLATES
        }
      ],
      awaitRefetchQueries: true
    }
  )

  async function submit() {
    setProgress(true)

    if (selectedTempalteIndex == -1) {
      await questionnaireTemplateCreate({
        variables: {
          id: templateIdInput,
          createdAt: new Date().toISOString(),
          template: JSON.parse(template.replace(/\\"/g, '"')),
          isForced: isForcedInput,
          isCanBeStandaloneStudy: isCanBeStandaloneStudyInput
        }
      })
    } else {
      await questionnaireTemplateUpdate({
        variables: {
          id: data.questionnaireTemplates[selectedTempalteIndex].id,
          updatedAt: new Date().toISOString(),
          template: JSON.parse(template.replace(/\\"/g, '"')),
          isForced: isForcedInput,
          isCanBeStandaloneStudy: isCanBeStandaloneStudyInput
        }
      })
    }

    setProgress(false)
    alert('done')
  }

  return (
    <div className='mt-5'>
      {error && <div> error... </div>}
      {loading ? (
        <Loader />
      ) : (
        <div className='mb-5'>
          <div className='level'>
            <div className='level-left'>
              <div className='level-item'>
                <div className='select'>
                  <select
                    value={selectedTempalteIndex}
                    onChange={(e) => {
                      if (e.target.value == -1) {
                        setSelectedTemplateIndex(e.target.value)
                        setTemplateIdInput('')
                        setTemplate('')
                        setIsForcedInput(false)
                        setIsCanBeStandaloneStudy(false)
                      } else {
                        setSelectedTemplateIndex(e.target.value)
                        setTemplateIdInput('')
                        setTemplate(
                          JSON.stringify(
                            data.questionnaireTemplates[
                              e.target.value
                            ].template.map((t) => {
                              let nt = {
                                type: t.type,
                                title: t.title,
                                options: t.options,
                                value: t.value
                              }
                              return nt
                            })
                          )
                        )
                        setIsForcedInput(
                          data.questionnaireTemplates[e.target.value].isForced
                        )
                        setIsCanBeStandaloneStudy(
                          data.questionnaireTemplates[e.target.value]
                            .isCanBeStandaloneStudy
                        )
                      }
                    }}
                  >
                    <option value='-1'>New template</option>
                    {data.questionnaireTemplates.map(({ id }, index) => (
                      <option value={index} key={id}>
                        {id}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className='level-right'>
              <div className='level-item'>
                <a
                  className='button is-link is-light'
                  href='/questionnaire-builder'
                  target='_blank'
                >
                  Open the questionnaire builder
                </a>
              </div>
            </div>
          </div>
          <div className='mt-5'>
            <div className='field is-horizontal'>
              <div className='field-label'>
                <label className='label has-text-left'>Template id:</label>
              </div>
              <div className='field-body'>
                <div className='field'>
                  {selectedTempalteIndex != -1 ? (
                    <div className='control'>
                      {data.questionnaireTemplates[selectedTempalteIndex].id}
                    </div>
                  ) : (
                    <div className='control'>
                      <input
                        className='input'
                        type='text'
                        placeholder='Template id'
                        value={templateIdInput}
                        onChange={(e) => setTemplateIdInput(e.target.value)}
                      ></input>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className='field is-horizontal'>
              <div className='field-label'>
                <label className='label has-text-left'>Is forced:</label>
              </div>
              <div className='field-body'>
                <div className='field'>
                  <div className='control'>
                    <label className='checkbox'>
                      <input
                        type='checkbox'
                        checked={isForcedInput}
                        onChange={(e) => setIsForcedInput(e.target.checked)}
                        className='mr-2'
                      />
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className='field is-horizontal'>
              <div className='field-label'>
                <label className='label has-text-left'>
                  Can be a standalone study:
                </label>
              </div>
              <div className='field-body'>
                <div className='field'>
                  <div className='control'>
                    <label className='checkbox'>
                      <input
                        type='checkbox'
                        checked={isCanBeStandaloneStudyInput}
                        onChange={(e) =>
                          setIsCanBeStandaloneStudy(e.target.checked)
                        }
                        className='mr-2'
                      />
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className='field is-horizontal'>
              <div className='field-label'>
                <label className='label has-text-left'>Template:</label>
              </div>
              <div className='field-body'>
                <div className='field'>
                  <div className='control'>
                    <textarea
                      className='textarea'
                      value={template}
                      placeholder='Template'
                      onChange={(e) => setTemplate(e.target.value)}
                      rows='20'
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
            <div className='level-right'>
              <div className='level-item'>
                <a
                  className={clsx('button is-primary', {
                    'is-loading': inProgress || isCreating || isUpdating
                  })}
                  href='#'
                  disabled={
                    (templateIdInput === '' && selectedTempalteIndex == -1) ||
                    template === ''
                  }
                  onClick={async () => submit()}
                >
                  Save
                </a>
              </div>
            </div>
          </div>
          <div>
            <p className='has-text-danger is-size-5 has-text-weight-bold'>
              Instructions
            </p>
            <ol>
              <li>
                Copy the questionnaire from here to the textarea in the
                questionnaire builder
              </li>
              <li>Click on load</li>
              <li>edit the questionnaire</li>
              <li>click on create to update the text area</li>
              <li>
                copy the textarea value from the questionnaire builder to here
              </li>
            </ol>
          </div>
        </div>
      )}
    </div>
  )
}

export default AdminQuestionnaireTemplates

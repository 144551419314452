import React, { useState } from 'react'
import { gql, useMutation } from '@apollo/client'
import Modal from '../Modal'
import clsx from 'clsx'
import { GET_REFERRALS_CONFIG } from '../queries'

const REMOVE_FROM_REFERRAL_CONFIG = gql`
  mutation REMOVE_FROM_REFERRAL_CONFIG(
    $id: ID!
    $group: String!
    $studyProtocol: StudyProtocol!
    $referralType: ReferralType!
  ) {
    userRemoveFromReferralsConfig(
      id: $id
      group: $group
      studyProtocol: $studyProtocol
      referralType: $referralType
    ) {
      id
    }
  }
`

const RemoeFromReferralConfig = ({
  group,
  configToRemove,
  onExit,
  onComplete
}) => {
  const [inProgress, setProgress] = useState(false)

  const [userRemoveFromReferralsConfig, { loading: isRemoving }] = useMutation(
    REMOVE_FROM_REFERRAL_CONFIG,
    {
      refetchQueries: [
        {
          query: GET_REFERRALS_CONFIG,
          variables: { referralsConfigId: group }
        }
      ],
      awaitRefetchQueries: true
    }
  )

  async function submit() {
    setProgress(true)

    await userRemoveFromReferralsConfig({
      variables: {
        id: configToRemove.caregiver.id,
        group: group,
        studyProtocol: configToRemove.studyProtocol,
        referralType: configToRemove.referralType
      }
    })
    setProgress(false)
    onComplete()
  }

  return (
    <Modal onClose={onExit} onSubmit={submit} inProgress={inProgress}>
      <div className='field is-horizontal'>
        <div className='field-label'>
          <label className='label has-text-left'>Group:</label>
        </div>
        <div className='field-body'>
          <div className='field'>{group}</div>
        </div>
      </div>
      {configToRemove.isQueue ? (
        <div className='field is-horizontal'>
          <div className='field-label'>
            <label className='label has-text-left'>Queue:</label>
          </div>
          <div className='field-body'>
            <div className='field'>
              <div className='control'>{configToRemove.caregiver.name}</div>
            </div>
          </div>
        </div>
      ) : (
        <div className='field is-horizontal'>
          <div className='field-label'>
            <label className='label has-text-left'>Caregiver:</label>
          </div>
          <div className='field-body'>
            <div className='field'>
              <div className='control'>{configToRemove.caregiver.name}</div>
            </div>
          </div>
        </div>
      )}
      <div className='field is-horizontal'>
        <div className='field-label'>
          <label className='label has-text-left'>Protocol:</label>
        </div>
        <div className='field-body'>
          <div className='field'>
            <div className='control'>{configToRemove.studyProtocol}</div>
          </div>
        </div>
      </div>
      <div className='field is-horizontal'>
        <div className='field-label'>
          <label className='label has-text-left'>Type:</label>
        </div>
        <div className='field-body'>
          <div className='field'>
            <div className='control'>{configToRemove.referralType}</div>
          </div>
        </div>
      </div>
      <div className='field is-horizontal'>
        <div className='field-label'>
          <label className='label has-text-left'>Price:</label>
        </div>
        <div className='field-body'>
          <div className='field'>
            <div className='control'>{configToRemove.price}</div>
          </div>
        </div>
      </div>
      <div className='field is-horizontal'>
        <div className='field-label'>
          <label className='label has-text-left'>Currency:</label>
        </div>
        <div className='field-body'>
          <div className='field'>
            <div className='control'>{configToRemove.currency}</div>
          </div>
        </div>
      </div>

      <div className='level'>
        <div className='level-left'>
          <div className='level-item'></div>
        </div>
        <div className='level-right'>
          <div className='level-item'>
            <button
              className={clsx('button is-danger', {
                'is-loading': inProgress || isRemoving
              })}
              onClick={async () => submit()}
            >
              REMOVE
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default RemoeFromReferralConfig

import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import dayjs from 'dayjs'
import S3 from 'aws-sdk/clients/s3'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faDownload,
  faExternalLinkAlt
} from '@fortawesome/free-solid-svg-icons'
import { currentCredentials } from '../../utils/credentials'
import { useLocalization } from './LocalizationProvider'

let s3

const StudyAttachments = ({ attachmentGroups = [], dateFormat }) => {
  const [tab, setTab] = useState(0)
  const [files, setfiles] = useState({})
  const { locale, interpolate } = useLocalization()

  useEffect(() => {
    async function callMe() {
      const credentials = await currentCredentials()
      s3 = new S3({ credentials })
      const urls = {}
      attachmentGroups.forEach((attachmentGroup) => {
        attachmentGroup.attachments.forEach((attachment) => {
          urls[attachment.id] = s3.getSignedUrl('getObject', {
            Bucket: attachment.file.bucket,
            Key: attachment.file.key
          })
        })
      })
      setfiles(urls)
    }
    callMe()
  }, [])
  return (
    <div className='columns'>
      <div className='column is-narrow'>
        <div className='tabs is-vertical'>
          <ul>
            {attachmentGroups.map((attachmentGroup, index) => (
              <li key={index} className={clsx({ 'is-active': tab === index })}>
                <a onClick={() => setTab(index)}>
                  <span className='attachment-title'>
                    {attachmentGroup.title || locale.no_title}
                  </span>{' '}
                  ({attachmentGroup.attachments.length})
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className='column'>
        <div className='level'>
          <div className='level-left'>
            <div className='level-item has-text-weight-bold is-justify-content-flex-start is-flex-grow-1 attachment-attribute-title'>
              {locale.attachments.title}
            </div>
            <div className='level-item'>
              <div className='attachment-attribute'>
                {attachmentGroups[tab].title}
              </div>
            </div>
          </div>
        </div>
        <div className='level'>
          <div className='level-left'>
            <div className='level-item has-text-weight-bold is-justify-content-flex-start attachment-attribute-title'>
              {locale.attachments.description}
            </div>
            <div className='level-item'>
              <div className='attachment-attribute'>
                {attachmentGroups[tab].description}
              </div>
            </div>
          </div>
        </div>
        {attachmentGroups[tab].attachments.map((attachment) => (
          <div key={attachment.id} className='content'>
            <div className='level mb-0'>
              <div className='level-left'>
                <div className='level-item'>
                  {interpolate(locale.attachments.added_at, {
                    time: dayjs(attachment.createdAt).format(dateFormat)
                  })}
                </div>
              </div>
              <div className='level-right'>
                <div className='level-item'>
                  <a
                    className='navbar-item'
                    download
                    onClick={() => {
                      const url = s3.getSignedUrl('getObject', {
                        Bucket: attachment.file.bucket,
                        Key: attachment.file.key,
                        ResponseContentDisposition: `attachment; filename="${attachment.file.fileName}"`,
                        ResponseContentType: attachment.file.type
                      })
                      window.open(url, '_blank')
                    }}
                  >
                    <span className='icon'>
                      <FontAwesomeIcon icon={faDownload} />
                    </span>
                    <span>{locale.attachments.download}</span>
                  </a>
                </div>
                <div className='level-item'>
                  <a
                    className='navbar-item'
                    onClick={() => {
                      const url = s3.getSignedUrl('getObject', {
                        Bucket: attachment.file.bucket,
                        Key: attachment.file.key,
                        ResponseContentDisposition: 'inline',
                        ResponseContentType: attachment.file.type
                      })
                      window.open(url, '_blank')
                    }}
                  >
                    <span className='icon'>
                      <FontAwesomeIcon icon={faExternalLinkAlt} />
                    </span>
                    <span>{locale.attachments.open}</span>
                  </a>
                </div>
              </div>
            </div>
            <img src={files[attachment.id]} />
          </div>
        ))}
      </div>
    </div>
  )
}

export default StudyAttachments

if (typeof window !== 'undefined') {

/*
    GLIPredData - structures for predicted values.
    Structure description:
    GLIPredData[<calculation name>][gender]: {
        maxAge: #,
        a: [],
        p: [],
        q: [],
        spline: [age, l, m, s]
    }
    
    Where calculation name is one of the spirometer calculated values (FVC, FEV1...)
*/
var GLIPredData = {};

GLIPredData.FVC = {};
GLIPredData.FEV1 = {};
GLIPredData.FEV1FVC = {};

GLIPredData.FVC.male = {};
GLIPredData.FVC.male.a = [-11.2281, 2.4135, 0.0865, -0.1684, -0.0405, -0.1177, -0.0825];
GLIPredData.FVC.male.p = [-2.2963,  0.0718, 0.0794, -0.4600, 0.0325,  -0.0503];
GLIPredData.FVC.male.q = [0.9481,0];
GLIPredData.FVC.male.maxAge = 95;
GLIPredData.FVC.male.spline = 
[[  3.00    ,   0.0000000000    ,   -0.0937836046   ,   0.2986081896    ],
[   3.25    ,   0.0000000000    ,   -0.0888079910   ,   0.2784511981    ],
[   3.50    ,   0.0000000000    ,   -0.0836252420   ,   0.2598470731    ],
[   3.75    ,   0.0000000000    ,   -0.0783481767   ,   0.2425203189    ],
[   4.00    ,   0.0000000000    ,   -0.0731354197   ,   0.2262502239    ],
[   4.25    ,   0.0000000000    ,   -0.0683066710   ,   0.2108847491    ],
[   4.50    ,   0.0000000000    ,   -0.0642288474   ,   0.1962994973    ],
[   4.75    ,   0.0000000000    ,   -0.0610636402   ,   0.1823898266    ],
[   5.00    ,   0.0000000000    ,   -0.0588799570   ,   0.1690617481    ],
[   5.25    ,   0.0000000000    ,   -0.0577618384   ,   0.1562426174    ],
[   5.50    ,   0.0000000000    ,   -0.0575580037   ,   0.1438516876    ],
[   5.75    ,   0.0000000000    ,   -0.0580622541   ,   0.1318254308    ],
[   6.00    ,   0.0000000000    ,   -0.0591159738   ,   0.1201535300    ],
[   6.25    ,   0.0000000000    ,   -0.0604698995   ,   0.1088156596    ],
[   6.50    ,   0.0000000000    ,   -0.0618021335   ,   0.0977854426    ],
[   6.75    ,   0.0000000000    ,   -0.0627449980   ,   0.0870587621    ],
[   7.00    ,   0.0000000000    ,   -0.0631941875   ,   0.0766448204    ],
[   7.25    ,   0.0000000000    ,   -0.0632673034   ,   0.0665304831    ],
[   7.50    ,   0.0000000000    ,   -0.0631259696   ,   0.0567240824    ],
[   7.75    ,   0.0000000000    ,   -0.0627934223   ,   0.0472408833    ],
[   8.00    ,   0.0000000000    ,   -0.0622323105   ,   0.0380763599    ],
[   8.25    ,   0.0000000000    ,   -0.0613089954   ,   0.0292284363    ],
[   8.50    ,   0.0000000000    ,   -0.0598289297   ,   0.0207221341    ],
[   8.75    ,   0.0000000000    ,   -0.0575848112   ,   0.0125814361    ],
[   9.00    ,   0.0000000000    ,   -0.0547039057   ,   0.0048446595    ],
[   9.25    ,   0.0000000000    ,   -0.0514752535   ,   -0.0024370167   ],
[   9.50    ,   0.0000000000    ,   -0.0481667744   ,   -0.0092171523   ],
[   9.75    ,   0.0000000000    ,   -0.0449259338   ,   -0.0154553878   ],
[   10.00   ,   0.0000000000    ,   -0.0418209444   ,   -0.0211267484   ],
[   10.25   ,   0.0000000000    ,   -0.0388957980   ,   -0.0262246957   ],
[   10.50   ,   0.0000000000    ,   -0.0361195620   ,   -0.0307656985   ],
[   10.75   ,   0.0000000000    ,   -0.0334371419   ,   -0.0347797663   ],
[   11.00   ,   0.0000000000    ,   -0.0307750149   ,   -0.0383016023   ],
[   11.25   ,   0.0000000000    ,   -0.0280244381   ,   -0.0413764781   ],
[   11.50   ,   0.0000000000    ,   -0.0250480295   ,   -0.0440537265   ],
[   11.75   ,   0.0000000000    ,   -0.0216945719   ,   -0.0463806550   ],
[   12.00   ,   0.0000000000    ,   -0.0178332387   ,   -0.0484007958   ],
[   12.25   ,   0.0000000000    ,   -0.0133771185   ,   -0.0501554464   ],
[   12.50   ,   0.0000000000    ,   -0.0082914400   ,   -0.0516856917   ],
[   12.75   ,   0.0000000000    ,   -0.0025514951   ,   -0.0530285966   ],
[   13.00   ,   0.0000000000    ,   0.0038413049    ,   -0.0542175078   ],
[   13.25   ,   0.0000000000    ,   0.0108424863    ,   -0.0552829777   ],
[   13.50   ,   0.0000000000    ,   0.0183697603    ,   -0.0562524613   ],
[   13.75   ,   0.0000000000    ,   0.0263239837    ,   -0.0571495716   ],
[   14.00   ,   0.0000000000    ,   0.0346104287    ,   -0.0579922533   ],
[   14.25   ,   0.0000000000    ,   0.0431366641    ,   -0.0587952911   ],
[   14.50   ,   0.0000000000    ,   0.0518160332    ,   -0.0595716419   ],
[   14.75   ,   0.0000000000    ,   0.0605600206    ,   -0.0603306976   ],
[   15.00   ,   0.0000000000    ,   0.0692708105    ,   -0.0610810879   ],
[   15.25   ,   0.0000000000    ,   0.0778532395    ,   -0.0618317824   ],
[   15.50   ,   0.0000000000    ,   0.0862224483    ,   -0.0625893258   ],
[   15.75   ,   0.0000000000    ,   0.0943066565    ,   -0.0633560419   ],
[   16.00   ,   0.0000000000    ,   0.1020454897    ,   -0.0641332116   ],
[   16.25   ,   0.0000000000    ,   0.1093878502    ,   -0.0649210230   ],
[   16.50   ,   0.0000000000    ,   0.1162957837    ,   -0.0657180646   ],
[   16.75   ,   0.0000000000    ,   0.1227424934    ,   -0.0665230569   ],
[   17.00   ,   0.0000000000    ,   0.1287091686    ,   -0.0673345174   ],
[   17.25   ,   0.0000000000    ,   0.1341870741    ,   -0.0681501347   ],
[   17.50   ,   0.0000000000    ,   0.1391890439    ,   -0.0689672882   ],
[   17.75   ,   0.0000000000    ,   0.1437362229    ,   -0.0697839813   ],
[   18.00   ,   0.0000000000    ,   0.1478490875    ,   -0.0705991037   ],
[   18.25   ,   0.0000000000    ,   0.1515459577    ,   -0.0714113847   ],
[   18.50   ,   0.0000000000    ,   0.1548467340    ,   -0.0722170465   ],
[   18.75   ,   0.0000000000    ,   0.1577665693    ,   -0.0730119609   ],
[   19.00   ,   0.0000000000    ,   0.1603151094    ,   -0.0737923465   ],
[   19.25   ,   0.0000000000    ,   0.1625039202    ,   -0.0745540044   ],
[   19.50   ,   0.0000000000    ,   0.1643576811    ,   -0.0752922087   ],
[   19.75   ,   0.0000000000    ,   0.1659023116    ,   -0.0760022342   ],
[   20.00   ,   0.0000000000    ,   0.1671593941    ,   -0.0766798985   ],
[   20.25   ,   0.0000000000    ,   0.1681498379    ,   -0.0773219967   ],
[   20.50   ,   0.0000000000    ,   0.1688933161    ,   -0.0779271107   ],
[   20.75   ,   0.0000000000    ,   0.1694103533    ,   -0.0784943063   ],
[   21.00   ,   0.0000000000    ,   0.1697221481    ,   -0.0790227611   ],
[   21.25   ,   0.0000000000    ,   0.1698468772    ,   -0.0795117783   ],
[   21.50   ,   0.0000000000    ,   0.1697979084    ,   -0.0799611256   ],
[   21.75   ,   0.0000000000    ,   0.1695885577    ,   -0.0803705070   ],
[   22.00   ,   0.0000000000    ,   0.1692321399    ,   -0.0807395103   ],
[   22.25   ,   0.0000000000    ,   0.1687403505    ,   -0.0810676962   ],
[   22.50   ,   0.0000000000    ,   0.1681214457    ,   -0.0813554400   ],
[   22.75   ,   0.0000000000    ,   0.1673852503    ,   -0.0816032390   ],
[   23.00   ,   0.0000000000    ,   0.1665432536    ,   -0.0818113887   ],
[   23.25   ,   0.0000000000    ,   0.1656067729    ,   -0.0819804286   ],
[   23.50   ,   0.0000000000    ,   0.1645846833    ,   -0.0821124195   ],
[   23.75   ,   0.0000000000    ,   0.1634849843    ,   -0.0822095691   ],
[   24.00   ,   0.0000000000    ,   0.1623156806    ,   -0.0822735747   ],
[   24.25   ,   0.0000000000    ,   0.1610849440    ,   -0.0823058379   ],
[   24.50   ,   0.0000000000    ,   0.1597995884    ,   -0.0823075670   ],
[   24.75   ,   0.0000000000    ,   0.1584665162    ,   -0.0822797604   ],
[   25.00   ,   0.0000000000    ,   0.1570927350    ,   -0.0822234197   ],
[   25.25   ,   0.0000000000    ,   0.1556845422    ,   -0.0821396304   ],
[   25.50   ,   0.0000000000    ,   0.1542467294    ,   -0.0820300149   ],
[   25.75   ,   0.0000000000    ,   0.1527825091    ,   -0.0818964205   ],
[   26.00   ,   0.0000000000    ,   0.1512934481    ,   -0.0817404780   ],
[   26.25   ,   0.0000000000    ,   0.1497805720    ,   -0.0815636081   ],
[   26.50   ,   0.0000000000    ,   0.1482441760    ,   -0.0813674298   ],
[   26.75   ,   0.0000000000    ,   0.1466856513    ,   -0.0811535077   ],
[   27.00   ,   0.0000000000    ,   0.1451068833    ,   -0.0809229530   ],
[   27.25   ,   0.0000000000    ,   0.1435118121    ,   -0.0806765372   ],
[   27.50   ,   0.0000000000    ,   0.1419058194    ,   -0.0804151149   ],
[   27.75   ,   0.0000000000    ,   0.1402941555    ,   -0.0801394790   ],
[   28.00   ,   0.0000000000    ,   0.1386819644    ,   -0.0798502909   ],
[   28.25   ,   0.0000000000    ,   0.1370732256    ,   -0.0795480726   ],
[   28.50   ,   0.0000000000    ,   0.1354680495    ,   -0.0792332510   ],
[   28.75   ,   0.0000000000    ,   0.1338660168    ,   -0.0789061923   ],
[   29.00   ,   0.0000000000    ,   0.1322668672    ,   -0.0785670267   ],
[   29.25   ,   0.0000000000    ,   0.1306701504    ,   -0.0782156806   ],
[   29.50   ,   0.0000000000    ,   0.1290735679    ,   -0.0778520391   ],
[   29.75   ,   0.0000000000    ,   0.1274757765    ,   -0.0774758413   ],
[   30.00   ,   0.0000000000    ,   0.1258762122    ,   -0.0770867906   ],
[   30.25   ,   0.0000000000    ,   0.1242748095    ,   -0.0766846002   ],
[   30.50   ,   0.0000000000    ,   0.1226714637    ,   -0.0762691944   ],
[   30.75   ,   0.0000000000    ,   0.1210662520    ,   -0.0758405436   ],
[   31.00   ,   0.0000000000    ,   0.1194598446    ,   -0.0753985553   ],
[   31.25   ,   0.0000000000    ,   0.1178533383    ,   -0.0749431363   ],
[   31.50   ,   0.0000000000    ,   0.1162482420    ,   -0.0744741160   ],
[   31.75   ,   0.0000000000    ,   0.1146461487    ,   -0.0739913006   ],
[   32.00   ,   0.0000000000    ,   0.1130490031    ,   -0.0734944786   ],
[   32.25   ,   0.0000000000    ,   0.1114589384    ,   -0.0729835035   ],
[   32.50   ,   0.0000000000    ,   0.1098766611    ,   -0.0724584887   ],
[   32.75   ,   0.0000000000    ,   0.1083022472    ,   -0.0719196550   ],
[   33.00   ,   0.0000000000    ,   0.1067358048    ,   -0.0713672762   ],
[   33.25   ,   0.0000000000    ,   0.1051773309    ,   -0.0708016572   ],
[   33.50   ,   0.0000000000    ,   0.1036267262    ,   -0.0702229493   ],
[   33.75   ,   0.0000000000    ,   0.1020843100    ,   -0.0696312068   ],
[   34.00   ,   0.0000000000    ,   0.1005506352    ,   -0.0690264865   ],
[   34.25   ,   0.0000000000    ,   0.0990260684    ,   -0.0684087320   ],
[   34.50   ,   0.0000000000    ,   0.0975098587    ,   -0.0677778467   ],
[   34.75   ,   0.0000000000    ,   0.0960012243    ,   -0.0671336902   ],
[   35.00   ,   0.0000000000    ,   0.0944993178    ,   -0.0664761640   ],
[   35.25   ,   0.0000000000    ,   0.0930030414    ,   -0.0658051193   ],
[   35.50   ,   0.0000000000    ,   0.0915105535    ,   -0.0651202659   ],
[   35.75   ,   0.0000000000    ,   0.0900202991    ,   -0.0644213021   ],
[   36.00   ,   0.0000000000    ,   0.0885315082    ,   -0.0637078930   ],
[   36.25   ,   0.0000000000    ,   0.0870433990    ,   -0.0629796847   ],
[   36.50   ,   0.0000000000    ,   0.0855543804    ,   -0.0622363847   ],
[   36.75   ,   0.0000000000    ,   0.0840628828    ,   -0.0614777131   ],
[   37.00   ,   0.0000000000    ,   0.0825678893    ,   -0.0607033387   ],
[   37.25   ,   0.0000000000    ,   0.0810685764    ,   -0.0599129501   ],
[   37.50   ,   0.0000000000    ,   0.0795640573    ,   -0.0591063860   ],
[   37.75   ,   0.0000000000    ,   0.0780536975    ,   -0.0582834774   ],
[   38.00   ,   0.0000000000    ,   0.0765373034    ,   -0.0574440144   ],
[   38.25   ,   0.0000000000    ,   0.0750145616    ,   -0.0565878376   ],
[   38.50   ,   0.0000000000    ,   0.0734848443    ,   -0.0557148512   ],
[   38.75   ,   0.0000000000    ,   0.0719476430    ,   -0.0548249718   ],
[   39.00   ,   0.0000000000    ,   0.0704024785    ,   -0.0539181361   ],
[   39.25   ,   0.0000000000    ,   0.0688487849    ,   -0.0529943067   ],
[   39.50   ,   0.0000000000    ,   0.0672858085    ,   -0.0520534633   ],
[   39.75   ,   0.0000000000    ,   0.0657127844    ,   -0.0510956054   ],
[   40.00   ,   0.0000000000    ,   0.0641290743    ,   -0.0501207407   ],
[   40.25   ,   0.0000000000    ,   0.0625341335    ,   -0.0491289081   ],
[   40.50   ,   0.0000000000    ,   0.0609273307    ,   -0.0481201719   ],
[   40.75   ,   0.0000000000    ,   0.0593085074    ,   -0.0470945213   ],
[   41.00   ,   0.0000000000    ,   0.0576779429    ,   -0.0460518829   ],
[   41.25   ,   0.0000000000    ,   0.0560359800    ,   -0.0449921684   ],
[   41.50   ,   0.0000000000    ,   0.0543830303    ,   -0.0439152556   ],
[   41.75   ,   0.0000000000    ,   0.0527196167    ,   -0.0428209829   ],
[   42.00   ,   0.0000000000    ,   0.0510465680    ,   -0.0417091893   ],
[   42.25   ,   0.0000000000    ,   0.0493647402    ,   -0.0405797735   ],
[   42.50   ,   0.0000000000    ,   0.0476747797    ,   -0.0394327036   ],
[   42.75   ,   0.0000000000    ,   0.0459772121    ,   -0.0382679424   ],
[   43.00   ,   0.0000000000    ,   0.0442725501    ,   -0.0370854422   ],
[   43.25   ,   0.0000000000    ,   0.0425612055    ,   -0.0358852082   ],
[   43.50   ,   0.0000000000    ,   0.0408431904    ,   -0.0346674822   ],
[   43.75   ,   0.0000000000    ,   0.0391185377    ,   -0.0334325504   ],
[   44.00   ,   0.0000000000    ,   0.0373873867    ,   -0.0321806879   ],
[   44.25   ,   0.0000000000    ,   0.0356498166    ,   -0.0309121249   ],
[   44.50   ,   0.0000000000    ,   0.0339054806    ,   -0.0296269813   ],
[   44.75   ,   0.0000000000    ,   0.0321539737    ,   -0.0283253614   ],
[   45.00   ,   0.0000000000    ,   0.0303949930    ,   -0.0270073827   ],
[   45.25   ,   0.0000000000    ,   0.0286282605    ,   -0.0256731708   ],
[   45.50   ,   0.0000000000    ,   0.0268534040    ,   -0.0243228587   ],
[   45.75   ,   0.0000000000    ,   0.0250700171    ,   -0.0229565901   ],
[   46.00   ,   0.0000000000    ,   0.0232777832    ,   -0.0215744985   ],
[   46.25   ,   0.0000000000    ,   0.0214767063    ,   -0.0201767250   ],
[   46.50   ,   0.0000000000    ,   0.0196667694    ,   -0.0187635777   ],
[   46.75   ,   0.0000000000    ,   0.0178480682    ,   -0.0173353858   ],
[   47.00   ,   0.0000000000    ,   0.0160207696    ,   -0.0158924625   ],
[   47.25   ,   0.0000000000    ,   0.0141849146    ,   -0.0144351131   ],
[   47.50   ,   0.0000000000    ,   0.0123402270    ,   -0.0129637019   ],
[   47.75   ,   0.0000000000    ,   0.0104864408    ,   -0.0114785974   ],
[   48.00   ,   0.0000000000    ,   0.0086233609    ,   -0.0099801567   ],
[   48.25   ,   0.0000000000    ,   0.0067507810    ,   -0.0084686839   ],
[   48.50   ,   0.0000000000    ,   0.0048683434    ,   -0.0069444026   ],
[   48.75   ,   0.0000000000    ,   0.0029757589    ,   -0.0054075129   ],
[   49.00   ,   0.0000000000    ,   0.0010727750    ,   -0.0038582098   ],
[   49.25   ,   0.0000000000    ,   -0.0008408638   ,   -0.0022967035   ],
[   49.50   ,   0.0000000000    ,   -0.0027653849   ,   -0.0007232734   ],
[   49.75   ,   0.0000000000    ,   -0.0047009700   ,   0.0008617958    ],
[   50.00   ,   0.0000000000    ,   -0.0066477057   ,   0.0024582307    ],
[   50.25   ,   0.0000000000    ,   -0.0086054279   ,   0.0040657524    ],
[   50.50   ,   0.0000000000    ,   -0.0105739278   ,   0.0056840143    ],
[   50.75   ,   0.0000000000    ,   -0.0125529846   ,   0.0073126775    ],
[   51.00   ,   0.0000000000    ,   -0.0145424062   ,   0.0089514283    ],
[   51.25   ,   0.0000000000    ,   -0.0165420611   ,   0.0105999539    ],
[   51.50   ,   0.0000000000    ,   -0.0185521953   ,   0.0122579274    ],
[   51.75   ,   0.0000000000    ,   -0.0205730857   ,   0.0139250178    ],
[   52.00   ,   0.0000000000    ,   -0.0226049080   ,   0.0156008924    ],
[   52.25   ,   0.0000000000    ,   -0.0246478117   ,   0.0172852190    ],
[   52.50   ,   0.0000000000    ,   -0.0267020654   ,   0.0189776681    ],
[   52.75   ,   0.0000000000    ,   -0.0287678188   ,   0.0206779212    ],
[   53.00   ,   0.0000000000    ,   -0.0308452436   ,   0.0223856676    ],
[   53.25   ,   0.0000000000    ,   -0.0329345588   ,   0.0241005886    ],
[   53.50   ,   0.0000000000    ,   -0.0350359152   ,   0.0258223543    ],
[   53.75   ,   0.0000000000    ,   -0.0371493687   ,   0.0275506419    ],
[   54.00   ,   0.0000000000    ,   -0.0392749247   ,   0.0292851429    ],
[   54.25   ,   0.0000000000    ,   -0.0414126610   ,   0.0310255611    ],
[   54.50   ,   0.0000000000    ,   -0.0435628449   ,   0.0327716082    ],
[   54.75   ,   0.0000000000    ,   -0.0457257627   ,   0.0345230112    ],
[   55.00   ,   0.0000000000    ,   -0.0479015797   ,   0.0362795209    ],
[   55.25   ,   0.0000000000    ,   -0.0500903549   ,   0.0380409115    ],
[   55.50   ,   0.0000000000    ,   -0.0522922229   ,   0.0398069715    ],
[   55.75   ,   0.0000000000    ,   -0.0545072813   ,   0.0415775071    ],
[   56.00   ,   0.0000000000    ,   -0.0567355573   ,   0.0433523421    ],
[   56.25   ,   0.0000000000    ,   -0.0589770902   ,   0.0451313472    ],
[   56.50   ,   0.0000000000    ,   -0.0612320528   ,   0.0469144082    ],
[   56.75   ,   0.0000000000    ,   -0.0635006073   ,   0.0487014384    ],
[   57.00   ,   0.0000000000    ,   -0.0657828259   ,   0.0504923731    ],
[   57.25   ,   0.0000000000    ,   -0.0680786969   ,   0.0522871446    ],
[   57.50   ,   0.0000000000    ,   -0.0703882511   ,   0.0540856400    ],
[   57.75   ,   0.0000000000    ,   -0.0727115357   ,   0.0558877374    ],
[   58.00   ,   0.0000000000    ,   -0.0750485904   ,   0.0576933086    ],
[   58.25   ,   0.0000000000    ,   -0.0773993610   ,   0.0595022119    ],
[   58.50   ,   0.0000000000    ,   -0.0797635913   ,   0.0613142675    ],
[   58.75   ,   0.0000000000    ,   -0.0821409873   ,   0.0631292956    ],
[   59.00   ,   0.0000000000    ,   -0.0845312155   ,   0.0649471295    ],
[   59.25   ,   0.0000000000    ,   -0.0869339241   ,   0.0667676047    ],
[   59.50   ,   0.0000000000    ,   -0.0893488711   ,   0.0685905520    ],
[   59.75   ,   0.0000000000    ,   -0.0917758237   ,   0.0704158207    ],
[   60.00   ,   0.0000000000    ,   -0.0942145269   ,   0.0722433013    ],
[   60.25   ,   0.0000000000    ,   -0.0966646465   ,   0.0740729195    ],
[   60.50   ,   0.0000000000    ,   -0.0991257375   ,   0.0759045771    ],
[   60.75   ,   0.0000000000    ,   -0.1015973137   ,   0.0777381712    ],
[   61.00   ,   0.0000000000    ,   -0.1040788765   ,   0.0795735987    ],
[   61.25   ,   0.0000000000    ,   -0.1065699043   ,   0.0814107487    ],
[   61.50   ,   0.0000000000    ,   -0.1090697976   ,   0.0832494768    ],
[   61.75   ,   0.0000000000    ,   -0.1115779297   ,   0.0850896462    ],
[   62.00   ,   0.0000000000    ,   -0.1140936660   ,   0.0869311336    ],
[   62.25   ,   0.0000000000    ,   -0.1166163507   ,   0.0887738168    ],
[   62.50   ,   0.0000000000    ,   -0.1191452628   ,   0.0906175597    ],
[   62.75   ,   0.0000000000    ,   -0.1216796922   ,   0.0924622181    ],
[   63.00   ,   0.0000000000    ,   -0.1242189519   ,   0.0943076479    ],
[   63.25   ,   0.0000000000    ,   -0.1267624104   ,   0.0961536975    ],
[   63.50   ,   0.0000000000    ,   -0.1293096157   ,   0.0980001846    ],
[   63.75   ,   0.0000000000    ,   -0.1318601779   ,   0.0998469212    ],
[   64.00   ,   0.0000000000    ,   -0.1344137192   ,   0.1016937187    ],
[   64.25   ,   0.0000000000    ,   -0.1369698933   ,   0.1035403888    ],
[   64.50   ,   0.0000000000    ,   -0.1395284207   ,   0.1053867225    ],
[   64.75   ,   0.0000000000    ,   -0.1420890538   ,   0.1072325062    ],
[   65.00   ,   0.0000000000    ,   -0.1446515497   ,   0.1090775315    ],
[   65.25   ,   0.0000000000    ,   -0.1472156536   ,   0.1109215993    ],
[   65.50   ,   0.0000000000    ,   -0.1497810288   ,   0.1127645380    ],
[   65.75   ,   0.0000000000    ,   -0.1523473165   ,   0.1146061870    ],
[   66.00   ,   0.0000000000    ,   -0.1549141710   ,   0.1164463890    ],
[   66.25   ,   0.0000000000    ,   -0.1574812670   ,   0.1182849899    ],
[   66.50   ,   0.0000000000    ,   -0.1600483356   ,   0.1201218475    ],
[   66.75   ,   0.0000000000    ,   -0.1626151474   ,   0.1219568213    ],
[   67.00   ,   0.0000000000    ,   -0.1651814951   ,   0.1237897734    ],
[   67.25   ,   0.0000000000    ,   -0.1677472225   ,   0.1256205689    ],
[   67.50   ,   0.0000000000    ,   -0.1703122443   ,   0.1274490721    ],
[   67.75   ,   0.0000000000    ,   -0.1728764569   ,   0.1292751495    ],
[   68.00   ,   0.0000000000    ,   -0.1754397631   ,   0.1310986680    ],
[   68.25   ,   0.0000000000    ,   -0.1780020681   ,   0.1329194996    ],
[   68.50   ,   0.0000000000    ,   -0.1805632526   ,   0.1347375211    ],
[   68.75   ,   0.0000000000    ,   -0.1831232259   ,   0.1365526121    ],
[   69.00   ,   0.0000000000    ,   -0.1856819020   ,   0.1383646555    ],
[   69.25   ,   0.0000000000    ,   -0.1882391910   ,   0.1401735431    ],
[   69.50   ,   0.0000000000    ,   -0.1907950143   ,   0.1419791741    ],
[   69.75   ,   0.0000000000    ,   -0.1933493031   ,   0.1437814510    ],
[   70.00   ,   0.0000000000    ,   -0.1959019783   ,   0.1455802811    ],
[   70.25   ,   0.0000000000    ,   -0.1984529577   ,   0.1473755760    ],
[   70.50   ,   0.0000000000    ,   -0.2010022010   ,   0.1491672431    ],
[   70.75   ,   0.0000000000    ,   -0.2035496902   ,   0.1509551900    ],
[   71.00   ,   0.0000000000    ,   -0.2060954200   ,   0.1527393258    ],
[   71.25   ,   0.0000000000    ,   -0.2086393917   ,   0.1545195672    ],
[   71.50   ,   0.0000000000    ,   -0.2111815915   ,   0.1562958448    ],
[   71.75   ,   0.0000000000    ,   -0.2137219916   ,   0.1580680926    ],
[   72.00   ,   0.0000000000    ,   -0.2162605423   ,   0.1598362459    ],
[   72.25   ,   0.0000000000    ,   -0.2187971442   ,   0.1616002463    ],
[   72.50   ,   0.0000000000    ,   -0.2213316440   ,   0.1633600541    ],
[   72.75   ,   0.0000000000    ,   -0.2238638815   ,   0.1651156424    ],
[   73.00   ,   0.0000000000    ,   -0.2263936989   ,   0.1668669922    ],
[   73.25   ,   0.0000000000    ,   -0.2289209402   ,   0.1686140861    ],
[   73.50   ,   0.0000000000    ,   -0.2314454297   ,   0.1703569037    ],
[   73.75   ,   0.0000000000    ,   -0.2339669629   ,   0.1720954281    ],
[   74.00   ,   0.0000000000    ,   -0.2364853327   ,   0.1738296443    ],
[   74.25   ,   0.0000000000    ,   -0.2390003402   ,   0.1755595345    ],
[   74.50   ,   0.0000000000    ,   -0.2415117954   ,   0.1772850687    ],
[   74.75   ,   0.0000000000    ,   -0.2440195160   ,   0.1790062149    ],
[   75.00   ,   0.0000000000    ,   -0.2465233248   ,   0.1807229408    ],
[   75.25   ,   0.0000000000    ,   -0.2490230403   ,   0.1824352147    ],
[   75.50   ,   0.0000000000    ,   -0.2515185232   ,   0.1841429975    ],
[   75.75   ,   0.0000000000    ,   -0.2540096543   ,   0.1858462487    ],
[   76.00   ,   0.0000000000    ,   -0.2564963164   ,   0.1875449294    ],
[   76.25   ,   0.0000000000    ,   -0.2589783841   ,   0.1892390029    ],
[   76.50   ,   0.0000000000    ,   -0.2614557199   ,   0.1909284358    ],
[   76.75   ,   0.0000000000    ,   -0.2639281731   ,   0.1926132011    ],
[   77.00   ,   0.0000000000    ,   -0.2663955830   ,   0.1942932756    ],
[   77.25   ,   0.0000000000    ,   -0.2688577969   ,   0.1959686373    ],
[   77.50   ,   0.0000000000    ,   -0.2713146958   ,   0.1976392630    ],
[   77.75   ,   0.0000000000    ,   -0.2737661753   ,   0.1993051304    ],
[   78.00   ,   0.0000000000    ,   -0.2762121339   ,   0.2009662179    ],
[   78.25   ,   0.0000000000    ,   -0.2786524829   ,   0.2026225076    ],
[   78.50   ,   0.0000000000    ,   -0.2810871613   ,   0.2042739946    ],
[   78.75   ,   0.0000000000    ,   -0.2835161095   ,   0.2059206799    ],
[   79.00   ,   0.0000000000    ,   -0.2859392769   ,   0.2075625665    ],
[   79.25   ,   0.0000000000    ,   -0.2883566167   ,   0.2091996564    ],
[   79.50   ,   0.0000000000    ,   -0.2907680868   ,   0.2108319475    ],
[   79.75   ,   0.0000000000    ,   -0.2931736475   ,   0.2124594371    ],
[   80.00   ,   0.0000000000    ,   -0.2955732573   ,   0.2140821221    ],
[   80.25   ,   0.0000000000    ,   -0.2979668691   ,   0.2157000001    ],
[   80.50   ,   0.0000000000    ,   -0.3003544362   ,   0.2173130712    ],
[   80.75   ,   0.0000000000    ,   -0.3027359173   ,   0.2189213382    ],
[   81.00   ,   0.0000000000    ,   -0.3051112750   ,   0.2205248030    ],
[   81.25   ,   0.0000000000    ,   -0.3074804752   ,   0.2221234695    ],
[   81.50   ,   0.0000000000    ,   -0.3098434837   ,   0.2237173516    ],
[   81.75   ,   0.0000000000    ,   -0.3122002673   ,   0.2253064656    ],
[   82.00   ,   0.0000000000    ,   -0.3145507959   ,   0.2268908285    ],
[   82.25   ,   0.0000000000    ,   -0.3168950401   ,   0.2284704609    ],
[   82.50   ,   0.0000000000    ,   -0.3192329773   ,   0.2300453866    ],
[   82.75   ,   0.0000000000    ,   -0.3215645891   ,   0.2316156295    ],
[   83.00   ,   0.0000000000    ,   -0.3238898578   ,   0.2331812139    ],
[   83.25   ,   0.0000000000    ,   -0.3262087711   ,   0.2347421636    ],
[   83.50   ,   0.0000000000    ,   -0.3285213371   ,   0.2362985027    ],
[   83.75   ,   0.0000000000    ,   -0.3308275716   ,   0.2378502550    ],
[   84.00   ,   0.0000000000    ,   -0.3331274889   ,   0.2393974442    ],
[   84.25   ,   0.0000000000    ,   -0.3354210990   ,   0.2409400942    ],
[   84.50   ,   0.0000000000    ,   -0.3377084057   ,   0.2424782274    ],
[   84.75   ,   0.0000000000    ,   -0.3399894099   ,   0.2440118665    ],
[   85.00   ,   0.0000000000    ,   -0.3422641106   ,   0.2455410342    ],
[   85.25   ,   0.0000000000    ,   -0.3445325059   ,   0.2470657531    ],
[   85.50   ,   0.0000000000    ,   -0.3467945955   ,   0.2485860446    ],
[   85.75   ,   0.0000000000    ,   -0.3490503798   ,   0.2501019302    ],
[   86.00   ,   0.0000000000    ,   -0.3512998586   ,   0.2516134306    ],
[   86.25   ,   0.0000000000    ,   -0.3535430343   ,   0.2531205664    ],
[   86.50   ,   0.0000000000    ,   -0.3557799191   ,   0.2546233574    ],
[   86.75   ,   0.0000000000    ,   -0.3580105304   ,   0.2561218244    ],
[   87.00   ,   0.0000000000    ,   -0.3602348863   ,   0.2576159887    ],
[   87.25   ,   0.0000000000    ,   -0.3624530072   ,   0.2591058723    ],
[   87.50   ,   0.0000000000    ,   -0.3646649196   ,   0.2605914994    ],
[   87.75   ,   0.0000000000    ,   -0.3668706521   ,   0.2620728948    ],
[   88.00   ,   0.0000000000    ,   -0.3690702328   ,   0.2635500834    ],
[   88.25   ,   0.0000000000    ,   -0.3712636892   ,   0.2650230889    ],
[   88.50   ,   0.0000000000    ,   -0.3734510476   ,   0.2664919332    ],
[   88.75   ,   0.0000000000    ,   -0.3756323335   ,   0.2679566378    ],
[   89.00   ,   0.0000000000    ,   -0.3778075723   ,   0.2694172237    ],
[   89.25   ,   0.0000000000    ,   -0.3799767904   ,   0.2708737120    ],
[   89.50   ,   0.0000000000    ,   -0.3821400175   ,   0.2723261239    ],
[   89.75   ,   0.0000000000    ,   -0.3842972823   ,   0.2737744806    ],
[   90.00   ,   0.0000000000    ,   -0.3864486123   ,   0.2752188031    ],
[   90.25   ,   0.0000000000    ,   -0.3885940341   ,   0.2766591126    ],
[   90.50   ,   0.0000000000    ,   -0.3907335717   ,   0.2780954306    ],
[   90.75   ,   0.0000000000    ,   -0.3928672483   ,   0.2795277785    ],
[   91.00   ,   0.0000000000    ,   -0.3949950871   ,   0.2809561777    ],
[   91.25   ,   0.0000000000    ,   -0.3971171121   ,   0.2823806495    ],
[   91.50   ,   0.0000000000    ,   -0.3992333511   ,   0.2838012162    ],
[   91.75   ,   0.0000000000    ,   -0.4013438326   ,   0.2852178999    ],
[   92.00   ,   0.0000000000    ,   -0.4034485849   ,   0.2866307227    ],
[   92.25   ,   0.0000000000    ,   -0.4055476365   ,   0.2880397064    ],
[   92.50   ,   0.0000000000    ,   -0.4076410169   ,   0.2894448722    ],
[   92.75   ,   0.0000000000    ,   -0.4097287559   ,   0.2908462412    ],
[   93.00   ,   0.0000000000    ,   -0.4118108830   ,   0.2922438343    ],
[   93.25   ,   0.0000000000    ,   -0.4138874273   ,   0.2936376722    ],
[   93.50   ,   0.0000000000    ,   -0.4159584178   ,   0.2950277753    ],
[   93.75   ,   0.0000000000    ,   -0.4180238835   ,   0.2964141642    ],
[   94.00   ,   0.0000000000    ,   -0.4200838527   ,   0.2977968589    ],
[   94.25   ,   0.0000000000    ,   -0.4221383540   ,   0.2991758795    ],
[   94.50   ,   0.0000000000    ,   -0.4241874153   ,   0.3005512458    ],
[   94.75   ,   0.0000000000    ,   -0.4262310646   ,   0.3019229777    ],
[   95.00   ,   0.0000000000    ,   -0.4282693296   ,   0.3032910945    ]];

GLIPredData.FEV1.male = {};
GLIPredData.FEV1.male.a = [-10.3420, 2.2196, 0.0574, -0.1589, -0.0351, -0.0881, -0.0708];
GLIPredData.FEV1.male.p = [-2.3268,  0.0798, 0.1096, -0.3973, 0.0327, 0.0114];
GLIPredData.FEV1.male.q = [0.886600, 0.085000];
GLIPredData.FEV1.male.maxAge = 95;
GLIPredData.FEV1.male.spline = 
[[  3.00    ,   0.0000000000    ,   -0.11332306220  ,   0.21433723090   ],
[   3.25    ,   0.0000000000    ,   -0.10726352410  ,   0.20433724930   ],
[   3.50    ,   0.0000000000    ,   -0.10112519090  ,   0.19544711150   ],
[   3.75    ,   0.0000000000    ,   -0.09507569730  ,   0.18721880130   ],
[   4.00    ,   0.0000000000    ,   -0.08926011860  ,   0.17939119040   ],
[   4.25    ,   0.0000000000    ,   -0.08406819590  ,   0.17208717340   ],
[   4.50    ,   0.0000000000    ,   -0.07987684910  ,   0.16534959200   ],
[   4.75    ,   0.0000000000    ,   -0.07688229570  ,   0.15920915440   ],
[   5.00    ,   0.0000000000    ,   -0.07524200610  ,   0.15349657240   ],
[   5.25    ,   0.0000000000    ,   -0.07500351380  ,   0.14792864830   ],
[   5.50    ,   0.0000000000    ,   -0.07578456420  ,   0.14208458060   ],
[   5.75    ,   0.0000000000    ,   -0.07713567840  ,   0.13560118540   ],
[   6.00    ,   0.0000000000    ,   -0.07874197750  ,   0.12845326090   ],
[   6.25    ,   0.0000000000    ,   -0.08032673960  ,   0.12046651700   ],
[   6.50    ,   0.0000000000    ,   -0.08163632070  ,   0.11146059940   ],
[   6.75    ,   0.0000000000    ,   -0.08230849730  ,   0.10160256650   ],
[   7.00    ,   0.0000000000    ,   -0.08220264830  ,   0.09115868520   ],
[   7.25    ,   0.0000000000    ,   -0.08148442810  ,   0.08007960760   ],
[   7.50    ,   0.0000000000    ,   -0.08042493650  ,   0.06850281450   ],
[   7.75    ,   0.0000000000    ,   -0.07917659810  ,   0.05648077280   ],
[   8.00    ,   0.0000000000    ,   -0.07783016220  ,   0.04393947950   ],
[   8.25    ,   0.0000000000    ,   -0.07632926860  ,   0.03082847720   ],
[   8.50    ,   0.0000000000    ,   -0.07448614300  ,   0.01736767720   ],
[   8.75    ,   0.0000000000    ,   -0.07207782440  ,   0.00386691850   ],
[   9.00    ,   0.0000000000    ,   -0.06912403090  ,   -0.00915072790  ],
[   9.25    ,   0.0000000000    ,   -0.06576956780  ,   -0.02104548090  ],
[   9.50    ,   0.0000000000    ,   -0.06221025140  ,   -0.03134061270  ],
[   9.75    ,   0.0000000000    ,   -0.05857249390  ,   -0.03972017930  ],
[   10.00   ,   0.0000000000    ,   -0.05491913730  ,   -0.04607554280  ],
[   10.25   ,   0.0000000000    ,   -0.05128244020  ,   -0.05040667830  ],
[   10.50   ,   0.0000000000    ,   -0.04758008310  ,   -0.05281247300  ],
[   10.75   ,   0.0000000000    ,   -0.04370138270  ,   -0.05345299780  ],
[   11.00   ,   0.0000000000    ,   -0.03954871940  ,   -0.05253383780  ],
[   11.25   ,   0.0000000000    ,   -0.03500006850  ,   -0.05033995690  ],
[   11.50   ,   0.0000000000    ,   -0.02990956560  ,   -0.04715958950  ],
[   11.75   ,   0.0000000000    ,   -0.02413280870  ,   -0.04320067910  ],
[   12.00   ,   0.0000000000    ,   -0.01756059020  ,   -0.03868399240  ],
[   12.25   ,   0.0000000000    ,   -0.01014532000  ,   -0.03385141250  ],
[   12.50   ,   0.0000000000    ,   -0.00190268960  ,   -0.02897016710  ],
[   12.75   ,   0.0000000000    ,   0.00714576190   ,   -0.02427038440  ],
[   13.00   ,   0.0000000000    ,   0.01694292400   ,   -0.01994926820  ],
[   13.25   ,   0.0000000000    ,   0.02738955170   ,   -0.01616977960  ],
[   13.50   ,   0.0000000000    ,   0.03835233810   ,   -0.01304909710  ],
[   13.75   ,   0.0000000000    ,   0.04967853240   ,   -0.01067787780  ],
[   14.00   ,   0.0000000000    ,   0.06122035780   ,   -0.00909937240  ],
[   14.25   ,   0.0000000000    ,   0.07283232200   ,   -0.00833851750  ],
[   14.50   ,   0.0000000000    ,   0.08438440390   ,   -0.00839409090  ],
[   14.75   ,   0.0000000000    ,   0.09575727710   ,   -0.00922516310  ],
[   15.00   ,   0.0000000000    ,   0.10682623500   ,   -0.01079265540  ],
[   15.25   ,   0.0000000000    ,   0.11747423980   ,   -0.01306344310  ],
[   15.50   ,   0.0000000000    ,   0.12760089740   ,   -0.01598469930  ],
[   15.75   ,   0.0000000000    ,   0.13713097740   ,   -0.01947049390  ],
[   16.00   ,   0.0000000000    ,   0.14600494300   ,   -0.02343281330  ],
[   16.25   ,   0.0000000000    ,   0.15417541520   ,   -0.02777700360  ],
[   16.50   ,   0.0000000000    ,   0.16162323830   ,   -0.03240559530  ],
[   16.75   ,   0.0000000000    ,   0.16835482260   ,   -0.03722723870  ],
[   17.00   ,   0.0000000000    ,   0.17439113890   ,   -0.04214909810  ],
[   17.25   ,   0.0000000000    ,   0.17975965250   ,   -0.04708934890  ],
[   17.50   ,   0.0000000000    ,   0.18450823410   ,   -0.05198273600  ],
[   17.75   ,   0.0000000000    ,   0.18869475960   ,   -0.05677872040  ],
[   18.00   ,   0.0000000000    ,   0.19237454470   ,   -0.06143849880  ],
[   18.25   ,   0.0000000000    ,   0.19559579360   ,   -0.06592953170  ],
[   18.50   ,   0.0000000000    ,   0.19839869070   ,   -0.07022199870  ],
[   18.75   ,   0.0000000000    ,   0.20081731730   ,   -0.07428185000  ],
[   19.00   ,   0.0000000000    ,   0.20288104210   ,   -0.07807820970  ],
[   19.25   ,   0.0000000000    ,   0.20461620390   ,   -0.08158180800  ],
[   19.50   ,   0.0000000000    ,   0.20604708760   ,   -0.08476694320  ],
[   19.75   ,   0.0000000000    ,   0.20719267150   ,   -0.08761210070  ],
[   20.00   ,   0.0000000000    ,   0.20806507260   ,   -0.09009962300  ],
[   20.25   ,   0.0000000000    ,   0.20867619810   ,   -0.09222217160  ],
[   20.50   ,   0.0000000000    ,   0.20904090410   ,   -0.09399263280  ],
[   20.75   ,   0.0000000000    ,   0.20917861790   ,   -0.09542602350  ],
[   21.00   ,   0.0000000000    ,   0.20911231640   ,   -0.09653587480  ],
[   21.25   ,   0.0000000000    ,   0.20886222430   ,   -0.09733414650  ],
[   21.50   ,   0.0000000000    ,   0.20844227960   ,   -0.09783653200  ],
[   21.75   ,   0.0000000000    ,   0.20786581710   ,   -0.09805856050  ],
[   22.00   ,   0.0000000000    ,   0.20714563020   ,   -0.09801405460  ],
[   22.25   ,   0.0000000000    ,   0.20629090190   ,   -0.09771731250  ],
[   22.50   ,   0.0000000000    ,   0.20530372600   ,   -0.09719274310  ],
[   22.75   ,   0.0000000000    ,   0.20418993360   ,   -0.09646356610  ],
[   23.00   ,   0.0000000000    ,   0.20295909250   ,   -0.09555045680  ],
[   23.25   ,   0.0000000000    ,   0.20162158810   ,   -0.09447442960  ],
[   23.50   ,   0.0000000000    ,   0.20018424970   ,   -0.09326232360  ],
[   23.75   ,   0.0000000000    ,   0.19865438620   ,   -0.09193955340  ],
[   24.00   ,   0.0000000000    ,   0.19704192300   ,   -0.09052876430  ],
[   24.25   ,   0.0000000000    ,   0.19535728430   ,   -0.08905159890  ],
[   24.50   ,   0.0000000000    ,   0.19360712830   ,   -0.08752729790  ],
[   24.75   ,   0.0000000000    ,   0.19179825150   ,   -0.08597304480  ],
[   25.00   ,   0.0000000000    ,   0.18993868400   ,   -0.08440559470  ],
[   25.25   ,   0.0000000000    ,   0.18803641170   ,   -0.08284062310  ],
[   25.50   ,   0.0000000000    ,   0.18609811690   ,   -0.08129227520  ],
[   25.75   ,   0.0000000000    ,   0.18412904530   ,   -0.07977339590  ],
[   26.00   ,   0.0000000000    ,   0.18213248050   ,   -0.07829417380  ],
[   26.25   ,   0.0000000000    ,   0.18011154270   ,   -0.07686249220  ],
[   26.50   ,   0.0000000000    ,   0.17806832040   ,   -0.07548716190  ],
[   26.75   ,   0.0000000000    ,   0.17600615240   ,   -0.07417541850  ],
[   27.00   ,   0.0000000000    ,   0.17392852260   ,   -0.07292948920  ],
[   27.25   ,   0.0000000000    ,   0.17184046630   ,   -0.07174942690  ],
[   27.50   ,   0.0000000000    ,   0.16974891480   ,   -0.07063488010  ],
[   27.75   ,   0.0000000000    ,   0.16766029610   ,   -0.06958447090  ],
[   28.00   ,   0.0000000000    ,   0.16558096170   ,   -0.06859575090  ],
[   28.25   ,   0.0000000000    ,   0.16351625860   ,   -0.06766605110  ],
[   28.50   ,   0.0000000000    ,   0.16146703010   ,   -0.06679222410  ],
[   28.75   ,   0.0000000000    ,   0.15943321490   ,   -0.06597132850  ],
[   29.00   ,   0.0000000000    ,   0.15741469020   ,   -0.06519940390  ],
[   29.25   ,   0.0000000000    ,   0.15541104350   ,   -0.06447136980  ],
[   29.50   ,   0.0000000000    ,   0.15341945270   ,   -0.06378284700  ],
[   29.75   ,   0.0000000000    ,   0.15143778810   ,   -0.06312967380  ],
[   30.00   ,   0.0000000000    ,   0.14946487280   ,   -0.06250794530  ],
[   30.25   ,   0.0000000000    ,   0.14750029350   ,   -0.06191394050  ],
[   30.50   ,   0.0000000000    ,   0.14554402900   ,   -0.06134529660  ],
[   30.75   ,   0.0000000000    ,   0.14359623520   ,   -0.06080102810  ],
[   31.00   ,   0.0000000000    ,   0.14165725010   ,   -0.06028096300  ],
[   31.25   ,   0.0000000000    ,   0.13972744520   ,   -0.05978595430  ],
[   31.50   ,   0.0000000000    ,   0.13780663450   ,   -0.05931791700  ],
[   31.75   ,   0.0000000000    ,   0.13589427990   ,   -0.05887918640  ],
[   32.00   ,   0.0000000000    ,   0.13399033490   ,   -0.05847201260  ],
[   32.25   ,   0.0000000000    ,   0.13209534890   ,   -0.05809814830  ],
[   32.50   ,   0.0000000000    ,   0.13020933690   ,   -0.05775967190  ],
[   32.75   ,   0.0000000000    ,   0.12833193770   ,   -0.05745930320  ],
[   33.00   ,   0.0000000000    ,   0.12646264570   ,   -0.05720002610  ],
[   33.25   ,   0.0000000000    ,   0.12460039970   ,   -0.05698483750  ],
[   33.50   ,   0.0000000000    ,   0.12274316510   ,   -0.05681652890  ],
[   33.75   ,   0.0000000000    ,   0.12088890110   ,   -0.05669697530  ],
[   34.00   ,   0.0000000000    ,   0.11903553930   ,   -0.05662709010  ],
[   34.25   ,   0.0000000000    ,   0.11718105860   ,   -0.05660588930  ],
[   34.50   ,   0.0000000000    ,   0.11532325610   ,   -0.05663058640  ],
[   34.75   ,   0.0000000000    ,   0.11346014260   ,   -0.05669729500  ],
[   35.00   ,   0.0000000000    ,   0.11158986810   ,   -0.05680209070  ],
[   35.25   ,   0.0000000000    ,   0.10971073010   ,   -0.05694106470  ],
[   35.50   ,   0.0000000000    ,   0.10782101290   ,   -0.05710879890  ],
[   35.75   ,   0.0000000000    ,   0.10591956210   ,   -0.05729948430  ],
[   36.00   ,   0.0000000000    ,   0.10400630420   ,   -0.05750686100  ],
[   36.25   ,   0.0000000000    ,   0.10208143240   ,   -0.05772474860  ],
[   36.50   ,   0.0000000000    ,   0.10014523840   ,   -0.05794759310  ],
[   36.75   ,   0.0000000000    ,   0.09819822190   ,   -0.05817041680  ],
[   37.00   ,   0.0000000000    ,   0.09624133520   ,   -0.05838792370  ],
[   37.25   ,   0.0000000000    ,   0.09427546800   ,   -0.05859530270  ],
[   37.50   ,   0.0000000000    ,   0.09230079460   ,   -0.05878969000  ],
[   37.75   ,   0.0000000000    ,   0.09031749390   ,   -0.05896873490  ],
[   38.00   ,   0.0000000000    ,   0.08832611820   ,   -0.05913022350  ],
[   38.25   ,   0.0000000000    ,   0.08632686740   ,   -0.05927233250  ],
[   38.50   ,   0.0000000000    ,   0.08431948710   ,   -0.05939393470  ],
[   38.75   ,   0.0000000000    ,   0.08230393010   ,   -0.05949395990  ],
[   39.00   ,   0.0000000000    ,   0.08028015470   ,   -0.05957160920  ],
[   39.25   ,   0.0000000000    ,   0.07824800140   ,   -0.05962628850  ],
[   39.50   ,   0.0000000000    ,   0.07620717700   ,   -0.05965765470  ],
[   39.75   ,   0.0000000000    ,   0.07415734020   ,   -0.05966559580  ],
[   40.00   ,   0.0000000000    ,   0.07209832600   ,   -0.05964999620  ],
[   40.25   ,   0.0000000000    ,   0.07003016160   ,   -0.05961073890  ],
[   40.50   ,   0.0000000000    ,   0.06795296710   ,   -0.05954725940  ],
[   40.75   ,   0.0000000000    ,   0.06586758040   ,   -0.05945782780  ],
[   41.00   ,   0.0000000000    ,   0.06377531750   ,   -0.05934017650  ],
[   41.25   ,   0.0000000000    ,   0.06167751330   ,   -0.05919164070  ],
[   41.50   ,   0.0000000000    ,   0.05957555980   ,   -0.05900906760  ],
[   41.75   ,   0.0000000000    ,   0.05747061490   ,   -0.05878923170  ],
[   42.00   ,   0.0000000000    ,   0.05536380500   ,   -0.05852904400  ],
[   42.25   ,   0.0000000000    ,   0.05325624340   ,   -0.05822599370  ],
[   42.50   ,   0.0000000000    ,   0.05114902560   ,   -0.05787840590  ],
[   42.75   ,   0.0000000000    ,   0.04904309870   ,   -0.05748460530  ],
[   43.00   ,   0.0000000000    ,   0.04693934660   ,   -0.05704273890  ],
[   43.25   ,   0.0000000000    ,   0.04483844450   ,   -0.05655122880  ],
[   43.50   ,   0.0000000000    ,   0.04274060300   ,   -0.05601017600  ],
[   43.75   ,   0.0000000000    ,   0.04064599680   ,   -0.05541991980  ],
[   44.00   ,   0.0000000000    ,   0.03855483460   ,   -0.05478067110  ],
[   44.25   ,   0.0000000000    ,   0.03646714790   ,   -0.05409240020  ],
[   44.50   ,   0.0000000000    ,   0.03438248640   ,   -0.05335416130  ],
[   44.75   ,   0.0000000000    ,   0.03230030250   ,   -0.05256482470  ],
[   45.00   ,   0.0000000000    ,   0.03021999330   ,   -0.05172333890  ],
[   45.25   ,   0.0000000000    ,   0.02814071630   ,   -0.05082882200  ],
[   45.50   ,   0.0000000000    ,   0.02606124540   ,   -0.04988080800  ],
[   45.75   ,   0.0000000000    ,   0.02398014030   ,   -0.04887896230  ],
[   46.00   ,   0.0000000000    ,   0.02189602340   ,   -0.04782265970  ],
[   46.25   ,   0.0000000000    ,   0.01980789250   ,   -0.04671131980  ],
[   46.50   ,   0.0000000000    ,   0.01771488600   ,   -0.04554556730  ],
[   46.75   ,   0.0000000000    ,   0.01561633780   ,   -0.04432608250  ],
[   47.00   ,   0.0000000000    ,   0.01351168500   ,   -0.04305345860  ],
[   47.25   ,   0.0000000000    ,   0.01140026850   ,   -0.04172828350  ],
[   47.50   ,   0.0000000000    ,   0.00928115190   ,   -0.04035170120  ],
[   47.75   ,   0.0000000000    ,   0.00715349070   ,   -0.03892498040  ],
[   48.00   ,   0.0000000000    ,   0.00501653640   ,   -0.03744931390  ],
[   48.25   ,   0.0000000000    ,   0.00286958720   ,   -0.03592565170  ],
[   48.50   ,   0.0000000000    ,   0.00071174150   ,   -0.03435514220  ],
[   48.75   ,   0.0000000000    ,   -0.00145783400  ,   -0.03273906810  ],
[   49.00   ,   0.0000000000    ,   -0.00363980750  ,   -0.03107869490  ],
[   49.25   ,   0.0000000000    ,   -0.00583474500  ,   -0.02937533210  ],
[   49.50   ,   0.0000000000    ,   -0.00804289230  ,   -0.02763060240  ],
[   49.75   ,   0.0000000000    ,   -0.01026441840  ,   -0.02584610090  ],
[   50.00   ,   0.0000000000    ,   -0.01249947150  ,   -0.02402324530  ],
[   50.25   ,   0.0000000000    ,   -0.01474795980  ,   -0.02216337670  ],
[   50.50   ,   0.0000000000    ,   -0.01700982050  ,   -0.02026797980  ],
[   50.75   ,   0.0000000000    ,   -0.01928505440  ,   -0.01833846240  ],
[   51.00   ,   0.0000000000    ,   -0.02157373850  ,   -0.01637592990  ],
[   51.25   ,   0.0000000000    ,   -0.02387602900  ,   -0.01438153710  ],
[   51.50   ,   0.0000000000    ,   -0.02619250050  ,   -0.01235678860  ],
[   51.75   ,   0.0000000000    ,   -0.02852370740  ,   -0.01030328800  ],
[   52.00   ,   0.0000000000    ,   -0.03087009950  ,   -0.00822274800  ],
[   52.25   ,   0.0000000000    ,   -0.03323209060  ,   -0.00611696720  ],
[   52.50   ,   0.0000000000    ,   -0.03561013710  ,   -0.00398764740  ],
[   52.75   ,   0.0000000000    ,   -0.03800464980  ,   -0.00183647050  ],
[   53.00   ,   0.0000000000    ,   -0.04041605780  ,   0.00033477050   ],
[   53.25   ,   0.0000000000    ,   -0.04284469490  ,   0.00252438610   ],
[   53.50   ,   0.0000000000    ,   -0.04529039910  ,   0.00473152790   ],
[   53.75   ,   0.0000000000    ,   -0.04775280970  ,   0.00695549810   ],
[   54.00   ,   0.0000000000    ,   -0.05023155350  ,   0.00919558030   ],
[   54.25   ,   0.0000000000    ,   -0.05272635500  ,   0.01145108710   ],
[   54.50   ,   0.0000000000    ,   -0.05523705980  ,   0.01372140140   ],
[   54.75   ,   0.0000000000    ,   -0.05776353000  ,   0.01600600380   ],
[   55.00   ,   0.0000000000    ,   -0.06030552860  ,   0.01830450810   ],
[   55.25   ,   0.0000000000    ,   -0.06286282740  ,   0.02061651450   ],
[   55.50   ,   0.0000000000    ,   -0.06543541490  ,   0.02294158390   ],
[   55.75   ,   0.0000000000    ,   -0.06802327430  ,   0.02527934600   ],
[   56.00   ,   0.0000000000    ,   -0.07062639070  ,   0.02762941680   ],
[   56.25   ,   0.0000000000    ,   -0.07324474070  ,   0.02999165250   ],
[   56.50   ,   0.0000000000    ,   -0.07587830700  ,   0.03236621400   ],
[   56.75   ,   0.0000000000    ,   -0.07852706020  ,   0.03475364360   ],
[   57.00   ,   0.0000000000    ,   -0.08119090210  ,   0.03715467640   ],
[   57.25   ,   0.0000000000    ,   -0.08386961450  ,   0.03957005380   ],
[   57.50   ,   0.0000000000    ,   -0.08656294550  ,   0.04200015420   ],
[   57.75   ,   0.0000000000    ,   -0.08927067230  ,   0.04444524100   ],
[   58.00   ,   0.0000000000    ,   -0.09199263140  ,   0.04690549860   ],
[   58.25   ,   0.0000000000    ,   -0.09472862730  ,   0.04938087180   ],
[   58.50   ,   0.0000000000    ,   -0.09747840270  ,   0.05187072910   ],
[   58.75   ,   0.0000000000    ,   -0.10024172250  ,   0.05437438100   ],
[   59.00   ,   0.0000000000    ,   -0.10301832370  ,   0.05689121720   ],
[   59.25   ,   0.0000000000    ,   -0.10580791860  ,   0.05942057280   ],
[   59.50   ,   0.0000000000    ,   -0.10861032120  ,   0.06196159500   ],
[   59.75   ,   0.0000000000    ,   -0.11142534070  ,   0.06451343180   ],
[   60.00   ,   0.0000000000    ,   -0.11425276800  ,   0.06707539830   ],
[   60.25   ,   0.0000000000    ,   -0.11709233070  ,   0.06964707450   ],
[   60.50   ,   0.0000000000    ,   -0.11994366260  ,   0.07222809030   ],
[   60.75   ,   0.0000000000    ,   -0.12280636240  ,   0.07481808580   ],
[   61.00   ,   0.0000000000    ,   -0.12568002860  ,   0.07741669170   ],
[   61.25   ,   0.0000000000    ,   -0.12856422850  ,   0.08002351340   ],
[   61.50   ,   0.0000000000    ,   -0.13145839010  ,   0.08263799720   ],
[   61.75   ,   0.0000000000    ,   -0.13436191670  ,   0.08525957420   ],
[   62.00   ,   0.0000000000    ,   -0.13727417520  ,   0.08788769130   ],
[   62.25   ,   0.0000000000    ,   -0.14019449690  ,   0.09052175940   ],
[   62.50   ,   0.0000000000    ,   -0.14312216050  ,   0.09316101380   ],
[   62.75   ,   0.0000000000    ,   -0.14605647510  ,   0.09580462030   ],
[   63.00   ,   0.0000000000    ,   -0.14899680330  ,   0.09845175680   ],
[   63.25   ,   0.0000000000    ,   -0.15194257630  ,   0.10110153610   ],
[   63.50   ,   0.0000000000    ,   -0.15489339350  ,   0.10375277290   ],
[   63.75   ,   0.0000000000    ,   -0.15784888610  ,   0.10640424660   ],
[   64.00   ,   0.0000000000    ,   -0.16080867780  ,   0.10905476360   ],
[   64.25   ,   0.0000000000    ,   -0.16377245300  ,   0.11170313460   ],
[   64.50   ,   0.0000000000    ,   -0.16674007250  ,   0.11434797960   ],
[   64.75   ,   0.0000000000    ,   -0.16971148110  ,   0.11698793430   ],
[   65.00   ,   0.0000000000    ,   -0.17268663350  ,   0.11962171510   ],
[   65.25   ,   0.0000000000    ,   -0.17566547190  ,   0.12224811800   ],
[   65.50   ,   0.0000000000    ,   -0.17864785110  ,   0.12486617320   ],
[   65.75   ,   0.0000000000    ,   -0.18163360720  ,   0.12747501980   ],
[   66.00   ,   0.0000000000    ,   -0.18462255400  ,   0.13007391100   ],
[   66.25   ,   0.0000000000    ,   -0.18761451980  ,   0.13266213130   ],
[   66.50   ,   0.0000000000    ,   -0.19060939740  ,   0.13523906010   ],
[   66.75   ,   0.0000000000    ,   -0.19360712660  ,   0.13780410800   ],
[   67.00   ,   0.0000000000    ,   -0.19660766810  ,   0.14035669840   ],
[   67.25   ,   0.0000000000    ,   -0.19961100750  ,   0.14289626200   ],
[   67.50   ,   0.0000000000    ,   -0.20261705520  ,   0.14542219710   ],
[   67.75   ,   0.0000000000    ,   -0.20562566240  ,   0.14793393140   ],
[   68.00   ,   0.0000000000    ,   -0.20863668430  ,   0.15043090800   ],
[   68.25   ,   0.0000000000    ,   -0.21164996930  ,   0.15291262790   ],
[   68.50   ,   0.0000000000    ,   -0.21466523250  ,   0.15537882130   ],
[   68.75   ,   0.0000000000    ,   -0.21768219470  ,   0.15782927370   ],
[   69.00   ,   0.0000000000    ,   -0.22070058830  ,   0.16026379850   ],
[   69.25   ,   0.0000000000    ,   -0.22372013940  ,   0.16268232580   ],
[   69.50   ,   0.0000000000    ,   -0.22674054480  ,   0.16508490120   ],
[   69.75   ,   0.0000000000    ,   -0.22976151320  ,   0.16747160100   ],
[   70.00   ,   0.0000000000    ,   -0.23278275160  ,   0.16984252180   ],
[   70.25   ,   0.0000000000    ,   -0.23580396450  ,   0.17219776450   ],
[   70.50   ,   0.0000000000    ,   -0.23882486050  ,   0.17453737410   ],
[   70.75   ,   0.0000000000    ,   -0.24184517900  ,   0.17686136920   ],
[   71.00   ,   0.0000000000    ,   -0.24486469730  ,   0.17916977800   ],
[   71.25   ,   0.0000000000    ,   -0.24788322700  ,   0.18146267750   ],
[   71.50   ,   0.0000000000    ,   -0.25090061970  ,   0.18374008180   ],
[   71.75   ,   0.0000000000    ,   -0.25391673630  ,   0.18600197790   ],
[   72.00   ,   0.0000000000    ,   -0.25693143730  ,   0.18824833770   ],
[   72.25   ,   0.0000000000    ,   -0.25994454680  ,   0.19047916620   ],
[   72.50   ,   0.0000000000    ,   -0.26295582290  ,   0.19269466940   ],
[   72.75   ,   0.0000000000    ,   -0.26596501470  ,   0.19489520430   ],
[   73.00   ,   0.0000000000    ,   -0.26897187130  ,   0.19708125110   ],
[   73.25   ,   0.0000000000    ,   -0.27197614590  ,   0.19925328100   ],
[   73.50   ,   0.0000000000    ,   -0.27497758380  ,   0.20141165230   ],
[   73.75   ,   0.0000000000    ,   -0.27797592640  ,   0.20355668080   ],
[   74.00   ,   0.0000000000    ,   -0.28097093010  ,   0.20568868180   ],
[   74.25   ,   0.0000000000    ,   -0.28396236180  ,   0.20780797490   ],
[   74.50   ,   0.0000000000    ,   -0.28694997760  ,   0.20991485740   ],
[   74.75   ,   0.0000000000    ,   -0.28993352780  ,   0.21200965250   ],
[   75.00   ,   0.0000000000    ,   -0.29291276000  ,   0.21409265890   ],
[   75.25   ,   0.0000000000    ,   -0.29588740510  ,   0.21616419790   ],
[   75.50   ,   0.0000000000    ,   -0.29885720440  ,   0.21822462510   ],
[   75.75   ,   0.0000000000    ,   -0.30182191200  ,   0.22027428540   ],
[   76.00   ,   0.0000000000    ,   -0.30478128150  ,   0.22231353270   ],
[   76.25   ,   0.0000000000    ,   -0.30773506690  ,   0.22434271270   ],
[   76.50   ,   0.0000000000    ,   -0.31068302690  ,   0.22636214680   ],
[   76.75   ,   0.0000000000    ,   -0.31362490850  ,   0.22837217370   ],
[   77.00   ,   0.0000000000    ,   -0.31656045450  ,   0.23037315610   ],
[   77.25   ,   0.0000000000    ,   -0.31948942820  ,   0.23236544310   ],
[   77.50   ,   0.0000000000    ,   -0.32241164400  ,   0.23434931430   ],
[   77.75   ,   0.0000000000    ,   -0.32532693610  ,   0.23632505100   ],
[   78.00   ,   0.0000000000    ,   -0.32823514300  ,   0.23829293510   ],
[   78.25   ,   0.0000000000    ,   -0.33113612170  ,   0.24025323110   ],
[   78.50   ,   0.0000000000    ,   -0.33402978130  ,   0.24220616170   ],
[   78.75   ,   0.0000000000    ,   -0.33691604240  ,   0.24415192650   ],
[   79.00   ,   0.0000000000    ,   -0.33979483120  ,   0.24609071360   ],
[   79.25   ,   0.0000000000    ,   -0.34266607520  ,   0.24802271300   ],
[   79.50   ,   0.0000000000    ,   -0.34552969620  ,   0.24994813390   ],
[   79.75   ,   0.0000000000    ,   -0.34838561720  ,   0.25186718300   ],
[   80.00   ,   0.0000000000    ,   -0.35123376010  ,   0.25378005620   ],
[   80.25   ,   0.0000000000    ,   -0.35407404820  ,   0.25568691650   ],
[   80.50   ,   0.0000000000    ,   -0.35690642460  ,   0.25758784190   ],
[   80.75   ,   0.0000000000    ,   -0.35973084030  ,   0.25948291630   ],
[   81.00   ,   0.0000000000    ,   -0.36254724690  ,   0.26137222280   ],
[   81.25   ,   0.0000000000    ,   -0.36535560520  ,   0.26325583660   ],
[   81.50   ,   0.0000000000    ,   -0.36815589230  ,   0.26513383010   ],
[   81.75   ,   0.0000000000    ,   -0.37094808830  ,   0.26700627030   ],
[   82.00   ,   0.0000000000    ,   -0.37373217560  ,   0.26887322400   ],
[   82.25   ,   0.0000000000    ,   -0.37650814050  ,   0.27073476390   ],
[   82.50   ,   0.0000000000    ,   -0.37927596810  ,   0.27259097240   ],
[   82.75   ,   0.0000000000    ,   -0.38203564550  ,   0.27444193500   ],
[   83.00   ,   0.0000000000    ,   -0.38478716020  ,   0.27628773620   ],
[   83.25   ,   0.0000000000    ,   -0.38753050770  ,   0.27812844960   ],
[   83.50   ,   0.0000000000    ,   -0.39026571390  ,   0.27996411200   ],
[   83.75   ,   0.0000000000    ,   -0.39299281520  ,   0.28179475020   ],
[   84.00   ,   0.0000000000    ,   -0.39571184700  ,   0.28362039100   ],
[   84.25   ,   0.0000000000    ,   -0.39842283970  ,   0.28544106150   ],
[   84.50   ,   0.0000000000    ,   -0.40112581520  ,   0.28725679100   ],
[   84.75   ,   0.0000000000    ,   -0.40382079530  ,   0.28906760660   ],
[   85.00   ,   0.0000000000    ,   -0.40650780270  ,   0.29087353280   ],
[   85.25   ,   0.0000000000    ,   -0.40918686070  ,   0.29267458760   ],
[   85.50   ,   0.0000000000    ,   -0.41185799850  ,   0.29447077370   ],
[   85.75   ,   0.0000000000    ,   -0.41452124690  ,   0.29626209000   ],
[   86.00   ,   0.0000000000    ,   -0.41717663660  ,   0.29804853210   ],
[   86.25   ,   0.0000000000    ,   -0.41982420200  ,   0.29983008900   ],
[   86.50   ,   0.0000000000    ,   -0.42246398880  ,   0.30160673400   ],
[   86.75   ,   0.0000000000    ,   -0.42509604780  ,   0.30337844760   ],
[   87.00   ,   0.0000000000    ,   -0.42772043050  ,   0.30514521600   ],
[   87.25   ,   0.0000000000    ,   -0.43033719010  ,   0.30690702900   ],
[   87.50   ,   0.0000000000    ,   -0.43294638780  ,   0.30866389100   ],
[   87.75   ,   0.0000000000    ,   -0.43554808640  ,   0.31041580950   ],
[   88.00   ,   0.0000000000    ,   -0.43814234800  ,   0.31216279260   ],
[   88.25   ,   0.0000000000    ,   -0.44072923110  ,   0.31390485120   ],
[   88.50   ,   0.0000000000    ,   -0.44330878210  ,   0.31564200850   ],
[   88.75   ,   0.0000000000    ,   -0.44588104450  ,   0.31737429060   ],
[   89.00   ,   0.0000000000    ,   -0.44844606120  ,   0.31910172310   ],
[   89.25   ,   0.0000000000    ,   -0.45100387450  ,   0.32082433040   ],
[   89.50   ,   0.0000000000    ,   -0.45355452580  ,   0.32254213180   ],
[   89.75   ,   0.0000000000    ,   -0.45609805480  ,   0.32425514440   ],
[   90.00   ,   0.0000000000    ,   -0.45863449980  ,   0.32596338490   ],
[   90.25   ,   0.0000000000    ,   -0.46116389790  ,   0.32766686910   ],
[   90.50   ,   0.0000000000    ,   -0.46368628450  ,   0.32936561090   ],
[   90.75   ,   0.0000000000    ,   -0.46620169380  ,   0.33105962350   ],
[   91.00   ,   0.0000000000    ,   -0.46871016000  ,   0.33274892000   ],
[   91.25   ,   0.0000000000    ,   -0.47121171820  ,   0.33443351730   ],
[   91.50   ,   0.0000000000    ,   -0.47370640760  ,   0.33611344740   ],
[   91.75   ,   0.0000000000    ,   -0.47619426830  ,   0.33778874540   ],
[   92.00   ,   0.0000000000    ,   -0.47867534000  ,   0.33945944600   ],
[   92.25   ,   0.0000000000    ,   -0.48114966200  ,   0.34112558280   ],
[   92.50   ,   0.0000000000    ,   -0.48361727250  ,   0.34278718390   ],
[   92.75   ,   0.0000000000    ,   -0.48607820900  ,   0.34444427640   ],
[   93.00   ,   0.0000000000    ,   -0.48853250900  ,   0.34609688710   ],
[   93.25   ,   0.0000000000    ,   -0.49098020950  ,   0.34774504240   ],
[   93.50   ,   0.0000000000    ,   -0.49342134710  ,   0.34938876860   ],
[   93.75   ,   0.0000000000    ,   -0.49585595840  ,   0.35102809180   ],
[   94.00   ,   0.0000000000    ,   -0.49828407940  ,   0.35266303780   ],
[   94.25   ,   0.0000000000    ,   -0.50070574590  ,   0.35429363210   ],
[   94.50   ,   0.0000000000    ,   -0.50312099360  ,   0.35591990020   ],
[   94.75   ,   0.0000000000    ,   -0.50552985750  ,   0.35754186720   ],
[   95.00   ,   0.0000000000    ,   -0.50793237270  ,   0.35915955800   ]];

GLIPredData.FEV1FVC.male = {};
GLIPredData.FEV1FVC.male.a = [0.7403,  -0.1595,  -0.0366,  0.0079,  0.0055,  0.0283,  0.0106];
GLIPredData.FEV1FVC.male.p = [-2.9595,  0.1156,  -0.0381,  -0.2227,  -0.1414,  -0.0860];
GLIPredData.FEV1FVC.male.q = [4.7101,  -0.6774];
GLIPredData.FEV1FVC.male.maxAge = 95;
GLIPredData.FEV1FVC.male.spline = 
[[  3.00    ,   1.38762249360   ,   -0.02207450000  ,   -0.08315553270  ],
[   3.25    ,   1.26208570370   ,   -0.01914029000  ,   -0.06164797960  ],
[   3.50    ,   1.14600666410   ,   -0.01667884000  ,   -0.04195783860  ],
[   3.75    ,   1.03819248760   ,   -0.01468331000  ,   -0.02416787120  ],
[   4.00    ,   0.93767520380   ,   -0.01315842000  ,   -0.00851065190  ],
[   4.25    ,   0.84367628630   ,   -0.01217043000  ,   0.00474376550   ],
[   4.50    ,   0.75555802740   ,   -0.01173148000  ,   0.01532571560   ],
[   4.75    ,   0.67288044550   ,   -0.01186174000  ,   0.02323542080   ],
[   5.00    ,   0.59533467470   ,   -0.01254906000  ,   0.02874407080   ],
[   5.25    ,   0.52272967760   ,   -0.01361278000  ,   0.03226116140   ],
[   5.50    ,   0.45494101470   ,   -0.01478871000  ,   0.03424426040   ],
[   5.75    ,   0.39180461050   ,   -0.01585905000  ,   0.03505970120   ],
[   6.00    ,   0.33313558970   ,   -0.01665586000  ,   0.03504646510   ],
[   6.25    ,   0.27875321280   ,   -0.01711992000  ,   0.03454897320   ],
[   6.50    ,   0.22849939770   ,   -0.01729294000  ,   0.03385457310   ],
[   6.75    ,   0.18223531880   ,   -0.01721339000  ,   0.03319617670   ],
[   7.00    ,   0.13974517090   ,   -0.01687449000  ,   0.03267817240   ],
[   7.25    ,   0.10077296650   ,   -0.01629900000  ,   0.03225440920   ],
[   7.50    ,   0.06506670840   ,   -0.01556061000  ,   0.03176185090   ],
[   7.75    ,   0.03241988300   ,   -0.01475901000  ,   0.03100287230   ],
[   8.00    ,   0.00264944660   ,   -0.01401177000  ,   0.02976608090   ],
[   8.25    ,   -0.02442109330  ,   -0.01343785000  ,   0.02780977130   ],
[   8.50    ,   -0.04896734160  ,   -0.01313989000  ,   0.02487718060   ],
[   8.75    ,   -0.07115236830  ,   -0.01319290000  ,   0.02083623900   ],
[   9.00    ,   -0.09115939950  ,   -0.01350281000  ,   0.01588957080   ],
[   9.25    ,   -0.10917753900  ,   -0.01389113000  ,   0.01041781090   ],
[   9.50    ,   -0.12540345260  ,   -0.01420561000  ,   0.00480310310   ],
[   9.75    ,   -0.14004011070  ,   -0.01434906000  ,   -0.00065796370  ],
[   10.00   ,   -0.15328189610  ,   -0.01425778000  ,   -0.00575612520  ],
[   10.25   ,   -0.16529845550  ,   -0.01388478000  ,   -0.01034684390  ],
[   10.50   ,   -0.17624122300  ,   -0.01319707000  ,   -0.01434581410  ],
[   10.75   ,   -0.18622767830  ,   -0.01217052000  ,   -0.01769928470  ],
[   11.00   ,   -0.19534036550  ,   -0.01079309000  ,   -0.02035023840  ],
[   11.25   ,   -0.20363983680  ,   -0.00906898200  ,   -0.02226512080  ],
[   11.50   ,   -0.21117188880  ,   -0.00702217900  ,   -0.02345260790  ],
[   11.75   ,   -0.21796133260  ,   -0.00469595100  ,   -0.02393977270  ],
[   12.00   ,   -0.22402118430  ,   -0.00213565100  ,   -0.02376781260  ],
[   12.25   ,   -0.22935211780  ,   0.00061497460   ,   -0.02298276220  ],
[   12.50   ,   -0.23395394840  ,   0.00351092300   ,   -0.02165532540  ],
[   12.75   ,   -0.23782507290  ,   0.00650548800   ,   -0.01987134760  ],
[   13.00   ,   -0.24096901330  ,   0.00954955100   ,   -0.01772010520  ],
[   13.25   ,   -0.24339852020  ,   0.01260075000   ,   -0.01528234430  ],
[   13.50   ,   -0.24513325050  ,   0.01562460000   ,   -0.01262234400  ],
[   13.75   ,   -0.24620143240  ,   0.01859111000   ,   -0.00978575430  ],
[   14.00   ,   -0.24663587580  ,   0.02147073000   ,   -0.00681217260  ],
[   14.25   ,   -0.24646937000  ,   0.02423179000   ,   -0.00375391700  ],
[   14.50   ,   -0.24573645350  ,   0.02684421000   ,   -0.00067131780  ],
[   14.75   ,   -0.24447248990  ,   0.02928504000   ,   0.00238344290   ],
[   15.00   ,   -0.24271203090  ,   0.03153645000   ,   0.00536382600   ],
[   15.25   ,   -0.24048673900  ,   0.03358525000   ,   0.00823143020   ],
[   15.50   ,   -0.23782561830  ,   0.03542214000   ,   0.01095353230   ],
[   15.75   ,   -0.23475667190  ,   0.03704382000   ,   0.01350367520   ],
[   16.00   ,   -0.23130707480  ,   0.03845005000   ,   0.01586413950   ],
[   16.25   ,   -0.22749979200  ,   0.03964286000   ,   0.01802844190   ],
[   16.50   ,   -0.22335700590  ,   0.04063197000   ,   0.01999523450   ],
[   16.75   ,   -0.21890080170  ,   0.04143279000   ,   0.02176218700   ],
[   17.00   ,   -0.21415197100  ,   0.04206220000   ,   0.02332557140   ],
[   17.25   ,   -0.20913013190  ,   0.04253550000   ,   0.02468168430   ],
[   17.50   ,   -0.20385322250  ,   0.04286716000   ,   0.02582824570   ],
[   17.75   ,   -0.19833915140  ,   0.04307220000   ,   0.02676447090   ],
[   18.00   ,   -0.19260585630  ,   0.04316498000   ,   0.02749029720   ],
[   18.25   ,   -0.18666997950  ,   0.04315900000   ,   0.02800741880   ],
[   18.50   ,   -0.18054663110  ,   0.04306800000   ,   0.02832492240   ],
[   18.75   ,   -0.17424914080  ,   0.04290548000   ,   0.02845241080   ],
[   19.00   ,   -0.16778985260  ,   0.04268467000   ,   0.02839677220   ],
[   19.25   ,   -0.16118118130  ,   0.04241711000   ,   0.02816518160   ],
[   19.50   ,   -0.15443786610  ,   0.04211016000   ,   0.02777032250   ],
[   19.75   ,   -0.14757436050  ,   0.04176869000   ,   0.02722533830   ],
[   20.00   ,   -0.14060469030  ,   0.04139626000   ,   0.02654259110   ],
[   20.25   ,   -0.13354307920  ,   0.04099596000   ,   0.02573348770   ],
[   20.50   ,   -0.12640376460  ,   0.04057142000   ,   0.02480580610   ],
[   20.75   ,   -0.11920014540  ,   0.04012664000   ,   0.02376695990   ],
[   21.00   ,   -0.11194482580  ,   0.03966578000   ,   0.02262393850   ],
[   21.25   ,   -0.10464963980  ,   0.03919251000   ,   0.02138267660   ],
[   21.50   ,   -0.09732593300  ,   0.03870933000   ,   0.02004666930   ],
[   21.75   ,   -0.08998427990  ,   0.03821809000   ,   0.01861839230   ],
[   22.00   ,   -0.08263428950  ,   0.03772019000   ,   0.01710013660   ],
[   22.25   ,   -0.07528477320  ,   0.03721659000   ,   0.01549508100   ],
[   22.50   ,   -0.06794321240  ,   0.03670728000   ,   0.01380538950   ],
[   22.75   ,   -0.06061676080  ,   0.03619256000   ,   0.01203267460   ],
[   23.00   ,   -0.05331246760  ,   0.03567311000   ,   0.01017902660   ],
[   23.25   ,   -0.04603662390  ,   0.03514942000   ,   0.00824615040   ],
[   23.50   ,   -0.03879424040  ,   0.03462082000   ,   0.00623431260   ],
[   23.75   ,   -0.03159031740  ,   0.03408707000   ,   0.00414473920   ],
[   24.00   ,   -0.02443025200  ,   0.03354901000   ,   0.00198070480   ],
[   24.25   ,   -0.01731915420  ,   0.03300785000   ,   -0.00025373450  ],
[   24.50   ,   -0.01026158000  ,   0.03246418000   ,   -0.00255511580  ],
[   24.75   ,   -0.00326204410  ,   0.03191859000   ,   -0.00492035080  ],
[   25.00   ,   0.00367498640   ,   0.03137202000   ,   -0.00734620750  ],
[   25.25   ,   0.01054484660   ,   0.03082569000   ,   -0.00982882200  ],
[   25.50   ,   0.01734165790   ,   0.03028089000   ,   -0.01236154060  ],
[   25.75   ,   0.02405949830   ,   0.02973897000   ,   -0.01493653820  ],
[   26.00   ,   0.03069298620   ,   0.02920123000   ,   -0.01754576730  ],
[   26.25   ,   0.03723677510   ,   0.02866906000   ,   -0.02018126630  ],
[   26.50   ,   0.04368556940   ,   0.02814370000   ,   -0.02283546920  ],
[   26.75   ,   0.05003475630   ,   0.02762617000   ,   -0.02550121530  ],
[   27.00   ,   0.05628049420   ,   0.02711710000   ,   -0.02817136070  ],
[   27.25   ,   0.06241945600   ,   0.02661684000   ,   -0.03083853320  ],
[   27.50   ,   0.06844878390   ,   0.02612573000   ,   -0.03349556260  ],
[   27.75   ,   0.07436597660   ,   0.02564389000   ,   -0.03613540840  ],
[   28.00   ,   0.08016843360   ,   0.02517140000   ,   -0.03875139960  ],
[   28.25   ,   0.08585355500   ,   0.02470849000   ,   -0.04133751800  ],
[   28.50   ,   0.09141892550   ,   0.02425519000   ,   -0.04389022220  ],
[   28.75   ,   0.09686235850   ,   0.02381136000   ,   -0.04640684160  ],
[   29.00   ,   0.10218172460   ,   0.02337699000   ,   -0.04888438720  ],
[   29.25   ,   0.10737469570   ,   0.02295223000   ,   -0.05131920250  ],
[   29.50   ,   0.11243900040   ,   0.02253724000   ,   -0.05370736410  ],
[   29.75   ,   0.11737261530   ,   0.02213199000   ,   -0.05604511190  ],
[   30.00   ,   0.12217363660   ,   0.02173641000   ,   -0.05832914860  ],
[   30.25   ,   0.12684046530   ,   0.02135040000   ,   -0.06055674260  ],
[   30.50   ,   0.13137195280   ,   0.02097373000   ,   -0.06272690090  ],
[   30.75   ,   0.13576694810   ,   0.02060609000   ,   -0.06483913640  ],
[   31.00   ,   0.14002428450   ,   0.02024706000   ,   -0.06689302840  ],
[   31.25   ,   0.14414281030   ,   0.01989611000   ,   -0.06888821870  ],
[   31.50   ,   0.14812161620   ,   0.01955227000   ,   -0.07082497820  ],
[   31.75   ,   0.15196000340   ,   0.01921432000   ,   -0.07270376200  ],
[   32.00   ,   0.15565736630   ,   0.01888102000   ,   -0.07452525240  ],
[   32.25   ,   0.15921316420   ,   0.01855124000   ,   -0.07629022030  ],
[   32.50   ,   0.16262675060   ,   0.01822420000   ,   -0.07800006710  ],
[   32.75   ,   0.16589740490   ,   0.01789926000   ,   -0.07965619270  ],
[   33.00   ,   0.16902449500   ,   0.01757580000   ,   -0.08125962040  ],
[   33.25   ,   0.17200749050   ,   0.01725302000   ,   -0.08281104080  ],
[   33.50   ,   0.17484611000   ,   0.01692998000   ,   -0.08431058580  ],
[   33.75   ,   0.17754040250   ,   0.01660552000   ,   -0.08575819080  ],
[   34.00   ,   0.18009081350   ,   0.01627831000   ,   -0.08715364140  ],
[   34.25   ,   0.18249823980   ,   0.01594722000   ,   -0.08849646060  ],
[   34.50   ,   0.18476404010   ,   0.01561167000   ,   -0.08978577800  ],
[   34.75   ,   0.18688964200   ,   0.01527121000   ,   -0.09102026540  ],
[   35.00   ,   0.18887653610   ,   0.01492547000   ,   -0.09219844500  ],
[   35.25   ,   0.19072610180   ,   0.01457434000   ,   -0.09331891400  ],
[   35.50   ,   0.19243977800   ,   0.01421807000   ,   -0.09438053120  ],
[   35.75   ,   0.19401913140   ,   0.01385693000   ,   -0.09538222630  ],
[   36.00   ,   0.19546569950   ,   0.01349116000   ,   -0.09632300560  ],
[   36.25   ,   0.19678103250   ,   0.01312107000   ,   -0.09720214760  ],
[   36.50   ,   0.19796682920   ,   0.01274735000   ,   -0.09801916610  ],
[   36.75   ,   0.19902479080   ,   0.01237076000   ,   -0.09877360880  ],
[   37.00   ,   0.19995663400   ,   0.01199196000   ,   -0.09946487510  ],
[   37.25   ,   0.20076421510   ,   0.01161155000   ,   -0.10009254290  ],
[   37.50   ,   0.20144972250   ,   0.01122989000   ,   -0.10065669780  ],
[   37.75   ,   0.20201545360   ,   0.01084727000   ,   -0.10115742770  ],
[   38.00   ,   0.20246366300   ,   0.01046390000   ,   -0.10159492480  ],
[   38.25   ,   0.20279659980   ,   0.01007992000   ,   -0.10196953560  ],
[   38.50   ,   0.20301659140   ,   0.00969554700   ,   -0.10228166250  ],
[   38.75   ,   0.20312580110   ,   0.00931109100   ,   -0.10253159430  ],
[   39.00   ,   0.20312619000   ,   0.00892685000   ,   -0.10271954650  ],
[   39.25   ,   0.20301968510   ,   0.00854308800   ,   -0.10284581000  ],
[   39.50   ,   0.20280831340   ,   0.00816010700   ,   -0.10291085820  ],
[   39.75   ,   0.20249411020   ,   0.00777819400   ,   -0.10291525910  ],
[   40.00   ,   0.20207907920   ,   0.00739765300   ,   -0.10285954970  ],
[   40.25   ,   0.20156555990   ,   0.00701883200   ,   -0.10274412680  ],
[   40.50   ,   0.20095687290   ,   0.00664220200   ,   -0.10256925810  ],
[   40.75   ,   0.20025640710   ,   0.00626825600   ,   -0.10233513590  ],
[   41.00   ,   0.19946744660   ,   0.00589746200   ,   -0.10204183510  ],
[   41.25   ,   0.19859340450   ,   0.00553022200   ,   -0.10168937120  ],
[   41.50   ,   0.19763785040   ,   0.00516689600   ,   -0.10127762280  ],
[   41.75   ,   0.19660423660   ,   0.00480769000   ,   -0.10080631350  ],
[   42.00   ,   0.19549593510   ,   0.00445264100   ,   -0.10027513060  ],
[   42.25   ,   0.19431617040   ,   0.00410176000   ,   -0.09968397820  ],
[   42.50   ,   0.19306777290   ,   0.00375519100   ,   -0.09903318760  ],
[   42.75   ,   0.19175335790   ,   0.00341309100   ,   -0.09832320140  ],
[   43.00   ,   0.19037539210   ,   0.00307559000   ,   -0.09755450550  ],
[   43.25   ,   0.18893625370   ,   0.00274276700   ,   -0.09672770690  ],
[   43.50   ,   0.18743815340   ,   0.00241469600   ,   -0.09584357960  ],
[   43.75   ,   0.18588323370   ,   0.00209139300   ,   -0.09490290750  ],
[   44.00   ,   0.18427366920   ,   0.00177280100   ,   -0.09390642590  ],
[   44.25   ,   0.18261154560   ,   0.00145880600   ,   -0.09285494390  ],
[   44.50   ,   0.18089861270   ,   0.00114933100   ,   -0.09174936750  ],
[   44.75   ,   0.17913652170   ,   0.00084429060   ,   -0.09059057020  ],
[   45.00   ,   0.17732691050   ,   0.00054352830   ,   -0.08937934140  ],
[   45.25   ,   0.17547135300   ,   0.00024673330   ,   -0.08811659830  ],
[   45.50   ,   0.17357127590   ,   -0.00004656918  ,   -0.08680362120  ],
[   45.75   ,   0.17162805940   ,   -0.00033693300  ,   -0.08544176190  ],
[   46.00   ,   0.16964308120   ,   -0.00062497220  ,   -0.08403237250  ],
[   46.25   ,   0.16761769970   ,   -0.00091136950  ,   -0.08257688450  ],
[   46.50   ,   0.16555332980   ,   -0.00119678200  ,   -0.08107688260  ],
[   46.75   ,   0.16345139430   ,   -0.00148186400  ,   -0.07953395170  ],
[   47.00   ,   0.16131322290   ,   -0.00176727000  ,   -0.07794961750  ],
[   47.25   ,   0.15914003900   ,   -0.00205360100  ,   -0.07632531550  ],
[   47.50   ,   0.15693296250   ,   -0.00234133500  ,   -0.07466236980  ],
[   47.75   ,   0.15469298270   ,   -0.00263086600  ,   -0.07296195680  ],
[   48.00   ,   0.15242108520   ,   -0.00292255800  ,   -0.07122514090  ],
[   48.25   ,   0.15011826570   ,   -0.00321668000  ,   -0.06945276610  ],
[   48.50   ,   0.14778553320   ,   -0.00351326400  ,   -0.06764504660  ],
[   48.75   ,   0.14542387780   ,   -0.00381230000  ,   -0.06580208100  ],
[   49.00   ,   0.14303424380   ,   -0.00411374400  ,   -0.06392400500  ],
[   49.25   ,   0.14061751460   ,   -0.00441752700  ,   -0.06201101870  ],
[   49.50   ,   0.13817448470   ,   -0.00472348600  ,   -0.06006351590  ],
[   49.75   ,   0.13570591710   ,   -0.00503146100  ,   -0.05808194320  ],
[   50.00   ,   0.13321257340   ,   -0.00534136200  ,   -0.05606674510  ],
[   50.25   ,   0.13069517670   ,   -0.00565316900  ,   -0.05401843260  ],
[   50.50   ,   0.12815441210   ,   -0.00596694300  ,   -0.05193756240  ],
[   50.75   ,   0.12559091690   ,   -0.00628277700  ,   -0.04982460400  ],
[   51.00   ,   0.12300529230   ,   -0.00660078200  ,   -0.04767994870  ],
[   51.25   ,   0.12039808890   ,   -0.00692106800  ,   -0.04550402580  ],
[   51.50   ,   0.11776968540   ,   -0.00724369800  ,   -0.04329744050  ],
[   51.75   ,   0.11512037310   ,   -0.00756871100  ,   -0.04106082310  ],
[   52.00   ,   0.11245041710   ,   -0.00789616000  ,   -0.03879480880  ],
[   52.25   ,   0.10976010110   ,   -0.00822610100  ,   -0.03650005220  ],
[   52.50   ,   0.10704982590   ,   -0.00855853200  ,   -0.03417718690  ],
[   52.75   ,   0.10432004490   ,   -0.00889350100  ,   -0.03182682480  ],
[   53.00   ,   0.10157121100   ,   -0.00923105700  ,   -0.02944963250  ],
[   53.25   ,   0.09880375850   ,   -0.00957120300  ,   -0.02704632420  ],
[   53.50   ,   0.09601806670   ,   -0.00991381700  ,   -0.02461752260  ],
[   53.75   ,   0.09321450860   ,   -0.01025875000  ,   -0.02216379820  ],
[   54.00   ,   0.09039345380   ,   -0.01060586000  ,   -0.01968570760  ],
[   54.25   ,   0.08755525610   ,   -0.01095502000  ,   -0.01718381780  ],
[   54.50   ,   0.08470023190   ,   -0.01130602000  ,   -0.01465867310  ],
[   54.75   ,   0.08182866960   ,   -0.01165863000  ,   -0.01211078340  ],
[   55.00   ,   0.07894083950   ,   -0.01201267000  ,   -0.00954063840  ],
[   55.25   ,   0.07603706280   ,   -0.01236799000  ,   -0.00694869750  ],
[   55.50   ,   0.07311786650   ,   -0.01272442000  ,   -0.00433534120  ],
[   55.75   ,   0.07018384550   ,   -0.01308183000  ,   -0.00170089340  ],
[   56.00   ,   0.06723561560   ,   -0.01344007000  ,   0.00095436870   ],
[   56.25   ,   0.06427376810   ,   -0.01379901000  ,   0.00363016760   ],
[   56.50   ,   0.06129897600   ,   -0.01415847000  ,   0.00632621380   ],
[   56.75   ,   0.05831196890   ,   -0.01451830000  ,   0.00904223720   ],
[   57.00   ,   0.05531346400   ,   -0.01487838000  ,   0.01177794220   ],
[   57.25   ,   0.05230415840   ,   -0.01523859000  ,   0.01453294070   ],
[   57.50   ,   0.04928476940   ,   -0.01559880000  ,   0.01730667380   ],
[   57.75   ,   0.04625601890   ,   -0.01595888000  ,   0.02009853200   ],
[   58.00   ,   0.04321864370   ,   -0.01631871000  ,   0.02290792680   ],
[   58.25   ,   0.04017338710   ,   -0.01667822000  ,   0.02573427830   ],
[   58.50   ,   0.03712107730   ,   -0.01703738000  ,   0.02857708470   ],
[   58.75   ,   0.03406256640   ,   -0.01739619000  ,   0.03143586030   ],
[   59.00   ,   0.03099871100   ,   -0.01775467000  ,   0.03431012830   ],
[   59.25   ,   0.02793031650   ,   -0.01811282000  ,   0.03719941840   ],
[   59.50   ,   0.02485800310   ,   -0.01847059000  ,   0.04010328570   ],
[   59.75   ,   0.02178228630   ,   -0.01882789000  ,   0.04302131190   ],
[   60.00   ,   0.01870365270   ,   -0.01918462000  ,   0.04595307040   ],
[   60.25   ,   0.01562262400   ,   -0.01954072000  ,   0.04889809660   ],
[   60.50   ,   0.01253976100   ,   -0.01989620000  ,   0.05185572680   ],
[   60.75   ,   0.00945561300   ,   -0.02025106000  ,   0.05482526380   ],
[   61.00   ,   0.00637072340   ,   -0.02060533000  ,   0.05780602880   ],
[   61.25   ,   0.00328559550   ,   -0.02095906000  ,   0.06079734160   ],
[   61.50   ,   0.00020060930   ,   -0.02131225000  ,   0.06379853700   ],
[   61.75   ,   -0.00288390780  ,   -0.02166494000  ,   0.06680894490   ],
[   62.00   ,   -0.00596766440  ,   -0.02201714000  ,   0.06982791540   ],
[   62.25   ,   -0.00905039620  ,   -0.02236885000  ,   0.07285475990   ],
[   62.50   ,   -0.01213190550  ,   -0.02272010000  ,   0.07588863700   ],
[   62.75   ,   -0.01521201740  ,   -0.02307093000  ,   0.07892867060   ],
[   63.00   ,   -0.01829055300  ,   -0.02342142000  ,   0.08197400370   ],
[   63.25   ,   -0.02136733980  ,   -0.02377162000  ,   0.08502381090   ],
[   63.50   ,   -0.02444230070  ,   -0.02412153000  ,   0.08807729320   ],
[   63.75   ,   -0.02751539410  ,   -0.02447114000  ,   0.09113368930   ],
[   64.00   ,   -0.03058658110  ,   -0.02482044000  ,   0.09419226040   ],
[   64.25   ,   -0.03365581330  ,   -0.02516941000  ,   0.09725227690   ],
[   64.50   ,   -0.03672296600  ,   -0.02551808000  ,   0.10031300550   ],
[   64.75   ,   -0.03978784800  ,   -0.02586650000  ,   0.10337375150   ],
[   65.00   ,   -0.04285026370  ,   -0.02621474000  ,   0.10643383830   ],
[   65.25   ,   -0.04591003500  ,   -0.02656286000  ,   0.10949263670   ],
[   65.50   ,   -0.04896700330  ,   -0.02691091000  ,   0.11254961600   ],
[   65.75   ,   -0.05202099910  ,   -0.02725896000  ,   0.11560429610   ],
[   66.00   ,   -0.05507185870  ,   -0.02760706000  ,   0.11865622910   ],
[   66.25   ,   -0.05811945540  ,   -0.02795527000  ,   0.12170497200   ],
[   66.50   ,   -0.06116372900  ,   -0.02830362000  ,   0.12475010200   ],
[   66.75   ,   -0.06420461220  ,   -0.02865216000  ,   0.12779121630   ],
[   67.00   ,   -0.06724203270  ,   -0.02900093000  ,   0.13082791240   ],
[   67.25   ,   -0.07027591480  ,   -0.02934995000  ,   0.13385980740   ],
[   67.50   ,   -0.07330617300  ,   -0.02969916000  ,   0.13688657000   ],
[   67.75   ,   -0.07633268270  ,   -0.03004850000  ,   0.13990790170   ],
[   68.00   ,   -0.07935527090  ,   -0.03039789000  ,   0.14292352470   ],
[   68.25   ,   -0.08237375510  ,   -0.03074727000  ,   0.14593317230   ],
[   68.50   ,   -0.08538792930  ,   -0.03109654000  ,   0.14893662010   ],
[   68.75   ,   -0.08839758240  ,   -0.03144560000  ,   0.15193365290   ],
[   69.00   ,   -0.09140250090  ,   -0.03179437000  ,   0.15492406210   ],
[   69.25   ,   -0.09440247180  ,   -0.03214275000  ,   0.15790768220   ],
[   69.50   ,   -0.09739738150  ,   -0.03249066000  ,   0.16088438860   ],
[   69.75   ,   -0.10038714780  ,   -0.03283800000  ,   0.16385406140   ],
[   70.00   ,   -0.10337169310  ,   -0.03318468000  ,   0.16681657810   ],
[   70.25   ,   -0.10635094320  ,   -0.03353063000  ,   0.16977183610   ],
[   70.50   ,   -0.10932482510  ,   -0.03387572000  ,   0.17271980920   ],
[   70.75   ,   -0.11229326920  ,   -0.03421983000  ,   0.17566048480   ],
[   71.00   ,   -0.11525621010  ,   -0.03456287000  ,   0.17859384980   ],
[   71.25   ,   -0.11821354300  ,   -0.03490473000  ,   0.18151992080   ],
[   71.50   ,   -0.12116516530  ,   -0.03524534000  ,   0.18443872140   ],
[   71.75   ,   -0.12411098530  ,   -0.03558462000  ,   0.18735026820   ],
[   72.00   ,   -0.12705091830  ,   -0.03592252000  ,   0.19025456600   ],
[   72.25   ,   -0.12998488240  ,   -0.03625899000  ,   0.19315159640   ],
[   72.50   ,   -0.13291278010  ,   -0.03659398000  ,   0.19604131870   ],
[   72.75   ,   -0.13583450620  ,   -0.03692749000  ,   0.19892368860   ],
[   73.00   ,   -0.13874994340  ,   -0.03725948000  ,   0.20179867110   ],
[   73.25   ,   -0.14165897860  ,   -0.03758994000  ,   0.20466623540   ],
[   73.50   ,   -0.14456152620  ,   -0.03791885000  ,   0.20752635690   ],
[   73.75   ,   -0.14745751150  ,   -0.03824621000  ,   0.21037901620   ],
[   74.00   ,   -0.15034685300  ,   -0.03857201000  ,   0.21322420580   ],
[   74.25   ,   -0.15322946150  ,   -0.03889626000  ,   0.21606193030   ],
[   74.50   ,   -0.15610525560  ,   -0.03921897000  ,   0.21889219810   ],
[   74.75   ,   -0.15897416750  ,   -0.03954014000  ,   0.22171502840   ],
[   75.00   ,   -0.16183616150  ,   -0.03985978000  ,   0.22453045700   ],
[   75.25   ,   -0.16469121130  ,   -0.04017788000  ,   0.22733851540   ],
[   75.50   ,   -0.16753928820  ,   -0.04049446000  ,   0.23013923200   ],
[   75.75   ,   -0.17038036290  ,   -0.04080950000  ,   0.23293263070   ],
[   76.00   ,   -0.17321440720  ,   -0.04112299000  ,   0.23571872890   ],
[   76.25   ,   -0.17604139500  ,   -0.04143493000  ,   0.23849752930   ],
[   76.50   ,   -0.17886129880  ,   -0.04174534000  ,   0.24126900000   ],
[   76.75   ,   -0.18167408760  ,   -0.04205422000  ,   0.24403310150   ],
[   77.00   ,   -0.18447972050  ,   -0.04236159000  ,   0.24678980660   ],
[   77.25   ,   -0.18727815060  ,   -0.04266748000  ,   0.24953909550   ],
[   77.50   ,   -0.19006931790  ,   -0.04297190000  ,   0.25228095120   ],
[   77.75   ,   -0.19285315310  ,   -0.04327487000  ,   0.25501536590   ],
[   78.00   ,   -0.19562958670  ,   -0.04357641000  ,   0.25774233430   ],
[   78.25   ,   -0.19839855610  ,   -0.04387655000  ,   0.26046185670   ],
[   78.50   ,   -0.20116001070  ,   -0.04417529000  ,   0.26317393500   ],
[   78.75   ,   -0.20391392090  ,   -0.04447267000  ,   0.26587857950   ],
[   79.00   ,   -0.20666028330  ,   -0.04476868000  ,   0.26857581800   ],
[   79.25   ,   -0.20939909380  ,   -0.04506334000  ,   0.27126567500   ],
[   79.50   ,   -0.21213033780  ,   -0.04535668000  ,   0.27394814820   ],
[   79.75   ,   -0.21485399810  ,   -0.04564871000  ,   0.27662322700   ],
[   80.00   ,   -0.21757005840  ,   -0.04593945000  ,   0.27929089840   ],
[   80.25   ,   -0.22027851590  ,   -0.04622892000  ,   0.28195115160   ],
[   80.50   ,   -0.22297940320  ,   -0.04651716000  ,   0.28460399910   ],
[   80.75   ,   -0.22567275010  ,   -0.04680417000  ,   0.28724947200   ],
[   81.00   ,   -0.22835859480  ,   -0.04708998000  ,   0.28988760910   ],
[   81.25   ,   -0.23103697740  ,   -0.04737460000  ,   0.29251844570   ],
[   81.50   ,   -0.23370792990  ,   -0.04765805000  ,   0.29514201720   ],
[   81.75   ,   -0.23637148130  ,   -0.04794035000  ,   0.29775835930   ],
[   82.00   ,   -0.23902765940  ,   -0.04822152000  ,   0.30036750890   ],
[   82.25   ,   -0.24167648190  ,   -0.04850156000  ,   0.30296950630   ],
[   82.50   ,   -0.24431795740  ,   -0.04878049000  ,   0.30556437330   ],
[   82.75   ,   -0.24695209270  ,   -0.04905832000  ,   0.30815212530   ],
[   83.00   ,   -0.24957889490  ,   -0.04933507000  ,   0.31073277760   ],
[   83.25   ,   -0.25219837190  ,   -0.04961074000  ,   0.31330634370   ],
[   83.50   ,   -0.25481053230  ,   -0.04988535000  ,   0.31587282880   ],
[   83.75   ,   -0.25741538540  ,   -0.05015891000  ,   0.31843223500   ],
[   84.00   ,   -0.26001294160  ,   -0.05043144000  ,   0.32098456350   ],
[   84.25   ,   -0.26260321060  ,   -0.05070294000  ,   0.32352981330   ],
[   84.50   ,   -0.26518619550  ,   -0.05097344000  ,   0.32606797560   ],
[   84.75   ,   -0.26776189680  ,   -0.05124294000  ,   0.32859904300   ],
[   85.00   ,   -0.27033031440  ,   -0.05151148000  ,   0.33112300980   ],
[   85.25   ,   -0.27289145210  ,   -0.05177906000  ,   0.33363987450   ],
[   85.50   ,   -0.27544532570  ,   -0.05204570000  ,   0.33614965170   ],
[   85.75   ,   -0.27799195370  ,   -0.05231141000  ,   0.33865235990   ],
[   86.00   ,   -0.28053135560  ,   -0.05257622000  ,   0.34114801690   ],
[   86.25   ,   -0.28306355230  ,   -0.05284013000  ,   0.34363663850   ],
[   86.50   ,   -0.28558856670  ,   -0.05310316000  ,   0.34611823410   ],
[   86.75   ,   -0.28810642300  ,   -0.05336533000  ,   0.34859281110   ],
[   87.00   ,   -0.29061714550  ,   -0.05362664000  ,   0.35106037660   ],
[   87.25   ,   -0.29312075950  ,   -0.05388713000  ,   0.35352094280   ],
[   87.50   ,   -0.29561729510  ,   -0.05414679000  ,   0.35597454260   ],
[   87.75   ,   -0.29810678350  ,   -0.05440563000  ,   0.35842121350   ],
[   88.00   ,   -0.30058925540  ,   -0.05466369000  ,   0.36086099270   ],
[   88.25   ,   -0.30306474210  ,   -0.05492095000  ,   0.36329391750   ],
[   88.50   ,   -0.30553327650  ,   -0.05517743000  ,   0.36572002600   ],
[   88.75   ,   -0.30799489190  ,   -0.05543315000  ,   0.36813935610   ],
[   89.00   ,   -0.31044962130  ,   -0.05568811000  ,   0.37055194560   ],
[   89.25   ,   -0.31289749770  ,   -0.05594231000  ,   0.37295783170   ],
[   89.50   ,   -0.31533855510  ,   -0.05619577000  ,   0.37535705160   ],
[   89.75   ,   -0.31777282780  ,   -0.05644849000  ,   0.37774964090   ],
[   90.00   ,   -0.32020035020  ,   -0.05670048000  ,   0.38013563460   ],
[   90.25   ,   -0.32262115670  ,   -0.05695173000  ,   0.38251506670   ],
[   90.50   ,   -0.32503528140  ,   -0.05720226000  ,   0.38488796850   ],
[   90.75   ,   -0.32744275870  ,   -0.05745207000  ,   0.38725437080   ],
[   91.00   ,   -0.32984362240  ,   -0.05770117000  ,   0.38961430400   ],
[   91.25   ,   -0.33223790630  ,   -0.05794957000  ,   0.39196779830   ],
[   91.50   ,   -0.33462564400  ,   -0.05819726000  ,   0.39431488400   ],
[   91.75   ,   -0.33700686900  ,   -0.05844426000  ,   0.39665559110   ],
[   92.00   ,   -0.33938161470  ,   -0.05869058000  ,   0.39898994960   ],
[   92.25   ,   -0.34174991430  ,   -0.05893621000  ,   0.40131798970   ],
[   92.50   ,   -0.34411180270  ,   -0.05918117000  ,   0.40363974360   ],
[   92.75   ,   -0.34646731450  ,   -0.05942546000  ,   0.40595524390   ],
[   93.00   ,   -0.34881648430  ,   -0.05966908000  ,   0.40826452300   ],
[   93.25   ,   -0.35115934630  ,   -0.05991204000  ,   0.41056761280   ],
[   93.50   ,   -0.35349593440  ,   -0.06015435000  ,   0.41286454530   ],
[   93.75   ,   -0.35582628240  ,   -0.06039600000  ,   0.41515535200   ],
[   94.00   ,   -0.35815042350  ,   -0.06063700000  ,   0.41744006430   ],
[   94.25   ,   -0.36046839100  ,   -0.06087736000  ,   0.41971871330   ],
[   94.50   ,   -0.36278021770  ,   -0.06111709000  ,   0.42199132980   ],
[   94.75   ,   -0.36508593620  ,   -0.06135617000  ,   0.42425794440   ],
[   95.00   ,   -0.36738557890  ,   -0.06159463000  ,   0.42651858760   ]];

GLIPredData.FVC.female = {};
GLIPredData.FVC.female.a = [-10.4030,  2.2633,  0.0234,  -0.1555,  -0.0262,  -0.1516,  -0.0833];
GLIPredData.FVC.female.p = [-2.3549,  0.1017,  0.0810,  -0.1809,  0.0459,  -0.0503];
GLIPredData.FVC.female.q = [0.8236,0];
GLIPredData.FVC.female.maxAge = 95;
GLIPredData.FVC.female.spline = 
[[  3.00    ,   0.00000000000   ,   -0.19404556450  ,   0.36935210000   ],
[   3.25    ,   0.00000000000   ,   -0.18241301620  ,   0.34310420000   ],
[   3.50    ,   0.00000000000   ,   -0.17167956310  ,   0.31864760000   ],
[   3.75    ,   0.00000000000   ,   -0.16186620880  ,   0.29578230000   ],
[   4.00    ,   0.00000000000   ,   -0.15300273830  ,   0.27437800000   ],
[   4.25    ,   0.00000000000   ,   -0.14510309510  ,   0.25424690000   ],
[   4.50    ,   0.00000000000   ,   -0.13822356400  ,   0.23516120000   ],
[   4.75    ,   0.00000000000   ,   -0.13237783910  ,   0.21696350000   ],
[   5.00    ,   0.00000000000   ,   -0.12759234990  ,   0.19952570000   ],
[   5.25    ,   0.00000000000   ,   -0.12375828630  ,   0.18271070000   ],
[   5.50    ,   0.00000000000   ,   -0.12059393400  ,   0.16643810000   ],
[   5.75    ,   0.00000000000   ,   -0.11785299540  ,   0.15068490000   ],
[   6.00    ,   0.00000000000   ,   -0.11530524410  ,   0.13547050000   ],
[   6.25    ,   0.00000000000   ,   -0.11278643630  ,   0.12085600000   ],
[   6.50    ,   0.00000000000   ,   -0.11017359980  ,   0.10692720000   ],
[   6.75    ,   0.00000000000   ,   -0.10739176100  ,   0.09378818000   ],
[   7.00    ,   0.00000000000   ,   -0.10445455100  ,   0.08149043000   ],
[   7.25    ,   0.00000000000   ,   -0.10141439260  ,   0.06999960000   ],
[   7.50    ,   0.00000000000   ,   -0.09831154400  ,   0.05924666000   ],
[   7.75    ,   0.00000000000   ,   -0.09515149100  ,   0.04914277000   ],
[   8.00    ,   0.00000000000   ,   -0.09180284210  ,   0.03962099000   ],
[   8.25    ,   0.00000000000   ,   -0.08806850490  ,   0.03065640000   ],
[   8.50    ,   0.00000000000   ,   -0.08375626880  ,   0.02227306000   ],
[   8.75    ,   0.00000000000   ,   -0.07872900310  ,   0.01450309000   ],
[   9.00    ,   0.00000000000   ,   -0.07308296180  ,   0.00741148500   ],
[   9.25    ,   0.00000000000   ,   -0.06693416830  ,   0.00103893600   ],
[   9.50    ,   0.00000000000   ,   -0.06035101050  ,   -0.00460396200  ],
[   9.75    ,   0.00000000000   ,   -0.05339227670  ,   -0.00952685000  ],
[   10.00   ,   0.00000000000   ,   -0.04612693200  ,   -0.01374553000  ],
[   10.25   ,   0.00000000000   ,   -0.03863926310  ,   -0.01730118000  ],
[   10.50   ,   0.00000000000   ,   -0.03099474810  ,   -0.02025238000  ],
[   10.75   ,   0.00000000000   ,   -0.02323408630  ,   -0.02266699000  ],
[   11.00   ,   0.00000000000   ,   -0.01537647840  ,   -0.02460672000  ],
[   11.25   ,   0.00000000000   ,   -0.00743075850  ,   -0.02613273000  ],
[   11.50   ,   0.00000000000   ,   0.00057220590   ,   -0.02731311000  ],
[   11.75   ,   0.00000000000   ,   0.00858172270   ,   -0.02821530000  ],
[   12.00   ,   0.00000000000   ,   0.01655143100   ,   -0.02890127000  ],
[   12.25   ,   0.00000000000   ,   0.02444341770   ,   -0.02942569000  ],
[   12.50   ,   0.00000000000   ,   0.03222303070   ,   -0.02983058000  ],
[   12.75   ,   0.00000000000   ,   0.03985887220   ,   -0.03014363000  ],
[   13.00   ,   0.00000000000   ,   0.04732014600   ,   -0.03038573000  ],
[   13.25   ,   0.00000000000   ,   0.05458030580   ,   -0.03057390000  ],
[   13.50   ,   0.00000000000   ,   0.06161020190   ,   -0.03072202000  ],
[   13.75   ,   0.00000000000   ,   0.06838501530   ,   -0.03084524000  ],
[   14.00   ,   0.00000000000   ,   0.07488514080   ,   -0.03096119000  ],
[   14.25   ,   0.00000000000   ,   0.08109170850   ,   -0.03108496000  ],
[   14.50   ,   0.00000000000   ,   0.08698096780   ,   -0.03122463000  ],
[   14.75   ,   0.00000000000   ,   0.09253562090   ,   -0.03138479000  ],
[   15.00   ,   0.00000000000   ,   0.09774630820   ,   -0.03156751000  ],
[   15.25   ,   0.00000000000   ,   0.10260625290   ,   -0.03177492000  ],
[   15.50   ,   0.00000000000   ,   0.10711075550   ,   -0.03201017000  ],
[   15.75   ,   0.00000000000   ,   0.11126263840   ,   -0.03227486000  ],
[   16.00   ,   0.00000000000   ,   0.11507366270   ,   -0.03256641000  ],
[   16.25   ,   0.00000000000   ,   0.11856033770   ,   -0.03288073000  ],
[   16.50   ,   0.00000000000   ,   0.12174696080   ,   -0.03321202000  ],
[   16.75   ,   0.00000000000   ,   0.12465457480   ,   -0.03355543000  ],
[   17.00   ,   0.00000000000   ,   0.12729774180   ,   -0.03390802000  ],
[   17.25   ,   0.00000000000   ,   0.12969009970   ,   -0.03426827000  ],
[   17.50   ,   0.00000000000   ,   0.13184684090   ,   -0.03463597000  ],
[   17.75   ,   0.00000000000   ,   0.13378157460   ,   -0.03501185000  ],
[   18.00   ,   0.00000000000   ,   0.13550752940   ,   -0.03539683000  ],
[   18.25   ,   0.00000000000   ,   0.13703784080   ,   -0.03579228000  ],
[   18.50   ,   0.00000000000   ,   0.13839012420   ,   -0.03620035000  ],
[   18.75   ,   0.00000000000   ,   0.13958171220   ,   -0.03662397000  ],
[   19.00   ,   0.00000000000   ,   0.14062829930   ,   -0.03706600000  ],
[   19.25   ,   0.00000000000   ,   0.14154435510   ,   -0.03752856000  ],
[   19.50   ,   0.00000000000   ,   0.14234427600   ,   -0.03801277000  ],
[   19.75   ,   0.00000000000   ,   0.14304129940   ,   -0.03851991000  ],
[   20.00   ,   0.00000000000   ,   0.14364741380   ,   -0.03905157000  ],
[   20.25   ,   0.00000000000   ,   0.14417320030   ,   -0.03960916000  ],
[   20.50   ,   0.00000000000   ,   0.14462548680   ,   -0.04019267000  ],
[   20.75   ,   0.00000000000   ,   0.14500956260   ,   -0.04080124000  ],
[   21.00   ,   0.00000000000   ,   0.14533020440   ,   -0.04143383000  ],
[   21.25   ,   0.00000000000   ,   0.14559161920   ,   -0.04209011000  ],
[   21.50   ,   0.00000000000   ,   0.14579618960   ,   -0.04277112000  ],
[   21.75   ,   0.00000000000   ,   0.14594519600   ,   -0.04347859000  ],
[   22.00   ,   0.00000000000   ,   0.14603909680   ,   -0.04421395000  ],
[   22.25   ,   0.00000000000   ,   0.14607811850   ,   -0.04497826000  ],
[   22.50   ,   0.00000000000   ,   0.14606246020   ,   -0.04577126000  ],
[   22.75   ,   0.00000000000   ,   0.14599273180   ,   -0.04659166000  ],
[   23.00   ,   0.00000000000   ,   0.14587018520   ,   -0.04743790000  ],
[   23.25   ,   0.00000000000   ,   0.14569764270   ,   -0.04830857000  ],
[   23.50   ,   0.00000000000   ,   0.14548093100   ,   -0.04920294000  ],
[   23.75   ,   0.00000000000   ,   0.14522689720   ,   -0.05012020000  ],
[   24.00   ,   0.00000000000   ,   0.14494284460   ,   -0.05105923000  ],
[   24.25   ,   0.00000000000   ,   0.14463579390   ,   -0.05201880000  ],
[   24.50   ,   0.00000000000   ,   0.14431095920   ,   -0.05299765000  ],
[   24.75   ,   0.00000000000   ,   0.14397305670   ,   -0.05399465000  ],
[   25.00   ,   0.00000000000   ,   0.14362672410   ,   -0.05500812000  ],
[   25.25   ,   0.00000000000   ,   0.14327551590   ,   -0.05603642000  ],
[   25.50   ,   0.00000000000   ,   0.14292155580   ,   -0.05707827000  ],
[   25.75   ,   0.00000000000   ,   0.14256636160   ,   -0.05813260000  ],
[   26.00   ,   0.00000000000   ,   0.14221136600   ,   -0.05919840000  ],
[   26.25   ,   0.00000000000   ,   0.14185710780   ,   -0.06027474000  ],
[   26.50   ,   0.00000000000   ,   0.14150075360   ,   -0.06135977000  ],
[   26.75   ,   0.00000000000   ,   0.14113956420   ,   -0.06245131000  ],
[   27.00   ,   0.00000000000   ,   0.14077159640   ,   -0.06354733000  ],
[   27.25   ,   0.00000000000   ,   0.14039513510   ,   -0.06464632000  ],
[   27.50   ,   0.00000000000   ,   0.14000838570   ,   -0.06574757000  ],
[   27.75   ,   0.00000000000   ,   0.13961004630   ,   -0.06685035000  ],
[   28.00   ,   0.00000000000   ,   0.13919976040   ,   -0.06795377000  ],
[   28.25   ,   0.00000000000   ,   0.13877782520   ,   -0.06905662000  ],
[   28.50   ,   0.00000000000   ,   0.13834405960   ,   -0.07015758000  ],
[   28.75   ,   0.00000000000   ,   0.13789842340   ,   -0.07125543000  ],
[   29.00   ,   0.00000000000   ,   0.13744129280   ,   -0.07234905000  ],
[   29.25   ,   0.00000000000   ,   0.13697303770   ,   -0.07343723000  ],
[   29.50   ,   0.00000000000   ,   0.13649286780   ,   -0.07451861000  ],
[   29.75   ,   0.00000000000   ,   0.13600002470   ,   -0.07559196000  ],
[   30.00   ,   0.00000000000   ,   0.13549428100   ,   -0.07665611000  ],
[   30.25   ,   0.00000000000   ,   0.13497550460   ,   -0.07770976000  ],
[   30.50   ,   0.00000000000   ,   0.13444270230   ,   -0.07875147000  ],
[   30.75   ,   0.00000000000   ,   0.13389462010   ,   -0.07977987000  ],
[   31.00   ,   0.00000000000   ,   0.13333025580   ,   -0.08079371000  ],
[   31.25   ,   0.00000000000   ,   0.13274839870   ,   -0.08179195000  ],
[   31.50   ,   0.00000000000   ,   0.13214692680   ,   -0.08277357000  ],
[   31.75   ,   0.00000000000   ,   0.13152400930   ,   -0.08373752000  ],
[   32.00   ,   0.00000000000   ,   0.13087807590   ,   -0.08468284000  ],
[   32.25   ,   0.00000000000   ,   0.13020790140   ,   -0.08560858000  ],
[   32.50   ,   0.00000000000   ,   0.12951267320   ,   -0.08651381000  ],
[   32.75   ,   0.00000000000   ,   0.12879199210   ,   -0.08739760000  ],
[   33.00   ,   0.00000000000   ,   0.12804587000   ,   -0.08825889000  ],
[   33.25   ,   0.00000000000   ,   0.12727440380   ,   -0.08909655000  ],
[   33.50   ,   0.00000000000   ,   0.12647781160   ,   -0.08990896000  ],
[   33.75   ,   0.00000000000   ,   0.12565655180   ,   -0.09069452000  ],
[   34.00   ,   0.00000000000   ,   0.12481137330   ,   -0.09145167000  ],
[   34.25   ,   0.00000000000   ,   0.12394331100   ,   -0.09217869000  ],
[   34.50   ,   0.00000000000   ,   0.12305247830   ,   -0.09287334000  ],
[   34.75   ,   0.00000000000   ,   0.12213908630   ,   -0.09353329000  ],
[   35.00   ,   0.00000000000   ,   0.12120356410   ,   -0.09415633000  ],
[   35.25   ,   0.00000000000   ,   0.12024640010   ,   -0.09474025000  ],
[   35.50   ,   0.00000000000   ,   0.11926702420   ,   -0.09528289000  ],
[   35.75   ,   0.00000000000   ,   0.11826452570   ,   -0.09578227000  ],
[   36.00   ,   0.00000000000   ,   0.11723806450   ,   -0.09623658000  ],
[   36.25   ,   0.00000000000   ,   0.11618707480   ,   -0.09664421000  ],
[   36.50   ,   0.00000000000   ,   0.11511172210   ,   -0.09700374000  ],
[   36.75   ,   0.00000000000   ,   0.11401242790   ,   -0.09731379000  ],
[   37.00   ,   0.00000000000   ,   0.11288958880   ,   -0.09757312000  ],
[   37.25   ,   0.00000000000   ,   0.11174364290   ,   -0.09778075000  ],
[   37.50   ,   0.00000000000   ,   0.11057505050   ,   -0.09793595000  ],
[   37.75   ,   0.00000000000   ,   0.10938428410   ,   -0.09803809000  ],
[   38.00   ,   0.00000000000   ,   0.10817183380   ,   -0.09808658000  ],
[   38.25   ,   0.00000000000   ,   0.10693799710   ,   -0.09808092000  ],
[   38.50   ,   0.00000000000   ,   0.10568264740   ,   -0.09802092000  ],
[   38.75   ,   0.00000000000   ,   0.10440568790   ,   -0.09790649000  ],
[   39.00   ,   0.00000000000   ,   0.10310730910   ,   -0.09773760000  ],
[   39.25   ,   0.00000000000   ,   0.10178779750   ,   -0.09751433000  ],
[   39.50   ,   0.00000000000   ,   0.10044691210   ,   -0.09723700000  ],
[   39.75   ,   0.00000000000   ,   0.09908433810   ,   -0.09690598000  ],
[   40.00   ,   0.00000000000   ,   0.09769994090   ,   -0.09652174000  ],
[   40.25   ,   0.00000000000   ,   0.09629360300   ,   -0.09608480000  ],
[   40.50   ,   0.00000000000   ,   0.09486482650   ,   -0.09559589000  ],
[   40.75   ,   0.00000000000   ,   0.09341342900   ,   -0.09505563000  ],
[   41.00   ,   0.00000000000   ,   0.09193958850   ,   -0.09446462000  ],
[   41.25   ,   0.00000000000   ,   0.09044357510   ,   -0.09382356000  ],
[   41.50   ,   0.00000000000   ,   0.08892559650   ,   -0.09313338000  ],
[   41.75   ,   0.00000000000   ,   0.08738598040   ,   -0.09239495000  ],
[   42.00   ,   0.00000000000   ,   0.08582541930   ,   -0.09160902000  ],
[   42.25   ,   0.00000000000   ,   0.08424475700   ,   -0.09077637000  ],
[   42.50   ,   0.00000000000   ,   0.08264469890   ,   -0.08989791000  ],
[   42.75   ,   0.00000000000   ,   0.08102599120   ,   -0.08897450000  ],
[   43.00   ,   0.00000000000   ,   0.07938958850   ,   -0.08800694000  ],
[   43.25   ,   0.00000000000   ,   0.07773662330   ,   -0.08699594000  ],
[   43.50   ,   0.00000000000   ,   0.07606806290   ,   -0.08594195000  ],
[   43.75   ,   0.00000000000   ,   0.07438478570   ,   -0.08484526000  ],
[   44.00   ,   0.00000000000   ,   0.07268762970   ,   -0.08370614000  ],
[   44.25   ,   0.00000000000   ,   0.07097740790   ,   -0.08252498000  ],
[   44.50   ,   0.00000000000   ,   0.06925471040   ,   -0.08130224000  ],
[   44.75   ,   0.00000000000   ,   0.06752006750   ,   -0.08003836000  ],
[   45.00   ,   0.00000000000   ,   0.06577409020   ,   -0.07873379000  ],
[   45.25   ,   0.00000000000   ,   0.06401720430   ,   -0.07738903000  ],
[   45.50   ,   0.00000000000   ,   0.06224910840   ,   -0.07600477000  ],
[   45.75   ,   0.00000000000   ,   0.06046939080   ,   -0.07458173000  ],
[   46.00   ,   0.00000000000   ,   0.05867761450   ,   -0.07312067000  ],
[   46.25   ,   0.00000000000   ,   0.05687339530   ,   -0.07162238000  ],
[   46.50   ,   0.00000000000   ,   0.05505643590   ,   -0.07008781000  ],
[   46.75   ,   0.00000000000   ,   0.05322664840   ,   -0.06851791000  ],
[   47.00   ,   0.00000000000   ,   0.05138413440   ,   -0.06691360000  ],
[   47.25   ,   0.00000000000   ,   0.04952906000   ,   -0.06527572000  ],
[   47.50   ,   0.00000000000   ,   0.04766145940   ,   -0.06360524000  ],
[   47.75   ,   0.00000000000   ,   0.04578138870   ,   -0.06190316000  ],
[   48.00   ,   0.00000000000   ,   0.04388900460   ,   -0.06017040000  ],
[   48.25   ,   0.00000000000   ,   0.04198447110   ,   -0.05840778000  ],
[   48.50   ,   0.00000000000   ,   0.04006766300   ,   -0.05661608000  ],
[   48.75   ,   0.00000000000   ,   0.03813838880   ,   -0.05479603000  ],
[   49.00   ,   0.00000000000   ,   0.03619664750   ,   -0.05294829000  ],
[   49.25   ,   0.00000000000   ,   0.03424246070   ,   -0.05107354000  ],
[   49.50   ,   0.00000000000   ,   0.03227565560   ,   -0.04917267000  ],
[   49.75   ,   0.00000000000   ,   0.03029610670   ,   -0.04724654000  ],
[   50.00   ,   0.00000000000   ,   0.02830377590   ,   -0.04529601000  ],
[   50.25   ,   0.00000000000   ,   0.02629866420   ,   -0.04332189000  ],
[   50.50   ,   0.00000000000   ,   0.02428075950   ,   -0.04132496000  ],
[   50.75   ,   0.00000000000   ,   0.02225006360   ,   -0.03930597000  ],
[   51.00   ,   0.00000000000   ,   0.02020664880   ,   -0.03726564000  ],
[   51.25   ,   0.00000000000   ,   0.01815055760   ,   -0.03520459000  ],
[   51.50   ,   0.00000000000   ,   0.01608171490   ,   -0.03312348000  ],
[   51.75   ,   0.00000000000   ,   0.01400001640   ,   -0.03102295000  ],
[   52.00   ,   0.00000000000   ,   0.01190539240   ,   -0.02890361000  ],
[   52.25   ,   0.00000000000   ,   0.00979785830   ,   -0.02676602000  ],
[   52.50   ,   0.00000000000   ,   0.00767735470   ,   -0.02461078000  ],
[   52.75   ,   0.00000000000   ,   0.00554377410   ,   -0.02243851000  ],
[   53.00   ,   0.00000000000   ,   0.00339697980   ,   -0.02024989000  ],
[   53.25   ,   0.00000000000   ,   0.00123684010   ,   -0.01804558000  ],
[   53.50   ,   0.00000000000   ,   -0.00093673320  ,   -0.01582626000  ],
[   53.75   ,   0.00000000000   ,   -0.00312370020  ,   -0.01359251000  ],
[   54.00   ,   0.00000000000   ,   -0.00532397530  ,   -0.01134491000  ],
[   54.25   ,   0.00000000000   ,   -0.00753746780  ,   -0.00908402800  ],
[   54.50   ,   0.00000000000   ,   -0.00976428530  ,   -0.00681053900  ],
[   54.75   ,   0.00000000000   ,   -0.01200450360  ,   -0.00452507700  ],
[   55.00   ,   0.00000000000   ,   -0.01425816220  ,   -0.00222826200  ],
[   55.25   ,   0.00000000000   ,   -0.01652534190  ,   0.00007933491   ],
[   55.50   ,   0.00000000000   ,   -0.01880637410  ,   0.00239719400   ],
[   55.75   ,   0.00000000000   ,   -0.02110159190  ,   0.00472485200   ],
[   56.00   ,   0.00000000000   ,   -0.02341126060  ,   0.00706185900   ],
[   56.25   ,   0.00000000000   ,   -0.02573556740  ,   0.00940773800   ],
[   56.50   ,   0.00000000000   ,   -0.02807465120  ,   0.01176190000   ],
[   56.75   ,   0.00000000000   ,   -0.03042854980  ,   0.01412379000   ],
[   57.00   ,   0.00000000000   ,   -0.03279723920  ,   0.01649290000   ],
[   57.25   ,   0.00000000000   ,   -0.03518066100  ,   0.01886874000   ],
[   57.50   ,   0.00000000000   ,   -0.03757863420  ,   0.02125080000   ],
[   57.75   ,   0.00000000000   ,   -0.03999092430  ,   0.02363856000   ],
[   58.00   ,   0.00000000000   ,   -0.04241729900  ,   0.02603154000   ],
[   58.25   ,   0.00000000000   ,   -0.04485755200  ,   0.02842925000   ],
[   58.50   ,   0.00000000000   ,   -0.04731154420  ,   0.03083120000   ],
[   58.75   ,   0.00000000000   ,   -0.04977914200  ,   0.03323693000   ],
[   59.00   ,   0.00000000000   ,   -0.05226018490  ,   0.03564602000   ],
[   59.25   ,   0.00000000000   ,   -0.05475444530  ,   0.03805808000   ],
[   59.50   ,   0.00000000000   ,   -0.05726160380  ,   0.04047277000   ],
[   59.75   ,   0.00000000000   ,   -0.05978130740  ,   0.04288977000   ],
[   60.00   ,   0.00000000000   ,   -0.06231318520  ,   0.04530877000   ],
[   60.25   ,   0.00000000000   ,   -0.06485688480  ,   0.04772946000   ],
[   60.50   ,   0.00000000000   ,   -0.06741218600  ,   0.05015147000   ],
[   60.75   ,   0.00000000000   ,   -0.06997886410  ,   0.05257439000   ],
[   61.00   ,   0.00000000000   ,   -0.07255666550  ,   0.05499788000   ],
[   61.25   ,   0.00000000000   ,   -0.07514534120  ,   0.05742170000   ],
[   61.50   ,   0.00000000000   ,   -0.07774474970  ,   0.05984565000   ],
[   61.75   ,   0.00000000000   ,   -0.08035474310  ,   0.06226957000   ],
[   62.00   ,   0.00000000000   ,   -0.08297513250  ,   0.06469334000   ],
[   62.25   ,   0.00000000000   ,   -0.08560572750  ,   0.06711687000   ],
[   62.50   ,   0.00000000000   ,   -0.08824644400  ,   0.06954012000   ],
[   62.75   ,   0.00000000000   ,   -0.09089722900  ,   0.07196305000   ],
[   63.00   ,   0.00000000000   ,   -0.09355803120  ,   0.07438566000   ],
[   63.25   ,   0.00000000000   ,   -0.09622881730  ,   0.07680790000   ],
[   63.50   ,   0.00000000000   ,   -0.09890961370  ,   0.07922983000   ],
[   63.75   ,   0.00000000000   ,   -0.10160043270  ,   0.08165148000   ],
[   64.00   ,   0.00000000000   ,   -0.10430125910  ,   0.08407295000   ],
[   64.25   ,   0.00000000000   ,   -0.10701207720  ,   0.08649428000   ],
[   64.50   ,   0.00000000000   ,   -0.10973283350  ,   0.08891555000   ],
[   64.75   ,   0.00000000000   ,   -0.11246346430  ,   0.09133679000   ],
[   65.00   ,   0.00000000000   ,   -0.11520389620  ,   0.09375804000   ],
[   65.25   ,   0.00000000000   ,   -0.11795403610  ,   0.09617934000   ],
[   65.50   ,   0.00000000000   ,   -0.12071377210  ,   0.09860067000   ],
[   65.75   ,   0.00000000000   ,   -0.12348300420  ,   0.10102200000   ],
[   66.00   ,   0.00000000000   ,   -0.12626162380  ,   0.10344340000   ],
[   66.25   ,   0.00000000000   ,   -0.12904948870  ,   0.10586460000   ],
[   66.50   ,   0.00000000000   ,   -0.13184643310  ,   0.10828570000   ],
[   66.75   ,   0.00000000000   ,   -0.13465229610  ,   0.11070640000   ],
[   67.00   ,   0.00000000000   ,   -0.13746692460  ,   0.11312660000   ],
[   67.25   ,   0.00000000000   ,   -0.14029014320  ,   0.11554630000   ],
[   67.50   ,   0.00000000000   ,   -0.14312167620  ,   0.11796510000   ],
[   67.75   ,   0.00000000000   ,   -0.14596122140  ,   0.12038300000   ],
[   68.00   ,   0.00000000000   ,   -0.14880847060  ,   0.12279980000   ],
[   68.25   ,   0.00000000000   ,   -0.15166311320  ,   0.12521540000   ],
[   68.50   ,   0.00000000000   ,   -0.15452484260  ,   0.12762950000   ],
[   68.75   ,   0.00000000000   ,   -0.15739336120  ,   0.13004220000   ],
[   69.00   ,   0.00000000000   ,   -0.16026836840  ,   0.13245320000   ],
[   69.25   ,   0.00000000000   ,   -0.16314956940  ,   0.13486240000   ],
[   69.50   ,   0.00000000000   ,   -0.16603668520  ,   0.13726970000   ],
[   69.75   ,   0.00000000000   ,   -0.16892943680  ,   0.13967480000   ],
[   70.00   ,   0.00000000000   ,   -0.17182755720  ,   0.14207750000   ],
[   70.25   ,   0.00000000000   ,   -0.17473079150  ,   0.14447780000   ],
[   70.50   ,   0.00000000000   ,   -0.17763888110  ,   0.14687540000   ],
[   70.75   ,   0.00000000000   ,   -0.18055155740  ,   0.14927030000   ],
[   71.00   ,   0.00000000000   ,   -0.18346853350  ,   0.15166230000   ],
[   71.25   ,   0.00000000000   ,   -0.18638950060  ,   0.15405150000   ],
[   71.50   ,   0.00000000000   ,   -0.18931413560  ,   0.15643760000   ],
[   71.75   ,   0.00000000000   ,   -0.19224212160  ,   0.15882060000   ],
[   72.00   ,   0.00000000000   ,   -0.19517314340  ,   0.16120030000   ],
[   72.25   ,   0.00000000000   ,   -0.19810688340  ,   0.16357660000   ],
[   72.50   ,   0.00000000000   ,   -0.20104302250  ,   0.16594930000   ],
[   72.75   ,   0.00000000000   ,   -0.20398124570  ,   0.16831830000   ],
[   73.00   ,   0.00000000000   ,   -0.20692124220  ,   0.17068350000   ],
[   73.25   ,   0.00000000000   ,   -0.20986270920  ,   0.17304460000   ],
[   73.50   ,   0.00000000000   ,   -0.21280533650  ,   0.17540160000   ],
[   73.75   ,   0.00000000000   ,   -0.21574880910  ,   0.17775440000   ],
[   74.00   ,   0.00000000000   ,   -0.21869281190  ,   0.18010270000   ],
[   74.25   ,   0.00000000000   ,   -0.22163702820  ,   0.18244650000   ],
[   74.50   ,   0.00000000000   ,   -0.22458116220  ,   0.18478580000   ],
[   74.75   ,   0.00000000000   ,   -0.22752491980  ,   0.18712030000   ],
[   75.00   ,   0.00000000000   ,   -0.23046800540  ,   0.18945020000   ],
[   75.25   ,   0.00000000000   ,   -0.23341012450  ,   0.19177510000   ],
[   75.50   ,   0.00000000000   ,   -0.23635096870  ,   0.19409520000   ],
[   75.75   ,   0.00000000000   ,   -0.23929022170  ,   0.19641040000   ],
[   76.00   ,   0.00000000000   ,   -0.24222757110  ,   0.19872070000   ],
[   76.25   ,   0.00000000000   ,   -0.24516270790  ,   0.20102600000   ],
[   76.50   ,   0.00000000000   ,   -0.24809531980  ,   0.20332630000   ],
[   76.75   ,   0.00000000000   ,   -0.25102510020  ,   0.20562160000   ],
[   77.00   ,   0.00000000000   ,   -0.25395174200  ,   0.20791180000   ],
[   77.25   ,   0.00000000000   ,   -0.25687494580  ,   0.21019700000   ],
[   77.50   ,   0.00000000000   ,   -0.25979444670  ,   0.21247710000   ],
[   77.75   ,   0.00000000000   ,   -0.26270999510  ,   0.21475210000   ],
[   78.00   ,   0.00000000000   ,   -0.26562135160  ,   0.21702190000   ],
[   78.25   ,   0.00000000000   ,   -0.26852827640  ,   0.21928650000   ],
[   78.50   ,   0.00000000000   ,   -0.27143051120  ,   0.22154580000   ],
[   78.75   ,   0.00000000000   ,   -0.27432780130  ,   0.22379980000   ],
[   79.00   ,   0.00000000000   ,   -0.27721990240  ,   0.22604840000   ],
[   79.25   ,   0.00000000000   ,   -0.28010657470  ,   0.22829180000   ],
[   79.50   ,   0.00000000000   ,   -0.28298757930  ,   0.23052980000   ],
[   79.75   ,   0.00000000000   ,   -0.28586268320  ,   0.23276240000   ],
[   80.00   ,   0.00000000000   ,   -0.28873166070  ,   0.23498970000   ],
[   80.25   ,   0.00000000000   ,   -0.29159430510  ,   0.23721170000   ],
[   80.50   ,   0.00000000000   ,   -0.29445047470  ,   0.23942840000   ],
[   80.75   ,   0.00000000000   ,   -0.29730004600  ,   0.24163970000   ],
[   81.00   ,   0.00000000000   ,   -0.30014290040  ,   0.24384570000   ],
[   81.25   ,   0.00000000000   ,   -0.30297892740  ,   0.24604630000   ],
[   81.50   ,   0.00000000000   ,   -0.30580803390  ,   0.24824160000   ],
[   81.75   ,   0.00000000000   ,   -0.30863012760  ,   0.25043150000   ],
[   82.00   ,   0.00000000000   ,   -0.31144511680  ,   0.25261610000   ],
[   82.25   ,   0.00000000000   ,   -0.31425291630  ,   0.25479530000   ],
[   82.50   ,   0.00000000000   ,   -0.31705346200  ,   0.25696900000   ],
[   82.75   ,   0.00000000000   ,   -0.31984669800  ,   0.25913740000   ],
[   83.00   ,   0.00000000000   ,   -0.32263256920  ,   0.26130030000   ],
[   83.25   ,   0.00000000000   ,   -0.32541102660  ,   0.26345780000   ],
[   83.50   ,   0.00000000000   ,   -0.32818204360  ,   0.26560990000   ],
[   83.75   ,   0.00000000000   ,   -0.33094559980  ,   0.26775650000   ],
[   84.00   ,   0.00000000000   ,   -0.33370167460  ,   0.26989770000   ],
[   84.25   ,   0.00000000000   ,   -0.33645024910  ,   0.27203330000   ],
[   84.50   ,   0.00000000000   ,   -0.33919130660  ,   0.27416350000   ],
[   84.75   ,   0.00000000000   ,   -0.34192482990  ,   0.27628820000   ],
[   85.00   ,   0.00000000000   ,   -0.34465080150  ,   0.27840730000   ],
[   85.25   ,   0.00000000000   ,   -0.34736920940  ,   0.28052090000   ],
[   85.50   ,   0.00000000000   ,   -0.35008006070  ,   0.28262900000   ],
[   85.75   ,   0.00000000000   ,   -0.35278336720  ,   0.28473140000   ],
[   86.00   ,   0.00000000000   ,   -0.35547914210  ,   0.28682830000   ],
[   86.25   ,   0.00000000000   ,   -0.35816740130  ,   0.28891960000   ],
[   86.50   ,   0.00000000000   ,   -0.36084816900  ,   0.29100530000   ],
[   86.75   ,   0.00000000000   ,   -0.36352147130  ,   0.29308540000   ],
[   87.00   ,   0.00000000000   ,   -0.36618733380  ,   0.29515990000   ],
[   87.25   ,   0.00000000000   ,   -0.36884578190  ,   0.29722880000   ],
[   87.50   ,   0.00000000000   ,   -0.37149684240  ,   0.29929210000   ],
[   87.75   ,   0.00000000000   ,   -0.37414054130  ,   0.30134980000   ],
[   88.00   ,   0.00000000000   ,   -0.37677690380  ,   0.30340180000   ],
[   88.25   ,   0.00000000000   ,   -0.37940595750  ,   0.30544820000   ],
[   88.50   ,   0.00000000000   ,   -0.38202773980  ,   0.30748900000   ],
[   88.75   ,   0.00000000000   ,   -0.38464229040  ,   0.30952410000   ],
[   89.00   ,   0.00000000000   ,   -0.38724964750  ,   0.31155360000   ],
[   89.25   ,   0.00000000000   ,   -0.38984984390  ,   0.31357750000   ],
[   89.50   ,   0.00000000000   ,   -0.39244290940  ,   0.31559570000   ],
[   89.75   ,   0.00000000000   ,   -0.39502887060  ,   0.31760840000   ],
[   90.00   ,   0.00000000000   ,   -0.39760775220  ,   0.31961550000   ],
[   90.25   ,   0.00000000000   ,   -0.40017957880  ,   0.32161710000   ],
[   90.50   ,   0.00000000000   ,   -0.40274438030  ,   0.32361310000   ],
[   90.75   ,   0.00000000000   ,   -0.40530218740  ,   0.32560360000   ],
[   91.00   ,   0.00000000000   ,   -0.40785303110  ,   0.32758870000   ],
[   91.25   ,   0.00000000000   ,   -0.41039694150  ,   0.32956830000   ],
[   91.50   ,   0.00000000000   ,   -0.41293394790  ,   0.33154240000   ],
[   91.75   ,   0.00000000000   ,   -0.41546407900  ,   0.33351120000   ],
[   92.00   ,   0.00000000000   ,   -0.41798736340  ,   0.33547470000   ],
[   92.25   ,   0.00000000000   ,   -0.42050383040  ,   0.33743280000   ],
[   92.50   ,   0.00000000000   ,   -0.42301351240  ,   0.33938560000   ],
[   92.75   ,   0.00000000000   ,   -0.42551644240  ,   0.34133320000   ],
[   93.00   ,   0.00000000000   ,   -0.42801265320  ,   0.34327540000   ],
[   93.25   ,   0.00000000000   ,   -0.43050217740  ,   0.34521250000   ],
[   93.50   ,   0.00000000000   ,   -0.43298504710  ,   0.34714440000   ],
[   93.75   ,   0.00000000000   ,   -0.43546129450  ,   0.34907110000   ],
[   94.00   ,   0.00000000000   ,   -0.43793095140  ,   0.35099270000   ],
[   94.25   ,   0.00000000000   ,   -0.44039404970  ,   0.35290920000   ],
[   94.50   ,   0.00000000000   ,   -0.44285062380  ,   0.35482060000   ],
[   94.75   ,   0.00000000000   ,   -0.44530070780  ,   0.35672700000   ],
[   95.00   ,   0.00000000000   ,   -0.44774433580  ,   0.35862830000   ]];

GLIPredData.FEV1.female = {};
GLIPredData.FEV1.female.a = [-9.6987,  2.1211,  -0.0270,  -0.1484,  -0.0149,  -0.1208,  -0.0708];
GLIPredData.FEV1.female.p = [-2.3765,  0.0972,  0.1016,  -0.0109,  0.0733,  0.0114];
GLIPredData.FEV1.female.q = [1.154,0];
GLIPredData.FEV1.female.maxAge = 95;
GLIPredData.FEV1.female.spline = 
[[  3.00    ,   0.00000000000   ,   -0.23110816820  ,   0.33514843750   ],
[   3.25    ,   0.00000000000   ,   -0.21699508630  ,   0.30976441550   ],
[   3.50    ,   0.00000000000   ,   -0.20400862150  ,   0.28620590330   ],
[   3.75    ,   0.00000000000   ,   -0.19222417640  ,   0.26428055970   ],
[   4.00    ,   0.00000000000   ,   -0.18174849650  ,   0.24386744730   ],
[   4.25    ,   0.00000000000   ,   -0.17266696020  ,   0.22484637620   ],
[   4.50    ,   0.00000000000   ,   -0.16512925740  ,   0.20708457910   ],
[   4.75    ,   0.00000000000   ,   -0.15919607820  ,   0.19046387110   ],
[   5.00    ,   0.00000000000   ,   -0.15484156270  ,   0.17487379250   ],
[   5.25    ,   0.00000000000   ,   -0.15175755730  ,   0.16020535270   ],
[   5.50    ,   0.00000000000   ,   -0.14943090120  ,   0.14635882300   ],
[   5.75    ,   0.00000000000   ,   -0.14739041110  ,   0.13325472860   ],
[   6.00    ,   0.00000000000   ,   -0.14519720630  ,   0.12084915090   ],
[   6.25    ,   0.00000000000   ,   -0.14255249000  ,   0.10911668100   ],
[   6.50    ,   0.00000000000   ,   -0.13929694480  ,   0.09805301350   ],
[   6.75    ,   0.00000000000   ,   -0.13540795430  ,   0.08767771800   ],
[   7.00    ,   0.00000000000   ,   -0.13103951260  ,   0.07799055590   ],
[   7.25    ,   0.00000000000   ,   -0.12640665790  ,   0.06895115260   ],
[   7.50    ,   0.00000000000   ,   -0.12171954150  ,   0.06050314780   ],
[   7.75    ,   0.00000000000   ,   -0.11710836040  ,   0.05259392700   ],
[   8.00    ,   0.00000000000   ,   -0.11247549570  ,   0.04519221300   ],
[   8.25    ,   0.00000000000   ,   -0.10758716900  ,   0.03828435430   ],
[   8.50    ,   0.00000000000   ,   -0.10223420410  ,   0.03187329890   ],
[   8.75    ,   0.00000000000   ,   -0.09626407180  ,   0.02596250060   ],
[   9.00    ,   0.00000000000   ,   -0.08968072610  ,   0.02056075760   ],
[   9.25    ,   0.00000000000   ,   -0.08248602640  ,   0.01566108620   ],
[   9.50    ,   0.00000000000   ,   -0.07468400700  ,   0.01123665850   ],
[   9.75    ,   0.00000000000   ,   -0.06629568850  ,   0.00724756310   ],
[   10.00   ,   0.00000000000   ,   -0.05734767360  ,   0.00365496570   ],
[   10.25   ,   0.00000000000   ,   -0.04790232680  ,   0.00041671120   ],
[   10.50   ,   0.00000000000   ,   -0.03803266260  ,   -0.00251251820  ],
[   10.75   ,   0.00000000000   ,   -0.02779287840  ,   -0.00517945320  ],
[   11.00   ,   0.00000000000   ,   -0.01720834990  ,   -0.00762525640  ],
[   11.25   ,   0.00000000000   ,   -0.00631322890  ,   -0.00988867970  ],
[   11.50   ,   0.00000000000   ,   0.00481544440   ,   -0.01200881210  ],
[   11.75   ,   0.00000000000   ,   0.01607148580   ,   -0.01401940720  ],
[   12.00   ,   0.00000000000   ,   0.02735330220   ,   -0.01595145720  ],
[   12.25   ,   0.00000000000   ,   0.03856595720   ,   -0.01783341270  ],
[   12.50   ,   0.00000000000   ,   0.04962069500   ,   -0.01968670550  ],
[   12.75   ,   0.00000000000   ,   0.06043309350   ,   -0.02152178460  ],
[   13.00   ,   0.00000000000   ,   0.07092813160   ,   -0.02334203760  ],
[   13.25   ,   0.00000000000   ,   0.08104555610   ,   -0.02514791880  ],
[   13.50   ,   0.00000000000   ,   0.09072768040   ,   -0.02693776650  ],
[   13.75   ,   0.00000000000   ,   0.09993169440   ,   -0.02871044190  ],
[   14.00   ,   0.00000000000   ,   0.10862881530   ,   -0.03046652110  ],
[   14.25   ,   0.00000000000   ,   0.11679797450   ,   -0.03220639690  ],
[   14.50   ,   0.00000000000   ,   0.12441258380   ,   -0.03392799290  ],
[   14.75   ,   0.00000000000   ,   0.13145696540   ,   -0.03562854780  ],
[   15.00   ,   0.00000000000   ,   0.13792930880   ,   -0.03730508810  ],
[   15.25   ,   0.00000000000   ,   0.14383380600   ,   -0.03895472540  ],
[   15.50   ,   0.00000000000   ,   0.14917773680   ,   -0.04057500350  ],
[   15.75   ,   0.00000000000   ,   0.15397869310   ,   -0.04216364800  ],
[   16.00   ,   0.00000000000   ,   0.15826797610   ,   -0.04371712910  ],
[   16.25   ,   0.00000000000   ,   0.16208115140   ,   -0.04523225980  ],
[   16.50   ,   0.00000000000   ,   0.16546140310   ,   -0.04670614680  ],
[   16.75   ,   0.00000000000   ,   0.16844672140   ,   -0.04813657430  ],
[   17.00   ,   0.00000000000   ,   0.17106607470   ,   -0.04952235080  ],
[   17.25   ,   0.00000000000   ,   0.17334923230   ,   -0.05086265250  ],
[   17.50   ,   0.00000000000   ,   0.17532856530   ,   -0.05215714930  ],
[   17.75   ,   0.00000000000   ,   0.17703327930   ,   -0.05340561590  ],
[   18.00   ,   0.00000000000   ,   0.17849047430   ,   -0.05460763520  ],
[   18.25   ,   0.00000000000   ,   0.17972486760   ,   -0.05576329460  ],
[   18.50   ,   0.00000000000   ,   0.18076101950   ,   -0.05687410450  ],
[   18.75   ,   0.00000000000   ,   0.18162077230   ,   -0.05794230360  ],
[   19.00   ,   0.00000000000   ,   0.18232340580   ,   -0.05897031260  ],
[   19.25   ,   0.00000000000   ,   0.18288677370   ,   -0.05996040740  ],
[   19.50   ,   0.00000000000   ,   0.18332827840   ,   -0.06091477130  ],
[   19.75   ,   0.00000000000   ,   0.18366344470   ,   -0.06183583280  ],
[   20.00   ,   0.00000000000   ,   0.18390584760   ,   -0.06272629800  ],
[   20.25   ,   0.00000000000   ,   0.18406689310   ,   -0.06358918740  ],
[   20.50   ,   0.00000000000   ,   0.18415143560   ,   -0.06442827020  ],
[   20.75   ,   0.00000000000   ,   0.18416197180   ,   -0.06524715710  ],
[   21.00   ,   0.00000000000   ,   0.18410076890   ,   -0.06604928120  ],
[   21.25   ,   0.00000000000   ,   0.18397057520   ,   -0.06683783410  ],
[   21.50   ,   0.00000000000   ,   0.18377237810   ,   -0.06761545890  ],
[   21.75   ,   0.00000000000   ,   0.18350785470   ,   -0.06838459590  ],
[   22.00   ,   0.00000000000   ,   0.18317928400   ,   -0.06914731900  ],
[   22.25   ,   0.00000000000   ,   0.18278845790   ,   -0.06990541240  ],
[   22.50   ,   0.00000000000   ,   0.18233587070   ,   -0.07065969000  ],
[   22.75   ,   0.00000000000   ,   0.18182242870   ,   -0.07141019670  ],
[   23.00   ,   0.00000000000   ,   0.18124981590   ,   -0.07215688730  ],
[   23.25   ,   0.00000000000   ,   0.18062132680   ,   -0.07289986230  ],
[   23.50   ,   0.00000000000   ,   0.17994367050   ,   -0.07363943660  ],
[   23.75   ,   0.00000000000   ,   0.17922490360   ,   -0.07437579960  ],
[   24.00   ,   0.00000000000   ,   0.17847365110   ,   -0.07510900590  ],
[   24.25   ,   0.00000000000   ,   0.17769821440   ,   -0.07583902050  ],
[   24.50   ,   0.00000000000   ,   0.17690460900   ,   -0.07656556490  ],
[   24.75   ,   0.00000000000   ,   0.17609866670   ,   -0.07728834540  ],
[   25.00   ,   0.00000000000   ,   0.17528653920   ,   -0.07800691520  ],
[   25.25   ,   0.00000000000   ,   0.17447334700   ,   -0.07872089640  ],
[   25.50   ,   0.00000000000   ,   0.17366274960   ,   -0.07943003950  ],
[   25.75   ,   0.00000000000   ,   0.17285798950   ,   -0.08013425630  ],
[   26.00   ,   0.00000000000   ,   0.17206207030   ,   -0.08083356030  ],
[   26.25   ,   0.00000000000   ,   0.17127648960   ,   -0.08152795330  ],
[   26.50   ,   0.00000000000   ,   0.17049812450   ,   -0.08221699640  ],
[   26.75   ,   0.00000000000   ,   0.16972407470   ,   -0.08290007520  ],
[   27.00   ,   0.00000000000   ,   0.16895250050   ,   -0.08357659680  ],
[   27.25   ,   0.00000000000   ,   0.16818127510   ,   -0.08424609580  ],
[   27.50   ,   0.00000000000   ,   0.16740656410   ,   -0.08490804620  ],
[   27.75   ,   0.00000000000   ,   0.16662485630   ,   -0.08556183160  ],
[   28.00   ,   0.00000000000   ,   0.16583359810   ,   -0.08620678070  ],
[   28.25   ,   0.00000000000   ,   0.16503138160   ,   -0.08684200960  ],
[   28.50   ,   0.00000000000   ,   0.16421675680   ,   -0.08746638810  ],
[   28.75   ,   0.00000000000   ,   0.16338860460   ,   -0.08807875260  ],
[   29.00   ,   0.00000000000   ,   0.16254643090   ,   -0.08867799650  ],
[   29.25   ,   0.00000000000   ,   0.16168995270   ,   -0.08926310490  ],
[   29.50   ,   0.00000000000   ,   0.16081839260   ,   -0.08983301740  ],
[   29.75   ,   0.00000000000   ,   0.15993146280   ,   -0.09038669520  ],
[   30.00   ,   0.00000000000   ,   0.15902947300   ,   -0.09092321350  ],
[   30.25   ,   0.00000000000   ,   0.15811298280   ,   -0.09144166690  ],
[   30.50   ,   0.00000000000   ,   0.15718142810   ,   -0.09194109130  ],
[   30.75   ,   0.00000000000   ,   0.15623389730   ,   -0.09242054810  ],
[   31.00   ,   0.00000000000   ,   0.15526964180   ,   -0.09287917890  ],
[   31.25   ,   0.00000000000   ,   0.15428758020   ,   -0.09331614940  ],
[   31.50   ,   0.00000000000   ,   0.15328531640   ,   -0.09373042180  ],
[   31.75   ,   0.00000000000   ,   0.15226070030   ,   -0.09412093240  ],
[   32.00   ,   0.00000000000   ,   0.15121174980   ,   -0.09448670200  ],
[   32.25   ,   0.00000000000   ,   0.15013671830   ,   -0.09482682860  ],
[   32.50   ,   0.00000000000   ,   0.14903469020   ,   -0.09514043870  ],
[   32.75   ,   0.00000000000   ,   0.14790544470   ,   -0.09542670840  ],
[   33.00   ,   0.00000000000   ,   0.14674925410   ,   -0.09568485290  ],
[   33.25   ,   0.00000000000   ,   0.14556622460   ,   -0.09591413920  ],
[   33.50   ,   0.00000000000   ,   0.14435557910   ,   -0.09611405840  ],
[   33.75   ,   0.00000000000   ,   0.14311662810   ,   -0.09628423710  ],
[   34.00   ,   0.00000000000   ,   0.14184943650   ,   -0.09642426400  ],
[   34.25   ,   0.00000000000   ,   0.14055474610   ,   -0.09653362450  ],
[   34.50   ,   0.00000000000   ,   0.13923260030   ,   -0.09661164110  ],
[   34.75   ,   0.00000000000   ,   0.13788347710   ,   -0.09665754600  ],
[   35.00   ,   0.00000000000   ,   0.13650802640   ,   -0.09667059570  ],
[   35.25   ,   0.00000000000   ,   0.13510697840   ,   -0.09665005570  ],
[   35.50   ,   0.00000000000   ,   0.13368047160   ,   -0.09659505580  ],
[   35.75   ,   0.00000000000   ,   0.13222830690   ,   -0.09650477390  ],
[   36.00   ,   0.00000000000   ,   0.13075017910   ,   -0.09637849020  ],
[   36.25   ,   0.00000000000   ,   0.12924600410   ,   -0.09621559940  ],
[   36.50   ,   0.00000000000   ,   0.12771643230   ,   -0.09601559960  ],
[   36.75   ,   0.00000000000   ,   0.12616222930   ,   -0.09577802620  ],
[   37.00   ,   0.00000000000   ,   0.12458410630   ,   -0.09550245230  ],
[   37.25   ,   0.00000000000   ,   0.12298287170   ,   -0.09518854500  ],
[   37.50   ,   0.00000000000   ,   0.12135947060   ,   -0.09483620850  ],
[   37.75   ,   0.00000000000   ,   0.11971475630   ,   -0.09444541500  ],
[   38.00   ,   0.00000000000   ,   0.11804937580   ,   -0.09401612300  ],
[   38.25   ,   0.00000000000   ,   0.11636360940   ,   -0.09354832180  ],
[   38.50   ,   0.00000000000   ,   0.11465756320   ,   -0.09304217910  ],
[   38.75   ,   0.00000000000   ,   0.11293153260   ,   -0.09249788710  ],
[   39.00   ,   0.00000000000   ,   0.11118604100   ,   -0.09191564370  ],
[   39.25   ,   0.00000000000   ,   0.10942171810   ,   -0.09129567420  ],
[   39.50   ,   0.00000000000   ,   0.10763904930   ,   -0.09063820920  ],
[   39.75   ,   0.00000000000   ,   0.10583853930   ,   -0.08994350010  ],
[   40.00   ,   0.00000000000   ,   0.10402080720   ,   -0.08921182050  ],
[   40.25   ,   0.00000000000   ,   0.10218636900   ,   -0.08844343770  ],
[   40.50   ,   0.00000000000   ,   0.10033541250   ,   -0.08763855630  ],
[   40.75   ,   0.00000000000   ,   0.09846833250   ,   -0.08679735740  ],
[   41.00   ,   0.00000000000   ,   0.09658581540   ,   -0.08592003780  ],
[   41.25   ,   0.00000000000   ,   0.09468859420   ,   -0.08500689130  ],
[   41.50   ,   0.00000000000   ,   0.09277715030   ,   -0.08405851450  ],
[   41.75   ,   0.00000000000   ,   0.09085197230   ,   -0.08307554140  ],
[   42.00   ,   0.00000000000   ,   0.08891390120   ,   -0.08205853070  ],
[   42.25   ,   0.00000000000   ,   0.08696392240   ,   -0.08100806130  ],
[   42.50   ,   0.00000000000   ,   0.08500292260   ,   -0.07992480950  ],
[   42.75   ,   0.00000000000   ,   0.08303178010   ,   -0.07880944580  ],
[   43.00   ,   0.00000000000   ,   0.08105154490   ,   -0.07766261220  ],
[   43.25   ,   0.00000000000   ,   0.07906344020   ,   -0.07648487040  ],
[   43.50   ,   0.00000000000   ,   0.07706845570   ,   -0.07527661900  ],
[   43.75   ,   0.00000000000   ,   0.07506732650   ,   -0.07403816900  ],
[   44.00   ,   0.00000000000   ,   0.07306064650   ,   -0.07276980430  ],
[   44.25   ,   0.00000000000   ,   0.07104899350   ,   -0.07147183560  ],
[   44.50   ,   0.00000000000   ,   0.06903274690   ,   -0.07014467360  ],
[   44.75   ,   0.00000000000   ,   0.06701220750   ,   -0.06878871220  ],
[   45.00   ,   0.00000000000   ,   0.06498778570   ,   -0.06740433780  ],
[   45.25   ,   0.00000000000   ,   0.06295973380   ,   -0.06599195420  ],
[   45.50   ,   0.00000000000   ,   0.06092760970   ,   -0.06455199860  ],
[   45.75   ,   0.00000000000   ,   0.05889080690   ,   -0.06308491830  ],
[   46.00   ,   0.00000000000   ,   0.05684854800   ,   -0.06159117440  ],
[   46.25   ,   0.00000000000   ,   0.05480006180   ,   -0.06007127020  ],
[   46.50   ,   0.00000000000   ,   0.05274487490   ,   -0.05852585050  ],
[   46.75   ,   0.00000000000   ,   0.05068279330   ,   -0.05695555910  ],
[   47.00   ,   0.00000000000   ,   0.04861386340   ,   -0.05536100940  ],
[   47.25   ,   0.00000000000   ,   0.04653823900   ,   -0.05374278620  ],
[   47.50   ,   0.00000000000   ,   0.04445582680   ,   -0.05210152820  ],
[   47.75   ,   0.00000000000   ,   0.04236645640   ,   -0.05043788240  ],
[   48.00   ,   0.00000000000   ,   0.04027002310   ,   -0.04875244880  ],
[   48.25   ,   0.00000000000   ,   0.03816637760   ,   -0.04704577390  ],
[   48.50   ,   0.00000000000   ,   0.03605497900   ,   -0.04531838360  ],
[   48.75   ,   0.00000000000   ,   0.03393521580   ,   -0.04357075940  ],
[   49.00   ,   0.00000000000   ,   0.03180670020   ,   -0.04180334370  ],
[   49.25   ,   0.00000000000   ,   0.02966907870   ,   -0.04001658410  ],
[   49.50   ,   0.00000000000   ,   0.02752185260   ,   -0.03821098810  ],
[   49.75   ,   0.00000000000   ,   0.02536453580   ,   -0.03638705560  ],
[   50.00   ,   0.00000000000   ,   0.02319663590   ,   -0.03454528850  ],
[   50.25   ,   0.00000000000   ,   0.02101768720   ,   -0.03268616180  ],
[   50.50   ,   0.00000000000   ,   0.01882741510   ,   -0.03081010080  ],
[   50.75   ,   0.00000000000   ,   0.01662559430   ,   -0.02891749280  ],
[   51.00   ,   0.00000000000   ,   0.01441204690   ,   -0.02700870100  ],
[   51.25   ,   0.00000000000   ,   0.01218656110   ,   -0.02508407240  ],
[   51.50   ,   0.00000000000   ,   0.00994890760   ,   -0.02314399940  ],
[   51.75   ,   0.00000000000   ,   0.00769880810   ,   -0.02118889510  ],
[   52.00   ,   0.00000000000   ,   0.00543600640   ,   -0.01921915470  ],
[   52.25   ,   0.00000000000   ,   0.00316036860   ,   -0.01723515340  ],
[   52.50   ,   0.00000000000   ,   0.00087186060   ,   -0.01523729320  ],
[   52.75   ,   0.00000000000   ,   -0.00142965170  ,   -0.01322599540  ],
[   53.00   ,   0.00000000000   ,   -0.00374437830  ,   -0.01120169060  ],
[   53.25   ,   0.00000000000   ,   -0.00607248030  ,   -0.00916479910  ],
[   53.50   ,   0.00000000000   ,   -0.00841392360  ,   -0.00711576820  ],
[   53.75   ,   0.00000000000   ,   -0.01076853650  ,   -0.00505502510  ],
[   54.00   ,   0.00000000000   ,   -0.01313618130  ,   -0.00298298280  ],
[   54.25   ,   0.00000000000   ,   -0.01551676730  ,   -0.00090006620  ],
[   54.50   ,   0.00000000000   ,   -0.01791037290  ,   0.00119325060   ],
[   54.75   ,   0.00000000000   ,   -0.02031703790  ,   0.00329650730   ],
[   55.00   ,   0.00000000000   ,   -0.02273680510  ,   0.00540925510   ],
[   55.25   ,   0.00000000000   ,   -0.02516975860  ,   0.00753108520   ],
[   55.50   ,   0.00000000000   ,   -0.02761618650  ,   0.00966164830   ],
[   55.75   ,   0.00000000000   ,   -0.03007637630  ,   0.01180062800   ],
[   56.00   ,   0.00000000000   ,   -0.03255059850  ,   0.01394771410   ],
[   56.25   ,   0.00000000000   ,   -0.03503907260  ,   0.01610260710   ],
[   56.50   ,   0.00000000000   ,   -0.03754188780  ,   0.01826501370   ],
[   56.75   ,   0.00000000000   ,   -0.04005906000  ,   0.02043465220   ],
[   57.00   ,   0.00000000000   ,   -0.04259061440  ,   0.02261125370   ],
[   57.25   ,   0.00000000000   ,   -0.04513658170  ,   0.02479454440   ],
[   57.50   ,   0.00000000000   ,   -0.04769684110  ,   0.02698419880   ],
[   57.75   ,   0.00000000000   ,   -0.05027125040  ,   0.02917987370   ],
[   58.00   ,   0.00000000000   ,   -0.05285970650  ,   0.03138122870   ],
[   58.25   ,   0.00000000000   ,   -0.05546213720  ,   0.03358792910   ],
[   58.50   ,   0.00000000000   ,   -0.05807849030  ,   0.03579962680   ],
[   58.75   ,   0.00000000000   ,   -0.06070876010  ,   0.03801598560   ],
[   59.00   ,   0.00000000000   ,   -0.06335295990  ,   0.04023669520   ],
[   59.25   ,   0.00000000000   ,   -0.06601104230  ,   0.04246146730   ],
[   59.50   ,   0.00000000000   ,   -0.06868285270  ,   0.04469002220   ],
[   59.75   ,   0.00000000000   ,   -0.07136826400  ,   0.04692208450   ],
[   60.00   ,   0.00000000000   ,   -0.07406719460  ,   0.04915738190   ],
[   60.25   ,   0.00000000000   ,   -0.07677956420  ,   0.05139564880   ],
[   60.50   ,   0.00000000000   ,   -0.07950523250  ,   0.05363659700   ],
[   60.75   ,   0.00000000000   ,   -0.08224404310  ,   0.05587994030   ],
[   61.00   ,   0.00000000000   ,   -0.08499584060  ,   0.05812542060   ],
[   61.25   ,   0.00000000000   ,   -0.08776049650  ,   0.06037281070   ],
[   61.50   ,   0.00000000000   ,   -0.09053800790  ,   0.06262190230   ],
[   61.75   ,   0.00000000000   ,   -0.09332841650  ,   0.06487249120   ],
[   62.00   ,   0.00000000000   ,   -0.09613177330  ,   0.06712438180   ],
[   62.25   ,   0.00000000000   ,   -0.09894811460  ,   0.06937739070   ],
[   62.50   ,   0.00000000000   ,   -0.10177744130  ,   0.07163134940   ],
[   62.75   ,   0.00000000000   ,   -0.10461975250  ,   0.07388610500   ],
[   63.00   ,   0.00000000000   ,   -0.10747504640  ,   0.07614150790   ],
[   63.25   ,   0.00000000000   ,   -0.11034334480  ,   0.07839741050   ],
[   63.50   ,   0.00000000000   ,   -0.11322471640  ,   0.08065366420   ],
[   63.75   ,   0.00000000000   ,   -0.11611922510  ,   0.08291012240   ],
[   64.00   ,   0.00000000000   ,   -0.11902693540  ,   0.08516664610   ],
[   64.25   ,   0.00000000000   ,   -0.12194793570  ,   0.08742309700   ],
[   64.50   ,   0.00000000000   ,   -0.12488228490  ,   0.08967932260   ],
[   64.75   ,   0.00000000000   ,   -0.12783004410  ,   0.09193517480   ],
[   65.00   ,   0.00000000000   ,   -0.13079129040  ,   0.09419050830   ],
[   65.25   ,   0.00000000000   ,   -0.13376608100  ,   0.09644517610   ],
[   65.50   ,   0.00000000000   ,   -0.13675437210  ,   0.09869902100   ],
[   65.75   ,   0.00000000000   ,   -0.13975612350  ,   0.10095188280   ],
[   66.00   ,   0.00000000000   ,   -0.14277131300  ,   0.10320360310   ],
[   66.25   ,   0.00000000000   ,   -0.14579987560  ,   0.10545402000   ],
[   66.50   ,   0.00000000000   ,   -0.14884165110  ,   0.10770294160   ],
[   66.75   ,   0.00000000000   ,   -0.15189648160  ,   0.10995016740   ],
[   67.00   ,   0.00000000000   ,   -0.15496423500  ,   0.11219549710   ],
[   67.25   ,   0.00000000000   ,   -0.15804473650  ,   0.11443873360   ],
[   67.50   ,   0.00000000000   ,   -0.16113758780  ,   0.11667968650   ],
[   67.75   ,   0.00000000000   ,   -0.16424234310  ,   0.11891816900   ],
[   68.00   ,   0.00000000000   ,   -0.16735856660  ,   0.12115399690   ],
[   68.25   ,   0.00000000000   ,   -0.17048582890  ,   0.12338698890   ],
[   68.50   ,   0.00000000000   ,   -0.17362365300  ,   0.12561698100   ],
[   68.75   ,   0.00000000000   ,   -0.17677157370  ,   0.12784382100   ],
[   69.00   ,   0.00000000000   ,   -0.17992913850  ,   0.13006736210   ],
[   69.25   ,   0.00000000000   ,   -0.18309592000  ,   0.13228745700   ],
[   69.50   ,   0.00000000000   ,   -0.18627153780  ,   0.13450394230   ],
[   69.75   ,   0.00000000000   ,   -0.18945562370  ,   0.13671665390   ],
[   70.00   ,   0.00000000000   ,   -0.19264782790  ,   0.13892543210   ],
[   70.25   ,   0.00000000000   ,   -0.19584781260  ,   0.14113013140   ],
[   70.50   ,   0.00000000000   ,   -0.19905521380  ,   0.14333064280   ],
[   70.75   ,   0.00000000000   ,   -0.20226965450  ,   0.14552686510   ],
[   71.00   ,   0.00000000000   ,   -0.20549074030  ,   0.14771870290   ],
[   71.25   ,   0.00000000000   ,   -0.20871806150  ,   0.14990606490   ],
[   71.50   ,   0.00000000000   ,   -0.21195122600  ,   0.15208884800   ],
[   71.75   ,   0.00000000000   ,   -0.21518986200  ,   0.15426695140   ],
[   72.00   ,   0.00000000000   ,   -0.21843360890  ,   0.15644027670   ],
[   72.25   ,   0.00000000000   ,   -0.22168210640  ,   0.15860872500   ],
[   72.50   ,   0.00000000000   ,   -0.22493496820  ,   0.16077219440   ],
[   72.75   ,   0.00000000000   ,   -0.22819180960  ,   0.16293058340   ],
[   73.00   ,   0.00000000000   ,   -0.23145225250  ,   0.16508379050   ],
[   73.25   ,   0.00000000000   ,   -0.23471592340  ,   0.16723171670   ],
[   73.50   ,   0.00000000000   ,   -0.23798243790  ,   0.16937427380   ],
[   73.75   ,   0.00000000000   ,   -0.24125141770  ,   0.17151138190   ],
[   74.00   ,   0.00000000000   ,   -0.24452248840  ,   0.17364297300   ],
[   74.25   ,   0.00000000000   ,   -0.24779526260  ,   0.17576899210   ],
[   74.50   ,   0.00000000000   ,   -0.25106933280  ,   0.17788940010   ],
[   74.75   ,   0.00000000000   ,   -0.25434428420  ,   0.18000416610   ],
[   75.00   ,   0.00000000000   ,   -0.25761969940  ,   0.18211326560   ],
[   75.25   ,   0.00000000000   ,   -0.26089516920  ,   0.18421668110   ],
[   75.50   ,   0.00000000000   ,   -0.26417027730  ,   0.18631440950   ],
[   75.75   ,   0.00000000000   ,   -0.26744460260  ,   0.18840644940   ],
[   76.00   ,   0.00000000000   ,   -0.27071773350  ,   0.19049279840   ],
[   76.25   ,   0.00000000000   ,   -0.27398926970  ,   0.19257345520   ],
[   76.50   ,   0.00000000000   ,   -0.27725881100  ,   0.19464842470   ],
[   76.75   ,   0.00000000000   ,   -0.28052596830  ,   0.19671771400   ],
[   77.00   ,   0.00000000000   ,   -0.28379035960  ,   0.19878133170   ],
[   77.25   ,   0.00000000000   ,   -0.28705162130  ,   0.20083928370   ],
[   77.50   ,   0.00000000000   ,   -0.29030943230  ,   0.20289156470   ],
[   77.75   ,   0.00000000000   ,   -0.29356348900  ,   0.20493816740   ],
[   78.00   ,   0.00000000000   ,   -0.29681350020  ,   0.20697908460   ],
[   78.25   ,   0.00000000000   ,   -0.30005917570  ,   0.20901430950   ],
[   78.50   ,   0.00000000000   ,   -0.30330020690  ,   0.21104384050   ],
[   78.75   ,   0.00000000000   ,   -0.30653628980  ,   0.21306767860   ],
[   79.00   ,   0.00000000000   ,   -0.30976713250  ,   0.21508582560   ],
[   79.25   ,   0.00000000000   ,   -0.31299245070  ,   0.21709828500   ],
[   79.50   ,   0.00000000000   ,   -0.31621195890  ,   0.21910506630   ],
[   79.75   ,   0.00000000000   ,   -0.31942537940  ,   0.22110618120   ],
[   80.00   ,   0.00000000000   ,   -0.32263244680  ,   0.22310164230   ],
[   80.25   ,   0.00000000000   ,   -0.32583292490  ,   0.22509146260   ],
[   80.50   ,   0.00000000000   ,   -0.32902665870  ,   0.22707565760   ],
[   80.75   ,   0.00000000000   ,   -0.33221351830  ,   0.22905424280   ],
[   81.00   ,   0.00000000000   ,   -0.33539338170  ,   0.23102723320   ],
[   81.25   ,   0.00000000000   ,   -0.33856613270  ,   0.23299464420   ],
[   81.50   ,   0.00000000000   ,   -0.34173165510  ,   0.23495649090   ],
[   81.75   ,   0.00000000000   ,   -0.34488982550  ,   0.23691279080   ],
[   82.00   ,   0.00000000000   ,   -0.34804052060  ,   0.23886356190   ],
[   82.25   ,   0.00000000000   ,   -0.35118362580  ,   0.24080882240   ],
[   82.50   ,   0.00000000000   ,   -0.35431905510  ,   0.24274859190   ],
[   82.75   ,   0.00000000000   ,   -0.35744673370  ,   0.24468289000   ],
[   83.00   ,   0.00000000000   ,   -0.36056658840  ,   0.24661173590   ],
[   83.25   ,   0.00000000000   ,   -0.36367855400  ,   0.24853514820   ],
[   83.50   ,   0.00000000000   ,   -0.36678259170  ,   0.25045314480   ],
[   83.75   ,   0.00000000000   ,   -0.36987866970  ,   0.25236574340   ],
[   84.00   ,   0.00000000000   ,   -0.37296675320  ,   0.25427296210   ],
[   84.25   ,   0.00000000000   ,   -0.37604680880  ,   0.25617481910   ],
[   84.50   ,   0.00000000000   ,   -0.37911880320  ,   0.25807133250   ],
[   84.75   ,   0.00000000000   ,   -0.38218270200  ,   0.25996251930   ],
[   85.00   ,   0.00000000000   ,   -0.38523847090  ,   0.26184839650   ],
[   85.25   ,   0.00000000000   ,   -0.38828608160  ,   0.26372898070   ],
[   85.50   ,   0.00000000000   ,   -0.39132552080  ,   0.26560428970   ],
[   85.75   ,   0.00000000000   ,   -0.39435677980  ,   0.26747434150   ],
[   86.00   ,   0.00000000000   ,   -0.39737985180  ,   0.26933915450   ],
[   86.25   ,   0.00000000000   ,   -0.40039473620  ,   0.27119874780   ],
[   86.50   ,   0.00000000000   ,   -0.40340144490  ,   0.27305314160   ],
[   86.75   ,   0.00000000000   ,   -0.40639999310  ,   0.27490235700   ],
[   87.00   ,   0.00000000000   ,   -0.40939039700  ,   0.27674641430   ],
[   87.25   ,   0.00000000000   ,   -0.41237267380  ,   0.27858533420   ],
[   87.50   ,   0.00000000000   ,   -0.41534684030  ,   0.28041913670   ],
[   87.75   ,   0.00000000000   ,   -0.41831291330  ,   0.28224784210   ],
[   88.00   ,   0.00000000000   ,   -0.42127090930  ,   0.28407147000   ],
[   88.25   ,   0.00000000000   ,   -0.42422084810  ,   0.28589003970   ],
[   88.50   ,   0.00000000000   ,   -0.42716276030  ,   0.28770356890   ],
[   88.75   ,   0.00000000000   ,   -0.43009667940  ,   0.28951207480   ],
[   89.00   ,   0.00000000000   ,   -0.43302263740  ,   0.29131557460   ],
[   89.25   ,   0.00000000000   ,   -0.43594066000  ,   0.29311408700   ],
[   89.50   ,   0.00000000000   ,   -0.43885077260  ,   0.29490763070   ],
[   89.75   ,   0.00000000000   ,   -0.44175299690  ,   0.29669622550   ],
[   90.00   ,   0.00000000000   ,   -0.44464735300  ,   0.29847989150   ],
[   90.25   ,   0.00000000000   ,   -0.44753386160  ,   0.30025864890   ],
[   90.50   ,   0.00000000000   ,   -0.45041254900  ,   0.30203252120   ],
[   90.75   ,   0.00000000000   ,   -0.45328344300  ,   0.30380153210   ],
[   91.00   ,   0.00000000000   ,   -0.45614657140  ,   0.30556570540   ],
[   91.25   ,   0.00000000000   ,   -0.45900196180  ,   0.30732506440   ],
[   91.50   ,   0.00000000000   ,   -0.46184964280  ,   0.30907963190   ],
[   91.75   ,   0.00000000000   ,   -0.46468964290  ,   0.31082943060   ],
[   92.00   ,   0.00000000000   ,   -0.46752199070  ,   0.31257448290   ],
[   92.25   ,   0.00000000000   ,   -0.47034671540  ,   0.31431481150   ],
[   92.50   ,   0.00000000000   ,   -0.47316385090  ,   0.31605044070   ],
[   92.75   ,   0.00000000000   ,   -0.47597343180  ,   0.31778139480   ],
[   93.00   ,   0.00000000000   ,   -0.47877549240  ,   0.31950769820   ],
[   93.25   ,   0.00000000000   ,   -0.48157006680  ,   0.32122937490   ],
[   93.50   ,   0.00000000000   ,   -0.48435718810  ,   0.32294644860   ],
[   93.75   ,   0.00000000000   ,   -0.48713688920  ,   0.32465894290   ],
[   94.00   ,   0.00000000000   ,   -0.48990920290  ,   0.32636688100   ],
[   94.25   ,   0.00000000000   ,   -0.49267416230  ,   0.32807028600   ],
[   94.50   ,   0.00000000000   ,   -0.49543180360  ,   0.32976918120   ],
[   94.75   ,   0.00000000000   ,   -0.49818216330  ,   0.33146358960   ],
[   95.00   ,   0.00000000000   ,   -0.50092527770  ,   0.33315353300   ]];

GLIPredData.FEV1FVC.female = {};
GLIPredData.FEV1FVC.female.a = [0.5506,  -0.1078,  -0.0544,  0.0055,  0.0088,  0.0285,  0.0106];
GLIPredData.FEV1FVC.female.p = [-3.2395,  0.1850,  -0.0307,  -0.1640,  -0.1521,  -0.0860];
GLIPredData.FEV1FVC.female.q = [7.032,  -1.197];
GLIPredData.FEV1FVC.female.maxAge = 95;
GLIPredData.FEV1FVC.female.spline = 
[[  3.00    ,   0.00000000000   ,   -0.04427233530  ,   0.03053059790   ],
[   3.25    ,   0.00000000000   ,   -0.03972778660  ,   0.03588405090   ],
[   3.50    ,   0.00000000000   ,   -0.03556960740  ,   0.04061345890   ],
[   3.75    ,   0.00000000000   ,   -0.03183499480  ,   0.04467789270   ],
[   4.00    ,   0.00000000000   ,   -0.02862524900  ,   0.04801321280   ],
[   4.25    ,   0.00000000000   ,   -0.02608386540  ,   0.05056813880   ],
[   4.50    ,   0.00000000000   ,   -0.02435346580  ,   0.05231108230   ],
[   4.75    ,   0.00000000000   ,   -0.02347605400  ,   0.05324005590   ],
[   5.00    ,   0.00000000000   ,   -0.02332762310  ,   0.05339356840   ],
[   5.25    ,   0.00000000000   ,   -0.02371733870  ,   0.05278015870   ],
[   5.50    ,   0.00000000000   ,   -0.02443484410  ,   0.05147016890   ],
[   5.75    ,   0.00000000000   ,   -0.02523804740  ,   0.04957888440   ],
[   6.00    ,   0.00000000000   ,   -0.02589224480  ,   0.04727719310   ],
[   6.25    ,   0.00000000000   ,   -0.02618575710  ,   0.04478079760   ],
[   6.50    ,   0.00000000000   ,   -0.02599768740  ,   0.04228294640   ],
[   6.75    ,   0.00000000000   ,   -0.02533286850  ,   0.03989005330   ],
[   7.00    ,   0.00000000000   ,   -0.02428022020  ,   0.03763388330   ],
[   7.25    ,   0.00000000000   ,   -0.02295638470  ,   0.03547641910   ],
[   7.50    ,   0.00000000000   ,   -0.02148603490  ,   0.03335647510   ],
[   7.75    ,   0.00000000000   ,   -0.01997842960  ,   0.03120679800   ],
[   8.00    ,   0.00000000000   ,   -0.01853586470  ,   0.02893846910   ],
[   8.25    ,   0.00000000000   ,   -0.01722424980  ,   0.02647330900   ],
[   8.50    ,   0.00000000000   ,   -0.01610140210  ,   0.02375873020   ],
[   8.75    ,   0.00000000000   ,   -0.01521552680  ,   0.02074747200   ],
[   9.00    ,   0.00000000000   ,   -0.01447068350  ,   0.01750514710   ],
[   9.25    ,   0.00000000000   ,   -0.01372675880  ,   0.01416094850   ],
[   9.50    ,   0.00000000000   ,   -0.01289013270  ,   0.01082275180   ],
[   9.75    ,   0.00000000000   ,   -0.01188626050  ,   0.00758204790   ],
[   10.00   ,   0.00000000000   ,   -0.01064322610  ,   0.00451674590   ],
[   10.25   ,   0.00000000000   ,   -0.00912040610  ,   0.00166978590   ],
[   10.50   ,   0.00000000000   ,   -0.00731567460  ,   -0.00094150100  ],
[   10.75   ,   0.00000000000   ,   -0.00524499890  ,   -0.00331917000  ],
[   11.00   ,   0.00000000000   ,   -0.00293005770  ,   -0.00547462500  ],
[   11.25   ,   0.00000000000   ,   -0.00040379100  ,   -0.00741614080  ],
[   11.50   ,   0.00000000000   ,   0.00228930040   ,   -0.00916180480  ],
[   11.75   ,   0.00000000000   ,   0.00510456400   ,   -0.01072322740  ],
[   12.00   ,   0.00000000000   ,   0.00799700220   ,   -0.01211540480  ],
[   12.25   ,   0.00000000000   ,   0.01092131090   ,   -0.01335496000  ],
[   12.50   ,   0.00000000000   ,   0.01383088340   ,   -0.01446444270  ],
[   12.75   ,   0.00000000000   ,   0.01668041060   ,   -0.01545914550  ],
[   13.00   ,   0.00000000000   ,   0.01943287300   ,   -0.01634655450  ],
[   13.25   ,   0.00000000000   ,   0.02205728040   ,   -0.01713534960  ],
[   13.50   ,   0.00000000000   ,   0.02452679920   ,   -0.01783436260  ],
[   13.75   ,   0.00000000000   ,   0.02682384490   ,   -0.01844699720  ],
[   14.00   ,   0.00000000000   ,   0.02893825750   ,   -0.01897305050  ],
[   14.25   ,   0.00000000000   ,   0.03086670720   ,   -0.01941248880  ],
[   14.50   ,   0.00000000000   ,   0.03260688910   ,   -0.01976989970  ],
[   14.75   ,   0.00000000000   ,   0.03415940210   ,   -0.02004993150  ],
[   15.00   ,   0.00000000000   ,   0.03552761650   ,   -0.02025814760  ],
[   15.25   ,   0.00000000000   ,   0.03671746950   ,   -0.02039939500  ],
[   15.50   ,   0.00000000000   ,   0.03773659510   ,   -0.02047707840  ],
[   15.75   ,   0.00000000000   ,   0.03859496250   ,   -0.02049321640  ],
[   16.00   ,   0.00000000000   ,   0.03930519510   ,   -0.02044747350  ],
[   16.25   ,   0.00000000000   ,   0.03988053550   ,   -0.02033748340  ],
[   16.50   ,   0.00000000000   ,   0.04033312370   ,   -0.02016179680  ],
[   16.75   ,   0.00000000000   ,   0.04067393730   ,   -0.01992153140  ],
[   17.00   ,   0.00000000000   ,   0.04091331720   ,   -0.01961921650  ],
[   17.25   ,   0.00000000000   ,   0.04106179720   ,   -0.01925885230  ],
[   17.50   ,   0.00000000000   ,   0.04113004850   ,   -0.01884503770  ],
[   17.75   ,   0.00000000000   ,   0.04112780310   ,   -0.01838240580  ],
[   18.00   ,   0.00000000000   ,   0.04106305050   ,   -0.01787600940  ],
[   18.25   ,   0.00000000000   ,   0.04094270410   ,   -0.01733173690  ],
[   18.50   ,   0.00000000000   ,   0.04077141410   ,   -0.01675754300  ],
[   18.75   ,   0.00000000000   ,   0.04055237780   ,   -0.01616193110  ],
[   19.00   ,   0.00000000000   ,   0.04028850450   ,   -0.01555279370  ],
[   19.25   ,   0.00000000000   ,   0.03998335580   ,   -0.01493641090  ],
[   19.50   ,   0.00000000000   ,   0.03964165360   ,   -0.01431774040  ],
[   19.75   ,   0.00000000000   ,   0.03926788570   ,   -0.01370136250  ],
[   20.00   ,   0.00000000000   ,   0.03886618440   ,   -0.01309150100  ],
[   20.25   ,   0.00000000000   ,   0.03844030030   ,   -0.01249242120  ],
[   20.50   ,   0.00000000000   ,   0.03799333850   ,   -0.01190885820  ],
[   20.75   ,   0.00000000000   ,   0.03752785750   ,   -0.01134539690  ],
[   21.00   ,   0.00000000000   ,   0.03704609450   ,   -0.01080653370  ],
[   21.25   ,   0.00000000000   ,   0.03655038530   ,   -0.01029637570  ],
[   21.50   ,   0.00000000000   ,   0.03604280200   ,   -0.00981816560  ],
[   21.75   ,   0.00000000000   ,   0.03552611960   ,   -0.00937499630  ],
[   22.00   ,   0.00000000000   ,   0.03500363620   ,   -0.00896999130  ],
[   22.25   ,   0.00000000000   ,   0.03447834320   ,   -0.00860608850  ],
[   22.50   ,   0.00000000000   ,   0.03395229160   ,   -0.00828560700  ],
[   22.75   ,   0.00000000000   ,   0.03342718840   ,   -0.00800991200  ],
[   23.00   ,   0.00000000000   ,   0.03290465890   ,   -0.00777980460  ],
[   23.25   ,   0.00000000000   ,   0.03238590640   ,   -0.00759619760  ],
[   23.50   ,   0.00000000000   ,   0.03187138790   ,   -0.00746104710  ],
[   23.75   ,   0.00000000000   ,   0.03136140530   ,   -0.00737601290  ],
[   24.00   ,   0.00000000000   ,   0.03085629880   ,   -0.00734203750  ],
[   24.25   ,   0.00000000000   ,   0.03035627540   ,   -0.00735997010  ],
[   24.50   ,   0.00000000000   ,   0.02986116070   ,   -0.00743059270  ],
[   24.75   ,   0.00000000000   ,   0.02937107090   ,   -0.00755419320  ],
[   25.00   ,   0.00000000000   ,   0.02888635280   ,   -0.00773074140  ],
[   25.25   ,   0.00000000000   ,   0.02840763000   ,   -0.00795992300  ],
[   25.50   ,   0.00000000000   ,   0.02793576520   ,   -0.00824089190  ],
[   25.75   ,   0.00000000000   ,   0.02747188480   ,   -0.00857270110  ],
[   26.00   ,   0.00000000000   ,   0.02701697550   ,   -0.00895445970  ],
[   26.25   ,   0.00000000000   ,   0.02657174160   ,   -0.00938512660  ],
[   26.50   ,   0.00000000000   ,   0.02613651200   ,   -0.00986315020  ],
[   26.75   ,   0.00000000000   ,   0.02571154710   ,   -0.01038696290  ],
[   27.00   ,   0.00000000000   ,   0.02529704230   ,   -0.01095512530  ],
[   27.25   ,   0.00000000000   ,   0.02489284920   ,   -0.01156617540  ],
[   27.50   ,   0.00000000000   ,   0.02449812610   ,   -0.01221788990  ],
[   27.75   ,   0.00000000000   ,   0.02411191710   ,   -0.01290778860  ],
[   28.00   ,   0.00000000000   ,   0.02373311100   ,   -0.01363340920  ],
[   28.25   ,   0.00000000000   ,   0.02336067220   ,   -0.01439228490  ],
[   28.50   ,   0.00000000000   ,   0.02299356620   ,   -0.01518207990  ],
[   28.75   ,   0.00000000000   ,   0.02263068290   ,   -0.01600058460  ],
[   29.00   ,   0.00000000000   ,   0.02227095160   ,   -0.01684568980  ],
[   29.25   ,   0.00000000000   ,   0.02191348510   ,   -0.01771535280  ],
[   29.50   ,   0.00000000000   ,   0.02155756650   ,   -0.01860832570  ],
[   29.75   ,   0.00000000000   ,   0.02120256380   ,   -0.01952374340  ],
[   30.00   ,   0.00000000000   ,   0.02084782730   ,   -0.02046074790  ],
[   30.25   ,   0.00000000000   ,   0.02049288330   ,   -0.02141835810  ],
[   30.50   ,   0.00000000000   ,   0.02013734000   ,   -0.02239544780  ],
[   30.75   ,   0.00000000000   ,   0.01978093750   ,   -0.02339079850  ],
[   31.00   ,   0.00000000000   ,   0.01942340290   ,   -0.02440311730  ],
[   31.25   ,   0.00000000000   ,   0.01906443230   ,   -0.02543112250  ],
[   31.50   ,   0.00000000000   ,   0.01870352000   ,   -0.02647373480  ],
[   31.75   ,   0.00000000000   ,   0.01834012640   ,   -0.02752988120  ],
[   32.00   ,   0.00000000000   ,   0.01797376460   ,   -0.02859847850  ],
[   32.25   ,   0.00000000000   ,   0.01760398820   ,   -0.02967836670  ],
[   32.50   ,   0.00000000000   ,   0.01723068240   ,   -0.03076819430  ],
[   32.75   ,   0.00000000000   ,   0.01685383250   ,   -0.03186669020  ],
[   33.00   ,   0.00000000000   ,   0.01647348260   ,   -0.03297259480  ],
[   33.25   ,   0.00000000000   ,   0.01608957560   ,   -0.03408472640  ],
[   33.50   ,   0.00000000000   ,   0.01570174420   ,   -0.03520208490  ],
[   33.75   ,   0.00000000000   ,   0.01530957500   ,   -0.03632368710  ],
[   34.00   ,   0.00000000000   ,   0.01491282580   ,   -0.03744841270  ],
[   34.25   ,   0.00000000000   ,   0.01451137460   ,   -0.03857506090  ],
[   34.50   ,   0.00000000000   ,   0.01410553750   ,   -0.03970201890  ],
[   34.75   ,   0.00000000000   ,   0.01369573990   ,   -0.04082761470  ],
[   35.00   ,   0.00000000000   ,   0.01328227830   ,   -0.04195026900  ],
[   35.25   ,   0.00000000000   ,   0.01286542400   ,   -0.04306842290  ],
[   35.50   ,   0.00000000000   ,   0.01244564960   ,   -0.04418050970  ],
[   35.75   ,   0.00000000000   ,   0.01202340200   ,   -0.04528505320  ],
[   36.00   ,   0.00000000000   ,   0.01159905030   ,   -0.04638056830  ],
[   36.25   ,   0.00000000000   ,   0.01117296910   ,   -0.04746551870  ],
[   36.50   ,   0.00000000000   ,   0.01074552340   ,   -0.04853841090  ],
[   36.75   ,   0.00000000000   ,   0.01031701550   ,   -0.04959777200  ],
[   37.00   ,   0.00000000000   ,   0.00988775540   ,   -0.05064211320  ],
[   37.25   ,   0.00000000000   ,   0.00945813170   ,   -0.05166988840  ],
[   37.50   ,   0.00000000000   ,   0.00902880370   ,   -0.05267937200  ],
[   37.75   ,   0.00000000000   ,   0.00860041130   ,   -0.05366885930  ],
[   38.00   ,   0.00000000000   ,   0.00817343200   ,   -0.05463664550  ],
[   38.25   ,   0.00000000000   ,   0.00774826800   ,   -0.05558098530  ],
[   38.50   ,   0.00000000000   ,   0.00732556170   ,   -0.05650009180  ],
[   38.75   ,   0.00000000000   ,   0.00690599120   ,   -0.05739227060  ],
[   39.00   ,   0.00000000000   ,   0.00649010530   ,   -0.05825592050  ],
[   39.25   ,   0.00000000000   ,   0.00607835930   ,   -0.05908958270  ],
[   39.50   ,   0.00000000000   ,   0.00567131290   ,   -0.05989199280  ],
[   39.75   ,   0.00000000000   ,   0.00526957890   ,   -0.06066191140  ],
[   40.00   ,   0.00000000000   ,   0.00487367380   ,   -0.06139818000  ],
[   40.25   ,   0.00000000000   ,   0.00448401560   ,   -0.06209959520  ],
[   40.50   ,   0.00000000000   ,   0.00410104600   ,   -0.06276474530  ],
[   40.75   ,   0.00000000000   ,   0.00372511010   ,   -0.06339215710  ],
[   41.00   ,   0.00000000000   ,   0.00335647040   ,   -0.06398039570  ],
[   41.25   ,   0.00000000000   ,   0.00299535310   ,   -0.06452825240  ],
[   41.50   ,   0.00000000000   ,   0.00264199420   ,   -0.06503498880  ],
[   41.75   ,   0.00000000000   ,   0.00229655000   ,   -0.06550003640  ],
[   42.00   ,   0.00000000000   ,   0.00195904540   ,   -0.06592289810  ],
[   42.25   ,   0.00000000000   ,   0.00162948420   ,   -0.06630309760  ],
[   42.50   ,   0.00000000000   ,   0.00130794780   ,   -0.06664023220  ],
[   42.75   ,   0.00000000000   ,   0.00099449460   ,   -0.06693391700  ],
[   43.00   ,   0.00000000000   ,   0.00068911230   ,   -0.06718384340  ],
[   43.25   ,   0.00000000000   ,   0.00039169710   ,   -0.06738983800  ],
[   43.50   ,   0.00000000000   ,   0.00010209370   ,   -0.06755183130  ],
[   43.75   ,   0.00000000000   ,   -0.00017991720  ,   -0.06766965210  ],
[   44.00   ,   0.00000000000   ,   -0.00045462290  ,   -0.06774303290  ],
[   44.25   ,   0.00000000000   ,   -0.00072227480  ,   -0.06777158840  ],
[   44.50   ,   0.00000000000   ,   -0.00098296330  ,   -0.06775486560  ],
[   44.75   ,   0.00000000000   ,   -0.00123678980  ,   -0.06769244710  ],
[   45.00   ,   0.00000000000   ,   -0.00148390160  ,   -0.06758402640  ],
[   45.25   ,   0.00000000000   ,   -0.00172443580  ,   -0.06742945130  ],
[   45.50   ,   0.00000000000   ,   -0.00195844900  ,   -0.06722882980  ],
[   45.75   ,   0.00000000000   ,   -0.00218604690  ,   -0.06698237960  ],
[   46.00   ,   0.00000000000   ,   -0.00240742210  ,   -0.06669032000  ],
[   46.25   ,   0.00000000000   ,   -0.00262278500  ,   -0.06635289090  ],
[   46.50   ,   0.00000000000   ,   -0.00283222450  ,   -0.06597057230  ],
[   46.75   ,   0.00000000000   ,   -0.00303584630  ,   -0.06554385360  ],
[   47.00   ,   0.00000000000   ,   -0.00323376890  ,   -0.06507318470  ],
[   47.25   ,   0.00000000000   ,   -0.00342607990  ,   -0.06455896460  ],
[   47.50   ,   0.00000000000   ,   -0.00361282180  ,   -0.06400146820  ],
[   47.75   ,   0.00000000000   ,   -0.00379408920  ,   -0.06340096110  ],
[   48.00   ,   0.00000000000   ,   -0.00397004390  ,   -0.06275775790  ],
[   48.25   ,   0.00000000000   ,   -0.00414090090  ,   -0.06207223240  ],
[   48.50   ,   0.00000000000   ,   -0.00430686230  ,   -0.06134477580  ],
[   48.75   ,   0.00000000000   ,   -0.00446815310  ,   -0.06057578900  ],
[   49.00   ,   0.00000000000   ,   -0.00462503960  ,   -0.05976572040  ],
[   49.25   ,   0.00000000000   ,   -0.00477781180  ,   -0.05891508650  ],
[   49.50   ,   0.00000000000   ,   -0.00492673470  ,   -0.05802450550  ],
[   49.75   ,   0.00000000000   ,   -0.00507210870  ,   -0.05709461960  ],
[   50.00   ,   0.00000000000   ,   -0.00521428810  ,   -0.05612601310  ],
[   50.25   ,   0.00000000000   ,   -0.00535365670  ,   -0.05511924050  ],
[   50.50   ,   0.00000000000   ,   -0.00549053500  ,   -0.05407494920  ],
[   50.75   ,   0.00000000000   ,   -0.00562525550  ,   -0.05299379300  ],
[   51.00   ,   0.00000000000   ,   -0.00575819720  ,   -0.05187642290  ],
[   51.25   ,   0.00000000000   ,   -0.00588972840  ,   -0.05072347580  ],
[   51.50   ,   0.00000000000   ,   -0.00602006050  ,   -0.04953553500  ],
[   51.75   ,   0.00000000000   ,   -0.00614939520  ,   -0.04831317920  ],
[   52.00   ,   0.00000000000   ,   -0.00627794780  ,   -0.04705699240  ],
[   52.25   ,   0.00000000000   ,   -0.00640592870  ,   -0.04576757660  ],
[   52.50   ,   0.00000000000   ,   -0.00653342570  ,   -0.04444556100  ],
[   52.75   ,   0.00000000000   ,   -0.00666054980  ,   -0.04309155840  ],
[   53.00   ,   0.00000000000   ,   -0.00678744140  ,   -0.04170616960  ],
[   53.25   ,   0.00000000000   ,   -0.00691420460  ,   -0.04028994560  ],
[   53.50   ,   0.00000000000   ,   -0.00704078810  ,   -0.03884337660  ],
[   53.75   ,   0.00000000000   ,   -0.00716715030  ,   -0.03736694790  ],
[   54.00   ,   0.00000000000   ,   -0.00729330960  ,   -0.03586112280  ],
[   54.25   ,   0.00000000000   ,   -0.00741930670  ,   -0.03432636980  ],
[   54.50   ,   0.00000000000   ,   -0.00754508100  ,   -0.03276317970  ],
[   54.75   ,   0.00000000000   ,   -0.00767058240  ,   -0.03117207870  ],
[   55.00   ,   0.00000000000   ,   -0.00779579580  ,   -0.02955359540  ],
[   55.25   ,   0.00000000000   ,   -0.00792070770  ,   -0.02790821620  ],
[   55.50   ,   0.00000000000   ,   -0.00804527660  ,   -0.02623641550  ],
[   55.75   ,   0.00000000000   ,   -0.00816948330  ,   -0.02453868760  ],
[   56.00   ,   0.00000000000   ,   -0.00829336030  ,   -0.02281553950  ],
[   56.25   ,   0.00000000000   ,   -0.00841698420  ,   -0.02106749330  ],
[   56.50   ,   0.00000000000   ,   -0.00854041390  ,   -0.01929507870  ],
[   56.75   ,   0.00000000000   ,   -0.00866373600  ,   -0.01749882190  ],
[   57.00   ,   0.00000000000   ,   -0.00878709400  ,   -0.01567925350  ],
[   57.25   ,   0.00000000000   ,   -0.00891064620  ,   -0.01383689170  ],
[   57.50   ,   0.00000000000   ,   -0.00903448610  ,   -0.01197224260  ],
[   57.75   ,   0.00000000000   ,   -0.00915871140  ,   -0.01008581340  ],
[   58.00   ,   0.00000000000   ,   -0.00928344240  ,   -0.00817812390  ],
[   58.25   ,   0.00000000000   ,   -0.00940880030  ,   -0.00624972980  ],
[   58.50   ,   0.00000000000   ,   -0.00953486450  ,   -0.00430125950  ],
[   58.75   ,   0.00000000000   ,   -0.00966174040  ,   -0.00233330950  ],
[   59.00   ,   0.00000000000   ,   -0.00978956620  ,   -0.00034641870  ],
[   59.25   ,   0.00000000000   ,   -0.00991850670  ,   0.00165887840   ],
[   59.50   ,   0.00000000000   ,   -0.01004873430  ,   0.00368196530   ],
[   59.75   ,   0.00000000000   ,   -0.01018045760  ,   0.00572221680   ],
[   60.00   ,   0.00000000000   ,   -0.01031392180  ,   0.00777901530   ],
[   60.25   ,   0.00000000000   ,   -0.01044935630  ,   0.00985173480   ],
[   60.50   ,   0.00000000000   ,   -0.01058684950  ,   0.01193968760   ],
[   60.75   ,   0.00000000000   ,   -0.01072647690  ,   0.01404220650   ],
[   61.00   ,   0.00000000000   ,   -0.01086833180  ,   0.01615866810   ],
[   61.25   ,   0.00000000000   ,   -0.01101250360  ,   0.01828851650   ],
[   61.50   ,   0.00000000000   ,   -0.01115904140  ,   0.02043132460   ],
[   61.75   ,   0.00000000000   ,   -0.01130801560  ,   0.02258669960   ],
[   62.00   ,   0.00000000000   ,   -0.01145952920  ,   0.02475425530   ],
[   62.25   ,   0.00000000000   ,   -0.01161367950  ,   0.02693360720   ],
[   62.50   ,   0.00000000000   ,   -0.01177044580  ,   0.02912438390   ],
[   62.75   ,   0.00000000000   ,   -0.01192978370  ,   0.03132622160   ],
[   63.00   ,   0.00000000000   ,   -0.01209164560  ,   0.03353876360   ],
[   63.25   ,   0.00000000000   ,   -0.01225597390  ,   0.03576167880   ],
[   63.50   ,   0.00000000000   ,   -0.01242263690  ,   0.03799473160   ],
[   63.75   ,   0.00000000000   ,   -0.01259149400  ,   0.04023771170   ],
[   64.00   ,   0.00000000000   ,   -0.01276243470  ,   0.04249040200   ],
[   64.25   ,   0.00000000000   ,   -0.01293537120  ,   0.04475256940   ],
[   64.50   ,   0.00000000000   ,   -0.01311022260  ,   0.04702393240   ],
[   64.75   ,   0.00000000000   ,   -0.01328692250  ,   0.04930421750   ],
[   65.00   ,   0.00000000000   ,   -0.01346542830  ,   0.05159316680   ],
[   65.25   ,   0.00000000000   ,   -0.01364570650  ,   0.05389049980   ],
[   65.50   ,   0.00000000000   ,   -0.01382768820  ,   0.05619586910   ],
[   65.75   ,   0.00000000000   ,   -0.01401129940  ,   0.05850892850   ],
[   66.00   ,   0.00000000000   ,   -0.01419647910  ,   0.06082937160   ],
[   66.25   ,   0.00000000000   ,   -0.01438316400  ,   0.06315691120   ],
[   66.50   ,   0.00000000000   ,   -0.01457124170  ,   0.06549122640   ],
[   66.75   ,   0.00000000000   ,   -0.01476059910  ,   0.06783198650   ],
[   67.00   ,   0.00000000000   ,   -0.01495113970  ,   0.07017885620   ],
[   67.25   ,   0.00000000000   ,   -0.01514276590  ,   0.07253150190   ],
[   67.50   ,   0.00000000000   ,   -0.01533533030  ,   0.07488960710   ],
[   67.75   ,   0.00000000000   ,   -0.01552868540  ,   0.07725285670   ],
[   68.00   ,   0.00000000000   ,   -0.01572269740  ,   0.07962093230   ],
[   68.25   ,   0.00000000000   ,   -0.01591724290  ,   0.08199350910   ],
[   68.50   ,   0.00000000000   ,   -0.01611218570  ,   0.08437020000   ],
[   68.75   ,   0.00000000000   ,   -0.01630740170  ,   0.08675061170   ],
[   69.00   ,   0.00000000000   ,   -0.01650278190  ,   0.08913436670   ],
[   69.25   ,   0.00000000000   ,   -0.01669823510  ,   0.09152107570   ],
[   69.50   ,   0.00000000000   ,   -0.01689369560  ,   0.09391029550   ],
[   69.75   ,   0.00000000000   ,   -0.01708911310  ,   0.09630157540   ],
[   70.00   ,   0.00000000000   ,   -0.01728444260  ,   0.09869447110   ],
[   70.25   ,   0.00000000000   ,   -0.01747963850  ,   0.10108854200   ],
[   70.50   ,   0.00000000000   ,   -0.01767464790  ,   0.10348333910   ],
[   70.75   ,   0.00000000000   ,   -0.01786942170  ,   0.10587841340   ],
[   71.00   ,   0.00000000000   ,   -0.01806391730  ,   0.10827332550   ],
[   71.25   ,   0.00000000000   ,   -0.01825809740  ,   0.11066765490   ],
[   71.50   ,   0.00000000000   ,   -0.01845192910  ,   0.11306100950   ],
[   71.75   ,   0.00000000000   ,   -0.01864538650  ,   0.11545300890   ],
[   72.00   ,   0.00000000000   ,   -0.01883845070  ,   0.11784328490   ],
[   72.25   ,   0.00000000000   ,   -0.01903110380  ,   0.12023148720   ],
[   72.50   ,   0.00000000000   ,   -0.01922330890  ,   0.12261731220   ],
[   72.75   ,   0.00000000000   ,   -0.01941502720  ,   0.12500047200   ],
[   73.00   ,   0.00000000000   ,   -0.01960622100  ,   0.12738068280   ],
[   73.25   ,   0.00000000000   ,   -0.01979684980  ,   0.12975767640   ],
[   73.50   ,   0.00000000000   ,   -0.01998686910  ,   0.13213122860   ],
[   73.75   ,   0.00000000000   ,   -0.02017624240  ,   0.13450114630   ],
[   74.00   ,   0.00000000000   ,   -0.02036493970  ,   0.13686725960   ],
[   74.25   ,   0.00000000000   ,   -0.02055293300  ,   0.13922940790   ],
[   74.50   ,   0.00000000000   ,   -0.02074017450  ,   0.14158744990   ],
[   74.75   ,   0.00000000000   ,   -0.02092661260  ,   0.14394126870   ],
[   75.00   ,   0.00000000000   ,   -0.02111219890  ,   0.14629076090   ],
[   75.25   ,   0.00000000000   ,   -0.02129689290  ,   0.14863582850   ],
[   75.50   ,   0.00000000000   ,   -0.02148066860  ,   0.15097636190   ],
[   75.75   ,   0.00000000000   ,   -0.02166350580  ,   0.15331224830   ],
[   76.00   ,   0.00000000000   ,   -0.02184538660  ,   0.15564337980   ],
[   76.25   ,   0.00000000000   ,   -0.02202629720  ,   0.15796966600   ],
[   76.50   ,   0.00000000000   ,   -0.02220622230  ,   0.16029106600   ],
[   76.75   ,   0.00000000000   ,   -0.02238514890  ,   0.16260755150   ],
[   77.00   ,   0.00000000000   ,   -0.02256306960  ,   0.16491909610   ],
[   77.25   ,   0.00000000000   ,   -0.02273998550  ,   0.16722566820   ],
[   77.50   ,   0.00000000000   ,   -0.02291590790  ,   0.16952721010   ],
[   77.75   ,   0.00000000000   ,   -0.02309085070  ,   0.17182366070   ],
[   78.00   ,   0.00000000000   ,   -0.02326482930  ,   0.17411495930   ],
[   78.25   ,   0.00000000000   ,   -0.02343785880  ,   0.17640104630   ],
[   78.50   ,   0.00000000000   ,   -0.02360995490  ,   0.17868186930   ],
[   78.75   ,   0.00000000000   ,   -0.02378113390  ,   0.18095737950   ],
[   79.00   ,   0.00000000000   ,   -0.02395141320  ,   0.18322752800   ],
[   79.25   ,   0.00000000000   ,   -0.02412081380  ,   0.18549226260   ],
[   79.50   ,   0.00000000000   ,   -0.02428935990  ,   0.18775152220   ],
[   79.75   ,   0.00000000000   ,   -0.02445707600  ,   0.19000524520   ],
[   80.00   ,   0.00000000000   ,   -0.02462398820  ,   0.19225337260   ],
[   80.25   ,   0.00000000000   ,   -0.02479012390  ,   0.19449585210   ],
[   80.50   ,   0.00000000000   ,   -0.02495550870  ,   0.19673264610   ],
[   80.75   ,   0.00000000000   ,   -0.02512016930  ,   0.19896372310   ],
[   81.00   ,   0.00000000000   ,   -0.02528413310  ,   0.20118905390   ],
[   81.25   ,   0.00000000000   ,   -0.02544742590  ,   0.20340861560   ],
[   81.50   ,   0.00000000000   ,   -0.02561006650  ,   0.20562239530   ],
[   81.75   ,   0.00000000000   ,   -0.02577207110  ,   0.20783038160   ],
[   82.00   ,   0.00000000000   ,   -0.02593345630  ,   0.21003256380   ],
[   82.25   ,   0.00000000000   ,   -0.02609423790  ,   0.21222893230   ],
[   82.50   ,   0.00000000000   ,   -0.02625443160  ,   0.21441948260   ],
[   82.75   ,   0.00000000000   ,   -0.02641405340  ,   0.21660421040   ],
[   83.00   ,   0.00000000000   ,   -0.02657311930  ,   0.21878311140   ],
[   83.25   ,   0.00000000000   ,   -0.02673164470  ,   0.22095618380   ],
[   83.50   ,   0.00000000000   ,   -0.02688964220  ,   0.22312343390   ],
[   83.75   ,   0.00000000000   ,   -0.02704712320  ,   0.22528487040   ],
[   84.00   ,   0.00000000000   ,   -0.02720409840  ,   0.22744050170   ],
[   84.25   ,   0.00000000000   ,   -0.02736057840  ,   0.22959033530   ],
[   84.50   ,   0.00000000000   ,   -0.02751657520  ,   0.23173437500   ],
[   84.75   ,   0.00000000000   ,   -0.02767210140  ,   0.23387262270   ],
[   85.00   ,   0.00000000000   ,   -0.02782716970  ,   0.23600507950   ],
[   85.25   ,   0.00000000000   ,   -0.02798179130  ,   0.23813174910   ],
[   85.50   ,   0.00000000000   ,   -0.02813597040  ,   0.24025264300   ],
[   85.75   ,   0.00000000000   ,   -0.02828970950  ,   0.24236777480   ],
[   86.00   ,   0.00000000000   ,   -0.02844301120  ,   0.24447715810   ],
[   86.25   ,   0.00000000000   ,   -0.02859587920  ,   0.24658080720   ],
[   86.50   ,   0.00000000000   ,   -0.02874831800  ,   0.24867873480   ],
[   86.75   ,   0.00000000000   ,   -0.02890033210  ,   0.25077095340   ],
[   87.00   ,   0.00000000000   ,   -0.02905192670  ,   0.25285747720   ],
[   87.25   ,   0.00000000000   ,   -0.02920310760  ,   0.25493832130   ],
[   87.50   ,   0.00000000000   ,   -0.02935388130  ,   0.25701349520   ],
[   87.75   ,   0.00000000000   ,   -0.02950425460  ,   0.25908300740   ],
[   88.00   ,   0.00000000000   ,   -0.02965423480  ,   0.26114686630   ],
[   88.25   ,   0.00000000000   ,   -0.02980382770  ,   0.26320508190   ],
[   88.50   ,   0.00000000000   ,   -0.02995303500  ,   0.26525766920   ],
[   88.75   ,   0.00000000000   ,   -0.03010185710  ,   0.26730464460   ],
[   89.00   ,   0.00000000000   ,   -0.03025029470  ,   0.26934602440   ],
[   89.25   ,   0.00000000000   ,   -0.03039834890  ,   0.27138182550   ],
[   89.50   ,   0.00000000000   ,   -0.03054601970  ,   0.27341206850   ],
[   89.75   ,   0.00000000000   ,   -0.03069330700  ,   0.27543677480   ],
[   90.00   ,   0.00000000000   ,   -0.03084021140  ,   0.27745596560   ],
[   90.25   ,   0.00000000000   ,   -0.03098673320  ,   0.27946966210   ],
[   90.50   ,   0.00000000000   ,   -0.03113287250  ,   0.28147788490   ],
[   90.75   ,   0.00000000000   ,   -0.03127862920  ,   0.28348065420   ],
[   91.00   ,   0.00000000000   ,   -0.03142400320  ,   0.28547799020   ],
[   91.25   ,   0.00000000000   ,   -0.03156899450  ,   0.28746991390   ],
[   91.50   ,   0.00000000000   ,   -0.03171360360  ,   0.28945644870   ],
[   91.75   ,   0.00000000000   ,   -0.03185783090  ,   0.29143761830   ],
[   92.00   ,   0.00000000000   ,   -0.03200167700  ,   0.29341344680   ],
[   92.25   ,   0.00000000000   ,   -0.03214514260  ,   0.29538395800   ],
[   92.50   ,   0.00000000000   ,   -0.03228822870  ,   0.29734917750   ],
[   92.75   ,   0.00000000000   ,   -0.03243093660  ,   0.29930913130   ],
[   93.00   ,   0.00000000000   ,   -0.03257326760  ,   0.30126384470   ],
[   93.25   ,   0.00000000000   ,   -0.03271522280  ,   0.30321334330   ],
[   93.50   ,   0.00000000000   ,   -0.03285680340  ,   0.30515765180   ],
[   93.75   ,   0.00000000000   ,   -0.03299801050  ,   0.30709679460   ],
[   94.00   ,   0.00000000000   ,   -0.03313884510  ,   0.30903079610   ],
[   94.25   ,   0.00000000000   ,   -0.03327930830  ,   0.31095968030   ],
[   94.50   ,   0.00000000000   ,   -0.03341940140  ,   0.31288346940   ],
[   94.75   ,   0.00000000000   ,   -0.03355912570  ,   0.31480218560   ],
[   95.00   ,   0.00000000000   ,   -0.03369848250  ,   0.31671585070   ]];

window.GLIPredData = GLIPredData

}
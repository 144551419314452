import { useState } from 'react'
import { useLocation, useNavigate } from '@reach/router' // eslint-disable-line import/no-unresolved
import { getParams } from '../../utils/utils'

export default (limit) => {
  const { pathname, search } = useLocation()
  const navigate = useNavigate()
  const queryParams = getParams(search)
  const [currentPage, setCurrentPage] = useState(1000)

  let values = {
    first: limit,
    before: undefined,
    after: undefined
  }

  try {
    values = {
      first: queryParams.first || limit,
      before: queryParams.before
        ? atob(window.decodeURIComponent(queryParams.before))
        : undefined,
      after: queryParams.after
        ? atob(window.decodeURIComponent(queryParams.after))
        : undefined
    }
  } catch {
    void 0
  }

  return [
    values,
    (before, after, isFirstPage) => {
      let nextPage = currentPage
      if (after && isFirstPage) {
        nextPage = 1
      } else if (after) {
        nextPage += 1
      } else if (before) {
        nextPage -= 1
      }
      setCurrentPage(nextPage)
      let queryString = ''
      try {
        queryString =
          before && nextPage === 0
            ? ''
            : before
            ? `before=${window.encodeURIComponent(btoa(before))}&`
            : after
            ? `after=${window.encodeURIComponent(btoa(after))}&`
            : ''
      } catch {
        void 0
      }
      return navigate(`${pathname}?${queryString}first=${limit}`)
    }
  ]
}

if (typeof window !== 'undefined') {

/*
    BaseViewer - base class for all the viewers (ECG, events, context, etc)
*/
bcViewer.BaseViewer = bcViewer.Class.extend({
    construct: function(globals, name, config) {
        $.extend(this, globals);
        this._globals = globals;
        this._globals._viewer = this;

        this._metadata;

        this.name = name;

        this.config = {};
        // TODO: do we need deep merge???
        $.extend(this.config, config);
        
        // Some viewers requires initialization on the first state change, 
        // this flag indicates we are on the first state change
        this._isInitCycle = true;

        // Indicate if viewer finish loading
        this.isViewerReady = false;

        // Which state can be changed on this viewer
        this._supportedStateChanges = ['timePosition'];
        
        this.initSupportedStateChanges();

        this._state = new bcViewer.ViewerState({'width': this.config.WIDTH, 'height': this.config.HEIGHT});
        
        this._isViewerDisabled = false;
    },

    /*
        Handle state changes
    */
    onStateChange: function(stateChanges, isInitCycle) {
        // Get rid of all the unsupported changes
        var _stateChanges = this._utils.filterObjectByKeys(stateChanges, this._supportedStateChanges);
        
        this.scaleStateChangesToViewer(_stateChanges);
        
        // We are changing the state before we apply the new state,
        // so the state might be wrong during the state changing
        $.extend(this._state, _stateChanges);

        if (this.config.HIDDEN) {
            isInitCycle && this._onLoadingEnd();
            return;
        }

        this.applyStateChanges(_stateChanges, isInitCycle);
    },
    
    /*
        apply the state changes
    */
    applyStateChanges: function(stateChanges, isInitCycle) {
       this._view && this._view.onStateChange(stateChanges);
    },

    /*
        Request state changes - usually called from the view due to requests from the user
    */
    onStateChangeRequest: function(stateChanges, internal) {
        var notifyTo = internal ? this : this._bcViewer;
        notifyTo.onStateChange(stateChanges);
    },
    
    /*
        input: state changes
        output: scaled state changes for the viewer
    */
    scaleStateChangesToViewer: function(stateChanges) {
        var capitalizeFirstLetter = this._utils.capitalizeFirstLetter;
        
        for (var key in stateChanges) {
            var handler = 'scale' + capitalizeFirstLetter(key) + 'ToViewer';
            if (this._view && this._view[handler]) {
              stateChanges[key] = this._view[handler](stateChanges);
            }
        }
    },
    
    /*
        listen to the state changes only if the state is not configured (in the config file)
    */
    initSupportedStateChanges: function() {
       if (typeof this.config.WIDTH === 'undefined') {
           this._supportedStateChanges.push('width');
       }
       
       if (typeof this.config.HEIGHT === 'undefined') {
           this._supportedStateChanges.push('height');
       }
    },
     
    /*
       handle window resizing
    */
    onWindowStartResizing: function(isWidthChanged, isHeightChanged) {
       this._view.onWindowStartResizing(isWidthChanged, isHeightChanged);
    },
  
    /*
        return viewer state
    */  
    getState: function() {
        return this._model ? this._model.getState() : this._state;
    },
   
    /*
        handle drag
    */ 
    onDragMove: function(timePosition) {
        this._view.onDragMove && this._view.onDragMove(timePosition);
    },

    /*
        save the metadata
    */
    onGotMetadata: function(metadata) {
        this._metadata = metadata;
    },

    /*
        input: time in msec
        output: time from start string
    */
    getTimeFromStartString: function(time) {
        if (typeof this._metadata === 'undefined') {
          return '';
        }
        var timeFormat = bcGlobals.locale.timeFormat;
        var startTime = this._metadata.StartTime;

        var timeStr;
        if (startTime === 0) {
            timeSpan = this._utils.formatTimeDuration(time, timeFormat);
            timeStr = $.tmpl(bcGlobals.locale.timeSpanFromStart, {'timeSpan':timeSpan}).text();
        } else {
            timeStr = this._utils.formatTime(startTime + time, timeFormat);
        }
        
        return timeStr;
    },

    /*
        Handle viewer loading end
    */
    _onLoadingEnd: function() {
        this._bcViewer.onViewerReady(this.name);
        this.isViewerReady = true;
    },

    /*
        Check if the viewer is disabled
    */
    isDisabled: function() {
        return this._isViewerDisabled;
    },

    /*
        Disable viewer
    */
    disableViewer: function() {
        this._isViewerDisabled = true;

        this._view.disableViewer();
    },
    
    /*
        Enable viewer
    */
    enableViewer: function() {
        this._isViewerDisabled = false;
        
        this._view.enableViewer();
    },

    /*
        Prepare viewer for realtime mode
    */
    initRealtimeMode: function() {
        this.enableViewer();
        this._onLoadingEnd();
    },

    /*
        Adjust viewer to mode
    */
    adjustToMode: function() {
    },

    /*
        Handle hash params
    */
    onGotHashParams: function(params) {
    },
    
    /*
        Stop realtime mode
    */
    stopRealtimeMode: function() {
    },
    
    /*
        Start realtime mode
    */
    startRealtimeMode: function() {
    }
});

}
import React, { useContext, useState } from 'react'
import { gql, useQuery, useLazyQuery, useMutation } from '@apollo/client'
import clsx from 'clsx'
import Layout from './Layout'
import AuthContext from './AuthContext'
import { GET_SHARES_AUTO, GET_SERVICE_PLAN_CHECKOUT_PAGE } from './queries'
import CreateAutoShare from './CreateAutoShare'
import Loader from './Loader'
import Notification from './Notification'
import { getParams, isAuthorizedAction } from '../../utils/utils'
import { useLocalization } from './LocalizationProvider'
import SomethingWentWrong from './SomethingWentWrong'

const CLINIC_SHARE_AUTO_DELETE = gql`
  mutation CLINIC_SHARE_AUTO_DELETE($id: ID!) {
    clinicShareAutoDelete(shareId: $id) {
      id
    }
  }
`

const CLINIC_SHARE_AUTO_ACTIVATE = gql`
  mutation CLINIC_SHARE_AUTO_ACTIVATE($input: ShareAutoDataInput!) {
    clinicShareAutoActivate(input: $input) {
      id
    }
  }
`

const CLINIC_SHARE_AUTO_DEACTIVATE = gql`
  mutation CLINIC_SHARE_AUTO_DEACTIVATE($input: ShareAutoDataInput!) {
    clinicShareAutoDeactivate(input: $input) {
      id
    }
  }
`

const Sharing = (props) => {
  const queryParams = getParams(props.location.search)
  const { locale } = useLocalization()
  const [isNewShareMode, setNewShareMode] = useState(false)
  const [, forceReRender] = useState(false)
  const currentUser = useContext(AuthContext)
  const [notification, setNotification] = useState(
    queryParams.redirectFromPayments === '1'
      ? {
          isSuccess: true,
          message: locale.payments.reports.success
        }
      : queryParams.redirectFromPaymentsFailure === '1'
      ? {
          isError: true,
          message: locale.payments.reports.error
        }
      : null
  )
  const { loading, error, data } = useQuery(GET_SHARES_AUTO, {
    variables: {
      id: currentUser.id,
      isAuthorizedSourceSharesAuto: isAuthorizedAction(
        currentUser.clinic.authorizedActions,
        'Clinic:sourceSharesAuto'
      ),
      isAuthorizedTargetSharesAuto: isAuthorizedAction(
        currentUser.clinic.authorizedActions,
        'Clinic:targetSharesAuto'
      )
    }
  })
  const [clinicShareAutoDelete, { loading: inProgressDelete }] = useMutation(
    CLINIC_SHARE_AUTO_DELETE,
    {
      refetchQueries: [
        {
          query: GET_SHARES_AUTO,
          variables: {
            id: currentUser.id,
            isAuthorizedSourceSharesAuto: isAuthorizedAction(
              currentUser.clinic.authorizedActions,
              'Clinic:sourceSharesAuto'
            ),
            isAuthorizedTargetSharesAuto: isAuthorizedAction(
              currentUser.clinic.authorizedActions,
              'Clinic:targetSharesAuto'
            )
          }
        }
      ],
      awaitRefetchQueries: true
    }
  )
  const [clinicShareAutoActivate, { loading: inProgressActivate }] =
    useMutation(CLINIC_SHARE_AUTO_ACTIVATE, {
      refetchQueries: [
        {
          query: GET_SHARES_AUTO,
          variables: {
            id: currentUser.id,
            isAuthorizedSourceSharesAuto: isAuthorizedAction(
              currentUser.clinic.authorizedActions,
              'Clinic:sourceSharesAuto'
            ),
            isAuthorizedTargetSharesAuto: isAuthorizedAction(
              currentUser.clinic.authorizedActions,
              'Clinic:targetSharesAuto'
            )
          }
        }
      ],
      awaitRefetchQueries: true
    })
  const [clinicShareAutoDeactivate, { loading: inProgressDeactivate }] =
    useMutation(CLINIC_SHARE_AUTO_DEACTIVATE, {
      refetchQueries: [
        {
          query: GET_SHARES_AUTO,
          variables: {
            id: currentUser.id,
            isAuthorizedSourceSharesAuto: isAuthorizedAction(
              currentUser.clinic.authorizedActions,
              'Clinic:sourceSharesAuto'
            ),
            isAuthorizedTargetSharesAuto: isAuthorizedAction(
              currentUser.clinic.authorizedActions,
              'Clinic:targetSharesAuto'
            )
          }
        }
      ],
      awaitRefetchQueries: true
    })
  const [
    getServicePlaCheckoutPage,
    { loading: loadingServicePlanCheckoutPage }
  ] = useLazyQuery(GET_SERVICE_PLAN_CHECKOUT_PAGE, {
    variables: {
      id: currentUser.clinic.servicePlans[0].id,
      clinicId: currentUser.clinic.id,
      returnUrl: window.location.origin + window.location.pathname
    }
  })

  if (loading)
    return (
      <Layout>
        <Loader />
      </Layout>
    )

  if (error) return <SomethingWentWrong />

  const isActiveServicePlan = !!currentUser.clinic.servicePlans.find(
    (servicePlan) => servicePlan.active
  )

  return (
    <Layout>
      {isNewShareMode && (
        <CreateAutoShare
          sharingUser={{
            id: currentUser.id,
            name: currentUser.settings.displayName,
            email: currentUser.email,
            clinic: {
              id: currentUser.clinic.id,
              name: currentUser.clinic.name
            }
          }}
          userAuthorizedActions={currentUser.clinic.authorizedActions}
          onComplete={() => setNewShareMode(false)}
          onExit={() => setNewShareMode(false)}
          GET_SHARES_AUTO={GET_SHARES_AUTO}
        />
      )}
      <Notification
        notification={notification}
        onClose={() => setNotification(null)}
      />
      <div className='section'>
        <div className='container'>
          <div className='box'>
            <div className='is-flex is-justify-content-space-between is-align-content-center'>
              <h2 className='subtitle is-5 has-text-weight-medium'>
                {locale.sharing.title}
              </h2>
              <div>
                {(inProgressDelete ||
                  inProgressActivate ||
                  inProgressDeactivate) &&
                  isActiveServicePlan && (
                    <button className='button is-text is-loading mr-3' />
                  )}
                {isAuthorizedAction(
                  currentUser.clinic.authorizedActions,
                  'Mutation:clinicShareAutoCreate'
                ) &&
                  isActiveServicePlan && (
                    <button
                      className='button is-outlined is-primary'
                      onClick={() => setNewShareMode(true)}
                      disabled={
                        inProgressDelete ||
                        inProgressActivate ||
                        inProgressDeactivate
                      }
                    >
                      {locale.share.share}
                    </button>
                  )}
              </div>
            </div>
            {!isActiveServicePlan ? (
              <div className='content'>
                <p className='has-text-weight-bold'>
                  {locale.service_plan.activate_message}
                </p>
                <p className='has-text-centered'>
                  <button
                    className={clsx('button is-outlined is-primary', {
                      'is-loading': loadingServicePlanCheckoutPage
                    })}
                    onClick={async () => {
                      const result = await getServicePlaCheckoutPage()
                      if (result.data) {
                        window.location = result.data.servicePlan.checkoutPage
                      }
                    }}
                    disabled={loadingServicePlanCheckoutPage}
                  >
                    {locale.service_plan.activate_cta}
                  </button>
                </p>
              </div>
            ) : (
              <>
                {data.clinic.sourceSharesAuto.edges.length > 0 && (
                  <>
                    <hr />
                    <h3 className='subtitle is-6 has-text-weight-bold'>
                      {locale.sharing.shared_with}
                    </h3>
                    <table className='table is-striped is-fullwidth is-hoverable'>
                      <thead>
                        <tr>
                          <th style={{ width: '33.333%' }}>
                            {locale.sharing.name}
                          </th>
                          <th style={{ width: '33.333%' }}>
                            {locale.sharing.status}
                          </th>
                          <th style={{ width: '33.333%' }}>
                            {locale.sharing.actions}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.clinic.sourceSharesAuto.edges.map(
                          ({ node: share }) => (
                            <tr key={share.id}>
                              <td>
                                {share.email || share.targetCaregiver.name}
                              </td>
                              <td>
                                {locale.sharing[share.status.toLowerCase()]}
                              </td>
                              <td>
                                <button
                                  className='button'
                                  disabled={
                                    inProgressDelete ||
                                    inProgressActivate ||
                                    inProgressDeactivate
                                  }
                                  onClick={async () => {
                                    await clinicShareAutoDelete({
                                      variables: { id: share.id }
                                    })
                                    forceReRender()
                                  }}
                                >
                                  {locale.sharing.remove_share}
                                </button>
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </table>
                  </>
                )}
                {data.clinic.targetSharesAuto.edges.length > 0 && (
                  <>
                    <hr />
                    <h3 className='subtitle is-6 has-text-weight-bold'>
                      {locale.sharing.shared_with_me}
                    </h3>
                    <table className='table is-striped is-fullwidth is-hoverable'>
                      <thead>
                        <tr>
                          <th style={{ width: '33.333%' }}>
                            {locale.sharing.name}
                          </th>
                          <th style={{ width: '33.333%' }}>
                            {locale.sharing.status}
                          </th>
                          <th style={{ width: '33.333%' }}>
                            {locale.sharing.actions}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.clinic.targetSharesAuto.edges.map(
                          ({ node: share }) => (
                            <tr key={share.id}>
                              <td>{share.sourceCaregiver.name}</td>
                              <td>
                                {locale.sharing[share.status.toLowerCase()]}
                              </td>
                              <td>
                                <button
                                  className='button'
                                  disabled={
                                    inProgressDelete ||
                                    inProgressActivate ||
                                    inProgressDeactivate
                                  }
                                  onClick={async () => {
                                    if (share.status === 'Active') {
                                      await clinicShareAutoDeactivate({
                                        variables: {
                                          input: {
                                            id: share.id,
                                            updatedAt: new Date().toISOString()
                                          }
                                        }
                                      })
                                    } else {
                                      await clinicShareAutoActivate({
                                        variables: {
                                          input: {
                                            id: share.id,
                                            updatedAt: new Date().toISOString()
                                          }
                                        }
                                      })
                                    }
                                    forceReRender(new Date().toISOString())
                                  }}
                                >
                                  {share.status === 'Active'
                                    ? locale.sharing.deactivate_share
                                    : locale.sharing.activate_share}
                                </button>
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </table>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Sharing

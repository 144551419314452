
if (typeof window !== 'undefined') {

/*
   ECGViewer.View - the ECG viewer view
*/
bcViewer.ECGViewer.View = bcViewer.BaseWavesViewer.View.extend({
    construct: function() {
        this._super.apply(this, arguments);

        // X offset for the drag start point
        this._dragStartX;

        // New time position when drag ends
        this._newTimePositionAfterDrag;

        //Reference to key drag stop timeout
        this._keyDragStopTimeout = null;

        // The interval to jump on a single left\right key swipe
        this._keyDragNormalMsInterval = 150;
        this._keyDragMsInterval = this._keyDragNormalMsInterval;

        this._allowNextPageSwipe = true;

        this._downKeys = {};
        for (key in this._globals.keyCodes) {
            this._downKeys[this._globals.keyCodes[key]] = false;
        }

        this._notWaitingForECGData = true;

        // Keep the current QRS annotations map to support hovering
        this._qrsDataMap;

        // Keep the current hovered annotation x
        this._annotationHoverX;

        // Sensitivity to hovering above annotation
        this._annotationHoverSensitivity = 2;

        // saving the visible window time parametrs
        this._visibleWindowTimeParams = {
            'startOffset': 0,
            'endOffset': 0,
            'duration': 0
        };

        // Current info details
        this._currentViewDetails = {
            'date': 0,
            'zoom': 0,
            'ampl': 0,
            'timeSpan': 0,
            'currentDetailsSeperator': bcGlobals.locale.currentDetailsSeperator,
            'timeSpanFromStart': bcGlobals.locale.timeSpanFromStart
        };

        // Current view details template
        this._currentViewDetailsTemplate = '#bcv_current_info_no_date';

        // Init tooltip
        this._canvas.initTooltip({ hide: false });
        this._tooltipPosition = "top-35";

        // Set the ecg viewer position
        $$('.bcv_ecg_viewer').css('top', this._viewer.config.TOP);
        $$('.bcv_ecg_viewer').css('bottom', this._viewer.config.BOTTOM);

        // Indicates if measuring mode is active
        this._isMeasuringMode = false;

        // Indicates if user marks mode is active
        this._isUserMarksAddMode = false;

        // Keep the current hovered user mark index
        this._userMarkHoverIndex;

        // Indicates if measuring
        this._isMeasuring = false;

        // Indicates if in magnify mode
        this._isMagnifyMode = false;

        // Indicates dragging while measuring
        this._isDragWhileMeasuring;
        this._isMeasuringWhileDrag;

        // Indicates if measurement need init
        this._measurementNeedInit = true;

        // Indicates if user mark placer need init
        this._userMarkPlacerNeedInit = true;

        // Index of currently edited user mark in the marks array
        this._userMarkEditIndex = -1;

        // Save the user marks before edit so we can rollback on cancel
        this._userMarksBeforeChange = null;

        // Position of mouse down event
        this._mouseDownPos;

        // Indicates if real time lead names are visible
        this._RTLeadNamesVisible = false;

        // Indicates if measurements need to be cleared
        this._shouldClearMeasurements = false;

        this._shiftModes = {
            'SHIFT_UP': 0,
            'DRAG_WHILE_MEASURE': 1,
            'MEASURE_WHILE_DRAG': 2,
            'IN_MAGNIFY_MODE': 3
        };

        this._currentShiftMode = this._shiftModes.SHIFT_UP;

        // Lead names configurations
        this._leadNamesConfig = {
            'xOffset': 10,
            'padding': 5,
            'minY': 23,
            'labelStyle': {
                'fillStyle': '#000',
                'textBaseline': 'bottom',
                'font': '10px sans-serif'
            }
        };
        this._numberOfFullLeads = 0;

        // Save the scrollbars width
        this._scrollbarsWidth = this._utils.getScrollbarsWidth();

        // reference to warning function
        this._notifyOnAsystole = null;

        if (this._bcViewer.mode == this._bcViewer.modes.IN_APP) {
            this._asystoleWarningAudio = new Audio('beep_lead_off.wav');
        }

        this._shouldRealtimeCanvasInit = true;

        this._inFullscreen = false;

        this._measurementBoundingBox = {
            startX: 0,
            startY: 0,
            endX: 0,
            endY: 0
        };

        this._measurementManipStartPos;
        this._measurementStartingPosLoc;
        this._measurementManipSensitivity = 5;

        this._measurementManipModes = {
            MOVE: 0,
            N_RESIZE: 1,
            S_RESIZE: 2,
            W_RESIZE: 3,
            E_RESIZE: 4,
            SW_RESIZE: 5,
            SE_RESIZE: 6,
            NW_RESIZE: 7,
            NE_RESIZE: 8
        };

        this._measurementManipMode = null;

        this._measurementResults = {
            HR: null,
            width: null,
            height: null
        },

        this._saveMeasurementInited = false;
        this._saveMeasurementMargin = 5;
        this._hasSavedMeasurementValues = false;
        this._measurementValues;

        if (this._bcViewer.doctorStudyEdits.measurement_values) {
            this._measurementValues = JSON.parse(this._bcViewer.doctorStudyEdits.measurement_values);
            this._hasSavedMeasurementValues = true;
            this._drawMeasurementSaveResults();
        } else {
            this._measurementValues = {
                                    HR: null,
                                    P: null,
                                    P_MV: null,
                                    PR: null,
                                    QRS: null,
                                    R: null,
                                    QT: null
                                };
        }
    },

    /*
        Start listen to UI events
    */
    _registerToEvents: function() {
        $$('#bc_viewer .bcv_zoom_x_controls').delegate('button', 'click', $.proxy(this.onZoomChangeRequest, this, 'zoomX', 'button'));
        $$('#bc_viewer .bcv_zoom_y_controls').delegate('button', 'click', $.proxy(this.onZoomChangeRequest, this, 'zoomY', 'button'));

        $$('#bc_viewer .bcv_zoom_x_list').on('change', $.proxy(this.onZoomChangeRequest, this, 'zoomX', 'list'));
        $$('#bc_viewer .bcv_zoom_y_list').on('change', $.proxy(this.onZoomChangeRequest, this, 'zoomY',' list'));

        $$('.bcv_baseline_correction_button').on('click', $.proxy(this.onBaselineCorrectionRequest, this));
        $$('.bcv_smooth_button').on('click', $.proxy(this.onSmoothRequest, this));
        $$('.bcv_measure_button').on('click', $.proxy(this.onMeasureClicked, this));
        $$('.bcv_magnify_button').on('click', $.proxy(this.onMagnifyClicked, this));
        $$('.bcv_qrs_mark_hr_button').on('click', $.proxy(this.onQrsMarkHrClicked, this));

        $$('#bc_viewer .bcv_main_canvas').on('mousedown touchstart', $.proxy(this.onMouseDown, this));
        $$('#bc_viewer .bcv_main_canvas').on('mouseup touchend', $.proxy(this.onMouseUp, this));
        $$('#bc_viewer .bcv_main_canvas').on('mousemove touchmove', $.proxy(this.onMouseMove, this));
        $$('#bc_viewer .bcv_main_canvas').on('mouseout touchcancel', $.proxy(this.onMouseUp, this));

        $$('#bc_viewer .bcv_channel_expansion input').on('click', $.proxy(this.onExpand7ChannelsClicked, this));
        $$('#bc_viewer .bcv_channel_expansion_12 input').on('click', $.proxy(this.onExpand12ChannelsClicked, this));

        $$('#bc_viewer .bcv_decrease_spacing').on('click', $.proxy(this.onChannelsSpacingClicked, this, -1));
        $$('#bc_viewer .bcv_increase_spacing').on('click', $.proxy(this.onChannelsSpacingClicked, this, 1));

        $$(document).on('keydown', $.proxy(this.onKeyDown, this));
        $$(document).on('keyup', $.proxy(this.onKeyUp, this));

        $$('#bc_viewer .bcv_main_canvas').on('click', $.proxy(this._onCanvasClick, this));

        $$('.bcv_fullscreen_button').on('click', $.proxy(this.onFullscreenClicked, this));
        $$(document).on('fullscreenchange mozfullscreenchange webkitfullscreenchange MSFullscreenChange', $.proxy(this.onFullscreenChanged, this));

        $$('.bcv_dropdownmenu_content').menu();

        // hack to prevent menu from closing on click
        $$('.bcv_dropdownmenu_content').on('mouseenter', function(e) {
            $$('#bcv_dropdownmenu_filters').click();
        });
        $$('.bcv_dropdownmenu').on('click', $.proxy(this._onDropdownmenuClick, this));

        $$('.bcv_measurement_save_result ul').on('mouseenter', 'li', $.proxy(this._onMeasurementResultsEnter, this));
        $$('.bcv_measurement_save_result ul').on('mouseleave', function() {
            $('.bcv_measurement_save_result ul .bcv_measurement_save_result_clear').hide();
        });
        $$('.bcv_measurement_save_result ul').on('click', '.bcv_measurement_save_result_clear', $.proxy(this._onMeasurementResultClear, this));

        $$('#bcv_user_mark_save').on('click', $.proxy(this._onUserMarkSave, this));
        $$('#bcv_user_mark_remove').on('click', $.proxy(this._onUserMarkRemove, this));
        $$('#bcv_user_mark_cancel').on('click', $.proxy(this._onUserMarkCancel, this));
    },

    /*
        Handle state changes
    */
    onStateChange: function(stateChanges, isInitCycle) {
        // Make sure we don't reference old hover after state change
        delete this._annotationHoverX;

        !this._bcViewer.isRealtimeMode && this.clearMeasurements(true);

        return this._super.apply(this, arguments);
    },

    /*
        Map all the annotation x's to their data so we can implement hover
    */
    _buildQRSDataMap: function() {
        // Update the annotations data
        var qrsData = this._model.getQRSAnnotations();
        delete this._qrsDataMap;
        this._qrsDataMap = {};

        var cacheTimeWindow = this._model.getCacheTimeWindow();
        // How many pixels we have from the begining of the cache to the begining
        // of the visible window
        var pixelsOffsetBeforeVisibleWindow = this._model.convertTimeToPixels(cacheTimeWindow.visibleWindowStartTime - cacheTimeWindow.startTime, this._getState());

        // Duplicating qrsData x's to suport hover sensitivity
        // seems like it's better to use a littble bit more memory in order to save running time
        // This is a little bit inefficient since we're doing it for all of the qrs data, even if it's outside of
        // the visible area
        for (var i=0; i<qrsData.length; i++) {
            // Visible window x
            var x = Math.round(qrsData[i].x - pixelsOffsetBeforeVisibleWindow);
            this._qrsDataMap[x] = qrsData[i];
            this._qrsDataMap[x].x = x; // Keep visible window x
            for (var o=1; o<=this._annotationHoverSensitivity; o++) {
                this._qrsDataMap[x+o] = this._qrsDataMap[x-o] = this._qrsDataMap[x];
            }
        }
    },

    /*
        Handler for getting new ECG data from the model
    */
    onGotECGData: function(response) {
        this._notWaitingForECGData = true;

        this._super.apply(this, arguments);

        this._drawLeadNames();

        this._drawScale();

        this._buildQRSDataMap();

        if (this._bcViewer.getStudyType() === this._bcViewer._studyTypes.REST) {
            this._buildShowHideLeadsMenu();
        }

        this._measurementNeedInit = true;
        this._userMarkPlacerNeedInit = true;

        var bcViewer = this._viewer._bcViewer;
        if (bcViewer.fullDisclosureViewer && this._viewer.name == 'main' && bcViewer._isReadyToPrintTriggered) {
           bcViewer.fullDisclosureFrameReady();
        }
    },

    /*
        Handle zoom changes from the UI
    */
    onZoomChangeRequest: function(zoomAxis, eventSource, event) {
        var changeRequest = {};
        if (eventSource === 'button') {
            changeRequest[zoomAxis] = parseInt($(event.target).attr('value'));
        } else if(eventSource == 'menu') {
            var li = $(event.target).closest('li');
            changeRequest[zoomAxis] = parseInt(li.attr('data-value'));
        } else {
            changeRequest[zoomAxis] = parseInt($(event.target).find(':selected').val());
        }

        if (zoomAxis === 'zoomY') {
            changeRequest['doesMainViewerGotScrollbars'] = changeRequest.zoomY > this._viewer.MIDDLE_ZOOM_Y;
            changeRequest['width'] = $$('#bc_viewer').width();
        }
        this._viewer.onStateChangeRequest(changeRequest);
    },

    /*
        Handle zoom changes from the controller
    */
    _onZoomChange: function(state, zoomContainer, zoomAxis) {
        zoomContainer.find('.bcv_control_button').each(function() {
            $(this).attr('disabled', ($(this).attr('value') == state[zoomAxis]));
        });

        var menuZoom = $$('#bcv_dropdownmenu_' + zoomAxis + ' ul');
        menuZoom.children('li').removeAttr('selected');
        menuZoom.find('[data-value=' + state[zoomAxis] + ']').attr('selected', 'selected');
    },

    /*
        Handle zoomX changes from the controller
    */
    onZoomXChange: function(state) {
        this._onZoomChange(state, $$('#bc_viewer .bcv_zoom_x_controls'), 'zoomX');
        this._currentViewDetails.zoom = $.tmpl(bcGlobals.locale.currentViewZoom, {'zoom': state['zoomX']}).text();
        this._updateCurrentViewDetails();
        $$('.bcv_zoom_x_list').val(state['zoomX']);
        this._keyDragMsInterval = (2500 / state['zoomX']); // Swipe in the same speed for all zooms.

        $('.bcv_control_button').blur();
    },

    /*
        Handle zoomY changes from the controller
    */
    onZoomYChange: function(state) {
        this._onZoomChange(state, $$('#bc_viewer .bcv_zoom_y_controls'), 'zoomY');
        this._currentViewDetails.ampl = $.tmpl(bcGlobals.locale.currentViewAmpl, {'ampl': state['zoomY']}).text();
        this._updateCurrentViewDetails();
        $$('.bcv_zoom_y_list').val(state['zoomY']);

        $('.bcv_control_button').blur();
    },

    /*
        Handle smooth changes from the controller
    */
    onSmoothChange: function(state) {
        this._changeButtonState('.bcv_smooth_button', state.smooth);
        this._changeMenuItemState('#bcv_dropdownmenu_filters ul li[data-action="smooth"]', state.smooth);

    },

    /*
        Handle baseline correction changes from the controller
    */
    onBaselineCorrectionChange: function(state) {
        this._changeButtonState('.bcv_baseline_correction_button', state.baselineCorrection);
        this._changeMenuItemState('#bcv_dropdownmenu_filters ul li[data-action="baseline_correction"]', state.baselineCorrection);
    },

    /*
        Handle qrs mark value changes from the controller
    */
    onQrsMarkValueChange: function(state) {
        var hrMarks = (state.qrsMarkValue === 'hr');
        this._changeButtonState('.bcv_qrs_mark_hr_button', hrMarks);
        this._changeMenuItemState('#bcv_dropdownmenu_qrs_marks ul li[data-value="hr"]', hrMarks);
        this._changeMenuItemState('#bcv_dropdownmenu_qrs_marks ul li[data-value="rr"]', !hrMarks);
    },


    /*
      handle window resizing
    */
    onWindowStartResizing: function(isWidthChanged, isHeightChanged) {
        if (this._bcViewer.mode === this._bcViewer.modes.IN_APP) return;

        if (isWidthChanged || isHeightChanged) {
            this._canvas.clear();
        }
    },

    /*
        Handle filter changes
    */
    _onFilterChange: function(buttonSelector, filterName) {
        var isSelected = $$(buttonSelector).attr('selected');

        this._changeButtonState(buttonSelector, !isSelected);

        var stateRequest = {};
        stateRequest[filterName] = !isSelected;
        this._viewer.onStateChangeRequest(stateRequest);
    },

    /*
        Change the button state
    */
    _changeButtonState: function(buttonSelector, state) {
        $$(buttonSelector).attr('selected', state);
    },

    _changeMenuItemState: function(itemSelector, state) {
        $$(itemSelector).attr('selected', state);
    },

    /*
        Handle baseline correction button click
    */
    onBaselineCorrectionRequest: function() {
        this._onFilterChange('.bcv_baseline_correction_button', 'baselineCorrection');
    },

    /*
        Handle smooth button click
    */
    onSmoothRequest: function() {
        this._onFilterChange('.bcv_smooth_button', 'smooth');
    },

    /*
        Handle measure button clicked.
    */
    onMeasureClicked: function(event) {
        var isSelected = !$$('.bcv_measure_button').attr('selected');
        this._toggleMeasurement(isSelected);
        this._toggleMagnifyMode(false);
    },

    /*
        Handle magnify button clicked.
    */
    onMagnifyClicked: function(event) {
        var isSelected = !$$('.bcv_magnify_button').attr('selected');
        this._toggleMagnifyMode(isSelected);
        this._toggleMeasurement(false);
    },

    /*
        Handle qrs mark hr button clicked.
    */
    onQrsMarkHrClicked: function(event, val) {
        var isSelected = !$$('.bcv_qrs_mark_hr_button').attr('selected');
        var stateValue = val || (isSelected ? 'hr' : 'rr');
        this._viewer.onStateChangeRequest({
            'qrsMarkValue': stateValue
        });

        var buttonState = val ? (val == 'hr') : isSelected;
        this._changeButtonState('.bcv_qrs_mark_hr_button', buttonState);
    },

    /*
        Init the canvas and the text
    */
    _initMeasurements: function() {
        this._measurementNeedInit = false;
        this._canvas.initMeasurement();
        $$('.bcv_measurement_result').show();
        $$('.bcv_measurement_result').text('');
    },

    /*
        Clear the canvas and hide the result text
    */
    clearMeasurements: function(needToClearCanvas) {
        if (!this._shouldClearMeasurements) return;

        if (needToClearCanvas) {
             this._canvas.clearMeasurements();
        }
        $$('.bcv_measurement_result').hide();
        delete this._measurementBoundingBox;

        $('.bcv_measurement_save select').val('default');
        $$('#bc_viewer').removeClass('measurement_save_mode');

        this._measurementBoundingBox = {startX: 0, startY: 0, endX: 0, endY: 0};
        this._measurementManipMode = null;
        var manipClass = 'measurement_canvas_';
        for (var manipMode in this._measurementManipModes) {
          $$('.bcv_main_canvas').removeClass(manipClass + manipMode.toLowerCase());
        }

        this._bcViewer.removeParamsFromHash(['measurement']);

        this._shouldClearMeasurements = false;
    },

    /*
        start\stop measuring according to activateMeasurement parameter
    */
    _toggleMeasurement: function(activateMeasurement) {
        $$('.bcv_measure_button').attr('selected', activateMeasurement);
        this._isMeasuringMode = activateMeasurement;
        $$('.bcv_main_canvas').toggleClass('measurement_canvas', activateMeasurement);
        $$('.bcv_dropdownmenu_measurement').attr('selected', activateMeasurement);
        if (this._currentShiftMode !== this._shiftModes.DRAG_WHILE_MEASURE) {
          this._toggleDisableUserMarksAdd(activateMeasurement);
        }

        if (activateMeasurement) {
            this._showMeasurementSaveResults();
            this._showMeasurementSave();
        } else {
            $$('#bc_viewer').removeClass('measurement_save_result_mode');
            $$('#bc_viewer').removeClass('measurement_save_mode');
        }
    },

    _toggleDisableMeasurements: function(shouldDisable) {
      $$('.bcv_dropdownmenu_measurement').attr('disabled', shouldDisable);
      $$('.bcv_dropdownmenu_measurement').toggleClass('ui-state-disabled', shouldDisable);
      $$('.bcv_measure_button').attr('disabled', shouldDisable);
    },

    _toggleDisableUserMarksAdd: function(shouldDisable) {
      $$('.bcv_dropdownmenu_user_marks').attr('disabled', shouldDisable);
      $$('.bcv_dropdownmenu_user_marks').toggleClass('ui-state-disabled', shouldDisable);
    },

    /*
        start\stop user marks mode
    */
      _toggleUserMarksAdd: function(activateUserMarks) {
        this._isUserMarksAddMode = activateUserMarks;
        $$('.bcv_dropdownmenu_user_marks').attr('selected', activateUserMarks);

        activateUserMarks && this._toggleDisableMeasurements(true);
    },

    /*
        start\stop magnify mode
    */
    _toggleMagnifyMode: function(activateMagnifyMode) {
        this._toggleCanvasMagnifyShrink(false);
        $$('.bcv_magnify_button').attr('selected', activateMagnifyMode);
        this._isMagnifyMode = activateMagnifyMode;
        $$('.bcv_main_canvas').toggleClass('magnify_mode_canvas', activateMagnifyMode);
    },

    /*
        handle visible time change
    */
    _onCurrentVisibleWindowChange: function(visibleWindowStartTime, visibleWindowEndTime, visibleWindowDuration) {
       this._visibleWindowTimeParams.startOffset = visibleWindowStartTime;
       this._visibleWindowTimeParams.endOffset = visibleWindowEndTime;
       this._visibleWindowTimeParams.duration = visibleWindowDuration;

       this._updateCurrentVisibleWindowTime(this._visibleWindowTimeParams);
    },

    /*
        update the current visible time
    */
    _updateCurrentVisibleWindowTime: function(timeParams) {
        var timeFormat = bcGlobals.locale.timeFormat;

        var startTime = this._viewer.getStartTime();

        // if there isn't start time in the record, show time offset from start
        var visibleWindowStartTime = (startTime === 0) ?
                                      this._utils.formatTimeDuration(timeParams.startOffset, timeFormat) :
                                      this._utils.formatTime(startTime + timeParams.startOffset, timeFormat);

        var visibleWindowEndTime = (startTime === 0) ?
                                    this._utils.formatTimeDuration(timeParams.endOffset, timeFormat) :
                                    this._utils.formatTime(startTime + timeParams.endOffset, timeFormat);

        this._currentViewDetails.timeSpan = $.tmpl(bcGlobals.locale.timeSpanFormat, {'startTime': visibleWindowStartTime, 'endTime': visibleWindowEndTime}).text();

        this._currentViewDetails.timeSpanFromStart = $.tmpl(bcGlobals.locale.timeSpanFromStart, {'timeSpan':this._currentViewDetails.timeSpan}).text();

        this._updateCurrentViewDetails();

    },

    /*
        Show the date in the view details
    */
    updateDate: function(startTime) {
        if (startTime !== 0) {
            var dateString = this._utils.formatDate(startTime, bcGlobals.locale.dateFormat);
            this._currentViewDetailsTemplate = '#bcv_current_info_with_date';
            this._currentViewDetails.date = dateString;

            this._updateCurrentVisibleWindowTime(this._visibleWindowTimeParams);
        }
        else {
            this._currentViewDetailsTemplate = '#bcv_current_info_no_date';
       }
    },

    /*
        Update the current view details
    */
    _updateCurrentViewDetails: function() {
        $$('.bcv_current_view_info').html($(this._currentViewDetailsTemplate).tmpl(this._currentViewDetails));
    },

    /*
        Hnalde mouse down event
    */
    onMouseDown: function(event) {

        this._mouseDownPos = this._canvas.getPosFromEvent(event);
        if (this._isMeasuringMode) {
            if (this._measurementManipMode === null) {
            this._onMeasuringStart(event);
            this._isMeasuring = true;
            } else {
                this._onMeasuringManipStart(event);
                this._isMeasuringManip = true;
            }
        } else if (this._isUserMarksAddMode) {
            // nothing
        } else if (this._isMagnifyMode) {
            // nothing
        } else {
            this.onDragStart(event);
        }
    },

    /*
        Handle drag start
    */
    onDragStart: function(event) {
        // preventDefault on douse down will prevent selecting with mouse when leaving the canvas while dragging
        // no need for touch events
        if (event.type == 'mousedown') {
            event.preventDefault();
        }

        if (! this._model.isDragAvailable()) return;

        this.clearMeasurements(false);

        this._model.onDragStart();

        this._dragStartX = event.pageX || event.originalEvent.touches[0].pageX;

        var state = this._getState();

        var halfDuration =  this._visibleWindowTimeParams.duration/2;

        // on the first and last visible windows of the record, if starting drag, keep the time position in the middle of the visible window
        if (state.timePosition < halfDuration) {
            this._viewer.onStateChangeRequest({
                'timePosition': halfDuration
            });
        } else if (state.timePosition > this._viewer._metadata.Duration - halfDuration) {
            this._viewer.onStateChangeRequest({
                'timePosition': this._viewer._metadata.Duration - halfDuration
            });
        }
    },

    /*
        Handle mouse up event
    */
    onMouseUp: function(event) {
        if (this._isMeasuringMode) {
        if (this._isMeasuring) {
            this._onMeasuringStop(event);
            }
            if (this._isMeasuringManip) {
                this._onMeasuringManipStop(event);
            }
            this._showMeasurementSave(event);
        } else if( this._isMagnifyMode) {
            // nothing
        } else if (this._isUserMarksAddMode) {
          var pos = this._canvas.getPosFromEvent(event);
          if (this._mouseDownPos && pos.x === this._mouseDownPos.x && this._mouseDownPos && pos.y === this._mouseDownPos.y) {
            this._onUserMarkAdd(event);
          }
        } else {
            var pos = this._canvas.getPosFromEvent(event);
            var markIndex = -1;
            if (this._userMarkEditIndex === -1 && this._mouseDownPos && pos.x === this._mouseDownPos.x && this._mouseDownPos && pos.y === this._mouseDownPos.y) {
              var posTimestamp1 = this._model.convertVisibleWindowPixelsToTime(pos.x-3, this._getState())
              var posTimestamp2 = this._model.convertVisibleWindowPixelsToTime(pos.x+3, this._getState())
              markIndex = this._getState().userMarks.findIndex((m) => m.x > posTimestamp1 && m.x < posTimestamp2);
            }

            if (markIndex !== -1) {
              this._toggleDisableUserMarksAdd(true);
              this._toggleDisableMeasurements(true);
              this._userMarksBeforeChange = this._getState().userMarks;
              this._showMarkEdit(markIndex, pos.x);
            } else {
              // Drag didn't start
              if (typeof this._dragStartX !== 'undefined') {
                this._viewer.onStateChangeRequest({
                    'timePosition': this._newTimePositionAfterDrag
                });
              }
            }

        }
        delete this._dragStartX;
        delete this._newTimePositionAfterDrag;
        delete this._mouseDownPos;
    },

    /*
        Handle mouse movement on viewer -
        show qrs annotation line if needed
        if drag move - calculate the new time position and broadcast the event
    */
    onMouseMove: function(event) {
        if (this._isMeasuringMode) {
            this._onMeasuringMouseMove(event);
        } else if (this._isUserMarksAddMode)  {
            if (this._userMarkPlacerNeedInit) {
              this._canvas.initUserMarkPlacer();
              this._userMarkPlacerNeedInit = false;
            }
            var pos = this._canvas.getPosFromEvent(event);
            var posTimestamp = this._model.convertVisibleWindowPixelsToTime(pos.x, this._getState());
            var isIllegalPosForMark = (this._getState().userMarks.some((e) => e.x > posTimestamp-250 && e.x < posTimestamp+250));
            this._canvas.drawUserMarkPlacer({
              x: pos.x,
              color: isIllegalPosForMark ? this._config.userMarks.DISABLED_COLOR :  this._config.userMarks.DEFAULT_COLOR
            });
            $$('#bc_viewer').toggleClass('bcv_user_mark_disable_add', isIllegalPosForMark);

        } else {
            this._showQRSAnnotation(event);
            this._highlightUserMarks(event);

            // Drag didn't start
            if (typeof this._dragStartX === 'undefined') return;

            var dragOffset = this.getDragOffset(event.pageX || event.originalEvent.touches[0].pageX);
            var state = this._getState();
            var timeOfDrag = this._model.convertPixelsToTime(dragOffset, state);

            var newTimePosition = Math.max(0, state.timePosition - timeOfDrag);

            // If dragging and there is no data in the newTimePosition, stop dragging, and change time position in order to fetch new data
            if (this._model.canDragToTimePosition(newTimePosition)) {
                this._newTimePositionAfterDrag = newTimePosition;
                this._bcViewer.onDragMove(newTimePosition);
            } else if (this._notWaitingForECGData) {
                this._viewer.onStateChangeRequest({
                    'timePosition': this._newTimePositionAfterDrag
                });

                this._notWaitingForECGData = false;
            }
        }
    },

    /*
        Handle drag move
    */
    onDragMove: function(timePosition) {
        try {
          var state = this._getState();
          var dragOffset = this._model.convertTimeToPixels(state.timePosition - timePosition, state);

          this._model.getECGDataForDrag(dragOffset, $.proxy(this.drawImage, this));

          var timeOfDrag = this._model.convertPixelsToTime(dragOffset, state);
          timeParams = {
              'duration': this._visibleWindowTimeParams.duration,
              'startOffset': this._visibleWindowTimeParams.startOffset - timeOfDrag,
              'endOffset': this._visibleWindowTimeParams.endOffset - timeOfDrag
          };

          $$('#bc_viewer').removeClass('measurement_save_mode');

          this._updateCurrentVisibleWindowTime(timeParams);
        } catch(e) {
        }
    },

    /*
        return drag offset
    */
    getDragOffset: function(pageX) {
        var dragOffset = pageX - this._dragStartX;
        var state = this._getState();
        var startOffsetPX = this._model.convertTimeToPixels(this._visibleWindowTimeParams.startOffset, state);
        var endOffsetPX = this._model.convertTimeToPixels(this._visibleWindowTimeParams.endOffset, state);
        var visibleDurationPX = this._model.convertTimeToPixels(this._visibleWindowTimeParams.duration, state);
        var durationPX = this._model.convertTimeToPixels(this._viewer._metadata.Duration, state);

        // keep dragging in record bounds
        if (startOffsetPX - dragOffset <= 0) {
            dragOffset = startOffsetPX;
        } else if (endOffsetPX - dragOffset >= durationPX) {
              dragOffset = -(durationPX - endOffsetPX);
        }
        return dragOffset;
    },

    _highlightUserMarks: function(e) {
      if (this._userMarkEditIndex !== -1) return;

      var pos = this._canvas.getPosFromEvent(e);
      var posTimestamp1 = this._model.convertVisibleWindowPixelsToTime(pos.x-3, this._getState());
      var posTimestamp2 = this._model.convertVisibleWindowPixelsToTime(pos.x+3, this._getState());
      markIndex = this._getState().userMarks.findIndex((m) => m.x > posTimestamp1 && m.x < posTimestamp2);
      if ((typeof this._userMarkHoverIndex != 'undefined') && (this._userMarkHoverIndex != markIndex)) {
        this._canvas.removeUserMarkHighlight();
        delete this._userMarkHoverIndex;
      }
      if (markIndex !== -1 && this._userMarkHoverIndex != markIndex) {
        x = this._model.convertTimeToVisibleWindowPixels(this._getState().userMarks[markIndex].x, this._getState());
        this._userMarkHoverIndex = markIndex;
        this._canvas.drawUserMarkHighlight(x);
      }
    },

    /*
       show qrs annotation line if needed
    */
    _showQRSAnnotation: function(e) {
        var x = Math.round(e.pageX - this._canvas.$.offset().left);
        // The actual x that we're showing the line at
        var qrsMapX = this._qrsDataMap && this._qrsDataMap[x] && this._qrsDataMap[x].x;

        if ((typeof this._annotationHoverX !== 'undefined')  && (this._annotationHoverX != qrsMapX)) {
            this._canvas.removeAnnotationLine(this._annotationHoverX);
            delete this._annotationHoverX;
            this._canvas.hideTooltip();
        }

        if (qrsMapX && (this._annotationHoverX != qrsMapX)) {
            this._annotationHoverX = qrsMapX;
            this._canvas.drawAnnotationLine(this._qrsDataMap[x]);
            var tooltip = '';
            if (typeof this._qrsDataMap[x].tooltip !== 'undefined') {
                tooltip = this._qrsDataMap[x].tooltip;
            }

            if (tooltip !== '') {
                var ttPos = $.ui.tooltip.prototype.options.position;
                var pos = $.extend (ttPos, {of: {
                                pageX: e.pageX,
                                pageY: e.pageY,
                                preventDefault: $.noop
                            }
                        });

                // Show annotation tooltip
                this._canvas.showTooltip({
                    content: tooltip,
                    position: pos
                });
            }
        }
    },

    /*
        Delete the last annotation
    */
    _deleteLastAnnotation: function() {
        if (typeof this._annotationHoverX !== 'undefined') {
            this._canvas.removeAnnotationLine(this._annotationHoverX);
            delete this._annotationHoverX;
            this._canvas.hideTooltip();
        }
    },

    /*
        Show updating notification on fetch start
    */
    onDataFetchStart: function() {
        $$('.bcv_updating_notification').show();
    },

    /*
        Hide updating notification on fetch end
    */
    onDataFetchEnd: function() {
        $$('.bcv_updating_notification').hide();
    },

    /*
        Hide Loading notification on all viewers ready
    */
    onAllViewersReady: function() {
        $$('.bcv_loading_notification').hide();
    },

    /*
        Handle the init cycle response from the model
    */
    onInitCycle: function(response) {
        try {
          this.drawImage(response.sourceCanvas, response.xOffset, response.yOffset);
        } catch(e) {
        }
    },

    /*
        Handle viewer loading end
    */
    onLoadingEnd: function() {
        if (!this._bcViewer.isRealtimeMode) {
            $$('.bcv_current_view_info').css('display', 'inline-block');
        }
    },

    /*
        Draw the lead names on the ECG canvas
    */
    _drawLeadNames: function() {
        var leadNames = this._model.getLeadNames();
        if (typeof leadNames === 'undefined') return;

        var conf = this._leadNamesConfig;
        var availChannels = Math.min(this._viewer._metadata.NumberOfChannels, leadNames.length);
        for (var i=0; i < availChannels; i++) {
            var nameWidth = this._canvas.getTextWidth(leadNames[i]);
            var points = this._model.getVisibleChannelsPointsInRange(i, conf.xOffset - conf.padding, conf.xOffset + nameWidth + conf.padding);
            if (points.length == 0) return;

            // get the minimum Y value in the points array
            var minY = points[0][1];
            for (var j=1; j < points.length; j++) {
                minY = Math.min(points[j][1], minY);
            }

            this._canvas.drawLabel(leadNames[i], conf.xOffset, Math.max(minY - conf.padding, conf.minY), conf.labelStyle);
        }
    },

    /*
        Disable the viewer
    */
    disableViewer: function() {
        $$('.bcv_filters_group button').prop('disabled', true);
        $$('.bcv_dropdownmenu').attr('disabled', true);

        this._super.apply(this, arguments);
    },

    /*
        Enable the viewer
    */
    enableViewer: function() {
        $$('.bcv_filters_group button').removeProp('disabled');
        $$('.bcv_dropdownmenu').removeAttr('disabled');

        this._bcViewer.setSpacingButtonsState();
        this._super.apply(this, arguments);
    },

    _onMeasuringManipStart: function(event) {
        var pos = this._canvas.getPosFromEvent(event);
        this._measurementManipStartPos = pos;
        this._measurementStartingPosLoc = this._getBoundingBoxStartingPointLocation(this._measurementBoundingBox);
    },

    /*
        Handle measuring start
    */
    _onMeasuringStart: function(event) {
        $('.bcv_measurement_save select').val('default');
        $$('#bc_viewer').removeClass('measurement_save_mode');

        if (this._measurementNeedInit) {
            this._initMeasurements();
        }
        event.preventDefault();

        var pos = this._canvas.getPosFromEvent(event);
        this._measurementBoundingBox.startX = pos.x;
        this._measurementBoundingBox.startY = pos.y;

        $$('#bc_viewer').removeClass('measurement_save_result_mode');
    },

    _updateMeasure: function(event) {
        var pos = this._canvas.getPosFromEvent(event);
        this._measurementBoundingBox.endX = pos.x;
        this._measurementBoundingBox.endY = pos.y;
        this._measure();
    },

    _getMeasureManipMode: function(event) {
        var pos = this._canvas.getPosFromEvent(event);

        var minX = Math.min(this._measurementBoundingBox.startX, this._measurementBoundingBox.endX);
        var minY = Math.min(this._measurementBoundingBox.startY, this._measurementBoundingBox.endY);
        var maxX = Math.max(this._measurementBoundingBox.startX, this._measurementBoundingBox.endX);
        var maxY = Math.max(this._measurementBoundingBox.startY, this._measurementBoundingBox.endY);

        var measureManipMode;

        if (Math.abs(pos.x - minX) < this._measurementManipSensitivity) {
            if (Math.abs(pos.y - minY) < this._measurementManipSensitivity) {
                return this._measurementManipModes.NW_RESIZE;
            }
            if (Math.abs(pos.y - maxY) < this._measurementManipSensitivity) {
                return this._measurementManipModes.SW_RESIZE;
            }

            if ((pos.y > minY) && (pos.y < maxY)) {
                return this._measurementManipModes.W_RESIZE;
            }
        }

        if (Math.abs(pos.x - maxX) < this._measurementManipSensitivity) {
            if (Math.abs(pos.y - minY) < this._measurementManipSensitivity) {
                return this._measurementManipModes.NE_RESIZE;
            }
            if (Math.abs(pos.y - maxY) < this._measurementManipSensitivity) {
                return this._measurementManipModes.SE_RESIZE;
            }

            if ((pos.y > minY) && (pos.y < maxY)) {
                return this._measurementManipModes.E_RESIZE;
            }
        }

        if ((pos.x > minX) && (pos.x < maxX)) {
            if (Math.abs(pos.y - minY) < this._measurementManipSensitivity) {
                return this._measurementManipModes.N_RESIZE;
            }
            if (Math.abs(pos.y - maxY) < this._measurementManipSensitivity) {
                return this._measurementManipModes.S_RESIZE;
            }
        }

        if ((pos.x > minX) && (pos.x < maxX) &&
            (pos.y > minY) && (pos.y < maxY)) {
            return this._measurementManipModes.MOVE;
        }

        return null;
    },

    _getBoundingBoxStartingPointLocation: function(boundingBox) {
        var spLoc;
        if (boundingBox.startX < boundingBox.endX) {
            if (boundingBox.startY < boundingBox.endY) {
                spLoc = 'NW';
            } else {
                spLoc = 'SW';
            }
        } else {
            if (boundingBox.startY < boundingBox.endY) {
                spLoc = 'NE';
            } else {
                spLoc = 'SE';
            }
        }
        return spLoc;
    },

    _onMeasuringManipMouseMove: function(event) {
        $$('#bc_viewer').removeClass('measurement_save_mode');

        var startPos = this._measurementManipStartPos;
        var pos = this._canvas.getPosFromEvent(event);

        var xDiff = pos.x - startPos.x;
        var yDiff = pos.y - startPos.y;

        if (this._measurementManipMode === this._measurementManipModes.MOVE) {
                this._measurementBoundingBox.startX += xDiff;
                this._measurementBoundingBox.endX += xDiff;
                this._measurementBoundingBox.startY += yDiff;
                this._measurementBoundingBox.endY += yDiff;
        }
        if ((this._measurementManipMode === this._measurementManipModes.N_RESIZE) ||
            (this._measurementManipMode === this._measurementManipModes.NW_RESIZE) ||
            (this._measurementManipMode === this._measurementManipModes.NE_RESIZE)) {
            if ((this._measurementStartingPosLoc === 'NW') ||
                    (this._measurementStartingPosLoc === 'NE')) {
                this._measurementBoundingBox.startY = pos.y;
            } else {
                this._measurementBoundingBox.endY = pos.y;
            }
        }
        if ((this._measurementManipMode === this._measurementManipModes.S_RESIZE) ||
            (this._measurementManipMode === this._measurementManipModes.SW_RESIZE) ||
            (this._measurementManipMode === this._measurementManipModes.SE_RESIZE)) {
            if ((this._measurementStartingPosLoc === 'NW') ||
                (this._measurementStartingPosLoc === 'NE')) {
                this._measurementBoundingBox.endY = pos.y;
            } else {
                this._measurementBoundingBox.startY = pos.y;
            }
        }
        if ((this._measurementManipMode === this._measurementManipModes.W_RESIZE) ||
            (this._measurementManipMode === this._measurementManipModes.SW_RESIZE) ||
            (this._measurementManipMode === this._measurementManipModes.NW_RESIZE)) {
            if ((this._measurementStartingPosLoc === 'NW') ||
                (this._measurementStartingPosLoc === 'SW')) {
                this._measurementBoundingBox.startX = pos.x;
            } else {
                this._measurementBoundingBox.endX = pos.x;
            }
        }
        if ((this._measurementManipMode === this._measurementManipModes.E_RESIZE) ||
            (this._measurementManipMode === this._measurementManipModes.NE_RESIZE) ||
            (this._measurementManipMode === this._measurementManipModes.SE_RESIZE)) {
            if ((this._measurementStartingPosLoc === 'NW') ||
                (this._measurementStartingPosLoc === 'SW')) {
                this._measurementBoundingBox.endX = pos.x;
            } else {
                this._measurementBoundingBox.startX = pos.x;
            }
        }

        this._measure();

        this._measurementManipStartPos = pos;
    },

    _onMeasuringMouseMove: function(event) {
        if (this._isMeasuring) {
            this._updateMeasure(event);
        } else if (this._isMeasuringManip) {
            this._onMeasuringManipMouseMove(event);
        } else {
            var manipClass = 'measurement_canvas_';

            // Clear all manip classes
            var measureManipClass = 'measurement_canvas_';
            var canvasClasses = $$('.bcv_main_canvas').attr('class');
            var regex = new RegExp("(^|\\s)" + measureManipClass + "\\S+", 'g');
            $$('.bcv_main_canvas').attr('class', canvasClasses.replace(regex, ''));

            this._measurementManipMode = this._getMeasureManipMode(event);
            if (this._measurementManipMode == null) return;

            for (var manipMode in this._measurementManipModes) {
                if (this._measurementManipMode === this._measurementManipModes[manipMode]) {
                    manipClass += manipMode.toLowerCase();
                }
            }
            $$('.bcv_main_canvas').addClass(manipClass);
        }
    },

    _onMeasurementSave: function(event) {
        this._hasSavedMeasurementValues = true;
        var selectedType = event.target.value;
        if (selectedType === 'HR') {
            this._measurementValues[selectedType] = this._measurementResults.HR;
        } else if (selectedType === 'R') {
            this._measurementValues[selectedType] = this._measurementResults.height / 1000;
        } else if (selectedType === 'P') {
            this._measurementValues[selectedType] = this._measurementResults.width;
            this._measurementValues['P_MV'] = this._measurementResults.height / 1000;
        } else {
            this._measurementValues[selectedType] = this._measurementResults.width;
        }
        this._drawMeasurementSaveResults();
        this._bcViewer.callbacks && this._bcViewer.callbacks.onMeasurementValuesChange && this._bcViewer.callbacks.onMeasurementValuesChange(this._bcViewer.getRecordHash(), this._measurementValues);
    },

    _drawMeasurementSaveResults: function() {
        var list = '';
        list = $('#bcv_measurement_save_result_list').tmpl(this._measurementValues);
        $$('.bcv_measurement_save_result').empty().append(list);
        if (this._isMeasuringMode) {
            this._showMeasurementSaveResults();
        }
    },

    _showMeasurementSaveResults: function() {
        if (!this._hasSavedMeasurementValues) return;

        var resultsProximity = 200;

        var showResultsOnRightSide = false;
        var bb = this._measurementBoundingBox;
        if (((bb.startX < resultsProximity && bb.startX > 0) &&
            (bb.startY < resultsProximity && bb.startY > 0)) ||
            ((bb.endX < resultsProximity && bb.endX > 0) &&
            (bb.endY < resultsProximity && bb.endY > 0))) {
            showResultsOnRightSide = true;
        }

        // In case of a measurement that starts from the left all the way to the right side
        var canvasWidth = $$('.bcv_main_canvas').width();
        if ((bb.startX < resultsProximity && bb.startY < resultsProximity) &&
            (Math.abs(bb.endX - canvasWidth) < resultsProximity  && bb.endY < resultsProximity)) {
            showResultsOnRightSide = false;
        }

        if (showResultsOnRightSide) {
            $$('.bcv_measurement_save_result').removeClass('bcv_show_on_left');
            $$('.bcv_measurement_save_result').addClass('bcv_show_on_right');
        } else {
            $$('.bcv_measurement_save_result').addClass('bcv_show_on_left');
            $$('.bcv_measurement_save_result').removeClass('bcv_show_on_right');
        }
        $$('#bc_viewer').addClass('measurement_save_result_mode');
    },

    _onMeasurementResultsEnter: function(event) {
        $('.bcv_measurement_save_result_clear').hide();
        var type = $(event.currentTarget).attr('data-type');
        if (this._measurementValues[type] !== null) {
            $(event.currentTarget).children('.bcv_measurement_save_result_clear').show();
        }
    },

    _onMeasurementResultClear: function(event) {
        var type = $(event.currentTarget).parent().attr('data-type');
        this._measurementValues[type] = null;
        this._drawMeasurementSaveResults();
        var hasValues = false;
        for (type in this._measurementValues) {
            if (this._measurementValues[type] !== null) {
                hasValues = true;
                break;
            }
        }
        this._hasSavedMeasurementValues = hasValues;
        !this._hasSavedMeasurementValues && $$('#bc_viewer').removeClass('measurement_save_result_mode');
        this._bcViewer.callbacks && this._bcViewer.callbacks.onMeasurementValuesChange && this._bcViewer.callbacks.onMeasurementValuesChange(this._bcViewer.getRecordHash(), this._measurementValues);
    },

    /*
        Creating the save tooltip on the fly as I want it to be a child of the body and not inside the viewer
    */
    _initSaveMeasurement: function() {
        this._saveMeasurementInited = true;

        $$('.bcv_measurement_save').on('change', $.proxy(this._onMeasurementSave, this));
    },

    _showMeasurementSave: function(event) {
        if (!this._saveMeasurementInited) this._initSaveMeasurement();

        var bb = this._measurementBoundingBox;
        if ((bb.startX === bb.endX) && (bb.startY === bb.endY)) {
            return;
        }

        var padding = parseInt($$('.bcv_measurement_save').css('padding-left'));
        var boxWidth = $$('.bcv_measurement_save').width() + padding*2;
        var boxHeight = $$('.bcv_measurement_save').height()+ padding*2;
        var viewerOffset = $$('#bc_viewer').offset(); // Converting to viewer coordinates
        var canvasOffest = $$('.bcv_main_canvas').offset();

        var top;
        var left;
        try {
            var leftOffset = canvasOffest.left - viewerOffset.left;
            var topOffset = canvasOffest.top - viewerOffset.top;
            top = bb.endY + topOffset;
            left = bb.endX + leftOffset;
        } catch (e) {
            top = 0;
            left = 0;
        }

        var startLoc = this._getBoundingBoxStartingPointLocation(bb);
        if (startLoc == 'NW') {
            top += this._saveMeasurementMargin;
            left -= boxWidth;
        }
        if (startLoc == 'NE') {
            top += this._saveMeasurementMargin;
        }
        if (startLoc == 'SW') {
            top -= (boxHeight + this._saveMeasurementMargin);
            left -= boxWidth;
        }
        if (startLoc == 'SE') {
            top -= (boxHeight + this._saveMeasurementMargin);
        }

        left = Math.max(left, 0);
        left = Math.min(left, $$('.bcv_main_canvas').width()-boxWidth);

        $$('.bcv_measurement_save').css({ top: top, left: left });
        $$('#bc_viewer').addClass('measurement_save_mode');
    },

    _onMeasuringStop: function(event) {
        this._updateMeasure(event);
        this._isMeasuring = false;

        this._bcViewer.updateHash({
            'measurement': [this._measurementBoundingBox.startX,
                            this._measurementBoundingBox.startY,
                            this._measurementBoundingBox.endX,
                            this._measurementBoundingBox.endY].join(',')
        });
        this._showMeasurementSaveResults();
    },

    _onMeasuringManipStop: function(event) {
        this._isMeasuringManip = false;

        this._bcViewer.updateHash({
            'measurement': [this._measurementBoundingBox.startX,
                            this._measurementBoundingBox.startY,
                            this._measurementBoundingBox.endX,
                            this._measurementBoundingBox.endY].join(',')
        });
    },

    /*
        calculate the measuring.
    */
    _measure: function() {
        var widthPX = Math.abs(this._measurementBoundingBox.startX - this._measurementBoundingBox.endX);
        var heightPX = Math.abs(this._measurementBoundingBox.startY - this._measurementBoundingBox.endY);

        // return if measured 0 height and width
        if ((widthPX === 0) && (heightPX === 0)) {
            $$('.bcv_measurement_result').text('');
            this._canvas.clearMeasurements(true);
            return;
        };

        var width = Math.round(this._model.convertPixelsToTime(widthPX, this._getState()));
        var height = Math.round((this._model._config.PIXEL_FACTOR / this._model._state.zoomY) * heightPX);
        var hr = Math.round(60000 / width);

        this._measurementResults = { HR: hr, width: width, height: height };

        var hrResultString = '';
        if ((hr >= this._viewer.config.MEASUREMENT.MIN_HR) && (hr <= this._viewer.config.MEASUREMENT.MAX_HR)) {
            hrResultString = $.tmpl(bcGlobals.locale.measurementHRResult, {'hr': hr}).text();
        }

        measurementResultText = $.tmpl(bcGlobals.locale.measurementResult, {'zoom': width, 'ampl': height, 'hr_str': hrResultString}).text();

        var spacedResultText = measurementResultText.replace(/\s/g, '\xA0');
        $$('.bcv_measurement_result').text(spacedResultText);

        this._canvas.drawMeasurements(this._measurementBoundingBox, measurementResultText);

        this._shouldClearMeasurements = true;
    },

    /*
        Handle mouse down event
    */
    onKeyDown: function(event) {
        this._downKeys[event.keyCode] = true;
        switch (event.keyCode) {
            case (this._globals.keyCodes.shift):
              if (this._currentShiftMode === this._shiftModes.SHIFT_UP && this._userMarkEditIndex == -1 && !this._isUserMarksAddMode) {
                    if (this._isMeasuringMode) {
                        this._currentShiftMode = this._shiftModes.DRAG_WHILE_MEASURE;
                        this._onMeasuringManipStop(event);
                        this._toggleMeasurement(false);
                    } else if (this._isMagnifyMode) {
                        this._currentShiftMode = this._shiftModes.IN_MAGNIFY_MODE;
                        this._toggleCanvasMagnifyShrink(true);
                    } else {
                        this._currentShiftMode = this._shiftModes.MEASURE_WHILE_DRAG;
                        this._deleteLastAnnotation();
                        this._toggleMeasurement(true);
                    }
                }
                this._keyDragMsInterval = this._visibleWindowTimeParams.duration;
                break;
            case (this._globals.keyCodes.leftArrow):
                this._handleKeySwipe(-1);
                break;
            case (this._globals.keyCodes.rightArrow):
                this._handleKeySwipe(1);
                break;
             case (this._globals.keyCodes.esc):
                  (this._userMarkEditIndex !== -1) && this._onUserMarkCancel();
                  break;
        }
    },

    /*
        handle swipe using arrows keys
    */
    _handleKeySwipe: function(directionFactor) {
        if (this._notWaitingForECGData) {
            if (this._downKeys[this._globals.keyCodes.shift] && !this._allowNextPageSwipe) {
                return;
            }
            // If all the study is in displayed in the visible window, no need to swipe
            if (this._viewer._metadata.Duration < this._visibleWindowTimeParams.duration) {
                return;
            }

            if (this._downKeys[this._globals.keyCodes.shift]) {
                this._allowNextPageSwipe = false;
            }

            var state = this._getState();

            // We change the state time position only when key arrow is released,
            // so if the key isnt released yet, we continue calculation from newTimePositionAfterDrag
            var timePosition = this._newTimePositionAfterDrag || state.timePosition;
            var newTimePosition = timePosition + (directionFactor*this._keyDragMsInterval);

            // Half of the duration in a visible window
            var halfDuration = this._visibleWindowTimeParams.duration/2;

            var newStateTimePosition;
            if (state.timePosition < halfDuration) {
                // if Starting from first half of the first window of the study, jump to the middle of the first window
                newStateTimePosition = halfDuration;
            } else if (state.timePosition > this._viewer._metadata.Duration - halfDuration) {
                // if starting from the last half of the last window of the study, jump to the middle of the last window
                newStateTimePosition = this._viewer._metadata.Duration - halfDuration
             } else if (this._model.canDragToTimePosition(newTimePosition)) { // Check if we have ECG data in the new time position
                // if the new time position is in the last half of the last window of the study, stay in the middle of the last window
                if(newTimePosition > this._viewer._metadata.Duration - halfDuration) {
                    newTimePosition = this._viewer._metadata.Duration - halfDuration;
                }
                this._newTimePositionAfterDrag = newTimePosition;
                this._bcViewer.onDragMove(newTimePosition);
            } else if(newTimePosition > halfDuration) {
                // If we don't have enough data in the new time posistion, we need to update the state and fetch new data.
                // If we swipe to the first half of the first window of the study, no need to fetch data
                newStateTimePosition = newTimePosition;
            } else if (newTimePosition < halfDuration) {
                this._newTimePositionAfterDrag = halfDuration;
                this._bcViewer.onDragMove(halfDuration);
            }

            // If we need to update the state
            if (newStateTimePosition) {
                this._notWaitingForECGData = false;
                this._viewer.onStateChangeRequest({
                    'timePosition': newStateTimePosition
                });
            }
        }
    },

   /*
        Jump to the next visible window
        return true if we are on the last window
     */
    jumpToNextWindow: function() {
        var state = this._getState();
        var windowDuration = this._visibleWindowTimeParams.duration;
        var newStateTimePosition = state.timePosition;
        var durationWithFilters = this._viewer._metadata.Duration - (this._model._dataFetcher._signalProcessing.getPreMinimumSamples() / this._model._dataFetcher._metadata.SamplingRate)*1000;
        if (this._visibleWindowTimeParams.endOffset >= durationWithFilters) return true;

        if (state.timePosition == 0) {
            newStateTimePosition += windowDuration/2;
        }

        newStateTimePosition += windowDuration;
        this._viewer.onStateChangeRequest({
            'timePosition': newStateTimePosition
        });

        return false;
    },

   /*
        Handle swipe with arrows keys stop
   */
    _handleKeySwipeStop: function() {
        window.clearTimeout(this._keyDragStopTimeout);

        // Handle after some timeout to prevent fetching data if the arrow key is rapidly clicked
        this._keyDragStopTimeout = window.setTimeout($.proxy(function() {
            if (this._notWaitingForECGData && this._newTimePositionAfterDrag) {
                this._viewer.onStateChangeRequest({
                    'timePosition': this._newTimePositionAfterDrag
                });
            }
            delete this._newTimePositionAfterDrag;
        }, this), 200);
    },

    _toggleCanvasMagnifyShrink: function(activate) {
        $$('.bcv_main_canvas').toggleClass('magnify_mode_canvas_shrink', activate);
    },

    /*
        Handle mouse up event
    */
    onKeyUp: function(event) {
        this._downKeys[event.keyCode] = false;

        switch (event.keyCode) {
            case (this._globals.keyCodes.shift):
               if (this._currentShiftMode === this._shiftModes.DRAG_WHILE_MEASURE) {
                   this._deleteLastAnnotation();
                   this._toggleMeasurement(true);
                } else if (this._currentShiftMode === this._shiftModes.MEASURE_WHILE_DRAG) {
                    this._onMeasuringManipStop(event);
                    this._toggleMeasurement(false);
                } else if (this._currentShiftMode == this._shiftModes.IN_MAGNIFY_MODE) {
                    this._toggleCanvasMagnifyShrink(false);
                }

                this._currentShiftMode = this._shiftModes.SHIFT_UP;
                this._keyDragMsInterval = this._keyDragNormalMsInterval;
                break;
            case (this._globals.keyCodes.leftArrow):
            case (this._globals.keyCodes.rightArrow):
                this._handleKeySwipeStop();
                this._allowNextPageSwipe = true;
                break;
        }
    },

    onFullscreenChanged: function(e) {
        this._inFullscreen = !this._inFullscreen;

        this._magnifyTo((this._inFullscreen ? this._bcViewer.defaultPixelPerMM : this._getState().magnify));
        $('#bc_viewer').toggleClass('fullscreen_mode', this._inFullscreen);
        this.clearMeasurements(true);
    },

    /*
        Scale the bc_viewer width to the ecg viewer width.
        If the viewer got scrollbars, reduce the width by the scrollbars width
    */
    scaleWidthToViewer: function(stateChanges) {
        var width = $$(this._canvasSelector).parent().width();

        var changingDimensions = ((typeof stateChanges.width !== 'undefined') || (typeof stateChanges.height !== 'undefined'));
        var notChangingZoomY = (typeof stateChanges.zoomY === 'undefined');

        return width;
    },

    /*
        Prepare viewer for realtime mode
    */
    initRealtimeMode: function() {
        var numberOfPixelsPerBigBlock = this._config.ecgGrid.SMALL_BLOCKS_PER_BIG_BLOCK * this._config.ecgGrid.NUM_PIXELS_PER_SMALL_BLOCK;

        if (this._shouldRealtimeCanvasInit) {
            this._canvas.init(numberOfPixelsPerBigBlock);
            this._shouldRealtimeCanvasInit = false;
        }

        this._showRtHr(0, this._bcViewer.hrStatusCodes.NORMAL);
        this._model.initRealTimeMode();
        this._model.toggleRealtimeMode(true, this._bcViewer.realtimeModeType, $.proxy(this._onRealtimeModeChanged, this));
    },

    /*
        Handle realtime mode changed
    */
    _onRealtimeModeChanged: function(isRealtimeModeActive, samplingRate, unitsPerMV) {
        this._model.onRealtimeModeChanged(isRealtimeModeActive,
                                          this._bcViewer.realtimeModeType,
                                          samplingRate,
                                          unitsPerMV,
                                          $.proxy(this._onRTGotData, this),
                                          $.proxy(this._onRTGotHR, this),
                                          $.proxy(this._onGetRealtimeDataError, this),
                                          $.proxy(this._onWaitingForEcgDataChanged, this));
    },

    /*
        Handle realtime Data
    */
    _onRTGotData: function(rtData, pointsNumber) {
        this._showRTLeadsNames(rtData, pointsNumber);
        this._canvas.draw(rtData, pointsNumber);
    },

    _showRTLeadsNames: function(rtData, pointsNumber) {
        if (this._RTLeadNamesVisible) return;
        this._RTLeadNamesVisible = true;

        var leadNames = this._model.getRTLeadNames();


        var height = $('.bcv_main_canvas').height();
        $('.bcv_realtime_leadnames_container').height(height);

        var leadNamesElements = "";
        var step = height / leadNames.length;
        for (var i=0; i<leadNames.length; i++) {
            var chY = 10+step*i;//Math.max(rtData[i][0] - 30, 20);
           leadNamesElements += '<div class="realtime_lead_name" style="top:' + chY  + 'px">' + leadNames[i] + '</div>';
        }
        $('.bcv_realtime_leadnames_container').append(leadNamesElements);
    },

     /*
        Handle realtime HR
    */
    _onRTGotHR: function(hr, saturation) {
        this._showRtHr(hr.value, hr.status, saturation);
    },

    /*
        Show realtime heart rate value
    */
    _showRtHr: function(hrValue, hrStatus, saturation) {
        var hrText = $.tmpl(bcGlobals.locale.hrResult, {'hrValue': hrValue}).text();

        if (typeof saturation !== 'undefined') {
            hrText = $.tmpl(bcGlobals.locale.hrSaturationResult, {'hrValue': hrValue, 'saturation': saturation}).text();
        }

        $('.bcv_realtime_heartrate').removeClass('hr_warning');
        if (hrStatus !== this._bcViewer.hrStatusCodes.NORMAL) {
            $('.bcv_realtime_heartrate').addClass('hr_warning');

            if ((hrStatus ===  this._bcViewer.hrStatusCodes.ASYSTOLE) &&
                (this._notifyOnAsystole === null)) {
                this._notifyOnAsystole = setInterval($.proxy(function() {
                    // chrome won't play again unless we load audio again....
                    this._asystoleWarningAudio.load();
                    this._asystoleWarningAudio.play();
                }, this), 6000);
            }
        }
        else {
            if (this._notifyOnAsystole !== null) {
                clearInterval(this._notifyOnAsystole);
                this._notifyOnAsystole = null;
            }
        }

        $('.bcv_realtime_heartrate').text(hrText);
    },

    /*
        Handle window unload
    */
    onWindowUnload: function() {
        this._bcViewer.isRealtimeMode && this._model.toggleRealtimeMode(false, this._bcViewer.realtimeModeType);
    },

    /*
        Adjust viewer to mode
    */
    adjustToMode: function() {
        if (this._bcViewer.mode === this._bcViewer.modes.IN_APP) {
            $$('.bcv_baseline_correction_button').removeClass('bcv_sprite').addClass('bcv_sprite_big');
            $$('.bcv_smooth_button').removeClass('bcv_sprite').addClass('bcv_sprite_big');
            $$('.bcv_realtime_record_toggle').removeClass('bcv_sprite').addClass('bcv_sprite_big');
        }
    },

    /*
        Handle hash params
    */
    onGotHashParams: function(params) {
        if (params.measurement) {
            this._onGotMeasurementParams(params.measurement);
        }
    },

    /*
        Handle measurement params
    */
    _onGotMeasurementParams: function(measurementParams) {
        var state = this._getState();

        // verify parameters are valid
        if ((measurementParams.start.x < 0) || (measurementParams.end.x < 0) ||
            (measurementParams.start.x > state.width) || (measurementParams.end.x > state.width) ||
            (measurementParams.start.y < 0) || (measurementParams.end.y < 0) ||
            (measurementParams.start.y > state.height) || (measurementParams.end.y > state.height)) {

            return; // invalid params
        }

        if (this._measurementNeedInit) {
            this._initMeasurements();
        }

        this._measurementBoundingBox = {
            startX: measurementParams.start.x,
            startY: measurementParams.start.y,
            endX: measurementParams.end.x,
            endY: measurementParams.end.y,
        }
        this._measure();
    },

    /*
        Handle click on show 7 channels checkbox
    */
    onExpand7ChannelsClicked: function(event) {
        this._viewer.onStateChangeRequest({expand3to7: event.target.checked});
    },

    /*
        Mark the checkbox
    */
    onExpand3to7Change: function(state) {
        $$('#bc_viewer .bcv_channel_expansion input').attr('checked', state.expand3to7);
    },

    /*
        Handle click on show 12 channels checkbox
    */
    onExpand12ChannelsClicked: function(event) {
        this._viewer.onStateChangeRequest({expand3to12: event.target.checked});
    },

    /*
        Mark the checkbox
    */
    onExpand3to12Change: function(state) {
        $$('#bc_viewer .bcv_channel_expansion_12 input').attr('checked', state.expand3to12);
    },

	 /*
        Stop realtime mode
    */
    stopRealtimeMode: function() {
        if (this._bcViewer.isRealtimeMode) {
            clearInterval(this._notifyOnAsystole);
            this._notifyOnAsystole = null;

            this._model.toggleRealtimeMode(false, this._bcViewer.realtimeModeType);
            this._model.onRealtimeModeChanged(false, this._bcViewer.realtimeModeType);
        }
    },

    /*
        Start realtime mode
    */
    startRealtimeMode: function() {
        if (this._bcViewer.isRealtimeMode) {
            this._model.toggleRealtimeMode(true, this._bcViewer.realtimeModeType, $.proxy( function(isRealTimeModeActive, samplingRate, unitsPerMV) {
                this._model.onRealtimeModeChanged(true, this._bcViewer.realtimeModeType, samplingRate, unitsPerMV);
            }, this));
        }
    },

    /*
        Handle error on fetching realtime data
    */
    _onGetRealtimeDataError: function() {
        this.stopRealtimeMode();
        this._bcViewer.viewerApi.onGetRealtimeDataError();
    },

    _onWaitingForEcgDataChanged: function(isWaiting) {
        this._bcViewer.viewerApi.onWaitingForEcgDataChanged(isWaiting);
    },

    _drawScale: function() {
        if (bcGlobals.showScale == "true") {
            var dimensions = this._model.getScaleDimensions();
            this._canvas.drawScale(dimensions);
        }
    },

    onChannelsSpacingClicked: function(factor) {
        this._bcViewer.onChannelsSpacingChangeRequest(factor);
    },

    _magnifyTo: function(num) {
        this._config.ecgGrid.NUM_PIXELS_PER_SMALL_BLOCK = num;
        this._config.PIXELS_SIZE = this._config.ecgGrid.SMALL_BLOCK_SIZE / num;
        this._config.PIXEL_PER_MM = 1 / this._config.PIXELS_SIZE;
        this._config.PIXEL_FACTOR = this._config.PIXELS_SIZE * 1000;
    },

    _onMagnifyRequest: function(direction) {
            var changed = false;
            var magnify = this._config.PIXEL_PER_MM;
        if (direction == 'out') {
                if (magnify > this._viewer.config.MAGNIFY.MIN) {
                    magnify--;
                    changed = true;
                }
            } else if (magnify < this._viewer.config.MAGNIFY.MAX) {
                magnify++;
                changed = true;
            }

            changed && this._viewer.onStateChangeRequest({
                magnify: magnify
            });
    },

    _onCanvasClick: function(event) {
        if (this._isMagnifyMode) {
            var direction = event.shiftKey ? 'out' : 'in';
            this._onMagnifyRequest(direction);
        }
    },

    onMagnifyChange: function(state) {
        // On the viewer's init cycle we dont have the number of channels
        // so we only recalculate the config
        this._magnifyTo(state.magnify);
        if (this._bcViewer._metadata) {
            this._bcViewer.adjustHeightToChannels(this._bcViewer._metadata.NumberOfChannels)
        }

    },

    requestFullscreen: function() {
        var elem = $('#bc_viewer')[0];

        if (elem.requestFullscreen) {
            elem.requestFullscreen();
        } else if (elem.webkitRequestFullscreen) {
            elem.webkitRequestFullscreen();
        } else if (elem.mozRequestFullScreen) {
            elem.mozRequestFullScreen();
        } else if(elem.msRequestFullscreen) {
            elem.msRequestFullscreen();
        }
    },

    exitFullscreen: function() {
        if (document.exitFullscreen) {
            document.exitFullscreen();
        } else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen();
        } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
            document.msExitFullscreen();
        }
    },

    onFullscreenClicked: function() {
        if (this._inFullscreen) {
            this.exitFullscreen();
        } else {
            this.requestFullscreen();
        }
    },

    _getRequestedChannels: function() {
        var leadNames = this._model.getLeadNames(true);
        var state = this._getState();
        var requestedChannels = state.requestedChannels && state.requestedChannels.slice(); // Copy to avoid live reference

        // Init requestedChannels if needed
        if ((typeof requestedChannels === 'undefined') || (requestedChannels.length === 0)) {
            requestedChannels = [];
            for (var i=0; i<leadNames.length; i++) {
                requestedChannels.push(i);
            }
        }
        return requestedChannels;
    },

    _buildShowHideLeadsMenu: function() {
        var leadNames = this._model.getLeadNames(true);
        var requestedChannels = this._getRequestedChannels();

        // Make sure we're building the menu with *all* of the leads
        if (this._numberOfFullLeads === leadNames.length) return;
        this._numberOfFullLeads = leadNames.length;

        var menu = '';
        for (var i=0; i<leadNames.length; i++) {
            var selected = (requestedChannels.indexOf(i) > -1) ? 'selected' : '';
            menu += '<li ' + selected + ' data-action="show_hide_leads" data-value="' + i + '"><div><span class="ui-icon ui-icon-check"></span><span>' + leadNames[i] + '</span></div></li>';
        }
        $('#bcv_dropdownmenu_show_hide_leads ul').empty();
        $('#bcv_dropdownmenu_show_hide_leads ul').append(menu);

        $$('.bcv_dropdownmenu_content').menu('refresh');
    },

    _showHideLeads: function(leadIndex) {
        var leadNames = this._model.getLeadNames(true);
        var requestedChannels = this._getRequestedChannels();

        var showChannel = true;

        // Add or remove leadIndex from requestedChannels
        var leadIndexInArr = requestedChannels.indexOf(leadIndex);
        if (leadIndexInArr > -1) {
            requestedChannels.splice(leadIndexInArr, 1);
            showChannel = false;
        } else {
            requestedChannels.push(leadIndex);
        }

        // Don't allow hiding all channels
        if (requestedChannels.length == 0) return;

        this._changeMenuItemState('#bcv_dropdownmenu_show_hide_leads ul li[data-value="' + leadIndex + '"]', showChannel);

        var stateRequest = {};
        stateRequest['requestedChannels'] = requestedChannels.sort(function(a,b) { return a - b; });
        this._viewer.onStateChangeRequest(stateRequest);
    },

    _onDropdownmenuClick: function(e) {
        var li = $(e.target).closest('li');
        var action = li.attr('data-action');

        function selectInList(isToggleList) {
            var selected;
            if (isToggleList) {
                li.parent().children('li').removeAttr('selected');
                selected = true;
            } else {
                selected = li.attr('selected');
    }
            li.attr('selected', selected);
        };

        switch(action) {
            case 'zoomX':
                selectInList(true);
                this.onZoomChangeRequest('zoomX', 'menu', e);
                break;
            case 'zoomY':
                selectInList(true);
                this.onZoomChangeRequest('zoomY', 'menu', e);
                break;
            case 'smooth': // TBD: onSmoothRequest implementation should be changed after removing the button
                selectInList(false);
                this.onSmoothRequest();
                break;
            case 'baseline_correction': // TBD: onBaselineCorrectionRequest implementation should be changed after removing the button
                selectInList(false);
                this.onBaselineCorrectionRequest();
                break;
            case 'qrs_marks': // TBD: onQRSMarkHrClicked implementation should be changed after removing the button
                selectInList(true);
                this.onQrsMarkHrClicked(null, li.attr('data-value'));
                break;
            case 'zoom_in_out':
                this._onMagnifyRequest(li.attr('data-value'));
                break;
            case 'channels_spacing':
                this.onChannelsSpacingClicked(parseInt(li.attr('data-value')));
                break;
            case 'fullscreen':
                this.onFullscreenClicked();
                break;
            case 'measurement':
                this._toggleMeasurement(!this._isMeasuringMode);
                break;
            case 'user_marks':
                this._toggleUserMarksAdd(!this._isUserMarksAddMode);
                if (!this._isUserMarksAddMode) {
                  this._toggleDisableMeasurements(false);
                  this._canvas.clearUserMarkPlacer();
                }
                break;
            case 'show_hide_leads':
                selectInList(false);
                this._showHideLeads(parseInt(li.attr('data-value')));
                break;
        }
        e.stopPropagation();
    },

    _onUserMarkAdd: function(event) {
      var pos = this._canvas.getPosFromEvent(event);
      var posTimestamp = this._model.convertVisibleWindowPixelsToTime(pos.x, this._getState())
      if (this._getState().userMarks.some((e) => e.x > posTimestamp-250 && e.x < posTimestamp+250)) {
        return;
      }
      this._toggleDisableUserMarksAdd(true);
      this._toggleUserMarksAdd(false)
      this._userMarksBeforeChange = this._getState().userMarks;
      var marks =  $.extend(true, [], this._getState().userMarks);
      marks.push({
        type: 'marker',
        color: this._config.userMarks.DEFAULT_COLOR,
        comment: '',
        x: posTimestamp
      })
      this._viewer.onStateChangeRequest({userMarks: marks});
      $$('#bcv_user_mark_remove').attr('disabled', true);
      this._showMarkEdit(marks.length-1, pos.x);
    },

    _showMarkEdit: function(markIndex, marXPos) {
      this._userMarkEditIndex = markIndex;
      $$('#bcv_user_mark_comment').val(this._getState().userMarks[markIndex].comment);
      $$('#bc_viewer').addClass('user_mark_edit_mode');
      var showOnRight = marXPos < ($$('.bcv_main_canvas').width() / 2);
      $$('.bcv_user_mark_edit_panel').toggleClass('bcv_show_on_right', showOnRight);
      $$('.bcv_user_mark_edit_panel').toggleClass('bcv_show_on_left', !showOnRight);
    },

    _onUserMarkSave: function() {
      var marks =  $.extend(true, [], this._getState().userMarks);
      marks[this._userMarkEditIndex].comment = $$('#bcv_user_mark_comment').val();
      this._onMarksEditDone(marks, true);
    },

    _onUserMarkRemove: function() {
      delete this._userMarkHoverIndex;
      var marks =  $.extend(true, [], this._getState().userMarks);
      marks.splice(this._userMarkEditIndex, 1);
      this._onMarksEditDone(marks, true);
    },

    _onUserMarkCancel: function() {
      this._onMarksEditDone(this._userMarksBeforeChange, false);
    },

    _onMarksEditDone: function(newMarks, notifyToCallbackOnChange) {
      notifyToCallbackOnChange && this._bcViewer.callbacks && this._bcViewer.callbacks.onUserMarksChange && this._bcViewer.callbacks.onUserMarksChange(this._bcViewer.getRecordHash(), newMarks);
      this._viewer.onStateChangeRequest({userMarks: newMarks});
      this._userMarkEditIndex = -1;
      $$('#bcv_user_mark_comment').val('');
      $$('#bc_viewer').removeClass('user_mark_edit_mode');
      this._toggleDisableMeasurements(false);
      this._toggleDisableUserMarksAdd(false);
      delete this._userMarksBeforeChange;
      $$('#bcv_user_mark_remove').attr('disabled', false);
    }
});

}

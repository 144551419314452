import React, { useEffect } from 'react'
import useDynamicStylesheets from '../hooks/useDynamicStylesheets'
import { useLocalization } from './LocalizationProvider'

import '../legacy/lib/jquery'
import '../legacy/lib/jquery.tmpl'
import '../legacy/bcSpiroViewer/bcSpiroViewer'
import '../legacy/bcSpiroViewer/bcSpiroViewerTestManager'
import '../legacy/bcSpiroViewer/bcSpiroViewerTestView'
import '../legacy/bcSpiroViewer/bcSpiroViewerResultsManager'
import '../legacy/bcSpiroViewer/bcSpiroViewerResultsView'
import '../legacy/bcSpiroViewer/bcSpiroViewerApi'
import '../legacy/bcSpiroViewer/d3.min.js'
import '../legacy/bcSpiroViewer/dataFetcherUtils'
import '../legacy/bcSpiroViewer/serverDataFetcher'
import '../legacy/bcSpiroViewer/fileDataFetcher'
import '../legacy/bcSpiroViewer/spiroChart'
import '../legacy/bcSpiroViewer/data/NHANESIIIPredData'
import '../legacy/bcSpiroViewer/NHANESIIIPredictor'
import '../legacy/bcSpiroViewer/hankinsonPredictor'
import '../legacy/bcSpiroViewer/GLIPredictor'
import '../legacy/bcSpiroViewer/data/GLIPredData'
import '../legacy/bcSpiroViewer/spirometerPrediction'
import '../legacy/bcSpiroViewer/pitchManager'
import '../legacy/bcSpiroViewer/calibrationManager'
import '../legacy/bcSpiroViewer/calibrationView'
import Loader from './Loader'

const BCSpiroViewer = ({ recordUrl, reportMode = false }) => {
  const isCSSLoaded = useDynamicStylesheets(
    [
      () =>
        import(
          /* webpackChunkName: "bc-spiro-viewer" */ '../legacy/bcSpiroViewer/stylesheets/bcSpiroViewer.css'
        ),
      () =>
        import(
          /* webpackChunkName: "bc-spiro-viewer" */ '../legacy/bcSpiroViewer/stylesheets/webSpiroViewer.css'
        )
    ],
    'bc-spiro-viewer'
  )

  const { locale } = useLocalization()

  useEffect(() => {
    if (!isCSSLoaded) return

    window.bcGlobals = window.bcGlobals || {}
    window.bcGlobals.locale = window.bcGlobals.locale || {}

    window.bcGlobals.locale.male = locale.spiroviewer_male
    window.bcGlobals.locale.female = locale.spiroviewer_female
    window.bcGlobals.locale.unspecified = locale.spiroviewer_unspecified
    window.bcGlobals.locale.noValue = locale.spiroviewer_no_value
    /* eslint-disable-next-line no-template-curly-in-string */
    window.bcGlobals.locale.patientHeight = locale.spiroviewer_patient_height
    window.bcGlobals.locale.flowAxisTitle = locale.spiroviewer_flow_axis_title
    window.bcGlobals.locale.volumeAxisTitle =
      locale.spiroviewer_volume_axis_title
    window.bcGlobals.locale.measured = locale.spiroviewer_measured
    window.bcGlobals.locale.predicted = locale.spiroviewer_predicted
    window.bcGlobals.locale.FVC = locale.spiroviewer_FVC
    window.bcGlobals.locale.litersUnit = locale.spiroviewer_liters_unit
    window.bcGlobals.locale.FEV1 = locale.spiroviewer_FEV1
    window.bcGlobals.locale.ratio = locale.spiroviewer_ratio
    window.bcGlobals.locale.PEF = locale.spiroviewer_PEF
    window.bcGlobals.locale.litersSecondsUnit =
      locale.spiroviewer_liters_seconds_unit
    window.bcGlobals.locale.duration = locale.spiroviewer_duration
    window.bcGlobals.locale.secondsUnit = locale.spiroviewer_seconds_unit

    const BCSpiroViewer = window.bcSpiroViewer
    // eslint-disable-next-line no-new
    const spiroViewer = new BCSpiroViewer({
      recordURL: recordUrl,
      reportMode
    })
    window.spiroViewer = spiroViewer
  }, [isCSSLoaded])

  if (!isCSSLoaded) return <Loader />

  return (
    <>
      <div
        dangerouslySetInnerHTML={{
          /* eslint-disable no-template-curly-in-string */
          __html:
            '<script id="flowStepTemplate" type="text/x-jquery-tmpl"><div class="flowStep" id="${id}"></div></script>' +
            '<script id="resultSelectTemplate" type="text/x-jquery-tmpl"><li class="testSelect" id="${id}" data-result-table-id=${resultTableId}>${title}</li></script>' +
            '<script id="resultRemplate" type="text/x-jquery-tmpl"><table id="${id}" class="testResultTable"><tr><td class="resultNoneTd" style="background-color:${titleBackcolor};"></td><td class="resultNoneTd" style="background-color:${titleBackcolor};"></td><td class="resultSubTitle" style="background-color:${titleBackcolor};">${measuredTitle}</td><td class="resultSubTitle predSubTitle" style="background-color:${titleBackcolor};">${predictedTitle}</td></tr><tr><td class="resultCalculationTitle">${FVCTitle}</td><td class="resultCalculationUnits">${litersUnit}</td><td class="resultEntry">${FVC}</td><td class="resultEntry">${predictedFVC}</td></tr><tr><td class="resultCalculationTitle">${FEV1Title}</td><td class="resultCalculationUnits">${litersUnit}</td><td class="resultEntry">${FEV1}</td><td class="resultEntry">${predictedFEV1}</td></tr><tr><td class="resultCalculationTitle">${ratioTitle}</td><td class="resultCalculationUnits">%</td><td class="resultEntry">${ratio}</td><td class="resultEntry">${predictedRatio}</td></tr><tr><td class="resultCalculationTitle">${PEFTitle}</td><td class="resultCalculationUnits">${litersSecondsUnit}</td><td class="resultEntry">${PEF}</td><td class="resultEntry">${predictedPEF}</td></tr><tr class="resultDurationRow"><td class="resultCalculationTitle">${durationTitle}</td><td class="resultCalculationUnits">${secondsUnit}</td><td class="resultEntry">${duration}</td><td class="resultEntry">${predictedDuration}</td></tr></table></script>' +
            '<script id="extraResultTemplate" type="text/x-jquery-tmpl"><div class="extraResultWrapper" id="${id}"><div class="extraResultChartWrapper" id="${chartWrapperId}"></div><div class="extraResultTableWrapper" id="${tableWrapperId}"></div></div></script>'
          /* eslint-enable no-template-curly-in-string */
        }}
      />

      <div id='bc_spiro_viewer'>
        <div id='testPanel'>
          <div id='liveFlowTitle'>{locale.spiroviewer_live_flow_title}</div>
          <div id='flowWidgetWrapper' className='widgetBox'>
            <div id='liveFlowWrapper'>
              <div id='liveFlow' />
            </div>
            <div id='timerWrapper'>
              <div id='timer'>
                <span id='time' />
                <span id='seconds'>{locale.spiroviewer_seconds_unit}</span>
              </div>
            </div>
          </div>
          <div id='instructionsWrapper'>
            <label id='instructionsTitle'>
              {locale.spiroviewer_instructions}
            </label>
            <ol>
              <li id='instructions1'>{locale.spiroviewer_instructions_1}</li>
              <li id='instructions2'>{locale.spiroviewer_instructions_2}</li>
              <li id='instructions3'>{locale.spiroviewer_instructions_3}</li>
              <li id='isntructions4'>{locale.spiroviewer_instructions_4}</li>
            </ol>
          </div>

          <div className='actionsPanel'>
            <button id='cancelTest' className='actionButton'>
              {locale.spiroviewer_cancel_test}
            </button>
          </div>
        </div>

        <div id='resultsWrapper'>
          <div id='resultsHeader'>
            <div id='patientDetails'>
              <table>
                <tbody>
                  <tr>
                    <td id='genderTitle'>{locale.spiroviewer_gender}</td>
                    <td className='patientDetailsValue' id='patientGender' />
                    <td id='ageTitle'>{locale.spiroviewer_age}</td>
                    <td className='patientDetailsValue' id='patientAge' />
                    <td id='heightTitle'>{locale.spiroviewer_height}</td>
                    <td className='patientDetailsValue' id='patientHeight' />
                  </tr>
                </tbody>
              </table>
            </div>

            <div id='settingsPanel'>
              <span id='predictedSourceTitle' className='mr-2'>
                {locale.spiroviewer_predicted_source}
              </span>
              <select name='predictor-select' id='predictor'>
                <option value='0' id='hankinsonPredictor'>
                  {locale.spiroviewer_hankinson}
                </option>
                <option value='1' id='NHANESIIIPredictor'>
                  {locale.spiroviewer_NHANESIII}
                </option>
                <option value='2' id='GLIPredictor'>
                  {locale.spiroviewer_GLI}
                </option>
              </select>
              <span id='predictorName' />
            </div>
          </div>

          <div className='resultsBox'>
            <ul id='resultsSelectPanel' />
          </div>
          <div className='resultsBox' id='mainResults'>
            <div id='flowVolumeChartWrapper' />
            <div id='resultsTableWrapper' />
          </div>
          <div className='resultsBox' id='allResults' />

          <div className='actionsPanel'>
            <button id='save' className='actionButton'>
              {locale.spiroviewer_save}
            </button>
          </div>
        </div>

        <div id='calibrationPanel'>
          <div id='calibrartionFlowValue' />
          <button id='startCalibration'>Start</button>

          <div id='calibrationValues' />
        </div>
      </div>
    </>
  )
}

export default BCSpiroViewer

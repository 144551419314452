if (typeof window !== 'undefined') {

const StudySummary = {};

StudySummary.drawMeanElectricalAxisChart = function(wrapperSelector, qrsAngle, activateTab) {
    var locale = bcGlobals.studySummaryLocale;
    qrsAngle = (qrsAngle === locale.noValue) ? 0 : qrsAngle;

    function degToRad(deg) {
        return (deg * Math.PI)/180;
    };

    function getPos(deg, radius) {
        var rad = degToRad(deg);
        return {
            x: mx+(radius * Math.cos(rad)),
            y: my+(radius * Math.sin(rad))
        };
    };

    function drawChartLine(startDeg, endDeg) {
        chartContext.beginPath();
        chartContext.strokeStyle = '#000';
        var startPos = getPos(startDeg, chartConfig.radius);
        var endPos = getPos(endDeg, chartConfig.radius);
        chartContext.moveTo(startPos.x, startPos.y);
        chartContext.lineTo(endPos.x, endPos.y);

        chartContext.stroke();
    };

    function drawValueLine() {
        chartContext.beginPath();
        chartContext.strokeStyle = chartConfig.qrsAngleColor;
        var pos = getPos(qrsAngle, chartConfig.radius);
        chartContext.moveTo(mx, my);
        chartContext.lineTo(pos.x, pos.y);

        chartContext.stroke();
    };

    function drawText(deg) {
        if (chartConfig.text[deg]) {
            chartContext.textAlign = 'center';
            chartContext.textBaseline = 'middle';
            chartContext.fillStyle = '#000';
            var degText = $.tmpl(locale.deg_val, {deg: chartConfig.text[deg].deg}).text();
            var degtxtPos = getPos(deg, chartConfig.radius + chartConfig.textOffset);
            chartContext.fillText(degText, degtxtPos.x, degtxtPos.y);
            if (chartConfig.text[deg].name) {
                chartContext.textAlign = chartConfig.text[deg].align;
                var chtxtPos = getPos(deg, chartConfig.radius + 2*chartConfig.textOffset);
                chartContext.fillText(locale.ch_names[chartConfig.text[deg].name], chtxtPos.x, chtxtPos.y);
            }
        }
    };

    function drawLegend() {
        var normalRangeKey = getNormalRangeKey();
        var isNormalRangeAvailable = (normalRangeKey in chartConfig.normalRange.range);

        var legendX = mx + chartConfig.radius + 70;
        var meaY = (isNormalRangeAvailable) ? my - 10 : my;
        chartContext.beginPath();
        chartContext.strokeStyle = chartConfig.qrsAngleColor;
        chartContext.moveTo(legendX, meaY);
        chartContext.lineTo(legendX+chartConfig.legend.colorSize, meaY);
        chartContext.stroke();

        chartContext.textAlign = 'left';
        chartContext.textBaseline = 'middle';
        chartContext.fillStyle = '#000';
        chartContext.font = chartConfig.legend.font;
        var mea = $.tmpl(locale.mea_legend, {deg: qrsAngle}).text();
        chartContext.fillText(mea, legendX+ chartConfig.legend.colorSize + chartConfig.legend.textOffset, meaY+1);

        if (isNormalRangeAvailable) {
            var normalY = my + 10;
            chartContext.beginPath();
            chartContext.strokeStyle = chartConfig.normalRange.color;
            chartContext.fillStyle = chartConfig.normalRange.color;
            chartContext.rect(legendX, normalY, chartConfig.legend.colorSize, chartConfig.legend.colorSize);
            chartContext.fill();
            chartContext.stroke();

            chartContext.fillStyle = '#000';
            chartContext.textBaseline = 'top';
            chartContext.fillText(locale.mea_normal_range, legendX+ chartConfig.legend.colorSize + chartConfig.legend.textOffset, normalY);
        }
    };

    function getNormalRangeKey() {
        var normalRangeKey;

        if (bcGlobals.isAnimal) {
            //normalRangeKey = 'animal';
            normalRangeKey = bcGlobals.animalType;
        } else {
            normalRangeKey = 'human';
        }

        return normalRangeKey;
    };

    function getNormalRange() {
        var normalRangeKey = getNormalRangeKey();

        var range = {
            start: 0,
            end: 0
        };

        if (normalRangeKey in chartConfig.normalRange.range) {
            range.start = chartConfig.normalRange.range[normalRangeKey].start;
            range.end = chartConfig.normalRange.range[normalRangeKey].end
        }

        return range;
    };

    function drawNormalRange() {
        chartContext.beginPath();
        chartContext.strokeStyle = chartConfig.normalRange.color;
        chartContext.fillStyle = chartConfig.normalRange.color;
        var range = getNormalRange();
        var startPos = getPos(range.start, chartConfig.radius);
        var endPos = getPos(range.end, chartConfig.radius);
        chartContext.moveTo(mx, my);
        chartContext.lineTo(startPos.x, startPos.y);
        chartContext.arc(mx, my, chartConfig.radius, degToRad(range.start), degToRad(range.end));
        chartContext.lineTo(mx, my);

        chartContext.fill();
        chartContext.stroke();
    };

    var chartConfig = {
        width: 550,
        height: 260,
        radius: 80,
        legend: {
            font: '12px Lucida Grande, Verdana, Arial, sans-serif',
            colorSize: 10,
            textOffset: 10
        },
        normalRange: {
            range: {
                'human': {
                    start: -30,
                    end: 90
                },
                'animal': {
                    start: 0,
                    end: 90
                },
                'Dog': {
                    start: 40,
                    end: 100
                },
                'SmallDog': {
                    start: 40,
                    end: 100
                },
                'MediumDog': {
                    start: 40,
                    end: 100
                },
                'BigDog': {
                    start: 40,
                    end: 100
                },
                'Cat': {
                    start: 0,
                    end: 160
                }
            },
            color: '#d3d3d3'
        },
        qrsAngleColor: '#FF0000',
        font: '10px Lucida Grande, Verdana, Arial, sans-serif',
        textOffset: 20,
        text: {
            '0': {
                deg: '0',
                name: 'I',
                align: 'right'
            },
            '30': {
                deg: '30',
                align: 'center'
            },
            '60': {
                deg: '60',
                name: 'II',
                align: 'center'
            },
            '90': {
                deg: '90',
                name: 'aVF',
                align: 'center'
            },
            '120': {
                deg: '120',
                name: 'III',
                align: 'center'
            },
            '150': {
                deg: '150',
                align: 'right'
            },
            '-180': {
                deg: '180',
                align: 'center'
            },
            '-30': {
                deg: '-30',
                name: 'aVL',
                align: 'left'
            },
            '-60': {
                deg: '-60',
                align: 'center'
            },
            '-90': {
                deg: '-90',
                align: 'center'
            },
            '-120': {
                deg: '-120',
                align: 'center'
            },
            '-150': {
                deg: '-150',
                name: 'aVR',
                align: 'right'
            }
        }
    };

    var wrapper = $(wrapperSelector);
    var chartCanvas = $('<canvas></canvas>')[0];
    chartCanvas.style.height = chartConfig.height;
    chartCanvas.height = chartConfig.height;
    chartCanvas.style.width = chartConfig.width;
    chartCanvas.width = chartConfig.width;
    var chartContext = chartCanvas.getContext('2d');
    chartContext.font = chartConfig.font;
    var mx = chartConfig.radius+50;
    var my = chartConfig.radius+40;
    drawNormalRange();
    for (var i=0;i<180;i+=30) {
        drawChartLine(i, -180+i);
        drawText(i);
        drawText(-180+i);
    }

    drawValueLine();
    drawLegend();

    wrapper.find('.mean_electrical_axis_chart').append(chartCanvas);
    wrapper.find('.mean_electrical_axis_wrapper').addClass('available');
    // $('#tabmenu_events').removeClass('disabled');
    activateTab && activateTab();
};

StudySummary.clearStudySummary = function(wrapperSelector) {
  var wrapper = $(wrapperSelector);
  wrapper.find('.mean_electrical_axis_wrapper').removeClass('available');
  wrapper.find('.mean_electrical_axis_chart').empty();

  wrapper.find('.study_qrs_median_summary').empty();
  wrapper.find('.study_summary').removeClass('available');

  wrapper.find('.study_events_summary_wrapper').removeClass('available');
}

StudySummary.fillSummaryParametersTable = function(wrapperSelector, data, activateTab) {
    var wrapper = $(wrapperSelector);
    var locale = bcGlobals.studySummaryLocale;
    var validData = this._validateSummaryParameters(data);

    var prtAxes = (validData.medianAngleParameters.pWaveAngle !== locale.noValue ||
                    validData.medianAngleParameters.qrsAngle !== locale.noValue ||
                    validData.medianAngleParameters.tWaveAngle !== locale.noValue) ?
                  $.tmpl(locale.PRT, {p: validData.medianAngleParameters.pWaveAngle, r: validData.medianAngleParameters.qrsAngle, t: validData.medianAngleParameters.tWaveAngle}).text() :
                  locale.noValue;
    var rowsConfig = [
        { title: locale.HR, dataEntry: validData.medianTimeParameters.heartRate}, // TBD: QTcB
        { title: locale.RR, dataEntry: validData.medianTimeParameters.rrValue, extraTitle: locale.prtAxes, extraData: prtAxes},
        { title: locale.QRS, dataEntry: validData.medianTimeParameters.qrsDuration},
        { title: locale.P, dataEntry: validData.medianTimeParameters.pWaveDuration, extraTitle: locale.pIIAmplitude, extraData: validData.medianAmplitudeParameters.pIIAmplitude },
        { title: locale.PQ, dataEntry: validData.medianTimeParameters.pqWaveDuration, extraTitle: locale.sV1Amplitude, extraData: validData.medianAmplitudeParameters.sV1Amplitude},
        { title: locale.QT, dataEntry: validData.medianTimeParameters.qtDuration, extraTitle: locale.rV5Amplitude, extraData: validData.medianAmplitudeParameters.rV5Amplitude},
        { title: locale.QTc, dataEntry: validData.medianTimeParameters.qtcDuration}
    ];

    var emptyTable = true;
    for (var i=0; (i<rowsConfig.length) && emptyTable; i++) {
        if (rowsConfig[i].extraData && rowsConfig[i].extraData !== locale.noValue) {
            emptyTable = false;
        }
        for (var j=0; (j<3) && emptyTable; j++) {
            if (rowsConfig[i].dataEntry[j] != 0) {
                emptyTable = false;
            }
        }
    }

    if (emptyTable) {
      $('body').addClass('no_events'); // relevant only in report page
      return;
    }

    var rowsHTML = [];

    for (var i=0; i<rowsConfig.length; i++) {
        rowsHTML.push($('#qrs_median_entry_template').tmpl({
            title: rowsConfig[i].title,
            min: (rowsConfig[i].dataEntry[0] != 0) ? rowsConfig[i].dataEntry[0] : locale.noValue,
            avg: (rowsConfig[i].dataEntry[1] != 0) ? rowsConfig[i].dataEntry[1] : locale.noValue,
            max: (rowsConfig[i].dataEntry[2] != 0) ? rowsConfig[i].dataEntry[2] : locale.noValue,
            extraTitle: (rowsConfig[i].extraTitle || ''),
            extraData: (rowsConfig[i].extraData || '')
        }));
    }

    wrapper.find('.study_qrs_median_summary').append(rowsHTML);
    //wrapper.find('.study_summary').show();
    wrapper.find('.study_summary').addClass('available');
    //$('#tabmenu_events').removeClass('disabled');

    activateTab && activateTab();
};

StudySummary._validateSummaryParameters = function(data) {
    var locale = bcGlobals.studySummaryLocale;

    function validateArrayEntry(entry) {
        return (typeof entry !== 'undefined') ? entry : [0,0,0];
    };

    function validateNumberEntry(entry) {
        return (typeof entry !== 'undefined') ? entry : locale.noValue;
    };

    var pWaveAngleValue = validateNumberEntry(data.medianAngleParameters.pWaveAngle);
    var qrsAngleValue = validateNumberEntry(data.medianAngleParameters.qrsAngle);
    var tWaveAngleValue = validateNumberEntry(data.medianAngleParameters.tWaveAngle);

    var validData = {
        medianTimeParameters: {
            heartRate: validateArrayEntry(data.medianTimeParameters.heartRate),
            rrValue: validateArrayEntry(data.medianTimeParameters.rrValue),
            pWaveDuration: validateArrayEntry(data.medianTimeParameters.pWaveDuration),
            pqWaveDuration: validateArrayEntry(data.medianTimeParameters.pqWaveDuration),
            qrsDuration: validateArrayEntry(data.medianTimeParameters.qrsDuration),
            qtDuration: validateArrayEntry(data.medianTimeParameters.qtDuration),
            qtcDuration: validateArrayEntry(data.medianTimeParameters.qtcDuration)
        },
        medianAngleParameters: {
            pWaveAngle: (data.medianTimeParameters.pWaveDuration[1] !== 0) ? pWaveAngleValue : locale.noValue,
            qrsAngle: qrsAngleValue,
            tWaveAngle: (data.medianTimeParameters.qtDuration[1] !== 0) ? tWaveAngleValue : locale.noValue
        },
        medianAmplitudeParameters: {
            pIIAmplitude: validateNumberEntry(data.medianAmplitudeParameters.pIIAmplitude),
            sV1Amplitude: validateNumberEntry(data.medianAmplitudeParameters.sV1Amplitude),
            rV5Amplitude: validateNumberEntry(data.medianAmplitudeParameters.rV5Amplitude)
        }
    };

    return validData;
};

StudySummary._createEventsArray = function(data) {
    var eventsData = [];

    $.each(data, function(eId, eData) {
        eventsData.push(eData);
    });

    return eventsData;
};

StudySummary._addEventsToTable = function(wrapperSelector, eventsData) {
    var wrapper = $(wrapperSelector);
    if (eventsData.length !== 0) {
        /*wrapper.find('.study_summary').show();
        wrapper.find('.study_events_summary_wrapper').css('display', 'inline-block');
*/

        wrapper.find('.study_summary').addClass('available');
        $('#tabmenu_events').removeClass('disabled');
        wrapper.find('.study_events_summary_wrapper').addClass('available');
        for (var i=0; i<eventsData.length; i++) {
            var rowHTML = $('#events_summary_entry_template').tmpl({
                            symbol: eventsData[i].annotation,
                            name: eventsData[i].name,
                            occurrences: eventsData[i].occurrences,
                            color: eventsData[i].color,
                            duration: eventsData[i].duration
                        });

            wrapper.find('tr[data-event-id='+ eventsData[i].id + ']').append(rowHTML);
        }
    }
};

StudySummary.fillEventsTable = function(wrapperSelector, data, activateTab) {
    var eventsData = this._createEventsArray(data);
    this._addEventsToTable(wrapperSelector, eventsData);

    activateTab && activateTab();
};

StudySummary.fillTopEvents = function(wrapperSelector, data, eventsNumber) {
    var eventsData = this._createEventsArray(data);
    eventsData = $.grep(eventsData, function(e) {
        return (typeof e.secondaryPriority !== 'undefined');
    });
    eventsData.sort(function(e1, e2) {
        var spDiff = (e1.secondaryPriority - e2.secondaryPriority);

        if (spDiff === 0) {
            return (e1.priority - e2.priority);
        }

        return spDiff;
    });

    if (eventsData.length > eventsNumber) {
        eventsData = eventsData.slice(0, eventsNumber);
        var wrapper = $(wrapperSelector);
        wrapper.find('.more_events_note').css('display', 'table-row');
    }
    this._addEventsToTable(wrapperSelector, eventsData);
};

window.StudySummary = StudySummary

}

if (typeof window !== 'undefined') {

/*
 * (c) 2015 Coptright Witalize LTD.
 * (r) All rights reserved
 */


/* Handles patient data */

PatientsManager = function(patients) {
  this.patients = {};
  this.privateData = {};
  var patient;
  for (var i = 0; i < patients.length; i++) {
    patient = patients[i];
    if (patient.hash_id && patient.hash_id.length) {
      this.patients[patient.hash_id] = patient;
    }
  }
};

/** returns a specific attribute value from private data
 * @param hashId: patient's hash
 * @param attributeName: requested attribute
 * @param attributeType: optional - attribute type ('string' | 'number' | 'boolean'), default 'string'
 * @param defaultValue: optional - attribute default value, default based on attributeType ('' | 0 | false)
 */

PatientsManager.prototype.getPrivateAttribute = function(hashId, attributeName, attributeType, defaultValue) {
  var patientPrivateData = this.getPrivateData(hashId);
  var defaultValues = {'string': '', 'number': 0, 'boolean': false};
  attributeType = (typeof attributeType === 'undefined') ? 'string' : attributeType;
  defaultValue = defaultValue || defaultValues[attributeType];
  var returnValue;

  if (typeof patientPrivateData[attributeName] === attributeType) {
    // found attrivute in private data
    returnValue = patientPrivateData[attributeName];
  } else  {
    // searching attribute in private fields or returning default value
    var patient = this.patients[hashId] || {};
    returnValue = (typeof patient[attributeName] === attributeType) ?
            patient[attributeName] : defaultValue;
  }
  if (attributeName == 'email' && returnValue === hashId + '@beecardia.com') {
    // generated email
    returnValue = defaultValue;
  }
  return returnValue;
};


/** Cashes private data and returns specific private data for a patient
 * @param hashId: patient's hash
 */

PatientsManager.prototype.getPrivateData = function(hashId) {
  if (!this.privateData[hashId]) {
    var patient = this.patients[hashId] || {};
    // EncryptionManager should handle errors such as missing or ill-formatted data
    // var patientPrivateData = EncryptionManager.decrypt(patient.encrypted_data);
    var patientPrivateData = patient.encrypted_data;
    var privateData;
    try {
      privateData = JSON.parse(patientPrivateData);
    } catch (e) {
      privateData = {};
    }
    this.privateData[hashId] = privateData;
  }
  return this.privateData[hashId] || {};
};


/** Calculates age from birth date to a certain date
 * @param (Date) birthDate
 * @param (String) toDateString target date
 */

PatientsManager.prototype.calculateAge = function(birthDate, toDateString) {
  var toDate = new Date(toDateString);
  if (isNaN(toDate.getTime())){
    // invalid toDateString
    return { years: 0, months: 0, days: 0 };
  }
  var months = ( toDate.getFullYear() * 12 + toDate.getMonth() ) - ( birthDate.getFullYear() * 12 + birthDate.getMonth() )
  return {
    years: Math.floor(months / 12),
    months: months,
    days: Math.floor((toDate - birthDate) / (1000 * 60 * 60 * 24))
  }
};

window.PatientsManager = PatientsManager;

}
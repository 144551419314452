import React from 'react'
import clsx from 'clsx'
import Modal from '../Modal'
import AuthScenery from './AuthScenery'
import { useLocalization } from '../LocalizationProvider'

export default function ForgotPasswordSendCore({
  handleInputChange,
  changeState,
  send,
  error,
  inProgress
}) {
  const { locale } = useLocalization()
  return (
    <AuthScenery>
      <Modal onSubmit={send} standalone={false}>
        <div className='field'>
          <label className='label is-medium'>
            {locale.reset_password.reset_header_1}
          </label>
        </div>
        <hr />
        <div className='field'>
          <label className='label'>{locale.login.email_forced}</label>
          <div className='control'>
            <input
              key='email'
              type='text'
              name='email'
              className={clsx('input', { 'is-danger': error })}
              onChange={handleInputChange}
              readOnly={inProgress}
            />
          </div>
        </div>
        <div className={clsx('field', { 'is-hidden': !error })}>
          <p className='help is-danger'>
            {error === 'UserNotFoundException'
              ? locale.login.no_such_user
              : locale.something_wrong}
          </p>
        </div>
        <hr />
        <div className='field'>
          <div className='level'>
            <div className='level-left'>
              <button
                className='button is-primary is-inverted level-item'
                onClick={() => changeState('signIn')}
                disabled={inProgress}
              >
                {locale.profile.back_to_signin}
              </button>
            </div>
            <div className='level-right'>
              <button
                className={clsx('button is-primary level-item', {
                  'is-loading': inProgress
                })}
                onClick={send}
                disabled={inProgress}
              >
                {locale.profile.send_code}
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </AuthScenery>
  )
}

if (typeof window !== 'undefined') {
/*
    StandaloneDataFetcher - handles fetching data from the API
*/
bcViewer.StandaloneDataFetcher = bcViewer.BaseDataFetcher.extend({
    construct: function(globals) {
        this._super.apply(this, arguments);
        this._signalProcessing = new SignalProcessing();

        this._rawQrsData = [];
        this._qrsData = [];
        this._eventsData = [];
        this._summaryParameters = {};
        this._sumEventsData = {};
        this.MISC_EVENT_ID = 39;
        this.VEC_EVENT_ID = 27;

        // qrs and events data together
        this._annotationsData = [];

        this._gotEventsError = false;
        this._waitingForResponseCount = 2;
    },

    /*
        Get the record data from url
        TODO: handle error - no record, wrong format, etc
    */
    getRecordData: function(url, callback) {
        this._waitingForResponseCount = 2;
        var evtUrl = url.substr(0, url.lastIndexOf(".")) + ".evt";
        if (this._bcViewer.cachedEventsData != null) {
          this._onGotEventsData(this._bcViewer.cachedEventsData, callback);
        } else {
          this._fetch({
            url: evtUrl,
              cache: false,
              type: 'GET',
              context: this,
              dataType: 'json',
              beforeSend: null, // External source, thus overriding global X-CSRF-Token verification
              success: function(resultData) {
                  this._onGotEventsData(resultData, callback);
              },
              error: function(result) {
                  if (this._gotEventsError) return;
                  this._gotEventsError = true;
                  this._waitingForResponseCount--; // no evt file

                  if (bcGlobals.reportMode) {
                      this._notifyOnSummaryParametersReady();
                      this._notifyOnEventsDataReady();
                  }
                  (this._waitingForResponseCount === 0) && this._onGotAllData(callback);
              }
          });
        }
        if (this._bcViewer.cachedRecordData != null) {
          this._onGotRecordData(this._bcViewer.cachedRecordData, callback);
        } else {
          this._fetch({
            url: url,
              cache: false,
              type: 'GET',
              context: this,
              dataType: 'json',
              beforeSend: null, // External source, thus overriding global X-CSRF-Token verification
              success: function(resultData) {
                  this._onGotRecordData(resultData, callback);
              }
          });
        }
    },

    /*
        Handler for getting record data
        TODO: more error handling
    */
    _onGotRecordData: function(resultData, callback) {
        this._waitingForResponseCount--;
        this._ecgData = resultData.ecg;
        (this._bcViewer.cachedRecordData === null) && this._bcViewer.viewerApi.onGotRecordData(resultData);

        // Saving the metadata
        this._metadata = {
            Duration: resultData.header.duration || resultData.footer.duration,
            NumberOfChannels: resultData.header.numberOfLeads || resultData.footer.numberOfLeads,
            StartTime: resultData.header.startTime || resultData.footer.startTime || 0,
            NumberOfSamples: resultData.header.numberOfSamples || resultData.footer.numberOfSamples,
            SamplingRate: resultData.header.samplingRate || resultData.footer.samplingRate,
            ry: resultData.header.unitsPerMv || resultData.footer.unitsPerMv,
            startTimeZone: resultData.header.startTimeZone || resultData.footer.startTimeZone || 0, // zero fallback in case of undefined
            electrodesPosition: resultData.header.electrodesPosition || resultData.footer.electrodesPosition || 1, // fallback in case of undefined
            deviceNumber: resultData.header.deviceNumber || resultData.footer.deviceNumber || 0,
            deviceClass: resultData.header.deviceClass || resultData.footer.deviceClass || 0,
            leadSelection: resultData.header.leadSelection
        };

        // seconds -> milliseconds
        if (this._metadata.StartTime !== 0) {
            this._metadata.StartTime *= 1000;
            this._metadata.StartTime = this._utils.adjustTimeToTimezone(this._metadata.StartTime, this._metadata.startTimeZone);
        }

        // Initiating the signal processor
        this._signalProcessing.setInputSignal(this._ecgData, {
            numberOfChannels: this._metadata.NumberOfChannels,
            numberOfSamples: this._metadata.NumberOfSamples,
            samplingRate: this._metadata.SamplingRate,
            ry: this._metadata.ry
        });

        (this._waitingForResponseCount === 0) && this._onGotAllData(callback);
    },

    /*
        handler for getting events data
    */
    _onGotEventsData: function(resultData, callback) {
      (this._bcViewer.cachedEventsData === null) &&  this._bcViewer.viewerApi.onGotEventsData(resultData);

        this._waitingForResponseCount--;
        if (bcGlobals.isVeterinarian) {
            resultData.eventsList = [];
        }
        this._rawQrsData = resultData.qrsParameters;
        // Hide the events data (table + annotations)
        // Remove the empty array and uncomment in order to show the events data
        this._eventsData = resultData.eventsList;

        this._summaryParameters = {
            medianTimeParameters: (typeof resultData.medianTimeParameters !== 'undefined') ? resultData.medianTimeParameters : {},
            medianAngleParameters: (typeof resultData.medianAngleParameters !== 'undefined') ? resultData.medianAngleParameters :  {},
            medianAmplitudeParameters: (typeof resultData.medianAmplitudeParameters !== 'undefined') ? resultData.medianAmplitudeParameters :  {}
        }
        this._notifyOnSummaryParametersReady();

        (this._waitingForResponseCount === 0) && this._onGotAllData(callback);
    },

    /*
        got all the data
    */
    _onGotAllData: function(callback) {
        // check if we have events\qrs data
        if (this._rawQrsData.length !== 0) {
            this._adjustQrsData();
            this._adjustEventsData();
            this._buildAnnotationsData();
        }

        if (!this._gotEventsError) {
            this._notifyOnEventsDataReady();
        }

        callback && callback();
    },

    /*
        trigger eventsDataReady event
    */
    _notifyOnEventsDataReady: function() {
        this._bcViewer.viewerApi.onEventsDataReady(this._sumEventsData);
    },

    /*
        trigger qrsMedianDataReady event
    */
    _notifyOnSummaryParametersReady: function() {
        this._bcViewer.viewerApi.onSummaryParametersReady(this._summaryParameters);
    },

    /*
        add needed data for QRS.
        ecg data is shifted by samplingRate/2 for filters calculations
        we need to shift qrsData accordingly
    */
    _adjustQrsData: function() {

        var samplingRate = this._metadata.SamplingRate;
        var that = this;

        function getQRSData(samplePosition, caption, name, color) {
            return {
                samplePosition: samplePosition,
                timePosition:samplePosition * 1000 / samplingRate,
                annotationType: 'qrs',
                caption: caption,
                color: color,
                priority: 0, // Most important
                name: name,
                extraInfoString: ''
            }
        };

        function addQRSData(qrsComplex, qrsData) {
            var samplePosition = qrsComplex.samplePosition - samplingRate / 2;
            var color = that._getQrsColor(qrsComplex.qrsType);
            var data;

            var shouldShowAllPoints = (window.location.href.indexOf('qrsDebug') > -1); // TODO check qa.beecardia.com as well

            // QRS begin
            data = getQRSData(samplePosition,
                                 qrsComplex.rrValue,
                                 (shouldShowAllPoints ? 'QRS start' : ''),
                                 color);
            qrsData.push(data);

            if (!shouldShowAllPoints) return;

            // P begin
            if (qrsComplex.pqWaveDuration != 0) {
                data = getQRSData(samplePosition-qrsComplex.pqWaveDuration, 'P1', 'P wave start', color);
                qrsData.push(data);
            }

            // P end
            if ((qrsComplex.pqWaveDuration != 0) && (qrsComplex.pWaveDuration != 0)) {
                data = getQRSData(samplePosition-qrsComplex.pqWaveDuration+qrsComplex.pWaveDuration, 'P2', 'P wave end', color);
                qrsData.push(data);
            }

            // QRS end
            if (qrsComplex.qrsDuration != 0) {
                data = getQRSData(samplePosition+qrsComplex.qrsDuration, 'Q2', 'QRS end', color);
                qrsData.push(data);
            }

            // T end
            if (qrsComplex.qtDuration != 0) {
                data = getQRSData(samplePosition+qrsComplex.qtDuration, 'QT', 'T wave end', color);
                qrsData.push(data);
            }
        }

        for (var i = 0; i < this._rawQrsData.length; i++) {
            addQRSData(this._rawQrsData[i], this._qrsData);
        }
    },

    /*
        add needed data for events
    */
    _adjustEventsData: function() {
        for (var i = 0; i < this._eventsData.length; i++) {
            var eventInfo = bcViewer.eventsInfo.rest[this._eventsData[i].eventID];
            this._eventsData[i].id = this._eventsData[i].eventID;
            this._eventsData[i].timePosition = this._eventsData[i].eventPosition * 1000 / this._metadata.SamplingRate;
            this._eventsData[i].annotationType = 'event';
            this._eventsData[i].caption = eventInfo.ANNOTATION;
            this._eventsData[i].color = eventInfo.COLOR;
            this._eventsData[i].priority = eventInfo.PRIORITY;
            this._eventsData[i].secondaryPriority = eventInfo.SECONDARY_PRIORITY;
            this._eventsData[i].name = bcGlobals.locale.restEvents[this._eventsData[i].eventID];
            this._eventsData[i].extraInfoString = this._getEventExtraInfoString(this._eventsData[i], eventInfo);
        }

        this._sumEvents();
    },

    /*

    */
    _sumEvents: function() {
        this._addEventsSum();
        this._addGroupsSum();
        this._eventsSumDurationToString();
    },

    /*
        Sum the events occurrences and duration
    */
    _addEventsSum: function() {
        for (var i = 0; i < this._eventsData.length; i++) {
            var eventInfo = bcViewer.eventsInfo.rest[this._eventsData[i].eventID];
            var sumEvent = this._sumEventsData[this._eventsData[i].eventID];

            var isEventWithDuration = ($.inArray('duration', eventInfo.EXTRA_INFO) !== -1);
            if (!sumEvent) {
                this._sumEventsData[this._eventsData[i].eventID] = {
                    id: this._eventsData[i].id,
                    annotation: this._eventsData[i].caption,
                    name: this._eventsData[i].name,
                    color: this._config.COLORS[this._eventsData[i].color],
                    priority: eventInfo.PRIORITY,
                    secondaryPriority: eventInfo.SECONDARY_PRIORITY,
                    occurrences: 1
                };

                if (isEventWithDuration) {
                    this._sumEventsData[this._eventsData[i].eventID].duration = this._eventsData[i].eventDuratin;
                }
            } else {
                sumEvent.occurrences++;

                if (isEventWithDuration) {
                    sumEvent.duration += this._eventsData[i].eventDuratin;
                }
            }
        }
    },

    /*
        Sum occureences and duration for each group
    */
    _addGroupsSum: function() {
        $.each(this._sumEventsData, $.proxy(function(eId, eData) {
            var eInfo = bcViewer.eventsInfo.rest[eId];
            var groupEventInfo = bcViewer.eventsInfo.rest[eInfo.GROUP];
            var isGroupWithDuration =($.inArray('duration', groupEventInfo.EXTRA_INFO) !== -1);

            if (typeof eInfo.GROUP !== 'undefined') {
                var groupEvent = this._sumEventsData[eInfo.GROUP];
                if (!groupEvent) {
                    this._sumEventsData[eInfo.GROUP] = {
                        id: eInfo.GROUP,
                        name: bcGlobals.locale.restEvents[eInfo.GROUP],
                        priority: groupEventInfo.PRIORITY,
                        occurrences: eData.occurrences
                    };

                    if (isGroupWithDuration) {
                        this._sumEventsData[eInfo.GROUP].duration = eData.duration;
                    }
                } else {
                    groupEvent.occurrences += eData.occurrences;
                    if (isGroupWithDuration) {
                        groupEvent.duration += eData.duration
                    }
                }
            }
        }, this));

        // Handle Misc event (id 39) - we don't show group details for misc if it's the only event under VEC group
        if (typeof this._sumEventsData[this.MISC_EVENT_ID] !== 'undefined') {
            // if misc event is the only event in VEC group, remove the group values
            var vecEvent = this._sumEventsData[this.VEC_EVENT_ID];
            if (this._sumEventsData[this.MISC_EVENT_ID].occurrences === vecEvent.occurrences) {
                delete vecEvent.name;
                delete vecEvent.occurrences;
            }
        }
    },

    /*
        Change the durations in the event sum to duration strings
    */
    _eventsSumDurationToString: function() {
        $.each(this._sumEventsData, $.proxy(function(eId, eData) {
            if (typeof eData.duration !== 'undefined') {
                var durationString = this._getDurationString(eData.duration);
                eData.duration = durationString;
            }
        }, this));
    },

    /*
        Build annotations data.
        build one array with QRS and events.
    */
    _buildAnnotationsData: function() {
        var sortedAnnotations = [];
        sortedAnnotations = this._qrsData.concat(this._eventsData);
        sortedAnnotations.sort(function(a, b) {
            return (a.timePosition === b.timePosition) ? (a.priority - b.priority) :  (a.timePosition - b.timePosition);
        });

        for (var i=0; i< sortedAnnotations.length; i++) {
            var annotationObj = {};
            var tooltip = "";
            annotationObj.x = sortedAnnotations[i].timePosition;

            // one objest for each X position
            var annotations = [];

            // push dummy QRS if only events
            if (sortedAnnotations[i].annotationType === 'event') {
                annotations.push({
                    caption: '',
                    annotationType: 'qrs',
                    name: '',
                    extraInfoString: ''
                });
            }

            annotations.push({
                caption: sortedAnnotations[i].caption,
                color: sortedAnnotations[i].color,
                annotationType: sortedAnnotations[i].annotationType,
                name: sortedAnnotations[i].name,
                extraInfoString: sortedAnnotations[i].extraInfoString
            });

            // add all annotations with the same time position
            while (i+1 < sortedAnnotations.length && sortedAnnotations[i].timePosition === sortedAnnotations[i+1].timePosition) {
                annotations.push({
                    caption: sortedAnnotations[i+1].caption,
                    color: sortedAnnotations[i+1].color,
                    annotationType: sortedAnnotations[i+1].annotationType,
                    name: sortedAnnotations[i+1].name,
                    extraInfoString: sortedAnnotations[i+1].extraInfoString
                });
                i++;
            }
            annotationObj.annotations = annotations;
            annotationObj.color = annotations[1] ? annotations[1].color : annotations[0].color;
            annotationObj.tooltip = this._buildAnnotationTooltipHTML(annotations);

            this._annotationsData.push(annotationObj);

        }
    },

    /*
        return html tooltip from annotations array
    */
    _buildAnnotationTooltipHTML: function(annotations) {
        var tooltip = '';
        for (var i=0; i< annotations.length; i++) {
            var annotationStr = $.tmpl(bcGlobals.locale.eventTooltipTemplate, {
                                                                        'name': annotations[i].name,
                                                                        'extraInfo': annotations[i].extraInfoString
                                                                    }).text();
            tooltip += (annotations[i].name !== '') ?
                       '<li>' + annotationStr + '</li>' :
                       '';
        }

        var tooltip = (tooltip !== '') ?
                      '<ul>' + tooltip + '</ul>' :
                      '';

        return tooltip;
    },

    /*
        input: duration in samples
        output: duration as a string
    */
    _getDurationString: function(durationSamples) {
        var durationMS = durationSamples * 1000 / this._metadata.SamplingRate;
        var durationTime = this._utils.normalizeTime(durationMS);
        var durationString = '';
        if (durationTime.hours !== 0) {
            durationString = $.tmpl(bcGlobals.locale.eventDurationHours, durationTime).text();
        } else if (durationTime.minutes !== 0) {
            durationString = $.tmpl(bcGlobals.locale.eventDurationMinutes, durationTime).text();
        } else {
            durationString = $.tmpl(bcGlobals.locale.eventDurationSeconds, durationTime).text();
        }

        return durationString;
    },

    /*
        return extra info string
    */
    _getEventExtraInfoString: function(eventData, eventInfo) {
        var extraInfoArray = eventInfo.EXTRA_INFO;

        var extraInfoStrings = [];
        for (var i=0; i < extraInfoArray.length ; i++) {
            switch (extraInfoArray[i]) {
                case 'duration':
                    var durationString = this._getDurationString(eventData.eventDuratin);
                    extraInfoStrings.push(durationString);

                    break;
                case 'timestamp':
                    var time = this._utils.formatTime(this._metadata.StartTime + eventData.timePosition, bcGlobals.locale.timeFormat);
                    var date = this._utils.formatDate(this._metadata.StartTime, bcGlobals.locale.dateFormat);
                    extraInfoStrings.push($.tmpl(bcGlobals.locale.eventTimestamp, {date: date, time: time}).text());

                    break;
                case 'hr':
                    var hr = eventData.eventHr;

                    if (hr > 0) {
                        extraInfoStrings.push($.tmpl(bcGlobals.locale.eventHr, {hr: hr}).text());
                    }

                    break;

            }

        }

        var extraInfoStr = '';
        if (extraInfoStrings.length != 0) {
            extraInfoStr = '(' + extraInfoStrings.join(', ') + ')';
        }

        return extraInfoStr;
    },

    /*
        Return the current record metadata to callback
    */
    getMetadata: function(callback) {
        callback && callback(this._metadata);
    },

    /*
        Return ecg data according to dataParams
    */
    requestECGData: function(dataParams, name, callback) {
        var isChannelSelected = (typeof dataParams.selectedChannel !== 'undefined');

        if (isChannelSelected) {
            dataParams.requestedChannels = [dataParams.selectedChannel];
        }

        var expand = (dataParams.expand3To7 || dataParams.expand3To12 || dataParams.expand8To12 || dataParams.expand2To6);

        this._signalProcessing.configProcessor('SmoothFilter', {enabled: dataParams.sm});
        this._signalProcessing.configProcessor('BaselineFilter', {enabled: dataParams.bl});
        this._signalProcessing.configProcessor('Expand3To7', {enabled: dataParams.expand3To7});
        this._signalProcessing.configProcessor('Expand2To6', {enabled: dataParams.expand2To6});
        this._signalProcessing.configProcessor('Expand3To12', {enabled: dataParams.expand3To12});
        this._signalProcessing.configProcessor('Expand8To12', {enabled: dataParams.expand8To12});
        var signal = this._signalProcessing.getOutputSignal(dataParams, false);
        var qrs = (name === 'main') ? this._getQrs(dataParams) : [];

        // Converting signal to viewer format
        var outSignal = this._convertSignalFormat(signal, qrs, dataParams.requestedChannels, expand, isChannelSelected);

        callback && callback(outSignal);
    },

    /*
        return qrs array.
        return only qrs annotations that are visible in the current time window.
    */
    _getQrs: function(dataParams) {
        var i=0;
        var qrs = [];

        i = 0;
        while ((i < this._annotationsData.length) && (this._annotationsData[i].x < dataParams.startTime)) { // find first qrs in the time span
            i++;
        }
        while ((i < this._annotationsData.length) && (this._annotationsData[i].x <= dataParams.endTime)) { // get all qrs in the time span
            qrs.push({
                annotations: this._annotationsData[i].annotations.slice(),
                color: this._annotationsData[i].color,
                tooltip: this._annotationsData[i].tooltip,
                x: this._annotationsData[i].x,
            });
            i++;
        }

        return qrs;
    },

    /*
        get qrs color name by qrs type
    */
    _getQrsColor: function(qrsType) {
        return (typeof this._config.qrsTypes[qrsType] !== 'undefined') ? this._config.qrsTypes[qrsType].COLOR_NAME : 'undefined';
    },

    /*
        Convert signal processing result to our signal format
    */
    _convertSignalFormat: function(signal, qrs, requestedChannels, expand, isChannelSelected) {
        var outSignal = {
            leadNames: [],
            fullLeadNames: [],
            series: [],
            qrs: []
        };

        outSignal.qrs = qrs.slice(); // copy the array
        outSignal.shouldConvertQrsToPixels = true;

        // Fake lead names (for now)
        // set lead names in case metadata.electrodesPosition is undefined or the value is not one of the supported positions
        outSignal.leadNames = (expand) ? ['I', 'II' , 'III', 'aVR', 'aVL', 'aVF', 'Vx'] : ['I', 'II', 'Vx'];

        var isVetDevice = this._bcViewer.isVetDevice({
            deviceNumber: this._metadata.deviceNumber,
            deviceClass: this._metadata.deviceClass
        });

        var positions = this._bcViewer.electrodesPositions;
        for (var pos in positions) {
            var posItem = positions[pos];
            if (posItem.id === this._metadata.electrodesPosition) {
                outSignal.leadNames = (expand) ? posItem.expandedLeadNames.slice() : posItem.leadNames.slice();
                if ((isVetDevice || this._metadata.leadSelection === '4w6l') && expand) {
                    outSignal.leadNames = posItem.expandedLeadNamesVet.slice();
                }
                break;
            }
        }

        outSignal.fullLeadNames = outSignal.leadNames;
       // If channel is selected, this is the context viewer and we want to show all the lead names
       if ((!isChannelSelected) && (typeof requestedChannels !== 'undefined')) {
           var requestedLeadNames = [];
           for (var i=0;i<outSignal.leadNames.length;i++) {
               if (requestedChannels.indexOf(i) !== -1) {
                   requestedLeadNames.push(outSignal.leadNames[i]);
               }
           }

           outSignal.leadNames = requestedLeadNames;
       }

        if (typeof signal[0] == 'undefined') {
            return outSignal;
        }

        // Prepare signal in old ShowSignalData format - this is crazy!
        for (var i=0; i<signal[0].length; i++) {
            var series = {};
            var channelNumber = requestedChannels ? requestedChannels[i] : i;
            series.color = 'Ch' + channelNumber;
            series.index = channelNumber;
            series.points = [];
            for (j=0; j<signal.length; j++) {
                var point = [j, signal[j][i]];
                series.points.push(point);
            }
            outSignal.series.push(series);
        }

        return outSignal;
    }
});

}

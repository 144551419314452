import React, { useState, useContext } from 'react'
import { gql, useQuery, useMutation } from '@apollo/client'
import { navigate } from '@reach/router' // eslint-disable-line import/no-unresolved
import clsx from 'clsx'
import dayjs from 'dayjs'
// import usePagination from '../hooks/usePagination'
import AuthContext from './AuthContext'
import { GET_CLINIC_QUEUES_REFERRALS } from './queries'
import Layout from './Layout'
import Loader from './Loader'
import ReferralCancel from './ReferralCancel'
import {
  DATE_FORMAT_CONVERTER,
  REFERRAL_TYPES_CONVERTER,
  isAuthorizedAction,
  localizePatientName
} from '../../utils/utils'
import { useLocalization } from './LocalizationProvider'
import SomethingWentWrong from './SomethingWentWrong'

const FIRST = 50

const UPDATE_REFERRAL = gql`
  mutation UPDATE_REFERRAL(
    $input: ReferralUpdateInput!
    $releaseTargetCaregiverId: ID!
    $releaseTargetClinicId: ID!
    $targetClinic: ClinicConnectionInput!
    $targetCaregiver: CaregiverConnectionInput!
    $isRelease: Boolean!
    $isAssign: Boolean!
  ) {
    studyReferralRelease(
      input: $input
      targetCaregiverId: $releaseTargetCaregiverId
      targetClinicId: $releaseTargetClinicId
    ) @include(if: $isRelease) {
      id
    }
    studyReferralAssign(
      input: $input
      targetClinic: $targetClinic
      targetCaregiver: $targetCaregiver
    ) @include(if: $isAssign) {
      id
    }
  }
`

const ReferralsQueue = () => {
  const { locale } = useLocalization()
  // const [{ before, after, first }, setPagination] = usePagination(FIRST)
  const [referralToCancel, setReferralToCancel] = useState(null)
  const [loadingId, setLoadingId] = useState(null)
  const currentUser = useContext(AuthContext)
  const { loading, error, data } = useQuery(GET_CLINIC_QUEUES_REFERRALS, {
    variables: {
      id: currentUser.clinic.id,
      startStatus: 'Pending',
      endStatus: 'PaymentPending',
      first: FIRST
      // first,
      // before,
      // after
    },
    skip: !isAuthorizedAction(
      currentUser.clinic.authorizedActions,
      'Clinic:referralsQueues'
    )
  })
  const [updateReferral] = useMutation(UPDATE_REFERRAL, {
    refetchQueries: [
      {
        query: GET_CLINIC_QUEUES_REFERRALS,
        variables: {
          id: currentUser.clinic.id,
          startStatus: 'Pending',
          endStatus: 'PaymentPending',
          first: FIRST
          // first,
          // before,
          // after
        },
        skip: !isAuthorizedAction(
          currentUser.clinic.authorizedActions,
          'Clinic:referralsQueues'
        )
      }
    ],
    update(
      cache,
      { data: { studyReferralAssign, studyReferralRelease } },
      { variables }
    ) {
      const clinicId = cache.identify(currentUser.clinic)

      if (
        currentUser.id === variables.targetCaregiver.id &&
        studyReferralAssign
      ) {
        cache.modify({
          id: clinicId,
          fields: {
            targetReferralsTotal: (value) => value + 1
          }
        })
        cache.evict({ id: clinicId, fieldName: 'targetReferrals' })
      } else if (
        currentUser.id === variables.releaseTargetCaregiverId &&
        studyReferralRelease
      ) {
        cache.modify({
          id: clinicId,
          fields: {
            targetReferralsTotal: (value) => value - 1
          }
        })
        cache.evict({ id: clinicId, fieldName: 'targetReferrals' })
      }

      if (
        !data.clinic.referralsQueues.find(
          (referralsQueue) =>
            referralsQueue.queueType === 'AssignmentAdminsAndCaregivers' &&
            referralsQueue.referrals.edges.find(
              ({ node: referral }) => referral.id === variables.input.id
            ) &&
            referralsQueue.caregivers.find(
              (caregiver) => caregiver.id === currentUser.id
            )
        )
      ) {
        return
      }

      if (studyReferralAssign && !studyReferralRelease) {
        cache.modify({
          id: clinicId,
          fields: {
            referralsQueuesReferralsTotal: (value) => value - 1
          }
        })
        cache.evict({
          id: clinicId,
          fieldName: 'referralsQueues',
          args: { isAdmin: false, queueType: 'AssignmentAdminsAndCaregivers' }
        })
      } else if (!studyReferralAssign && studyReferralRelease) {
        cache.modify({
          id: clinicId,
          fields: {
            referralsQueuesReferralsTotal: (value) => value + 1
          }
        })
        cache.evict({
          id: clinicId,
          fieldName: 'referralsQueues',
          args: { isAdmin: false, queueType: 'AssignmentAdminsAndCaregivers' }
        })
      }
    }
  })

  if (error) return <SomethingWentWrong />

  return (
    <Layout>
      {isAuthorizedAction(
        currentUser.clinic.authorizedActions,
        'Clinic:referralsQueues'
      ) && (
        <div className='section'>
          <div className='container'>
            {loading ? (
              <Loader />
            ) : (
              <>
                {referralToCancel && (
                  <ReferralCancel
                    referral={referralToCancel}
                    isCancelByTarget
                    onComplete={() => {
                      setReferralToCancel(null)
                    }}
                    onExit={() => setReferralToCancel(null)}
                    refetchQueries={[
                      {
                        query: GET_CLINIC_QUEUES_REFERRALS,
                        variables: {
                          id: currentUser.clinic.id,
                          startStatus: 'Pending',
                          endStatus: 'PaymentPending',
                          first: FIRST
                          // first,
                          // before,
                          // after
                        },
                        skip: !isAuthorizedAction(
                          currentUser.clinic.authorizedActions,
                          'Clinic:referralsQueues'
                        )
                      }
                    ]}
                    onUpdate={(
                      cache,
                      {
                        data: {
                          studyReferralCancel: { id }
                        }
                      }
                    ) => {
                      cache.modify({
                        id: cache.identify(currentUser.clinic),
                        fields: {
                          targetReferralsTotal: (value) => value - 1
                        }
                      })
                      cache.modify({
                        id: cache.identify(currentUser.clinic),
                        fields: {
                          targetReferrals: ({ edges, pageInfo }) => ({
                            edges: edges.filter(({ node }) => node.id !== id),
                            pageInfo
                          })
                        }
                      })
                      cache.modify({
                        id: `Study:${referralToCancel.studyId}`,
                        fields: {
                          referrals: (referrals) =>
                            referrals.filter((r) => r.id !== id)
                        }
                      })
                    }}
                  ></ReferralCancel>
                )}
                {data.clinic.referralsQueues.map((referralsQueue) => (
                  <div key={referralsQueue.id} className='mb-6'>
                    <h2 className='subtitle is-5 has-text-weight-medium'>
                      {referralsQueue.name}
                    </h2>
                    <hr className='has-background-grey-lighter' />
                    <div className='table-container'>
                      <table className='table is-fullwidth is-hoverable'>
                        <thead>
                          <tr>
                            <th>{locale.ordered_by}</th>
                            <th>{locale.studies.patient_name}</th>
                            <th>{locale.studies.type}</th>
                            <th>{locale.studies.date}</th>
                            <th>{locale.status}</th>
                            <th>{locale.report_requests.assign_to}</th>
                            <th
                              style={{
                                paddingLeft: 'calc(0.75em + 1em + 1px)'
                              }}
                            >
                              {locale.report_requests.actions}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {referralsQueue.referrals.edges.map(
                            ({ node: referral }) => (
                              <tr
                                key={referral.studyId}
                                className='c-hand'
                                onClick={() =>
                                  navigate(`/study/${referral.studyId}`)
                                }
                              >
                                <td className='is-vcentered'>
                                  {referral.sourceCaregiver.name}
                                </td>
                                <td className='is-vcentered'>
                                  {localizePatientName(
                                    referral.patient.name,
                                    locale
                                  )}
                                </td>
                                <td className='is-vcentered'>
                                  {
                                    locale.studies.reports[
                                      REFERRAL_TYPES_CONVERTER[
                                        referral.referralType
                                      ]
                                    ]
                                  }
                                </td>
                                <td className='is-vcentered'>
                                  {dayjs(referral.createdAt).format(
                                    `${
                                      DATE_FORMAT_CONVERTER[
                                        currentUser.settings.dateFormat
                                      ]
                                    } HH:mm:ss`
                                  )}
                                </td>
                                <td className='is-vcentered'>
                                  {locale.referrals.status[referral.status]}
                                </td>
                                <td className='is-vcentered'>
                                  <div className='control'>
                                    <div
                                      className={clsx('select', {
                                        'is-loading': loadingId === referral.id
                                      })}
                                    >
                                      <select
                                        value={
                                          referral.isTargetIsQueue
                                            ? referral.targetQueue.id
                                            : referral.targetCaregiver.id
                                        }
                                        disabled={loadingId}
                                        onClick={(e) => e.stopPropagation()}
                                        onChange={async (e) => {
                                          setLoadingId(referral.id)

                                          let isRelease,
                                            isAssign,
                                            targetCaregiver = {
                                              id: 'TEMP',
                                              name: 'TEMP',
                                              email: 'TEMP@beecardia.com'
                                            },
                                            targetClinic = {
                                              id: 'TEMP',
                                              name: 'TEMP'
                                            },
                                            releaseTargetCaregiverId = 'TEMP',
                                            releaseTargetClinicId = 'TEMP'

                                          const queueMember =
                                            referralsQueue.caregivers.find(
                                              (queueMember) =>
                                                queueMember.id ===
                                                e.target.value
                                            )

                                          if (queueMember) {
                                            targetCaregiver = {
                                              id: queueMember.id,
                                              name: queueMember.name,
                                              email: queueMember.email
                                            }
                                            targetClinic = {
                                              id: queueMember.clinic.id,
                                              name: queueMember.clinic.name
                                            }
                                          }

                                          if (referral.isTargetIsQueue) {
                                            isRelease = false
                                            isAssign = true
                                          } else if (
                                            e.target.value === referralsQueue.id
                                          ) {
                                            isRelease = true
                                            isAssign = false
                                            releaseTargetCaregiverId =
                                              referral.targetCaregiver.id
                                            releaseTargetClinicId =
                                              referral.targetClinic.id
                                          } else {
                                            isRelease = true
                                            isAssign = true
                                            releaseTargetCaregiverId =
                                              referral.targetCaregiver.id
                                            releaseTargetClinicId =
                                              referral.targetClinic.id
                                          }

                                          await updateReferral({
                                            variables: {
                                              input: {
                                                id: referral.id,
                                                updatedAt:
                                                  new Date().toISOString()
                                              },
                                              releaseTargetCaregiverId,
                                              releaseTargetClinicId,
                                              targetCaregiver,
                                              targetClinic,
                                              isRelease,
                                              isAssign
                                            },
                                            awaitRefetchQueries: true
                                          })

                                          setLoadingId(null)
                                        }}
                                      >
                                        <option value={referralsQueue.id}>
                                          {referralsQueue.name}
                                        </option>
                                        <option disabled>{'----'}</option>
                                        {referralsQueue.caregivers
                                          .filter(
                                            (queueMember) =>
                                              queueMember.referralType ===
                                              referral.referralType
                                          )
                                          .map((queueMember) => (
                                            <option
                                              value={queueMember.id}
                                              key={queueMember.id}
                                            >
                                              {queueMember.name}
                                            </option>
                                          ))}
                                      </select>
                                    </div>
                                  </div>
                                </td>
                                <td className='is-vcentered'>
                                  {referral.status !== 'Done' &&
                                  !referral.targetCaregiver &&
                                  referral.price === 0 ? (
                                    <button
                                      className='button is-text'
                                      onClick={(e) => {
                                        e.stopPropagation()
                                        setReferralToCancel(referral)
                                      }}
                                    >
                                      {locale.report_requests.cancel_report}
                                    </button>
                                  ) : null}
                                </td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                    </div>
                    {/* <nav
                  className='pagination'
                  role='navigation'
                  aria-label='pagination'
                >
                  <a
                    className='pagination-previous'
                    disabled={!referrals.pageInfo.hasPreviousPage || loadingId}
                    onClick={() => {
                      if (referrals.pageInfo.hasPreviousPage && !loadingId) {
                        setPagination(referrals.pageInfo.startCursor, null)
                      }
                    }}
                  >
                    {locale.previous}
                  </a>
                  <a
                    className='pagination-next'
                    disabled={!referrals.pageInfo.hasNextPage || loadingId}
                    onClick={() => {
                      if (referrals.pageInfo.hasNextPage && !loadingId) {
                        setPagination(null, referrals.pageInfo.endCursor)
                      }
                    }}
                  >
                    {locale.next}
                  </a>
                </nav> */}
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
      )}
    </Layout>
  )
}

export default ReferralsQueue

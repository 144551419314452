if (typeof window !== 'undefined') {

PatientDetailsTable = {};

PatientDetailsTable.cleanup = function() {
  $('body').removeClass('mobile_patient_animal');
}

PatientDetailsTable.fillPrivateAttributes = function() {
    /* // $('#patient_name').text(bcGlobals.patientsManager.getPrivateAttribute(bcGlobals.study.patient_hash_id, 'name', 'string', bcGlobals.generalLocale.anonymous));
    var birthdate_timestamp = bcGlobals.patientsManager.getPrivateAttribute(bcGlobals.study.patient_hash_id, 'birthdate', 'number', 'unknown');
    var age = {};
    if ($.isNumeric(birthdate_timestamp) && birthdate_timestamp > -2000000000) {
        var birthDate = new Date(birthdate_timestamp * 1000);
        var birthDateString = formatDate(birthDate.getTime(), bcGlobals.studyHeaderLocale.birthdate_format);
        $('#patient_birthdate').text(birthDateString);
        age = bcGlobals.patientsManager.calculateAge(birthDate, bcGlobals.study.start_time);
    }

    patient_age = age2str(age);

    $('#patient_age').text(patient_age);
    $('#patient_id').text(bcGlobals.patientsManager.getPrivateAttribute(bcGlobals.study.patient_hash_id, 'patient_id'));
    $('#patient_email').text(bcGlobals.patientsManager.getPrivateAttribute(bcGlobals.study.patient_hash_id, 'email'));
    $('#patient_phone').text(bcGlobals.patientsManager.getPrivateAttribute(bcGlobals.study.patient_hash_id, 'phone'));
*/
    var isAnimal =  bcGlobals.isVeterinarian;//bcGlobals.patientsManager.getPrivateAttribute(bcGlobals.study.patient_hash_id, 'is_animal', 'boolean', 'false');
    if (isAnimal && (bcGlobals.study.record_type == bcGlobals.recordTypes.MOBILE)) {
        $('body').addClass('mobile_patient_animal'); // TBD: add class mobile
    }
    $('#animal_remarks').toggleClass('hide_animal_remarks', !isAnimal)
};

function age2str(age) {
    if (bcGlobals.study.age > 0 && bcGlobals.study.age < 110) return bcGlobals.study.age;
    if (age.years > 0) return age.years;
    if (age.months > 0) return age.months + ' ' + bcGlobals.studyHeaderLocale.months;
    if (age.days > 0) return age.days + ' ' + bcGlobals.studyHeaderLocale.days;
    return bcGlobals.studyHeaderLocale.unknown;
}

function formatDate(msecs, format) {
  var date = new Date(msecs);
  var day = date.getDate();
  var month = date.getMonth()+1;
  var year = date.getFullYear();

  var dateString = format;
  dateString = dateString.replace(/yyyy/, year);
  dateString = dateString.replace(/yy/, String(year).substr(2));
  dateString = dateString.replace(/MM/, this.zeroPadd(month));
  dateString = dateString.replace(/M/, month);
  dateString = dateString.replace(/dd/, this.zeroPadd(day));
  dateString = dateString.replace(/d/, day);

  return dateString;
};

window.PatientDetailsTable = PatientDetailsTable;

}

import React, { useState } from 'react'
import { gql, ApolloConsumer } from '@apollo/client'
import clsx from 'clsx'
import Auth from '@aws-amplify/auth'
import Modal from '../Modal'
import { hashPassword } from '../../../utils/utils'
import { useLocalization } from '../LocalizationProvider'

const USER_EMAIL_UPDATE = gql`
  mutation USER_EMAIL_UPDATE($input: UserUpdateInput!) {
    userUpdate(input: $input) {
      id
    }
  }
`

export default function EmailEdit({ id, oldEmail, onComplete, onExit }) {
  const [inProgress, setProgress] = useState(false)
  const [password, setPassword] = useState('')
  const [email, setEmail] = useState('')
  const [code, setCode] = useState('')
  const [mode, setMode] = useState('password')
  const [errorPassword, setErrorPassword] = useState(false)
  const [errorEmail, setErrorEmail] = useState(false)
  const [errorSameEmail, setErrorSameEmail] = useState(false)
  const [errorAliasEmail, setErrorAliasEmail] = useState(false)
  const [errorCode, setErrorCode] = useState(false)
  const { locale } = useLocalization()
  async function passwordCheck() {
    try {
      setErrorPassword(false)
      setProgress(true)
      const user = await Auth.currentAuthenticatedUser()
      const passwordHashed = hashPassword(oldEmail, password)
      await Auth.changePassword(user, passwordHashed, passwordHashed)
      setProgress(false)
      setMode('send')
    } catch (e) {
      setProgress(false)
      if (e.code === 'NotAuthorizedException') {
        setErrorPassword(true)
      }
    }
  }
  async function send() {
    if (email === oldEmail) {
      setErrorSameEmail(true)
      return
    }
    try {
      setErrorEmail(false)
      setErrorSameEmail(false)
      setProgress(true)
      const user = await Auth.currentAuthenticatedUser()
      await Auth.updateUserAttributes(user, { email })
      setProgress(false)
      setMode('submit')
    } catch (e) {
      setProgress(false)
      if (e.code === 'InvalidParameterException') {
        setErrorEmail(true)
      }
    }
  }
  async function resend() {
    setProgress(true)
    await Auth.verifyCurrentUserAttribute('email')
    setProgress(false)
  }
  async function submit(client, id) {
    try {
      setErrorCode(false)
      setErrorAliasEmail(false)
      setProgress(true)
      await Auth.verifyCurrentUserAttributeSubmit('email', code)
      const user = await Auth.currentAuthenticatedUser()
      const oldPasswordHashed = hashPassword(oldEmail, password)
      const newPasswordHashed = hashPassword(email, password)
      await Auth.changePassword(user, oldPasswordHashed, newPasswordHashed)
      await client.mutate({
        mutation: USER_EMAIL_UPDATE,
        variables: {
          input: {
            id,
            email,
            oldEmail,
            updatedAt: new Date().toISOString()
          }
        }
      })
      /* TODO: remove after migration */
      try {
        const iframe = document.createElement('iframe')
        iframe.setAttribute(
          'src',
          window.location.origin + '/en/account/edit_profile'
        )
        iframe.setAttribute('hidden', 'hidden')
        document.getElementsByTagName('body')[0].appendChild(iframe)
        await new Promise((resolve) => iframe.addEventListener('load', resolve))
        const csrfToken = iframe.contentWindow.document
          .querySelector("meta[name='csrf-token']")
          .getAttribute('content')
        iframe.parentNode.removeChild(iframe)
        const response = await window.fetch(`/api/update_email`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
            'X-CSRF-Token': csrfToken
          },
          body: new URLSearchParams({
            current_password: oldPasswordHashed,
            new_password: newPasswordHashed,
            email: email,
            is_request_from_new_app: true
          })
        })
        const result = await response.json()
        console.log('update_email result:', result)
      } catch (e) {
        console.error(e)
      }
      /* TODO: remove after migration */

      setProgress(false)
      onComplete(email)
    } catch (e) {
      setProgress(false)
      if (e.code === 'CodeMismatchException') {
        setErrorCode(true)
      } else if (e.code === 'AliasExistsException') {
        setErrorAliasEmail(true)
      }
    }
  }

  return (
    <ApolloConsumer>
      {(client) => (
        <Modal
          onClose={onExit}
          onSubmit={() =>
            mode === 'password'
              ? passwordCheck()
              : mode === 'send'
              ? send()
              : submit(client, id)
          }
        >
          <div className='field'>
            <label className='label is-medium'>{locale.email}</label>
          </div>
          <hr />
          <div className={clsx('field', { 'is-hidden': mode !== 'password' })}>
            <label className='label'>{locale.profile.new_email_password}</label>
            <div className='control'>
              <input
                type='password'
                className={clsx('input', { 'is-danger': errorPassword })}
                value={password}
                onChange={(e) => setPassword(e.target.value.trim())}
                readOnly={inProgress}
              />
            </div>
            {errorPassword && (
              <p className='help is-danger'>
                {locale.profile.enter_valid_password}
              </p>
            )}
          </div>
          <div className={clsx('field', { 'is-hidden': mode === 'password' })}>
            <label className='label'>{locale.profile.new_email}</label>
            <div className='control'>
              <input
                key='username'
                type='text'
                name='username'
                className={clsx('input', {
                  'is-danger': errorEmail || errorSameEmail
                })}
                value={email}
                onChange={(e) => setEmail(e.target.value.trim())}
                disabled={mode === 'submit'}
                readOnly={inProgress}
              />
            </div>
            {errorEmail && (
              <p className='help is-danger'>
                {locale.profile.enter_valid_email}
              </p>
            )}
            {errorSameEmail && (
              <p className='help is-danger'>
                {locale.profile.enter_different_email}
              </p>
            )}
          </div>
          <div className={clsx('field', { 'is-hidden': mode !== 'submit' })}>
            <label className='label'>{locale.profile.confirmation_code}</label>
            <div className='control'>
              <input
                key='code'
                type='text'
                name='code'
                className={clsx('input', {
                  'is-danger': errorCode || errorAliasEmail
                })}
                value={code}
                autoComplete='off'
                onChange={(e) => setCode(e.target.value.trim())}
                readOnly={inProgress}
              />
            </div>
            {errorCode && (
              <p className='help is-danger'>{locale.profile.wrong_code}</p>
            )}
            {errorAliasEmail && (
              <p className='help is-danger'>{locale.profile.alias_email}</p>
            )}
            <p className='help'>
              <span className='level'>
                <span className='level-left'>
                  <span className='level-item'>{locale.profile.lost_code}</span>
                  <button
                    className='button is-small is-inverted is-primary level-item'
                    onClick={resend}
                    disabled={inProgress}
                  >
                    {locale.profile.resend_code}
                  </button>
                </span>
              </span>
            </p>
          </div>
          <hr />
          <div className='field'>
            <div className='level'>
              <div className='level-left' />
              <div className='level-right'>
                <button
                  className={clsx('button is-primary level-item', {
                    'is-loading': inProgress
                  })}
                  disabled={inProgress}
                  onClick={async () =>
                    mode === 'password'
                      ? passwordCheck()
                      : mode === 'send'
                      ? send()
                      : submit(client, id)
                  }
                >
                  {mode === 'password'
                    ? locale.profile.new_email_password_continue
                    : mode === 'send'
                    ? locale.profile.update_email
                    : locale.profile.do_submit}
                </button>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </ApolloConsumer>
  )
}

import React, { useState } from 'react'
import clsx from 'clsx'
import S3 from 'aws-sdk/clients/s3'
import { currentCredentials } from '../../../utils/credentials'

const AdminUploadApplication = () => {
  const [appFile, setAppFile] = useState(null)
  const [description, setDescription] = useState('')
  const [showNotification, setShowNotification] = useState(false)
  const [uploading, setUploading] = useState(false)

  return (
    <div className='mt-5'>
      <div>
        <input
          type='file'
          onChange={(event) => {
            setAppFile({
              file: event.target.files[0],
              key: event.target.files[0].name
            })
          }}
        ></input>
      </div>
      <div className='mt-5'>
        <textarea
          className='textarea'
          value={description}
          placeholder='description'
          onChange={(e) => setDescription(e.target.value)}
        ></textarea>
      </div>
      <div className='mt-2'>
        <label className='checkbox'>
          <input
            type='checkbox'
            checked={showNotification}
            onChange={(e) => setShowNotification(e.target.checked)}
            className='mr-2'
          />
          Show notification
        </label>
      </div>
      <div className='mt-4'>
        <button
          className={clsx('button', 'is-primary', {
            'is-loading': uploading
          })}
          onClick={async () => {
            setUploading(true)
            const credentials = await currentCredentials()
            const s3 = new S3({ credentials, region: 'eu-west-1' })
            await s3
              .putObject({
                Bucket: process.env.MobileAppVersionsBucket,
                Key: appFile.key,
                Body: appFile.file,
                ContentType: 'application/vnd.android.package-archive',
                ACL: 'public-read'
              })
              .promise()

            const appVersion = appFile.key
              .replace('bee-doctor-v', '')
              .replace('.apk', '')
            await s3
              .putObject({
                Bucket: process.env.MobileAppVersionsDescriptionBucket,
                Key: `${
                  1000000000000 - parseInt(Date.now() / 1000)
                } - ${appVersion}.json`,
                Body: JSON.stringify({
                  app_version: appVersion,
                  link: `https://s3.eu-west-1.amazonaws.com/${process.env.MobileAppVersionsDescriptionBucket}/${appFile.key}`,
                  description,
                  should_show_notification: showNotification
                }),
                ContentType: 'application/json'
              })
              .promise()

            setUploading(false)
          }}
        >
          Upload
        </button>
      </div>
    </div>
  )
}

export default AdminUploadApplication

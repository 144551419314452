import React, { useEffect } from 'react'

import '../legacy/js/study_summary'
import '../legacy/js/measurement_values_manager'

import '../legacy/stylesheets/StudySummary.css'
import { useLocalization } from './LocalizationProvider'

const StudySummary = ({
  animalType,
  activateTab,
  setInitialMEA,
  studyId,
  isCaregiverVeterinarian,
  currentUser
}) => {
  const { locale } = useLocalization()

  useEffect(() => {
    window.bcGlobals = window.bcGlobals || {}

    window.bcGlobals.isAnimal = isCaregiverVeterinarian

    window.bcGlobals.animalTypesEnum = {
      OTHER: 'Other',
      HORSE: 'Horse',
      BIG_DOG: 'BigDog',
      MEDIUM_DOG: 'MediumDog',
      SMALL_DOG: 'SmallDog',
      CAT: 'Cat',
      RABBIT: 'Rabbit',
      FERRET: 'Ferret',
      CAVY: 'Cavy',
      RAT: 'Rat',
      DOG: 'Dog',
      BIRD: 'Bird'
    }

    window.bcGlobals.studySummaryLocale = locale.study_summary

    window.bcGlobals.animalType = animalType

    window.$(document).on('summaryParametersReady', function (e) {
      if (typeof e.summaryParameters.medianTimeParameters !== 'undefined') {
        !isCaregiverVeterinarian &&
          window.StudySummary.fillSummaryParametersTable(
            '#study-summary-tab',
            e.summaryParameters,
            activateTab
          )
        if (!(currentUser && currentUser.email === 'info@elecografista.com')) {
          window.StudySummary.drawMeanElectricalAxisChart(
            '#study-summary-tab',
            e.summaryParameters.medianAngleParameters.qrsAngle,
            activateTab
          )
          const initialMEA = window.MeasurementValuesManager.getMeaLabel(
            e.summaryParameters.medianAngleParameters.qrsAngle
          )
          setInitialMEA(initialMEA ? { mea: initialMEA } : null)
        }
      }
    })

    window.$(document).on('eventsDataReady', function (e) {
      !isCaregiverVeterinarian &&
        window.StudySummary.fillEventsTable(
          '#study-summary-tab',
          e.eventsData,
          activateTab
        )
    })

    return () => {
      window.StudySummary.clearStudySummary('#study-summary-tab')
      window.$(document).off('summaryParametersReady')
      window.$(document).off('eventsDataReady')
    }
  }, [studyId])
  return (
    <>
      <div className='study_summary'>
        <div
          dangerouslySetInnerHTML={{
            /* eslint-disable no-template-curly-in-string */
            __html:
              '<script id="qrs_median_entry_template" type="text/x-jquery-tmpl"><tr><td class="study_qrs_median_summary_attribute">${title}</td><td class="study_summary_number_value study_summary_min">${min}</td><td class="study_summary_number_value">${avg}</td><td class="study_summary_number_value study_summary_max">${max}</td><td class="study_qrs_median_summary_attribute">${extraTitle}</td><td class="study_summary_number_value">${extraData}</td></tr></script>' +
              '<script id="events_summary_entry_template" type="text/x-jquery-tmpl"><td class="study_summary_event_symbol" style="color:${color}">${symbol}</td><td>${name}</td><td class="study_summary_number_value">${occurrences}</td><td class="study_summary_number_value">${duration}</td></script>'
            /* eslint-enable no-template-curly-in-string */
          }}
        />
        <h3 className='study_summary_title'>{locale.study_summary.title}</h3>
        <div className='study_qrs_median_summary_wrapper'>
          <table className='study_qrs_median_summary'>
            <tbody>
              <tr>
                <td colSpan='6' className='study_summary_subtitle'>
                  {locale.study_summary.ecg_summary_title}
                </td>
              </tr>
              <tr>
                <td className='study_qrs_median_summary_title' />
                <td className='study_qrs_median_summary_title study_summary_min'>
                  {locale.study_summary.min}
                </td>
                <td className='study_qrs_median_summary_title'>
                  {locale.study_summary.avg}
                </td>
                <td className='study_qrs_median_summary_title study_summary_max'>
                  {locale.study_summary.max}
                </td>
                <td className='study_qrs_median_summary_title' />
                <td className='study_qrs_median_summary_title' />
              </tr>
            </tbody>
          </table>
        </div>
        <div className='study_events_summary_wrapper'>
          <table className='study_events_summary'>
            <tbody>
              <tr>
                <td colSpan='4' className='study_summary_subtitle'>
                  {locale.study_summary.events_title}
                </td>
              </tr>
              <tr>
                <td className='study_events_summary_title study_summary_event_symbol'>
                  {locale.study_summary.symbol}
                </td>
                <td className='study_events_summary_title'>
                  {locale.study_summary.name}
                </td>
                <td className='study_events_summary_title'>
                  {locale.study_summary.occurrences}
                </td>
                <td className='study_events_summary_title'>
                  {locale.study_summary.duration}
                </td>
              </tr>
              <tr data-event-id='2' />
              <tr data-event-id='3' />
              <tr data-event-id='5' />
              <tr className='study_events_summary_group' data-event-id='27' />
              <tr data-event-id='28' />
              <tr data-event-id='29' />
              <tr data-event-id='30' />
              <tr data-event-id='31' />
              <tr data-event-id='32' />
              <tr data-event-id='33' />
              <tr data-event-id='34' />
              <tr data-event-id='39' />
              <tr className='study_events_summary_group' data-event-id='19' />
              <tr data-event-id='20' />
              <tr data-event-id='21' />
              <tr data-event-id='22' />
              <tr data-event-id='23' />
              <tr data-event-id='24' />
              <tr data-event-id='25' />
              <tr className='study_events_summary_group' data-event-id='14' />
              <tr data-event-id='15' />
              <tr data-event-id='16' />
              <tr data-event-id='17' />
              <tr className='study_events_summary_group' data-event-id='35' />
              <tr data-event-id='36' />
              <tr data-event-id='37' />
              <tr data-event-id='38' />
              <tr className='study_events_summary_group' data-event-id='9' />
              <tr data-event-id='10' />
              <tr data-event-id='11' />
              <tr data-event-id='12' />
              <tr className='more_events_note'>
                <td colSpan='4'>{locale.study_summary.more_events_note}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className='mean_electrical_axis_wrapper'>
        <h3 className='mean_electrical_axis_title has-text-weight-bold'>
          {locale.study_summary.mea_title}
        </h3>
        <div className='mean_electrical_axis_chart'></div>
      </div>
    </>
  )
}

export default StudySummary

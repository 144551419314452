import React, { useEffect } from 'react'
import useScript from '../hooks/useScript'

const QuestionnaireBuilder = () => {
  useScript('https://code.jquery.com/jquery-3.1.1.min.js')
  useScript(
    'http://ajax.microsoft.com/ajax/jquery.templates/beta1/jquery.tmpl.min.js'
  )
  useEffect(() => {
    var $ = window.$
    var items = []
    var selected = 'text'

    function TextField() {
      this.type = 'text'
      this.title = ''
    }
    var TextCreator = {
      add: function () {
        var textField = new TextField()
        textField.title = $('#textFieldTitle').val()

        items.push(textField)
      },

      clear: function () {
        $('#textFieldTitle').val('')
      }
    }

    function YesnoField() {
      this.type = 'yesno'
      this.title = ''
    }
    var YesnoCreator = {
      add: function () {
        var yesnoField = new YesnoField()
        yesnoField.title = $('#yesnoFieldTitle').val()

        items.push(yesnoField)
      },

      clear: function () {
        $('#yesnoFieldTitle').val('')
      }
    }

    function TextareaField() {
      this.type = 'textarea'
      this.title = ''
    }
    var TextareaCreator = {
      add: function () {
        var textareaField = new TextareaField()
        textareaField.title = $('#textareaFieldTitle').val()

        items.push(textareaField)
      },

      clear: function () {
        $('#textareaFieldTitle').val('')
      }
    }

    function RadioField() {
      this.type = 'radio'
      this.title = ''
      this.options = []
    }
    var RadioCreator = {
      add: function () {
        var radioField = new RadioField()
        radioField.title = $('#radioFieldTitle').val()
        radioField.options.push($('#radioOption1').val())
        radioField.options.push($('#radioOption2').val())
        radioField.options.push($('#radioOption3').val())

        items.push(radioField)
      },

      clear: function () {
        $('#radioFieldTitle').val('')
        $('#radioOption1').val('')
        $('#radioOption2').val('')
        $('#radioOption3').val('')
      }
    }

    function SelectField() {
      this.type = 'select'
      this.title = ''
      this.options = []
    }
    var SelectCreator = {
      add: function () {
        var selectField = new SelectField()
        selectField.title = $('#selectFieldTitle').val()

        var cnt = parseInt($('#selectField ul li').last().attr('data-count'))

        for (var i = 0; i < cnt; i++) {
          var option = $('#selectOption' + (i + 1))
          selectField.options.push(option.val())
        }

        items.push(selectField)
      },

      clear: function () {
        $('#selectFieldTitle').val('')
        $('#selectField ul li:gt(1)').remove()
        $('#selectOption1').val('')
      }
    }

    function appendItem() {
      var item = items[items.length - 1]
      var type = item.type

      var itemId = 'item' + (items.length - 1)
      var itemElem = $('#itemWrapper').tmpl({ cnt: items.length - 1 })

      $('#output').append(itemElem)

      switch (type) {
        case 'text':
          $('#' + itemId).append($('#itemText').tmpl({ title: item.title }))
          break
        case 'yesno':
          $('#' + itemId).append($('#itemYesno').tmpl({ title: item.title }))
          break
        case 'textarea':
          $('#' + itemId).append($('#itemTextarea').tmpl({ title: item.title }))
          break
        case 'radio':
          $('#' + itemId).append(
            $('#itemRadio').tmpl({
              title: item.title,
              option1: item.options[0],
              option2: item.options[1],
              option3: item.options[2]
            })
          )
          break
        case 'select':
          var options = []
          for (var i = 0; i < item.options.length; i++) {
            options.push({ title: item.options[i] })
          }

          var select = $('#itemSelect').tmpl({ title: item.title })
          $('#' + itemId).append(select)

          $('#' + itemId + ' select').append(
            $('#itemSelectOption').tmpl(options)
          )
          break
      }

      $('#deleteItem' + (items.length - 1)).click(function () {
        var index = $(this).attr('data-count')
        items[index] = 'DELETED'
        $('#item' + index).remove()
      })
    }

    function registerEvents() {
      $('#fieldType').change(function () {
        var type = $('#fieldType').val()
        selected = type

        $('.fieldValue').hide()
        $('#' + type + 'Field').show()
      })

      $('#add').click(function () {
        var creator
        switch (selected) {
          case 'text':
            creator = TextCreator
            break
          case 'yesno':
            creator = YesnoCreator
            break
          case 'textarea':
            creator = TextareaCreator
            break
          case 'radio':
            creator = RadioCreator
            break
          case 'select':
            creator = SelectCreator
            break
        }

        creator.add()
        creator.clear()

        appendItem()
      })

      $('#addSelectOption').click(function () {
        var cnt = parseInt($('#selectField ul li').last().attr('data-count'))
        cnt++
        $('#selectField ul').append(
          '<li data-count="' +
            cnt +
            '">Option ' +
            cnt +
            ': <input id="selectOption' +
            cnt +
            '"></input></li>'
        )
      })

      $('#create').click(function () {
        var itemsRes = $.grep(items, function (n) {
          return n !== 'DELETED'
        })
        $('#outputString').val(JSON.stringify(itemsRes).replace(/"/g, '\\"'))
      })

      $('#load').click(function () {
        try {
          var input = JSON.parse($('#outputString').val().replace(/\\"/g, '"'))
        } catch (e) {
          alert('sorry cant load')
          return
        }

        items = []
        $('#output').empty()
        for (var i = 0; i < input.length; i++) {
          items[i] = input[i]
          appendItem()
        }
      })
    }

    registerEvents()
  }, [])

  return (
    <>
      <style>{`

        body {
          background: white;
          padding: 8px
        }

        #add, #create, #load {
          margin-left: 8px;
        }

        #textField {
          display: block;
        }

        #outputString {
          width: 400px;
        }

        .fieldValue {
          display: none;
          border: 1px solid black;
          padding: 10px;
          width: 400px;
          margin-top: 10px;
        }

        #radioField ul,
        #selectField ul	{
          list-style-type: none;
          padding: 0;
          margin: 0;
        }


        #radioField li,
        #selectField li{
          margin-bottom: 10px;
        }

        .item {
          margin-top: 10px;
        }

        .item label {
          margin-right: 10px;
          margin-left: 8px;
          vertical-align: top;
        }

        #createPanel {
          display: inline-block;
          width: 40%;
        }

        #output {
          display: inline-block;
          width: 55%;
          vertical-align: top;
        }

        .item button {
          background-color: #FFFFFF;
          border: 1px solid black;
          color: red;
          cursor: pointer;
          vertical-align: top
        }

        #outputString {
          margin-top: 20px;
        }
      `}</style>
      <h1 className='title'>Questionnaire creator</h1>
      <div id='createPanel'>
        <select id='fieldType'>
          <option value='text'>Text</option>
          <option value='yesno'>Yes No question</option>
          <option value='textarea'>Text area</option>
          <option value='radio'>Radio (3 options)</option>
          <option value='select'>Select</option>
        </select>
        <button id='add'>Add</button>
        <button id='create'>Create</button>
        <button id='load'>Load</button>

        <div id='textField' className='fieldValue'>
          Title: <input id='textFieldTitle'></input>
        </div>

        <div id='yesnoField' className='fieldValue'>
          Title: <input id='yesnoFieldTitle'></input>
        </div>

        <div id='textareaField' className='fieldValue'>
          Title: <input id='textareaFieldTitle'></input>
        </div>

        <div id='radioField' className='fieldValue'>
          <ul>
            <li>
              Title: <input id='radioFieldTitle'></input>
            </li>
            <li>
              option 1: <input id='radioOption1'></input>
            </li>
            <li>
              option 2: <input id='radioOption2'></input>
            </li>
            <li>
              option 3: <input id='radioOption3'></input>
            </li>
          </ul>
        </div>

        <div id='selectField' className='fieldValue'>
          <ul>
            <li>
              Title: <input id='selectFieldTitle'></input>
            </li>
            <li data-count='1'>
              option 1: <input id='selectOption1'></input>
            </li>
          </ul>
          <button id='addSelectOption'>Add Option</button>
        </div>

        <textarea rows='20' cols='50' id='outputString'></textarea>
      </div>
      <div id='output'></div>
      <script id='itemWrapper' type='text/x-jquery-tmpl'>
        <div className='item' id='item${cnt}' data-count='${cnt}'>
          <button id='deleteItem${cnt}' data-count='${cnt}'>
            X
          </button>
        </div>
      </script>
      <script id='itemText' type='text/x-jquery-tmpl'>
        <label>{'${title}'}</label>
        <input defaultValue=''></input>
      </script>
      <script id='itemYesno' type='text/x-jquery-tmpl'>
        <label>{'${title}'}</label>
        <select>
          <option defaultValue='yes'>Yes</option>
          <option defaultValue='no'>No</option>
        </select>
      </script>
      <script id='itemTextarea' type='text/x-jquery-tmpl'>
        <label>{'${title}'}</label>
        <textarea rows='3' cols='16'></textarea>
      </script>
      <script id='itemRadio' type='text/x-jquery-tmpl'>
        <label>{'${title}'}</label>
        <input type='radio' name='radio' /> {'${option1}'}
        <input type='radio' name='radio' /> {'${option2}'}
        <input type='radio' name='radio' /> {'${option3}'}
      </script>
      <script id='itemSelect' type='text/x-jquery-tmpl'>
        <label>{'${title}'}</label>
        <select></select>
      </script>
      <script id='itemSelectOption' type='text/x-jquery-tmpl'>
        <option>{'${title}'}</option>
      </script>
    </>
  )
}

export default QuestionnaireBuilder

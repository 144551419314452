if (typeof window !== 'undefined') {

/*
    HRViewer - the heart-rate viewer class
*/
bcViewer.HRViewer = bcViewer.BaseViewer.extend({
    construct: function() {
        this._super.apply(this, arguments);

        this._view = new bcViewer.HRViewer.View(this._globals, '#bc_viewer .bcv_hr_canvas', bcViewer.HRCanvas, 
                                                this._state.width, this._state.height);
    },
    
    /*
        Apply the new state: If dimensions changed, redraw the HR canvas
    */
    applyStateChanges: function(stateChanges, isInitCycle) {
        this._super(stateChanges);

        if (isInitCycle) return;

        if (typeof stateChanges.width !== 'undefined' || typeof stateChanges.height !== 'undefined') {
            this._dataFetcher.getHRData(
                 this._state.width, this._state.height, $.proxy(this._view.onGotHRData, this._view));
        }
    }
});

}
if (typeof window !== 'undefined') {

/*
    BaseCanvas - base class for all the canvases
*/
bcViewer.BaseCanvas = bcViewer.Class.extend({
    construct: function(config, canvasSelector, width, height) {
        this._config = config;
        this.canvas = canvasSelector ? $$(canvasSelector)[0] : document.createElement('canvas');
        // Keeping jquery ref to the canvas
        this.$ = $(this.canvas);

        this._width;
        this._height;

        if (typeof width !== 'undefined') {
            this.setWidth(width);
        }
        if (typeof height !== 'undefined') {
            this.setHeight(height);
        }

        this.context = this.canvas.getContext('2d');
    },

    /*
        Clear the canvas
    */
    clear: function() {
    //    this.context.clearRect(0, 0, this._width, this._height);
        // Workaround for canvas issue on Android
        // See http://stackoverflow.com/questions/12804710/android-4-html5-canvas-not-redrawing
        this.context.fillStyle = '#FFFFFF';
        this.context.fillRect(0, 0, this._width, this._height);
    },

    /*
        Change the canvas height
    */
    setHeight: function(height) {
        if (height !== this._height) {
            this.canvas.height = height;
            // In order to allow the canvase to resize their width we're setting their width to 100%
            // and updating their height when needed
            this.canvas.style.height = height + 'px';
            this._height = height;
        }
    },

    /*
        Change the canvas width
    */
    setWidth: function(width) {
        if (width !== this._width) {
            this.canvas.width = width;
            this._width = width;
        }
    },

    /*
        Get color from color name
    */
    _getColor: function(colorName, defaultColor) {
        defaultColor = defaultColor || this._config.DEFAULT_COLOR;
        return this._config.COLORS[colorName] || defaultColor;
    },

    /*
        Get x,y position from data point
    */
    _getPositionFromPoint: function(point) {
        return {
            'x': point[0],
            'y': point[1]
        }
    },

    /*
        Call once to init tooltip before using the tooltip
        config - optional parameter for tooltip configuration
    */
    initTooltip: function(config) {
        config = config || {};
        config.items = "canvas";

        this.$.tooltip(config);
    },

    /*
        Show the tooltip
        config - optional parameter for tooltip configuration
    */
    showTooltip: function(config) {
        config && this.$.tooltip(config);
        this.$.tooltip("enable").tooltip("open");
    },

    /*
        Hide the tooltip
    */
    hideTooltip: function() {
        this.$.tooltip("close").tooltip("disable");
    },

    /*
        Change the canvas tooltip text
    */
    changeTooltip: function(text) {
        this.$.tooltip({ content: text });
    },

    /*
        Draw label on canvas
    */
    drawLabel: function(text, x, y, config) {
        // Apply the config
        if (typeof config !== 'undefined') {
            $.each(config, $.proxy(function(key, value) {
               this.context[key] = value;
            },this));
        }

        this.context.fillText(text, x, y);
    },

    /*
        Return canvas width\height
    */
    getDimensions: function() {
        return {
            'width': this._width,
            'height': this._height
        };
    },

    _drawUserMark: function(mark) {
      this.context.beginPath();
      this.context.moveTo(mark.x, 0);
      this.context.lineTo(mark.x, this._height);
      this.context.strokeStyle = mark.color;
      this.context.lineWidth = 2;
      this.context.stroke();
    },

    drawUserMarks: function(userMarksData) {
      this.context.save();
      for (var i=0; i<userMarksData.length; i++) {
        this._drawUserMark(userMarksData[i]);
      }
      this.context.restore();
    }
});

/*
    ECG cahce canvas - handles ECG data caching
*/
bcViewer.ECGCacheCanvas = bcViewer.BaseCanvas.extend({
    construct: function() {
        this._super.apply(this, arguments);

        this._annotationConfig = {
            markLength: 10,
            markWidth: 1,
            qrsCaptionOffset: {
                x: 5,
                y: 2
            },
            eventCaptionMargin: 20,
            eventCaptionOffset: {
                x: 5,
                y: 4
            },
            eventCaptionSeparator: ','
        };
    },

    /*
        Draw the ECG data graph
    */
    draw: function(ecgData, gridCanvas, gridOffset) {
        try{
          this.context.clearRect(0, 0, this._width, this._height);

          gridCanvas && this.context.drawImage(gridCanvas.canvas, gridOffset, 0);

          if (ecgData === null) return;

          for (var i = 0; i < ecgData.series.length; i++) {
              var channelData = ecgData.series[i];

              var points = channelData.points;

              this.context.beginPath();

              // Move to the first data point
              var pos = this._getPositionFromPoint(points[0]);
              this.context.moveTo(pos.x, pos.y);

              // Draw the actual graph
              for (j = 1; j < points.length; j++) {
                  pos = this._getPositionFromPoint(points[j]);
                  this.context.lineTo(pos.x, pos.y);
              }

              var color_name = 'Ch' + (channelData.index % this._config.COLORS_NUMBER);
              var channelColor = this._getColor(color_name, this._config.DEFAULT_CHANNEL_COLOR);
              this.context.strokeStyle = channelColor;
              this.context.stroke();
          }

          this._drawAnnotations(ecgData.qrs);
          this.drawUserMarks(ecgData.userMarks);
        } catch(e) {
        }
    },

    /*
        Draw the QRS annotations
    */
    _drawAnnotations: function(qrsData) {
        if (!qrsData) return;

        this.context.save();
        for (var i=0; i<qrsData.length; i++) {
            var prevAnnotationX = qrsData[i-1] && qrsData[i-1].x
            var nextAnnotationX = qrsData[i+1] && qrsData[i+1].x
            this._drawAnnotation(qrsData[i], prevAnnotationX, nextAnnotationX);
        }
        this.context.restore();
    },

    /*
        Draw QRS annotation
        We draw:
        - one mark at the top and one at the bottom of the graph
        - QRS caption from left of the mark
        - event caption from right of the nark

        annotationData - the annotation location and caption
        prevAnnotationX - the previous annotation location
        nextAnnotationX - the next annotation location
    */
    _drawAnnotation: function(annotationData, prevAnnotationX, nextAnnotationX) {
        this._drawAnnotationMark(annotationData);
        this._drawQrsCaption(annotationData, prevAnnotationX);
        this._drawEventsCaption(annotationData, nextAnnotationX);
    },

    /*
        Draw the annotation mark
    */
    _drawAnnotationMark: function(annotationData) {
        var annotationColor = this._getColor(annotationData.color, this._config.DEFAULT_ANNOTATION_COLOR);

        this.context.beginPath();
        this.context.moveTo(annotationData.x, 0);
        this.context.lineTo(annotationData.x, this._annotationConfig.markLength);
        this.context.moveTo(annotationData.x, this._height);
        this.context.lineTo(annotationData.x, this._height - this._annotationConfig.markLength);

        this.context.strokeStyle = annotationColor;
        this.context.lineWidth = this._annotationConfig.markWidth;

        this.context.stroke();
    },

    /*
        Draw the QRS caption
    */
    _drawQrsCaption: function(annotationData, prevAnnotationX) {
        var annotations = annotationData.annotations;

        var qrsCaptionWidth = this.context.measureText(annotations[0].caption).width;
        var drawQrsCaptionAt = annotationData.x - qrsCaptionWidth - this._annotationConfig.qrsCaptionOffset.x;

        // Don't draw the caption if it overlaps with the previous one
        if (prevAnnotationX && (drawQrsCaptionAt <= prevAnnotationX)) {
            return;
        }

        var qrsTopY = this._annotationConfig.markLength + this._annotationConfig.qrsCaptionOffset.y;
        var qrsBottomY = this._height - this._annotationConfig.qrsCaptionOffset.y;
        var annotationColor = this._getColor(annotations[0].color, this._config.DEFAULT_ANNOTATION_COLOR);

        this._drawAnnotationCaption(annotations[0].caption, drawQrsCaptionAt, qrsTopY, qrsBottomY, annotationColor);
    },

    /*
        Draw the events caption
    */
    _drawEventsCaption: function(annotationData, nextAnnotationX) {
        var annotations = annotationData.annotations;

        var drawEventCaptionAt = annotationData.x + this._annotationConfig.eventCaptionOffset.x;

        // event names saperated by eventCaptionSeparator. example: a,b,c
        var eventCaption = annotations.slice(1).map(function(elem) { return elem.caption; }).join(this._annotationConfig.eventCaptionSeparator);
        var eventCaptionEndsIn = drawEventCaptionAt + this.context.measureText(eventCaption).width;

        // Don't draw the event caption if it overlaps with the next one
        if (nextAnnotationX && (eventCaptionEndsIn + this._annotationConfig.eventCaptionMargin >= nextAnnotationX)) {
            return;
        }

        // Draw event caption. result example: V1, V2, A
        var addedWidth = 0;
        for (var i = 1; i < annotations.length; i++) {
            var annotationColor = this._getColor(annotationData.annotations[i].color, this._config.DEFAULT_ANNOTATION_COLOR);
            var caption = annotations[i].caption;
            caption += (i !== annotations.length -1) ? this._annotationConfig.eventCaptionSeparator : '';
            addedWidth += this.context.measureText(caption).width + this._annotationConfig.eventCaptionOffset.x;
            var eventTopY = this._annotationConfig.markLength*2 + this._annotationConfig.eventCaptionOffset.y;
            var eventBottomY = this._height - this._annotationConfig.eventCaptionOffset.y - this._annotationConfig.markLength;

            this._drawAnnotationCaption(caption, drawEventCaptionAt, eventTopY, eventBottomY, annotationColor);

            drawEventCaptionAt = annotationData.x + this._annotationConfig.eventCaptionOffset.x + addedWidth;
        }
    },

    /*
        Draw qrs annotation
    */
    _drawAnnotationCaption: function(caption, x, topY, bottomY, color) {
        this.context.fillStyle = color;
        this.context.textBaseline = 'bottom';
        this.drawLabel(caption, x, topY);
        this.drawLabel(caption, x, bottomY);
    }
});

/*
    ECG viewer canvas - handles ECG viewer data
*/
bcViewer.ECGViewerCanvas = bcViewer.BaseCanvas.extend({
    construct: function() {
        this._super.apply(this, arguments);

        // A little bit ugly since we're suplicating the same width as the
        // annotation mark width
        this._annotationLineWidth = 1;

        // Saved data so we can restore it after removing annotation line
        this._savedData = {};

        this._savedDataForUserMark = {};

        this._drawUtility = new bcViewer.DrawUtility(this);
        this._userMarkDrawUtility = new bcViewer.UserMarkDrawUtility(this);
    },

    // Draw annotation line at x
    drawAnnotationLine: function(annotationData) {
        this._savedData[annotationData.x] = this.context.getImageData(annotationData.x - this._annotationLineWidth, 0,
                                            this._annotationLineWidth * 2, this._height);

        this.context.save();
        this.context.beginPath();
        this.context.lineWidth = this._annotationLineWidth;
        var annotationColor = this._getColor(annotationData.color, this._config.DEFAULT_ANNOTATION_COLOR);
        this.context.strokeStyle = annotationColor;
        this.context.moveTo(annotationData.x, 0);
        this.context.lineTo(annotationData.x, this._height);
        this.context.stroke();
        this.context.restore();
    },

    // Remove annotation line at x and restore the data
    removeAnnotationLine: function(x) {
        this.context.putImageData(this._savedData[x], x - this._annotationLineWidth, 0);
        delete this._savedData[x];
    },

    // Return text width
    getTextWidth: function(text) {
        return this.context.measureText(text).width;
    },

    // Return the X,Y offset from event
    getPosFromEvent: function(event) {
        try {
            var oe = event.originalEvent;
            var ex = event.pageX || (oe.touches[0] || oe.changedTouches[oe.changedTouches.length-1]).pageX;
            var ey = event.pageY || (oe.touches[0] || oe.changedTouches[oe.changedTouches.length-1]).pageY;
            var x = Math.round(ex - this.$.offset().left);
            var y = Math.round(ey - this.$.offset().top);
            return {
                'x': x,
                'y': y
            }
        } catch (e) {
            return {
              'x': 0,
              'y': 0
            }
        }
    },

    /*
        Draw measurements results
    */
    drawMeasurements: function(boundingBox, measurementResultText) {
        this._drawUtility.drawMeasurements(boundingBox, measurementResultText);
    },

    /*
        Clear last measurements
    */
    clearMeasurements: function() {
        this._drawUtility.clearMeasurements();
    },

    /*
        Init measurement utility class
    */
    initMeasurement: function() {
        this._drawUtility.init();
    },

    drawScale: function(scaleDimensions) {
        var yMargin = 20;
        var xMargin = 10;
        var baselineSize = Math.ceil(scaleDimensions.width/3);

        this.context.lineWidth = 2;
        this.context.strokeStyle = '#000';

        this.context.beginPath();

        this.context.moveTo(this._width - xMargin - scaleDimensions.width - baselineSize, this._height - yMargin);
        this.context.lineTo(this._width - xMargin - scaleDimensions.width, this._height - yMargin);
        this.context.lineTo(this._width - xMargin - scaleDimensions.width, this._height - yMargin - scaleDimensions.height);
        this.context.lineTo(this._width - xMargin, this._height - yMargin - scaleDimensions.height);
        this.context.lineTo(this._width - xMargin, this._height - yMargin);
        this.context.lineTo(this._width - xMargin + baselineSize, this._height - yMargin);

        this.context.stroke();
    },

    initUserMarkPlacer: function() {
        this._userMarkDrawUtility.init();
    },

    drawUserMarkPlacer: function(mark) {
      this._userMarkDrawUtility.drawUserMarkPlacer(mark);
    },

    clearUserMarkPlacer: function(mark) {
      this._userMarkDrawUtility.clearUserMarkPlacer();
    },

    drawUserMarkHighlight: function(x) {
      this._savedDataForUserMark = {
        x: x,
        data: this.context.getImageData(x-2, 0, 4, this._height)
      };

      this.context.save();
      this.context.beginPath();
      this.context.lineWidth = 2;
      this.context.strokeStyle = this._config.userMarks.HIGHLIGHT_COLOR;
      this.context.moveTo(x, 0);
      this.context.lineTo(x, this._height);
      this.context.stroke();
      this.context.restore();
    },

    removeUserMarkHighlight: function() {
      this.context.putImageData(this._savedDataForUserMark.data, this._savedDataForUserMark.x - 2, 0);
      delete this._savedDataForUserMark;
    }
});

/*
    Realtime ECG viewer canvas - handles realtime ECG viewer data
*/
bcViewer.RTECGViewerCanvas = bcViewer.BaseCanvas.extend({
    construct: function() {
        this._super.apply(this, arguments);

        this._xCoord = 0;            // X Coordinate to draw on
        this._isShiftNeeded = false; // indicates if we need to shift the drawing left before drawing
        this._gridBigBlockSize;      // px number of the grid big block
        this._bgSnapshot;            // Saving a background snapshot in order to redraw it when the real time data is "moving"
        this._bgSnapshotWidth;       // px width of the background snapshot
        this._gridOffset;            // The background snapshot is actually a few columns of the grid,
                                     // we need to calculate the needed offset each time the background is moving
        this._saveGridColNumber = 3; // how many grid columns should we save in background snapshot
        this._cacheCanvas = document.createElement('canvas'); // Cache canvas, helps shiting the canvas
        this._cacheCanvasContext = this._cacheCanvas.getContext('2d'); // Cashe canvas context
    },

    /*
        Init the canvas.
        save the <this._saveGridColNumber> columns of the grid background.
    */
    init: function(gridNumberOfPixelsPerBigBlock) {
        this._gridBigBlockSize = gridNumberOfPixelsPerBigBlock;
        this._bgSnapshotWidth = this._gridBigBlockSize * this._saveGridColNumber;
        this._bgSnapshot = this.context.getImageData(0, 0, this._bgSnapshotWidth, this._height);

        this._gridOffset = this._width % this._gridBigBlockSize;

        this._cacheCanvas.width = this._width;
        this._cacheCanvas.height = this._height;

        this.context.imageSmoothingEnabled= false;
        this._cacheCanvasContext.imageSmoothingEnabled= false;
    },

    /*
        Draw realtime data.
        two phases:
            first: draw the data on the canvas. when we reach the end of the canvas, move to phase two
            second: move the canvas left and draw the new data on the end of the canvas

        Input:
            rtData: two dimensional array [channel_index][y_coordinate], hold the y coordinates to draw for each channel
            pointsNumber: how many points to draw
    */
    draw: function(data, pointsNumber) {
        // shift ECG drawing left if needed
        if (this._isShiftNeeded) {
            this._shift(pointsNumber);
        }

        // draw the new data
        this._drawData(data, pointsNumber);

        // If we reached the end of the canvas, start shifting the drawing left
        if ((this._isShiftNeeded === false) && (this._xCoord >= this._width)) {
            this._isShiftNeeded = true;
        }
    },

    /*
        Draw the input data
        data: array of y coordinates to draw
    */
    _drawData: function(data, pointsNumber) {
        for (var i = 0; i < data.length; i++) {
            this.context.beginPath();

            // Set channel color
            var colorName = 'Ch' + (i % this._config.COLORS_NUMBER);
            var channelColor = this._getColor(colorName, this._config.DEFAULT_CHANNEL_COLOR);
            this.context.strokeStyle = channelColor;

            var xCoordinate = this._xCoord;
            for (var j = 0; j < pointsNumber; j++) {
                this.context.moveTo(xCoordinate, data[i][j]);
                this.context.lineTo(xCoordinate+1, data[i][j+1]);

                this.context.stroke();

                xCoordinate++;
            }
        }

        // If no shift is done, calculate the next x coordinate to draw on
        if (this._isShiftNeeded === false) {
            // why pointsNumber-1:  first point is the last of the previous draw
            this._xCoord += pointsNumber - 1;
        }
    },

    /*
        Move the canvas drawing <pointsNumber> pixels to the left
    */
    _shift: function(pointsNumber) {
        try {
          // first point is the last of the previous draw
          var newPointsNumber = pointsNumber - 1;

          // draw the background snapshot in the correct offset
          var bgSnapshotX = this._width - newPointsNumber - this._gridOffset;
          var bgSnapshotWidth = this._bgSnapshotWidth - this._gridOffset;

          // Save the ECG canvas to the realtime cahce canvas
          this._cacheCanvasContext.drawImage(this.canvas, 0, 0);

          // Clear the ECG Canvas
          this.clear();

          // Draw the realtime chache canvas back to the ECG canvas, moving it <newPointsNumber> pixels to the left
          this.context.drawImage(this._cacheCanvas, -newPointsNumber, 0);

          // Draw the grid in the blank space after moving the main canvas image left
          this.context.putImageData(this._bgSnapshot, bgSnapshotX, 0, this._gridOffset, 0, bgSnapshotWidth, this._height);

          // Draw the new data in the correct x coordinate
          this._xCoord = this._width - newPointsNumber;

          // calculate the new grid offset
          this._gridOffset = (this._gridOffset + newPointsNumber) % this._gridBigBlockSize;
        } catch(e) {
        }
    }
});

/*
    ECG grid canvas
*/
bcViewer.ECGGridCanvas = bcViewer.BaseCanvas.extend({
    construct: function() {
        this._super.apply(this, arguments);

        this._smallBlockLineWidth = 0.5;
        this._bigBlockLineWidth = 1;

        // starting from 0.5 to get a real 1 pixel line width
        this._startDrawingFrom = 0.5;
    },

    /*
        Draw the grid
    */
    draw: function() {
        var gridConfig = this._config.ecgGrid;

        this.context.clearRect(0, 0, this._width, this._height);

        var bigBlockGap = gridConfig.SMALL_BLOCKS_PER_BIG_BLOCK * gridConfig.NUM_PIXELS_PER_SMALL_BLOCK;

        this.drawHorizontalLines(this._startDrawingFrom, this._width, gridConfig.NUM_PIXELS_PER_SMALL_BLOCK, this._height,
                                 this._smallBlockLineWidth, gridConfig.SMALL_BLOCK_LINE_COLOR);
        this.drawHorizontalLines(this._startDrawingFrom, this._width, bigBlockGap, this._height,
                                 this._bigBlockLineWidth,  gridConfig.BIG_BLOCK_LINE_COLOR);

        this.drawVerticalLines(this._startDrawingFrom, this._height, gridConfig.NUM_PIXELS_PER_SMALL_BLOCK,
                               this._width, this._smallBlockLineWidth, gridConfig.SMALL_BLOCK_LINE_COLOR);
        this.drawVerticalLines(this._startDrawingFrom, this._height, bigBlockGap, this._width,
                               this._bigBlockLineWidth, gridConfig.BIG_BLOCK_LINE_COLOR);

    },

    /*
        Draw the grid horizontal lines
    */
    drawHorizontalLines: function(from, to, gap, height, lineWidth, lineStyle) {
        this.context.strokeStyle = lineStyle;
        this.context.lineWidth = lineWidth;
        this.context.beginPath();
        var xPos = from;
        while (xPos <= to) {
            this.context.moveTo(xPos, 0);
            this.context.lineTo(xPos, height);
            xPos += gap;
        }
        this.context.stroke();
    },

    /*
        Draw the grid vertical lines
    */
    drawVerticalLines: function(from, to, gap, width, lineWidth, lineStyle) {
        this.context.strokeStyle = lineStyle;
        this.context.lineWidth = lineWidth;
        this.context.beginPath();
        var yPos = from;
        while (yPos <= to) {
            this.context.moveTo(0, yPos);
            this.context.lineTo(width, yPos);
            yPos += gap;
        }
        this.context.stroke();
    }
});

/*
    The timeline canvas
*/
bcViewer.TimelineCanvas = bcViewer.BaseCanvas.extend({
    construct: function() {
        this._super.apply(this, arguments);

        this._tickHeight = 5;
        this._majorTickHeight = 7;
    },

    /*
        Draws the timeline ticks according to the timelineObj
    */
    drawTimeline: function(timelineObj) {
        var tickTime = new Date();
        var startDrawingAt = timelineObj.startDrawingAt;
        this.clear();
        this._setStyle();
        this.context.beginPath();
        var tickNum = 0;
        while (startDrawingAt < this._width) {
            tickTime.setTime(1000*(timelineObj.startTime + startDrawingAt*timelineObj.onePixelDuration));
            var drawMajorTick = ((tickNum % timelineObj.numOfSegmentsPerBlock) === 0);
            var height = drawMajorTick ? this._majorTickHeight : this._tickHeight;
            this._drawTick(startDrawingAt, height);
            if (drawMajorTick && (startDrawingAt > 0)) {
                this._drawTick(startDrawingAt+1, height);
                // Don't add caption in case that we don't have any start time
                if (timelineObj.startTime !== 0) {
                    // TODO: handle cases of short records (time < 60 seconds)
                    // TODO: deal with text that draws outside of the canvas screen bounds
                    this.drawLabel(this._getPrintTime(tickTime), startDrawingAt, 10);
                }
            }
            startDrawingAt += timelineObj.segmentWidth;
            tickNum++;
        }
        this.context.stroke();
    },

    /*
        Draws a timeline tick at x position
    */
    _drawTick: function(x, height) {
        this.context.moveTo(x, this._height);
        this.context.lineTo(x, this._height - height);
    },

    /*
        Return time in format HH:MM - we need to fix this function to deal with seconds as well
        TEMP function we need to decide how to handle time printing in the timeline and move this to Utils
    */
    _getPrintTime: function(date) {
        var hours   = date.getHours();
        var minutes = date.getMinutes();

        if (hours < 10)   hours = '0' + hours;
        if (minutes < 10) minutes = '0' + minutes;

        return hours + ':' + minutes;
    },

    /*
        Set canvas style
    */
    _setStyle: function() {
        this.context.strokeStyle = '#000000';
        this.context.font = '10px Arial';
        this.context.fillStyle = '#222';
        this.context.textAlign = 'center';
    }
});

/*
    The hr canvas
*/
bcViewer.HRCanvas = bcViewer.BaseCanvas.extend({
    draw: function(data) {
        this.clear();
        for (var i=0; i<data.length; i++) {
            var series = data[i].series;
            if (!series.length) continue;

            var seriesColor = this._getColor(data[i].color, this._config.DEFAULT_EVENT_GRAPH_COLOR);
            this.context.strokeStyle = seriesColor;
            this.context.beginPath();

            // Move to the first data point
            var pos = this._getPositionFromPoint(series[0]);
            this.context.moveTo(pos.x, this._height - pos.y);

            for (j=1; j<series.length; j++){
                pos = this._getPositionFromPoint(series[j]);
                this.context.lineTo(pos.x, this._height - pos.y);
            }
            this.context.stroke();
        }
    }
});

/*
    The event canvas
*/
bcViewer.EventCanvas = bcViewer.BaseCanvas.extend({
    construct: function() {
        this._super.apply(this, arguments);
        this._midHeight = Math.ceil(this._height / 2);
    },

    /*
        Draw the event lines
    */
    draw: function(data) {
        this.clear();
        for (var i=0; i<data.length; i++) {
            this.context.beginPath();
            this.context.strokeStyle = '#' + data[i][1];
            // State |-|
            if ($.isArray(data[i][0])) {
                var pos = this._getPositionFromPoint(data[i][0]);
                this.context.moveTo(pos.x, 0);
                this.context.lineTo(pos.x, this._height);
                this.context.moveTo(pos.x, this._midHeight);
                this.context.lineTo(pos.y, this._midHeight);
                this.context.moveTo(pos.y, 0);
                this.context.lineTo(pos.y, this._height);
            } else {
            // Event |
                var x = data[i][0];
                this.context.moveTo(x, 0);
                this.context.lineTo(x, this._height);
            }
            this.context.stroke();
        }
    }
});

/***********************************
    Canvas utility class
***********************************/

/*
    ECG Draw utility
*/
bcViewer.DrawUtility = bcViewer.Class.extend({
    construct: function(targetCanvas) {
        // The canvas we draw on
        this._targetCanvas = targetCanvas;
        this._targetContext = this._targetCanvas.context;

        // Source canvas copy
        this._cacheCanvas;
        this._cacheContext;

        // Save last measurement data
        this._lastBB;
        this._lastMeasuringTextDim;

        // Measureament style configuration
        this._measurementConfig = {
            'boundingBox': {
                    'color': '#000',
                    'width': '1'
                },
            'text': {
                    'color': '#000',
                    'font': 'Bold Italic 12px Arial',
                    'height': 16, // Can't get the text height, that number just looks good
                    'padding': 5, // Add padding so the text won't stick to the canvas edge
                    'bbPadding': 5 // Add padding from the bounding box
                },
            'predictive': {
                    'color': '#FF0000'
                }
        };

        this._predictiveMeasurementX = [];
    },

    /*
        Init the utility, create new cache canvas if dimensions changed
    */
    init: function() {
      try {
        var targetCanvasDimensions = this._targetCanvas.getDimensions();

        // Create new cache canvas if needed
        if ((typeof this._cacheCanvas === 'undefined') ||
            (targetCanvasDimensions.width !== this._cacheCanvas.width) ||
            (targetCanvasDimensions.height !== this._cacheCanvas.height)) {
                delete this._cacheCanvas;
                this._cacheCanvas = document.createElement('canvas');
                this._cacheContext = this._cacheCanvas.getContext('2d');

                this._cacheCanvas.width = targetCanvasDimensions.width;
                this._cacheCanvas.height = targetCanvasDimensions.height;
        }

        // Copy the target canvas to the cache canvas
        this._cacheContext.clearRect(0, 0, this._cacheCanvas.width, this._cacheCanvas.height);
        this._cacheContext.drawImage(this._targetCanvas.canvas, 0, 0);

        // Delete last measurement data
        delete this._lastBB;
        delete this._lastMeasuringTextDim;
      } catch(e) {
      }
    },

    /*
        Draw measurement on the target canvas
    */
    drawMeasurements: function(boundingBox, resultText) {
        this.clearMeasurements();

        var measureBB = this._getMeasuringBoundingBox(boundingBox);
        this._drawBoundingBox(measureBB);

        this._targetCanvas._config.ecgViewer.MEASUREMENT.DISPLAY_PREDICTIVE_MEASUREMENT && this._drawPredictiveMeasurement(measureBB);

        this._drawResultText(boundingBox, resultText);

        // Expand the bounding box for 1 px to each side and include the result text
        this._adjustBoundingBox(measureBB);

        // Save the latest bounding box
        this._lastBB = measureBB;
    },

    /*
        Clear last measurement from the target canvas
    */
    clearMeasurements: function() {
        try {
          if (typeof this._lastBB === 'undefined') return;

          this._targetContext.clearRect(this._lastBB.x, this._lastBB.y, this._lastBB.width, this._lastBB.height);

          if ((this._lastBB.height !== 0) && (this._lastBB.width !== 0)) {
              this._targetContext.drawImage(this._cacheCanvas, 0, 0);
              /* this._targetContext.drawImage(this._cacheCanvas, this._lastBB.x, this._lastBB.y,
                                            this._lastBB.width, this._lastBB.height,
                                            this._lastBB.x, this._lastBB.y,
                                            this._lastBB.width, this._lastBB.height); */
          }

          if (this._predictiveMeasurementX[0]) {
              var predictiveHeight = this._targetCanvas.getDimensions().height;

              this._targetContext.drawImage(this._cacheCanvas, this._predictiveMeasurementX[0]-1, 0,
                                            2, predictiveHeight,
                                            this._predictiveMeasurementX[0]-1, 0,
                                            2, predictiveHeight);
              this._targetContext.drawImage(this._cacheCanvas, this._predictiveMeasurementX[1]-1, 0,
                                            2, predictiveHeight,
                                            this._predictiveMeasurementX[1]-1, 0,
                                            2, predictiveHeight);


              this._predictiveMeasurementX = [];
          }
        } catch(e) {
        }
    },

    /*
        Draw the bounding box
    */
    _drawBoundingBox: function(boundingBox) {
        this._targetContext.beginPath();
        this._targetContext.strokeStyle = this._measurementConfig.boundingBox.color;
        this._targetContext.lineWidth = this._measurementConfig.boundingBox.width;
        this._targetContext.rect(boundingBox.x, boundingBox.y, boundingBox.width, boundingBox.height);

        this._targetContext.stroke();
    },

    /*
        Draw predictive measurement
    */
    _drawPredictiveMeasurement: function(boundingBox) {
        var firstLineX = boundingBox.x + (2 * boundingBox.width);
        var secondLineX = boundingBox.x + (3 * boundingBox.width);
        var targetCanvasDimensions = this._targetCanvas.getDimensions();

        this._predictiveMeasurementX[0] = firstLineX;
        this._predictiveMeasurementX[1] = secondLineX;

        this._targetContext.beginPath();
        this._targetContext.strokeStyle = this._measurementConfig.predictive.color;
        this._targetContext.lineWidth = 1;
        this._targetContext.moveTo(firstLineX, 0);
        this._targetContext.lineTo(firstLineX, targetCanvasDimensions.height);
        this._targetContext.moveTo(secondLineX, 0);
        this._targetContext.lineTo(secondLineX, targetCanvasDimensions.height);
        this._targetContext.stroke();
    },

    /*
        Draw the result text
    */
    _drawResultText: function(boundingBox, resultText) {
        this._targetContext.font = this._measurementConfig.text.font;
        this._targetContext.fillStyle = this._measurementConfig.text.color;

        var textWidth = this._targetCanvas.getTextWidth(resultText);
        var textHeight = this._measurementConfig.text.height;
        var textPadding = this._measurementConfig.text.padding;
        var bbPadding = this._measurementConfig.text.bbPadding;
        var targetCanvasDimensions = this._targetCanvas.getDimensions();

        var x = boundingBox.startX;
        var y = boundingBox.startY - bbPadding;

        // chnage text position according to the measuring orientation
        if (boundingBox.startY > boundingBox.endY) {
            y += (textHeight + bbPadding*2);
        }

        if (boundingBox.startX >= boundingBox.endX) {
            x = Math.max(x - textWidth, textPadding);
        }

        // Keep the text in the canvas bounds
        if (y < (textHeight + textPadding)) {
            y += (textHeight + textPadding + bbPadding);
        }

        if (y > (targetCanvasDimensions.height - textPadding)) {
            y -= (textHeight + textPadding + bbPadding);
        }

        if (x + textWidth > targetCanvasDimensions.width) {
            x -= ((x + textWidth) - targetCanvasDimensions.width) + textPadding;
        }

        this._targetContext.fillText(resultText, x, y);

        this._lastMeasuringTextDim = {
                        'x': x,
                        'y': y,
                        'width': textWidth,
                        'height': textHeight + this._measurementConfig.text.bbPadding
                };
    },

    /*
        Change bounding box coordinates and dimensions to include the text
    */
    _adjustBoundingBox: function(boundingBox) {
        if (typeof boundingBox === 'undefined') return;

        var textPadding = this._measurementConfig.text.padding;

        // Change the bounding box to include the previous text
        if (this._lastMeasuringTextDim) {
            var xDiff = 1;
            if (this._lastMeasuringTextDim.x < boundingBox.x) {
                xDiff = boundingBox.x - this._lastMeasuringTextDim.x +1;
            }

            boundingBox.x -= xDiff;

            boundingBox.y -= this._lastMeasuringTextDim.height;

            if (this._lastMeasuringTextDim.width - boundingBox.width > 2) {
                boundingBox.width += (this._lastMeasuringTextDim.width - boundingBox.width) + this._lastMeasuringTextDim.height;
            } else {
                boundingBox.width += 2;
            }
            boundingBox.width += xDiff*2;

            boundingBox.height += this._lastMeasuringTextDim.height*2 + textPadding;
        } else {
            boundingBox.x -= 1;
            boundingBox.y -= 1;
            boundingBox.width += 2;
            boundingBox.height += 2;
        }

        // Keep the bounding box in the canvas dimensions
        var canvasDimensions = this._targetCanvas.getDimensions();
        boundingBox.x = Math.max(0, boundingBox.x);
        boundingBox.y = Math.max(0, boundingBox.y);
        var xGap = canvasDimensions.width - boundingBox.x;
        boundingBox.width = Math.min(xGap, boundingBox.width);
        var yGap = canvasDimensions.height - boundingBox.y;
        boundingBox.height = Math.min(yGap, boundingBox.height);
    },

    /*
         Return the measuring bounding box dimentions
    */
    _getMeasuringBoundingBox: function(boundingBox) {
        var minX = Math.min(boundingBox.startX, boundingBox.endX);
        var minY = Math.min(boundingBox.startY, boundingBox.endY);
        var maxX = Math.max(boundingBox.startX, boundingBox.endX);
        var maxY = Math.max(boundingBox.startY, boundingBox.endY);
        return {
            'x': minX,
            'y': minY,
            'width': maxX - minX,
            'height': maxY - minY
        }
    }

});

bcViewer.UserMarkDrawUtility = bcViewer.Class.extend({
  construct: function(targetCanvas) {
      // The canvas we draw on
      this._targetCanvas = targetCanvas;
      this._targetContext = this._targetCanvas.context;
      // Source canvas copy
      this._cacheCanvas;
      this._cacheContext;
  },

  init: function() {
    try {
      var targetCanvasDimensions = this._targetCanvas.getDimensions();
      // Create new cache canvas if needed
      if ((typeof this._cacheCanvas === 'undefined') ||
          (targetCanvasDimensions.width !== this._cacheCanvas.width) ||
          (targetCanvasDimensions.height !== this._cacheCanvas.height)) {
              delete this._cacheCanvas;
              this._cacheCanvas = document.createElement('canvas');
              this._cacheContext = this._cacheCanvas.getContext('2d');
              this._cacheCanvas.width = targetCanvasDimensions.width;
              this._cacheCanvas.height = targetCanvasDimensions.height;
      }
      // Copy the target canvas to the cache canvas
      this._cacheContext.clearRect(0, 0, this._cacheCanvas.width, this._cacheCanvas.height);
      this._cacheContext.drawImage(this._targetCanvas.canvas, 0, 0);
    } catch(e) {
    }
  },

  drawUserMarkPlacer: function(mark) {
      this.clearUserMarkPlacer();
      this._targetCanvas._drawUserMark(mark);
  },

  clearUserMarkPlacer: function() {
    this._targetContext.drawImage(this._cacheCanvas, 0, 0);
  }
});

}

import React, { useState } from 'react'
import { gql, useMutation } from '@apollo/client'
import clsx from 'clsx'
import { v4 as uuidv4 } from 'uuid'
import Modal from './Modal'
import { isAuthorizedAction } from '../../utils/utils'
import { useLocalization } from './LocalizationProvider'

const CLINIC_SHARE_AUTO_CREATE = gql`
  mutation CLINIC_SHARE_AUTO_CREATE(
    $sourceClinic: ClinicConnectionInput!
    $sourceCaregiver: CaregiverConnectionInput!
    $email: AWSEmail!
    $input: ClinicShareAutoCreateInput!
  ) {
    clinicShareAutoCreate(
      sourceClinic: $sourceClinic
      sourceCaregiver: $sourceCaregiver
      email: $email
      input: $input
    ) {
      id
    }
  }
`

export default function CreateAutoShare({
  sharingUser,
  userAuthorizedActions,
  onComplete,
  onExit,
  GET_SHARES_AUTO
}) {
  const { locale } = useLocalization()

  const [clinicShareAutoCreate, { loading: inProgress, error }] = useMutation(
    CLINIC_SHARE_AUTO_CREATE,
    {
      refetchQueries: [
        {
          query: GET_SHARES_AUTO,
          variables: {
            id: sharingUser.id,
            isAuthorizedSourceSharesAuto: isAuthorizedAction(
              userAuthorizedActions,
              'Clinic:sourceSharesAuto'
            ),
            isAuthorizedTargetSharesAuto: isAuthorizedAction(
              userAuthorizedActions,
              'Clinic:targetSharesAuto'
            )
          }
        }
      ],
      awaitRefetchQueries: true
    }
  )
  const [email, setEmail] = useState('')
  async function send() {
    const createdAt = new Date().toISOString()
    try {
      await clinicShareAutoCreate({
        variables: {
          sourceClinic: {
            id: sharingUser.clinic.id,
            name: sharingUser.clinic.name
          },
          sourceCaregiver: {
            id: sharingUser.id,
            name: sharingUser.name,
            email: sharingUser.email
          },
          email,
          input: {
            id: uuidv4(),
            createdAt
          }
        }
      })
      onComplete()
    } catch {
      void 0
    }
  }
  return (
    <Modal onClose={onExit} onSubmit={async () => send()}>
      <div className='field'>
        <label className='label is-medium'>{locale.share.share}</label>
      </div>
      <hr />
      <div className='field'>
        <label className='label'>{locale.sharing.email}</label>
        <div className='control'>
          <input
            type='text'
            className={clsx('input', {
              'is-danger': error
            })}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            readOnly={inProgress}
          />
        </div>
        <p
          className={clsx('help is-danger', {
            'is-hidden': !error
          })}
        >
          {error
            ? error.message === 'ShareExists'
              ? locale.sharing.errors.share_connection_already_exists
              : error.message === 'ForbiddenSelfShare'
              ? locale.sharing.errors.sharing_with_myself
              : error.message.startsWith(
                  "Variable 'email' has an invalid value"
                )
              ? locale.sharing.errors.invalid_email
              : locale.something_wrong
            : null}
        </p>
      </div>
      <hr />
      <div className='field'>
        <div className='level'>
          <div className='level-left' />
          <div className='level-right'>
            <button
              className={clsx('button is-primary level-item', {
                'is-loading': inProgress
              })}
              disabled={!email || inProgress}
              onClick={async () => send()}
            >
              {locale.sharing.add_share}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

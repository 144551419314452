import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'

const Loader = () => {
  return (
    <div
      className='is-flex is-justify-content-center is-align-items-center'
      style={{ height: '100%' }}
    >
      <FontAwesomeIcon icon={faCircleNotch} spin size='2x' />
    </div>
  )
}

export default Loader

if (typeof window !== 'undefined') {

/*
    Chart implementation
*/
var SpiroChart = function() {
    this._wrapperSelector;
    this._chart;
    this._chartID;
    this._chartSelector;
    this._config = {};
    this._xAxisConfig = {};
    this._yAxisConfig = [];
    this._data = [];
    this._xScale;
    this._xAxis;
    this._yScales = [];
    this._xAxis = [];
    this._lineGens = [];

    this.COLORS = {
        CATEGORY10: d3.scale.category10().domain(d3.range(0,20)),
        CATEGORY20: d3.scale.category20().domain(d3.range(0,20)),
        CATEGORY20b: d3.scale.category20b().domain(d3.range(0,20)),
        CATEGORY20c: d3.scale.category20c().domain(d3.range(0,20)),
        MONO: function() { return '#aaa'; }
    };

    /*
        Show the chart
    */
    this.showChart = function(wrapperID, config, xAxisConfig, yAxisConfig, data) {
        if (typeof d3 === 'undefined') return; // throw exception?
        $.extend(this._config, config);
        $.extend(this._xAxisConfig, xAxisConfig);
        this._data = [];
        
        for (var i=0; i< data.length; i++) {
            this._data[i] = {};
            this._data[i].yAxisIndex = data[i].yAxisIndex;
            this._data[i].title = data[i].title;
            this._data[i].data = data[i].data.slice();

            this._data[i].data = $.map(this._data[i].data, function(d) {
                return $.extend(d, {lineIndex: i});
            });
        }
        
        this._yAxisConfig = $.map(yAxisConfig, function(conf) {
            return $.extend({max:Number.MIN_VALUE}, conf);
        });

        for (var i=0; i<data.length; i++) {
            this._yAxisConfig[data[i].yAxisIndex].max = Math.max(this._yAxisConfig[data[i].yAxisIndex].max, Math.max.apply(Math,  $.map(this._data[i].data, function(d) { return d.y; })));
        }
       
        this._config.margins = {
            top: 20,
            right: 20,
            bottom: 20,
            left: (this._yAxisConfig.length === 1) ? 50 : 60
        }; 

        this._config.chartHeight = (xAxisConfig.dateAxis) ? (this._config.height - 70) : (this._config.height - 20);
        this._config.chartWidth =  this._config.width;

        this._xAxisConfig.min = Number.MAX_VALUE;
        this._xAxisConfig.max = Number.MIN_VALUE;
        for (var i=0; i<data.length; i++) {
            this._xAxisConfig.min = Math.min(this._xAxisConfig.min, Math.min.apply(Math, $.map(this._data[i].data, function(d) { return d.x; })));
            this._xAxisConfig.max = Math.max(this._xAxisConfig.max, Math.max.apply(Math, $.map(this._data[i].data, function(d) { return d.x; })));
        }

        this._chartID = wrapperID + 'Chart';
        this._chartSelector = '#' + this._chartID;
        this._colors = this.COLORS[config.colors];        
        this._wrapperSelector = '#' + wrapperID;

        if (this._config.legend && this._config.legend.show) {
            this._createLegend();
        }

        d3.select(this._wrapperSelector).append('svg').attr('width', this._config.width).attr('height', this._config.height).attr('id', this._chartID);
        this._chart = d3.select(this._chartSelector);
                     
        this._createChartScales(); 
        this._addXAxis(); 
        this._addYAxis(); 
        this._createLineGens();
        this._addDataLines();

        if (this._config.showDataPoints) {
            this._addDataCircles();
        }

        var tooltip = d3.select("body").append("div")   
                .attr("id", this._chartID + "Tooltip")               
                .attr("class", "spiroChartTooltip")               
                .style("opacity", 0);

    };  
        
    /*
        Create the chart scales
    */
    this._createChartScales = function() {
        var config = this._config;
        var xAxisConfig = this._xAxisConfig;
        var yAxisConfig = this._yAxisConfig;
        
        this._xScale = d3.scale.linear().range([config.margins.left, config.chartWidth - config.margins.right]).domain([xAxisConfig.min, Math.ceil(xAxisConfig.max)+1]);

        this._yScales = [];
        for (var i=0; i<yAxisConfig.length; i++) {
            this._yScales[i] = d3.scale.linear()
                .range([config.chartHeight - config.margins.top, config.margins.bottom])
                .domain([0,Math.ceil(yAxisConfig[i].max)+1]);
        }
    };

    /*
        Add X Axis
    */
    this._addXAxis = function() {
        var config = this._config;
        var xAxisConfig = this._xAxisConfig;

        this._xAxis = d3.svg.axis()
        .scale(this._xScale)
        .innerTickSize(-this._config.chartHeight + this._config.margins.top + this._config.margins.bottom)
        .outerTickSize(0)
        .tickPadding(10);

        if (xAxisConfig.showExactTicks) {
            this._xAxis.tickValues($.map(this._data[0].data, function(d) { return d.x; }));
        }

        if (xAxisConfig.dateAxis) {
            this._xAxis.tickFormat(function(d) {
                return d3.time.format('%d-%m-%Y %H:%M')(new Date(d));                          
            });
        }

        this._chart.append("svg:g")
              .attr("class", "x axis")
              .attr("transform", "translate(0," + (config.chartHeight - config.margins.bottom) + ")")
              .call(this._xAxis);
        
        if (xAxisConfig.dateAxis) {
            d3.selectAll(this._chartSelector + ' .x.axis text')
                .style("text-anchor", "end")
                .attr("transform", function(d) {
                    return "rotate(-45)" 
                });
        
        }

        if (typeof xAxisConfig.title !== 'undefined') {
            this._chart.append("text")
                  .attr("x", config.width/2 )
                  .attr("y", config.chartHeight + 20 )
                  .style("text-anchor", "middle")
                  .text(xAxisConfig.title);
        }
    };

    /*
        Add Y Axis
    */
    this._addYAxis = function() {
        var config = this._config;
        var yAxisConfig = this._yAxisConfig;

        this._yAxis = [];
        for (var i=0; i<yAxisConfig.length; i++) {
            this._yAxis[i] = d3.svg.axis()
                      .scale(this._yScales[i])
                      .orient("left")
                      .innerTickSize(-this._config.chartWidth + this._config.margins.right + this._config.margins.left)
                      .outerTickSize(0)
                      .tickPadding(10);
        }
        
        for (var i=0; i<yAxisConfig.length; i++) {
            this._chart.append("svg:g")
                  .attr("class", "y axis")
                  .attr("id", "yAxis_"+i)
                  .attr("transform", "translate(" + (config.margins.left) + ",0)")
                  .call(this._yAxis[i]);
        }

        if (yAxisConfig.length > 1) {
            d3.selectAll(this._chartSelector + ' .y.axis text')
                    .style('visibility', 'hidden');
        } else {
            this._chart.append("text")
                  .attr("transform", "rotate(-90)")
                  .attr("y", 0)
                  .attr("x",-config.chartHeight/2)
                  .attr("dy", "1em")
                  .style("text-anchor", "middle")
                  .text(yAxisConfig[0].title);
        }
    };

    /*
        Create the lines generators
    */
    this._createLineGens = function() {
        var xAxisConfig = this._xAxisConfig;
        var yAxisConfig = this._yAxisConfig;
        var xScale = this._xScale;
        var yScales = this._yScales;
        var data = this._data;

        this._lineGens = [];
        for (var i=0; i<data.length; i++) {
            this._lineGens[i] = d3.svg.line()
                    .x(function(d) {
                        var data = d.x;
                        if (xAxisConfig.dateAxis) {
                            data = new Date(d.x);
                        }

                        return xScale(data);
                    })
                    .y(function(d) {
                        return yScales[data[d.lineIndex].yAxisIndex](d.y);
                    })
                    //.interpolate("basis");
        }
    };

    /*
        Add lines to the chart
    */
    this._addDataLines = function() {
        var xAxisConfig = this._xAxisConfig;
        var yAxisConfig = this._yAxisConfig;
        var config = this._config;
        var chart = this;

        for (var i=0; i<this._data.length; i++) {
            this._chart.append('svg:path')
                  .attr('d', this._lineGens[i](this._data[i].data))
                  .attr('stroke', this._colors(i))
                  .attr('stroke-width', 2)
                  .attr('fill', 'none')
                  .attr('class', 'line')
                  .attr('cursor', 'pointer')
                  .attr('data-line-index', i)
                  .attr("class", 'line_' + i)     
                  .on("mouseover", function() {
                    var line = d3.select(this);
                    var index = line.attr('data-line-index');
                   // chart.toggleLineFocus(index, true);
                    config.onMouseOverLineCallback && config.onMouseOverLineCallback(index);
                  })
                  .on("mouseout", function() {
                    var line = d3.select(this);
                    var index = line.attr('data-line-index');
                  //  chart.toggleLineFocus(index, false);
                    config.onMouseOutLineCallback && config.onMouseOutLineCallback(index);
                  })
                 .on("click", function() {
                    var line = d3.select(this);
                 });
        }
    };

    /*
        Add Circles to the chart
    */
    this._addDataCircles = function() {
        var xAxisConfig = this._xAxisConfig;
        var yAxisConfig = this._yAxisConfig;
        var chart = this;
        
        for (var i=0; i<this._data.length; i++) {
            this._chart
             .selectAll("circle.line")
             .data(this._data[i].data)
             .enter().append("svg:circle")
             .attr("fill", "#fff")
             .attr('stroke', this._colors(i))
             .attr('stroke-width', 2)
             .attr('cursor', 'pointer')
             .attr("r", 3.5)
             .attr("cx", this._lineGens[i].x())
             .attr("cy", this._lineGens[i].y())
             .attr("class", 'circle_' + i)
             .on("mouseover", function(d) {
                var circle = d3.select(this);
                var color = circle.attr('stroke');
                circle.attr('fill', color);
                chart.toggleLineFocus(d.lineIndex, true);

                var tooltip = d3.select('#' + chart._chartID + 'Tooltip');
                tooltip.transition()        
                    .duration(200)      
                    .style("opacity", 1);
                var xVal = (xAxisConfig.dateAxis) ? Spirometer._formatDateTime(d.x, 'dd.mm.yyyy HH:MM:SS') : d.x;
                tooltip
                    .html("<span>" + 
                          yAxisConfig[d.lineIndex].title +
                          ': '  + 
                          d.y + 
                          "<br/><br/>"  +
                          xVal +
                          "</span>") 
                    .style("left", (d3.event.pageX - 83) + "px")     
                    .style("top", (d3.event.pageY - 75) + "px");    
              })
             .on("mouseout", function(d) {  
                d3.select(this).attr('fill', '#fff');
                chart.toggleLineFocus(d.lineIndex, false);
                
                var tooltip = d3.select('#' + chart._chartID + 'Tooltip');
                tooltip.transition()        
                    .duration(500)      
                    .style("opacity", 0)
                    .each("end", function() { 
                        d3.select(this)
                        .style("left", "-100px")     
                        .style("top", "-100px");   
                    });
             });
        }
    };

    /*
        Toggle the line focus
    */
    this.toggleLineFocus = function(lineIndex, isFocused, color, shouldBold) {
        var yAxisConfig = this._yAxisConfig;
        var axisText = d3.selectAll(this._chartSelector +' #yAxis_' + lineIndex + ' text');
        var line = d3.select(this._chartSelector + ' .line_' + lineIndex);
        var circles = d3.selectAll(this._chartSelector + ' .circle_' + lineIndex);
        
        var _shouldBold = (typeof shouldBold !== 'undefined') ? shouldBold : true;
        if (line.style('visibility') === 'hidden') return;    

        if (isFocused) {
            this._moveGraphElementToFront(line);
            this._moveGraphElementToFront(circles);

            if (yAxisConfig.length > 1) {
                axisText.style('visibility', 'visible');   
            }
        
            var strokeWidth = _shouldBold ? 4 : 2; 
            line.attr('stroke-width', strokeWidth);
            circles.attr('stroke-width', strokeWidth);
            
            if (typeof color !== 'undefined') {
                line.attr('stroke', color);
            } else {
                line.attr('stroke', this._colors(lineIndex));
            }
        } else {
            if (yAxisConfig.length > 1) {
                axisText.style('visibility', 'hidden');   
            }
            line.attr('stroke-width', 2);
            circles.attr('stroke-width', 2);
            
            if (typeof color !== 'undefined') {
                line.attr('stroke', color);
            } else {
                line.attr('stroke', this._colors(lineIndex));
            }
        }
    };

    /*
        Toggle the line visibility
    */
    this.toggleLineVisibility = function(lineIndex, shouldShow) {
        var yAxisConfig = this._yAxisConfig;
        var line = d3.select(this._chartSelector + ' .line_' + lineIndex);
        var circles = d3.selectAll(this._chartSelector + ' .circle_' + lineIndex);
        var axisText = d3.selectAll(this._chartSelector +' #yAxis_' + lineIndex + ' text');
        
        var shouldShowVal = (typeof shouldShow !== 'undefined') ? shouldShow : (line.style('visibility') === 'hidden');

        if (shouldShowVal) {    
            line.style('visibility','visible');
            circles.style('visibility','visible');
           
            if (yAxisConfig.length > 1) {
                axisText.style('visibility', 'visible');   
            }
        } else {
            line.style('visibility','hidden');
            circles.style('visibility','hidden');
            
            if (yAxisConfig.length > 1) {
                axisText.style('visibility', 'hidden');   
            }
        }
    };

    /*
        Move graph element to the front
    */
    this._moveGraphElementToFront = function(elem) {  
        return elem.each(function(){
            this.parentNode.appendChild(this);
        });
    };

    /*
        Create the chart legend
    */
    this._createLegend = function() {
        var chart = this;
        var data = this._data;

        var legendList = $('<ul></ul>');
        legendList.addClass('trendLegend');
        $.each(data, $.proxy(function(i, line) {
            var legendItem = $('<li></li>');
            legendItem.attr('data-index', i);
            var checkbox = $('<input type="checkbox" checked="true"></input>');
            checkbox.attr('id', 'legendCB_' + i);
            
            checkbox.on('change', function() {

            });

            legendItem.click(function(e) {
                var index = $(this).attr('data-index');
                var checkbox = $('#legendCB_' + index);
                var isChecked = checkbox.is(':checked');

                chart.toggleLineVisibility(index);


                if (e.target.type !== 'checkbox') {
                    if (isChecked) {
                        checkbox.removeProp('checked');
                    } else {
                        checkbox.prop('checked', 'true');
                    }
                }
            });

            legendItem.append(checkbox);
            legendItem.append(line.title);
            legendItem.css('color', chart._colors(i));

            legendItem.hover(function() {
                var index = $(this).attr('data-index');
                chart.toggleLineFocus(index, true);
            },
            function() {
                var index = $(this).attr('data-index');
                chart.toggleLineFocus(index, false);
            });

            legendList.append(legendItem);
        }, this));
        var selector = (typeof this._config.legend.selector !== 'undefined') ? this._config.legend.selector : this._wrapperSelector;
        $(selector).append(legendList);
    };
    
    this.hideEveryXTick = function(x) {
        var ticks = d3.selectAll(this._chartSelector + ' .tick text');
        ticks.attr('class', function(d, i) {
            if (i%x != 0) d3.select(this).remove();
        });
    };
}

window.SpiroChart = SpiroChart;

}
import { useState, useEffect } from 'react'

const LOADED_CHUNKS = {}

export default (stylesheets, webpackChunkName, keepCssPermanent = false) => {
  const [isCSSLoaded, setIsCSSLoaded] = useState(
    keepCssPermanent ? LOADED_CHUNKS[webpackChunkName] || false : false
  )
  useEffect(() => {
    async function load() {
      for (const fn of stylesheets) {
        await fn()
      }
      setIsCSSLoaded(true)
      if (keepCssPermanent) {
        LOADED_CHUNKS[webpackChunkName] = true
      }
    }
    if (keepCssPermanent) {
      load()
    } else {
      const element = document.head.querySelector(
        `link[rel=stylesheet][href*="/${webpackChunkName}."][href$=".css"]`
      )
      if (element) {
        element.removeAttribute('disabled')
        setTimeout(() => {
          setIsCSSLoaded(true)
        }, 500) // for some reason, without the timeout, second visit on study page for example shows empty canvas - maybe the css rules doesn't affect right away?
      } else {
        load()
      }
    }
    return () => {
      if (keepCssPermanent) return
      const element = document.head.querySelector(
        `link[rel=stylesheet][href*="/${webpackChunkName}."][href$=".css"]`
      )
      if (element) {
        element.setAttribute('disabled', 'disabled')
        setIsCSSLoaded(false)
      }
    }
  }, [])
  return isCSSLoaded
}

import React from 'react'
import clsx from 'clsx'

const Notification = ({ notification, onClose }) => (
  <div
    style={{
      display: notification ? 'initial' : 'none',
      position: 'fixed',
      top: 0,
      width: '100%',
      left: '50%',
      transform: 'translateX(-50%)',
      // transition: 'all .5s ease-in-out',
      zIndex: '100'
    }}
  >
    <div
      className={clsx(
        'is-flex is-justify-content-center notification is-light',
        {
          'is-danger': notification && notification.isError,
          'is-success': notification && notification.isSuccess
        }
      )}
    >
      <button className='delete' onClick={() => onClose && onClose()} />
      {notification ? notification.message : ''}
    </div>
  </div>
)

export default Notification

import React from 'react'
import { ForgotPassword as OriginalForgotPassword } from 'aws-amplify-react'
import { Hub } from '@aws-amplify/core'
import ForgotPasswordSendCore from './ForgotPasswordSendCore'
import ForgotPasswordSubmitCore from './ForgotPasswordSubmitCore'
import { hashPassword } from '../../../utils/utils'

export class ForgotPassword extends OriginalForgotPassword {
  constructor(props) {
    super(props)
    this._validAuthStates = ['forgotPassword']
    this.onHubCapsule = this.onHubCapsule.bind(this)
    this.error = this.error.bind(this)
    this.state = {
      error: null,
      inProgressSend: false,
      inProgressSubmit: false,
      originalPassword: null
    }
  }

  componentDidMount() {
    Hub.listen('auth', this.onHubCapsule)
  }

  componentWillUnmount() {
    Hub.remove('auth', this.onHubCapsule)
  }

  onHubCapsule(capsule) {
    const { channel, payload } = capsule
    if (channel === 'auth' && payload.event === 'signIn' && payload.data) {
      setTimeout(
        () => this.setState({ inProgressSend: false, inProgressSubmit: false }),
        1000
      )
    }
  }

  error(err) {
    if (this.state.originalPassword) {
      this.inputs.password = this.state.originalPassword
    }
    this.setState({
      error: err.code ? err.code : err.message ? err.message : err,
      inProgressSend: false,
      inProgressSubmit: false,
      originalPassword: null
    })
  }

  showComponent() {
    const { authData = {} } = this.props
    const isSubmitView = this.state.delivery || authData.username
    if (isSubmitView) {
      return (
        <ForgotPasswordSubmitCore
          handleInputChange={(evt) => {
            if (evt.target.value) {
              evt.target.value = evt.target.value.trim()
            }
            return this.handleInputChange(evt)
          }}
          send={(props) => {
            if (this.state.error) {
              this.setState({ error: null })
            }
            this.send(props)
          }}
          submit={() => {
            if (this.state.error) {
              this.setState({ error: null })
            }
            const _originalPassword = this.inputs.password
            this.setState({
              inProgressSubmit: true,
              originalPassword: _originalPassword
            })
            this.inputs.password = hashPassword(
              this.inputs.email,
              this.inputs.password
            )
            window.previousAuthState = 'forgotPassword'
            window.loginDetails = {
              username: this.inputs.email,
              password: _originalPassword
            }
            this.submit()
          }}
          error={this.state.error}
          inProgress={this.state.inProgressSubmit}
        />
      )
    }
    return (
      <ForgotPasswordSendCore
        handleInputChange={(evt) => {
          if (evt.target.value) {
            evt.target.value = evt.target.value.trim()
          }
          return this.handleInputChange(evt)
        }}
        changeState={(props) => {
          if (this.state.error) {
            this.setState({ error: null })
          }
          this.changeState(props)
        }}
        send={() => {
          if (this.state.error) {
            this.setState({ error: null })
          }
          this.setState({ inProgressSend: true })
          this.send()
        }}
        error={this.state.error}
        inProgress={this.state.inProgressSend}
      />
    )
  }
}

export default ForgotPassword

import React, { useState } from 'react'
import Modal from './Modal'
import { gql, useMutation } from '@apollo/client'
import clsx from 'clsx'

const STUDY_COPY = gql`
  mutation STUDY_COPY(
    $studyId: ID!
    $email: AWSEmail!
    $patientName: String
    $isAnonymous: Boolean!
  ) {
    studyCopy(
      studyId: $studyId
      email: $email
      patientName: $patientName
      isAnonymous: $isAnonymous
    ) {
      id
    }
  }
`

export default function CreateStudyCopy({ study, onExit }) {
  const [targetDoctor, setTargetDoctor] = useState('')
  const [patientName, setPatientName] = useState('')
  const [isAnonymous, setIsAnonymous] = useState(true)
  const [copyStudy, { loading: inProgress }] = useMutation(STUDY_COPY)
  const [copyStudyError, setCopyStudyError] = useState(null)
  const [newStudyId, setNewStudyId] = useState(null)

  async function submit() {
    try {
      setNewStudyId(null)
      const result = await copyStudy({
        variables: {
          studyId: study.id,
          email: targetDoctor,
          patientName: patientName,
          isAnonymous: isAnonymous
        }
      })
      setNewStudyId(result.data.studyCopy.id)
    } catch (e) {
      setCopyStudyError(e)
    }
  }

  return (
    <Modal onClose={onExit} onSubmit={submit} inProgress={inProgress}>
      <label className='label is-medium'>Copy study</label>
      <hr />
      <div className='field is-horizontal'>
        <div className='field-label is-normal'>
          <label className='label has-text-left'>Target doctor:</label>
        </div>
        <div className='field-body'>
          <div className='field'>
            <input
              type='text'
              className='input'
              value={targetDoctor}
              onChange={(e) => {
                setTargetDoctor(e.target.value)
                setCopyStudyError(null)
              }}
            />
          </div>
        </div>
      </div>
      <div className='field is-horizontal'>
        <div className='field-label is-normal'>
          <label className='label has-text-left'>Patient name:</label>
        </div>
        <div className='field-body'>
          <div className='field'>
            <input
              type='text'
              className='input'
              value={patientName}
              onChange={(e) => {
                setPatientName(e.target.value)
                setCopyStudyError(null)
              }}
            />
          </div>
        </div>
      </div>
      <div className='field is-horizontal'>
        <div className='field-label is-normal'>
          <label className='label has-text-left'>Anonymous:</label>
        </div>
        <div className='field-body'>
          <div className='field'>
            <input
              type='checkbox'
              checked={isAnonymous}
              onChange={(e) => {
                setIsAnonymous(e.target.checked)
                setCopyStudyError(null)
              }}
            />
          </div>
        </div>
      </div>
      <hr />
      <div className='field'>
        <div className='level'>
          <div className='level-left'>
            {copyStudyError && (
              <div className='has-text-danger'>{copyStudyError.message}</div>
            )}

            {newStudyId && (
              <div className='has-text-success'>
                Study copied! &nbsp;
                <a
                  href={`/study/${newStudyId}`}
                  target='_blank'
                  rel='noreferrer'
                >
                  view
                </a>
              </div>
            )}
          </div>
          <div className='level-right'>
            <button
              className={clsx('button is-primary level-item', {
                'is-loading': inProgress
              })}
              onClick={async () => submit()}
            >
              Copy
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

import React, { useState, useEffect } from 'react'
import { useLazyQuery } from '@apollo/client'
import clsx from 'clsx'
import useDebounce from '../../hooks/useDebounce'
import { GET_USERS_LIST_FROM_USER_INPUT } from '../queries'

const AdminUserSearchInput = ({ onSelect }) => {
  const [userInput, setUserInput] = useState('')
  const debouncedUserInput = useDebounce(userInput, 500)
  const [
    getUsersListFromUserInput,
    { loading: isSearchingUsers, data: usersList }
  ] = useLazyQuery(GET_USERS_LIST_FROM_USER_INPUT, {
    fetchPolicy: 'network-only'
  })
  useEffect(() => {
    if (debouncedUserInput && debouncedUserInput.trim().length > 2) {
      getUsersListFromUserInput({
        variables: { text: debouncedUserInput.trim() }
      })
    }
  }, [debouncedUserInput])

  return (
    <div
      className={clsx('dropdown', {
        'is-active':
          debouncedUserInput &&
          usersList &&
          usersList.admin.userSearch.length > 0
      })}
      style={{ width: '100%' }}
    >
      <div className='dropdown-trigger' style={{ width: '100%' }}>
        <div
          className={clsx('control', {
            'is-loading': isSearchingUsers
          })}
        >
          <input
            type='text'
            className='input'
            value={userInput}
            onChange={(e) => setUserInput(e.target.value)}
            onKeyDown={(e) => {
              if (
                e.keyCode === 13 &&
                debouncedUserInput &&
                usersList &&
                usersList.admin.userSearch.length > 0
              ) {
                setUserInput('')
                onSelect(usersList.admin.userSearch[0])
              }
            }}
          />
        </div>
      </div>
      <div className='dropdown-menu' role='menu' style={{ width: '100%' }}>
        <div
          className='dropdown-content'
          style={{ maxHeight: 270, overflow: 'auto' }}
        >
          {debouncedUserInput &&
            usersList &&
            usersList.admin.userSearch.map((user) => (
              <a
                key={user.id}
                className='dropdown-item'
                onClick={async () => {
                  setUserInput('')
                  onSelect(user)
                }}
              >
                {`${user.name} (${user.email})`}
              </a>
            ))}
        </div>
      </div>
    </div>
  )
}

export default AdminUserSearchInput

import React, { useState } from 'react'
import { gql, useMutation } from '@apollo/client'
import clsx from 'clsx'
import dayjs from 'dayjs'
import { v4 as uuidv4 } from 'uuid'
import { isAuthorizedAction, isRTL } from '../../utils/utils'
import { useLocalization } from './LocalizationProvider'

const ADD_COMMENT_TO_STUDY = gql`
  mutation ADD_COMMENT_TO_STUDY(
    $studyId: ID!
    $caregiver: CaregiverConnectionInput!
    $input: CommentDataInput!
  ) {
    studyCommentCreate(
      input: $input
      studyId: $studyId
      caregiver: $caregiver
    ) {
      id
    }
  }
`

const StudyComments = ({
  comments,
  study,
  studyId,
  currentUser,
  dateFormat
}) => {
  const [text, setText] = useState('')
  const [addCommentToStudy, { loading }] = useMutation(ADD_COMMENT_TO_STUDY, {
    onCompleted: () => setText('')
  })
  const { locale } = useLocalization()

  return (
    <>
      {comments.map((comment) => (
        <article className='media' key={comment.id}>
          <div className='media-content'>
            <div className='content'>
              <span style={{ whiteSpace: 'pre-wrap' }}>{comment.text}</span>
              <p className='mt-5  has-text-right is-size-6'>
                <span className='has-text-weight-bold ml-2'>
                  {locale.added_by}
                </span>
                <span className='ml-2'>{comment.caregiver.name}</span>
                <span className='has-text-weight-bold ml-3'>
                  {locale.added_at}
                </span>
                <span className='ml-2'>
                  {dayjs(comment.createdAt).format(dateFormat)}
                </span>
              </p>
            </div>
          </div>
        </article>
      ))}
      {isAuthorizedAction(
        study.authorizedActions,
        'Mutation:studyCommentCreate'
      ) && (
        <article className='media'>
          <div className='media-content'>
            <div className='field'>
              <p className='control'>
                <textarea
                  className='textarea'
                  placeholder={locale.discussion_comment_placeholder}
                  value={text}
                  style={{ direction: text && isRTL(text[0]) ? 'rtl' : 'ltr' }}
                  onChange={(e) => setText(e.target.value)}
                />
              </p>
            </div>
            <div className='field'>
              <p className='control'>
                <button
                  className={clsx('button is-primary', {
                    'is-loading': loading
                  })}
                  disabled={loading || !text.trim()}
                  onClick={() => {
                    const newComment = {
                      __typename: 'CommentData',
                      id: uuidv4(),
                      createdAt: new Date().toISOString(),
                      text,
                      caregiver: {
                        __typename: 'CaregiverConnectionLink',
                        name: currentUser.settings.displayName
                      }
                    }
                    addCommentToStudy({
                      variables: {
                        studyId,
                        caregiver: {
                          id: currentUser.id,
                          name: currentUser.settings.displayName,
                          email: currentUser.email
                        },
                        input: {
                          id: newComment.id,
                          createdAt: newComment.createdAt,
                          text: newComment.text
                        }
                      },
                      update(cache) {
                        cache.modify({
                          id: cache.identify(study),
                          fields: {
                            comments: (existingComment = []) => [
                              ...existingComment,
                              newComment
                            ]
                          }
                        })
                      }
                    })
                  }}
                >
                  {locale.post_comment}
                </button>
              </p>
            </div>
          </div>
        </article>
      )}
    </>
  )
}

export default StudyComments

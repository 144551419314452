if (typeof window !== 'undefined') {

/*
    bcSpiroViewerTestView - Display the spirometer test
*/
var bcSpiroViewerTestView = function(spiroViewer, testManager) {
    this._spiroViewer = spiroViewer;
    this._testManager = testManager;     
         
    this._liveFlowStepsNumber = 0;
    this._liveFlowParams = {
        flowHeight: 150,
        stepsPerFlow: 6, 
        flowStepMargin: 2 
    };
     
    this._registerEventListeners();
    
    this._initLiveFlow();
};

/*
    Register event listeners
*/
bcSpiroViewerTestView.prototype._registerEventListeners = function() {
    $('#cancelTest').on('click', $.proxy(this._onCancelTestClick,this));
};

/*
    Init the live flow widget
*/
bcSpiroViewerTestView.prototype._initLiveFlow = function() {
    var liveFlowParams = this._liveFlowParams;
    var testParams = this._testManager.getTestParams();
    
    this._liveFlowStepsNumber = (Math.ceil(testParams.maxFlow) * liveFlowParams.stepsPerFlow);
    var flowOneStepHeight = Math.floor((liveFlowParams.flowHeight - (this._liveFlowStepsNumber * liveFlowParams.flowStepMargin)) / this._liveFlowStepsNumber); 
   
    $('#exhaleFlowSteps').empty();

    var neededMargin = liveFlowParams.flowHeight - ((this._liveFlowStepsNumber-1) * liveFlowParams.flowStepMargin + this._liveFlowStepsNumber * flowOneStepHeight);
    $('#liveFlow').append($('<div></div>').height(neededMargin));
    for (var i=0;i<this._liveFlowStepsNumber;i++) {
        $('#liveFlow').append($('#flowStepTemplate').tmpl({
            'id': 'exhaleStep' + (this._liveFlowStepsNumber-i)
        }).height(flowOneStepHeight));
    } 
};

/*
    Draw flow in the live flow widget
*/
bcSpiroViewerTestView.prototype.drawLiveFlowStep = function(flow) {
    var liveFlowParams = this._liveFlowParams;
    var testParams = this._testManager.getTestParams();
    
    for (var i=0;i<this._liveFlowStepsNumber;i++) {
        $("#exhaleStep" + (i+1)).removeClass('visibleStep');
    }
    if (flow > testParams.maxFlow || flow < 0) return;

    for (var i=0;i<(Math.abs(flow) * liveFlowParams.stepsPerFlow); i++) {
        $("#exhaleStep" + (i+1)).addClass('visibleStep');
    }
};

/*
    Update the test clock value
*/
bcSpiroViewerTestView.prototype.updateTestClock = function(value) {
    $('#time').text(value);
};

/*
    Handle test start.
    Test start means we have good exhalation time.
*/
bcSpiroViewerTestView.prototype.onTestStart = function(flow) {
    this.toggleTestActive(true);
    this.updateTestClock(0);
    this.onGoodExhaleStateChanged(false);
};

/*
    Handle good exhalation time
*/
bcSpiroViewerTestView.prototype.onGoodExhaleStateChanged = function(value) {
    $('#testPanel').toggleClass('goodExhaleTest', value);
};

/*
    Handle cancel test event
*/
bcSpiroViewerTestView.prototype._onCancelTestClick = function() {
    this._spiroViewer.writeToLogFile("Spirometer test cancel clicked");
    this._testManager.cancelTest();
};

/*
    Toggle test active state
*/
bcSpiroViewerTestView.prototype.toggleTestActive = function(isActive) {
    $('#bc_spiro_viewer').toggleClass('testActive', isActive);
};

window.bcSpiroViewerTestView = bcSpiroViewerTestView;

}
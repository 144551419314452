if (typeof window !== 'undefined') {

/*
    BaseDataFetcher - handles fetching data from the API
*/
bcViewer.BaseDataFetcher = bcViewer.Class.extend({
    construct: function(globals, recordType) {
        $.extend(this, globals);

        this._events; // Cache the events data
        this._metadata; // Cache the metadata
        
        // How many live ajax requests we currently have
        this._liveAjaxRequests = 0;
        

        this._recordType = recordType;
    },

    /*
        Fetch data from API
        Let bcViewer know when we start and end fetching
    */
    _fetch: function(config) {
        this._liveAjaxRequests++;
        // Only announce if no other call is taking place
        if (this._liveAjaxRequests === 1) {
            this._bcViewer.onDataFetchStart();
        }
        var complete = config.complete;
        config.complete = $.proxy(function() {
            this._liveAjaxRequests--;
            complete && complete.apply(this, arguments);
            // Only announce if no other call is taking place
            if (this._liveAjaxRequests === 0) {
                this._bcViewer.onDataFetchEnd();
            }
        }, this);
        return this._utils.callAPI(config);
    },

    /*
        Return the current record metadata to callback
    */
    getMetadata: function(callback) { callback && callback({}); },

    /*
        Return the current record HR data to callback
    */
    getHRData: function(width, height, callback) { callback && callback({}); },

    /*
        Return all the current record events data to callback
    */
    getEventsData: function(width, height, callback) { callback && callback({}); },

    /*
        Get the events NP info from the API
    */
    getEventsNPInfo: function(recordHash, timePosition, callback) { callback && callback({}); },

    /*
        Enable ECG data regquests - send all the cached requests to the server
    */
    enableECGDataRequests:function() {},

    /*
        Disable ECG data regquests - no requests will be sent to server until enableECGDataRequests is called
    */
    disableECGDataRequests: function() {},

    /*
        Request new ecg data based on the dataParams
        The data itself won't be requested until we call _getECGData
    */
    requestECGData: function(dataParams, name, callback) { callback && callback({}); },

    /*
        Abort reuqest
    */
    abortECGDataRequest: function() {}
});

}
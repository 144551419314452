if (typeof window !== 'undefined') {

/*
    FileDataFetcher - handles fetching data from the API
*/
var FileDataFetcher = function() {
};

/*
    Get the spirometer record data from the URL
*/
FileDataFetcher.prototype.getSpirometerRecordData = function(url, callback) {    
    DataFetcherUtils._fetch({
        url: url,
        cache: false,
        type: 'GET',
        context: this,
        dataType: 'json',
        beforeSend: null, // External source, thus overriding global X-CSRF-Token verification
        success: function(resultData){                
            callback && callback(resultData);
        },
        error: function(e) {
            console.log(e);
        }
    });
};

window.FileDataFetcher = FileDataFetcher;

}
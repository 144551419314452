import React, { useState, useEffect, useContext } from 'react'
import { Link } from '@reach/router' // eslint-disable-line import/no-unresolved
import clsx from 'clsx'
import AuthContext from './AuthContext'
import LayoutActions from './LayoutActions'
import { ANONYMOUS_USER_ID, isAuthorizedAction } from '../../utils/utils'
import logo from '../legacy/img/logo.png'
import { useLocalization } from './LocalizationProvider'

const Layout = ({ gotoLoginPage, children }) => {
  const currentUser = useContext(AuthContext)
  const { locale, interpolate } = useLocalization()
  const isAnonymous = currentUser.id === ANONYMOUS_USER_ID
  const [isMenuOpen, toggleMenu] = useState(false)
  useEffect(() => {
    document.body.classList.add('has-navbar-fixed-top')
    return () => {
      document.body.classList.remove('has-navbar-fixed-top')
    }
  }, [])
  useEffect(() => {
    const head = document.querySelector('head')
    const script = document.createElement('script')

    script.setAttribute(
      'src',
      'https://d29l98y0pmei9d.cloudfront.net/js/widget.min.js?k=Y2xpZW50SWQ9Mjk4MyZob3N0TmFtZT1iZWVjYXJkaWEuc3VwcG9ydGhlcm8uaW8='
    )
    script.setAttribute('async', 'async')
    script.setAttribute('data-cfasync', 'false')
    script.onload = () => {
      if (window.supportHeroWidget) {
        window.supportHeroWidget.track({
          custom: {
            userEmail: currentUser.email
          }
        })
      }
    }

    head.appendChild(script)

    return () => {
      head.removeChild(script)
    }
  }, [])
  return (
    <>
      <header className='navbar is-fixed-top main-navbar'>
        <div className='navbar-brand'>
          <Link
            to='/'
            className='navbar-item mr-4'
            onClick={() => isMenuOpen && toggleMenu(false)}
          >
            <img src={logo} />
          </Link>
          <a
            role='button'
            className={clsx('navbar-burger', {
              'is-active': isMenuOpen
            })}
            aria-label='menu'
            aria-expanded='false'
            data-target='navbar-items'
            onClick={() => toggleMenu(!isMenuOpen)}
          >
            <span aria-hidden='true' />
            <span aria-hidden='true' />
            <span aria-hidden='true' />
          </a>
        </div>
        <div
          id='navbar-items'
          className={clsx('navbar-menu', {
            'is-active': isMenuOpen
          })}
        >
          {!isAnonymous && (
            <div className='navbar-start main-navbar-items'>
              <Link
                to='/'
                onClick={() => isMenuOpen && toggleMenu(false)}
                getProps={({ isCurrent }) => ({
                  className: clsx('navbar-item', { 'is-active': isCurrent })
                })}
              >
                {locale.studies.studies}
              </Link>
              {isAuthorizedAction(
                currentUser.clinic.authorizedActions,
                'Clinic:sourceReferrals'
              ) && (
                <Link
                  to='/reports'
                  onClick={() => isMenuOpen && toggleMenu(false)}
                  getProps={({ isCurrent }) => ({
                    className: clsx('navbar-item', { 'is-active': isCurrent })
                  })}
                >
                  {interpolate(locale.report_requests.reports)}
                </Link>
              )}
              {isAuthorizedAction(
                currentUser.clinic.authorizedActions,
                'Clinic:targetReferrals'
              ) &&
                currentUser.isTargetForReferrals && (
                  <Link
                    to='/referrals'
                    onClick={() => isMenuOpen && toggleMenu(false)}
                    getProps={({ isCurrent }) => ({
                      className: clsx('navbar-item', { 'is-active': isCurrent })
                    })}
                  >
                    {interpolate(locale.report_requests.pending_requests, {
                      number:
                        currentUser.clinic.targetReferralsTotal +
                        currentUser.clinic.referralsQueuesReferralsTotal
                    })}
                  </Link>
                )}
              {isAuthorizedAction(
                currentUser.clinic.authorizedActions,
                'Clinic:referralsQueues'
              ) &&
                currentUser.clinic.referralsQueues.length > 0 && (
                  <Link
                    to='/referralsQueue'
                    onClick={() => isMenuOpen && toggleMenu(false)}
                    getProps={({ isCurrent }) => ({
                      className: clsx('navbar-item', { 'is-active': isCurrent })
                    })}
                  >
                    {locale.report_requests.pending_requests_queue}
                  </Link>
                )}
              {isAuthorizedAction(
                currentUser.authorizedActions,
                'Query:admin'
              ) && (
                <Link
                  to='/admin'
                  onClick={() => isMenuOpen && toggleMenu(false)}
                  getProps={({ isCurrent }) => ({
                    className: clsx('navbar-item', { 'is-active': isCurrent })
                  })}
                >
                  {locale.admin}
                </Link>
              )}
            </div>
          )}
          <div className='navbar-end'>
            {isAnonymous ? (
              <a
                className='navbar-item'
                onClick={() => gotoLoginPage && gotoLoginPage()}
              >
                {locale.signin}
              </a>
            ) : (
              <LayoutActions>
                <Link
                  to='/profile'
                  onClick={() => isMenuOpen && toggleMenu(false)}
                  getProps={({ isCurrent }) => ({
                    className: clsx('navbar-item', { 'is-active': isCurrent })
                  })}
                >
                  {locale.profile_menu}
                </Link>
                <Link
                  to='/settings'
                  onClick={() => isMenuOpen && toggleMenu(false)}
                  getProps={({ isCurrent }) => ({
                    className: clsx('navbar-item', { 'is-active': isCurrent })
                  })}
                >
                  {locale.settings}
                </Link>
                {(isAuthorizedAction(
                  currentUser.clinic.authorizedActions,
                  'Clinic:sourceSharesAuto'
                ) ||
                  isAuthorizedAction(
                    currentUser.clinic.authorizedActions,
                    'Clinic:targetSharesAuto'
                  )) && (
                  <Link
                    to='/sharing'
                    onClick={() => isMenuOpen && toggleMenu(false)}
                    getProps={({ isCurrent }) => ({
                      className: clsx('navbar-item', {
                        'is-active': isCurrent
                      })
                    })}
                  >
                    {locale.sharing.title}
                  </Link>
                )}
                <a
                  className='navbar-item'
                  target='_blank'
                  rel='noreferrer'
                  href='https://beecardia.com/contact'
                >
                  {locale.contact_us}
                </a>
                <a
                  className='navbar-item'
                  target='_blank'
                  rel='noreferrer'
                  href='https://beecardia.com/terms-and-conditions'
                >
                  {locale.legal.terms_and_conditions}
                </a>
              </LayoutActions>
            )}
          </div>
        </div>
      </header>
      {children}
    </>
  )
}

export default Layout

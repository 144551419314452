if (typeof window !== 'undefined') {

/*
   BaseWavesViewer.View - the waves viewer view
*/
bcViewer.BaseWavesViewer.Model = bcViewer.Class.extend({
    construct: function(globals, state) {
        this._globals = globals;
        $.extend(this, globals)

        this._state = new bcViewer.ViewerState(state);
        this._requestedState = new bcViewer.ViewerState(state);

        // Keep all the time calculations for the current and request states
        this._stateTimeCalculations = {};
        this._requestedStateTimeCalculations ={};

        this._cacheWindowSize = this._viewer.config.CACHE_WINDOW_SIZE;
        this._shouldDrawGrid = this._viewer.config.SHOW_GRID;

        var canvasWidth = state.width && (state.width * this._cacheWindowSize);
        this._ecgCacheCanvas = new bcViewer.ECGCacheCanvas(this._config, null, canvasWidth, state.height);

        this._ecgGridCanvas = this._shouldDrawGrid ? new bcViewer.ECGGridCanvas(this._config, null, canvasWidth, state.height) : null;

        this._numberOfPixelsPerBigBlock = this._config.ecgGrid.SMALL_BLOCKS_PER_BIG_BLOCK * this._config.ecgGrid.NUM_PIXELS_PER_SMALL_BLOCK;

        // HACKY: this flag is set to true when we are waiting for ECG data to return from the dataFetcher
        this._waitingForECGData = false;

        // Saving the latest QRS annotations
        this._qrsData;

        // Record duration
        this._duration = 0;

        // Saving the latest ECG data
        this._ecgData;

        // Saving the lead names
        this._leadNames;
        this._fullLeadNames;

        // Realtime parameters
        this._rtIsActive = false;  // Indicates if realtime mode is active
        this._rtIsBufferReady = false;  // On the first time we get data, we need to create buffer for each channel
        this._rtReadInterval = 400; // Get realtime data interval
        this._rtWriteInterval = 50; // Show realtime data interval
        this._rtWriteClock = null;         // Save reference to the write clock
        this._rtBuffer = [];        // Realtime data buffer: two dimensional array [channel_index][ecg_data]
                                    // each this._rtReadInterval we fetch new data and concat the fetched data
                                    // of each channel to the appropriate index in the buffer.
                                    // each this._rtWriteInterval we read this._writeBlockSize amount of data from the buffer
                                    // and asking the viewer to display it.
        this._rtWriteIndex = 0;     // In what index is the realtime data block we want to show
        this._rtWriteBlockSize = 6; // How many points we want to show each time we show realtime data

        this._rtWriteCallback; // save reference to write callback
        this._rtWriteHRCallback; // save reference to write HR callback

        // Initial amount of points the buffer should hold before start writing data to canvas
        this._rtBufferSizeBeforeWriting = (this._viewer.config.REALTIME) ? this._viewer.config.REALTIME.BUFFERING_INITIAL_SIZE : 200;

         // Increase _rtBufferSizeBeforeWriting by _rtBufferIncrementSize each time we dont have enough data
        this._rtBufferIncrementSize = (this._viewer.config.REALTIME) ? this._viewer.config.REALTIME.BUFFER_INCREMENT_SIZE : 100;

        // Adjusting speed parameters
        this._rtStableBufferSize = this._rtBufferSizeBeforeWriting; // Writing speed will change in order to keep this buffer size
        this._rtLastBufferSize = this._rtBufferSizeBeforeWriting;   // Remember the size of the buffer from the last tendency check
        this._rtCheckTendencyInterval = 5; // How many times we should handle new data before performing tendency check
        this._rtCheckTendencyCounter = 0; // Count how many times we got new data

        // Below this size the writing speed will drop to critical buffer writing speed
        this._rtCriticalBufferSize = (this._viewer.config.REALTIME) ? this._viewer.config.REALTIME.CRITICAL_BUFFER_SIZE : 50;
        this._rtCriticalWritingSpeed = (this._viewer.config.REALTIME) ? this._viewer.config.REALTIME.CRITICAL_BUFFER_WRITING_SPEED : 3;

        // error callback
        this._rtErrorCallback = null;

        // waiting for ecg data callback
        this._rtWaitingForEcgDataChanged = null;

        this._rtSamplingRate = 400;
        this._rtUnitsPerMV = 328;
    },

    /*
        Handle state changes
    */
    onStateChange: function(stateChanges, callback, isInitCycle) {
        // Apply changes to this._requestedState and
        // return  if it's the same as the current state
        // For example current zoomX is 10 and we got request to change zoomX to 10
        $.extend(this._requestedState, stateChanges);

        // No need to fetch data on init cycle, only update the state
        if (isInitCycle) {
            $.extend(this._state, this._requestedState);
            var response = this._handleInitCycle();

            callback && callback(response);
            return;
        }

        if (this._bcViewer.isRealtimeMode) {
            $.extend(this._state, this._requestedState);
            return; // add callback for realtime mode if needed
        }

        // If we're waiting for any ECG data we allow the request to go through
        // not super efficient but simpler implementation
        if (!this._waitingForECGData && this._state.equalTo(this._requestedState)) return;

        if (typeof this._requestedState.timePosition === 'undefined' ||
            typeof this._requestedState.width === 'undefined' ||
            typeof this._requestedState.zoomX === 'undefined' ) {
          return;
        }

        // Calculate times for the requested state
        this._requestedStateTimeCalculations = this._getTimeCalculations(this._requestedState);

        // We got the data in the cache - good for us!
        if (this._gotDataForRequestedStateInCache()) {
            this._changeStateToReuqestedState();
            this._sendECGDataBackToCallback(callback);
        }

        // If resizing or changing vertical zoom, redraw the grid
        if ((typeof stateChanges.width !== 'undefined') ||
            (typeof stateChanges.height !== 'undefined') ||
            (typeof stateChanges.zoomY !== 'undefined')) {

            this._shouldDrawGrid = this._viewer.config.SHOW_GRID;
        }

        this._waitingForECGData = true;
        var requestedParams = this._getDataParamsForRequestedState();
        this._drawGridIfNeeded(requestedParams.cy);

        // Request new ECG data for the new state
        this._dataFetcher.requestECGData(requestedParams, this._viewer.name,
            $.proxy(function(ecgData) {
                    this._waitingForECGData = false;
                    this._ecgData = ecgData;
                    this._ecgCacheCanvas.setHeight(requestedParams.cy);
                    this._ecgCacheCanvas.setWidth(requestedParams.cx);
                    this._changeStateToReuqestedState();
                    if (ecgData.shouldConvertQrsToPixels) {
                        this._convertTimePositionToPixels(ecgData.qrs, this._requestedStateTimeCalculations.startTime);
                    }

                    ecgData.userMarks = $.extend(true, [], this._state.userMarks);
                    this._convertTimePositionToPixels(ecgData.userMarks, this._requestedStateTimeCalculations.startTime);

                    if (this._requestedState.qrsMarkValue === 'hr') {
                        ecgData.qrs = $.extend(true, [], ecgData.qrs);
                        this._convertQrsRRToHr(ecgData.qrs);
                    }

                    if (!this._bcViewer.showAnnotations) {
                        ecgData.qrs = [];
                    }
                    this._ecgCacheCanvas.draw(ecgData, this._ecgGridCanvas, this._getGridOffset());
                    this._stateTimeCalculations.startTime = this._requestedStateTimeCalculations.startTime;
                    this._stateTimeCalculations.endTime = this._requestedStateTimeCalculations.endTime;
                    this._qrsData = ecgData.qrs;
                    this._leadNames = ecgData.leadNames;
                    this._fullLeadNames = ecgData.fullLeadNames;
                    this._sendECGDataBackToCallback(callback);
            }, this));

    },

    /*
        Returns the needed grid offset for the viewer
    */
    _getGridOffset: function() {
        return -(this.convertTimeToPixels(this._requestedStateTimeCalculations.startTime, this._state) % this._numberOfPixelsPerBigBlock);
    },

    /*
        Draw grid canvas if needed
    */
    _drawGridIfNeeded: function(cy) {
        if (this._shouldDrawGrid) {
            this._ecgGridCanvas.setHeight(cy);

            // width*this._cacheWindowSize = cache canvas width.
            // Adding bigBlockSize because we are copying the grid canvas to the cache canvas
            // with a negative offset which is no larger than bigBlockSize
            // and we don't want to have blank space in the end of the cache canvas
            this._ecgGridCanvas.setWidth((this._requestedState.width * this._cacheWindowSize) + this._numberOfPixelsPerBigBlock);
            this._ecgGridCanvas.draw();
            this._shouldDrawGrid = false;
        }
    },

    /*
        Update state and _stateTimeCalculations with the requested state
    */
    _changeStateToReuqestedState: function() {
        // Apply requestedState changes to state (set this._state to this._requestedState)
        $.extend(this._state, this._requestedState);

        // Update the cache visible window times
        this._stateTimeCalculations.visibleWindowStartTime = this._requestedStateTimeCalculations.visibleWindowStartTime;
        this._stateTimeCalculations.visibleWindowEndTime = this._requestedStateTimeCalculations.visibleWindowEndTime;
    },

    /*
        Send new data to onStateChange callback

        sends the cached canvas back to the callback with the correct xOffset for
        the requested timePosition
    */
    _sendECGDataBackToCallback: function(callback) {
        var xOffset = this._getCacheCanvasXOffset(this._state.timePosition);
        xOffset = Math.max(0, xOffset);

        var response = {
                        'sourceCanvas': this._ecgCacheCanvas.canvas,
                        'xOffset': -xOffset,
                        'yOffset': 0,
                        'width': this._ecgCacheCanvas._width,
                        'height': this._ecgCacheCanvas._height,
                        'visibleWindowStartTime': this._requestedStateTimeCalculations.visibleWindowStartTime,
                        'visibleWindowEndTime': this._requestedStateTimeCalculations.visibleWindowEndTime,
                        'visibleWindowDuration': this._requestedStateTimeCalculations.visibleWindowDuration
                      };

        callback && callback(response);
    },

    /*
        Return the current state
    */
    getState: function() {
        return this._state;
    },

    /*
        Return the x-offset (px) on the cache canvas for a specific timePosition
    */
    _getCacheCanvasXOffset: function(timePosition) {
        var timeDifference = timePosition - this._stateTimeCalculations.startTime;
        var pixelLocation = this.convertTimeToPixels(timeDifference, this._state);

        var timeTillEnd = this._duration - timePosition;

        var offset =  Math.max(0, pixelLocation - (this._state.width/2));

        // if time position is on the second half of the last visible window, stop moving the waves.
        // i.e. calculate the offset so the window visible start time will be of the last visible window.
        if (timeTillEnd <= this._requestedStateTimeCalculations.visibleWindowDuration/2) {
            var lastWindowStartTimePosition = this._duration - this.convertPixelsToTime(this._state.width, this._state);

            // timeOffset = the time offset from the cache start time to the last visible window start time
            var timeOffset = lastWindowStartTimePosition - this._stateTimeCalculations.startTime;

            offset = this.convertTimeToPixels(timeOffset, this._state);
        }

        return offset;
    },

    /*
        Returns true if we have data in the cache to show the requested state
    */
    _gotDataForRequestedStateInCache: function() {
        // Cache is empty
        if (typeof this._stateTimeCalculations.startTime === 'undefined') return false;

        // requestedState got to have the same zoom, dimensions and filter
        if ((this._requestedState.zoomX !== this._state.zoomX) ||
            (this._requestedState.zoomY !== this._state.zoomY) ||
            (this._requestedState.selectedChannel !== this._state.selectedChannel) ||
            (this._requestedState.width !== this._state.width) ||
            (this._requestedState.height !== this._state.height) ||
            (this._requestedState.baselineCorrection !== this._state.baselineCorrection) ||
            (this._requestedState.smooth !== this._state.smooth)) {
            return false;
        }

        // Cache hit
        if ((this._requestedStateTimeCalculations.visibleWindowStartTime >= this._stateTimeCalculations.startTime) &&
            (this._requestedStateTimeCalculations.visibleWindowEndTime <= this._stateTimeCalculations.endTime)) {
            return true;
        }

        return false;
    },

    /*
        Return data parameters  for the requested state

        cx - width of the window (px)
        cy - height of the window (px)
        rx - duration of one pixel (ms)
        ry - amplitude of one pixel
        startTime - start time of the entire window
        startPointOffset - start time of visible window
        endPointOffset   - end time of visible window
        bl - baseline filter (boolean)
        sm - smooth filter (boolean)
    */
    _getDataParamsForRequestedState: function() {
        var timeCalculations = this._requestedStateTimeCalculations;
        var state = this._requestedState;

        var cacheStartTimeToRecordEndTimePX = this.convertTimeToPixels(this._duration - timeCalculations.startTime, state);

        var expandChannels = this._getExpandChannelsValue();

        return {
            'cx': Math.round(Math.max(Math.min(state.width * this._cacheWindowSize, cacheStartTimeToRecordEndTimePX), state.width)),
            'cy': Math.round(state.height),
            'rx': timeCalculations.onePixelDuration, // ms
            'ry': this._config.PIXEL_FACTOR / state.zoomY , // One pixel amplitude
            'startTime': timeCalculations.startTime,
            'endTime': timeCalculations.endTime,
            'startPointOffset': timeCalculations.visibleWindowStartTime,
            'endPointOffset'  : timeCalculations.visibleWindowEndTime,
            'selectedChannel': state.selectedChannel && state.selectedChannel,
            'bl': state.baselineCorrection,
            'sm': state.smooth,
            'expand2To6': state.expand2to6,
            'expand3To7': state.expand3to7,
            'expand3To12': state.expand3to12,
            'expand8To12': state.expand8to12,
            'expandChannels': expandChannels,
            'requestedChannels': state.requestedChannels && state.requestedChannels
        }
    },

    _getExpandChannelsValue: function() {

        var state = this._requestedState;

        if (state.expand2to6) { return 6; }
        if (state.expand3to7) { return 7; }
        if (state.expand3to12) { return 12; }
        if (state.expand8to12) { return 8; }

        return undefined;
    },

    /*
        Get basic time calculations or a state
    */
    _getTimeCalculations: function(state) {
        if (typeof state.timePosition === 'undefined' ||
            typeof state.width        === 'undefined' ||
            typeof state.zoomX        === 'undefined') {
            throw "Can't calculate time window without timePosition, width or zoomX";
        }

        var onePixelDuration = this._getOnePixelDuration(state);
        var visibleWindowDuration = state.width * onePixelDuration;
        // (this._cacheWindowSize - 1)/2 - number of windows to get before the current window
        // timePosition - visibleWindowDuration/2 - to allow the timePosition to show in the middle of the canvas
        var startTime = Math.max(0, state.timePosition- visibleWindowDuration/2 - (((this._cacheWindowSize - 1)/2) * visibleWindowDuration));

        var visibleWindowStartTime = Math.max(0, state.timePosition - visibleWindowDuration/2);

        // When the time position is in the last half of the visible window of the record, keep the visible window on the last visible window
        if ((!this._bcViewer.fullDisclosureViewer) && (this._duration - state.timePosition <= visibleWindowDuration/2)) {
            visibleWindowStartTime = Math.max(0, this._duration-visibleWindowDuration);
        }

        var endTime = startTime + (visibleWindowDuration * this._cacheWindowSize);
        var visibleWindowEndTime = visibleWindowStartTime + visibleWindowDuration;
        if (!this._bcViewer.fullDisclosureViewer) {
            endTime = Math.min(endTime, this._duration);
            visibleWindowEndTime = Math.min(visibleWindowEndTime, this._duration);
        }

        return {
            'onePixelDuration': onePixelDuration,
            'visibleWindowDuration': visibleWindowDuration,
            'startTime': startTime,
            'endTime'  : endTime,
            'visibleWindowStartTime': visibleWindowStartTime,
            'visibleWindowEndTime'  : visibleWindowEndTime
        }
    },

    /*
        Return the duration in ms of one pixel for a specific state
    */
    _getOnePixelDuration: function(state) {
        return this._config.PIXEL_FACTOR / state.zoomX;
    },

    /*
        Convert time (ms) to pixels
    */
    convertTimeToPixels: function(time, state) {
        return time / this._getOnePixelDuration(state);
    },

    /*
        Convert pixels to time (ms)
    */
    convertPixelsToTime: function(pixel, state) {
        return pixel * this._getOnePixelDuration(state);
    },

    /*
        Convert time (ms) to visible window pixel location
    */
    convertTimeToVisibleWindowPixels: function(timePosition, state) {
        var timeDifference = timePosition - this._stateTimeCalculations.visibleWindowStartTime;
        return this.convertTimeToPixels(timeDifference, state);
    },

    /*
        Convert visible window pixel location to time (ms)
    */
    convertVisibleWindowPixelsToTime: function(pixelsLocation, state) {
        var timePosition = this.convertPixelsToTime(pixelsLocation, state);
        return timePosition + this._stateTimeCalculations.visibleWindowStartTime;
    },

    /*
        Return the visible window start and end time
    */
    getCacheTimeWindow: function() {
        return this._stateTimeCalculations;
    },

    /*
        update the model state
        this function is for updating the model state without affecting the viewer, but only the calculations
        usage examples:
            * update the zoomX of the context viewer by the main-context viewers width ratio
            * change the time position while dragging
        in both of this cases we want to know about the changes for the calculations but we dont want to
        affect the viewer
    */
    updateState: function(stateChanges) {
        $.extend(this._requestedState, stateChanges);

        // if time position changed, recalculate the time parameters
        if (typeof stateChanges.timePosition !== 'undefined') {
            this._requestedStateTimeCalculations = this._getTimeCalculations(this._requestedState);
        }

        this._changeStateToReuqestedState();
    },

    /*
        Handle drag start
    */
    onDragStart: function() {
        this._dataFetcher.abortECGDataRequest();
    },

    /*
        Check if drag is available.
        drag is available if the record duration is longer than the viewer width
    */
    isDragAvailable: function() {
        return (this.convertTimeToPixels(this._duration, this._state) > this._state.width);
    },

    /*
        Return ECG data according to the drag offset
    */
    getECGDataForDrag: function(xOffset, callback) {
        var offset = this._getCacheCanvasXOffset(this._state.timePosition);
        offset -= xOffset;
        (xOffset !== 0) && callback && callback(this._ecgCacheCanvas.canvas, -offset, 0, this._ecgCacheCanvas._width, this._ecgCacheCanvas._height);
    },

    /*
        Update the record duration
    */
    updateDuration: function(duration) {
        this._duration = duration;
    },

    /*
        update zoomX if the record duration is too small to fit the viewer width
        return true if the zoom has changed
    */
    updateZoomXIfNeeded: function() {
        if (this._duration < this.convertPixelsToTime(this._state.width,this._state)) {
            var newZoomX = this._state.zoomX / (this._duration / this.convertPixelsToTime(this._state.width,this._state));
            this.updateState({zoomX: newZoomX});
            return true;
        }

        return false;
    },

    /*
        Return the QRS annotaitons data
    */
    getQRSAnnotations: function() {
        return this._qrsData;
    },

    /*
        Check if dragging to a valid location.
    */
    canDragToTimePosition: function(timePosition) {
        var offset = this._getCacheCanvasXOffset(timePosition);
        var maxDragOffsetAvailable = this._state.width*((this._cacheWindowSize+1)/2);
        var recordStart = this.convertPixelsToTime(this._state.width/2, this._state);

        // on the the record start point offset = 0, so if offset <0 but the timePosition is the start, enable drag
        return (((offset > 0) || (timePosition == recordStart)) && (offset <  maxDragOffsetAvailable));
    },

    /*
        Handle init cycle: draw grid if needed and create response
    */
    _handleInitCycle: function() {
        if (this._shouldDrawGrid) {
            this._drawGridIfNeeded(this._state.height);
            this._ecgCacheCanvas.setHeight(this._state.height);
            this._ecgCacheCanvas.setWidth(this._state.width);
            this._ecgCacheCanvas.draw(null, this._ecgGridCanvas, 0);
        }

        // this._drawGridIfNeeded change this._shouldDrawGrid to false
        // we need to change it back to it's default value in order
        // to redraw it in the correct offset,
        // after the init cycle, when we got the time position,
        this._shouldDrawGrid = this._viewer.config.SHOW_GRID;

        var response = {
            'sourceCanvas': this._ecgCacheCanvas.canvas,
            'xOffset': 0,
            'yOffset': 0
        };

        return response;
    },

    /*
        return lead names
    */
    getLeadNames: function(shouldGetFull) {
        var ret = this._leadNames;
        if (shouldGetFull) ret = this._fullLeadNames;
        return ret;
    },

    /*
        return visible points values for a channel in range
    */
    getVisibleChannelsPointsInRange: function(channelNumber, from, to) {
        if (channelNumber >= this._ecgData.series.length) {
            return [];
        }

        // get the index of the first visible point
        var timeOffsetToVisiblePoint = this._stateTimeCalculations.visibleWindowStartTime - this._stateTimeCalculations.startTime;
        var firstVisiblePX = Math.round(this.convertTimeToPixels(timeOffsetToVisiblePoint, this._state));

        var visibleFrom = from + firstVisiblePX;
        var visibleTo = to + firstVisiblePX;
        return this._ecgData.series[channelNumber].points.slice(visibleFrom, visibleTo);
    },

    /*
        Call callback passing the cache canvas and the top-left corder of the bounding box in respect to the time position
    */
     getData: function(boundingBox, callback) {
        var timeOffsetToVisiblePoint = this._stateTimeCalculations.visibleWindowStartTime - this._stateTimeCalculations.startTime;
        var pxOffsetToVisiblePoint = this.convertTimeToPixels(timeOffsetToVisiblePoint, this._state);

        var offset = {
                x: pxOffsetToVisiblePoint + boundingBox.x,
                y: boundingBox.y
            };
        callback && callback(this._ecgCacheCanvas.canvas, offset);
    },

    /*
        Init realtime mode
    */
    initRealTimeMode: function() {
        this._rtIsBufferReady = false;
        this._rtWriteClock = null;
        this._rtBuffer = [];
        this._rtWriteIndex = 0;
        this._rtWriteBlockSize = 6;
        this._rtBufferSizeBeforeWriting = (this._viewer.config.REALTIME) ? this._viewer.config.REALTIME.BUFFERING_INITIAL_SIZE : 200;
        this._rtBufferIncrementSize = (this._viewer.config.REALTIME) ? this._viewer.config.REALTIME.BUFFER_INCREMENT_SIZE : 100;
        this._rtStableBufferSize = this._rtBufferSizeBeforeWriting;
        this._rtLastBufferSize = this._rtBufferSizeBeforeWriting;
        this._rtCheckTendencyCounter = 0;
    },

    /*
        start/stop realtime mode
    */
    toggleRealtimeMode: function(activateRealtimeMode, realtimeModeType, callback) {
        this._dataFetcher.toggleRealtimeMode(activateRealtimeMode, realtimeModeType, callback);
    },

    /*
        Handle realtime mode changed
        start\stop read\write data
    */
    onRealtimeModeChanged: function(isRealtimeActive, realtimeModeType, samplingRate, unitsPerMV, writeCallback, writeHRCallback, errorCallback, waitingForEcgDataChanged) {
        this._rtIsActive = isRealtimeActive;
        this._rtErrorCallback = errorCallback;

        this._bcViewer.viewerApi.onRealtimeModeChanged(isRealtimeActive);

        if (isRealtimeActive) {
            this._rtSamplingRate = samplingRate;
            this._rtUnitsPerMV = unitsPerMV;
        }

        this._rtWaitingForEcgDataChanged = waitingForEcgDataChanged;

        // nothing to do if stethoscope only mode
        var isStethoscopeOnlyMode = ((realtimeModeType & this._bcViewer.realtimeModeTypes.STETHOSCOPE) && !(realtimeModeType & this._bcViewer.realtimeModeTypes.ECG));
        if (isStethoscopeOnlyMode) {
            this._rtWaitingForEcgDataChanged && this._rtWaitingForEcgDataChanged(false);
            return;
        }

        if (isRealtimeActive) {
            // calculate pixel writing rate
            var neededPixelsPerMillisecond = (this._state.zoomX * this._config.ecgGrid.NUM_PIXELS_PER_SMALL_BLOCK) / 1000;
            this._rtWriteBlockSize = (neededPixelsPerMillisecond * this._rtWriteInterval) + 1;

            setTimeout($.proxy(this._rtReadData, this), this._rtReadInterval);

            this._rtWriteCallback = writeCallback;
            this._rtWriteHRCallback = writeHRCallback;
        } else {
            clearInterval(this._rtWriteClock);

            this._rtWriteClock = null;
        }
    },

    /*
        Read realtime data
    */
    _rtReadData: function() {
        var onePixelDuration = this._getOnePixelDuration(this.getState());

        var state = this.getState();

        var requestParams =  {
            'cy': state.height,
            'rx': onePixelDuration, // ms
            'ry': this._config.PIXEL_FACTOR / state.zoomY , // One pixel amplitude
            'sm': state.smooth,
            'bl': state.baselineCorrection,
            'shouldExpand': (parseInt(this._bcViewer.windowOrientation) === this._bcViewer.windowOrientations.PORTRAIT)
        }
        this._dataFetcher.getRealtimeData(requestParams, $.proxy(this._rtGotData, this), this._rtErrorCallback);
    },

    /*
        Store realtime data
    */
    _rtGotData: function(rtData) {
        if ((typeof rtData.series === 'undefined') || (rtData.series.length === 0)) {
            this._rtIsActive && setTimeout($.proxy(this._rtReadData, this), this._rtReadInterval);
            return;
        }

        this._bcViewer.viewerApi.onRealtimeGotData(rtData, this._rtSamplingRate, this._rtUnitsPerMV, this._bcViewer.electrodesPosition);

        // first time reading, init the buffer
        if (this._rtIsBufferReady === false) {
            // rtData.series.length: number of channels
            // this loop creates a buffer for each channel
            for (var i=0; i < rtData.series.length; i++) {
                this._rtBuffer[i] = [];
            }

            this._rtIsBufferReady = true;
        }

        // this._rtBuffer[i]: data for channel i
        // concat the new data of each channel to the channel buffer
        for (var i=0; i < rtData.series.length; i++) {
            this._rtBuffer[i] = this._rtBuffer[i].concat(rtData.series[i].points);
        }

        // If we didn't start writing yet and we have enough data, start writing
        if ((this._rtWriteClock === null) && ((this._rtBuffer[0].length - this._rtWriteIndex)  >= this._rtBufferSizeBeforeWriting)) {
            this._rtWaitingForEcgDataChanged(false);
            this._rtWriteClock = setInterval($.proxy(this._rtWriteData, this, this._rtWriteCallback), this._rtWriteInterval);
        }

        if (this._rtWriteClock !== null) {
            this.adjustSpeed();
        }

        // call HR callback, which will display the data
        // no need to handle in the same way as ECG data because no buffering is needed
        this._rtWriteHRCallback(rtData.HR, rtData.saturation);

        this._rtIsActive && setTimeout($.proxy(this._rtReadData, this), this._rtReadInterval);
    },

    /*
        Show Realtimedata
    */
    _rtWriteData: function(writeCallback) {
        // no data to read (if we didnt read data yet or if we wrote everything we read)
        if ((typeof(this._rtBuffer[0]) === 'undefined') || (typeof(this._rtBuffer[0][this._rtWriteIndex]) === 'undefined')) {
       		return;
        }

        // yPoints is the parameter for writeCallback, we will fill it with y-coordinates of the data to draw
        var yPoints = [];

        // calculate how many points can we draw, buffer[0] is the data for channel 1 and all the channels
        // holds the same length of data
        var blockSize = Math.min(this._rtWriteBlockSize, this._rtBuffer[0].length - this._rtWriteIndex);

        // blockSize should never be 0, if blockSize is 1 it means we finished writing the data we have,
        // the last point is the first point for the next time we will draw (when new data will arrive)
        // so blockSize 1 means we have nothing new to draw
        if (blockSize === 1) {
            this._rtWaitingForEcgDataChanged(true);

            // stop writing, increase initial amount of points the buffer should hold before start writing
            // and start buffering
            this._rtBufferSizeBeforeWriting += this._rtBufferIncrementSize;

            clearInterval(this._rtWriteClock);
            this._rtWriteClock = null;

            this._rtCheckTendencyCounter = 0;
            var bufferSize = this._rtBuffer[0].length - (this._rtWriteIndex +1);
            this._rtLastBufferSize = bufferSize;

            this._rtStableBufferSize = this._rtBufferSizeBeforeWriting;
            this._rtWriteBlockSize = this._rtCriticalWritingSpeed;

            return;
        };

        // for each channel, fill yPoints array with the y-coordinates to draw
        for (var i=0; i < this._rtBuffer.length; i++) {
            yPoints[i] = [];

            for (var j=0; j < blockSize; j++) {
                yPoints[i][j] = this._rtBuffer[i][this._rtWriteIndex+j][1];
            }
        }

        // call callback, which will display the data
        writeCallback(yPoints, blockSize);

        // why blockSize-1? each block we draw is a series of lines lets say the line points are: x(0),...,x(5),
        // next line points should be: x(5),...,x(10). in general the last point of the current line
        // should be the first point of the next line
        this._rtWriteIndex += (blockSize-1);

		// delete data we dont need anymore
        for (var i=0; i < this._rtBuffer.length; i++) {
            this._rtBuffer[i] = this._rtBuffer[i].slice(this._rtWriteIndex);
        }
        this._rtWriteIndex = 0;
    },

    /*
        Adjust writing speed according to the buffer size
    */
    adjustSpeed: function() {
        var bufferSize = this._rtBuffer[0].length - (this._rtWriteIndex +1);

        // If in critical buffer size, drop the speed to critical speed
        if (bufferSize <= this._rtCriticalBufferSize) {
            this._rtWriteBlockSize = this._rtCriticalWritingSpeed;
            this._rtCheckTendencyCounter = 0;
            this._rtLastBufferSize = bufferSize;
        } else if (this._rtCheckTendencyCounter === this._rtCheckTendencyInterval) {
            // Calculate the buffer tendency
            var bufferChange = (bufferSize - this._rtLastBufferSize);

            // If buffer size is below stable size and the buffer size got smaller, slow down the writing speed
            // If buffer size is above stable size and the buffer size got longer, speed up the writing
            if ((bufferSize < this._rtStableBufferSize) && (bufferChange < 0)) {
                this._rtWriteBlockSize--;
            } else if ((bufferSize > this._rtStableBufferSize) && (bufferChange > 0)) {
                this._rtWriteBlockSize++;
            }

            this._rtCheckTendencyCounter = 0;
            this._rtLastBufferSize = bufferSize;
        }

        this._rtCheckTendencyCounter++;
    },

    /*
        return real time viewer lead names
    */
    getRTLeadNames: function() {
        return this._dataFetcher.getLeadNamesByElectrodesPosition(parseInt(this._bcViewer.electrodesPosition));
    },

    /*
        calculate the x pixel of the qrs\user marks annotation according to it's time position
    */
    _convertTimePositionToPixels: function(data, startTime) {
      for(var i=0; i<data.length; i++) {
        var timeOffsetToX = data[i].x - startTime;
        data[i].x = Math.round(this.convertTimeToPixels(timeOffsetToX, this._state));
      }
    },

    getScaleDimensions: function() {
        return {
            width: this.convertTimeToPixels(100, this._state),
            height: 1000/(this._config.PIXEL_FACTOR/this._state.zoomY ) //(1000 / ry)
        };
    },

    _convertQrsRRToHr: function(qrsData) {
        for (var i=0;i<qrsData.length;i++) {
            var annotations = qrsData[i].annotations;
            for (var j=0;j<annotations.length;j++) {
                    var annotation = annotations[j];
                    if (annotation.annotationType == 'qrs' && annotation.caption != '' && annotation.caption != 0) {
                        annotation.caption = Math.round(60000/parseInt(annotation.caption));
                    }
            }
        }
    }
});

}

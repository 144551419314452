import React from 'react'
import Auth from '@aws-amplify/auth'
import { Hub } from '@aws-amplify/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
import { useLocalization } from '../LocalizationProvider'
import { SCHEMA_LOCALE_TO_COOKIE_LOCALE } from '../../../utils/utils'

const SignOut = () => {
  const { localeKey, locale } = useLocalization()
  return (
    <a
      className='navbar-item'
      onClick={async () => {
        window.IS_LOGGED_OUT = true
        Hub.dispatch('auth', { event: 'signedOut' }, 'Auth')
        await Auth.signOut()

        // TODO: remove after full migration
        const form = document.createElement('form')
        form.setAttribute('method', 'post')
        form.setAttribute(
          'action',
          `/${SCHEMA_LOCALE_TO_COOKIE_LOCALE[localeKey]}/user_session`
        )
        // Create an input element for Full Name
        var input = document.createElement('input')
        input.setAttribute('type', 'hidden')
        input.setAttribute('name', '_method')
        input.setAttribute('value', 'delete')
        form.appendChild(input)
        document.getElementsByTagName('body')[0].appendChild(form)
        form.submit()
        // TODO: remove after full migration

        // window.location = '/login' // TODO: uncomment after full migration
      }}
    >
      <span className='icon'>
        <FontAwesomeIcon icon={faSignOutAlt} />
      </span>
      <span>{locale.logout}</span>
    </a>
  )
}

export default SignOut

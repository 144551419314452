import React, { useState } from 'react'
import Modal from '../Modal'
import { gql, useMutation } from '@apollo/client'
import clsx from 'clsx'
import { hashPassword } from '../../../utils/utils'

const SET_USER_PASSWORD = gql`
  mutation SET_USER_PASSWORD(
    $id: ID!
    $password: String!
    $isNotify: Boolean!
  ) {
    userSetPassword(id: $id, password: $password, isNotify: $isNotify) {
      id
    }
  }
`

export default function SetUserPassword({ user, onExit, onComplete }) {
  const [inProgress, setInProgress] = useState(false)
  const [password, setPassword] = useState('')
  const [isNotify, setIsNotify] = useState(true)

  const [setUserPassword] = useMutation(SET_USER_PASSWORD)

  async function submit() {
    setInProgress(true)
    await setUserPassword({
      variables: {
        id: user.id,
        password: hashPassword(user.email, password),
        isNotify
      }
    })
    setInProgress(false)
    onComplete()
  }

  return (
    <Modal onClose={onExit} onSubmit={submit} inProgress={inProgress}>
      <label className='label is-medium'>Set user password</label>
      <hr />

      <div>
        <div className='field is-horizontal'>
          <div className='field-label'>
            <label className='label has-text-left'>Password:</label>
          </div>
          <div className='field-body'>
            <div className='field'>
              <div className='control'>
                <input
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                ></input>
              </div>
            </div>
          </div>
        </div>
        <div className='field is-horizontal'>
          <div className='field-label'>
            <label className='label has-text-left'>Notify user:</label>
          </div>
          <div className='field-body'>
            <div className='field'>
              <div className='control'>
                <input
                  type='checkbox'
                  checked={isNotify}
                  onChange={(e) => {
                    setIsNotify(e.target.checked)
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className='field'>
          <div className='level'>
            <div className='level-left'>
              <div className='level-item'></div>
            </div>
            <div className='level-right'>
              <div className='level-item'>
                <button
                  className={clsx('button is-primary level-item', {
                    'is-loading': inProgress
                  })}
                  onClick={async () => submit()}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

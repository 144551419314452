if (typeof window !== 'undefined') {

/*
    bcSpiroViewerTestManager - Handle spirometer test
*/
var bcSpiroViewerTestManager = function(spiroViewer, dataFetcher, pitchManager) {
    this._spiroViewer = spiroViewer;
    this._dataFetcher = dataFetcher;
    this._pitchManager = pitchManager;
    

    this._testParams = {
        exhaleFlowLimit: (this._spiroViewer.getSpiroType() !== this._spiroViewer.spiroTypes.POND) ? 0.00000001 : 0.1,        // If flow > exhaleFlowLimit this is exhalation
        inhaleFlowLimit: (this._spiroViewer.getSpiroType() !== this._spiroViewer.spiroTypes.POND) ? 0.00000001 : 0,          // If flow < exhaleFlowLimit this is inhalation
        exhaleSecondsBeforeStart: 2, // Seconds of exhalation before we decide the test started   
        inhaleCountBeforeStop: 10,   // How many flow points of inhalation before we stop the test
        maxFlow: 6                   // Max flow for visualization 
    };
    
    this._startCount = 0;
    
    // Did exhalation start
    this._exhaleStarted = false;
    
    // Is test in progress
    this._isTestActive = false;
    
    // Count inhale flow points
    this._cntInhale = 0;
    
    // the flow array
    this._flowArr = [];
    
    // Start time of the test
    this._startTime = 0;
    
    // End time of the test
    this._endTime = 0;
    
    // Reading flow from server
    this._getDataTimeout = null;
    
    // Spirometer test view
    this._view = new bcSpiroViewerTestView(spiroViewer, this);
};

/*
    Return the test parameters
*/
bcSpiroViewerTestManager.prototype.getTestParams = function() {
    return this._testParams;
};

/*
    Start spiroemter test
*/
bcSpiroViewerTestManager.prototype.start = function() {
    this._startCount = 0;

    this._isTestActive = true;
    
    this._view.onTestStart();
    
    this._flowArr = [];
    
    this._spiroViewer.writeToLogFile("Spirometer test start");
    
    this._spiroType = this._spiroViewer.getSpiroType();
    
    if (this._spiroType == this._spiroViewer.spiroTypes.POND) {
        this._pitchManager.start($.proxy(this._onTestTimeout, this));
    }
    
    this.startSpirometer();
};

bcSpiroViewerTestManager.prototype.startSpirometer = function() {
    this._dataFetcher.startSpirometer(this._spiroType, this._pitchManager.shouldPitchFromServer(), $.proxy(function(response) {
        if (response.result === 'fail') {
            if (this._startCount === 6) {
                this._spiroViewer.writeToLogFile("Cant start spirometer test");
                this._spiroViewer.spiroViewerApi.onStartMonitoringError();
                return;
            }
            
            this._spiroViewer.writeToLogFile("Try starting spirometer " + this._startCount);
            
            this._startCount++;
            this.startSpirometer(); // retry
        } else {
            this._dataFetcher.getSpirometerData($.proxy(this.onGotData, this));
        }
    }, this));
};


/*
    Handle getting flow array from the server
*/
bcSpiroViewerTestManager.prototype.onGotData = function(results) {
    if (!this._isTestActive) return; 
    if (!results.data) return;
       
    //this._spiroViewer.writeToLogFile("onGotData: " + JSON.stringify(results));
   
    for (var i=0; i < results.data.length; i++) {
        var flow = results.data[i];
        
        this._view.drawLiveFlowStep(flow);
        
        this._processFlow(flow);
        
        if (this._exhaleStarted) {
            this._flowArr.push(flow);
        }
    }
    
    this._getDataTimeout = setTimeout($.proxy(function() {
        this._dataFetcher.getSpirometerData($.proxy(this.onGotData, this));
    }, this), 70);
};

/*
    Manage the test
*/
bcSpiroViewerTestManager.prototype._processFlow = function(flow) {
    // If this is exhalation
    if (flow > this._testParams.exhaleFlowLimit && !this._exhaleStarted) {
        this._view.updateTestClock(0);
        this._exhaleStarted = true;
        this._startTime = Date.now();
        this._cntInhale = 0;
        this._flowArr = [];
    }
    
    // Init the inhale count
    if (this._exhaleStarted && flow > this._testParams.exhaleFlowLimit ) {
        this._cntInhale = 0;
    }
    
    // if this is inhalation and the exhalation started 
    if (this._exhaleStarted  && flow < this._testParams.exhaleFlowLimit)  {
        this._cntInhale++
    }
    
    // Exhanlation. Update the clock and check if its a good test            
    if (this._exhaleStarted) {
        var secondsFromStart = Math.floor((Date.now() - this._startTime)/1000);
        this._view.updateTestClock(secondsFromStart);
            
        if (secondsFromStart === this._testParams.exhaleSecondsBeforeStart) {
            this._view.onGoodExhaleStateChanged(true);
        }
    }
    
    // No longer exhalation (not testing < inhaleFlow limit because exhale limit doesnt have to be equatl to inhale limit)
    if (flow < this._testParams.exhaleFlowLimit && this._exhaleStarted) {
        // If this is inhalation and we have enough inhalation points, stop the test
        if ((flow < this._testParams.inhaleFlowLimit) && (this._cntInhale > this._testParams.inhaleCountBeforeStop) && ((Date.now() - this._startTime) > this._testParams.exhaleSecondsBeforeStart*1000)) {
            this._endTime = Date.now();
            this.stop(false);
        } else if ((this._cntInhale > this._testParams.inhaleCountBeforeStop) && ((Date.now() - this._startTime) < this._testParams.exhaleSecondsBeforeStart*1000)) {
            // If the test didnt start but this is no longer exhalation
            this._view.updateTestClock(0);
            this._exhaleStarted = false;
            
        }
    }
};

/*
    Stop spirometer test
*/
bcSpiroViewerTestManager.prototype.stop = function(testCancelled) {
    this._spiroViewer.writeToLogFile("Spirometer test stop");
    
    this._view.toggleTestActive(false);
    this._isTestActive = false;
    
    this._exhaleStarted = false;
    
    this._view.drawLiveFlowStep(0);
    
    if (this._spiroType == this._spiroViewer.spiroTypes.POND) {
        this._pitchManager.stop();
    }
    this._dataFetcher.stopSpirometer()
    
    clearTimeout(this._getDataTimeout);

    if (!testCancelled) {
        this._spiroViewer.onTestDone({
            flowArr: this._flowArr,
            startTime: this._startTime,
            endTime: this._endTime
        });
    }
};

/*
    Handle cancel test request
*/
bcSpiroViewerTestManager.prototype.cancelTest = function() {
    this._spiroViewer.cancelTest();
};

/*
    Handle window unload event
*/
bcSpiroViewerTestManager.prototype.onWindowUnload = function() {
   if (this._isTestActive) {
        this._dataFetcher.stopSpirometer();
    }
};

/*
    Handle test timeout event.
    Timeout can occur when pitch is coming from the server and not from JS
*/
bcSpiroViewerTestManager.prototype._onTestTimeout = function() {
    this._spiroViewer.writeToLogFile("Spirometer test timeout");
    
    this._dataFetcher.stopSpirometer();
   
    this._spiroViewer.onTestTimeout();
};


/*
    Indicates if test is in progress
*/
bcSpiroViewerTestManager.prototype.isTestActive = function() {
    return this._isTestActive;
};

window.bcSpiroViewerTestManager = bcSpiroViewerTestManager;

}
import React from 'react'
import { useLocation } from '@reach/router' // eslint-disable-line import/no-unresolved
import AppWrapper from '../components/AppWrapper'
import SignUp from '../components/Auth/SignUp'
import ConfirmSignUp from '../components/Auth/ConfirmSignUp'
import SignIn from '../components/Auth/SignIn'
import ForgetPassword from '../components/Auth/ForgetPassword'
import { Authenticator, UsernameAttributes } from 'aws-amplify-react'
import { IS_BROWSER } from '../../utils/utils'

const Index = () => {
  const { pathname } = useLocation()
  const isCanBePublicPath =
    (pathname.indexOf('/study') > -1 && pathname.indexOf('/assign') === -1) ||
    pathname.indexOf('/viewer') > -1
  const isSignup = pathname === '/signup'
  // need the theme for standalone viewer to be full screen
  if (!IS_BROWSER) return null
  return (
    <Authenticator
      authState={
        isCanBePublicPath ? 'publicPath' : isSignup ? 'signUp' : 'signIn'
      }
      hideDefault
      usernameAttributes={UsernameAttributes.EMAIL}
      theme={{ container: { height: '100%' } }}
    >
      <SignUp />
      <ConfirmSignUp />
      <SignIn />
      <ForgetPassword />
      <AppWrapper />
    </Authenticator>
  )
}

export default Index

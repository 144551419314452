import React, { useState } from 'react'
import Modal from '../Modal'
import { gql, useMutation, useApolloClient } from '@apollo/client'
import {
  GET_REFERRALS_QUEUES_CONFIG,
  GET_ADMIN_DATA_FROM_USER_EMAIL
} from '../queries'
import { REFERRAL_TYPES_CONVERTER } from '../../../utils/utils'
import { useLocalization } from '../LocalizationProvider'
import clsx from 'clsx'

const GraphqlSchemaEnums = JSON.parse(process.env.GraphqlSchemaEnums)

const ADD_TO_REFERRAL_QUEUE = gql`
  mutation ADD_TO_REFERRAL_QUEUE(
    $id: ID!
    $queue: QueueConnectionInput!
    $referralType: ReferralType
    $isAdmin: Boolean!
    $createdAt: AWSDateTime!
  ) {
    userAddToReferralsQueue(
      id: $id
      queue: $queue
      referralType: $referralType
      isAdmin: $isAdmin
      createdAt: $createdAt
    ) {
      id
    }
  }
`

const AddToReferralsQueue = ({ queuesData, onExit, onComplete }) => {
  const { locale } = useLocalization()
  const [selectedQueueIndex, setSelectedQueueIndex] = useState(-1)
  const [inProgress, setProgress] = useState(false)
  const [queueIdInput, setQueueIdInput] = useState('')
  const [queueNameInput, setQueueNameInput] = useState('')
  const [queueTypeInput, setQueueTypeInput] = useState(
    GraphqlSchemaEnums.QueueType[0]
  )
  const [caregiverEmailInput, setCaregiverEmailInput] = useState('')
  const [isQueueAdminInput, setIsQueueAdminInput] = useState(false)
  const [typeInput, setTypeInput] = useState(-1)
  const [result, setResult] = useState(null)

  const client = useApolloClient()

  const [userAddToReferralsQueue, { loading: isAdding }] = useMutation(
    ADD_TO_REFERRAL_QUEUE,
    {
      refetchQueries: [
        {
          query: GET_REFERRALS_QUEUES_CONFIG
        }
      ],
      awaitRefetchQueries: true
    }
  )

  async function submit() {
    setProgress(true)
    setResult(null)
    try {
      const {
        data: {
          admin: { userByEmail }
        }
      } = await client.query({
        query: GET_ADMIN_DATA_FROM_USER_EMAIL,
        variables: {
          email: caregiverEmailInput
        }
      })

      if (userByEmail) {
        let variables = {
          id: userByEmail.id,
          queue: {
            id: queueIdInput,
            name: queueNameInput,
            queueType: queueTypeInput
          },
          isAdmin: isQueueAdminInput,
          createdAt: new Date().toISOString()
        }

        if (!isQueueAdminInput) {
          variables.referralType = typeInput
        }

        await userAddToReferralsQueue({
          variables
        })
        onComplete()
      } else {
        setResult("Can't find the user")
      }
    } catch (e) {
      setResult('Make sure the email is valid.')
    }
    setProgress(false)
  }

  return (
    <Modal onClose={onExit} onSubmit={submit} inProgress={inProgress}>
      <div className='field is-horizontal'>
        <div className='field-label'>
          <label className='label has-text-left'>Queue:</label>
        </div>
        <div className='field-body'>
          <div className='field'>
            <div className='select'>
              <select
                value={selectedQueueIndex}
                onChange={(e) => {
                  setSelectedQueueIndex(e.target.value)
                  setQueueIdInput(
                    e.target.value != '-1'
                      ? queuesData.referralsQueues[e.target.value].id
                      : ''
                  )
                  setQueueNameInput(
                    e.target.value != '-1'
                      ? queuesData.referralsQueues[e.target.value].name
                      : ''
                  )
                  setQueueTypeInput(
                    e.target.value != '-1'
                      ? queuesData.referralsQueues[e.target.value].queueType
                      : false
                  )
                }}
              >
                <option value='-1'>New queue</option>
                {queuesData.referralsQueues.map(({ id, name }, index) => (
                  <option value={index} key={id}>
                    {name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </div>
      <div className='field is-horizontal'>
        <div className='field-label'>
          <label className='label has-text-left'>Queue id:</label>
        </div>
        <div className='field-body'>
          <div className='field'>
            <input
              className='input'
              type='text'
              placeholder='queue id'
              value={queueIdInput}
              disabled={selectedQueueIndex != '-1'}
              onChange={(e) => setQueueIdInput(e.target.value)}
            ></input>
          </div>
        </div>
      </div>
      <div className='field is-horizontal'>
        <div className='field-label'>
          <label className='label has-text-left'>Queue name:</label>
        </div>
        <div className='field-body'>
          <div className='field'>
            <input
              className='input'
              type='text'
              placeholder='queue name'
              disabled={selectedQueueIndex != '-1'}
              value={queueNameInput}
              onChange={(e) => setQueueNameInput(e.target.value)}
            ></input>
          </div>
        </div>
      </div>
      <div className='field is-horizontal'>
        <div className='field-label'>
          <label className='label has-text-left'>Queue type:</label>
        </div>
        <div className='field-body'>
          <div className='field'>
            <div className='select'>
              <select
                value={queueTypeInput}
                disabled={selectedQueueIndex != '-1'}
                onChange={(e) => {
                  setQueueTypeInput(e.target.value)
                }}
              >
                {GraphqlSchemaEnums.QueueType.map((type) => (
                  <option value={type} key={type}>
                    {type}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </div>
      <div className='field is-horizontal'>
        <div className='field-label'>
          <label className='label has-text-left'>Caregiver email:</label>
        </div>
        <div className='field-body'>
          <div className='field'>
            <div className='control'>
              <input
                className='input'
                type='text'
                placeholder='Caregiver email'
                value={caregiverEmailInput}
                onChange={(e) => setCaregiverEmailInput(e.target.value)}
              ></input>
            </div>
          </div>
        </div>
      </div>
      <div className='field is-horizontal'>
        <div className='field-label'>
          <label className='label has-text-left'>Is queue admin:</label>
        </div>
        <div className='field-body'>
          <div className='field'>
            <div className='control'>
              <label className='checkbox'>
                <input
                  type='checkbox'
                  checked={isQueueAdminInput}
                  onChange={(e) => setIsQueueAdminInput(e.target.checked)}
                  className='mr-2'
                />
              </label>
            </div>
          </div>
        </div>
      </div>

      {!isQueueAdminInput && (
        <div className='field is-horizontal'>
          <div className='field-label'>
            <label className='label has-text-left'>Referral type:</label>
          </div>
          <div className='field-body'>
            <div className='field'>
              <div className='control'>
                <div className='select'>
                  <select
                    value={typeInput}
                    onChange={(e) => setTypeInput(e.target.value)}
                  >
                    <option disabled='disabled' value='-1'>
                      Select type
                    </option>
                    {GraphqlSchemaEnums.ReferralType.map((type) => (
                      <option value={type} key={type}>
                        {locale.studies.reports[REFERRAL_TYPES_CONVERTER[type]]}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className='level'>
        <div className='level-left'>
          <div className='level-item'>
            <label className='has-text-danger-dark'>{result}</label>
          </div>
        </div>
        <div className='level-right'>
          <div className='level-item'>
            <button
              className={clsx('button is-primary', {
                'is-loading': inProgress || isAdding
              })}
              onClick={async () => submit()}
            >
              ok
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default AddToReferralsQueue

import React, { useState, useEffect, useContext } from 'react'
import { useQuery } from '@apollo/client'
import { Link } from '@reach/router' // eslint-disable-line import/no-unresolved
import AuthContext from './AuthContext'
import ReportCore from './ReportCore'
import { GET_STUDY_DATA, GET_REPORT_DATA } from './queries'
import { RECORDS_PRIORITIES } from '../../utils/utils'
import { useLocalization } from './LocalizationProvider'
import Modal from './Modal'
import AuthScenery from './Auth/AuthScenery'

const Report = ({ studyId, reportId: _reportId, location, gotoLoginPage }) => {
  const [reportId, setReportId] = useState(_reportId)
  const currentUser = useContext(AuthContext)
  const { locale } = useLocalization()
  useEffect(() => {
    setReportId(_reportId)
  }, [_reportId])
  const {
    loading: loadingStudy,
    error: errorStudy,
    data: dataStudy
  } = useQuery(GET_STUDY_DATA, {
    variables: {
      id: studyId,
      clinicId: currentUser.clinic.id,
      referralsConfigId: currentUser.group,
      hasGroup: !!currentUser.group
    }
  })
  const {
    loading: loadingReport,
    error: errorReport,
    data: dataReport
  } = useQuery(GET_REPORT_DATA, {
    variables: {
      reportId: reportId || studyId,
      hasReport: !!reportId
    }
  })
  const loading = loadingStudy || loadingReport
  if (loading) {
    return (
      <div id='page_wrapper'>
        <div className='main'>
          <div id='main'>
            <div id='loader_wrapper'>
              <div id='loader' />
            </div>
          </div>
        </div>
      </div>
    )
  }
  const error = errorStudy || errorReport
  if (error) {
    return (
      <div
        className='is-flex is-flex-direction-column is-justify-content-center is-align-items-center'
        style={{ height: '100%' }}
      >
        {error.message === 'AccessDenied' ? (
          <>
            <p>{locale.access_denied_report_line1}</p>
            <p>{locale.access_denied_line2}</p>
            <a onClick={() => gotoLoginPage()}>{locale.profile.login}</a>
          </>
        ) : (
          <p>{locale.something_wrong}</p>
        )}
      </div>
    )
  }
  if (
    !dataStudy ||
    !dataStudy.study ||
    (reportId && (!dataReport || !dataReport.report))
  ) {
    return (
      <AuthScenery>
        <Modal standalone={false}>
          <p className='has-text-centered has-text-weight-bold mb-4'>
            {!dataStudy || !dataStudy.study
              ? locale.study_not_found
              : locale.report_not_found}
          </p>
          <p className='has-text-centered'>
            <Link to='/' className='button is-primary is-outlined'>
              {locale.back_to_studies}
            </Link>
          </p>
        </Modal>
      </AuthScenery>
    )
  }
  return (
    <ReportCore
      currentUser={currentUser}
      study={{
        ...dataStudy.study,
        records: [...dataStudy.study.records].sort(
          (e1, e2) =>
            RECORDS_PRIORITIES[e1.__typename] -
            RECORDS_PRIORITIES[e2.__typename]
        )
      }}
      report={dataReport && dataReport.report}
      location={location}
      setReportId={setReportId}
    />
  )
}

export default Report

if (typeof window !== 'undefined') {

/*
  Handles singal processing for ECG

  cx - requested width (px)
  cy - requested height (px)
  rx - duration of one pixel (ms)
  ry - amplitude of one pixel (mV)
  startTime - requested start time (ms)
*/
function SignalProcessing() {
    this._outParams; /* Parameters for the requested signal */
    this._inParams;  /* Parameters for the input signal */
    this._inSignal;  /* The input signal */
    this._processors = {}; 
    this._preProcessors = ['FlipSignal', 'HighPassFilter', 'SmoothFilter', 'BaselineFilter', 'Expand3To7', 'Expand3To12', 'Expand8To12', 'Expand2To6']; /* All the processors to run before resampling */ 
    this._postProcessors = ['AdjustYResolution', 'AdjustChannelOffset']; /* All the processors to run after resampling */

    this.preProcessors; // List of pre processors without turned off filters
    this.postProcessors; // List of post processors without turned off filters
    this._preMinimumSamples; // Number of samples needed for pre filters calculations
    this._postMinimumSamples; // Number of samples needed for post filters calculations
    this.postProccessFunc; // Function to handle array of vectors according to resampling type

    this._defaultSamplingRate = 400;

    /* Initiate all processors instances */
    for (var i in SignalProcessing.processors) {
        if (i === 'BaseProcessor') continue; // Ignore the base class
        this._processors[i] = new SignalProcessing.processors[i];
    }

    this._ittNum = 0; // index of processed vector
    this._isOutputSignalInitDone = false; // Indicates if output signal init is done
}

/*
    Set the input data - signal and metadata
*/
SignalProcessing.prototype.setInputSignal = function(signal, metadata) {
    this._inParams = {
        numberOfChannels: metadata.numberOfChannels,
        samplingRate: metadata.samplingRate || this._defaultSamplingRate,
        numberOfSamples: metadata.numberOfSamples, 
        ry: metadata.ry
    };
    this._inParams.rx = 1000 / this._inParams.samplingRate;

    if (this._inParams.ry === 0) {
        throw "ry cann't be set to zero";
    }
    if (signal[0].length === 0) {
        throw "Input signal must have at least one channel";
    }
    
    this._inSignal = signal;
};

/*
    Calulate the out params from params
*/
SignalProcessing.prototype.prepareOutParams = function(params, inSignal) {
    this._outParams = $.extend({}, true, params);
    
    if (this._outParams.rx === 0) {
        throw "rx cann't be set to zero";
    }

    // The requested channel (e.g be [0, 3])
    if (params.requestedChannels) {
        this._outParams.requestedChannels = params.requestedChannels;
    } else {
        var len = inSignal[0].length;
        this._outParams.requestedChannels = [];
        if ((this._processors.Expand3To7.getConfig().enabled === true) && 
            (len === 3)) {
            len = 7;
        }
        if ((this._processors.Expand3To12.getConfig().enabled === true) && 
            (len === 3)) {
            len = 12;
        }
        if ((this._processors.Expand8To12.getConfig().enabled === true) && 
            (len === 8)) {
            len = 12;
        }
        if ((this._processors.Expand2To6.getConfig().enabled === true) && 
            (len === 3 || len === 2)) { // 3 beacuse the device actually returns 3 channels
            len = 6;
        }
        for (var i=0; i<len; i++) {
            this._outParams.requestedChannels.push(i);
        }
    }
    this._outParams.samplingRate = 1000 / this._outParams.rx; 
};

/*
    Init processors pipelines
*/
SignalProcessing.prototype.initProcessors = function(inSignal) {
    this._preMinimumSamples = this._initProcessorsPipeline(this._preProcessors, inSignal);
    this._initProcessorsPipeline(['Resampling'], inSignal);
    this._postMinimumSamples = this._initProcessorsPipeline(this._postProcessors, inSignal);
    
    // Get specific function to handle vector or array of vectors according to resampling type
    this.postProccessFunc = this._getPostProcessFunc();
};

/*
    Get the opuput signals according to the requested params
*/
SignalProcessing.prototype.getOutputSignal = function(params, isContinuousSignal) {
    // Making sure we're not changing the original _inSignal
    var inSignal = this._inSignal.map(function(arr) { return arr.slice(); });

    if (!isContinuousSignal || !this._isOutputSignalInitDone) {
        this._isOutputSignalInitDone = true;
        this.prepareOutParams(params, inSignal);
        this.initProcessors(inSignal);
    }
    
    // Remove disabled processors - we're only doing it after init because we need
    // to get the minimumNumberOfSamples from all the processors, even the disabled ones
    this.preProcessors = this._removeDisabledProcessors(this._preProcessors);
    this.postProcessors = this._removeDisabledProcessors(this._postProcessors);

    var resampleProccesor = this._processors.Resampling;

    // set sampleNum to the starting sample 
    var sampleNum = (!isContinuousSignal) ? Math.round(this._outParams.startTime / this._inParams.rx) : 0; 
    
    if (!isContinuousSignal) {
        this._ittNum = 0;
    }

    var outSignal = [];
    while ((isContinuousSignal && (sampleNum < inSignal.length)) ||
           (!isContinuousSignal && (outSignal.length < this._outParams.cx))) {
        this._ittNum++;

        // Can't get more data then available in the input signal
        if (!isContinuousSignal && (sampleNum >= inSignal.length)) break; 

        // vector is array in the length of numberOfChannels
        var vector = inSignal[sampleNum]; 
        sampleNum++;

        // Run all the pre-processors on vector
        vector = this._processVector(this.preProcessors, vector);

        if (this._ittNum < this._preMinimumSamples) continue;
        
        // Resampling - can return vector, array of vectors or null
        vector = resampleProccesor.processVector(vector);
        if (!vector) continue;

        // Run all post-processors on vector
        this.postProccessFunc.call(this, this.postProcessors, vector, outSignal);

        if (this._ittNum < this._postMinimumSamples) outSignal = [];
    }

    return outSignal;
};

/*
    Configure a processor
*/
SignalProcessing.prototype.configProcessor = function(processorName, configObj) {
    if (!this._processors[processorName]) return;
    this._processors[processorName].config(configObj);
};

/*
    Init processors pipeline
*/
SignalProcessing.prototype._initProcessorsPipeline = function(processPipeline, inSignal) {
    var minimumNumberOfSamplesNeeded = 0;
    for (var i=0; i<processPipeline.length; i++) {
        var processorName = processPipeline[i];
        var processor = this._processors[processorName];
        processor.init(this._inParams, this._outParams, inSignal);
        // We summarize the minimumNumber of sameples needed because bl and sm filters return the needed shift and not the minimum shift 
        minimumNumberOfSamplesNeeded += processor.minimumNumberOfSamplesNeeded(); //Math.max(minimumNumberOfSamplesNeeded, processor.minimumNumberOfSamplesNeeded());
    }
    return minimumNumberOfSamplesNeeded;
};

/*
    Remove disabled processors from the pipeline
*/
SignalProcessing.prototype._removeDisabledProcessors = function(processPipeline) {
    var outPipeline = [];
    for (var i=0; i<processPipeline.length; i++) {
        var processorName = processPipeline[i];
        if (this._processors[processorName].getConfig().enabled) {
            outPipeline.push(processorName);
        }
    }
    return outPipeline;
};

/*
    Run vector through a processors pipeline
*/
SignalProcessing.prototype._processVector = function(processPipeline, vector) {
    for (var i=0; i<processPipeline.length; i++) {
        vector = this._processors[processPipeline[i]].processVector(vector);
    }
    return vector;
};

/*
    Return a function that runs processors pipeline on vector or array of vectors
    according to the resampling type 
*/
SignalProcessing.prototype._getPostProcessFunc = function() {
    var postProccessFunc;
    var resampleProccesor = this._processors.Resampling;
    if (resampleProccesor.isDownSampling()) {
        // If we're down sampling then we get back one vector at a time
        postProccessFunc = function(processPipeline, vector, outSignal) {
            outSignal.push(this._processVector(processPipeline, vector));
        };
    } else {
        // If we're up sampling then we get back array of vectors 
        postProccessFunc = function(processPipeline, vectorArr, outSignal) {
            for (var i=0; i<vectorArr.length; i++) {           
                outSignal.push(this._processVector(processPipeline, vectorArr[i]));
            }
        };
    }
    return postProccessFunc;
};

/*
-----------------------------------------------
    Processors section
-----------------------------------------------
*/

SignalProcessing.processors = {};

/*
    Processors base class
*/
SignalProcessing.processors.BaseProcessor = bcViewer.Class.extend({ 
    construct: function() {
        this._config = {
            enabled: true
        }
    },

    init: function(inParams, outParams, inSignal) {
    },

    config: function(obj) {
        for (var key in obj) {
            this._config[key] = obj[key];
        }
    },

    getConfig: function() {
        return this._config;
    },

    minimumNumberOfSamplesNeeded: function() {
        return 0;
    },

    processVector: function(vector) {
    }
});

/*
    Flipping the signal from classical coordinates to screen coordinates
*/
SignalProcessing.processors.FlipSignal = SignalProcessing.processors.BaseProcessor.extend({
    processVector: function(vector) {
        for (var i=0; i<vector.length; i++) {
            vector[i] = vector[i]*-1;
        }
        return vector;
    }
});

/*
    High pass filter processor
*/
SignalProcessing.processors.HighPassFilter = SignalProcessing.processors.BaseProcessor.extend({
    construct: function() {
        this._super.apply(this, arguments);

        //Transient response time ecg standard want 3.2 sec (we use much more - 5 seconds)
        this._transientResponseTime = 5;
    },

    init: function(inParams, outParams, inSignal) {
        var transientResponse = inParams.samplingRate * this._transientResponseTime;
        this._alpha = Math.exp(-1 / transientResponse);
        this._beta = 1 - this._alpha;
        this._lastVector = inSignal[0];
    },

    // Filter the vector
    processVector: function(vector) {
        var outVector = [];
        for (var i=0; i<vector.length; i++) {
            this._lastVector[i] = this._alpha*this._lastVector[i] + this._beta*vector[i];
            outVector[i] = vector[i] - this._lastVector[i];
        }
        return outVector;
    }
});

/*
    Resampling processor
*/
SignalProcessing.processors.Resampling = SignalProcessing.processors.BaseProcessor.extend({
    init: function(inParams, outParams, inSignal) {
        this._isDownSampling = (inParams.samplingRate > outParams.samplingRate);
        // Set the resampling funtioc to be upSample or downSample
        this._resamplingFunc = this._isDownSampling ? this.downSample : this.upSample;
        this._step = outParams.rx / inParams.rx;
        this._inRX = inParams.rx;
        this._outRX = outParams.rx;

        this._prevVector;
        this._currentPosition;
        if (this._isDownSampling) {
            this._initDownSampling(inSignal[0].length);
        } 
    },

    // Init the down sampling parameters
    _initDownSampling: function(numberOfChannels) {
        this._step = Math.round(this._step);
        this._inVectorNum = 0;
        this._outVectorNum = 0;
        
        this._currentVector = [];
        // The current min/max values
        this._min = [];
        this._max = [];
        // Did we encounter max recently
        this._wasMaxFoundRecent = []; 
        this._reverse = [];
        // Previous min/max values
        this._prevMin = [];
        this._prevMax = [];
        // Init values
        for (var i=0; i<numberOfChannels; i++) {
            this._min.push(Number.MAX_VALUE);
            this._max.push(-Number.MAX_VALUE);
            this._prevMin.push(Number.MAX_VALUE);
            this._prevMax.push(-Number.MAX_VALUE);
            this._wasMaxFoundRecent.push(false);
            this._reverse.push(false);
        }
    },

    // Returns true if we're down sampling
    isDownSampling: function() {
        return this._isDownSampling;
    },

    // Updated min/max values with the current vector
    _updateMinMax: function(vector) {
        for (var i=0; i<vector.length; i++) {
            var val = vector[i];
            if (val > this._max[i]) {
                this._max[i] = val;
                this._wasMaxFoundRecent[i] = true;
            } 
            if (val < this._min[i]) {
                this._min[i] = val;
                this._wasMaxFoundRecent[i] = false;
            }
        }
    },

    // Get an output vector for down sampling
    _getDownSampledVector: function(vector) {
        var outVector = [];
        for (var i=0; i<vector.length; i++) {
            if ((this._max[i] > this._prevMax[i]) && (this._min[i] < this._prevMin[i])) {
                if (this._wasMaxFoundRecent[i]) {
                   this._currentVector[i] = this._min[i];
                } else {
                   this._currentVector[i] = this._max[i];
                   this._reverse[i] = true;
                }
            } else {
                if (this._max[i] > this._prevMax[i]) {
                    this._min[i] = this._prevMax[i];
                } 
                if (this._min[i] < this._prevMin[i]) {
                    this._max[i] = this._prevMin[i];
                    this._reverse[i] = true;
                }
            }

            outVector[i] = this._currentVector[i];

            this._currentVector[i] = this._reverse[i] ? this._min[i] : this._max[i];
            this._prevMax[i] = this._max[i];
            this._prevMin[i] = this._min[i];
            this._min[i] = Number.MAX_VALUE;
            this._max[i] = -Number.MAX_VALUE;
            this._reverse[i] = false;
        }
        return outVector;
    },

    // Handle down sampling
    downSample: function(vector) {
        var outVector = null;
        this._inVectorNum++;
        this._updateMinMax(vector);

        if (this._currentVector.length === 0) {
            this._currentVector = vector;
        }

        // current vector time
        var inTime = this._inVectorNum * this._inRX;

        // next output vector time
        var outTime = this._outVectorNum * this._outRX;
        
        // if current input vector time > next output vector time
        if (inTime > outTime) {
            outVector = this._getDownSampledVector(vector); 
            this._outVectorNum++;
        }

        return outVector;
    },

    // Handle up sampling
    upSample: function(vector) {
        // Edge case - handle first vector
        if (typeof this._currentPosition === 'undefined') {
            this._currentPosition = this._step;
            this._prevVector = vector;
            return vector;
        }
        
        var outVector = [];
        for (var pos=this._currentPosition; pos<1; pos+=this._step) {
            var tempVector = [];
            for (var i=0; i<vector.length; i++) {
                tempVector[i] = vector[i] + (vector[i] - this._prevVector[i])*pos;
            }
            outVector.push(tempVector);
        }
        this._prevVector = vector;
        // Make sure next vector starts at the correct position
        this._currentPosition = pos-1;
        return outVector;
    },

    // Perform resampling
    processVector: function(vector) {
        return this._resamplingFunc(vector);
    }
});

/*
    Adjusting the Y resolution processor
*/
SignalProcessing.processors.AdjustYResolution = SignalProcessing.processors.BaseProcessor.extend({
    // Init parameters
    init: function(inParams, outParams, inSignal) {
        // Calculate the ratio betwen ry output to input and change the
        // untis from mv per pixels to pixels per mv
        this._coeff = 1000 / outParams.ry / inParams.ry;
    },

    // Adjust the Y return according to the coefficient
    processVector: function(vector) {
        var outVector = [];
        for (var i=0; i<vector.length; i++) {
            outVector[i] = vector[i] * this._coeff;
        }
        return outVector;
    }
});

/*
    Adjust the channel offsets
*/
SignalProcessing.processors.AdjustChannelOffset = SignalProcessing.processors.BaseProcessor.extend({
    // Init parameters
    init: function(inParams, outParams, inSignal) {
        this._requestedChannels = outParams.requestedChannels;
        this._offset = [];
        // Look at the requested number of channels
        var numberOfChannels = this._requestedChannels.length;
        // Pre calculate the offsets array
        var temp = outParams.cy / (numberOfChannels*2);
        for (var i=0; i<numberOfChannels; i++) {
            this._offset[i] = temp * (i*2+1);
        }
        if (numberOfChannels > 1) {
            this._offset[0] += temp/10;
            this._offset[numberOfChannels-1] -= temp/10;
        }
    },

    // Adjust the channel offsets
    // This is somewhat hacky but I'm using the requested channels parameter (outParams.channels)
    // to skip certain channels and output/offset only the requested channels
    processVector: function(vector) {
        var outVector = [];
        for (var i=0; i<this._requestedChannels.length; i++) {
            var channelNumber = this._requestedChannels[i];
            outVector[i] = vector[channelNumber] + this._offset[i];
        }
        return outVector;
    }
});

/*
    Generic average processor
*/
SignalProcessing.processors.Average = SignalProcessing.processors.BaseProcessor.extend({
    // Constructor 
    // width - the window width
    construct: function(width) {
        this._super.apply(this, arguments);
        if (width === 0) {
            throw "Average width can't be set to zero";
        }
        this._windowWidth = width;
    },

    // Init the processor
    init: function(vectorLength) {
        // Kepp an array of vectors 
        this._buffer = [];
        // Keep the sum of vectors
        this._bufferSum = [];
        // Pointer to current buffer location
        this._currentPointer = 0;

        // Init buffer and sum 
        for (var i=0; i<vectorLength; i++) {
            this._buffer[i] = new Array(this._windowWidth);
            this._bufferSum[i] = 0;
            for (var j=0; j<this._buffer[i].length; j++) {
                this._buffer[i][j] = 0;
                this._bufferSum[i] += this._buffer[i][j];
            }
        }
    },

    // Return the current average buffer
    getBuffer: function() {
        return this._buffer;
    },

    // Return the middle vector - the vector which we currently calculating the average for
    getMidVector: function() {
        var midPos = Math.floor((this._currentPointer + (this._windowWidth/2)) % this._windowWidth)
        var outVector = [];
        for (var i=0; i<this._buffer.length; i++) {
            outVector.push(this._buffer[i][midPos]);
        }
        return outVector;
    },

    // Perform averaging
    processVector: function(vector) {
        var outVector = [];

        for (var i=0; i<vector.length; i++) {
            this._bufferSum[i] -= this._buffer[i][this._currentPointer];
            this._bufferSum[i] += vector[i];
            this._buffer[i][this._currentPointer] = vector[i];
            outVector[i] = this._bufferSum[i] / this._windowWidth;
        }
        this._currentPointer = (this._currentPointer + 1) % this._windowWidth;
        return outVector;
    }
});

/*
    Handle smooth filter
*/
SignalProcessing.processors.SmoothFilter = SignalProcessing.processors.BaseProcessor.extend({
    // Constructor
    construct: function() {
        this._super.apply(this, arguments);
        this._smoothCutoff = 50;
        this._filterGain = 1;
    },

    // Init parameters
    init: function(inParams, outParams, inSignal) {
        this._filterGain = Math.round(inParams.samplingRate / this._smoothCutoff);
        this._avgFilter = new SignalProcessing.processors.Average(this._filterGain);
        this._avgFilter.init(inSignal[0].length);
    },

    // Return the number of minimum required samples
    minimumNumberOfSamplesNeeded: function() {
        return this._config.enabled ? this._filterGain : Math.round(this._filterGain/2);
    },

    // Perform sooth filter
    processVector: function(vector) {
        return this._avgFilter.processVector(vector);
    }
});

/*
    Handle baseline filter
*/
SignalProcessing.processors.BaselineFilter = SignalProcessing.processors.BaseProcessor.extend({
    // Init parameters
    init: function(inParams, outParams, inSignal) {
        this._width = inParams.samplingRate;
        this._avgFilter = new SignalProcessing.processors.Average(this._width);
        this._avgFilter.init(inSignal[0].length);
    },

    // Return the number of minimum required samples
    minimumNumberOfSamplesNeeded: function() {
        return this._config.enabled ? this._width : Math.round(this._width/2);
    },

    // Perform baseline filter
    processVector: function(vector) {
        var outVector = [];
        var midVector = this._avgFilter.getMidVector();
        var avgVector = this._avgFilter.processVector(vector);
        for (var i=0; i<vector.length; i++) {
            outVector[i] = midVector[i] - avgVector[i];
        }

        return outVector;
    }
});

/* 
    Calculate 3 to 7 channels
*/
SignalProcessing.processors.Expand3To7 = SignalProcessing.processors.BaseProcessor.extend({
    processVector: function(vector) {
        var outVector = [
            vector[0],
            vector[1],
            vector[1]-vector[0],
            -(vector[0]+vector[1])/2,
            vector[0]-vector[1]/2,
            vector[1]-vector[0]/2,
            vector[2] 
        ];
        return outVector;
    }
});

/* 
    Calculate 2 to 6 channels
*/
SignalProcessing.processors.Expand2To6 = SignalProcessing.processors.BaseProcessor.extend({
    processVector: function(vector) {
        var outVector = [
            vector[0],
            vector[1],
            vector[1]-vector[0],
            -(vector[0]+vector[1])/2,
            vector[0]-vector[1]/2,
            vector[1]-vector[0]/2
        ];
        return outVector;
    }
});

/*
AI(X)
ES(Y)
AS(Z)
*/
var matrix  = [
   [0.701,    0.026,  -0.174],
   [-0.763,  -0.002,   1.098],
   [-1.464,  -0.028,   1.272],
   [0.031,   -0.012,  -0.462],
   [1.082,    0.027,  -0.723],
   [-1.114,  -0.015,   1.185],
   [0.080,    0.641,  -0.391],
   [1.021,    1.229,  -1.050],
   [0.987,    0.947,  -0.539],
   [0.841,    0.525,   0.004],
   [0.630,    0.179,   0.278],
   [0.213,   -0.043,   0.431]
];

/* 
    Calculate 3 to 12 channels
*/
SignalProcessing.processors.Expand3To12 = SignalProcessing.processors.BaseProcessor.extend({
    processVector: function(vector) {
        var outVector = [];
        for (var i = 0; i < 12; i++) {
            outVector[i] = parseInt (
                        matrix[i][0] * vector[0]
                            + matrix[i][1] * vector[1]
                            + matrix[i][2] * vector[2]);
            }

        return outVector;
    }
});

/* 
    Calculate 8 to 12 channels
*/
SignalProcessing.processors.Expand8To12 = SignalProcessing.processors.BaseProcessor.extend({
    processVector: function(vector) {
        var outVector = [
            vector[0],
            vector[1],
            vector[1]-vector[0],
            -(vector[0]+vector[1])/2,
            vector[0]-vector[1]/2,
            vector[1]-vector[0]/2,
            vector[2], 
            vector[3], 
            vector[4], 
            vector[5], 
            vector[6], 
            vector[7] 
        ];
        return outVector;
    }
});

SignalProcessing.prototype.getPreMinimumSamples = function() {
    var minimumNumberOfSamplesNeeded = 0;
    for (var i = 0; i < this._preProcessors.length; i++) {
        var processorName = this._preProcessors[i];
        var processor = this._processors[processorName];
        processor.init(this._inParams, this._outParams, this._inSignal);
        minimumNumberOfSamplesNeeded += processor.minimumNumberOfSamplesNeeded();
    }
    return minimumNumberOfSamplesNeeded;
};

window.SignalProcessing = SignalProcessing

}
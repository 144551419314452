import React from 'react'
import clsx from 'clsx'
import Modal from '../Modal'
import AuthScenery from './AuthScenery'
import { useLocalization } from '../LocalizationProvider'
import { SCHEMA_LOCALE_TO_COOKIE_LOCALE } from '../../../utils/utils'

export default function SignInCore({
  handleInputChange,
  // changeState,
  signIn,
  inProgress,
  error
}) {
  const { locale, localeKey } = useLocalization()

  return (
    <AuthScenery>
      <Modal onSubmit={signIn} standalone={false}>
        <div className='field'>
          <label className='label is-medium'>{locale.login.signin_title}</label>
        </div>
        <hr />
        <div className='field'>
          <label className='label'>{locale.login.email_forced}</label>
          <div className='control'>
            <input
              key='email'
              type='text'
              name='email'
              id='email'
              className='input'
              onChange={handleInputChange}
              readOnly={inProgress}
            />
          </div>
        </div>
        <div className='field'>
          <label className='label'>{locale.login.password_forced}</label>
          <div className='control'>
            <input
              key='password'
              type='password'
              name='password'
              className='input'
              onChange={handleInputChange}
              readOnly={inProgress}
              autoComplete='current-password'
              id='current-password'
            />
          </div>
          <p className='help'>
            <span className='level'>
              <span className='level-left'>
                <span className='level-item'>
                  {locale.login.forgot_password}
                </span>
                {/* TODO: remove after full migration */}
                <button
                  className='button is-small is-inverted is-primary level-item'
                  onClick={async () => {
                    const form = document.createElement('form')
                    form.setAttribute(
                      'action',
                      `/${SCHEMA_LOCALE_TO_COOKIE_LOCALE[localeKey]}/user_session`
                    )
                    // Create an input element for Full Name
                    var input = document.createElement('input')
                    input.setAttribute('type', 'hidden')
                    input.setAttribute('name', '_method')
                    input.setAttribute('value', 'delete')
                    form.appendChild(input)
                    await window.fetch(form.action, {
                      method: 'post',
                      body: new FormData(form)
                    })
                    window.location.href = `/${SCHEMA_LOCALE_TO_COOKIE_LOCALE[localeKey]}/reset_password`
                  }}
                  disabled={inProgress}
                >
                  {locale.login.reset_password}
                </button>
                {/* TODO: remove after full migration */}
                {/* TODO: remove comment after full migration */}
                {/* <button
                  className='button is-small is-inverted is-primary level-item'
                  onClick={() => changeState('forgotPassword')}
                  disabled={inProgress}
                >
                  {locale.login.reset_password}
                </button> */}
                {/* TODO: remove comment after full migration */}
              </span>
            </span>
          </p>
        </div>
        <div className={clsx('field', { 'is-hidden': !error })}>
          <p className='help is-danger'>
            {error ? locale.profile.auth_errors.signin[error] || error : ''}
          </p>
        </div>
        <hr />
        <div className='field'>
          <div className='level'>
            <div className='level-left'>
              <span className='level-item'>
                {locale.login.dont_have_an_account}
              </span>
              <a
                className='button is-primary is-inverted level-item'
                href={`/${SCHEMA_LOCALE_TO_COOKIE_LOCALE[localeKey]}/account/new`}
                style={inProgress ? { pointerEvents: 'none' } : {}}
              >
                {locale.login.signup}
              </a>
              {/* <button
                className='button is-primary is-inverted level-item'
                onClick={() => changeState('signUp')}
                disabled={inProgress}
              >
                {locale.login.signup}
              </button> */}
            </div>
            <div className='level-right'>
              <button
                id='sign-in'
                className={clsx('button is-primary level-item', {
                  'is-loading': inProgress
                })}
                onClick={(e) => signIn(e)}
                disabled={inProgress}
              >
                {locale.login.signin}
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </AuthScenery>
  )
}

if (typeof window !== 'undefined') {

/*
    Utils - generic utilities
*/
bcViewer.Utils = function() {
    this._defaultThrottleDelay = 100;
    
    this.SCREEN_TYPES = {
    	DESKTOP: 'desktop',
    	TABLET: 'tablet',
    	PHONE: 'phone'
    };
};

/*
    Wrapper around jquery ajax in case we'll want to replace it 
*/
bcViewer.Utils.prototype.callAPI = function(config) {
    return $.ajax(config);
};

/*
    Return a new object containing only keys that appear in the filter list (array)
    example: 
    obj = { a: 1, b: 2, c: 3, d: 4 }
    filterList = ['b', 'd']

    return value:
    { b: 2, d: 4 }
*/                                                                  
bcViewer.Utils.prototype.filterObjectByKeys = function(obj, filterList) {
    var filteredObj = {};
    for (var i=0; i<filterList.length; i++) {
        var key = filterList[i];
        if (typeof obj[key] !== 'undefined') {
            filteredObj[key] = obj[key];
        }        
    }      
    return filteredObj;
};        

/*
    Capitalize first letter, e.g. test -> Test
*/
bcViewer.Utils.prototype.capitalizeFirstLetter = function(string)
{
    return string.charAt(0).toUpperCase() + string.slice(1);
};

/*
    format date from milliseconds
*/
bcViewer.Utils.prototype.formatDate = function(msecs, format) {
    var date = new Date(msecs);
    var day = date.getDate();
    var month = date.getMonth()+1;
    var year = date.getFullYear();

    var dateString = format;
    dateString = dateString.replace(/yyyy/, year);
    dateString = dateString.replace(/yy/, String(year).substr(2));
    dateString = dateString.replace(/MM/, this.zeroPadd(month));
    dateString = dateString.replace(/M/, month);
    dateString = dateString.replace(/dd/, this.zeroPadd(day));
    dateString = dateString.replace(/d/, day);
    
    
    return dateString; 
}

/*
    Foramt time from milliseconds
*/
bcViewer.Utils.prototype.formatTime = function(msecs, format) {
    var time = new Date(msecs);

    return this.getTimeString(time.getHours(), time.getMinutes(), time.getSeconds(), format);
}

/*
    Format time duration from milliseconds to HH:MM:SS
*/
bcViewer.Utils.prototype.formatTimeDuration = function(msecs, format) {
    var timeObj = this.normalizeTime(msecs);
   
    return this.getTimeString(timeObj.hours, timeObj.minutes, timeObj.seconds, format); 
};

/*
    return time string
*/
bcViewer.Utils.prototype.getTimeString = function(hours, minutes, seconds, format) {
    var timeString = format;
    
    timeString = timeString.replace(/HH/, this.zeroPadd(hours));
    timeString = timeString.replace(/MM/, this.zeroPadd(minutes));
    timeString = timeString.replace(/SS/, this.zeroPadd(seconds));

    return timeString;
}

/*
    Returns an object with hours, minutes and seconds
*/
bcViewer.Utils.prototype.normalizeTime = function(msecs) {
    var seconds = Math.floor(msecs / 1000),
    minutes = Math.floor(seconds / 60),
    hours   = Math.floor(minutes / 60);

    return {
        'seconds' : seconds % 60,
        'minutes' : minutes % 60,
        'hours'   : hours
    };
};

/*
    Add zero to a number if it's smaller than 10
    e.g. 9 -> 09, 3 -> 03, etc
*/
bcViewer.Utils.prototype.zeroPadd = function(num) {
    return ((num < 10) ? '0' : '') + num;
};

/*
    Check if the input is a number.
*/
bcViewer.Utils.prototype.isNumber = function(n){
  return !isNaN(parseFloat(n)) && isFinite(n);
};

/*
    Create a throttle event call
*/
bcViewer.Utils.prototype.throttle = function(scope, name, callback, delay) {
    var _delay = delay;
    if (typeof _delay === 'undefined') {
        _delay = this._defaultThrottleDelay;
    }
    
    if (typeof bcViewer.Utils.prototype.throttle[name] !== 'undefined') {
          clearTimeout(bcViewer.Utils.prototype.throttle[name]);
    }
    
    bcViewer.Utils.prototype.throttle[name] = setTimeout(function() { 
              delete bcViewer.Utils.prototype.throttle[name];
              callback.call(scope);
          }, _delay);
};

/*
   Get Object keys array 
*/
bcViewer.Utils.prototype.getObjectKeys = function(obj) {
    var keys = [];

    if (Object.keys) {
         keys = Object.keys(obj);
    } else {
        for (var k in obj) {
            keys.push(k);
        }
    }

    return keys;
};

/*
   Get scrollbars width
*/
bcViewer.Utils.prototype.getScrollbarsWidth = function() {
    var outer = document.createElement("div");
    outer.style.visibility = "hidden";
    outer.style.width = "100px";
    document.body.appendChild(outer);

    var widthNoScroll = outer.offsetWidth;
    // force scrollbars
    outer.style.overflow = "scroll";

    // add innerdiv
    var inner = document.createElement("div");
    inner.style.width = "100%";
    outer.appendChild(inner);        

    var widthWithScroll = inner.offsetWidth;

    // remove divs
    outer.parentNode.removeChild(outer);

    return widthNoScroll - widthWithScroll;
};

/*
	return the screen type
*/
bcViewer.Utils.prototype.getScreenType = function() {
    var userAgent = navigator.userAgent.toLowerCase();
        
    var screenType = this.SCREEN_TYPES.DESKTOP;
    
    var isAndroid = (userAgent.indexOf('android') > -1);
    var isMobile = (userAgent.indexOf('mobile') > -1);
    
    if (isAndroid) {
        if(isMobile) {
            screenType = this.SCREEN_TYPES.PHONE;
        } else {
            screenType = this.SCREEN_TYPES.TABLET;
        }
    }
    
    return screenType;
};

/*
    input: GMT time (milliseconds) and timezone offset (minutes) 
    output: time adjusted to timezone
*/
bcViewer.Utils.prototype.adjustTimeToTimezone = function(GMTTime, timezoneOffset) {
    var localTimezoneOffset = new Date(GMTTime).getTimezoneOffset();
    return  GMTTime + (localTimezoneOffset + timezoneOffset) * 60 * 1000;
};

/*
    $$ - get jquery wrapper on DOM element with cache
    TODO: do we want to pollute the global namespace? 

    forceNoCaching - if set to true results will be fresh from the DOM and not from cache
*/
function $$(selector, forceNoCaching) {
    if (forceNoCaching || (typeof $$[selector] === 'undefined')) {  
        $$[selector] = $(selector);
    }

    return  $$[selector];
};

/*
    get css property with no units
*/
jQuery.fn.cssNumber = function(prop){
    var val = parseFloat(this.css(prop));
    return isNaN(val) ? 0 : val;
};

/*
    add dummy tooltip util to jquery if needed
*/
if (typeof jQuery.fn.tooltip === 'undefined') {
	jQuery.fn.tooltip = function() {
	};
};

/*
    add slice to Int16Array if needed (IE)
*/
if (!Int16Array.prototype.slice) {
    //Returns a new Int16Array whose contents are a copy of this ArrayBuffer's
    //bytes from `begin`, inclusive, up to `end`, exclusive
     Int16Array.prototype.slice = function(begin, end) {
        //If `begin` is unspecified, Chrome assumes 0, so we do the same
        if (begin === void 0) {
            begin = 0;
        }

        //If `end` is unspecified, the new Int16Array contains all
        //bytes from `begin` to the end of this Int16Array.
        if (end === void 0) {
            end = this.byteLength;
        }

        //Chrome converts the values to integers via flooring
        begin = Math.floor(begin);
        end = Math.floor(end);

        //If either `begin` or `end` is negative, it refers to an
        //index from the end of the array, as opposed to from the beginning.
        if (begin < 0) {
            begin += this.byteLength;
        }
        if (end < 0) {
            end += this.byteLength;
        }

        //The range specified by the `begin` and `end` values is clamped to the 
        //valid index range for the current array.
        begin = Math.min(Math.max(0, begin), this.byteLength);
        end = Math.min(Math.max(0, end), this.byteLength);

        //If the computed length of the new Int16Array would be negative, it 
        //is clamped to zero.
        if (end - begin <= 0) {
            return new Int16Array();
        }
        
        var result = new Int16Array(end - begin);
        for (var i=begin;i<end;i++) {
            result[i-begin] = this[i];
        }
       
        return result;
    };
};

}
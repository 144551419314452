import React, { useState } from 'react'
import Modal from '../Modal'
import { gql, useMutation } from '@apollo/client'
import clsx from 'clsx'
import { objectToFieldsConverter } from '../../../utils/utils'

const UPDATE_USER_TYPE = gql`
  mutation UPDATE_USER_TYPE($input: UserUpdateInput!) {
    userUpdate(input: $input) {
      id
    }
  }
`

export default function ChangeUserType({ user, onExit, onComplete }) {
  const [userType, setUserType] = useState(user.userType)
  const [isCardiologist, setIsCardiologist] = useState(
    user.isCardiologist || false
  )
  const [inProgress, setInProgress] = useState(false)
  const [updateUserType] = useMutation(UPDATE_USER_TYPE)

  async function submit() {
    setInProgress(true)
    await updateUserType({
      variables: {
        input: {
          id: user.id,
          updatedAt: new Date().toISOString(),
          userType,
          isCardiologist
        }
      },
      update: (cache) => {
        cache.modify({
          id: cache.identify(user),
          fields: objectToFieldsConverter({
            userType,
            isCardiologist
          })
        })
      }
    })
    setInProgress(false)
    onComplete()
  }

  return (
    <Modal onClose={onExit} onSubmit={submit} inProgress={inProgress}>
      <label className='label is-medium'>Change user type</label>
      <hr />
      <div className='field is-horizontal'>
        <div className='field-label'>
          <label className='label has-text-left'>Type:</label>
        </div>
        <div className='field-body'>
          <div className='field'>
            <div className='control'>
              <label className='radio'>
                <input
                  type='radio'
                  name='userType'
                  value='Veterinarian'
                  checked={userType === 'Veterinarian'}
                  onChange={(e) => setUserType(e.target.value)}
                />
                &nbsp; Veterinarian
              </label>
              <label className='radio'>
                <input
                  type='radio'
                  name='userType'
                  value='Physician'
                  checked={userType === 'Physician'}
                  onChange={(e) => setUserType(e.target.value)}
                />
                &nbsp; Physician
              </label>
            </div>
          </div>
        </div>
      </div>
      <div className='field is-horizontal'>
        <div className='field-label'>
          <label className='label has-text-left'>Cardiologist:</label>
        </div>
        <div className='field-body'>
          <div className='field'>
            <input
              type='checkbox'
              checked={isCardiologist}
              onChange={(e) => {
                setIsCardiologist(e.target.checked)
              }}
            />
          </div>
        </div>
      </div>
      <div className='has-text-danger-dark'>
        Dont foreget to check the service plan after you change the user type!
      </div>
      <div className='field'>
        <div className='level'>
          <div className='level-left'></div>
          <div className='level-right'>
            <div className='level-item'>
              <button
                className={clsx('button is-primary level-item ', {
                  'is-loading': inProgress
                })}
                onClick={async () => submit()}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

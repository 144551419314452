if (typeof window !== 'undefined') {

/*
    NHANESIIIPredictor - calculate predicted values
    https://www.cdc.gov/niosh/topics/spirometry/nhanes.html
*/
var GLIPredictor = {
    /*
        Calculate all the predicted values
    */
    getPredictedValues: function(patientDetails) {
         var predicted = {
            FVC: 0,
            FEV1: 0,
            PEF: 0     
        };
        
        if (this.canCalculatePrediction(patientDetails)) {
            var genderKey = SpirometerPrediction.getGenderKey(patientDetails.gender);
            predicted.FVC = this._calcPrediction(GLIPredData.FVC[genderKey], patientDetails);
            predicted.FEV1 = this._calcPrediction(GLIPredData.FEV1[genderKey], patientDetails);
            predicted.RATIO = this._calcPrediction(GLIPredData.FEV1FVC[genderKey], patientDetails);
            predicted.PEF = null;
        }
        
        return predicted;
    },
    
    /*
        Calculate prediction value
        for now the age is integer but the calculation support float age
    */
    _calcPrediction: function(predData, patientDetails) {
        var tAge = Math.max(Math.min(patientDetails.age, predData.maxAge), 3);
        var tIndex = parseInt((tAge - 3) * 4); // 4 rows per year
        var rAge = (tIndex / 4) + 3;
        
        var Lspline0 = predData.spline[tIndex][1];
        var Mspline0 = predData.spline[tIndex][2];
        var Sspline0 = predData.spline[tIndex][3];
        
        var Lspline1;
        var Mspline1;
        var Sspline1;
        if (patientDetails.age < predData.maxAge) {
            Lspline1 = predData.spline[tIndex+1][1];
            Mspline1 = predData.spline[tIndex+1][2];
            Sspline1 = predData.spline[tIndex+1][3];
        } else {
            Lspline1 = Lspline0;
            Mspline1 = Mspline0;
            Sspline1 = Sspline0;
        }
        
        
        Mspline0 = Mspline0 + ((tAge-rAge)/0.25)*(Mspline1 - (Mspline0));
        Sspline0 = Sspline0 + ((tAge-rAge)/0.25)*(Sspline1 - Sspline0);
        
        var prediction = Math.exp(predData.a[0] + predData.a[1]*Math.log(patientDetails.height) + predData.a[2]*Math.log(tAge) + predData.a[6]*0 + Mspline0 );
        
        return prediction; 
       
       /* future for lln and zscore calculation
        var S = exp(p[0] + p[1]*log(tAge) + p[5]*0 + Sspline0 ); 
        var L = q[0] + q[1]*log(tAge) + Lspline0;
        
        pResult->predicted = M; 
        pResult->lln = exp(log(M) + log(1 - 1.645*L*S)/L);
        pResult->zScore = ( pow((pResult->measured/M),L) - 1) / (L*S); 
        pResult->percentPredicted = (pResult->measured/M) * 100;
        */
    },
    
    /*
        Indicates if the predicted values can be calculated
    */
    canCalculatePrediction: function(patientDetails) {
       if (!(patientDetails.gender ||
              patientDetails.age ||
              patientDetails.height)) {
            return false;
        }
        
        if (patientDetails.height === 0) return false;
        if (patientDetails.gender === spiroViewer._gender.UNSPECIFIED) return false;
        
        return true;
    }
};

window.GLIPredictor = GLIPredictor;

}
import React from 'react'
import { SignIn as OriginalSignIn } from 'aws-amplify-react'
import { Hub } from '@aws-amplify/core'
import SignInCore from './SignInCore'
import Loader from '../Loader'
import { hashPassword } from '../../../utils/utils'

export class SignIn extends OriginalSignIn {
  constructor(props) {
    super(props)
    this._validAuthStates = ['signIn', 'signedOut', 'signedUp']
    this._signIn = this._signIn.bind(this)
    this.error = this.error.bind(this)
    this.onHubCapsule = this.onHubCapsule.bind(this)
    this.state = {
      error: null,
      isAutoLogin: false,
      originalPassword: null,
      inProgress: false
    }
  }

  componentDidMount() {
    Hub.listen('auth', this.onHubCapsule)
  }

  componentWillUnmount() {
    Hub.remove('auth', this.onHubCapsule)
  }

  onHubCapsule(capsule) {
    const { channel, payload } = capsule
    if (channel === 'auth' && payload.event === 'signIn' && payload.data) {
      setTimeout(
        () => this.setState({ inProgress: false, isAutoLogin: false }),
        1000
      )
    }
  }

  componentDidUpdate() {
    if (
      (this.props.authState === 'signedUp' ||
        window.previousAuthState === 'forgotPassword') &&
      window.loginDetails &&
      window.loginDetails.username &&
      window.loginDetails.password
    ) {
      this.inputs.email = window.loginDetails.username
      this.inputs.password = hashPassword(
        this.inputs.email,
        window.loginDetails.password
      )
      delete window.loginDetails
      delete window.previousAuthState
      this.setState({ isAutoLogin: true })
      this.signIn()
    }
  }

  error(err) {
    if (err.code === 'UserNotFoundException') {
      err.message = 'Incorrect username or password.'
    }
    this.inputs.password = this.state.originalPassword
    this.setState({
      error: err.message ? err.message : err,
      originalPassword: null,
      inProgress: false,
      isAutoLogin: false
    })
  }

  async _signIn(event) {
    const _originalPassword = this.inputs.password
    if (this.state.error) {
      this.setState({ error: null })
    }
    this.setState({ inProgress: true, originalPassword: _originalPassword })

    // TODO: remove after full migration
    const iframe = document.createElement('iframe')
    iframe.setAttribute('src', window.location.origin + '/en')
    iframe.setAttribute('hidden', 'hidden')
    document.getElementsByTagName('body')[0].appendChild(iframe)
    await new Promise((resolve) => iframe.addEventListener('load', resolve))
    const emailInput = iframe.contentWindow.document.body.querySelector(
      '#user_session_email'
    )
    if (emailInput) emailInput.value = this.inputs.email
    const passwordInput = iframe.contentWindow.document.body.querySelector(
      '#user_session_raw_password'
    )
    if (passwordInput) passwordInput.value = _originalPassword
    const loginButton =
      iframe.contentWindow.document.body.querySelector('#login-button')
    if (loginButton) {
      loginButton.click()
      await new Promise((resolve) => iframe.addEventListener('load', resolve))
    }
    iframe.parentNode.removeChild(iframe)
    // TODO: remove after full migration

    this.inputs.password = hashPassword(this.inputs.email, _originalPassword)
    window.loginDetails = {
      username: this.inputs.email,
      password: _originalPassword
    }
    window.previousAuthState = 'signIn' // in case the user didn't confirm itself on signup, after signin the UI goes to confim signup and we check this prop to send the confirmation code
    this.signIn(event)
    setTimeout(() => delete window.previousAuthState, 10000) // ignnore this check if succesfull login
  }

  showComponent() {
    return this.state.isAutoLogin || window.IS_LOGGED_OUT ? (
      <Loader />
    ) : (
      <SignInCore
        handleInputChange={(evt) => {
          if (evt.target.value) {
            evt.target.value = evt.target.value.trim()
          }
          return this.handleInputChange(evt)
        }}
        changeState={(props) => {
          if (this.state.error) {
            this.setState({ error: null })
          }
          this.changeState(props)
        }}
        signIn={this._signIn}
        inProgress={this.state.inProgress}
        error={this.state.error}
      />
    )
  }
}

export default SignIn

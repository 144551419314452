import React from 'react'
import Modal from './Modal'
import { useQuery } from '@apollo/client'
import { GET_STUDY_SHARES } from './queries'
import Loader from './Loader'
import clsx from 'clsx'
import { useLocalization } from './LocalizationProvider'
import { useAdmin } from './AdminProvider'

function getSharingSource(share) {
  return share.targetCaregiver
    ? `${share.targetCaregiver.name} (${share.targetCaregiver.email})`
    : share.email
}

export default function StudyShares({ studyId, onExit }) {
  const { loading, error, data } = useQuery(GET_STUDY_SHARES, {
    variables: {
      studyId
    }
  })
  const { locale } = useLocalization()

  const { setViewAsUser } = useAdmin()

  return (
    <Modal onClose={onExit}>
      <label className='label is-medium'>Study shares</label>
      <hr />
      {loading && <Loader />}

      {error && <p>{locale.something_wrong}</p>}

      {data && data.study.shares.length > 0 && (
        <table className='table is-fullwidth'>
          <thead>
            <tr>
              <th>Sharing with</th>
              <th>Share type</th>
              <th>View as</th>
            </tr>
          </thead>
          <tbody>
            {data.study.shares.map((share) => (
              <tr
                key={share.id}
                className={clsx({
                  'has-background-warning-light':
                    share.shareType === 'ShareOriginal'
                })}
              >
                <td className='is-vcentered'>{getSharingSource(share)}</td>
                <td className='is-vcentered'>{share.shareType}</td>
                <td className='is-vcentered'>
                  {share.targetCaregiver && (
                    <a
                      className='button'
                      onClick={() => {
                        setViewAsUser({
                          id: share.targetCaregiver.id,
                          email: share.targetCaregiver.email,
                          name: share.targetCaregiver.name
                        })
                      }}
                    >
                      view as user
                    </a>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </Modal>
  )
}

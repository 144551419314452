import React from 'react'
import '../legacy/stylesheets/MeasurementsValues.css'
import { useLocalization } from './LocalizationProvider'
const MeasurementValues = ({
  measurements,
  mea,
  dispatch,
  animalType,
  disabled
}) => {
  const { locale } = useLocalization()
  return (
    <div className='measurement_values mt-2'>
      <div>
        <div>{locale.report.HR_title}</div>
        <div>
          <input
            id='hr_measurement'
            value={measurements.HR || ''}
            onChange={(e) =>
              dispatch({ key: 'measurements.HR', payload: e.target.value })
            }
            disabled={disabled}
          ></input>
        </div>
      </div>
      <div>
        <div>{locale.report.P_title}</div>
        <div>
          <input
            id='p_measurement'
            value={measurements.P || ''}
            onChange={(e) =>
              dispatch({ key: 'measurements.P', payload: e.target.value })
            }
            disabled={disabled}
          ></input>
        </div>
      </div>
      <div>
        <div>{locale.report.P_mv_title}</div>
        <div>
          <input
            id='p_mv_measurement'
            value={measurements.P_MV || ''}
            onChange={(e) =>
              dispatch({ key: 'measurements.P_MV', payload: e.target.value })
            }
            disabled={disabled}
          ></input>
        </div>
      </div>
      <div>
        <div>{locale.report.PR_title}</div>
        <div>
          <input
            id='pr_measurement'
            value={measurements.PR || ''}
            onChange={(e) =>
              dispatch({ key: 'measurements.PR', payload: e.target.value })
            }
            disabled={disabled}
          ></input>
        </div>
      </div>
      <div>
        <div>{locale.report.QRS_title}</div>
        <div>
          <input
            id='qrs_measurement'
            value={measurements.QRS || ''}
            onChange={(e) =>
              dispatch({ key: 'measurements.QRS', payload: e.target.value })
            }
            disabled={disabled}
          ></input>
        </div>
      </div>
      <div>
        <div>{locale.report.R_title}</div>
        <div>
          <input
            id='r_measurement'
            value={measurements.R || ''}
            onChange={(e) =>
              dispatch({ key: 'measurements.R', payload: e.target.value })
            }
            disabled={disabled}
          ></input>
        </div>
      </div>
      <div>
        <div>{locale.report.QT_title}</div>
        <div>
          <input
            id='qt_measurement'
            value={measurements.QT || ''}
            onChange={(e) =>
              dispatch({ key: 'measurements.QT', payload: e.target.value })
            }
            disabled={disabled}
          ></input>
        </div>
      </div>
      <div>
        <div>{locale.report.MEA_title}</div>
        <div>
          <select
            value={mea || ''}
            onChange={(e) =>
              dispatch({ key: 'mea', payload: e.target.value || null })
            }
            disabled={disabled}
          >
            <option value=''></option>
            <option value='Normal'>{locale.report.mea_normal}</option>
            {['Mild', 'Moderate', 'Severe'].indexOf(mea) > -1 ||
            animalType !== 'Cat' ? (
              <>
                <option value='Mild'>{locale.report.mea_mild}</option>
                <option value='Moderate'>{locale.report.mea_moderate}</option>
                <option value='Severe'>{locale.report.mea_severe}</option>
                <option value='Left'>{locale.report.mea_left}</option>
              </>
            ) : (
              <>
                <option value='Right'>{locale.report.mea_right}</option>
                <option value='Left'>{locale.report.mea_left}</option>
              </>
            )}
          </select>
        </div>
      </div>
    </div>
  )
}

export default MeasurementValues

if (typeof window !== 'undefined') {

/*
    RemoteMonitorDataFetcher - handles fetching data from the WebSocket
*/

bcViewer.RemoteMonitorDataFetcher = bcViewer.BaseDataFetcher.extend({
    construct: function(globals) {
        this._super.apply(this, arguments);
        this._signalProcessing = new SignalProcessing();
        this._socket = null;

        this._testCnt = 0;

        this._cnt = 0;

        this._data = false;
        this._hr = 0;
        this._saturation;
        this._electrodesPosition = null;
        this._samplingRate = 400;
        this._unitsPerMV = 328;
        this._isVetDevice = false;
        this._expandStates = [];

        this._remoteMonitorErrors = {
            DEVICE_DISCONNECTED: 0,
            EXIT_VIEWER: 1,
            SCREEN_LOCKED: 2,
            BLOCKED: 3
        };

        this._noDataTimeout = null;
    },

    _notifyWatching: function() {
        this._socket.send({
            type: 'WATCHING',
            id: this._bcViewer.sessionId
        });
        setTimeout($.proxy(function() {
            this._notifyWatching();
        }, this),10000);
    },

    toggleRealtimeMode: function(activateRealtimeMode, realtimeModeType, callback) {
        this._showNotification(bcGlobals.locale.remoteWaiting, 'warning', true, false);

        this._socket = io2.connect('https://remote.beecardia.com');
        this._socket.on('connect', $.proxy(function () {
            this._socket.send({ type: 'INIT', id: this._bcViewer.sessionId });
            callback && callback(activateRealtimeMode);
        }, this));
        this._notifyWatching();

        this._socket.on(this._bcViewer.sessionId, $.proxy(function (message) {
            switch(message.type) {
                case 'DATA':
                    this._onDataMessage(message);
                    break;
               case 'ERROR':
                    this._onErrorMessage(message);
                    break;
            }
        }, this));
    },

    _onDataMessage: function(message) {
        closeNotification();

        if (!this._data) {
            this._data = [];
        }
        this._data = this._data.concat(message.data);
        this._hr = message.hr;
        this._saturation = message.saturation;
        this._samplingRate = message.samplingRate;
        this._unitsPerMV = message.unitsPerMV;
        this._isVetDevice = (typeof message.isVetDevice !== 'undefined') ? message.isVetDevice : false;

        if (this._electrodesPosition === null) {
            this._handleElectrodesPosition(message.electrodesPosition);
        } else if (this._electrodesPosition !== message.electrodesPosition) {
            location.reload(); // refresh the page if electrodes position has changed
        }

        clearTimeout(this._noDataTimeout);
        delete this._noDataTimeout;

        this._noDataTimeout = setTimeout($.proxy(function() {
            this._showNotification(bcGlobals.locale.remoteError, 'error', true,  false);
        }, this), 10000);

    },

    _onErrorMessage: function(message) {
        switch (message.errorId) {
            case this._remoteMonitorErrors.DEVICE_DISCONNECTED:
            case this._remoteMonitorErrors.EXIT_VIEWER:
            case this._remoteMonitorErrors.SCREEN_LOCKED:
            case this._remoteMonitorErrors.BLOCKED:
                this._showNotification(bcGlobals.locale.remoteError, 'error', true,  false);
                break;
        }

        // not waiting for data on error
        clearTimeout(this._noDataTimeout);
        delete this._noDataTimeout;
    },

    _handleElectrodesPosition: function(electrodesPosition) {
        function getFilterName(expandState) {
            switch (expandState) {
                case 'expand2to6':
                    return 'Expand2To6';
                    break;
                case 'expand3to7':
                    return 'Expand3To7';
                    break;
                case 'expand3to12':
                    return 'Expand3To12';
                    break;
                case 'expand8to12':
                    return 'Expand8To12';
                    break;
            }
        };

        this._electrodesPosition = electrodesPosition;

        var positions = this._bcViewer.electrodesPositions;
        var expandState = (this._isVetDevice) ? 'expandStateVet' : 'expandState';

        for (var pos in positions) {
            this._expandStates[positions[pos][expandState]] = {
                enabled: (positions[pos].id == this._electrodesPosition),
                filterName: getFilterName(positions[pos][expandState])
            };
        }
    },

    getRealtimeData: function(requestParams, callback, errorCallback) {
        var result = {};
        if (this._data) {
            var processedData = this._processData(requestParams, this._data);
            result = this._convertSignalFormat(processedData);
            result.HR = this._hr;
            result.saturation = this._saturation;
            this._data = false;
        }
        callback && callback(result);
    },

    _convertSignalFormat: function(signal) {
        var outSignal = {
            leadNames: [],
            series: [],
            qrs: []
        };
        if (signal.length === 0) return outSignal;

        // Prepare signal in old ShowSignalData format - this is crazy!
        for (var i=0; i<signal[0].length; i++) {
            var series = {};
            var channelNumber = i;//requestedChannels ? requestedChannels[i] : i;
            series.color = 'Ch' + channelNumber;
            series.index = channelNumber;
            series.points = [];
            for (j=0; j<signal.length; j++) {
                var point = [j, signal[j][i]];
                series.points.push(point);
            }
            outSignal.series.push(series);
        }

        return outSignal;
    },

    _processData: function(dataParams, data) {
        this._signalProcessing.setInputSignal(data, {
            numberOfChannels: data[0].length,
            numberOfSamples: data.length,
            samplingRate: this._samplingRate,
            ry: this._unitsPerMV
        });
        this._signalProcessing.configProcessor('SmoothFilter', {enabled: dataParams.sm});
        this._signalProcessing.configProcessor('BaselineFilter', {enabled: dataParams.bl});

        this._signalProcessing.configProcessor('Expand2To6', {enabled: false}); // TBD: support vet devices (hide Vx)
        this._signalProcessing.configProcessor('Expand3To7', {enabled: false}); // TBD: support vet devices (hide Vx)
        for (var e in this._expandStates) {
            this._signalProcessing.configProcessor(this._expandStates[e].filterName, {enabled: this._expandStates[e].enabled});
        }

        var signal = this._signalProcessing.getOutputSignal(dataParams, true);

        return signal;
    },

    getLeadNamesByElectrodesPosition: function() {
        var leadNames = []
        var positions = this._bcViewer.electrodesPositions;
        var expandedLeadNames = (this._isVetDevice) ? 'expandedLeadNamesVet' : 'expandedLeadNames';
        for (var pos in positions) {
            if (positions[pos].id == this._electrodesPosition) {
                leadNames = positions[pos][expandedLeadNames];
            }
        }
        return leadNames;
    },

    _showNotification: function(message, type, showLoading,  autoClose, duration) {
        var msg = message;

        if (showLoading) {
            msg = '<span class="notification_loading"></span><span class="notification_text">' + msg + '</span>';
        }

       showNotification({
            message: msg,
            type: type,
            autoClose: autoClose,
            duration: duration
        });
    }
});

}

import React from 'react'
import { useQuery } from '@apollo/client'
import { Redirect } from '@reach/router' // eslint-disable-line import/no-unresolved
import { GET_STUDY_REPORT } from './queries'
import { useLocalization } from './LocalizationProvider'
import { getParams } from '../../utils/utils'

const ReportRedirect = ({ gotoLoginPage, location: { search } }) => {
  const params = getParams(search)
  const isGoToStudyPrint = params.print === 'true' && params.study === 'true'
  const { locale } = useLocalization()
  const { loading, error, data } = useQuery(GET_STUDY_REPORT, {
    variables: {
      id: params.hash_id
    },
    skip: isGoToStudyPrint
  })
  if (loading) {
    return (
      <div id='page_wrapper'>
        <div className='main'>
          <div id='main'>
            <div id='loader_wrapper'>
              <div id='loader' />
            </div>
          </div>
        </div>
      </div>
    )
  }
  if (error) {
    return (
      <div
        className='is-flex is-flex-direction-column is-justify-content-center is-align-items-center'
        style={{ height: '100%' }}
      >
        {error.message === 'AccessDenied' ? (
          <>
            <p>{locale.access_denied_report_line1}</p>
            <p>{locale.access_denied_line2}</p>
            <a onClick={() => gotoLoginPage()}>{locale.profile.login}</a>
          </>
        ) : (
          <p>{locale.something_wrong}</p>
        )}
      </div>
    )
  }
  return (
    <Redirect
      to={
        isGoToStudyPrint
          ? `/study/${params.hash_id}/report/new?study_mode=true&print=true`
          : `/study/${params.hash_id}/report/${
              data.study.reports.length > 0 ? data.study.reports[0].id : 'new'
            }`
      }
      noThrow
    />
  )
}

export default ReportRedirect

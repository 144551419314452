import React from 'react'
import clsx from 'clsx'
import Modal from '../Modal'
import { useLocalization } from '../LocalizationProvider'
import AuthScenery from './AuthScenery'

export default function ConfirmSignUpCore({
  username,
  handleInputChange,
  resend,
  confirm,
  error,
  inProgress,
  inProgressResend
}) {
  const { locale } = useLocalization()

  return (
    <AuthScenery>
      <Modal onSubmit={confirm} standalone={false}>
        <div className='field'>
          <label className='label is-medium'>
            {locale.profile.confirm_signup}
          </label>
        </div>
        <hr />
        <div className='field'>
          <label className='label'>
            {locale.profile.confirmation_code_sent_to_email}
          </label>
          <label className='label'>
            {locale.profile.check_email_for_confirmation_code}
          </label>
          <div className='control'>
            <input
              key='username'
              type='text'
              name='username'
              className='input'
              value={username}
              disabled={username}
            />
          </div>
        </div>
        <div className='field'>
          <label className='label'>{locale.profile.confirmation_code}</label>
          <div className='control'>
            <input
              key='code'
              type='text'
              name='code'
              className='input'
              autoComplete='off'
              onChange={handleInputChange}
              readOnly={inProgress || inProgressResend}
            />
          </div>
          <p className='help'>
            <span className='level'>
              <span className='level-left'>
                <span className='level-item'>{locale.profile.lost_code}</span>
                <button
                  className={clsx('button is-small is-primary level-item', {
                    'is-loading': inProgressResend,
                    'is-inverted': !inProgressResend
                  })}
                  onClick={resend}
                  disabled={inProgress || inProgressResend}
                >
                  {locale.profile.resend_code}
                </button>
              </span>
            </span>
          </p>
        </div>
        <div className={clsx('field', { 'is-hidden': !error })}>
          <p className='help is-danger'>{error}</p>
        </div>
        <hr />
        <div className='field'>
          <div className='level'>
            <div className='level-left' />
            <div className='level-right'>
              <button
                className={clsx('button is-primary level-item', {
                  'is-loading': inProgress
                })}
                onClick={confirm}
                disabled={inProgress}
              >
                {locale.profile.confirm}
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </AuthScenery>
  )
}

import React, {
  useState,
  useEffect,
  useReducer,
  useRef,
  useContext
} from 'react'
import { gql, useMutation } from '@apollo/client'
import clsx from 'clsx'
import dayjs from 'dayjs'
import { v4 as uuidv4 } from 'uuid'
import MeasurementValues from './MeasurementValues'
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons'
import AuthContext from './AuthContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DiagnosisTags from './DiagnosisTags'
import { useCombobox } from 'downshift'
import { navigate } from '@reach/router' // eslint-disable-line import/no-unresolved
import { useLocalization } from './LocalizationProvider'
import {
  base64UrlEncode,
  isRTL,
  interpolateConclusionTemplate
} from '../../utils/utils'

const MAX_TAGS_NUMBER = 24

const CREATE_DIAGNOSIS = gql`
  mutation CREATE_DIAGNOSIS(
    $studyId: ID!
    $clinicId: ID!
    $caregiver: CaregiverConnectionInput!
    $clinic: ClinicConnectionInput!
    $input: StudyDiagnosisCreateInput!
  ) {
    studyDiagnosisCreate(
      studyId: $studyId
      caregiver: $caregiver
      clinic: $clinic
      input: $input
    ) {
      id
    }
    query {
      study(id: $studyId) {
        id
        diagnosis(clinicId: $clinicId) {
          id
          updatedAt
          measurements
          mea
          conclusion
          tags
          caregiver {
            name
          }
        }
      }
    }
  }
`

const UPDATE_DIAGNOSIS = gql`
  mutation UPDATE_DIAGNOSIS(
    $caregiver: CaregiverConnectionInput!
    $studyId: ID!
    $clinicId: ID!
    $input: StudyDiagnosisUpdateInput!
  ) {
    studyDiagnosisUpdate(caregiver: $caregiver, input: $input) {
      id
    }
    query {
      study(id: $studyId) {
        id
        diagnosis(clinicId: $clinicId) {
          id
          updatedAt
          measurements
          mea
          conclusion
          tags
          caregiver {
            name
          }
        }
      }
    }
  }
`

const initialState = {
  measurements: JSON.stringify({
    HR: '',
    P: '',
    P_MV: '',
    PR: '',
    QRS: '',
    QT: '',
    R: ''
  }),
  mea: null,
  conclusion: '',
  tags: []
}

function reducerBuilder(state, [key, ...keys], payload) {
  if (keys.length === 0) {
    return {
      ...state,
      [key]: payload
    }
  }
  return {
    ...state,
    [key]: reducerBuilder(state[key], keys, payload)
  }
}

function reducer(state, action) {
  if (action.type === 'RESET') return action.payload
  return reducerBuilder(state, action.key.split('.'), action.payload)
}

const DiagnosisTab = ({
  isRest,
  diagnosis,
  userMarks,
  initialMEA,
  initialMeasurements,
  dateFormat,
  animalType,
  studyId,
  forceReRender,
  setTab,
  setTimePositionAfterTabChange
}) => {
  const currentUser = useContext(AuthContext)
  const { locale, interpolate } = useLocalization()
  const [conclusionTemplates] = useState(
    currentUser.settings.reportConclusionTemplates
      ? JSON.parse(currentUser.settings.reportConclusionTemplates)
      : null
  )
  const [diagnosisData, dispatchDiagnosisData] = useReducer(
    reducer,
    initialState
  )

  useEffect(() => {
    const initialConclusion =
      !(diagnosis && typeof diagnosis.conclusion === 'string') &&
      conclusionTemplates &&
      conclusionTemplates.default_template
        ? interpolateConclusionTemplate(
            conclusionTemplates.list[conclusionTemplates.default_template].text,
            currentUser
          )
        : diagnosis
        ? diagnosis.conclusion
        : ''
    dispatchDiagnosisData({
      type: 'RESET',
      payload: {
        measurements: JSON.parse(
          (diagnosis || initialMeasurements || initialState).measurements
        ),
        mea: (diagnosis || initialMEA || initialState).mea,
        conclusion: initialConclusion,
        tags: (diagnosis || initialState).tags
      }
    })
  }, [studyId])
  useEffect(() => {
    dispatchDiagnosisData({
      key: 'mea',
      payload: (diagnosis || initialMEA || initialState).mea
    })
  }, [initialMEA])
  useEffect(() => {
    dispatchDiagnosisData({
      key: 'measurements',
      payload: JSON.parse(
        (diagnosis || initialMeasurements || initialState).measurements
      )
    })
  }, [initialMeasurements])

  const [tagsTextCursorPosition, setTagsTextCursorPosition] = useState(null)
  useEffect(() => {
    if (tagsTextCursorPosition !== null) {
      tagsInput.current.setSelectionRange(
        tagsTextCursorPosition,
        tagsTextCursorPosition
      )
      setTagsTextCursorPosition(null)
    }
  }, [tagsTextCursorPosition])
  const conclusionTextarea = useRef()
  // move textarea cursor to end so the first template insertion will be to the end
  useEffect(() => {
    if (conclusionTextarea && conclusionTextarea.current) {
      conclusionTextarea.current.setSelectionRange(
        diagnosisData.conclusion.length,
        diagnosisData.conclusion.length
      )
    }
  }, [conclusionTextarea])

  const [inProgressUpdateLegacyClinic, setInProgressUpdateLegacyClinic] =
    useState(false)
  const [createDiagnosis, { loading: createDiagnosisLoading }] =
    useMutation(CREATE_DIAGNOSIS)
  const [updateDiagnosis, { loading: updateDiagnosisLoading }] =
    useMutation(UPDATE_DIAGNOSIS)

  const hasDiagnosis = !!diagnosis

  const defaultData = diagnosis || initialState

  const defaultDataMeasurements = JSON.parse(defaultData.measurements)

  const hasMeasurementsChanged =
    JSON.stringify(
      Object.keys(defaultDataMeasurements)
        .sort()
        .reduce((obj, key) => {
          obj[key] = defaultDataMeasurements[key]
          return obj
        }, {})
    ) !==
    JSON.stringify(
      Object.keys(diagnosisData.measurements)
        .sort()
        .reduce((obj, key) => {
          obj[key] = diagnosisData.measurements[key]
          return obj
        }, {})
    )

  const hasMeaChanged = defaultData.mea !== diagnosisData.mea
  const hasConclusionChanged =
    defaultData.conclusion !== diagnosisData.conclusion
  const hasTagsChanged =
    JSON.stringify(defaultData.tags) !== JSON.stringify(diagnosisData.tags)

  const hasFormChanged =
    hasMeasurementsChanged ||
    hasMeaChanged ||
    hasConclusionChanged ||
    hasTagsChanged

  const [diagnosisTagsText, setDiagnosisTagsText] = useState([])
  const [isDiagnosisTagsMode, setDiagnosisTagsMode] = useState(false)

  const autoCompleteDictionary = {
    rest: [
      'Aberrant conduction',
      'Arrhythmogenic right ventricular tachycardia',
      'Atrial fibrillation',
      'Atrial flutter',
      'Atrial premature complexes',
      'Atrial tachycardia',
      'Atrioventricular nodal reciprocating tachycardia',
      'AV-Block',
      'AV-Block 1st degree',
      'AV-Block 2nd degree',
      'AV-Block Mobitz type I',
      'AV-Block Mobitz type II',
      'Bradyarrhythmia',
      'Bundle branch block',
      'Chamber enlargement',
      'Escape rhythm',
      'Focal atrial tachycardia',
      'Fusion complexes',
      'Idioventricular rhythm',
      'Isorhythmic atrioventricular dissociation',
      'Left atrial enlargement',
      'Left bundle branch block',
      'Left ventricular enlargement',
      'Left ventricular escape rhythm',
      'Monomorphic ventricular tachycardia',
      'Monomorphic ventricular tachycardia (LV focus)',
      'Monomorphic ventricular tachycardia (RV focus)',
      'Nodal escape rhythm',
      'Nodal tachycardia',
      'Normal respiratory sinus arrhythmia',
      'Normal sinus arrhythmia',
      'Normal sinus rhythm',
      'Orthodromic atrioventricular reciprocating tachycardia',
      'Polymorphic ventricular tachycardia',
      'R-on-T phenomenon',
      'Right atrial enlargement',
      'Right bundle branch block',
      'Right ventricular enlargement',
      'Right ventricular escape rhythm',
      'Sick sinus syndrome',
      'Supraventricular tachycardia with aberrant ventricular conduction',
      'Sustained monomorphic ventricular tachycardia',
      'Sustained polymorphic ventricular tachycardia',
      'Tachyarrhythmia',
      'Ventricular bigeminy',
      'Ventricular escape rhythm',
      'Ventricular premature complexes',
      'Ventricular tachycardia',
      'Ventricular trigeminy',
      'Wide complex tachycardia'
    ]
  }

  const tagsInput = useRef()
  const autoCompleteItems = isRest ? autoCompleteDictionary.rest : []
  const [inputItems, setInputItems] = useState(autoCompleteItems)
  const {
    isOpen,
    getMenuProps,
    getInputProps,
    highlightedIndex,
    getItemProps,
    selectItem,
    closeMenu
  } = useCombobox({
    items: inputItems,
    onInputValueChange: ({ inputValue }) => {
      let pos = tagsInput.current.selectionStart
      let sub = inputValue.substring(0, pos)
      let val = sub.substring(sub.lastIndexOf(',') + 1, pos).trim()

      if (val === '') {
        closeMenu()
        return
      }

      setInputItems(
        autoCompleteItems.filter((item) =>
          item.toLowerCase().startsWith(val.toLowerCase())
        )
      )
    },
    onSelectedItemChange: (changes) => {
      let item = changes.selectedItem
      if (item == '' || item == null) return

      let currentValue = tagsInput.current.value
      let firstPart = currentValue.substring(
        0,
        tagsInput.current.selectionStart
      )
      let lastComma = firstPart.lastIndexOf(',')
      let newStart =
        (lastComma != -1 ? firstPart.substring(0, lastComma) + ', ' : '') + item
      let newValue =
        newStart + currentValue.substring(firstPart.length, currentValue.length)

      setTagsTextCursorPosition(newStart.length)
      setDiagnosisTagsText(newValue)
      selectItem('')
    }
  })

  function addDiagnosisTags() {
    let tagsArr = (diagnosisTagsText || '').split(',')
    const tmpArr = []
    tagsArr.forEach((tag) => {
      tag = tag.trim().toLowerCase()
      if (
        tag !== '' &&
        !diagnosisData.tags.includes(tag) &&
        !tmpArr.includes(tag)
      ) {
        tmpArr.push(tag)
      }
    })
    dispatchDiagnosisData({
      key: 'tags',
      payload: [...diagnosisData.tags, ...tmpArr]
    })
  }

  return (
    <>
      {isDiagnosisTagsMode && (
        <DiagnosisTags
          diagnosisTags={diagnosisData.tags}
          onComplete={(tags) => {
            dispatchDiagnosisData({
              key: 'tags',
              payload: tags
            })
            setDiagnosisTagsMode(false)
          }}
          onExit={() => setDiagnosisTagsMode(false)}
        />
      )}
      <div className='is-small'>
        {isRest && (
          <div className='diagnosis-section'>
            <div className='mb-4'>
              <span className='title is-5'>{locale.measurements}</span>
            </div>
            <MeasurementValues
              measurements={diagnosisData.measurements}
              mea={diagnosisData.mea}
              dispatch={dispatchDiagnosisData}
              animalType={animalType}
            />
            <div
              className={clsx('help is-danger mt-4 ', {
                'is-hidden': true // !(hasMeasurementsChanged || hasMeaChanged)
              })}
            >
              {locale.save_warning}
            </div>
          </div>
        )}

        <div className='diagnosis-section'>
          <div className='mb-4'>
            <span className='title is-5'>{locale.conclusions}</span>
            {conclusionTemplates !== null && (
              <select
                id='select_conclusion_template'
                onChange={(e) => {
                  if (e.target.value === '-1') return
                  const textToInsert = interpolateConclusionTemplate(
                    conclusionTemplates.list[e.target.value].text,
                    currentUser
                  )
                  const cursorPosition =
                    conclusionTextarea.current.selectionStart
                  const textBeforeCursorPosition =
                    diagnosisData.conclusion.slice(0, cursorPosition)
                  const textAfterCursorPosition =
                    diagnosisData.conclusion.slice(cursorPosition)
                  dispatchDiagnosisData({
                    key: 'conclusion',
                    payload:
                      textBeforeCursorPosition +
                      textToInsert +
                      textAfterCursorPosition
                  })
                }}
                value='-1'
              >
                <option disabled='disabled' value='-1'>
                  {locale.report.insert_template}
                </option>
                {Object.entries(conclusionTemplates.list).map(
                  ([key, template]) => (
                    <option key={key} value={key}>
                      {template.name}
                    </option>
                  )
                )}
              </select>
            )}
          </div>
          <textarea
            className='textarea mt-2'
            maxLength={10000}
            value={diagnosisData.conclusion}
            style={{
              direction:
                diagnosisData.conclusion && isRTL(diagnosisData.conclusion[0])
                  ? 'rtl'
                  : 'ltr'
            }}
            onChange={(e) =>
              dispatchDiagnosisData({
                key: 'conclusion',
                payload: e.target.value
              })
            }
            ref={conclusionTextarea}
          />
          <div className='is-flex is-justify-content-space-between'>
            <span id='report_signature_creator'>
              {hasDiagnosis ? diagnosis.caregiver.name : ''}
            </span>
            <span id='report_signature_date' className='report_signature_date'>
              {hasDiagnosis
                ? dayjs(diagnosis.updatedAt).format(dateFormat)
                : ''}
            </span>
          </div>
          <div
            className={clsx('help is-danger mt-4', {
              'is-hidden': true //!hasConclusionChanged
            })}
          >
            {locale.save_warning}
          </div>
        </div>

        <div className='diagnosis-section'>
          <div className='mb-4'>
            <span className='title is-5'>{locale.diagnosis_tags}</span>
          </div>
          <div>
            <div className='field control is-expanded has-addons mt-2'>
              <div className='control is-expanded'>
                <input
                  className='input'
                  {...getInputProps({
                    onKeyDown: (e) => {
                      if (e.keyCode === 13 && highlightedIndex === -1) {
                        addDiagnosisTags()
                        setDiagnosisTagsText('')
                      }
                    },
                    onChange: (e) => {
                      setDiagnosisTagsText(e.target.value)
                    },
                    ref: tagsInput
                  })}
                  value={diagnosisTagsText}
                />
              </div>
              {isRest && (
                <div className='control'>
                  <button
                    className='button is-primary is-outlined'
                    onClick={() => setDiagnosisTagsMode(true)}
                  >
                    <FontAwesomeIcon icon={faEllipsisH} />
                  </button>
                </div>
              )}
            </div>
            <aside
              {...getMenuProps()}
              className='menu autocomplete-menu-wrapper'
            >
              <ul className='menu-list' id='tags-autocomplete-menu'>
                {isOpen &&
                  inputItems.map((item, index) => (
                    <li
                      key={`${item}${index}`}
                      {...getItemProps({ item, index })}
                    >
                      <a
                        className={
                          highlightedIndex === index ? 'is-active' : ''
                        }
                      >
                        {item}
                      </a>
                    </li>
                  ))}
              </ul>
            </aside>
          </div>
          <div className='field is-grouped is-grouped-multiline mt-5'>
            {diagnosisData.tags.map((tag) => (
              <div key={tag} className='control'>
                <div className='tags has-addons'>
                  <a
                    className='tag is-primary'
                    onClick={() => navigate(`/tags/${base64UrlEncode(tag)}`)}
                  >
                    {tag}
                  </a>
                  <a
                    className='tag is-delete is-primary'
                    onClick={() => {
                      var tags = [...diagnosisData.tags]
                      tags.splice(tags.indexOf(tag), 1)
                      dispatchDiagnosisData({
                        key: 'tags',
                        payload: tags
                      })
                    }}
                  ></a>
                </div>
              </div>
            ))}
          </div>
          <div
            className={clsx('help is-danger mt-4', {
              'is-hidden': true //!hasTagsChanged
            })}
          >
            {locale.save_warning}
          </div>
        </div>
        {userMarks.length > 0 && (
          <div className='diagnosis-section'>
            <div className='mb-4'>
              <span className='title is-5'>{locale.marks}</span>
            </div>
            <div id='marks-list'>
              {userMarks
                .sort((m1, m2) => (m2.x < m1.x ? 1 : -1))
                .map((mark) => (
                  <div className='level' key={mark.x}>
                    <div className='level-item level-left'>
                      <div className='mark-comment'>
                        {mark.comment === ''
                          ? locale.no_description
                          : mark.comment}
                      </div>
                    </div>
                    <div className='level-item level-right'>
                      <button
                        className='button is-primary is-small'
                        onClick={() => {
                          setTab('RecordEcgRestData')
                          setTimePositionAfterTabChange(mark.x)
                        }}
                      >
                        {locale.view}
                      </button>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        )}

        <div className='level'>
          <div className='level-left'>
            <div className='level-item'>
              <button
                className={clsx('button is-primary', {
                  'is-loading':
                    createDiagnosisLoading ||
                    updateDiagnosisLoading ||
                    inProgressUpdateLegacyClinic
                })}
                disabled={
                  !hasFormChanged ||
                  createDiagnosisLoading ||
                  updateDiagnosisLoading ||
                  inProgressUpdateLegacyClinic ||
                  diagnosisData.tags.length > MAX_TAGS_NUMBER
                }
                onClick={async () => {
                  const createdAt = new Date().toISOString()

                  /* TODO: remove after migration */
                  try {
                    setInProgressUpdateLegacyClinic(true)
                    const iframe = document.createElement('iframe')
                    iframe.setAttribute(
                      'src',
                      window.location.origin + '/en/account/edit_profile'
                    )
                    iframe.setAttribute('hidden', 'hidden')
                    document.getElementsByTagName('body')[0].appendChild(iframe)
                    await new Promise((resolve) =>
                      iframe.addEventListener('load', resolve)
                    )
                    const csrfToken = iframe.contentWindow.document
                      .querySelector("meta[name='csrf-token']")
                      .getAttribute('content')
                    iframe.parentNode.removeChild(iframe)

                    const body = new URLSearchParams({
                      hash_id: studyId,
                      conclusion: diagnosisData.conclusion,
                      measurement_values: JSON.stringify(
                        Object.keys(diagnosisData.measurements).reduce(
                          (obj, key) => (
                            (obj[key] = diagnosisData.measurements[key] || ''),
                            obj
                          ),
                          {}
                        )
                      ),
                      mea_label: {
                        '': 'novalue',
                        null: 'novalue',
                        undefined: 'novalue',
                        Normal: 'normal',
                        Mild: 'mild_right_axis_deviation',
                        Moderate: 'moderate_right_axis_deviation',
                        Severe: 'severe_right_axis_deviation',
                        Right: 'right_axis_deviation',
                        Left: 'left_axis_deviation'
                      }[diagnosisData.mea],
                      is_request_from_new_app: true
                    })

                    diagnosisData.tags.forEach((tag) =>
                      body.append('tags[]', tag)
                    )

                    const response = await window.fetch(`/api/add_diagnosis`, {
                      method: 'POST',
                      headers: {
                        'Content-Type':
                          'application/x-www-form-urlencoded; charset=UTF-8',
                        'X-CSRF-Token': csrfToken
                      },
                      body
                    })
                    const result = await response.json()
                    console.log('add_diagnosis result:', result)
                    setInProgressUpdateLegacyClinic(false)
                  } catch (e) {
                    console.error(e)
                    setInProgressUpdateLegacyClinic(false)
                  }

                  if (hasDiagnosis) {
                    await updateDiagnosis({
                      variables: {
                        caregiver: {
                          id: currentUser.id,
                          name: currentUser.settings.displayName,
                          email: currentUser.email
                        },
                        input: {
                          id: diagnosis.id,
                          updatedAt: createdAt,
                          ...diagnosisData,
                          measurements: JSON.stringify(
                            diagnosisData.measurements
                          )
                        },
                        studyId,
                        clinicId: currentUser.clinic.id
                      }
                    })
                  } else {
                    await createDiagnosis({
                      variables: {
                        studyId,
                        clinicId: currentUser.clinic.id,
                        caregiver: {
                          id: currentUser.id,
                          name: currentUser.settings.displayName,
                          email: currentUser.email
                        },
                        clinic: {
                          id: currentUser.clinic.id,
                          name: currentUser.clinic.name
                        },
                        input: {
                          id: uuidv4(),
                          createdAt,
                          ...diagnosisData,
                          measurements: JSON.stringify(
                            diagnosisData.measurements
                          )
                        }
                      }
                    })
                  }
                  forceReRender()
                }}
              >
                {locale.save}
              </button>
            </div>
            <div className='level-item'>
              <label
                className={clsx('has-text-danger', {
                  'is-hidden': diagnosisData.tags.length <= MAX_TAGS_NUMBER
                })}
              >
                {interpolate(locale.max_tags_error, {
                  tags_count: diagnosisData.tags.length,
                  max_tags_number: MAX_TAGS_NUMBER
                })}
              </label>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default DiagnosisTab

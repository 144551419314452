import React, { useState, useRef } from 'react'
import { gql, useMutation } from '@apollo/client'
import clsx from 'clsx'
import AWS from 'aws-sdk/global'
import S3 from 'aws-sdk/clients/s3'
import { currentCredentials } from '../../utils/credentials'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import ModalCard from './ModalCard'
import SignatureCanvas from './SignatureCanvas'
import { useLocalization } from './LocalizationProvider'

const DIGITAL_SIGNATURE_UPDATE = gql`
  mutation DIGITAL_SIGNATURE_UPDATE($input: UserUpdateInput!) {
    userUpdate(input: $input) {
      id
    }
  }
`
function shouldDisableSubmit(
  inProgress,
  updateUserLoading,
  hasSignature,
  currentDigitalSignature,
  hasSignatureCleared
) {
  if (inProgress || updateUserLoading) return true

  if (currentDigitalSignature != null && hasSignatureCleared) return false

  return !hasSignature
}

export default function DigitalSignatureManager({
  user,
  currentDigitalSignature,
  onExit,
  onComplete
}) {
  const [inProgress, setInProgress] = useState(false)
  const [signatureCanvasRef, setSignatureCanvasRef] = useState(null)
  const [hasSignature, setHasSignature] = useState(false)
  const [hasSignatureCleared, setHasSignatureCleared] = useState(false)
  const [updateUser, { loading: updateUserLoading }] = useMutation(
    DIGITAL_SIGNATURE_UPDATE,
    {
      onCompleted: onComplete,
      update(cache, _, { variables }) {
        cache.modify({
          id: cache.identify(user),
          fields: {
            settings: (settings) => ({
              ...settings,
              reportDigitalSignature: variables.input.settings
                .isDeleteReportDigitalSignature
                ? null
                : {
                    ...settings.reportDigitalSignature,
                    ...variables.input.settings.reportDigitalSignature
                  }
            })
          }
        })
      }
    }
  )

  const doClear = useRef(null)
  const { locale } = useLocalization()

  return (
    <ModalCard
      onClose={() => {
        if (inProgress || updateUserLoading) return
        onExit()
      }}
      loader={
        <FontAwesomeIcon
          icon={faSpinner}
          spin
          size='lg'
          className={clsx({
            'is-hidden': !(inProgress || updateUserLoading)
          })}
        />
      }
      title={locale.report_settings.digital_signature}
      actions={
        <>
          <button
            className='button is-outlined is-primary'
            onClick={() => {
              doClear.current()
            }}
          >
            {locale.report_settings.digital_signature_clear}
          </button>
          <button
            className='button is-outlined is-primary'
            style={{ marginLeft: 'auto' }}
            onClick={async () => {
              const updatedAt = new Date().toISOString()
              if (hasSignature) {
                try {
                  setInProgress(true)
                  const data = signatureCanvasRef.current.toDataURL()
                  const buffer = Buffer.from(
                    data.replace(/^data:image\/\w+;base64,/, ''),
                    'base64'
                  )
                  const signatureFileName = `${AWS.util.crypto.sha256(
                    buffer,
                    'hex'
                  )}.png`
                  const signatureKey = `images/${user.id}/digital_signature/${signatureFileName}`
                  const credentials = await currentCredentials()
                  const s3 = new S3({ credentials })
                  await s3
                    .putObject({
                      Bucket: process.env.S3BucketObjects,
                      Key: signatureKey,
                      Body: buffer,
                      ContentType: 'image/png'
                    })
                    .promise()

                  setInProgress(false)

                  updateUser({
                    variables: {
                      input: {
                        id: user.id,
                        updatedAt,
                        settings: {
                          reportDigitalSignature: {
                            service: 's3',
                            region: process.env.Region,
                            bucket: process.env.S3BucketObjects,
                            key: signatureKey,
                            fileName: signatureFileName,
                            type: 'image/png',
                            size: null
                          }
                        }
                      }
                    }
                  })
                } catch (error) {
                  setInProgress(false)
                  console.log('Error uploading file: ', error)
                }
              } else if (currentDigitalSignature != null) {
                // We aren't deleting it since we're storing pointer to this file in the report
                // try {
                //   setInProgress(true)
                //   const credentials = await currentCredentials()
                //   const s3 = new S3({ credentials })
                //   await s3
                //     .deleteObject({
                //       Bucket: process.env.S3BucketObjects,
                //       Key: currentDigitalSignature.key
                //     })
                //     .promise()
                //   setInProgress(false)
                // } catch (error) {
                //   setInProgress(false)
                //   console.log('Error deleting file: ', error)
                // }
                updateUser({
                  variables: {
                    input: {
                      id: user.id,
                      updatedAt,
                      settings: {
                        isDeleteReportDigitalSignature: true
                      }
                    }
                  }
                })
              }
            }}
            disabled={shouldDisableSubmit(
              inProgress,
              updateUserLoading,
              hasSignature,
              currentDigitalSignature,
              hasSignatureCleared
            )}
          >
            {locale.report_settings.submit}
          </button>
        </>
      }
      additionalClasses='modal-fit-to-content'
    >
      <SignatureCanvas
        signatureCanvasRef={signatureCanvasRef}
        setSignatureCanvasRef={setSignatureCanvasRef}
        hasSignature={hasSignature}
        setHasSignature={setHasSignature}
        currentSignature={currentDigitalSignature}
        setHasSignatureCleared={setHasSignatureCleared}
        disabled={inProgress || updateUserLoading}
        doClear={doClear}
      ></SignatureCanvas>
    </ModalCard>
  )
}

import React, { useEffect } from 'react'
import logo from '../../legacy/img/logo.png'
import { useLocalization } from '../LocalizationProvider'

export default function AuthScenery({ children }) {
  const { setLocaleKey, localeKey, locale } = useLocalization()

  useEffect(() => {
    document.body.classList.add('auth-scenery')
    return () => {
      document.body.classList.remove('auth-scenery')
    }
  }, [])

  return (
    <div>
      <img className='auth-scenery-logo' src={logo} />
      {children}
      <div className='auth-scenery-language-select select'>
        <select
          value={localeKey}
          onChange={(e) => {
            setLocaleKey(e.target.value)
            window.AUTH_CONFIG.clientMetadata.locale = e.target.value
          }}
        >
          <option value='en_US'>{locale.en}</option>
          <option value='ru_RU'>{locale.ru}</option>
          <option value='uk_UA'>{locale.uk}</option>
          <option value='zh_TW'>{locale.zh}</option>
        </select>
      </div>
    </div>
  )
}

import React, { useState, useEffect, useContext } from 'react'
import { gql, useQuery, useMutation } from '@apollo/client'
import { Link, navigate } from '@reach/router' // eslint-disable-line import/no-unresolved
import AuthContext from './AuthContext'
import Layout from './Layout'
import Loader from './Loader'
import { useLocalization } from './LocalizationProvider'

const REFERRAL_QUEUE_TYPE = gql`
  query REFERRAL_QUEUE_TYPE($referralId: ID!) {
    referral(id: $referralId) {
      referralsQueue {
        queueType
      }
    }
  }
`

const STUDY_REFERRAL_ASSIGN = gql`
  mutation STUDY_REFERRAL_ASSIGN(
    $input: ReferralUpdateInput!
    $targetClinic: ClinicConnectionInput!
    $targetCaregiver: CaregiverConnectionInput!
  ) {
    studyReferralAssign(
      input: $input
      targetClinic: $targetClinic
      targetCaregiver: $targetCaregiver
    ) {
      id
    }
  }
`

const ReferralsQueueAssign = ({ studyId, referralId }) => {
  const { locale } = useLocalization()
  const [isMounting, setIsMounting] = useState(true)
  const currentUser = useContext(AuthContext)
  const {
    data,
    loading: loadingQuery,
    error: errorQuery
  } = useQuery(REFERRAL_QUEUE_TYPE, {
    variables: {
      referralId
    }
  })
  const [referralAssign, { loading, error }] = useMutation(
    STUDY_REFERRAL_ASSIGN,
    {
      variables: {
        input: {
          id: referralId,
          updatedAt: new Date().toISOString()
        },
        targetClinic: {
          id: currentUser.clinic.id,
          name: currentUser.clinic.name
        },
        targetCaregiver: {
          id: currentUser.id,
          name: currentUser.name,
          email: currentUser.email
        }
      },
      update(cache) {
        if (
          currentUser &&
          currentUser.clinic &&
          currentUser.isTargetForReferrals
        ) {
          const clinicId = cache.identify(currentUser.clinic)
          cache.modify({
            id: clinicId,
            fields: {
              targetReferralsTotal: (value) => value + 1
            }
          })
          cache.evict({ id: clinicId, fieldName: 'targetReferrals' })
        }
      }
    }
  )

  useEffect(() => {
    if (!data) return
    async function callMe() {
      setIsMounting(false)
      try {
        const { data } = await referralAssign()
        if (data && data.studyReferralAssign.id) {
          navigate(`/study/${studyId}`)
        }
      } catch (e) {
        void e
      }
    }
    callMe()
  }, [data])

  return (
    <Layout>
      <div className='section'>
        <div className='container has-text-centered'>
          {isMounting || loading || loadingQuery ? (
            <Loader />
          ) : error || errorQuery ? (
            !errorQuery && error.message === 'ReferralTaken' ? (
              <>
                <p className='mb-4'>{locale.queue.errors.taken}</p>
                {data &&
                  data.referral.referralsQueue &&
                  data.referral.referralsQueue.queueType ===
                    'AssignmentAdminsAndCaregivers' && (
                    <p>
                      <Link to='/referrals' className='button'>
                        {locale.queue.requestsCta}
                      </Link>
                    </p>
                  )}
              </>
            ) : (
              <p>{locale.queue.errors.error}</p>
            )
          ) : (
            ''
          )}
        </div>
      </div>
    </Layout>
  )
}

export default ReferralsQueueAssign

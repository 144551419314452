import React, { useState, useContext } from 'react'
import AuthContext from '../AuthContext'
import { useQuery } from '@apollo/client'
import { gql, useMutation } from '@apollo/client'
import { GET_NON_ACTIVE_USERS } from '../queries'
import { DATE_FORMAT_CONVERTER } from '../../../utils/utils'
import dayjs from 'dayjs'
import clsx from 'clsx'

const USER_ACTIVATE = gql`
  mutation USER_ACTIVATE($id: ID!) {
    userActivate(id: $id) {
      id
    }
  }
`

const NonActiveUsers = () => {
  const currentUser = useContext(AuthContext)
  const [inProgress, setProgress] = useState(false)
  const { loading, error, data } = useQuery(GET_NON_ACTIVE_USERS)
  const [userActivate] = useMutation(USER_ACTIVATE, {
    refetchQueries: [
      {
        query: GET_NON_ACTIVE_USERS
      }
    ],
    awaitRefetchQueries: true
  })

  return (
    <div className='mt-5'>
      {loading && <p>Loading...</p>}
      {error && <div> error... </div>}
      {data && (
        <div>
          <table className='table is-fullwidth is-hoverable'>
            <thead>
              <tr>
                <th>Created at</th>
                <th>Email</th>
                <th>Activate</th>
              </tr>
            </thead>
            <tbody>
              {data.admin.nonActiveUsers.map((user) => (
                <tr key={user.id}>
                  <td>
                    {dayjs(user.createdAt).format(
                      `${
                        DATE_FORMAT_CONVERTER[currentUser.settings.dateFormat]
                      } HH:mm:ss`
                    )}
                  </td>
                  <td>{user.email}</td>
                  <td>
                    <button
                      className={clsx('button', {
                        'is-loading': inProgress
                      })}
                      onClick={async (e) => {
                        e.stopPropagation()
                        setProgress(true)
                        await userActivate({
                          variables: {
                            id: user.id
                          }
                        })
                        setProgress(false)
                      }}
                    >
                      Activate
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  )
}

export default NonActiveUsers

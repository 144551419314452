if (typeof window !== 'undefined') {

/*
    bcSpiroViewerTestView - Display the spirometer test
*/
var calibrationView = function(spiroViewer, calibrationManager) {
    this._spiroViewer = spiroViewer;
    this._calibrationManager = calibrationManager;     
           
    this._registerEventListeners();
};

/*
    Register event listeners
*/
calibrationView.prototype._registerEventListeners = function() {
    $('#startCalibration').on('click', $.proxy(this.onStartCalibrationClick,this));
};

calibrationView.prototype.update = function(flow) {
    $('#calibrartionFlowValue').text('Flow: ' + Number(parseFloat(flow)).toFixed(6));
};

calibrationView.prototype.onStartCalibrationClick = function(flow) {
    $('#startCalibration').hide();
    $('#calibrationValues').html('');
    this._calibrationManager.startCalibration();
};

calibrationView.prototype.onCalibrationEnded = function(results) {
    $('#calibrationValues').html("Avg: " + results.avg + "<br>Max: " + results.max + "<br>Min: " + results.min);
    $('#startCalibration').show(); 
};

window.calibrationView = calibrationView;

}
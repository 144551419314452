if (typeof window !== 'undefined') {

/*
    Implement common functions for data fetchers
*/
var DataFetcherUtils = {
    /*
        Fetch data from API
    */
    _fetch: function(config) {
        var complete = config.complete;
        config.complete = $.proxy(function() {
            complete && complete.apply(this, arguments);
        }, this);
        return this._callAPI(config);
    },
        
    /*
        Wrapper around jquery ajax in case we'll want to replace it 
    */
    _callAPI: function(config) {
        return $.ajax(config);
    }
}

window.DataFetcherUtils = DataFetcherUtils;

}
import React from 'react'
import { SignUp as OriginalSignUp } from 'aws-amplify-react'
import { navigate } from '@reach/router' // eslint-disable-line import/no-unresolved
import SignUpCore from './SignUpCore'
import AccountType from './AccountType'
import { hashPassword } from '../../../utils/utils'

export class SignUp extends OriginalSignUp {
  constructor(props) {
    super(props)
    this._validAuthStates = ['signUp']
    this.signUpFields = [
      {
        key: 'username',
        required: true
      },
      // {
      //   // remove it - it is only for testing
      //   key: 'preferred_username',
      //   required: true
      // },
      {
        key: 'password',
        required: true
      }
    ]
    this._signUp = this._signUp.bind(this)
    this.error = this.error.bind(this)
    this.state = {
      error: null,
      authState: window.AUTH_CONFIG.clientMetadata.userType
        ? 'signUp'
        : 'accountType',
      originalPassword: null
    }
  }

  error(err) {
    this.inputs.password = this.state.originalPassword
    this.setState({
      error: err.message ? err.message : err,
      originalPassword: null
    })
  }

  _signUp(event) {
    const _originalPassword = this.inputs.password
    if (this.state.error) {
      this.setState({ error: null, originalPassword: _originalPassword })
    } else {
      this.setState({ originalPassword: _originalPassword })
    }
    this.inputs.password = hashPassword(
      this.inputs.username,
      this.inputs.password
    )
    window.loginDetails = {
      username: this.inputs.username,
      password: _originalPassword
    }
    this.signUp(event)
  }

  showComponent() {
    if (this.state.authState === 'signUp') {
      return (
        <SignUpCore
          handleInputChange={(evt) => {
            if (evt.target.value) {
              evt.target.value = evt.target.value.trim()
            }
            return this.handleInputChange(evt)
          }}
          changeState={(props) => {
            if (this.state.error) {
              this.setState({ error: null })
            }
            navigate('/')
            this.setState({ authState: 'accountType' })
            this.changeState(props)
          }}
          signUp={this._signUp}
          error={this.state.error}
          inProgress={this.state.requestPending}
        />
      )
    }
    return (
      <AccountType
        changeState={(props) => {
          if (props === 'signUp') {
            this.setState({ authState: 'signUp' })
          } else {
            this.changeState(props)
          }
        }}
      />
    )
  }
}

export default SignUp

import React, { useContext, useState, useReducer } from 'react'
import { gql, useApolloClient, useQuery, useMutation } from '@apollo/client'
import clsx from 'clsx'
import countries from 'i18n-iso-countries'
import countriesLocaleEn from 'i18n-iso-countries/langs/en.json'
import AuthContext from './AuthContext'
import { GET_USER_DATA } from './queries'
import Layout from './Layout'
import EmailEdit from './Auth/EmailEdit'
import PasswordEdit from './Auth/PasswordEdit'
import LogoManager from './LogoManager'
import Loader from './Loader'
import {
  removeEmptyValuesAndObjects,
  objectToFieldsConverter
} from '../../utils/utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencil } from '@fortawesome/free-solid-svg-icons'
import { useLocalization } from './LocalizationProvider'
import SomethingWentWrong from './SomethingWentWrong'

countries.registerLocale(countriesLocaleEn)
const countryNames = countries.getNames('en')

function reducer(state, action) {
  if (action.type === 'INIT') return {}
  const newState = { ...state }
  if (action.value === action.original) {
    delete newState[action.key]
  } else newState[action.key] = action.value
  return newState
}

function getRequiredFieldsIfSet(localData = {}, address = {}) {
  const result = []
  Object.keys(localData).forEach((field) => {
    if (!localData[field] && ['firstName', 'lastName'].indexOf(field) > -1) {
      result.push(field)
    }
  })
  Object.keys(address).forEach((field) => {
    if (!address[field] && ['country'].indexOf(field) > -1) result.push(field)
  })
  return result
}

const USER_PROFILE_UPDATE = gql`
  mutation USER_PROFILE_UPDATE(
    $input: UserUpdateInput!
    $id: ID!
    $nameHasChanged: Boolean!
  ) {
    userUpdate(input: $input) {
      id
    }
    # the name is automatically added to the cache by apollo client
    query @include(if: $nameHasChanged) {
      user(id: $id) {
        id
        name
        clinic {
          name
        }
      }
    }
  }
`

const Profile = () => {
  const [isEmailEditMode, setEmailEditMode] = useState(false)
  const [isPasswordEditMode, setPasswordEditMode] = useState(false)
  const [isLogoEditMode, setLogoEditMode] = useState(false)
  const [localData, dispatchData] = useReducer(reducer, {})
  const [clinic, dispatchClinic] = useReducer(reducer, {})
  const [address, dispatchAddress] = useReducer(reducer, {})
  const [requiredFieldsIfSet, setRequiredFieldsIfSet] = useState([])
  const [isProfileUpdated, setIsProfileUpdated] = useState(false)
  const { locale } = useLocalization()

  const hasNewData =
    Object.keys(localData).length +
      Object.keys(clinic).length +
      Object.keys(address).length >
    0

  const { id, email } = useContext(AuthContext)
  const { loading, error, data } = useQuery(GET_USER_DATA, {
    variables: { id, isAuthenticated: true }
  })

  const [updateUser, { loading: updateUserLoading }] = useMutation(
    USER_PROFILE_UPDATE,
    {
      onCompleted: () => {
        dispatchData({ type: 'INIT' })
        dispatchClinic({ type: 'INIT' })
        dispatchAddress({ type: 'INIT' })
        setRequiredFieldsIfSet([])
        setIsProfileUpdated(true)
      }
    }
  )

  const client = useApolloClient()

  if (loading)
    return (
      <Layout>
        <Loader />
      </Layout>
    )

  if (error) return <SomethingWentWrong />

  return (
    <Layout>
      {isEmailEditMode && (
        <EmailEdit
          id={id}
          oldEmail={email}
          onComplete={(email) => {
            setEmailEditMode(false)
            client.cache.modify({
              id: client.cache.identify(data.user),
              fields: {
                email: () => email
              }
            })
          }}
          onExit={() => setEmailEditMode(false)}
        />
      )}
      {isPasswordEditMode && (
        <PasswordEdit
          onComplete={() => setPasswordEditMode(false)}
          onExit={() => setPasswordEditMode(false)}
        />
      )}
      {isLogoEditMode && (
        <LogoManager
          user={data.user}
          currentLogo={data.user.clinic.logo}
          onComplete={() => setLogoEditMode(false)}
          onExit={() => setLogoEditMode(false)}
        />
      )}

      <div className='section'>
        <div className='container'>
          <div className='box'>
            <h2 className='subtitle is-5 has-text-weight-medium'>
              {locale.profile.personal_info}
            </h2>
            <hr />
            <div className='field is-horizontal'>
              <div className='field-body'>
                <div className='field'>
                  <label className='label'>{locale.profile.first_name}</label>
                  <div className='control'>
                    <input
                      className={clsx('input', {
                        'is-danger':
                          requiredFieldsIfSet.indexOf('firstName') > -1
                      })}
                      type='text'
                      value={
                        typeof localData.firstName === 'undefined'
                          ? data.user.firstName || ''
                          : localData.firstName
                      }
                      onChange={(e) =>
                        dispatchData({
                          key: 'firstName',
                          value: e.target.value,
                          original: data.user.firstName
                        })
                      }
                    />
                  </div>
                  <p
                    className={clsx('help is-danger', {
                      'is-hidden':
                        requiredFieldsIfSet.indexOf('firstName') === -1
                    })}
                  >
                    {locale.profile.required}
                  </p>
                </div>
                <div className='field'>
                  <label className='label'>{locale.profile.middle_name}</label>
                  <div className='control'>
                    <input
                      className='input'
                      type='text'
                      value={
                        typeof localData.middleName === 'undefined'
                          ? data.user.middleName || ''
                          : localData.middleName
                      }
                      onChange={(e) =>
                        dispatchData({
                          key: 'middleName',
                          value: e.target.value,
                          original: data.user.middleName
                        })
                      }
                    />
                  </div>
                </div>
                <div className='field'>
                  <label className='label'>{locale.profile.last_name}</label>
                  <div className='control'>
                    <input
                      className={clsx('input', {
                        'is-danger':
                          requiredFieldsIfSet.indexOf('lastName') > -1
                      })}
                      type='text'
                      value={
                        typeof localData.lastName === 'undefined'
                          ? data.user.lastName || ''
                          : localData.lastName
                      }
                      onChange={(e) =>
                        dispatchData({
                          key: 'lastName',
                          value: e.target.value,
                          original: data.user.lastName
                        })
                      }
                    />
                  </div>
                  <p
                    className={clsx('help is-danger', {
                      'is-hidden':
                        requiredFieldsIfSet.indexOf('lastName') === -1
                    })}
                  >
                    {locale.profile.required}
                  </p>
                </div>
                <div className='field'>
                  <label className='label'>{locale.profile.user_id}</label>
                  <div className='control'>
                    <input
                      className='input'
                      type='text'
                      value={
                        typeof localData.uId === 'undefined'
                          ? data.user.uId || ''
                          : localData.uId
                      }
                      onChange={(e) =>
                        dispatchData({
                          key: 'uId',
                          value: e.target.value,
                          original: data.user.uId
                        })
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='box'>
            <h2 className='subtitle is-5 has-text-weight-medium'>
              {locale.profile.login_details}
            </h2>
            <hr />
            <div className='field is-horizontal'>
              <div className='field-body'>
                <div className='field'>
                  <label className='label'>{locale.profile.password}</label>
                  <div className='control'>
                    <button
                      className='button is-outlined is-primary'
                      onClick={() => setPasswordEditMode(true)}
                    >
                      {locale.profile.update_password}
                    </button>
                  </div>
                </div>
                <div className='field'>
                  <label className='label'>{locale.profile.email}</label>
                  <div className='field has-addons'>
                    <p className='control'>
                      <button className='button is-static'>
                        {data.user.email}
                      </button>
                    </p>
                    <p className='control'>
                      <button
                        className='button is-outlined is-primary'
                        onClick={() => setEmailEditMode(true)}
                      >
                        <FontAwesomeIcon icon={faPencil} />
                      </button>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='box'>
            <h2 className='subtitle is-5 has-text-weight-medium'>
              {locale.profile.clinic_info}
            </h2>
            <hr />
            <div className='field is-horizontal'>
              <div className='field-body'>
                <div className='field'>
                  <label className='label'>
                    {locale.profile.hospital_name}
                  </label>
                  <div className='control'>
                    <input
                      className={clsx('input', {
                        'is-danger': requiredFieldsIfSet.indexOf('name') > -1
                      })}
                      type='text'
                      value={
                        typeof clinic.name === 'undefined'
                          ? data.user.clinic.name || ''
                          : clinic.name
                      }
                      onChange={(e) =>
                        dispatchClinic({
                          key: 'name',
                          value: e.target.value,
                          original: data.user.clinic.name
                        })
                      }
                    />
                  </div>
                  <p
                    className={clsx('help is-danger', {
                      'is-hidden': requiredFieldsIfSet.indexOf('name') === -1
                    })}
                  >
                    {locale.profile.required}
                  </p>
                </div>
                <div className='field'>
                  <label className='label'>{locale.profile.phone}</label>
                  <div className='control'>
                    <input
                      className='input'
                      type='tel'
                      value={
                        typeof clinic.phone === 'undefined'
                          ? data.user.clinic.phone || ''
                          : clinic.phone
                      }
                      onChange={(e) =>
                        dispatchClinic({
                          key: 'phone',
                          value: e.target.value,
                          original: data.user.clinic.phone
                        })
                      }
                    />
                  </div>
                </div>
                <div className='field'>
                  <label className='label'>{locale.profile.country}</label>
                  <div className='control is-expanded'>
                    <div className='select is-fullwidth'>
                      <select
                        value={
                          typeof address.country === 'undefined'
                            ? data.user.clinic.address.country || ''
                            : address.country
                        }
                        onChange={(e) =>
                          dispatchAddress({
                            key: 'country',
                            value: e.target.value,
                            original: data.user.clinic.address.country
                          })
                        }
                      >
                        {Object.keys(countryNames).map((country) => (
                          <option value={country} key={country}>
                            {countryNames[country]}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='field is-horizontal'>
              <div className='field-body'>
                <div className='field'>
                  <label className='label'>{locale.profile.address1}</label>
                  <div className='control'>
                    <input
                      className='input'
                      type='text'
                      value={
                        typeof address.address1 === 'undefined'
                          ? data.user.clinic.address.address1 || ''
                          : address.address1
                      }
                      onChange={(e) =>
                        dispatchAddress({
                          key: 'address1',
                          value: e.target.value,
                          original: data.user.clinic.address.address1
                        })
                      }
                    />
                  </div>
                </div>
                <div className='field'>
                  <label className='label'>{locale.profile.address2}</label>
                  <div className='control'>
                    <input
                      className='input'
                      type='text'
                      value={
                        typeof address.address2 === 'undefined'
                          ? data.user.clinic.address.address2 || ''
                          : address.address2
                      }
                      onChange={(e) =>
                        dispatchAddress({
                          key: 'address2',
                          value: e.target.value,
                          original: data.user.clinic.address.address2
                        })
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='field is-horizontal'>
              <div className='field-body'>
                <div className='field'>
                  <label className='label'>{locale.profile.city}</label>
                  <div className='control'>
                    <input
                      className='input'
                      type='text'
                      value={
                        typeof address.city === 'undefined'
                          ? data.user.clinic.address.city || ''
                          : address.city
                      }
                      onChange={(e) =>
                        dispatchAddress({
                          key: 'city',
                          value: e.target.value,
                          original: data.user.clinic.address.city
                        })
                      }
                    />
                  </div>
                </div>
                <div className='field'>
                  <label className='label'>{locale.profile.region}</label>
                  <div className='control'>
                    <input
                      className='input'
                      type='text'
                      value={
                        typeof address.region === 'undefined'
                          ? data.user.clinic.address.region || ''
                          : address.region
                      }
                      onChange={(e) =>
                        dispatchAddress({
                          key: 'region',
                          value: e.target.value,
                          original: data.user.clinic.address.region
                        })
                      }
                    />
                  </div>
                </div>
                <div className='field'>
                  <label className='label'>{locale.profile.zipcode}</label>
                  <div className='control'>
                    <input
                      className='input'
                      type='text'
                      value={
                        typeof address.zipcode === 'undefined'
                          ? data.user.clinic.address.zipcode || ''
                          : address.zipcode
                      }
                      onChange={(e) =>
                        dispatchAddress({
                          key: 'zipcode',
                          value: e.target.value,
                          original: data.user.clinic.address.zipcode
                        })
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='field'>
              <label className='label'>{locale.profile.logo}</label>
              <button
                className='button is-outlined is-primary'
                onClick={() => setLogoEditMode(true)}
              >
                {locale.profile.update_logo}
              </button>
            </div>
          </div>
          <div className='is-flex is-align-items-center'>
            <button
              className={clsx('button is-primary', {
                'is-loading': updateUserLoading
              })}
              disabled={!hasNewData || updateUserLoading}
              onClick={async () => {
                const _requiredFields = getRequiredFieldsIfSet(
                  localData,
                  address
                )
                if (_requiredFields.length > 0) {
                  setRequiredFieldsIfSet(_requiredFields)
                  return
                }
                setRequiredFieldsIfSet([])

                const updatedAt = new Date().toISOString()
                const input = removeEmptyValuesAndObjects(localData)
                if (
                  Object.keys(clinic).length + Object.keys(address).length >
                  0
                ) {
                  input.clinic = {
                    id,
                    address: removeEmptyValuesAndObjects(address),
                    ...removeEmptyValuesAndObjects({ ...clinic })
                  }
                }
                updateUser({
                  variables: {
                    input: {
                      id,
                      updatedAt,
                      ...input
                    },
                    id,
                    nameHasChanged:
                      typeof localData.firstName !== 'undefined' ||
                      typeof localData.middleName !== 'undefined' ||
                      typeof localData.lastName !== 'undefined'
                  },
                  update: (cache) => {
                    cache.modify({
                      id: cache.identify(data.user),
                      fields: objectToFieldsConverter(
                        removeEmptyValuesAndObjects(localData)
                      )
                    })
                    input.clinic &&
                      cache.modify({
                        id: cache.identify(data.user.clinic),
                        fields: objectToFieldsConverter(input.clinic)
                      })
                  }
                })
              }}
            >
              {locale.profile.submit}
            </button>
            &nbsp;
            <span
              className={clsx('help is-primary', {
                'is-hidden': !isProfileUpdated
              })}
            >
              {locale.profile.profile_updated}
            </span>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Profile

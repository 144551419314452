import React, { useEffect, useState } from 'react'
import { GET_ADMIN_DATA_FROM_USER_EMAIL } from '../queries'
import { useLazyQuery } from '@apollo/client'
import { navigate } from '@reach/router' // eslint-disable-line import/no-unresolved
import clsx from 'clsx'
import AdminUserReferrals from './AdminUserReferrals'
import AdminUserShares from './AdminUserShares'
import AdminUserServicePlan from './AdminUserServicePlan'
import AdminUserDevices from './AdminUserDevices'
import AdminUserStudies from './AdminUserStudies'
import CreateStudiesCopy from './CreateStudiesCopy'
import ChangeUserType from './ChangeUserType'
import UpdateUserGroup from './UpdateUserGroup'
import SetUserPassword from './SetUserPassword'
import UpdateUserQuestionnaireTemplate from './UpdateUserQuestionnaireTemplate'
import { useAdmin } from '../AdminProvider'
import AdminDebugData from './AdminDebugData'

const AdminUserDetails = ({ userEmail }) => {
  const { setViewAsUser } = useAdmin()
  const [tab, setTab] = useState(null)
  const [isCopyMode, setCopyMode] = useState(false)
  const [isUserTypeMode, setUserTypeMode] = useState(false)
  const [isSetGroupMode, setGroupMode] = useState(false)
  const [isUserQuestionnaireTemplateMode, setUserQuestionnaireTemplateMode] =
    useState(false)

  const [isSetUserPasswordMode, setUserPasswordMode] = useState(false)
  const [getUser, { loading, error, data }] = useLazyQuery(
    GET_ADMIN_DATA_FROM_USER_EMAIL,
    {
      fetchPolicy: 'network-only'
    }
  )

  function searchUserByEmail() {
    getUser({
      variables: {
        email: userEmail.trim()
      }
    })
  }

  useEffect(() => {
    userEmail.trim() && searchUserByEmail()
  }, [userEmail])

  const { admin } = data || {}
  const { userByEmail } = admin || {}
  const { clinic, ...userData } = userByEmail || {}

  return (
    <div className='container'>
      {loading && <p>Loading...</p>}
      {error && <div> error... </div>}

      {data && data.admin && !data.admin.userByEmail && (
        <div> User not found </div>
      )}

      {userData && isCopyMode && (
        <CreateStudiesCopy
          clinicId={clinic.id}
          onComplete={() => setCopyMode(false)}
          onExit={() => setCopyMode(false)}
        />
      )}

      {userData && isUserTypeMode && (
        <ChangeUserType
          user={userData}
          onComplete={() => setUserTypeMode(false)}
          onExit={() => setUserTypeMode(false)}
        />
      )}

      {userData && isSetGroupMode && (
        <UpdateUserGroup
          user={userData}
          onComplete={() => setGroupMode(false)}
          onExit={() => setGroupMode(false)}
        />
      )}

      {userData && isUserQuestionnaireTemplateMode && (
        <UpdateUserQuestionnaireTemplate
          user={userData}
          onComplete={() => setUserQuestionnaireTemplateMode(false)}
          onExit={() => setUserQuestionnaireTemplateMode(false)}
        />
      )}

      {userData && isSetUserPasswordMode && (
        <SetUserPassword
          user={userData}
          onComplete={() => setUserPasswordMode(false)}
          onExit={() => setUserPasswordMode(false)}
        />
      )}

      {userData.id && (
        <div className='mt-5'>
          <div>
            <span className='mr-3'>Name:</span>
            <span className='has-text-weight-bold'>{userData.name}</span>
            <span className='ml-3 mr-3'>|</span>
            <span className='mr-3'>Clinic:</span>
            <span className='has-text-weight-bold'>{clinic.name}</span>
            <span className='ml-3 mr-3'>|</span>
            <span className='mr-3'>Email:</span>
            <span className='has-text-weight-bold'>{userData.email}</span>
            <span className='ml-3 mr-3'>|</span>
            <span className='mr-3'>ID:</span>
            <span className='has-text-weight-bold'> {userData.id}</span>
            <span className='ml-3 mr-3'>|</span>
            <span className='mr-3'>Type:</span>
            <span className='has-text-weight-bold'> {userData.userType}</span>
            {userData.isCardiologist && (
              <>
                <span className='ml-3 mr-3'>|</span>
                <span className='has-text-weight-bold'>Cardiologist</span>
              </>
            )}
            {userData.isAdmin && (
              <>
                <span className='ml-3 mr-3'>|</span>
                <span className='has-text-weight-bold'>Admin</span>
              </>
            )}
            {userData.group && (
              <>
                <span className='ml-3 mr-3'>|</span>
                <span className='mr-3'>Group:</span>
                <span className='has-text-weight-bold'>{userData.group}</span>
              </>
            )}
            {userData.questionnaireTemplate && (
              <>
                <span className='ml-3 mr-3'>|</span>
                <span className='mr-3'>Questionnaire:</span>
                <span className='has-text-weight-bold'>
                  {userData.questionnaireTemplate.id}
                </span>
              </>
            )}
          </div>
        </div>
      )}

      {userData.id && (
        <div className='mt-5'>
          <button
            className='button is-outlined'
            onClick={() => setCopyMode(true)}
          >
            Copy studies
          </button>
          <button
            className='button is-outlined ml-3'
            onClick={() => setUserTypeMode(true)}
          >
            Change user type
          </button>
          <button
            className='button is-outlined ml-3'
            onClick={() => setGroupMode(true)}
          >
            Set group
          </button>
          <button
            className='button is-outlined ml-3'
            onClick={() => setUserQuestionnaireTemplateMode(true)}
          >
            Set questionnaire
          </button>
          <button
            className='button is-outlined ml-3'
            onClick={() => setUserPasswordMode(true)}
          >
            Set password
          </button>
          <button
            className='button is-outlined ml-3'
            onClick={() => {
              setViewAsUser({
                id: userData.id,
                name: userData.settings.displayName,
                email: userData.email
              })
              navigate('/')
            }}
          >
            View the site as this user
          </button>
        </div>
      )}

      {userData.id && (
        <div className='mt-5'>
          <div className='tabs is-boxed'>
            <ul>
              <li
                className={clsx({
                  'is-active': tab === 'studies' || !tab
                })}
              >
                <a onClick={() => setTab('studies')}>Studies</a>
              </li>
              <li
                className={clsx({
                  'is-active': tab === 'referrals'
                })}
              >
                <a onClick={() => setTab('referrals')}>Referrals</a>
              </li>
              <li
                className={clsx({
                  'is-active': tab === 'shares'
                })}
              >
                <a onClick={() => setTab('shares')}>Shares</a>
              </li>
              <li
                className={clsx({
                  'is-active': tab === 'servicePlan'
                })}
              >
                <a onClick={() => setTab('servicePlan')}>Service plans</a>
              </li>
              <li
                className={clsx({
                  'is-active': tab === 'devices'
                })}
              >
                <a onClick={() => setTab('devices')}>Devices</a>
              </li>
              <li
                className={clsx({
                  'is-active': tab === 'debugData'
                })}
              >
                <a onClick={() => setTab('debugData')}>Debug data</a>
              </li>
            </ul>
          </div>
        </div>
      )}

      {userData.id && (
        <div className='box'>
          {(!tab || tab == 'studies') && (
            <div>
              <AdminUserStudies clinicId={clinic.id}></AdminUserStudies>
            </div>
          )}

          {tab == 'referrals' && (
            <div>
              <AdminUserReferrals clinicId={clinic.id}></AdminUserReferrals>
            </div>
          )}

          {tab == 'shares' && (
            <div>
              <AdminUserShares user={userByEmail}></AdminUserShares>
            </div>
          )}

          {tab == 'servicePlan' && (
            <div>
              <AdminUserServicePlan clinic={clinic}></AdminUserServicePlan>
            </div>
          )}

          {tab == 'devices' && (
            <div>
              <AdminUserDevices clinic={clinic}></AdminUserDevices>
            </div>
          )}

          {tab == 'debugData' && (
            <div>
              <AdminDebugData
                userId={userData.id}
                clinic={clinic}
                rawData={JSON.stringify(
                  { ...userData, clinic: clinic },
                  null,
                  '\t'
                )}
              ></AdminDebugData>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default AdminUserDetails

import React, { useState, useReducer, useEffect, useRef } from 'react'
import { gql, useMutation, useApolloClient } from '@apollo/client'
import clsx from 'clsx'
import dayjs from 'dayjs'
import { Link } from '@reach/router' // eslint-disable-line import/no-unresolved
import { v4 as uuidv4 } from 'uuid'
import AWS from 'aws-sdk/global'
import S3 from 'aws-sdk/clients/s3'
// import aws4 from 'aws4'
import { currentCredentials } from '../../utils/credentials'
import useDynamicStylesheets from '../hooks/useDynamicStylesheets'
import PatientDetailsOld from './PatientDetailsOld'
import BCSpiroViewer from './BCSpiroViewer'
import StudySummary from './StudySummary'
import { GET_REPORT_DATA } from './queries'
import poweredByLogo from '../legacy/img/powered_by_logo.png'
import {
  DATE_FORMAT_CONVERTER,
  isAuthorizedAction,
  isRTL,
  durationToHuman,
  adjustTimeToTimezone,
  getParams,
  interpolateConclusionTemplate,
  encodeViewerState,
  decodeViewerState
} from '../../utils/utils'
import { useLocalization } from './LocalizationProvider'

import BCViewer from './BCViewer'

import MeasurementValues from './MeasurementValues'

import '../legacy/lib/jquery'
import '../legacy/js/study_summary'
import '../legacy/js/measurement_values_manager'
import '../legacy/report/twitter-text'
import '../legacy/report/RTLText'
import '../legacy/report/patients_base'
import '../legacy/report/patient_details_table'
// import '../report/conclusion_template_select_manager'
import '../legacy/report/report_manager'
import '../legacy/report/autosize.min'
import Loader from './Loader'

import '../legacy/stylesheets/ConclusionTemplateSelect.css'

const MARK_STUDY_AS_SEEN2 = gql`
  mutation MARK_STUDY_AS_SEEN2($studyId: ID!, $clinicId: ID!) {
    studyMarkAsSeen(studyId: $studyId, clinicId: $clinicId) {
      id
    }
  }
`

const DATE_FORMAT_CONVERTER_LEGACY = {
  DDMMYYYY: 'dd/MM/yyyy',
  MMDDYYYY: 'MM/dd/yyyy'
}

const initialState = {
  measurements: JSON.stringify({
    HR: '',
    P: '',
    P_MV: '',
    PR: '',
    QRS: '',
    QT: '',
    R: ''
  }),
  mea: null,
  conclusion: ''
}

function reducerBuilder(state, [key, ...keys], payload) {
  if (keys.length === 0) {
    return {
      ...state,
      [key]: payload
    }
  }
  return {
    ...state,
    [key]: reducerBuilder(state[key], keys, payload)
  }
}

function reducer(state, action) {
  if (action.type === 'INIT') return {}
  return reducerBuilder(state, action.key.split('.'), action.payload)
}

const RECORDS_TYPES = {
  RecordEcgRestData: 1,
  RecordEcgHolterData: 2,
  RecordStethoscopeData: 4,
  RecordSpirometerData: 8192,
  RecordQuestionnaireData: 32768
}

const CREATE_REPORT = gql`
  mutation CREATE_REPORT(
    $studyId: ID!
    $caregiver: CaregiverConnectionInput!
    $input: StudyReportCreateInput!
  ) {
    studyReportCreate(studyId: $studyId, caregiver: $caregiver, input: $input) {
      id
      conclusion
      measurements
      state
      mea
      updatedAt
      caregiver {
        id
        name
      }
      signature
      digitalSignature {
        bucket
        key
      }
      authorizedActions {
        allow
        deny
      }
    }
  }
`

const UPDATE_REPORT = gql`
  mutation UPDATE_REPORT(
    $caregiver: CaregiverConnectionInput!
    $input: StudyReportUpdateInput!
  ) {
    studyReportUpdate(caregiver: $caregiver, input: $input) {
      id
    }
  }
`

function initReportManager(
  currentUser,
  study,
  report,
  hasReport,
  queryParams,
  userSettings,
  conclusionTemplates,
  isStudyMode,
  setInitialMEA,
  locale
) {
  const restRecord = study.records.filter(
    (record) => record.__typename === 'RecordEcgRestData'
  )[0]
  const isRestRecord = !!restRecord

  const recordTypes = {
    ARNIKA: 0,
    PHYSIONET: 1,
    MOBILE: 2,
    I_FEEL_LABS: 3
  }

  const params = {
    is_country_us: false,
    is_questionnaire: queryParams.questionnaire,
    is_print_mode: queryParams.print,
    isVeterinarian: study.caregiver.userType === 'Veterinarian',
    has_conclusion: hasReport,
    is_study_mode: isStudyMode,
    isShowMeanElectricalAxisChart: !(
      currentUser && currentUser.email === 'info@elecografista.com'
    ),
    recordTypes,
    studyTypes: {
      rest: 1,
      holter: 2,
      stethoscope: 4,
      i_feel_labs_daily_practice: 8,
      i_feel_labs_overnight_monitoring: 16,
      i_feel_labs_questionnaires_motivation: 32,
      i_feel_labs_questionnaires_exercise_challenge: 64,
      i_feel_labs_questionnaires_daily: 128,
      i_feel_labs_questionnaires_weekly: 256,
      i_feel_labs_questionnaires_weeks_0_1_8_15: 512,
      i_feel_labs_questionnaires_end_of_study: 1024,
      i_feel_labs_questionnaires_hosen: 2048,
      i_feel_labs_questionnaires_cf: 4096,
      spirometer: 8192,
      ecg: 16384,
      questionnaire: 32768
    },
    studyHeaderLocale: {
      birthdate_format: DATE_FORMAT_CONVERTER_LEGACY[userSettings.dateFormat],
      months: locale.date.months,
      days: locale.date.days,
      unknown: locale.unknown
    },
    study: {
      hash_id: study.id,
      patient_hash_id: study.patient.id,
      record_type: recordTypes.MOBILE,
      has_ecg_signal: isRestRecord,
      study_type: study.records.reduce(
        (result, record) => result | (RECORDS_TYPES[record.__typename] || 0),
        0
      ),
      age: study.anonymousPatientData.age,
      link: isRestRecord ? restRecord.file.url : null
    },
    userSettings: {
      locale: userSettings.locale,
      paper_size: userSettings.reportPaperSize,
      add_full_disclosure: isStudyMode
    },
    conclusion_templates: conclusionTemplates,
    generalLocale: {
      anonymous: locale.anonymous
    }
  }

  window.bcGlobals = window.bcGlobals || {}

  Object.keys(params).forEach((key) => {
    window.bcGlobals[key] = params[key]
  })

  const p = {
    hash_id: study.patient.id,
    name: study.patient.name,
    email: study.patient.email,
    phone: study.patient.phone,
    gender: study.anonymousPatientData.gender,
    weight: study.anonymousPatientData.weight,
    height: study.anonymousPatientData.height
  }
  p.encrypted_data = JSON.stringify(p)
  window.bcGlobals.patientsManager = new window.PatientsManager([p])

  window.reportManager = new window.ReportManager(null, setInitialMEA)

  return () => {
    if (window.reportManager) {
      window.reportManager.cleanup()
      delete window.reportManager
      window.reportManager = undefined
    }
    Object.keys(params).forEach((key) => {
      window.bcGlobals[key] = undefined
    })
    delete window.bcGlobals.patientsManager
    window.bcGlobals.patientsManager = undefined
  }
}

const ReportCore = ({
  currentUser,
  study,
  report,
  location: { search },
  setReportId
}) => {
  const isCSSLoaded = useDynamicStylesheets(
    [
      () =>
        import(
          /* webpackChunkName: "report" */ '../legacy/report/stylesheets/main.css'
        ),
      () =>
        import(
          /* webpackChunkName: "report" */ '../legacy/report/stylesheets/study_report.css'
        ),
      () =>
        import(
          /* webpackChunkName: "report" */ '../legacy/report/stylesheets/patient_details_table.css'
        ),
      () =>
        import(
          /* webpackChunkName: "report" */ '../legacy/stylesheets/StudySummary.css'
        )
    ],
    'report'
  )

  const { locale } = useLocalization()
  const queryParams = getParams(search)
  const hasReport = !!report
  const [conclusionTemplates] = useState(
    currentUser.settings.reportConclusionTemplates
      ? JSON.parse(currentUser.settings.reportConclusionTemplates)
      : null
  )
  const [reportData, dispatchReportData] = useReducer(reducer, {
    measurements: JSON.parse(
      (report && report.measurements) ||
        (study.diagnosis && study.diagnosis.measurements) ||
        (study.measurements && study.measurements.measurements) ||
        initialState.measurements
    ),
    mea: (report || study.diagnosis || initialState).mea,
    conclusion: hasReport
      ? report.conclusion
      : !(study.diagnosis && typeof study.diagnosis.conclusion === 'string') &&
        conclusionTemplates &&
        conclusionTemplates.default_template
      ? interpolateConclusionTemplate(
          conclusionTemplates.list[conclusionTemplates.default_template].text,
          currentUser
        )
      : study.diagnosis && study.diagnosis.conclusion
      ? study.diagnosis.conclusion
      : ''
  })
  const _defaultData = report || initialState
  const [defaultData, setDefaultData] = useState({
    measurements: JSON.parse(_defaultData.measurements),
    mea: _defaultData.mea,
    conclusion: _defaultData.conclusion
  })

  const hasFormChanged =
    JSON.stringify(defaultData) !== JSON.stringify(reportData)

  const [logoUrl, setLogoUrl] = useState(null)
  const [inProgressUpdateLegacyClinic, setInProgressUpdateLegacyClinic] =
    useState(false)

  const client = useApolloClient()

  useEffect(() => {
    if (!study.clinic.logo) return
    async function callMe() {
      const credentials = await currentCredentials()
      const s3 = new S3({ credentials })
      setLogoUrl(
        s3.getSignedUrl('getObject', {
          Bucket: study.clinic.logo.bucket,
          Key: study.clinic.logo.key
        })
      )
    }
    callMe()
  }, [])

  const [digitalSigntatureUrl, setDigitalSigntatureUrl] = useState(null)
  useEffect(() => {
    if (!report || !report.digitalSignature) {
      setDigitalSigntatureUrl(null)
      return
    }
    async function callMe() {
      const credentials = await currentCredentials()
      const s3 = new S3({ credentials })

      setDigitalSigntatureUrl(
        s3.getSignedUrl('getObject', {
          Bucket: report.digitalSignature.bucket,
          Key: report.digitalSignature.key
        })
      )
    }
    callMe()
  }, [report])

  const conclusionTextarea = useRef()
  useEffect(() => {
    document.documentElement.style = 'height: initial;'
    return () => {
      document.documentElement.style = 'height: 100%;'
    }
  }, [])
  // move textarea cursor to end so the first template insertion will be to the end
  useEffect(() => {
    if (
      conclusionTextarea &&
      conclusionTextarea.current &&
      reportData.conclusion &&
      isCSSLoaded
    ) {
      conclusionTextarea.current.setSelectionRange(
        reportData.conclusion.length,
        reportData.conclusion.length
      )
    }
  }, [conclusionTextarea, isCSSLoaded])
  useEffect(() => {
    if (!isCSSLoaded) return

    if (hasReport && report.state) {
      window.location.hash = decodeViewerState(report.state)
    }

    const cleanup = initReportManager(
      currentUser,
      study,
      report,
      hasReport,
      queryParams,
      currentUser.settings,
      conclusionTemplates,
      queryParams.study_mode === 'true',
      (initialMEA) =>
        dispatchReportData({
          key: 'mea',
          payload: (report || study.diagnosis || initialMEA || initialState).mea
        }),
      locale
    )
    return () => {
      cleanup()
    }
  }, [report, isCSSLoaded])
  useEffect(() => {
    async function callMe() {
      if (!study || study.isSeen) return
      await client.mutate({
        mutation: MARK_STUDY_AS_SEEN2,
        variables: {
          studyId: study.id,
          clinicId: currentUser.clinic.id
        },
        update(cache) {
          cache.modify({
            id: cache.identify(study),
            fields: {
              isSeen: () => true
            }
          })
          cache.modify({
            id: cache.identify({
              __typename: 'StudyShareDataInMyStudies',
              studyId: study.id
            }),
            fields: {
              isSeen: () => true
            }
          })
        }
      })
    }
    callMe()
  }, [study])

  const [createReport, { loading: createReportLoading }] = useMutation(
    CREATE_REPORT,
    {
      update(cache, { data: { studyReportCreate: _report } }) {
        // TODO: remove when add signature to all reports in system
        cache.writeQuery({
          query: GET_REPORT_DATA,
          variables: {
            reportId: _report.id,
            hasReport: true
          },
          data: {
            report: {
              ..._report,
              __typename: 'Report',
              caregiver: {
                id: currentUser.id,
                settings: {
                  displayName: currentUser.settings.displayName,
                  reportSignature: currentUser.settings.reportSignature,
                  __typename: 'Settings'
                },
                __typename: 'User'
              }
            }
          }
        })
        cache.modify({
          id: cache.identify(study),
          fields: {
            hasReport: () => true,
            reports: () => [
              {
                id: _report.id,
                updatedAt: _report.updatedAt,
                state: _report.state,
                caregiver: {
                  name: _report.caregiver.name,
                  __typename: 'CaregiverConnectionLink'
                },
                __typename: 'ReportData'
              }
            ]
          }
        })
        cache.modify({
          id: cache.identify({
            __typename: 'StudyShareDataInMyStudies',
            studyId: study.id
          }),
          fields: {
            hasReport: () => true
          }
        })
        const referral = study.referrals.find(
          (referral) =>
            (referral.targetCaregiver && referral.targetCaregiver.id) ===
              currentUser.id && referral.status === 'Pending'
        )
        if (
          currentUser &&
          currentUser.clinic &&
          currentUser.isTargetForReferrals &&
          currentUser.clinic.targetReferralsTotal > 0 &&
          referral
        ) {
          cache.modify({
            id: cache.identify(currentUser.clinic),
            fields: {
              targetReferrals: ({ edges, pageInfo }) => ({
                edges: edges.filter(({ node }) => node.id !== referral.id),
                pageInfo
              }),
              targetReferralsTotal: (value) => value - 1
            }
          })
        }
      },
      onCompleted: async ({ studyReportCreate: _report }) => {
        if (_report && _report.id) {
          window.history.replaceState(
            {},
            '',
            `/study/${study.id}/report/${
              _report.id
            }#${window.location.hash.substring(1)}`
          )
          setReportId(_report.id)
        }
      }
    }
  )
  const [updateReport, { loading: updateReportLoading }] = useMutation(
    UPDATE_REPORT,
    {
      update(cache) {
        const referral = study.referrals.find(
          (referral) =>
            (referral.targetCaregiver && referral.targetCaregiver.id) ===
              currentUser.id && referral.status === 'Pending'
        )
        if (
          currentUser &&
          currentUser.clinic &&
          currentUser.isTargetForReferrals &&
          currentUser.clinic.targetReferralsTotal > 0 &&
          referral
        ) {
          cache.modify({
            id: cache.identify(currentUser.clinic),
            fields: {
              targetReferrals: ({ edges, pageInfo }) => ({
                edges: edges.filter(({ node }) => node.id !== referral.id),
                pageInfo
              }),
              targetReferralsTotal: (value) => value - 1
            }
          })
        }
      }
    }
  )
  const creator = hasReport ? report.caregiver : {}
  const isQuestionnaire = queryParams.questionnaire
  const isStandaloneQuestionnaire = study.protocol === 'Questionnaire'

  if (!isCSSLoaded) return <Loader />

  return (
    <>
      <div id='page_wrapper'>
        {study.protocol === 'Rest' && (
          <div
            id='bcViewer_wrapper'
            style={{
              position: 'absolute',
              top: '0',
              left: '-100000px',
              zIndex: 100,
              height: '1000px',
              width: '500px'
            }}
          >
            <BCViewer
              recordHash={study.id}
              recordUrl={
                study.records.find(
                  (record) => record.__typename === 'RecordEcgRestData'
                ).file.url
              }
              recordType={2}
              studyType={1}
              hasEvents={false}
              studyAuthorizedActions={study.authorizedActions}
              isCaregiverVeterinarian={
                study.caregiver.userType === 'Veterinarian'
              }
            />
          </div>
        )}
        {!isQuestionnaire && (
          <div className='action_menu level mb-0'>
            <div className='level-left'>
              <Link
                to={`/study/${study.id}${
                  queryParams.prev_state
                    ? decodeURIComponent(queryParams.prev_state)
                    : ''
                }`}
                id='view_study'
                className='view_study'
              >
                {`« ${locale.view_study}`}
              </Link>
            </div>
            <div className='level-right'>
              {hasReport &&
                isAuthorizedAction(
                  report.authorizedActions,
                  'Report:generatePdf'
                ) && (
                  <button
                    id='download_pdf_report'
                    onClick={async () => {
                      const credentials = await currentCredentials()

                      const httpRequest = new AWS.HttpRequest(
                        `${
                          process.env.PDFReportsBucketLambdaAccessPoint
                        }/report.${report.id}.${AWS.util.crypto.sha256(
                          report.caregiver.id +
                            report.updatedAt +
                            report.conclusion,
                          'hex'
                        )}.${currentUser.id}.pdf`,
                        process.env.Region
                      )

                      httpRequest.method = 'GET'
                      httpRequest.headers = {
                        host: process.env.PDFReportsBucketLambdaAccessPoint,
                        'presigned-expires': 900
                      }

                      const signer = new AWS.Signers.V4(
                        httpRequest,
                        's3-object-lambda',
                        true
                      )

                      signer.addAuthorization(
                        credentials,
                        AWS.util.date.getDate()
                      )
                      const signature =
                        httpRequest.headers.Authorization.split(' ')[3].split(
                          '='
                        )[1]

                      const url = `https://${httpRequest.headers.host}${httpRequest.path}&X-Amz-Signature=${signature}`
                      window.open(url, '_blank')

                      // https://github.com/mhart/aws4fetch/issues/35
                      // https://stackoverflow.com/questions/26148362/put-object-to-s3-using-v4-authentication-without-hashing-the-payload
                      // const opts = {
                      //   host: process.env.PDFReportsBucketLambdaAccessPoint,
                      //   path: `report.${report.id}.${window.encodeURIComponent(currentUser.id)}.pdf?X-Amz-Expires=900`,
                      //   headers: {
                      //     'x-amz-content-sha256': 'UNSIGNED-PAYLOAD'
                      //   },
                      //   service: 's3-object-lambda',
                      //   region: 'us-east-1',
                      //   signQuery: true
                      // }

                      // const request = aws4.sign(opts, credentials)
                      // const url = 'https://' + request.host + '/' + request.path
                      // window.open(url, '_blank')

                      // const s3 = new S3({ credentials })

                      // await s3
                      //   .getObject({
                      //     Bucket: process.env.PDFReportsBucketLambdaAccessPoint,
                      //     Key: `report.${report.id}.${window.encodeURIComponent(currentUser.id)}.pdf`
                      //   })
                      //   .promise()

                      // const url = s3.getSignedUrl('getObject', {
                      //   Bucket: process.env.PDFReportsBucketLambdaAccessPoint,
                      //   Key: `report.${report.id}.${window.encodeURIComponent(currentUser.id)}.pdf`
                      // })
                      // window.open(url, '_blank')
                    }}
                  >
                    {locale.download_pdf}
                  </button>
                )}
              {hasReport &&
                isAuthorizedAction(
                  report.authorizedActions,
                  'Report:print'
                ) && (
                  <button id='report_print' onClick={() => window.print()}>
                    {locale.print}
                  </button>
                )}
            </div>
          </div>
        )}
        <div className='page_header' id='page_header'>
          <img src={logoUrl} className='hospital_logo' />
          <label className='doctor_name'>
            {study.caregiver.settings.reportHeader ||
              study.caregiver.settings.displayName}
          </label>
          <label className='hospital_name'>{study.clinic.name}</label>
        </div>

        <div className='main'>
          <div id='main'>
            {!isQuestionnaire && (
              <div id='loader_wrapper'>
                <div id='loader' />
              </div>
            )}
            <div id='report_main'>
              <div className='contact_details'>
                <label>{`${study.clinic.address.address1 || ''} ${
                  study.clinic.address.address2 || ''
                }`}</label>
                {study.clinic.address.address2 && study.clinic.phone && (
                  <label>&nbsp;</label>
                )}
                {(study.clinic.address.address1 ||
                  study.clinic.address.address2) &&
                  study.clinic.phone && <label>|&nbsp;</label>}
                <label>{study.clinic.phone}</label>
              </div>
              <div className='study_details'>
                <label className='study_details_title'>
                  {locale.report.type}
                </label>
                <label className='study_details_value'>
                  {locale.studies.types[study.protocol.toLowerCase()]}
                </label>
                <label className='study_details_title'>
                  {locale.report.date}
                </label>
                <label className='study_details_value'>
                  {adjustTimeToTimezone(
                    study.createdAt,
                    study.timezoneOffset
                  ).format(
                    DATE_FORMAT_CONVERTER[currentUser.settings.dateFormat]
                  )}
                </label>
                {['Rest', 'Holter', 'Stethoscope'].includes(study.protocol) &&
                study.duration ? (
                  <>
                    <label className='study_details_title'>
                      {locale.report.duration}
                    </label>
                    <label className='study_details_value'>
                      {durationToHuman(study.duration)}
                    </label>
                  </>
                ) : (
                  <></>
                )}
              </div>
              <div id='viewer_header'>
                <table>
                  <tbody>
                    <tr>
                      <PatientDetailsOld
                        anonymousPatientData={study.anonymousPatientData}
                        patient={study.patient}
                        isAnimal={study.caregiver.userType === 'Veterinarian'}
                        dateFormat={currentUser.settings.dateFormat}
                      />
                    </tr>
                  </tbody>
                </table>
              </div>

              {(isQuestionnaire || isStandaloneQuestionnaire) && (
                <div
                  id='questionnaire'
                  className={clsx('content', {
                    standalone: isStandaloneQuestionnaire
                  })}
                >
                  <label className='has-text-weight-bold'>
                    {locale.questionnaire}
                  </label>
                  <ol>
                    {study.records
                      .find(
                        (record) =>
                          record.__typename === 'RecordQuestionnaireData'
                      )
                      .items.map((item, index) => (
                        <li key={index}>
                          <strong className='mr-1'>{item['title']}</strong>
                          <i>
                            {item['type'] === 'radio' ||
                            item['type'] === 'select'
                              ? item['value'] === null ||
                                parseInt(item['value']) === -1
                                ? ''
                                : item['options'][parseInt(item['value'])]
                              : item['value']}
                          </i>
                        </li>
                      ))}
                  </ol>
                </div>
              )}
              {!isQuestionnaire && (
                <>
                  {study.protocol === 'Spirometer' ? (
                    <BCSpiroViewer
                      recordUrl={
                        study.records.find(
                          (record) =>
                            record.__typename === 'RecordSpirometerData'
                        ).file.url
                      }
                      reportMode
                    />
                  ) : study.protocol === 'Rest' ||
                    study.protocol === 'Holter' ? (
                    <>
                      <div
                        id='reportScreenshotsPlaceholder'
                        className='loading_element'
                      >
                        <div
                          className='screenshotWrapper'
                          style={{ marginRight: 3 }}
                        >
                          <div id='mainEcgState' className='stateWrapper'>
                            <span className='stateVisibleStartTime' />
                            <span className='stateZoom' />
                            <span className='stateAmpl' />
                          </div>
                          <img id='firstChannels' />
                        </div>
                        <div className='screenshotWrapper'>
                          <img id='lastChannels' />
                        </div>
                        <div className='screenshotWrapper'>
                          <div id='rhythmState' className='stateWrapper'>
                            <span className='stateVisibleStartTime' />
                            <span className='stateZoom' />
                            <span className='stateAmpl' />
                          </div>
                          <img id='rhythm' />
                        </div>
                      </div>
                      <div id='extra_signature' className='signature_wrapper'>
                        <img
                          className={
                            digitalSigntatureUrl == null
                              ? 'no_digital_signature'
                              : 'digital_signature_img'
                          }
                          src={digitalSigntatureUrl}
                        ></img>
                        <label>{locale.signature}</label>
                        <span className='signature_line' />
                      </div>
                    </>
                  ) : null}
                </>
              )}
              <div className='loading_element' />
            </div>

            {(study.protocol === 'Rest' || study.protocol === 'Holter') && (
              <div className='powered_by' id='main_powered_by'>
                <div className='powered_by_text'>Powered by </div>
                <div className='powered_by_logo'>
                  <img src={poweredByLogo} />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {!isQuestionnaire && (study.protocol === 'Rest' || !queryParams.print) && (
        <div className='subpage_wrapper second_page'>
          <div className='subpage_container'>
            <div className='report_summary loading_element'>
              <StudySummary
                animalType={
                  study.caregiver.userType === 'Veterinarian' &&
                  study.anonymousPatientData.animal &&
                  study.anonymousPatientData.animal.type
                }
                setInitialMEA={() => {}}
                isCaregiverVeterinarian={
                  study.caregiver.userType === 'Veterinarian'
                }
                currentUser={currentUser}
              />
            </div>

            <div className='report_conclusion_wrapper'>
              <div className='report_conclusion_inner'>
                {study.protocol === 'Rest' &&
                  isAuthorizedAction(
                    currentUser.authorizedActions,
                    'User:measurements'
                  ) && (
                    <MeasurementValues
                      measurements={reportData.measurements}
                      mea={reportData.mea}
                      dispatch={dispatchReportData}
                      animalType={
                        study.caregiver.userType === 'Veterinarian' &&
                        study.anonymousPatientData.animal &&
                        study.anonymousPatientData.animal.type
                      }
                      disabled={
                        createReportLoading ||
                        updateReportLoading ||
                        inProgressUpdateLegacyClinic
                      }
                    />
                  )}
                <label className='report_conclusion_title'>
                  {locale.report_conclusion_title}
                </label>
                {conclusionTemplates !== null && (
                  <select
                    id='select_conclusion_template'
                    onChange={(e) => {
                      if (e.target.value === '-1') return
                      const textToInsert = interpolateConclusionTemplate(
                        conclusionTemplates.list[e.target.value].text,
                        currentUser
                      )
                      const cursorPosition =
                        conclusionTextarea.current.selectionStart
                      const textBeforeCursorPosition =
                        reportData.conclusion.slice(0, cursorPosition)
                      const textAfterCursorPosition =
                        reportData.conclusion.slice(cursorPosition)
                      dispatchReportData({
                        key: 'conclusion',
                        payload:
                          textBeforeCursorPosition +
                          textToInsert +
                          textAfterCursorPosition
                      })
                    }}
                    value='-1'
                    disabled={
                      createReportLoading ||
                      updateReportLoading ||
                      inProgressUpdateLegacyClinic
                    }
                  >
                    <option disabled='disabled' value='-1'>
                      {locale.report.insert_template}
                    </option>
                    {Object.entries(conclusionTemplates.list).map(
                      ([key, template]) => (
                        <option key={key} value={key}>
                          {template.name}
                        </option>
                      )
                    )}
                  </select>
                )}
                <textarea
                  className='report_conclusion_text'
                  maxLength={10000}
                  value={reportData.conclusion}
                  onChange={(e) =>
                    dispatchReportData({
                      key: 'conclusion',
                      payload: e.target.value
                    })
                  }
                  style={{
                    direction:
                      reportData.conclusion && isRTL(reportData.conclusion[0])
                        ? 'rtl'
                        : 'ltr'
                  }}
                  disabled={
                    createReportLoading ||
                    updateReportLoading ||
                    inProgressUpdateLegacyClinic
                  }
                  ref={conclusionTextarea}
                />
                <div className='is-flex is-justify-content-space-between'>
                  <span id='report_signature_creator'>
                    {hasReport
                      ? report.signature ||
                        creator.settings.reportSignature || // backwards compatibility when there was no signature field on the report - TODO: remove when add signature to all reports in system
                        creator.settings.displayName
                      : ''}
                  </span>
                  <span
                    id='report_signature_date'
                    className='report_signature_date'
                  >
                    {hasReport
                      ? dayjs(report.updatedAt).format('MMM DD, YYYY')
                      : ''}
                  </span>
                </div>
                <div id='report_buttom'>
                  {(isAuthorizedAction(
                    study.authorizedActions,
                    'Mutation:studyReportCreate'
                  ) ||
                    (hasReport &&
                      isAuthorizedAction(
                        report.authorizedActions,
                        'Mutation:studyReportUpdate'
                      ))) && (
                    <button
                      id='save_report'
                      className={clsx('button', {
                        'is-loading':
                          createReportLoading ||
                          updateReportLoading ||
                          inProgressUpdateLegacyClinic
                      })}
                      disabled={
                        createReportLoading ||
                        updateReportLoading ||
                        inProgressUpdateLegacyClinic ||
                        !hasFormChanged
                      }
                      onClick={async () => {
                        let {
                          __typename, // eslint-disable-line no-unused-vars
                          ...digitalSignature
                        } = currentUser.settings.reportDigitalSignature || {}
                        if (!currentUser.settings.reportDigitalSignature) {
                          digitalSignature = null
                        }

                        const createdAt = new Date().toISOString()

                        /* TODO: remove after migration */
                        try {
                          setInProgressUpdateLegacyClinic(true)
                          const iframe = document.createElement('iframe')
                          iframe.setAttribute(
                            'src',
                            window.location.origin +
                              '/en/account/study_reports?hash_id=' +
                              study.id
                          )
                          iframe.setAttribute('hidden', 'hidden')
                          document
                            .getElementsByTagName('body')[0]
                            .appendChild(iframe)
                          await new Promise((resolve) =>
                            iframe.addEventListener('load', resolve)
                          )
                          const csrfToken = iframe.contentWindow.document
                            .querySelector("meta[name='csrf-token']")
                            .getAttribute('content')

                          const body = new URLSearchParams({
                            hash_id: study.id,
                            conclusion: reportData.conclusion,
                            state: window.location.hash.substring(1),
                            measurement_values: JSON.stringify(
                              Object.keys(reportData.measurements).reduce(
                                (obj, key) => (
                                  (obj[key] =
                                    reportData.measurements[key] || ''),
                                  obj
                                ),
                                {}
                              )
                            ),
                            mea_label: {
                              '': 'novalue',
                              null: 'novalue',
                              undefined: 'novalue',
                              Normal: 'normal',
                              Mild: 'mild_right_axis_deviation',
                              Moderate: 'moderate_right_axis_deviation',
                              Severe: 'severe_right_axis_deviation',
                              Right: 'right_axis_deviation',
                              Left: 'left_axis_deviation'
                            }[reportData.mea],
                            is_request_from_new_app: true
                          })

                          if (
                            iframe.contentWindow.bcGlobals.reports.length > 0
                          ) {
                            body.append(
                              'report_id',
                              iframe.contentWindow.bcGlobals.reports[0].id
                            )
                          }

                          iframe.parentNode.removeChild(iframe)

                          const response = await window.fetch(
                            `/api/update_report`,
                            {
                              method: 'POST',
                              headers: {
                                'Content-Type':
                                  'application/x-www-form-urlencoded; charset=UTF-8',
                                'X-CSRF-Token': csrfToken
                              },
                              body
                            }
                          )
                          const result = await response.json()
                          console.log('update_report result:', result)
                          setInProgressUpdateLegacyClinic(false)
                        } catch (e) {
                          console.error(e)
                          setInProgressUpdateLegacyClinic(false)
                        }

                        if (hasReport) {
                          await updateReport({
                            variables: {
                              caregiver: {
                                id: currentUser.id,
                                name: currentUser.settings.displayName,
                                email: currentUser.email
                              },
                              input: {
                                id: report.id,
                                updatedAt: createdAt,
                                signature:
                                  currentUser.settings.reportSignature ||
                                  currentUser.settings.displayName,
                                digitalSignature,
                                ...reportData,
                                measurements: JSON.stringify(
                                  reportData.measurements
                                )
                              }
                            },
                            awaitRefetchQueries: true,
                            refetchQueries: [
                              {
                                query: GET_REPORT_DATA,
                                variables: {
                                  reportId: report.id,
                                  hasReport: true
                                }
                              }
                            ]
                          })
                        } else {
                          const reportId = uuidv4()
                          await createReport({
                            variables: {
                              studyId: study.id,
                              caregiver: {
                                id: currentUser.id,
                                name: currentUser.settings.displayName,
                                email: currentUser.email
                              },
                              input: {
                                id: reportId,
                                createdAt,
                                state: encodeViewerState(
                                  window.location.hash.substring(1)
                                ),
                                signature:
                                  currentUser.settings.reportSignature ||
                                  currentUser.settings.displayName,
                                digitalSignature,
                                ...reportData,
                                measurements: JSON.stringify(
                                  reportData.measurements
                                )
                              }
                            }
                          })
                        }
                        setDefaultData({
                          measurements: reportData.measurements,
                          mea: reportData.mea,
                          conclusion: reportData.conclusion
                        })
                      }}
                    >
                      {hasReport ? locale.update_report : locale.create_report}
                    </button>
                  )}
                  <div className='signature_wrapper'>
                    <img
                      className={
                        digitalSigntatureUrl == null
                          ? 'no_digital_signature'
                          : 'digital_signature_img'
                      }
                      src={digitalSigntatureUrl}
                    ></img>
                    <label>{locale.signature}</label>
                    <span className='signature_line' />
                  </div>
                </div>
                <div className='powered_by'>
                  <div className='powered_by_text'>Powered by </div>
                  <div className='powered_by_logo'>
                    <img src={poweredByLogo} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default ReportCore

import React, { useState, useEffect } from 'react'
import { createPortal } from 'react-dom'

const ModalCard = ({
  onClose,
  onSubmit,
  standalone = true,
  title,
  loader,
  actions,
  children,
  additionalClasses = ''
}) => {
  const [element] = useState(document.createElement('div'))
  useEffect(() => {
    const modalRoot = document.getElementById('___gatsby').parentElement
    modalRoot.appendChild(element)
    return () => {
      modalRoot.removeChild(element)
    }
  }, [])
  useEffect(() => {
    const onKeyDown = async (e) => {
      if (e.keyCode === 27 && onClose) onClose(e)
      if (e.keyCode === 13 && onSubmit) await onSubmit(e)
    }
    window.addEventListener('keydown', onKeyDown)
    return () => window.removeEventListener('keydown', onKeyDown)
  }, [onClose, onSubmit])
  useEffect(() => {
    if (!standalone) return
    window.document.body.classList.add('modal-open')
    return () => {
      window.document.body.classList.remove('modal-open')
    }
  })
  return createPortal(
    <div className={`modal is-active ${additionalClasses}`}>
      {standalone && <div className='modal-background' onClick={onClose} />}
      <div className='modal-card'>
        <header className='modal-card-head'>
          <p className='modal-card-title'>{title}</p>
          {loader}
        </header>
        <section className='modal-card-body'>{children}</section>
        <footer className='modal-card-foot'>{actions}</footer>
      </div>
      {standalone && (
        <button
          className='modal-close is-large'
          aria-label='close'
          onClick={onClose}
        ></button>
      )}
    </div>,
    element
  )
}

export default ModalCard

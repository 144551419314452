import React, { useState, useEffect, useRef } from 'react'
import { gql, useMutation } from '@apollo/client'
import clsx from 'clsx'
import AWS from 'aws-sdk/global'
import S3 from 'aws-sdk/clients/s3'
import { currentCredentials } from '../../utils/credentials'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUpload, faSpinner } from '@fortawesome/free-solid-svg-icons'
import ModalCard from './ModalCard'
import { useLocalization } from './LocalizationProvider'

const USER_LOGO_UPDATE = gql`
  mutation USER_LOGO_UPDATE($input: UserUpdateInput!) {
    userUpdate(input: $input) {
      id
    }
  }
`

export default function LogoManager({ user, currentLogo, onExit, onComplete }) {
  const [inProgress, setInProgress] = useState(false)
  const [logo, setLogo] = useState(null)
  const [currentLogoUrl, setCurrentLogoUrl] = useState(null)
  const [fileError, setFileError] = useState(null)
  const fileInput = useRef()
  const { locale, interpolate } = useLocalization()

  const MAX_LOGO_SIZE_MB = 1

  useEffect(() => {
    if (!currentLogo) return
    async function callMe() {
      const credentials = await currentCredentials()
      const s3 = new S3({ credentials })
      setCurrentLogoUrl(
        s3.getSignedUrl('getObject', {
          Bucket: currentLogo.bucket,
          Key: currentLogo.key
        })
      )
    }
    callMe()
  }, [])
  const [updateUser, { loading: updateUserLoading }] = useMutation(
    USER_LOGO_UPDATE,
    {
      onCompleted: onComplete,
      update(cache, _, { variables }) {
        cache.modify({
          id: cache.identify(user.clinic),
          fields: {
            logo: (logo) =>
              variables.input.clinic.isDeleteLogo
                ? null
                : {
                    ...logo,
                    ...variables.input.clinic.logo
                  }
          }
        })
      }
    }
  )

  return (
    <ModalCard
      onClose={onExit}
      loader={
        <FontAwesomeIcon
          icon={faSpinner}
          spin
          size='lg'
          className={clsx({
            'is-hidden': !(inProgress || updateUserLoading)
          })}
        />
      }
      title={locale.profile.logo}
      actions={
        <>
          <div className=' file'>
            <label className='file-label'>
              <input
                ref={fileInput}
                className='button file-input'
                type='file'
                disabled={inProgress || updateUserLoading}
                accept='.jpg,.jpeg,.png,.gif,.bmp'
                onChange={(event) => {
                  setFileError(null)
                  if (event.target.files.length === 0) {
                    setLogo(null)
                    return
                  }
                  if (
                    !event.target.files[0].name.match(
                      /\.(jpg|jpeg|png|gif|bmp)$/
                    )
                  ) {
                    setLogo(null)
                    setFileError('NOT_IMAGE')
                    return
                  }

                  if (event.target.files[0].size > 1024 * 1024) {
                    setLogo(null)
                    setFileError('IMAGE_TOO_BIG')
                    return
                  }
                  AWS.util.crypto.sha256(
                    event.target.files[0],
                    'hex',
                    (err, key) => {
                      setLogo({
                        file: event.target.files[0],
                        key: `images/${user.id}/hospital_logo/${key}`,
                        url: window.URL.createObjectURL(event.target.files[0])
                      })
                    }
                  )
                }}
              />
              <span className='file-cta'>
                <span className='file-icon'>
                  <FontAwesomeIcon icon={faUpload} />
                </span>
                <span className='file-label'>{locale.profile.choose_file}</span>
              </span>
            </label>
          </div>
          <button
            className='button is-outlined is-primary'
            style={{ marginLeft: 'auto' }}
            onClick={async () => {
              const updatedAt = new Date().toISOString()
              try {
                setInProgress(true)
                const credentials = await currentCredentials()
                const s3 = new S3({ credentials })
                await s3
                  .deleteObject({
                    Bucket: process.env.S3BucketObjects,
                    Key: currentLogo.key
                  })
                  .promise()
                setInProgress(false)
              } catch (error) {
                setInProgress(false)
                console.log('Error uploading file: ', error)
              }
              updateUser({
                variables: {
                  input: {
                    id: user.id,
                    updatedAt,
                    clinic: {
                      id: user.clinic.id,
                      isDeleteLogo: true
                    }
                  }
                }
              })
            }}
            disabled={
              !currentLogo ||
              logo ||
              inProgress ||
              updateUserLoading ||
              fileError
            }
          >
            {locale.profile.remove}
          </button>

          <button
            className='button is-outlined is-primary'
            onClick={async () => {
              const updatedAt = new Date().toISOString()
              try {
                setInProgress(true)
                const credentials = await currentCredentials()
                const s3 = new S3({ credentials })
                await s3
                  .putObject({
                    Bucket: process.env.S3BucketObjects,
                    Key: logo.key,
                    Body: logo.file,
                    ContentType: logo.file.type
                  })
                  .promise()
                if (currentLogo) {
                  await s3
                    .deleteObject({
                      Bucket: currentLogo.bucket,
                      Key: currentLogo.key
                    })
                    .promise()
                }
                setInProgress(false)
              } catch (error) {
                setInProgress(false)
                console.log('Error uploading file: ', error)
              }

              updateUser({
                variables: {
                  input: {
                    id: user.id,
                    updatedAt,
                    clinic: {
                      id: user.clinic.id,
                      logo: {
                        service: 's3',
                        region: process.env.Region,
                        bucket: process.env.S3BucketObjects,
                        key: logo.key,
                        fileName: logo.file.name,
                        size: logo.file.size,
                        type: logo.file.type
                      }
                    }
                  }
                }
              })
            }}
            disabled={
              !logo ||
              logo.key === (currentLogo && currentLogo.key) ||
              inProgress ||
              updateUserLoading ||
              fileError
            }
          >
            {locale.profile.submit}
          </button>
        </>
      }
    >
      <div className='is-relative'>
        <button
          className={clsx('delete', {
            'is-hidden': !logo
          })}
          style={{ position: 'absolute', right: -10, top: -10 }}
          onClick={async () => {
            setLogo(null)
            fileInput.current.value = ''
          }}
          disabled={inProgress || updateUserLoading}
        />
        {fileError ? (
          <span className='is-size-5 has-text-danger'>
            {fileError === 'NOT_IMAGE'
              ? locale.profile.not_an_image_error
              : interpolate(locale.profile.image_too_large_error, {
                  size: MAX_LOGO_SIZE_MB
                })}
          </span>
        ) : (
          <img src={(logo && logo.url) || currentLogoUrl} />
        )}
      </div>
    </ModalCard>
  )
}

import React, { useContext, useState } from 'react'
import { GET_REFERRALS_BY_STATUS } from '../queries'
import { useQuery } from '@apollo/client'
import useMemoryPagination, {
  prev,
  next
} from '../../hooks/useMemoryPagination'
import AuthContext from '../AuthContext'
import { DATE_FORMAT_CONVERTER } from '../../../utils/utils'
import dayjs from 'dayjs'
import ReferralCancel from '../ReferralCancel'
import AdminAssignQueueReferral from './AdminAssignQueueReferral'
import { useLocalization } from '../LocalizationProvider'
import { REFERRAL_TYPES_CONVERTER } from '../../../utils/utils'

const FIRST = 20

const AdminReferralsDetails = ({ status }) => {
  const currentUser = useContext(AuthContext)
  const { locale } = useLocalization()

  const [referralToCancel, setReferralToCancel] = useState(null)
  const [queueReferralToAssign, setQueueReferralToAssign] = useState(null)

  const [paginationState, dispatch] = useMemoryPagination()

  const { loading, error, data } = useQuery(GET_REFERRALS_BY_STATUS, {
    variables: { status: status, first: FIRST, after: paginationState }
  })

  return (
    <div>
      {referralToCancel && (
        <ReferralCancel
          referral={referralToCancel}
          onComplete={() => {
            setReferralToCancel(null)
            alert('Referral is canceled, refresh the page to see the result')
          }}
          onExit={() => setReferralToCancel(null)}
        ></ReferralCancel>
      )}
      {queueReferralToAssign && (
        <AdminAssignQueueReferral
          referral={queueReferralToAssign}
          onComplete={() => {
            setQueueReferralToAssign(null)
          }}
          onExit={() => setQueueReferralToAssign(null)}
        ></AdminAssignQueueReferral>
      )}
      {loading && <p>Loading...</p>}
      {error && <div> error... </div>}

      {data && (
        <div>
          <table className='table is-fullwidth is-hoverable'>
            <thead>
              <tr>
                <th>Created At</th>
                <th>Type</th>
                <th>Ordered By</th>
                <th>Ordered From</th>
                <th>Message</th>
                <th>Price</th>
                <th>Currency</th>
                {status !== 'Done' && <th>Cancel</th>}
                {(status === 'PendingInQueue' || status === 'Pending') && (
                  <th>Assign</th>
                )}
              </tr>
            </thead>
            <tbody>
              {data.admin.referralsByStatus.edges.map(({ node: referral }) => (
                <tr
                  key={referral.id}
                  className='c-hand'
                  onClick={async () =>
                    window.open(`/study/${referral.studyId}`, '_blank')
                  }
                >
                  <td>
                    {dayjs(referral.createdAt).format(
                      `${
                        DATE_FORMAT_CONVERTER[currentUser.settings.dateFormat]
                      } HH:mm:ss`
                    )}
                  </td>
                  <td>
                    {
                      locale.studies.reports[
                        REFERRAL_TYPES_CONVERTER[referral.referralType]
                      ]
                    }
                  </td>
                  <td>{`${referral.sourceCaregiver.name} (${referral.sourceCaregiver.email})`}</td>
                  <td>
                    {referral.isTargetIsQueue
                      ? referral.targetQueue.name
                      : `${referral.targetCaregiver.name} (${referral.targetCaregiver.email})`}
                  </td>
                  <td>{referral.message}</td>
                  <td>{referral.price}</td>
                  <td>{referral.currency}</td>
                  {status !== 'Done' && (
                    <td>
                      <button
                        className='button'
                        onClick={(e) => {
                          e.stopPropagation()
                          setReferralToCancel(referral)
                        }}
                      >
                        Cancel
                      </button>
                    </td>
                  )}
                  {(status === 'PendingInQueue' || status === 'Pending') && (
                    <td>
                      {referral.targetQueue !== null && (
                        <button
                          className='button'
                          onClick={(e) => {
                            e.stopPropagation()
                            setQueueReferralToAssign(referral)
                          }}
                        >
                          Assign
                        </button>
                      )}
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
          <nav className='pagination' role='navigation' aria-label='pagination'>
            <a
              className='pagination-previous'
              disabled={!data.admin.referralsByStatus.pageInfo.hasPreviousPage}
              onClick={() => {
                if (data.admin.referralsByStatus.pageInfo.hasPreviousPage) {
                  dispatch(prev())
                }
              }}
            >
              Previous
            </a>
            <a
              className='pagination-next'
              disabled={!data.admin.referralsByStatus.pageInfo.hasNextPage}
              onClick={() => {
                if (data.admin.referralsByStatus.pageInfo.hasNextPage) {
                  dispatch(
                    next(data.admin.referralsByStatus.pageInfo.endCursor)
                  )
                }
              }}
            >
              Next
            </a>
          </nav>
        </div>
      )}
    </div>
  )
}

export default AdminReferralsDetails

import React, { useState } from 'react'
import Modal from '../Modal'
import { GET_STUDIES } from '../queries'
import { gql, useApolloClient } from '@apollo/client'
import clsx from 'clsx'

const FIRST = 10 // TBD - change to bigger number ?

const STUDY_COPY_MUTATION = gql`
  mutation STUDY_COPY_MUTATION(
    $studyId: ID!
    $email: AWSEmail!
    $patientName: String
    $isAnonymous: Boolean!
  ) {
    studyCopy(
      studyId: $studyId
      email: $email
      patientName: $patientName
      isAnonymous: $isAnonymous
    ) {
      id
    }
  }
`

export default function CreateStudiesCopy({ clinicId, onExit, onComplete }) {
  const [targetDoctor, setTargetDoctor] = useState('')
  const [isAnonymous, setIsAnonymous] = useState(true)
  const [copyStudyError, setCopyStudyError] = useState(null)
  const [inProgress, setInProgress] = useState(false)

  const client = useApolloClient()

  async function getStudiesPage(after) {
    const {
      data: {
        clinic: { studies }
      }
    } = await client.query({
      query: GET_STUDIES,
      variables: {
        id: clinicId,
        patientId: '',
        first: FIRST,
        after: after
      }
    })
    return studies
  }

  async function submit() {
    setInProgress(true)
    let studiesList = []
    var studies = {
      pageInfo: {
        endCursor: null
      }
    }

    // get all studies
    do {
      studies = await getStudiesPage(studies.pageInfo.endCursor)
      studiesList = studiesList.concat(studies.edges)
    } while (studies.pageInfo.hasNextPage)

    // copy studies
    for (const { node: study } of studiesList) {
      try {
        await client.mutate({
          mutation: STUDY_COPY_MUTATION,
          variables: {
            studyId: study.studyId,
            email: targetDoctor,
            isAnonymous: isAnonymous
          }
        })
      } catch (e) {
        console.log(e)
      }
    }

    setInProgress(false)
    onComplete()
  }

  return (
    <Modal onClose={onExit} onSubmit={submit} inProgress={inProgress}>
      <label className='label is-medium'>Copy studies</label>
      <hr />
      <div className='field is-horizontal'>
        <div className='field-label is-normal'>
          <label className='label has-text-left'>Target doctor:</label>
        </div>
        <div className='field-body'>
          <div className='field'>
            <input
              type='text'
              className='input'
              value={targetDoctor}
              onChange={(e) => {
                setTargetDoctor(e.target.value)
                setCopyStudyError(null)
              }}
            />
          </div>
        </div>
      </div>
      <div className='field is-horizontal'>
        <div className='field-label is-normal'>
          <label className='label has-text-left'>Anonymous:</label>
        </div>
        <div className='field-body'>
          <div className='field'>
            <input
              type='checkbox'
              checked={isAnonymous}
              onChange={(e) => {
                setIsAnonymous(e.target.checked)
                setCopyStudyError(null)
              }}
            />
          </div>
        </div>
      </div>
      <div className='field'>
        <div className='level'>
          <div className='level-left'>
            <div className='level-item'>
              {copyStudyError && (
                <div className='has-text-danger'>{copyStudyError.message}</div>
              )}
            </div>
          </div>
          <div className='level-right'>
            <div className='level-item'>
              <button
                className={clsx('button is-primary level-item', {
                  'is-loading': inProgress
                })}
                onClick={async () => submit()}
              >
                Copy
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

import React, { useContext } from 'react'
import { Router, Redirect } from '@reach/router' // eslint-disable-line import/no-unresolved
import AuthContext from './AuthContext'
import Studies from './Studies'
import Profile from './Profile'
import Settings from './Settings'
import Sharing from './Sharing'
import Reports from './Reports'
import Referrals from './Referrals'
import ReferralsQueue from './ReferralsQueue'
import Study from './Study'
import ReportRedirect from './ReportRedirect'
import Report from './Report'
import Viewer from './Viewer'
import QuestionnaireBuilder from './QuestionnaireBuilder'
import AdminMain from './Admin/AdminMain'
import CustomRedirect from './Redirect'
import { isAuthorizedAction } from '../../utils/utils'
import ReferralsQueueAssign from './ReferralsQueueAssign'
import RedirectToChangeCCPage from './RedirectToChangeCCPage'
import ActivateServicePlan from './ActivateServicePlan'

// height: 100 for Router needed for standalone viewer to be full screen
const App = ({ gotoLoginPage }) => {
  const currentUser = useContext(AuthContext)
  return (
    <Router style={{ height: '100%' }}>
      <Studies path='/' />
      <Studies path='/patients/:patientId' />
      <Studies path='/protocols/:protocol' />
      <Studies path='/tags/:tag' />
      <Profile path='/profile' />
      <Settings path='/settings' />
      {(isAuthorizedAction(
        currentUser.clinic.authorizedActions,
        'Clinic:sourceSharesAuto'
      ) ||
        isAuthorizedAction(
          currentUser.clinic.authorizedActions,
          'Clinic:targetSharesAuto'
        )) && <Sharing path='/sharing' />}
      {isAuthorizedAction(
        currentUser.clinic.authorizedActions,
        'Clinic:sourceReferrals'
      ) && <Reports path='/reports' />}
      {isAuthorizedAction(
        currentUser.clinic.authorizedActions,
        'Clinic:targetReferrals'
      ) && <Referrals path='/referrals' />}
      {isAuthorizedAction(
        currentUser.clinic.authorizedActions,
        'Clinic:referralsQueues'
      ) && <ReferralsQueue path='/referralsQueue' />}
      <ActivateServicePlan path='/activate_service_plan/:transactionId' />
      <Study path='/study/:id' gotoLoginPage={gotoLoginPage} />
      <ReferralsQueueAssign path='/study/:studyId/assign/:referralId' />
      <RedirectToChangeCCPage path='change_cc_page' />
      <Viewer path='/viewer' gotoLoginPage={gotoLoginPage} />
      <Viewer path='/live/:sessionId' gotoLoginPage={gotoLoginPage} />
      <Report path='/study/:studyId/report/new' gotoLoginPage={gotoLoginPage} />
      <Report
        path='/study/:studyId/report/:reportId'
        gotoLoginPage={gotoLoginPage}
      />
      <QuestionnaireBuilder path='/questionnaire-builder' />
      {isAuthorizedAction(currentUser.authorizedActions, 'Query:admin') && (
        <AdminMain path='/admin' />
      )}
      {/* *** Redirect from legacy paths to new web app format *** */}
      <CustomRedirect
        path='/study'
        from='/study?hash_id=:studyId&paymentRequest=:paymentRequest&doctorHash=:doctorHash&reportType=:reportType'
        to='/study/:studyId?paymentRequest=:paymentRequest&targetCaregiverId=:doctorHash&referralType=:reportType&isLegacyRedirect=:paymentRequest'
      />
      <CustomRedirect
        path='/:locale/account/study'
        from='/:locale/account/study?hash_id=:studyId&paymentRequest=:paymentRequest&doctorHash=:doctorHash&reportType=:reportType&referralId=:referralId'
        to='/study/:studyId?paymentRequest=:paymentRequest&targetCaregiverId=:doctorHash&referralType=:reportType&referralId=:referralId&isLegacyRedirect=:paymentRequest'
      />
      <CustomRedirect
        path='/:locale/account/queue'
        from='/:locale/account/queue?hash_id=:studyId&referralId=:referralId'
        to='/study/:studyId/assign/:referralId'
      />
      <ReportRedirect
        path='/:locale/account/study_report'
        gotoLoginPage={gotoLoginPage}
      />
      <Redirect
        from='/ecg_demo'
        to='/study/b1c5738ba5e6d1e78088f7cd6815caa363c0a9a9'
        noThrow
      />
      {/* *** Done *** */}
      <Redirect from='/*' to='/' default noThrow />
    </Router>
  )
}

export default App

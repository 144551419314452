if (typeof window !== 'undefined') {

/*
   ContextViewer.View - the context viewer view
*/
bcViewer.ContextViewer.View = bcViewer.BaseWavesViewer.View.extend({
    construct: function() {
        this._super.apply(this, arguments);

        // Save the zoom x ratio, the calculation is: oldZoomX / newZoomX
        this._zoomXRatio;

        this._DRAG_MODES = {HIGHLIGHT_MOVE: 0, WAVES_MOVE: 1};
        this._dragMode = this._DRAG_MODES.HIGHLIGHT_MOVE;

        // Keep to know if lead names changed
        this._leadNames = [];
    },

    /*
        Start listen to UI events
    */
    _registerToEvents: function() {
        $$('#bc_viewer .bcv_context_viewer .bcv_canvas_wrapper').on('click',  $.proxy(this.onCanvasClicked, this));
        $$('#bc_viewer .bcv_context_viewer .bcv_canvas_wrapper').on('mousemove',  $.proxy(this.onMouseMove, this));
        $$('#bc_viewer .bcv_context_viewer .bcv_canvas_wrapper').on('mouseenter', $.proxy(this.onMouseEnter, this));
        $$('#bc_viewer .bcv_context_viewer .bcv_canvas_wrapper').on('mouseleave',  $.proxy(this.onMouseLeave, this));
        $$('.bcv_channels_list').on('change', $.proxy(this.onChannelSelected, this));
    },

    /*
        Handle canvas clicks
    */
    onCanvasClicked: function(event) {
        if (this._viewer.isDisabled()) return;

        var xOffset = event.pageX - $$('#bc_viewer .bcv_context_viewer .bcv_canvas_wrapper').offset().left;
        var timePosition = this._model.convertVisibleWindowPixelsToTime(xOffset, this._getState());

        this._viewer.onStateChangeRequest({'timePosition': timePosition});
    },

    /*
        Select a channel in the UI
    */
    selectChannel: function(channelId) {
        $$('.bcv_channels_list').val(channelId);
    },

    /*
        return the id of the current selected channel
    */
    getSelectedChannelId: function() {
        return parseInt($$('.bcv_channels_list').find(':selected').val(), 10);
    },

    /*
        Handle channel selection in the UI
    */
    onChannelSelected: function() {
        this._viewer.onStateChangeRequest({'selectedChannel': this.getSelectedChannelId()});
    },

    /*
        Handle width change
    */
    onWidthChange: function(state) {
        this._super(state);

        this.updateHighlightWidth(state);
    },

    /*
        Update the zoom ratio after zoomX changed
    */
    updateZoomXRatio: function(zoomX, stateZoomX) {
        this._zoomXRatio = stateZoomX / zoomX;
    },

    /*
         Update the highlight window width whenever the bcViewer zoomX is changing
         or when dimensions have changed
    */
    updateHighlightWidth: function(state) {
        // we cant update the highlight width without the state width
        if (typeof state.width === 'undefined') {
            return;
        }

        var zoomXRatio = Math.min(this._zoomXRatio, 1);
        var newWidth = state.width * zoomXRatio;
        $$('#bc_viewer .bcv_context_highlight').width(Math.floor(newWidth));
        $$('#bc_viewer .bcv_context_highlight_on_hover').width(Math.floor(newWidth));
        state.timePosition && this.updateHighlightPosition(state.timePosition, state);
    },

    /*
        Update the highlight window position whenever the time position is changing
    */
    updateHighlightPosition: function(timePosition, state) {
        var timeOffsetInPX = this._model.convertTimeToVisibleWindowPixels(timePosition, state);

        this._setHighlightPosition($$('#bc_viewer .bcv_context_highlight'), timeOffsetInPX);
    },

    /*
        Handle time position changes
    */
    onTimePositionChange: function(newState) {
        newState.timePosition && this.updateHighlightPosition(newState.timePosition, newState);
    },

    onMagnifyChange: function(newState) {
        newState.timePosition && this.updateHighlightPosition(newState.timePosition, newState);
    },

    /*
        Build channles dropdown UI
    */
    buildChannelsDropdown: function(channels_number, selectedChannel, leadNames) {
        var optionsData = [];

        if ((typeof leadNames === 'undefined') || (leadNames.length === 0)) {
            for (var i=0 ; i<channels_number ; i++) {
                var channelText = $.tmpl(bcGlobals.locale.channelAbbreviation, {channel_number: i+1}).text();
                optionsData.push({value: i, text: channelText});
            }
        } else {
            for (var i=0; i < channels_number; i++) {
                optionsData.push({value: i, text: leadNames[i]});
            }
        }

        $$('.bcv_channels_list').empty();
        $$('.bcv_channels_list').append($('#bcv_dropdown_option').tmpl(optionsData));

        // Protect against switching from 7 to 3
        if (selectedChannel >= channels_number) {
            selectedChannel = 0;
            this._viewer.onStateChangeRequest({'selectedChannel': 0});
        }

        this.selectChannel(selectedChannel);
    },

    /*
        Return array of elements selectors to hide
    */
    getElementsToHide: function() {
        return ['.bcv_context_viewer'];
    },

    /*
        Handle drag
    */
    onDragMove: function(timePosition) {
        try {
          var state = this._getState();
          var offsetFromStart = this._model.convertTimeToPixels(timePosition, state);
          var durationPX = this._model.convertTimeToPixels(this._viewer._metadata.Duration, state);

          // TODO: reading it from the DOM is not optimal
          var highlightWindowWidth = $$('#bc_viewer .bcv_context_highlight').width();

          // on the first and last visible windows of the record move the higlight window,
          if ((offsetFromStart < state.width/2) ||
              ((offsetFromStart + state.width/2) >= durationPX)) {

              this._model.updateState({'timePosition': timePosition});

              // When switching between modes, update the waves position
              if (this._dragMode === this._DRAG_MODES.WAVES_MOVE) {
                this._dragMode = this._DRAG_MODES.HIGHLIGHT_MOVE;
                this._model.getECGDataForDrag(0, $.proxy(this.drawImage, this));
              }

              this.updateHighlightPosition(timePosition, state);
          } else {
              // When switching between modes, update the highlight position, and let the model know about the time position
              if (this._dragMode === this._DRAG_MODES.HIGHLIGHT_MOVE) {
                this._dragMode = this._DRAG_MODES.WAVES_MOVE;
                this._model.updateState({'timePosition': timePosition});
                this.updateHighlightPosition(timePosition, state);
              }

              var dragOffset = this._model.convertTimeToPixels(state.timePosition - timePosition, state);
              this._model.getECGDataForDrag(dragOffset, $.proxy(this.drawImage, this));
          }
        } catch(e) {
        }
    },

    /*
        Handle mouse move events - update the hover highlight position
    */
    onMouseMove: function(event) {
        if (this._viewer.isDisabled()) return;

        var state = this._getState();
        var xOffset = event.pageX - $$('#bc_viewer .bcv_context_viewer .bcv_canvas_wrapper').offset().left;
        this._setHighlightPosition($$('.bcv_context_highlight_on_hover'), xOffset);
    },

    /*
        Handle mouse enter events
    */
    onMouseEnter: function(event) {
        if (this._viewer.isDisabled()) return;

        (this._bcViewer.mode === this._bcViewer.modes.NORMAL) && $$('.bcv_context_highlight_on_hover').show();
    },

    /*
        Handle mouse leave events
    */
    onMouseLeave: function(event) {
        $$('.bcv_context_highlight_on_hover').hide();
    },

    /*
        Set an highlight window position
    */
    _setHighlightPosition: function(highlightElm, xOffset) {
        var state = this._getState();
        // TODO: reading it from the DOM is not optimal
        var highlightWindowWidth = highlightElm.width();
        var newLeft = Math.min(Math.max(0, xOffset - (highlightWindowWidth/2)), state.width - highlightWindowWidth);
        highlightElm.css('left', Math.floor(newLeft));
    },

    /*
        Handler for getting new ECG data from the model,
        add lead names to channels dropdown if needed
    */
    onGotECGData: function(response) {
        var leadNames = this._model.getLeadNames();
        if (leadNames !== this._leadNames) {
            this.buildChannelsDropdown(this._viewer._metadata.NumberOfChannels, this._viewer.getState().selectedChannel, leadNames);
            this._leadNames = leadNames;
        }

        this._super.apply(this, arguments);
    },

    /*
        Disable the viewer
    */
    disableViewer: function() {
        $$('.bcv_channels_list').prop('disabled', true);
        $$('.bcv_context_viewer .bcv_canvas_wrapper').addClass('bcv_canvas_wrapper_disabled');

        this._super.apply(this, arguments);
    },

    /*
        Enable the viewer
    */
    enableViewer: function() {
        $$('.bcv_channels_list').removeProp('disabled');
        $$('.bcv_context_viewer .bcv_canvas_wrapper').removeClass('bcv_canvas_wrapper_disabled');

        this._super.apply(this, arguments);
    }
});

}

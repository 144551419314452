import React, { useState } from 'react'
import AdminUserSearchInput from './AdminUserSearchInput'
import AdminUserDetails from './AdminUserDetails'

const AdminUserSearch = () => {
  const [userInputSearch, setPatientInputSearch] = useState(null)

  return (
    <div className='mt-5'>
      <div className='columns'>
        <div className='column is-4'>
          {(userInputSearch && (
            <span className='tag is-primary is-medium'>
              {`${userInputSearch.name} (${userInputSearch.email})`}
              <button
                className='delete'
                onClick={() => setPatientInputSearch(null)}
              ></button>
            </span>
          )) || <AdminUserSearchInput onSelect={setPatientInputSearch} />}
        </div>
      </div>
      {userInputSearch && (
        <AdminUserDetails userEmail={userInputSearch.email}></AdminUserDetails>
      )}
    </div>
  )
}

export default AdminUserSearch

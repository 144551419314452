if (typeof window !== 'undefined') {

/*
    Config - configuration class
*/
bcViewer.Config = function(overrides) {
//    this.BC_VIEWER_WIDTH  = 804;
//    this.BC_VIEWER_HEIGHT  = 450;

    this.ecgViewer = {
        CACHE_WINDOW_SIZE: 3,
        DEFAULT_ZOOM: {
            'x': 25,
            'y': 10
        },
        DEFAULT_FILTERS: {
            BASELINE: true,
            SMOOTH: true
        },
        SHOW_GRID: true,
        MEASUREMENT: {
            MIN_HR: 10,
            MAX_HR: 1200,
            DISPLAY_PREDICTIVE_MEASUREMENT: false
        },
        REALTIME: {
            BUFFERING_INITIAL_SIZE: 200,
            BUFFER_INCREMENT_SIZE: 100,
            CRITICAL_BUFFER_SIZE: 50, // Below this size the velocity will drop to critical buffer writing speed
            CRITICAL_BUFFER_WRITING_SPEED: 3
        },
        CHANNELS_SPACING: {
            DEFAULT_SPACING: -2,
            MAX_STEPS: 6,
            MV_PER_STEP: 0.25
        },
        MAGNIFY: {
            MIN: 4,
            MAX: 20
        }
    };

    this.contextViewer = {
        HIDDEN: false,
        CACHE_WINDOW_SIZE: 3,
        DEFAULT_SELECTED_CHANNEL: 0,
        HEIGHT: 28,
        DEFAULT_ZOOM: {
            'x': 5,
            'y': 2
        },
        SHOW_GRID: false
    };

    this.timelineViewer = {
        HIDDEN: false,
        HEIGHT: 20
    };

    this.HRViewer = {
        HIDDEN: false,
        HEIGHT: 80
        // We are setting this viewer width below
    };

    this.eventsViewer = {
        HIDDEN: false,
        HEIGHT: 24,
        DEFAULT_SELECTED_EVENT_ID: 2
        // We are setting this viewer width below
    };

    this.ecgGrid = {
        SMALL_BLOCK_LINE_COLOR: '#D1D1D1',
        BIG_BLOCK_LINE_COLOR: '#B1B1B1',
        SMALL_BLOCKS_PER_BIG_BLOCK: 10
    };

    this.userMarks = {
      DEFAULT_COLOR: '#FF0000',
      HIGHLIGHT_COLOR: '#FF8080',
      DISABLED_COLOR: '#CACACA'
    };

    this.appConfig = {
        LOW_RESOLUTION_WIDTH_LIMIT: 930
    };

    this.qrsTypes = {
        1: {
            COLOR_NAME: "QRS_N"
        },
        2: {
            COLOR_NAME: "QRS_B"
        },
        3: {
            COLOR_NAME: "QRS_S"
        },
        4: {
            COLOR_NAME: "QRS_V"
        },
        5: {
            COLOR_NAME: "QRS_F"
        },
        6: {
            COLOR_NAME: "QRS_P"
        }
    };

    // Currently assuming same channel colors for all viewers
    this.DEFAULT_COLOR             = '#000000';
    this.DEFAULT_CHANNEL_COLOR     = '#000000';
    this.DEFAULT_ANNOTATION_COLOR  = '#000000';
    this.DEFAULT_EVENT_GRAPH_COLOR = '#000000';
    this.COLORS_NUMBER = 3;
    this.COLORS = {
        'Ch0':  '#0000a0',
        'Ch1':  '#017b1b',
        'Ch2':  '#0a9092',
        'QRS_A': "#7c7c7c",
        'QRS_N': "#288c8c",
        'QRS_S': "#00007c",
        'QRS_V': "#7c0000",
        'QRS_B': "#00007F",
        'QRS_F': "#80007F",
        'QRS_P': "#007F00",
        'colorEventsGr': "#00ffff",
        'colorHRaver'  : "#0000fa",
        'colorHRmax'   : "#404040",
        'colorHRmin'   : "#404040",
        'colorStGr0'   : "#009696",
        'EVENT_C': '#0a9092',
        'EVENT_G': '#7c7c7c',
        'EVENT_R': '#7c0000',
        'EVENT_B': '#00007F',
        'EVENT_O': '#FFAE00',
        'EVENT_GR': '#007F00'
    };

    this.HIDE_HEADER = true;

    this.controlPanel = {
        HIDDEN: false
    };

    // Overide config defaults
    $.extend(true, this, overrides); // TODO: do we want to protect against illegal overrides?

    this.ecgGrid.NUM_PIXELS_PER_SMALL_BLOCK = 4;
    this.ecgGrid.SMALL_BLOCK_SIZE = 1; // mm.

    // Config parameters that the user can't override
    this.PIXELS_SIZE    = this.ecgGrid.SMALL_BLOCK_SIZE / this.ecgGrid.NUM_PIXELS_PER_SMALL_BLOCK; // mm. TODO: we need to fully understand this
    this.PIXEL_PER_MM   = 1/this.PIXELS_SIZE;
    this.PIXEL_FACTOR   = this.PIXELS_SIZE * 1000; // We need this is few calculations

    // Timeline, HR and events must have the same width
    this.HRViewer.WIDTH     = this.timelineViewer.WIDTH;
    this.eventsViewer.WIDTH = this.timelineViewer.WIDTH;
};

}

import React, { useState } from 'react'
import Modal from '../Modal'
import { gql, useQuery, useMutation } from '@apollo/client'
import { GET_QUEUE_MEMBERS } from '../queries'
import clsx from 'clsx'
import Loader from '../Loader'

const ADMIN_UPDATE_REFERRAL = gql`
  mutation ADMIN_UPDATE_REFERRAL(
    $input: ReferralUpdateInput!
    $releaseTargetCaregiverId: ID!
    $releaseTargetClinicId: ID!
    $targetClinic: ClinicConnectionInput!
    $targetCaregiver: CaregiverConnectionInput!
    $isRelease: Boolean!
    $isAssign: Boolean!
  ) {
    studyReferralRelease(
      input: $input
      targetCaregiverId: $releaseTargetCaregiverId
      targetClinicId: $releaseTargetClinicId
    ) @include(if: $isRelease) {
      id
    }
    studyReferralAssign(
      input: $input
      targetClinic: $targetClinic
      targetCaregiver: $targetCaregiver
    ) @include(if: $isAssign) {
      id
    }
  }
`

const AdminAssignQueueReferral = ({ referral, onComplete, onExit }) => {
  const [inProgress, setProgress] = useState(false)
  const [assignedMember, setAssignedMember] = useState(
    referral.isTargetIsQueue
      ? referral.targetQueue.id
      : referral.targetCaregiver.id
  )
  const { loading, data } = useQuery(GET_QUEUE_MEMBERS, {
    variables: { id: referral.targetQueue.id }
  })

  const [updateReferral] = useMutation(ADMIN_UPDATE_REFERRAL)

  async function submit() {
    setProgress(true)
    let isRelease,
      isAssign,
      targetCaregiver = {
        id: 'TEMP',
        name: 'TEMP',
        email: 'TEMP@beecardia.com'
      },
      targetClinic = {
        id: 'TEMP',
        name: 'TEMP'
      },
      releaseTargetCaregiverId = 'TEMP',
      releaseTargetClinicId = 'TEMP'

    const queueMember = data.referralsQueue.caregivers.find(
      (queueMember) => queueMember.id === assignedMember
    )

    if (queueMember) {
      targetCaregiver = {
        id: queueMember.id,
        name: queueMember.name,
        email: queueMember.email
      }
      targetClinic = {
        id: queueMember.clinic.id,
        name: queueMember.clinic.name
      }
    }

    if (referral.isTargetIsQueue) {
      isRelease = false
      isAssign = true
    } else if (assignedMember === data.referralsQueue.id) {
      isRelease = true
      isAssign = false
      releaseTargetCaregiverId = referral.targetCaregiver.id
      releaseTargetClinicId = referral.targetClinic.id
    } else {
      isRelease = true
      isAssign = true
      releaseTargetCaregiverId = referral.targetCaregiver.id
      releaseTargetClinicId = referral.targetClinic.id
    }

    await updateReferral({
      variables: {
        input: {
          id: referral.id,
          updatedAt: new Date().toISOString()
        },
        releaseTargetCaregiverId,
        releaseTargetClinicId,
        targetCaregiver,
        targetClinic,
        isRelease,
        isAssign
      }
    })
    setProgress(false)
    onComplete()
    alert('Referral is assigned, refresh the page to see the result')
  }

  return (
    <Modal onClose={onExit} onSubmit={submit} inProgress={inProgress}>
      <div className='field'>
        <label className='label'>Assign referral</label>
        {loading ? (
          <Loader />
        ) : (
          <div className='control'>
            <div
              className={clsx('select', {
                'is-loading': false
              })}
            >
              <select
                value={assignedMember}
                onChange={(e) => setAssignedMember(e.target.value)}
              >
                <option value={data.referralsQueue.id}>
                  {data.referralsQueue.name}
                </option>
                <option disabled>{'----'}</option>
                {data.referralsQueue.caregivers
                  .filter(
                    (queueMember) =>
                      queueMember.referralType === referral.referralType
                  )
                  .map((queueMember) => (
                    <option value={queueMember.id} key={queueMember.id}>
                      {queueMember.name}
                    </option>
                  ))}
              </select>
            </div>
          </div>
        )}
        <div className='has-text-right'>
          <button
            className={clsx('button is-primary mt-4', {
              'is-loading': inProgress
            })}
            disabled={inProgress}
            onClick={submit}
          >
            OK
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default AdminAssignQueueReferral

import React, { useState } from 'react'
import { gql, useMutation, useQuery, useLazyQuery } from '@apollo/client'
import clsx from 'clsx'
import { GET_REFERRALS_CONFIGS } from '../queries'
import Loader from '../Loader'

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

const GENERATE_ADMIN_REPORT = gql`
  mutation GENERATE_ADMIN_REPORT(
    $type: AdminReportType!
    $group: String!
    $fromDate: AWSDate!
    $toDate: AWSDate
    $excludes: [String]!
  ) {
    startAdminReportGeneration(
      type: $type
      group: $group
      fromDate: $fromDate
      toDate: $toDate
      excludes: $excludes
    ) {
      id
    }
  }
`

const GET_ADMIN_REPORT_STATUS = gql`
  query GET_ADMIN_REPORT_STATUS($id: ID!, $type: AdminReportType!) {
    admin {
      id
      reportStatus(id: $id, type: $type)
    }
  }
`

const GET_ADMIN_REPORT_RESULTS = gql`
  query GET_ADMIN_REPORT_RESULTS($id: ID!, $type: AdminReportType!) {
    admin {
      id
      reportResults(id: $id, type: $type) {
        results
      }
    }
  }
`

const AdminReports = () => {
  const [type, setType] = useState('users')
  const [group, setGroup] = useState('-1')
  const [fromDate, setFromDate] = useState('')
  const [toDate, setToDate] = useState('')
  const [excludes, setExcludes] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [isValidToDate, setIsValidToDate] = useState(true)
  const [isShowRequiredFields, setIsShowRequiredFields] = useState(false)
  const [generateAdminReport] = useMutation(GENERATE_ADMIN_REPORT)
  const [getAdminReportStatus] = useLazyQuery(GET_ADMIN_REPORT_STATUS, {
    fetchPolicy: 'network-only'
  })
  const [getAdminReportResults, { data }] = useLazyQuery(
    GET_ADMIN_REPORT_RESULTS,
    {
      fetchPolicy: 'network-only'
    }
  )
  const {
    loading: configsLoading,
    error: configsError,
    data: configsData
  } = useQuery(GET_REFERRALS_CONFIGS, {})

  let results = []

  if (data && data.admin.reportResults.results) {
    results = JSON.parse(data.admin.reportResults.results)
  }

  return (
    <div className='mt-5'>
      {configsLoading ? (
        <Loader />
      ) : (
        <div>
          <div className='field is-horizontal mt-5'>
            <div className='field-body'>
              <div className='field is-narrow'>
                <div className='control'>
                  <div className='select'>
                    <select
                      value={type}
                      onChange={(e) => setType(e.target.value)}
                    >
                      <option value='users'>users</option>
                      <option value='reports'>reports</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className='field is-narrow'>
                <div className='control'>
                  <div className='select'>
                    <select
                      value={group}
                      onChange={(e) => setGroup(e.target.value)}
                    >
                      <option disabled='disabled' value='-1'>
                        Select group
                      </option>
                      {configsData.referralsConfigs.map(({ id: groupId }) => (
                        <option value={groupId} key={groupId}>
                          {groupId}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                {isShowRequiredFields && (
                  <p className='help is-danger'>This field is required</p>
                )}
              </div>
              <div className='field is-narrow'>
                <div className='control'>
                  <input
                    type='month'
                    className={clsx('input', {
                      'is-danger': isShowRequiredFields
                    })}
                    placeholder='from date'
                    value={fromDate}
                    onChange={(e) => setFromDate(e.target.value)}
                  />
                </div>
                {isShowRequiredFields && (
                  <p className='help is-danger'>This field is required</p>
                )}
              </div>
              <div className='field is-narrow'>
                <div className='control'>
                  <input
                    type='month'
                    className={clsx('input', { 'is-danger': !isValidToDate })}
                    placeholder='to date'
                    max={new Date()
                      .toISOString()
                      .split('-')
                      .slice(0, 2)
                      .join('-')}
                    value={toDate}
                    onChange={(e) => setToDate(e.target.value)}
                  />
                </div>
                {!isValidToDate && (
                  <p className='help is-danger'>
                    Should be greater than from date
                  </p>
                )}
              </div>
              <div className='field'>
                <div className='control'>
                  <input
                    type='text'
                    className='input'
                    placeholder='excludes'
                    value={excludes}
                    onChange={(e) => setExcludes(e.target.value)}
                  />
                </div>
              </div>
              <div className='field is-narrow'>
                <div className='control'>
                  <button
                    className={clsx('button is-primary', {
                      'is-loading': isLoading
                    })}
                    disabled={isLoading}
                    onClick={async () => {
                      setIsShowRequiredFields(false)
                      setIsValidToDate(true)
                      if (!group || !fromDate) {
                        setIsShowRequiredFields(true)
                        return
                      }
                      if (toDate && toDate <= fromDate) {
                        setIsValidToDate(false)
                        return
                      }
                      setIsLoading(true)
                      const {
                        data: {
                          startAdminReportGeneration: { id }
                        }
                      } = await generateAdminReport({
                        variables: {
                          type,
                          group,
                          fromDate: fromDate + '-01',
                          toDate: toDate ? toDate + '-01' : undefined,
                          excludes: excludes.split(',').filter((value) => value)
                        }
                      })

                      let status = 'RUNNING'
                      while (status === 'RUNNING') {
                        await sleep(1000)
                        let {
                          data: {
                            admin: { reportStatus }
                          }
                        } = await getAdminReportStatus({
                          variables: {
                            id,
                            type
                          }
                        })
                        status = reportStatus
                      }
                      if (status === 'SUCCEEDED') {
                        await getAdminReportResults({
                          variables: {
                            id,
                            type
                          }
                        })
                      } else if (status === 'FAILED') {
                        setIsError(true)
                      }
                      setIsLoading(false)
                    }}
                  >
                    Generate
                  </button>
                </div>
              </div>
              <div className='field is-narrow'>
                <div className='control'>
                  <a
                    className={clsx('button is-primary')}
                    disabled={results.length === 0}
                    href={
                      'data:text/json;charset=utf-8,' +
                      encodeURIComponent(JSON.stringify(results))
                    }
                    download={`${group}.${type}.json`}
                    target='_blank'
                    rel='noreferrer'
                  >
                    Download
                  </a>
                </div>
              </div>
            </div>
          </div>
          {results.length > 0 ? (
            <div className='table-container'>
              <table className='table is-fullwidth is-hoverable is-striped mt-5'>
                <thead>
                  <tr>
                    {Object.keys(results[0]).map((title) => (
                      <th key={title}>{title}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {results.map((item) => (
                    <tr
                      key={JSON.stringify(item)}
                      style={{ textWrap: 'nowrap' }}
                    >
                      {Object.keys(item).map((key) => (
                        <td key={key}>{item[key]}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : data && results.length === 0 ? (
            <div className='has-text-centered mt-5'>
              <span className='tag is-warning is-large'>No Results</span>
            </div>
          ) : isError || configsError ? (
            <div className='has-text-centered mt-5'>
              <span className='tag is-danger is-large'>Error</span>
            </div>
          ) : null}
        </div>
      )}
    </div>
  )
}

export default AdminReports

import React, { useState, useContext, useEffect } from 'react'
import clsx from 'clsx'
import { GET_STUDIES, GET_STUDY_REPORT } from '../queries'
import useMemoryPagination, {
  prev,
  next,
  init
} from '../../hooks/useMemoryPagination'
import {
  DATE_FORMAT_CONVERTER,
  durationToHuman,
  adjustTimeToTimezone
} from '../../../utils/utils'
import AuthContext from '../AuthContext'
import { useLazyQuery, useApolloClient } from '@apollo/client'

const FIRST = 20

const AdminUserStudies = ({ clinicId }) => {
  const currentUser = useContext(AuthContext)
  const [fetchingReportStudyId, setFetchingReportStudyId] = useState(null)
  const [studiesPaginationState, dispatchStudies] = useMemoryPagination()
  const [getStudies, { loading, error, data }] = useLazyQuery(GET_STUDIES)
  const client = useApolloClient()

  function doGetStudies(initPagination) {
    initPagination && dispatchStudies(init())
    getStudies({
      variables: {
        id: clinicId,
        patientId: '',
        first: FIRST,
        after: studiesPaginationState
      }
    })
  }

  useEffect(() => {
    doGetStudies(true)
  }, [clinicId])

  useEffect(() => {
    doGetStudies(false)
  }, [studiesPaginationState])

  return (
    <div>
      {loading && <p>Loading...</p>}
      {error && <div> error... </div>}

      {data && (
        <div>
          <table className='table is-fullwidth is-hoverable'>
            <thead>
              <tr>
                <th>Patient</th>
                <th>Owner</th>
                <th>Shared by</th>
                <th>Type</th>
                <th>Date</th>
                <th>Duration</th>
                <th className='has-text-centered'>Report</th>
              </tr>
            </thead>
            <tbody>
              {data.clinic.studies.edges.map(({ node: study }) => (
                <tr
                  key={study.id}
                  className='c-hand'
                  onClick={() =>
                    window.open(`/study/${study.studyId}`, '_blank')
                  }
                >
                  <td>{study.patient.name}</td>
                  <td>{study.patient.owner}</td>
                  <td>
                    {study.shareType !== 'ShareOriginal'
                      ? study.sourceCaregiver.name
                      : ''}
                  </td>
                  <td>{study.protocol}</td>
                  <td>
                    {adjustTimeToTimezone(
                      study.studyCreatedAt,
                      study.timezoneOffset
                    ).format(
                      `${
                        DATE_FORMAT_CONVERTER[currentUser.settings.dateFormat]
                      } HH:mm:ss`
                    )}
                  </td>
                  <td>
                    {['Rest', 'Holter', 'Stethoscope'].includes(
                      study.protocol
                    ) && study.duration
                      ? durationToHuman(study.duration)
                      : ''}
                  </td>
                  <td className='has-text-centered is-vcentered'>
                    {study.hasReport && (
                      <button
                        className={clsx('button is-text', {
                          'is-loading': study.studyId === fetchingReportStudyId
                        })}
                        onClick={async (e) => {
                          e.stopPropagation()
                          setFetchingReportStudyId(study.studyId)
                          const { data } = await client.query({
                            query: GET_STUDY_REPORT,
                            variables: {
                              id: study.studyId
                            }
                          })
                          setFetchingReportStudyId(null)
                          if (
                            data &&
                            data.study &&
                            data.study.reports &&
                            data.study.reports.length > 0
                          ) {
                            window.open(
                              data.study.reports[0].privatePdf ||
                                data.study.reports[0].publicPdf
                                ? `/study/${study.studyId}?report=true`
                                : `/study/${study.studyId}/report/${data.study.reports[0].id}`,
                              '_blank'
                            )
                          }
                        }}
                      >
                        View report
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <nav className='pagination' role='navigation' aria-label='pagination'>
            <a
              className='pagination-previous'
              disabled={!data.clinic.studies.pageInfo.hasPreviousPage}
              onClick={() => {
                if (data.clinic.studies.pageInfo.hasPreviousPage) {
                  dispatchStudies(prev())
                }
              }}
            >
              Previous
            </a>
            <a
              className='pagination-next'
              disabled={!data.clinic.studies.pageInfo.hasNextPage}
              onClick={() => {
                if (data.clinic.studies.pageInfo.hasNextPage) {
                  dispatchStudies(next(data.clinic.studies.pageInfo.endCursor))
                }
              }}
            >
              Next
            </a>
          </nav>
        </div>
      )}
    </div>
  )
}

export default AdminUserStudies

if (typeof window !== 'undefined') {

/*
    TimelineViewer - the time line viewer class
*/
bcViewer.TimelineViewer = bcViewer.BaseViewer.extend({
    construct: function() {
        this._super.apply(this, arguments);
        this._supportedStateChanges.push('userMarks');

        this._onePixelDuration;

        this._view = new bcViewer.TimelineViewer.View(this._globals, '#bc_viewer .bcv_timeline_canvas',
                                        bcViewer.TimelineCanvas, this._state.width, this._state.height);

        this._isTimeLineReady = false;
    },

    /*
        Apply new state: update the hightlight position and redraw the timeline if dimensions changed
    */
    applyStateChanges: function(stateChanges, isInitCycle) {
        this._super(stateChanges, isInitCycle);

        if (isInitCycle) return;

        if (!this._isTimelineReady) {
            this._buildTimeLine();
            this._view.setCurrentTime(this._state.timePosition);
            this._isTimelineReady = true;
        }

        var updateTimePosition = (typeof stateChanges.timePosition !== 'undefined');

        if ((typeof stateChanges.width != 'undefined') ||
            (typeof stateChanges.height != 'undefined') ||
            (typeof stateChanges.userMarks != 'undefined')) {
                this._buildTimeLine();
                updateTimePosition = true;
        }
        updateTimePosition && this._view.setCurrentTime(this._state.timePosition);
    },

    /*
        Draw the timeline
    */
    _buildTimeLine: function() {
        try {
          this._onePixelDuration = this._metadata.Duration / this._state.width;

          this._view.drawTimeline(this._metadata, this._state.width);

          this.enableViewer();

          !this.isViewerReady && this._onLoadingEnd();
        } catch (e) {
        }
    },

    /*
        Convert time position (ms) to pixels position
    */
    convertTimeToPixels: function(timePosition) {
        return timePosition / this._onePixelDuration;
    },

    /*
        Convert pixels position to time (ms)
    */
    convertPixelsToTime: function(pixels) {
        return pixels * this._onePixelDuration;
    }
});

}

if (typeof window !== 'undefined') {

/*
    bcSpiroViewerTestManager - Handle spirometer test
*/
var pitchManager = function(spiroViewer, dataFetcher) {
    this._spiroViewer = spiroViewer;
    this._dataFetcher = dataFetcher;

    // Decide if we pitch with JS or with JAVA
    this._pitchFromServer = (typeof AudioContext === 'undefined');
    
    if (!this._pitchFromServer) {
        this.audioContext = new AudioContext();
    }
    
    // Pitch variables
    this._osc = null;
    this._testTimeoutTime = 180000; // 3 minutes 
    this._testTimeout = null;
    
    this._powerupOsc = null;
    this._powerupOscTimeoutTime = 1000; // 1 second 
    this._powerupOscTimeout = null;
};

pitchManager.prototype.shouldPitchFromServer = function() {
    return this._pitchFromServer;
};

/*
    Start pitch or set timeout (if pitch from java)
*/
pitchManager.prototype.start = function(onTimeout) {
    if (!this._pitchFromServer) {
        this._startPitch();
    } else {
        this._testTimeout = setTimeout(onTimeout,this._testTimeoutTime);
    }
};

/*
    Stop pitch or clear timeout (if pitch from java)
*/
pitchManager.prototype.stop = function(onTestTimeout) {
    if (!this._pitchFromServer) {
        this._stopPitch();
    } else {
        clearTimeout(this._testTimeout);
    }
};

/*
    Start pitch
*/
pitchManager.prototype._startPitch = function() {
    this._osc = this.audioContext.createOscillator();
    this._powerupOsc = this.audioContext.createOscillator();
    
    this._powerupOsc.frequency.value = 10500;
    this._powerupOsc.connect(this.audioContext.destination);
    this._powerupOsc.start(0);
        
    this._powerupOscTimeout = setTimeout($.proxy(function() {
        this._powerupOsc.stop(0);
        this._osc.frequency.value = 10000;
        this._osc.connect(this.audioContext.destination);
        this._osc.start(0);    
    },this), this._powerupOscTimeoutTime);    
};

/*
    Stop pitch
*/
pitchManager.prototype._stopPitch = function() {
    try {
        this._osc.stop(0);
    } catch(e) {
    }
    
    try {
        this._powerupOsc.stop(0);
    } catch(e) {
    }
    
    clearTimeout(this._powerupOscTimeout);
    delete this._powerupOscTimeout;
};

window.pitchManager = pitchManager;

}
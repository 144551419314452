if (typeof window !== 'undefined') {

/*
    bcSpiroViewerResultsManager - Calculate and handle spirometer results
*/
var bcSpiroViewerResultsManager = function(spiroViewer) {
    this._spiroViewer = spiroViewer;
     
    this.TestResultData = function() {
        this.flowArr = [];
        this.volume = [];
        this.origFlowArr = [];
        this.FVC = 0;
        this.FEV1 = 0;
        this.ratio = 0;
        this.PEF = 0;
        this.duration = 0;
        this.startTime = 0;
        this.endTime = 0;
    };
    
    this._testResults = {
        tests: [],
        bestTest: {}
    };
    
    this._sampleRate = 44100;
    this._flowWindowSlide = 512;
    this._flowPerSecond = (this._spiroViewer.getSpiroType() !== this._spiroViewer.spiroTypes.POND) ? 100 : (this._sampleRate / this._flowWindowSlide);
    this._volumeMultiplier = (1/this._flowPerSecond);
           
    // For flow calibration
    this._flowFactor = {
        0.1:    1.31,
        0.15:   1.233,
        0.2:    1.173,
        0.25:   1.126,
        0.3:    1.089,
        0.35:   1.06,
        0.4:    1.037,
        0.45:   1.018,
        0.5:    1.003,
        0.55:   0.991,
        0.6:    0.981,
        0.65:   0.973,
        0.7:    0.966,
        0.75:   0.96,
        0.8:    0.955,
        0.85:   0.951,
        0.9:    0.947,
        0.95:   0.943,
        1:      0.94,
        1.05:   0.936,
        1.1:    0.934,
        1.15:   0.931,
        1.2:    0.928,
        1.25:   0.925,
        1.3:    0.923,
        1.35:   0.92,
        1.4:    0.918,
        1.45:   0.916,
        1.5:    0.913,
        1.55:   0.911,
        1.6:    0.909,
        1.65:   0.906,
        1.7:    0.9
    };    
    
    this._view = new bcSpiroViewerResultsView(this._spiroViewer, this);
    
};

/*
    Get the spirometer test results (flow and time) and calculate all the needed results 
*/                
bcSpiroViewerResultsManager.prototype.calculateResults = function(testResults) {
    var resultsData = new this.TestResultData();
    resultsData.startTime = testResults.startTime;
    resultsData.endTime = testResults.endTime;
    resultsData.flowArr = testResults.flowArr;
    
    this._fillResults(resultsData);

    this._testResults.tests.push(resultsData);
    
    this._calculateBestTest();
};

/*
    Fill the results to the input (resultsData)
*/
bcSpiroViewerResultsManager.prototype._fillResults = function(resultsData) {
    // Copy and save the flow array before manipulations
    resultsData.origFlowArr = resultsData.flowArr.slice();
    
    this._adjustFlowArray(resultsData);
        
    var flowFactor = this._getFlowFactor(resultsData);
    
    // Calibration
    resultsData.flowArr = $.map(resultsData.flowArr, function(val, ind) {
        return val * flowFactor;
    });

    this._buildVolumeArray(resultsData);
    
    var FVC = this._calcFVC(resultsData);
    var result = this._calcFEV1AndPef(resultsData, FVC);
    var FEV1 = result.FEV1;
    var PEF = result.PEF;
        
    this._spiroViewer.writeToLogFile("flowFactor: " + flowFactor);
    this._spiroViewer.writeToLogFile("FVC: " + FVC);
    this._spiroViewer.writeToLogFile("FEV1: " + FEV1);
    this._spiroViewer.writeToLogFile("PEF: " + PEF);
    
    resultsData.FVC = FVC;
    resultsData.FEV1 = FEV1;
    resultsData.ratio = (FVC !== 0) ? (FEV1 / FVC) : 0;
    resultsData.PEF = PEF;
    resultsData.duration = (resultsData.flowArr.length / this._flowPerSecond);

    this.linearFilter(resultsData.flowArr);
};

/*
    Remove all flow under 0.1 L and add 0 flow to the start and end of the test
*/
bcSpiroViewerResultsManager.prototype._adjustFlowArray = function(resultsData) {
    // Remove <= 0.1 (inhale + threshold) 
    resultsData.flowArr = $.grep(resultsData.flowArr, $.proxy(function(val, ind) {
        var cutoff = (this._spiroViewer.getSpiroType() !== this._spiroViewer.spiroTypes.POND) ? 0 : 0.1;
        return (val >= cutoff);
    }, this));
    
    resultsData.flowArr.unshift(0); // push one zero pitch point to beginning
    resultsData.flowArr.push(0); // push one zero pitch point to end
};

/*
    Calculate the flow factor (used for results adjustments)
*/
bcSpiroViewerResultsManager.prototype._getFlowFactor = function(resultsData) {
    // No post calibration for bee spiro
    if (this._spiroViewer.getSpiroType() !== this._spiroViewer.spiroTypes.POND) {
        return 1;
    }
    
    var sum = 0;
    for (var i = 0; i < resultsData.flowArr.length; i++ ) {
        sum += resultsData.flowArr[i];
    } 
    
    var avg = (sum / resultsData.flowArr.length);
        
    // round avg to closest to 0.05 step
    var step = 0.05;
    var factor = parseInt(avg / step) * step;
    if (!((avg % step) > (step / 2))) {
        step = 0;
    }
    
    closestToAvg = Number(parseFloat(factor + step).toFixed(2));
    
    var flowFactor;
    if (closestToAvg < 0.1) {
        flowFactor = this._flowFactor[0.1];
    } else if (closestToAvg <= 1.7) {
        flowFactor = this._flowFactor[closestToAvg];
    } else {
        flowFactor = this._flowFactor[1.7];
    }
    
    return flowFactor;
};

/*
    Calculate the volume array
*/
bcSpiroViewerResultsManager.prototype._buildVolumeArray = function(resultsData) {
    var sum = 0;
    resultsData.volume = $.map(resultsData.flowArr, $.proxy(function(val, ind) {
        sum += (this._volumeMultiplier * val);
        return sum;
    }, this));
};

/*
    Calculate FVC
*/
bcSpiroViewerResultsManager.prototype._calcFVC = function(resultsData) {
    var volumeSum = resultsData.volume[(resultsData.volume.length-1)];
    
    if (this._spiroViewer.getSpiroType() !== this._spiroViewer.spiroTypes.POND) {
        return volumeSum;
    }
    
    if (volumeSum < 0.5) {
        return volumeSum * 1.9149999618530273;
    }
    if (volumeSum <= 0.5 || volumeSum >= 2) {
        return volumeSum;
    }
    return volumeSum * 0.9415000081062317;
};

/*
    Calculate FEV1 and PEF
*/
bcSpiroViewerResultsManager.prototype._calcFEV1AndPef = function(resultsData, FVC) {
    var result = {
        FEV1: 0,
        PEF: 0
    };
    
    var maxFlow = 0;
    var maxFlowIndex = 0;
    var flowSum = 0;
    var flowSumArr = [];
    for (var i = 0; i < resultsData.flowArr.length; i++) {
        var flow = resultsData.flowArr[i];
        if (maxFlow < flow) {
            maxFlow = flow;
            maxFlowIndex = i;
        }
        flowSum += flow;
        flowSumArr.push(flowSum);
    }
    
    var flowSumAtMaxFlow = flowSumArr[maxFlowIndex];
    var FEV1StartIndex = Math.ceil(maxFlowIndex - (flowSumAtMaxFlow / maxFlow));
    var index = FEV1StartIndex + parseInt(this._flowPerSecond); // + 1 second
    var computedFEV1 = (resultsData.volume.length > index) ? resultsData.volume[index] : 0;
    if (resultsData.volume[FEV1StartIndex] > Math.max(FVC * 0.05, 0.15)) {
        computedFEV1 = 0;
    }
    
    if (this._spiroViewer.getSpiroType() === this._spiroViewer.spiroTypes.POND) {
        if (FVC < 0.5) {
            result.FEV1 = computedFEV1 * 1.3839999437332153;
        } else if (FVC <= 0.5 || FVC >= 2) {
            result.FEV1 = computedFEV1;
        } else{
            result.FEV1 = computedFEV1 * 1.0429999828338623;
        }
    } else {
        result.FEV1 = computedFEV1;
    }
    
    result.PEF = resultsData.flowArr[maxFlowIndex];
    
    return result;
};

/*
    Linear filter on the input array
*/
bcSpiroViewerResultsManager.prototype.linearFilter = function(arr) {
    var windowSize = 3;
    var curStep = 0;
    var prevValues = [];
    var initValue = 0;
    var sum = windowSize * initValue;
          
    for (var i = 0; i < windowSize; i++) {
        prevValues[i] = initValue;
    } 
     
    for (var i=0; i< arr.length-1; i++) {
        sum -= prevValues[curStep];
        prevValues[curStep] = arr[i];
        sum += arr[i];
        curStep = (curStep + 1) % windowSize;
        arr[i] = Number(parseFloat((sum / windowSize)).toFixed(2));
    }
};

/*
    Calculate the best test results
*/
bcSpiroViewerResultsManager.prototype._calculateBestTest = function() {
    var bestTest = new this.TestResultData();
    
    var bestScore = 0;
    var bestFVC = -1;
    var bestFEV1 = -1;
    var bestPEF = -1;
    var bestTestIndex = 0;
    for (var i = 0; i < this._testResults.tests.length; i++) {
        var test = this._testResults.tests[i];
        
        var score = test.FVC + test.FEV1;
        if (score > bestScore) {
            bestScore = score;
            bestTestIndex = i;
        }
        
        if (test.FVC > bestFVC) {
            bestFVC = test.FVC;
        }
        
        if (test.FEV1 > bestFEV1) {
            bestFEV1 = test.FEV1;
        }
        
        if (test.PEF > bestPEF) {
            bestPEF = test.PEF;
        }
    }
    
    bestTest = jQuery.extend(true, {}, this._testResults.tests[bestTestIndex]);
    bestTest.FVC = bestFVC;
    bestTest.FEV1 = bestFEV1;
    bestTest.PEF = bestPEF;
    bestTest.ratio = (bestFVC !== 0) ? (bestFEV1 / bestFVC) : 0;
    
    this._testResults.bestTest = bestTest;
};

/*
    Show the results
*/
bcSpiroViewerResultsManager.prototype.showResults = function(predictor) {
    this._view.showResults(this._testResults, predictor, false);
};

/*
    Hide the results
*/
bcSpiroViewerResultsManager.prototype.hideResults = function() {
    this._view.hideResults();
};

/*
    Handle add test request
*/
bcSpiroViewerResultsManager.prototype.onAddTestRequest = function() {
    this._spiroViewer.onAddTestRequest();
};

/*
    Return the tests count
*/
bcSpiroViewerResultsManager.prototype.getTestsCount = function() {
    return this._testResults.tests.length;
};

/*
    Handle save resutls request
*/
bcSpiroViewerResultsManager.prototype.save = function() {
    this._spiroViewer.save(this._testResults);
};

/*
    Show saved resutls (results coming from file not from current test)
*/
bcSpiroViewerResultsManager.prototype.showSavedResults = function(recordData, predictor) {
    this._testResults.tests = recordData.tests;
    this._testResults.bestTest = recordData.bestTest;
    
    this._view.showSavedResults(this._testResults, predictor);
    this._spiroViewer.onReadyToPrint();
};

/*
    Handle predictor changed request
*/
bcSpiroViewerResultsManager.prototype.onPredictorChanged = function(predictor) {
    this._spiroViewer.onPredictorChanged(predictor);
};

/*
    Fill the patient details
*/
bcSpiroViewerResultsManager.prototype.fillPatientDetails = function(patientDetails) {
    this._view.fillPatientDetails(patientDetails);
};

window.bcSpiroViewerResultsManager = bcSpiroViewerResultsManager;

}
import React from 'react'

const Questionnaire = ({ items }) => {
  return (
    <div className='content'>
      <ol>
        {items.map((item, index) => (
          <li key={index} className='pb-3'>
            <label className='has-text-weight-bold mr-1'>{item.title}</label>
            <label className='is-italic'>
              {item.type === 'radio' || item.type === 'select'
                ? item.options[item.value] || ''
                : item.value}
            </label>
          </li>
        ))}
      </ol>
    </div>
  )
}

export default Questionnaire

import React, { useState } from 'react'
import clsx from 'clsx'
import AdminAttachedServicePlans from './AdminAttachedServicePlans'
import AdminPendingServicePlans from './AdminPendingServicePlans'

const AdminServicePlans = () => {
  const [tab, setTab] = useState(null)

  return (
    <div>
      <div>
        <div className='mt-5'>
          <div className='tabs is-boxed'>
            <ul>
              <li
                className={clsx({
                  'is-active': tab === 'attached' || !tab
                })}
              >
                <a onClick={() => setTab('attached')}>Attached</a>
              </li>
              <li
                className={clsx({
                  'is-active': tab === 'pending'
                })}
              >
                <a onClick={() => setTab('pending')}>Pending</a>
              </li>
            </ul>
          </div>
        </div>

        <div>
          <div className='box'>
            {(!tab || tab == 'attached') && (
              <AdminAttachedServicePlans></AdminAttachedServicePlans>
            )}
            {tab == 'pending' && (
              <AdminPendingServicePlans></AdminPendingServicePlans>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdminServicePlans

import React, { useState } from 'react'
import ModalCard from './ModalCard'
import { useLocalization } from './LocalizationProvider'

export default function DiagnosisTags({ diagnosisTags, onComplete, onExit }) {
  const [selectedTags, setSelectedTags] = useState(diagnosisTags)
  const { locale } = useLocalization()

  function toggleTag(tag) {
    let tags = [...selectedTags]
    if (tags.includes(tag)) {
      tags.splice(tags.indexOf(tag), 1)
    } else {
      tags.push(tag)
    }
    setSelectedTags(tags)
  }

  function submit() {
    onComplete(selectedTags)
  }

  const tagsData = [
    {
      title: 'Aberrant Conduction',
      tagRows: [
        ['Aberrant conduction', 'AV-Block Mobitz type II'],
        ['AV-Block', 'Bundle branch block'],
        ['AV-Block 1st degree', 'Left bundle branch block'],
        ['AV-Block 2nd degree', 'Right bundle branch block'],
        ['AV-Block Mobitz type I']
      ]
    },
    {
      title: 'Chamber enlargement',
      tagRows: [
        ['Chamber enlargement', 'Right atrial enlargement'],
        ['Left atrial enlargement', 'Right ventricular enlargement'],
        ['Left ventricular enlargement']
      ]
    },
    {
      title: 'Tachyarrhythmia',
      tagRows: [
        ['Atrial fibrillation', 'Nodal tachycardia'],
        [
          'Atrial flutter',
          'Orthodromic atrioventricular reciprocating tachycardia'
        ],
        ['Atrial premature complexes', 'R-on-T phenomenon'],
        ['Atrial tachycardia', 'Tachyarrhythmia'],
        [
          'Atrioventricular nodal reciprocating tachycardia',
          'Ventricular bigeminy'
        ],
        ['Focal atrial tachycardia', 'Ventricular premature complexes'],
        ['Isorhythmic atrioventricular dissociation', 'Ventricular trigeminy']
      ]
    },
    {
      title: 'Wide Complex Tachycardia',
      tagRows: [
        [
          'Arrhythmogenic right ventricular tachycardia',
          'Supraventricular tachycardia with aberrant ventricular conduction'
        ],
        [
          'Monomorphic ventricular tachycardia',
          'Sustained monomorphic ventricular tachycardia'
        ],
        [
          'Monomorphic ventricular tachycardia (LV focus)',
          'Sustained polymorphic ventricular tachycardia'
        ],
        [
          'Monomorphic ventricular tachycardia (RV focus)',
          'Ventricular tachycardia'
        ],
        ['Polymorphic ventricular tachycardia', 'Wide complex tachycardia']
      ]
    },
    {
      title: 'Bradyarrhythmia',
      tagRows: [['Bradyarrhythmia', 'Sick sinus syndrome']]
    },
    {
      title: 'Escape Rhythm',
      tagRows: [
        ['Escape rhythm', 'Right ventricular escape rhythm'],
        ['Left ventricular escape rhythm', 'Ventricular escape rhythm'],
        ['Nodal escape rhythm']
      ]
    },
    {
      title: 'More tags',
      tagRows: [
        ['Fusion complexes', 'Normal sinus arrhythmia'],
        ['Idioventricular rhythm', 'Normal sinus rhythm'],
        ['Normal respiratory sinus arrhythmia']
      ]
    }
  ]

  return (
    <ModalCard
      onClose={onExit}
      onSubmit={submit}
      title={locale.diagnosis_popup_title}
      actions={
        <div className='container has-text-right'>
          <button
            className='button is-ghost'
            onClick={() => {
              onExit()
            }}
          >
            {locale.cancel}
          </button>
          <button
            className='button is-primary'
            onClick={() => {
              submit()
            }}
          >
            {locale.done}
          </button>
        </div>
      }
    >
      <div>
        {tagsData.map((tagCategory, i) => (
          <div key={i}>
            <div
              key={tagCategory.title}
              className='has-text-weight-bold is-size-5 mb-3'
            >
              {tagCategory.title}
            </div>

            {tagCategory.tagRows.map((tagsRow) => (
              <div key={tagsRow[0]} className='columns'>
                <div className='column'>
                  <label className='checkbox is-flex'>
                    <input
                      type='checkbox'
                      className='mr-2 mt-1'
                      checked={selectedTags.includes(tagsRow[0].toLowerCase())}
                      onChange={() => toggleTag(tagsRow[0].toLowerCase())}
                    ></input>
                    <span className='is-flex'>{tagsRow[0]}</span>
                  </label>
                </div>
                {tagsRow[1] && (
                  <div className='column'>
                    <label className='checkbox is-flex'>
                      <input
                        type='checkbox'
                        className='mr-2 mt-1'
                        checked={selectedTags.includes(
                          tagsRow[1].toLowerCase()
                        )}
                        onChange={() => toggleTag(tagsRow[1].toLowerCase())}
                      ></input>
                      <span className='is-flex'>{tagsRow[1]}</span>
                    </label>
                  </div>
                )}
              </div>
            ))}
            {i !== tagsData.length - 1 && <hr />}
          </div>
        ))}
      </div>
    </ModalCard>
  )
}

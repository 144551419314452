import React, { useState, useEffect, useContext } from 'react'
import { gql, useMutation } from '@apollo/client'
import { Link } from '@reach/router' // eslint-disable-line import/no-unresolved
import AuthContext from './AuthContext'
import Layout from './Layout'
import Loader from './Loader'
import { useLocalization } from './LocalizationProvider'

const TRANSACTION_ATTACH_TO_CLINIC = gql`
  mutation TRANSACTION_ATTACH_TO_CLINIC(
    $clinic: ClinicConnectionInput!
    $transactionId: ID!
  ) {
    transactionAttachToClinic(clinic: $clinic, transactionId: $transactionId) {
      id
    }
  }
`

const ActivateServicePlan = ({ transactionId }) => {
  const { locale } = useLocalization()
  const [isMounting, setIsMounting] = useState(true)
  const currentUser = useContext(AuthContext)
  const [transactionAttachToClinic, { data, loading, error }] = useMutation(
    TRANSACTION_ATTACH_TO_CLINIC,
    {
      variables: {
        transactionId,
        clinic: {
          id: currentUser.clinic.id,
          name: currentUser.clinic.name
        }
      }
    }
  )

  useEffect(() => {
    async function callMe() {
      setIsMounting(false)
      try {
        await transactionAttachToClinic()
      } catch {
        void 0
      }
    }
    callMe()
  }, [])

  return (
    <Layout>
      <div className='section'>
        <div className='container has-text-centered'>
          {isMounting || loading ? (
            <Loader />
          ) : (data &&
              data.transactionAttachToClinic &&
              data.transactionAttachToClinic.id) ||
            (error && error.message === 'ServicePlanActivated') ? (
            <>
              <p className='mb-4'>{locale.service_plan.activated}</p>
              <p>
                <Link to='/' className='button'>
                  {locale.service_plan.cta}
                </Link>
              </p>
            </>
          ) : (
            <p>{locale.service_plan.errors.error}</p>
          )}
        </div>
      </div>
    </Layout>
  )
}

export default ActivateServicePlan

import React, { useState } from 'react'
import Modal from '../Modal'
import { gql, useMutation, useApolloClient } from '@apollo/client'
import clsx from 'clsx'
import { GET_ADMIN_DATA_FROM_USER_EMAIL } from '../queries'

const CLINIC_ATTACH_TO_SERVICE_PLAN = gql`
  mutation CLINIC_ATTACH_TO_SERVICE_PLAN(
    $clinic: ClinicConnectionInput!
    $servicePlanId: ID!
  ) {
    clinicAttachToServicePlan(clinic: $clinic, servicePlanId: $servicePlanId) {
      id
    }
  }
`

const AttachToServicePlan = ({ servicePlan, onExit }) => {
  const [inProgress, setProgress] = useState(false)
  const [userEmailInput, setUserEmailInput] = useState('')
  const [result, setResult] = useState(null)
  const client = useApolloClient()

  const [clinicAttachToServicePlan, { loading: isAttaching }] = useMutation(
    CLINIC_ATTACH_TO_SERVICE_PLAN,
    {
      onCompleted: () => {
        alert('please refresh to see the results')
      }
    }
  )

  async function submit() {
    setProgress(true)
    setResult(null)
    try {
      const {
        data: {
          admin: { userByEmail }
        }
      } = await client.query({
        query: GET_ADMIN_DATA_FROM_USER_EMAIL,
        variables: {
          email: userEmailInput
        }
      })

      if (userByEmail) {
        await clinicAttachToServicePlan({
          variables: {
            clinic: {
              id: userByEmail.clinic.id,
              name: userByEmail.clinic.name
            },
            servicePlanId: servicePlan.id
          }
        })
        setResult('Done. please refresh to see the results')
      } else {
        setResult("Can't find the user")
      }
    } catch (e) {
      setResult('Make sure the email is valid')
    }
    setProgress(false)
  }

  return (
    <Modal
      onClose={onExit}
      onSubmit={submit}
      inProgress={inProgress || isAttaching}
    >
      <label>User email:</label>
      <div className='field'>
        <div className='field has-addons'>
          <div className='control is-expanded'>
            <input
              type='text'
              className='input'
              value={userEmailInput}
              onChange={(e) => setUserEmailInput(e.target.value)}
            />
          </div>
          <div className='control'>
            <button
              className={clsx('button is-primary', {
                'is-loading': inProgress || isAttaching
              })}
              onClick={async () => {
                submit()
              }}
            >
              Attach
            </button>
          </div>
        </div>
      </div>
      <label>{result}</label>
    </Modal>
  )
}

export default AttachToServicePlan

import React from 'react'
import Modal from '../Modal'
import AuthScenery from './AuthScenery'
import { useLocalization } from '../LocalizationProvider'
import pets from '../../legacy/img/pets.png'
import medicalBox from '../../legacy/img/medical-box.png'

export default function AccountType({ changeState }) {
  const { locale } = useLocalization()
  return (
    <AuthScenery>
      <Modal standalone={false}>
        <div className='field has-text-centered mb-6'>
          <label className='label is-size-2'>{locale.account_type_title}</label>
        </div>
        <div className='field is-flex is-justify-content-center'>
          <button
            className='button is-primary is-outlined is-medium p-6 mr-4 is-flex is-flex-direction-column'
            onClick={() => {
              window.AUTH_CONFIG.clientMetadata.userType = 'Physician'
              changeState('signUp')
            }}
          >
            <span className='icon mb-2'>
              <img src={medicalBox} />
            </span>
            {locale.account_type_physician}
          </button>
          <button
            className='button is-primary is-outlined is-medium p-6 is-flex is-flex-direction-column'
            onClick={() => {
              window.AUTH_CONFIG.clientMetadata.userType = 'Veterinarian'
              changeState('signUp')
            }}
          >
            <span className='icon mb-2'>
              <img src={pets} />
            </span>
            {locale.account_type_veterinarian}
          </button>
        </div>
        <div className='field has-text-centered mt-6'>
          <div className='level'>
            <div className='level-item'>
              <span>{locale.profile.have_an_account}</span>
              <button
                className='button is-primary is-inverted level-item'
                onClick={() => {
                  window.AUTH_CONFIG.clientMetadata.userType = undefined
                  changeState('signIn')
                }}
              >
                {locale.login.signin}
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </AuthScenery>
  )
}

import React, { useEffect, useContext } from 'react'
import { useQuery } from '@apollo/client'
import AuthContext from './AuthContext'
import Layout from './Layout'
import Loader from './Loader'
import { GET_SERVICE_PLAN_CHANGE_CC_PAGE } from './queries'
import { useLocalization } from './LocalizationProvider'

const RedirectToChangeCCPage = () => {
  const { locale } = useLocalization()
  const currentUser = useContext(AuthContext)

  const servicePlanBS = currentUser.clinic.servicePlans.find(
    (sp) => sp.provider === 'Bluesnap' && sp.active
  ) || { id: 'NOT_A_REAL_ID' }
  const { data, loading, error } = useQuery(GET_SERVICE_PLAN_CHANGE_CC_PAGE, {
    variables: {
      id: servicePlanBS.id,
      clinicId: currentUser.clinic.id
    }
  })

  useEffect(() => {
    if (!(data && data.servicePlan && data.servicePlan.changeCCPage)) return
    window.location = data.servicePlan.changeCCPage
  }, [data])

  return (
    <Layout>
      <div className='section'>
        <div className='container has-text-centered'>
          {loading ? (
            <Loader />
          ) : error ||
            !(data && data.servicePlan && data.servicePlan.changeCCPage) ? (
            <p>{locale.service_plan.errors.error}</p>
          ) : (
            ''
          )}
        </div>
      </div>
    </Layout>
  )
}

export default RedirectToChangeCCPage

import React, { useState } from 'react'
import clsx from 'clsx'
import AdminReferralsList from './AdminReferralsList'

const AdminReferrals = () => {
  const [tab, setTab] = useState(null)

  const REFERRAL_STATUS = {
    paymentPending: 'PaymentPending',
    pending: 'Pending',
    PendingInQueue: 'PendingInQueue',
    done: 'Done'
  }

  return (
    <div>
      <div>
        <div className='mt-5'>
          <div className='tabs is-boxed'>
            <ul>
              <li
                className={clsx({
                  'is-active': tab === REFERRAL_STATUS.pending || !tab
                })}
              >
                <a onClick={() => setTab(REFERRAL_STATUS.pending)}>Pending</a>
              </li>
              <li
                className={clsx({
                  'is-active': tab === REFERRAL_STATUS.PendingInQueue
                })}
              >
                <a onClick={() => setTab(REFERRAL_STATUS.PendingInQueue)}>
                  Pending in queue
                </a>
              </li>
              <li
                className={clsx({
                  'is-active': tab === REFERRAL_STATUS.paymentPending
                })}
              >
                <a onClick={() => setTab(REFERRAL_STATUS.paymentPending)}>
                  Payment pending
                </a>
              </li>
              <li
                className={clsx({
                  'is-active': tab === REFERRAL_STATUS.done
                })}
              >
                <a onClick={() => setTab(REFERRAL_STATUS.done)}>Done</a>
              </li>
            </ul>
          </div>
        </div>

        <div>
          <div className='box'>
            {(!tab || tab == REFERRAL_STATUS.pending) && (
              <AdminReferralsList
                status={REFERRAL_STATUS.pending}
              ></AdminReferralsList>
            )}
            {tab == REFERRAL_STATUS.PendingInQueue && (
              <AdminReferralsList
                status={REFERRAL_STATUS.PendingInQueue}
              ></AdminReferralsList>
            )}
            {tab == REFERRAL_STATUS.paymentPending && (
              <AdminReferralsList
                status={REFERRAL_STATUS.paymentPending}
              ></AdminReferralsList>
            )}
            {tab == REFERRAL_STATUS.done && (
              <AdminReferralsList
                status={REFERRAL_STATUS.done}
              ></AdminReferralsList>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdminReferrals

if (typeof window !== 'undefined') {

/*
    WebDataFetcher - handles fetching data from the API
*/
bcViewer.ArnikaDataFetcher = bcViewer.BaseDataFetcher.extend({
    construct: function(globals) {
        this._super.apply(this, arguments);

        // Handle requests for events
        this._eventsDataRequestAjax;
        this._eventsDataRequestCallbacks = [];

        this._metadata;

        this._dataHeader;
        this._dataTimeTable;
        this._signalProcessing = new SignalProcessing();

        this._QRS_DETECTION_DELAY = 0;
        this._eventsFile;
        this._eventsHeader;
        this._qrsTimeTable = [];

        this._qrsTypes = {
            A_VEC: 4,
            A_QVEC: 18,
            A_QSVEC: 19,
            PVC: 5,
            VESC: 10,
            A_SVEC: 3,
            APC: 8,
            SVPB: 9,
            A_ARTI: 16,
            A_QARTI: 20,
            PACE: 12,
        };

        this._latestECGRequestTime = {};
        this._readFileRangeMaxTries = 5;
        this._readFileTryNumber = 1;
    },

    _getStudyLink: function() {
            if (bcGlobals && bcGlobals.study && bcGlobals.study.link) return bcGlobals.study.link;

            return this._bcViewer._recordURL;
    },

    /*
        Return the current record metadata to callback
    */
    getMetadata: function(callback) {
        // if we got the metadata
        if (typeof this._metadata !== 'undefined') {
            callback && callback(this._metadata);
        }
        this._eventsFile = this._getStudyLink().replace('sig', 'wz3');
        this._readHeaders().then($.proxy(function() {
            this._metadata = {
                Duration: parseInt(this._dataHeader.numberOfSamples * 1000 / this._dataHeader.samplingRate),
                ElectrodePlacementId: this._dataHeader.electrodesPlacementId,
                NumberOfChannels: this._dataHeader.numberOfLeads,
                StartTime: (this._dataHeader.gmtUnixTime * 1000) + (this._dataHeader.timeZoneOffset * 60 * 1000),
                deviceNumber: this._dataHeader.deviceNumber,
                deviceClass: this._dataHeader.deviceClass
            };

            this._metadata.StartTime = this._utils.adjustTimeToTimezone(this._metadata.StartTime, 0);

            callback && callback(this._metadata);
        }, this));
    },

    _readHeaders: function() {
        return Promise.all([this._readDataHeader(),
                     this._readEventsHeader()]);
    },

    _dataHeaderStruct: {
        formatVersion: {
            type: 'string',
            size: 32,
        },
        timeIndexTableOffset: {
            type: 'int',
            size: 32,
        },
        timeIndexTableSize: {
            type: 'int',
            size: 32
        },
        ecgDataOffset: {
            type: 'int',
            size: 32
        },
        ecgDataSize: {
            type: 'int',
            size: 32
        },
        primaryEventsOffset: {
            type: 'int',
            size: 32
        },
        primaryEventsSize: {
            type: 'int',
            size: 32
        },
        userDefDataOffset: {
            type: 'int',
            size: 32
        },
        userDefDataSize: {
            type: 'int',
            size: 32
        },
        gmtUnixTime: {
            type: 'int',
            size: 64
        },
        timeZoneOffset: {
            type: 'int',
            size: 32
        },
        recordDuration: {
            type: 'int',
            size: 32
        },
        samplingRate: {
            type: 'int',
            size: 32
        },
        numberOfSamples: {
            type: 'int',
            size: 32
        },
        unitsPerMv: {
            type: 'int',
            size: 32
        },
        ecgDataBlockSize: {
            type: 'uint',
            size: 16
        },
        numberOfLeads: {
            type: 'uint',
            size: 16
        },
        leadsId: {
            type: 'string',
            size: 256
        },
        deviceClass: {
            type: 'string',
            size: 24
        },
        deviceNumber: {
            type: 'string',
            size: 24
        },
        recId: {
            type: 'string',
            size: 44
        },
        devId: {
            type: 'string',
            size: 44
        },
        ecgDataCrc: {
            type: 'uint',
            size: 16
        },
        timeIndexTableCrc: {
            type: 'uint',
            size: 16
        },
        primaryEventsCrc: {
            type: 'uint',
            size: 16
        },
        userDefDataCrc: {
            type: 'uint',
            size: 16
        },
        electrodesPlacementId: {
            type: 'int',
            size: 32
        },
        headerCrc: {
            type: 'uint',
            size: 16
        }
    },

    _DATA_HEADER_SIZE: 502,

    _blockHeaderStruct: {
        crc: {
            type: 'uint',
            size: 16,
        },
        blockSize: {
            type: 'int',
            size: 16,
        },
        blockNumber: {
            type: 'int',
            size: 32,
        },
        startSampleNum: {
            type: 'int',
            size: 32,
        },
        samplesInBlock: {
            type: 'int',
            size: 16,
        },
        numberOfChannels: {
            type: 'int',
            size: 8,
        },
        dataFormat: {
            type: 'int',
            size: 8,
        },
        leadsOffStatus: {
            type: 'int',
            size: 16,
        }
    },

    _BLOCK_HEADER_SIZE: 18,

    _eventsHeaderStruct: {
        formatVersion: {
            type: 'string',
            size: 32,
        },
        ecgSamplingRate: {
            type: 'int',
            size: 32,
        },
        samplesInEcgFile: {
            type: 'int',
            size: 32,
        },
        qrsTimeIndexOffset: {
            type: 'int',
            size: 32,
        },
        qrsTimeIndexSize: {
            type: 'int',
            size: 32,
        },
        eventsTimeIndexOffset: {
            type: 'int',
            size: 32,
        },
        eventsTimeIndexSize: {
            type: 'int',
            size: 32,
        },
        qrsDataOffset: {
            type: 'int',
            size: 32,
        },
        qrsDataSize: {
            type: 'int',
            size: 32,
        },
        qrsVersion: {
            type: 'int',
            size: 32,
        },
        eventsDataOffset: {
            type: 'int',
            size: 32,
        },
        eventsDataSize: {
            type: 'int',
            size: 32,
        },
        eventsVersion: {
            type: 'int',
            size: 32,
        },
        userDefDataOffset: {
            type: 'int',
            size: 32,
        },
        userDefDataSize: {
            type: 'int',
            size: 32,
        },
        userDataVersion: {
            type: 'int',
            size: 32,
        },
        qrsTimeIndexCrc: {
            type: 'uint',
            size: 16,
        },
        eventsTimeIndexCrc: {
            type: 'uint',
            size: 16,
        },
        qrsDataCrc: {
            type: 'uint',
            size: 16,
        },
        eventsDataCrc: {
            type: 'uint',
            size: 16,
        },
        userDefDataCrc: {
            type: 'uint',
            size: 16,
        },
        headerCrc: {
            type: 'uint',
            size: 16,
        }
    },

    _EVENTS_HEADER_SIZE: 104,

    _qrsStruct: {
        samplePosition: {
            type: 'int',
            size: 32,
        },
        rrValue: {
            type: 'int',
            size: 32,
        },
        pWaveDuration: {
            type: 'int',
            size: 32,
        },
        qrsDuration: {
            type: 'int',
            size: 32,
        },
        qDuration: {
            type: 'int',
            size: 32,
        },
        qtDuration: {
            type: 'int',
            size: 32,
        },
        prAxis: {
            type: 'int',
            size: 32,
        },
        tAxis: {
            type: 'int',
            size: 32,
        },
        piiAmplitude: {
            type: 'int',
            size: 32,
        },
        sv1Rv5Amplitude: {
            type: 'int',
            size: 32,
        },
        qPeakAmplitude: {
            type: 'int',
            size: 32,
        },
        tWavePositiveAmplitude: {
            type: 'int',
            size: 32,
        },
        tWaveNegativeAmplitude: {
            type: 'int',
            size: 32,
        },
        bitmaskType: {
            type: 'int',
            size: 32,
        },
        stPqLevel: {
            type: 'intArray',
            size: 32,
            length: 3
        },
        stJpointLevel: {
            type: 'intArray',
            size: 32,
            length: 3
        },
        stJPlus80Level: {
            type: 'intArray',
            size: 32,
            length: 3
        },
        rrNextValue: {
            type: 'int',
            size: 32,
        },
        tmpHr: {
            type: 'int',
            size: 32,
        },
        artifactBitmask: {
            type: 'int',
            size: 32,
        },
        primaryType: {
            type: 'int',
            size: 32,
        },
        empty7: {
            type: 'int',
            size: 32,
        },
        qrsNumber: {
            type: 'int',
            size: 32,
        },
        pqIntervalDuration: {
            type: 'int',
            size: 32,
        },
        templateType: {
            type: 'int',
            size: 32,
        },
        templateNumber: {
            type: 'int',
            size: 32,
        },
    },

    _QRS_DATA_SIZE: 128,

    _fillStructFromByteArray: function(byteArray, struct) {
        var dv = new DataView(byteArray);
        var result = {};
        var offset = 0;
        for (var field in struct) {
            var type = struct[field].type;
            var size = struct[field].size;
            var value = '';
            switch (type) {
                case 'int':
                    if (size == 8) {
                        value = dv.getInt8(offset, true);
                        offset += 1;
                    }if (size == 16) {
                        value = dv.getInt16(offset, true);
                        offset += 2;
                    } else if (size == 32) {
                        value = dv.getInt32(offset, true);
                        offset += 4;
                    } else if (size == 64) {
                        value = this._readInt64(dv, offset);
                        offset += 8;
                    }
                    break;
                case 'uint':
                    if (size == 16) {
                        value = dv.getUint16(offset, true);
                        offset += 2;
                    }
                    break;
                case 'string':
                    value = '';
                    for (var i=0;i<size;i++) {
                        value += String.fromCharCode(dv.getUint8(offset));
                        offset++;
                    }
                    break;
                case 'intArray':
                    value = [];
                    var length = struct[field].length;
                    for (var i=0;i<length;i++) {
                        if (size == 32) {
                            value.push(dv.getInt32(offset, true));
                            offset += 4;
                        }
                    }
                    break;
            }
            result[field] = value;
        }

        return result;
    },

    _readInt64: function(dv, offset) {
        var value = 0;
        for (var i = 7; i >= 0; i--) {
            value = (value << 8) | dv.getUint8(offset + i, true);
        }

        return value;
    },

    _readDataHeader: function() {
        return this._readFileRange(this._getStudyLink(), 0, this._DATA_HEADER_SIZE).then($.proxy(function(response) {
            this._dataHeader = this._fillStructFromByteArray(response, this._dataHeaderStruct);
            var checksumRes = this._verifyChecksum();

            if (!checksumRes) {
                console.log('bad checksum'); // notify in some way
            } else {
                return this._readTimeIndexTable(this._getStudyLink(), this._dataHeader.timeIndexTableOffset, this._dataHeader.timeIndexTableSize)
                    .then($.proxy(function (timeTable) {
                        this._dataTimeTable = timeTable;
                    }, this));
                }
        }, this));
    },

    _readTimeIndexTable: function(fileName, tableOffset, tableSize) {
        return this._readFileRange(fileName, tableOffset,(tableOffset + tableSize)).then($.proxy(function(response) {
            var timeTable = [];
            var dv = new DataView(response);
            var tableLength = tableSize / 4;
            for (var i = 0; i < tableLength; i++) {
                timeTable[i] = dv.getInt32(i*4, true);
            }
            return timeTable;
        }, this));
    },

    _readEventsHeader: function() {
        return this._readFileRange(this._eventsFile, 0, this._EVENTS_HEADER_SIZE).then($.proxy(function(response) {
            this._eventsHeader = this._fillStructFromByteArray(response, this._eventsHeaderStruct);

            // Fix Chrome bug - resonse 206 and not 403 code on cached 403 (after and farward in the browser)
            if (this._eventsHeader.formatVersion.startsWith('<?xml')) {
                delete this._eventsHeader;
                return Promise.resolve();
            }

            var checksumRes = this._verifyChecksum();

            if (!checksumRes) {
                console.log('bad checksum'); // notify in some way
            } else {
                return this._readTimeIndexTable(this._eventsFile, this._eventsHeader.qrsTimeIndexOffset, this._eventsHeader.qrsTimeIndexSize)
                    .then($.proxy(function (timeTable) {
                        this._qrsTimeTable = timeTable;
                    }, this));
            }
        }, this)).catch($.proxy(function() {
            return Promise.resolve();
        }, this));
    },

    _findBlockIndex: function(timeTable, sampleNumber, imin, imax) {
        var half = parseInt((imin + imax) / 2);

        if (half === 0) return 0;

        if ((half !== 0 && timeTable[half] === 0) || sampleNumber < timeTable[half]) {
            return this._findBlockIndex(timeTable, sampleNumber, imin, half - 1);
        } else if (sampleNumber > timeTable[half] && half < imax && sampleNumber > timeTable[half + 1]) {
            return this._findBlockIndex(timeTable, sampleNumber, half + 1, imax);
        } else {
            return parseInt(half);
        }
    },

    _convertSignalFormat: function(signal, qrs, requestedChannels, expand, isChannelSelected) {
        var outSignal = {
            leadNames: [],
            series: [],
            qrs: []
        };

        outSignal.qrs = qrs.slice(); // copy the array
        outSignal.shouldConvertQrsToPixels = true;

        // Fake lead names (for now)
        // set lead names in case metadata.electrodesPosition is undefined or the value is not one of the supported positions
        outSignal.leadNames = (expand) ? ['I', 'II' , 'III', 'aVR', 'aVL', 'aVF', 'Vx'] : ['I', 'II', 'Vx'];

        var isVetDevice = this._bcViewer.isVetDevice({
            deviceNumber: this._metadata.deviceNumber,
            deviceClass: this._metadata.deviceClass
        });

        var positions = this._bcViewer.electrodesPositions;
        for (var pos in positions) {
            var posItem = positions[pos];
            var electrodesPosition = this._metadata.electrodesPosition || this._metadata.ElectrodePlacementId;
            if (posItem.id === electrodesPosition) {
                outSignal.leadNames = (expand) ? posItem.expandedLeadNames.slice() : posItem.leadNames.slice();
                if (isVetDevice && expand) {
                    outSignal.leadNames = posItem.expandedLeadNamesVet.slice();
                }
                break;
            }
        }

       // If channel is selected, this is the context viewer and we want to show all the lead names
       if ((!isChannelSelected) && (typeof requestedChannels !== 'undefined')) {
           var requestedLeadNames = [];
           for (var i=0;i<outSignal.leadNames.length;i++) {
               if (requestedChannels.indexOf(i) !== -1) {
                   requestedLeadNames.push(outSignal.leadNames[i]);
               }
           }

           outSignal.leadNames = requestedLeadNames;
       }

        if (signal[0]) {
          // Prepare signal in old ShowSignalData format - this is crazy!
          for (var i=0; i<signal[0].length; i++) {
              var series = {};
              var channelNumber = requestedChannels ? requestedChannels[i] : i;
              series.color = 'Ch' + channelNumber;
              series.index = channelNumber;
              series.points = [];
              for (j=0; j<signal.length; j++) {
                  var point = [j, signal[j][i]];
                  series.points.push(point);
              }
              outSignal.series.push(series);
          }
        }

        return outSignal;
    },

    _getSignal: function(startSample, endSample, response) {
       var index = 0;
       var readAll = false;
       var foundStartBlock = false;
       var result = [];
       while (!readAll) {
            var currentBlockStartByte = index * this._dataHeader.ecgDataBlockSize;
            var currentBlockHeaderEndByte = currentBlockStartByte + this._BLOCK_HEADER_SIZE - 1;
            var blockHeader = this._fillStructFromByteArray(response.slice(currentBlockStartByte, currentBlockHeaderEndByte +1), this._blockHeaderStruct);
            var sampleStartIndex = 0;
            var sampleEndIndex = blockHeader.samplesInBlock * blockHeader.numberOfChannels;

            if (!foundStartBlock) {
                if (startSample >= blockHeader.startSampleNum  && startSample < blockHeader.startSampleNum + blockHeader.samplesInBlock) {
                    foundStartBlock = true;
                    sampleStartIndex = (startSample - blockHeader.startSampleNum) * blockHeader.numberOfChannels;
                }
            }

            if (endSample >= blockHeader.startSampleNum && endSample < blockHeader.startSampleNum + blockHeader.samplesInBlock) {
                sampleEndIndex = (endSample - blockHeader.startSampleNum) * blockHeader.numberOfChannels;
                readAll = true;
            }

            if (foundStartBlock) {
                var data = response.slice(currentBlockHeaderEndByte +1, ((currentBlockHeaderEndByte + 1) + (this._dataHeader.ecgDataBlockSize - this._BLOCK_HEADER_SIZE) + 1));
                var block = bcViewer.ECGUnpacking.unpackBlock(blockHeader, data);

                result = result.concat(Array.prototype.slice.call(block.slice(sampleStartIndex, sampleEndIndex)));
            }

            index++;
       }

       var signal = [];
       for (var i=0;i<result.length; i+= this._dataHeader.numberOfLeads) {
           var vector = [];
           for (var j=0; j<this._dataHeader.numberOfLeads; j++) {
               vector.push(result[i+j]);
           }
           signal.push(vector);
       }

       return signal;
    },

    _getQrs: function(startSample, endSample, response) {
       var index = 0;
       var readAll = false;
       var result = [];

       if (response.byteLength < this._QRS_DATA_SIZE) {
           return [];
       }

       while (!readAll) {
            var currentDataStartByte = index * this._QRS_DATA_SIZE;
            var currentDataEndByte = currentDataStartByte + this._QRS_DATA_SIZE - 1;
            var qrsData = this._fillStructFromByteArray(response.slice(currentDataStartByte, currentDataEndByte +1), this._qrsStruct);

            if (startSample <= qrsData.samplePosition) {
                result.push(qrsData);
            }

            if (qrsData.samplePosition > endSample) {
                readAll = true;
            }

            if (response.byteLength - currentDataEndByte < this._QRS_DATA_SIZE) {
                readAll = true;
            }

            index++;
       }

       return result;
    },

    /*
       deltaAdjustment: qrs delta is +1 for some reason :|
    */
    _readDataRange: function(link, headerParams, timeTable, params, deltaAdjustment) {
        var startSample = parseInt((params.startTime * headerParams.samplingRate) / 1000);
        var onScreenStartSample = startSample;

        var preSamples = 0;
        startSample -= preSamples;

        var step = params.rx / (1000 / headerParams.samplingRate);
        var endSample = parseInt(startSample + (step * params.cx) + this._signalProcessing.getPreMinimumSamples());
        endSample = Math.min(endSample, headerParams.numberOfSamples - 1);

        var blocks = parseInt(headerParams.dataSize / headerParams.blockSize);
        var delta = (blocks > timeTable.length) ? parseInt(blocks / timeTable.length) : 1;

        if (delta > 1) {
            delta += deltaAdjustment;
        }

        var startBlockIndex = this._findBlockIndex(timeTable, startSample, 0, timeTable.length) * delta;
        var endBlockIndex = this._findBlockIndex(timeTable, endSample, 0, timeTable.length);

        if (endBlockIndex === timeTable.length - 1) {
            endBlockIndex = blocks - 1;
        } else {
            endBlockIndex = (endBlockIndex * delta) + delta ;
            // patch for wrong datatables that doesnt start with 0
            if ((delta > 1) && (timeTable[0] !== 0)) {
                endBlockIndex += delta;
            }
        }

        // Add one delta in order to include the end block in the response.
        endBlockIndex += delta;

        var startByte = headerParams.dataOffset + (headerParams.blockSize * startBlockIndex);
        var endByte = headerParams.dataOffset + (headerParams.blockSize * endBlockIndex);

        return this._readFileRange(link, startByte, endByte).then(function(byteArray) {
            return {
                byteArray: byteArray,
                startSample: startSample,
                endSample: endSample,
                onScreenStartSample: onScreenStartSample
            };
        }).catch(function() {
            return {
                byteArray: [],
                startSample: 0,
                endSample: 0,
                onScreenStartSample: 0
            };
        });
    },

    _readData: function(request, params) {
        var promises = [];

        var ecgDataHeaderParams = {
            samplingRate: this._dataHeader.samplingRate,
            numberOfSamples: this._dataHeader.numberOfSamples,
            dataSize: this._dataHeader.ecgDataSize,
            blockSize: this._dataHeader.ecgDataBlockSize,
            dataOffset: this._dataHeader.ecgDataOffset
        };
        this._createSignalProcessor(params);
        var ecgDataPromise = this._readDataRange(this._getStudyLink(), ecgDataHeaderParams, this._dataTimeTable, params, 0);
        promises.push(ecgDataPromise);

        // context viewer doesnt need the QRSs
        if (request !== 'context' && typeof this._eventsHeader !== 'undefined' && this._eventsHeader.qrsDataSize !== 0) {
            var qrsDataHeaderParams = {
                samplingRate: this._eventsHeader.ecgSamplingRate,
                numberOfSamples: this._eventsHeader.samplesInEcgFile,
                dataSize: this._eventsHeader.qrsDataSize,
                blockSize: this._QRS_DATA_SIZE,
                dataOffset: this._eventsHeader.qrsDataOffset
            };
            var qrsDataPromise = this._readDataRange(this._eventsFile, qrsDataHeaderParams, this._qrsTimeTable, params, 1);

            promises.push(qrsDataPromise);
        }

        return Promise.all(promises)
            .then($.proxy(function(results) {
                var ecgDataRange = results[0];
                var signal = this._getSignal(ecgDataRange.startSample, ecgDataRange.endSample, ecgDataRange.byteArray);
                var processedSignal = this._processSignal(signal, params);

                var qrsOutput = [];

                var qrsDataRange = results[1];
                if (qrsDataRange) {
                    var qrs = this._getQrs(qrsDataRange.startSample, qrsDataRange.endSample, qrsDataRange.byteArray);
                    qrsOutput = this._convertQrsFormat(qrsDataRange.onScreenStartSample, params, qrs);
                }

                var isChannelSelected = (typeof params.selectedChannel !== 'undefined');
                var expand = (params.expand3To7 || params.expand3To12 || params.expand8To12 || params.expand2To6);
                var out = this._convertSignalFormat(processedSignal, qrsOutput, params.requestedChannels, expand, isChannelSelected);

                return out;
            }, this));
    },

    _convertQrsFormat: function(startSample, params, qrsInput) {
        var output = [];

        for (var i=0;i<qrsInput.length;i++) {
            var color = "";
            switch (qrsInput[i].templateType) {
                case this._qrsTypes.A_VEC:
                case this._qrsTypes.A_QVEC:
                case this._qrsTypes.PVC:
                case this._qrsTypes.VESC:
                    color = "QRS_V";
                    break;
                case this._qrsTypes.A_SVEC:
                case this._qrsTypes.A_QSVEC:
                case this._qrsTypes.APC:
                case this._qrsTypes.SVPB:
                    color = "QRS_S";
                    break;
                case this._qrsTypes.A_ARTI:
                case this._qrsTypes.A_QARTI:
                case this._qrsTypes.PACE:
                    color = "QRS_A";
                    break;
                default:
                    color = "QRS_N";
            }

            var points = [];
            var samplePosition = qrsInput[i].samplePosition - this._QRS_DETECTION_DELAY - (this._dataHeader.samplingRate / 2);

            var x = samplePosition * 1000 / this._dataHeader.samplingRate;
            var caption = (qrsInput[i].qrsNumber !== 0) ? qrsInput[i].rrValue + ' ' : '';
            output.push({
                color: color,
                x: x,
                annotations: [{
                    annotationType: 'qrs',
                    caption: caption,
                    color: color,
                    name: ''
                }]
            });
        }

        return output;
    },

    _createSignalProcessor: function (params) {
       this._signalProcessing = new SignalProcessing();

       // Dummy - just for setting the sampling rate - we need this for the minimum pre samples number
       this._signalProcessing.setInputSignal([[0]], {
            numberOfChannels: this._dataHeader.numberOfLeads,
            numberOfSamples: 0,
            samplingRate: this._dataHeader.samplingRate,
            ry: this._dataHeader.unitsPerMv
        });

       this._signalProcessing.configProcessor('SmoothFilter', {enabled: params.sm});
       this._signalProcessing.configProcessor('BaselineFilter', {enabled: params.bl});
       this._signalProcessing.configProcessor('Expand3To7', {enabled: params.expand3To7});
       this._signalProcessing.configProcessor('Expand2To6', {enabled: params.expand2To6});
       this._signalProcessing.configProcessor('Expand3To12', {enabled: params.expand3To12});
       this._signalProcessing.configProcessor('Expand8To12', {enabled: params.expand8To12});
    },

    _processSignal: function(signal, params) {
        this._signalProcessing.setInputSignal(signal, {
            numberOfChannels: this._dataHeader.numberOfLeads,
            numberOfSamples: signal.length,
            samplingRate: this._dataHeader.samplingRate,
            ry: this._dataHeader.unitsPerMv
        });

        var isChannelSelected = (typeof params.selectedChannel !== 'undefined');

        if (isChannelSelected) {
            params.requestedChannels = [params.selectedChannel];
        }

        var fixedParams = $.extend({}, params);
        fixedParams.startTime = 0;
        var outSignal = this._signalProcessing.getOutputSignal(fixedParams, false);

        return outSignal;
    },

    _readFileRange: function(file, start, end, callback) {
        var readTry = 1;
        return new Promise($.proxy(function(resolve, reject) {
            this._fetch({
                url: file,
                headers: {range: 'bytes=' + start + '-' + end},
                dataType: 'binary',
                responseType: 'arraybuffer',
                type: 'GET',
                context: this,
                success: function(result) {
                    resolve(result);
                },
                error: $.proxy(function(e) {
                    this._readFileTryNumber++;
                    if ((e.status === 403) || (this._readFileTryNumber > this._readFileRangeMaxTries)) {
                        console.log('sorry cant load the file');
                        this._readFileTryNumber = 1;
                        reject();
                    } else {
                        console.log('Try again... try: ' + this._readFileTryNumber);
                        resolve( this._readFileRange(file, start, end, callback));
                    }
                }, this)
            });
        }, this));
    },

    _verifyChecksum: function() {
        // Add later... only calculate the header crc not blocks
        return true;
    },

    /*
        Return the current record HR data to callback
    */
    getHRData: function(width, height, callback) {
        // resultData.events[0] - the specific HR data
        this._eventsDataRequestCallbacks.push(function(resultData) {
            // Protect from empty HR graph
            var HRData = resultData.events[0];
            var noHRData = true;

            var i=0;
            while ((noHRData) && (HRData.graph) && (i < HRData.graph.length)) {
                if (HRData.graph[i].series.length > 0) {
                    noHRData = false;
                }
                i++;
            }

            var response = (!noHRData) ? HRData : {};
            callback(response);
        });

        this._getEventsData(this._bcViewer.getRecordHash(), width, height);
    },

    /*
        Return all the current record events data to callback
    */
    getEventsData: function(width, height, callback) {
        var _callback = function(resultData) {
            // Changing the structre to be more efficient
            // TODO: do this change on Sasha's side
            var events = resultData.events.slice(1); // Getting rid of resultData.events[0] since it's HR data
            var _events = {};
            for (var i=0; i<events.length; i++) {
                var currentEvent = events[i];

                // if there are no events for the current events
                if (!currentEvent.lines || (currentEvent.lines.length === 0)) continue;

                // I'm currently copying the entire event to save some processing time
                // we need to remove id and maybe other fields as well (tooltip, time, etc)
                _events[currentEvent.id] = currentEvent;
            }
            callback(_events);
        };
        this._eventsDataRequestCallbacks.push(_callback);
        this._getEventsData(this._bcViewer.getRecordHash(), width, height);
    },

    /*
        Execute a request to the get_events API to get all the events in the current record
        and call all the callbacks in this._eventsDataRequestCallbacks
    */
    _getEventsData: function(recordHash, width, height) {
        // We alread have a live request for events data, no need to execute another one
        if (typeof this._eventsDataRequestAjax !== 'undefined') return;

        this._eventsDataRequestAjax = this._fetch({
            url: process.env.BinScriptRunnerLambdaFunctionUrl + 'bin-script-runner/get_events',
            data: {'record_hash': recordHash, 'record_type': this._recordType, 'cx': width, 'cy': height},
            cache: false,
            type: 'GET',
            context: this,
            success: function(resultData) {
                // TODO: handle errors

                delete this._eventsDataRequestAjax;

                var callback;
                while (callback = this._eventsDataRequestCallbacks.pop()) {
                    callback && callback(resultData);
                }
            }
        });
    },

    /*
        Get the events NP info from the API
    */
    getEventsNPInfo: function(recordHash, timePosition, callback) {
        this._fetch({
            url: process.env.BinScriptRunnerLambdaFunctionUrl + 'bin-script-runner/getevent_np_info',
            data: {'record_hash': recordHash, 'startTime': timePosition, 'get_bookmarks': 0},
            cache: true,
            type: 'GET',
            context: this,
            success: function(events) {
                // TODO: handle errors

                // Making an hash out of this data - we should change getevent_np_info to return hash
                var _events = {};
                for (var i=0; i<events.length; i++) {
                    var currentEvent = events[i];
                    _events[currentEvent.id] = currentEvent.all;
                }

                callback && callback(_events);
            }
        });
    },

    /*
        Request new ecg data based on the dataParams
        The data itself won't be requested until we call _getECGData
    */
    requestECGData: function(dataParams, name, callback) {
        var reqTime = Date.now();
        var ecgDataRequests = [];
        ecgDataRequests[name] = {
            'params': dataParams,
            'callback': callback,
            'time': reqTime
        };

        this._latestECGRequestTime[name] = reqTime;

        this._executeECGDataRequest(ecgDataRequests);
    },

    /*
        Get ECG data from the server based on this._ecgDataRequests
    */

    _executeECGDataRequest:function(ecgDataRequests) {
        var requestsPromises = [];
        for (var request in ecgDataRequests) {
            requestsPromises.push(this._readData(request, ecgDataRequests[request].params));
        }
        Promise.all(requestsPromises).then($.proxy(function(results) {
            var i=0;
            for (var request in ecgDataRequests) {
                if (ecgDataRequests[request].time === this._latestECGRequestTime[request]) {
                    ecgDataRequests[request].callback(results[i]);
                }
                i++;
            }
        }, this));
    }
});

}

import React from 'react'
import { GET_PENDING_TRANSACTIONS_BY_TYPE } from '../queries'
import { gql, useQuery, useMutation } from '@apollo/client'
import useMemoryPagination, {
  prev,
  next
} from '../../hooks/useMemoryPagination'
import dayjs from 'dayjs'

const FIRST = 20

function getServicePlanContractName(transactionData) {
  if (transactionData.productName === 'Service Plan') {
    return transactionData.contractName
  }

  for (let i = 0; i < parseInt(transactionData.promoteContractsNum); i++) {
    if (transactionData[`promoteProductName${i}`] === 'Service Plan') {
      return transactionData[`promoteContractName${i}`]
    }
  }

  return ''
}

const AdminPendingServicePlans = ({ clinic }) => {
  const [paginationState, dispatch] = useMemoryPagination()

  const { loading, error, data } = useQuery(GET_PENDING_TRANSACTIONS_BY_TYPE, {
    variables: {
      type: 'TransactionServicePlan',
      first: FIRST,
      after: paginationState
    }
  })

  let transactions = data
    ? data.admin.pendingTransactionsByType.edges.map(
        ({ node: transaction }) => {
          let _transaction = JSON.parse(JSON.stringify(transaction))
          _transaction.data = JSON.parse(_transaction.data)
          return _transaction
        }
      )
    : null

  const ADMIN_TRANSACTION_ATTACH_TO_CLINC = gql`
    mutation ADMIN_TRANSACTION_ATTACH_TO_CLINC(
      $clinic: ClinicConnectionInput!
      $transactionId: ID!
    ) {
      transactionAttachToClinic(
        clinic: $clinic
        transactionId: $transactionId
      ) {
        id
      }
    }
  `

  const [transactionAttachToClinic, { loading: isAttaching }] = useMutation(
    ADMIN_TRANSACTION_ATTACH_TO_CLINC,
    {
      onCompleted: () => {
        alert('please refresh to see the results')
      }
    }
  )

  return (
    <div>
      {(loading || isAttaching) && <p>Loading...</p>}
      {error && <div> error... </div>}
      {!isAttaching && data && (
        <div>
          <table className='table is-fullwidth is-hoverable'>
            <thead>
              <tr>
                <th>Created At</th>
                <th>Email</th>
                <th>First name</th>
                <th>Last name</th>
                <th>Contract name</th>
                {clinic && <th>Attach</th>}
              </tr>
            </thead>
            <tbody>
              {transactions.map((transaction) => (
                <tr key={transaction.id}>
                  <td>{dayjs(transaction.createdAt).format('YYYY-MM-DD')}</td>
                  <td>{transaction.data.email}</td>
                  <td>{transaction.data.firstName}</td>
                  <td>{transaction.data.lastName}</td>
                  <td>{getServicePlanContractName(transaction.data)}</td>
                  {clinic && (
                    <td>
                      <button
                        className='button'
                        onClick={async () => {
                          await transactionAttachToClinic({
                            variables: {
                              clinic: {
                                id: clinic.id,
                                name: clinic.name
                              },
                              transactionId: transaction.id
                            }
                          })
                        }}
                      >
                        Attach
                      </button>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
          <nav className='pagination' role='navigation' aria-label='pagination'>
            <a
              className='pagination-previous'
              disabled={
                !data.admin.pendingTransactionsByType.pageInfo.hasPreviousPage
              }
              onClick={() => {
                if (
                  data.admin.pendingTransactionsByType.pageInfo.hasPreviousPage
                ) {
                  dispatch(prev())
                }
              }}
            >
              Previous
            </a>
            <a
              className='pagination-next'
              disabled={
                !data.admin.pendingTransactionsByType.pageInfo.hasNextPage
              }
              onClick={() => {
                if (data.admin.pendingTransactionsByType.pageInfo.hasNextPage) {
                  dispatch(
                    next(
                      data.admin.pendingTransactionsByType.pageInfo.endCursor
                    )
                  )
                }
              }}
            >
              Next
            </a>
          </nav>
        </div>
      )}
    </div>
  )
}

export default AdminPendingServicePlans

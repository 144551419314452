import React, { useState } from 'react'
import { GET_DEVICE } from '../queries'
import { useLazyQuery } from '@apollo/client'
import dayjs from 'dayjs'

const AdminDevices = () => {
  const [deviceInput, setDeviceInput] = useState('')

  const [getDevices, { loading, error, data }] = useLazyQuery(GET_DEVICE)
  let deviceAttachedList = data
    ? [...data.device].sort(
        (a, b) =>
          new Date(b.attachedAt).getTime() - new Date(a.attachedAt).getTime()
      )
    : []

  return (
    <div className='mt-5'>
      <div className='columns'>
        <div className='column is-two-fifths'>
          <div className='field has-addons'>
            <div className='control is-expanded'>
              <input
                type='text'
                className='input'
                value={deviceInput}
                onChange={(e) => setDeviceInput(e.target.value)}
                onKeyDown={async (e) => {
                  if (e.keyCode === 13) {
                    await getDevices({
                      variables: {
                        deviceId: deviceInput
                      }
                    })
                  }
                }}
              />
            </div>
            <div className='control'>
              <button
                className='button is-primary'
                onClick={async () => {
                  await getDevices({
                    variables: {
                      deviceId: deviceInput
                    }
                  })
                }}
              >
                Search
              </button>
            </div>
          </div>
        </div>
      </div>

      {loading && <p>Loading...</p>}
      {error && <div> error... </div>}

      {data && data.device.length === 0 && <span>Device not found</span>}

      {deviceAttachedList.length > 0 && (
        <div>
          <div className='mt-5'>
            <span className='has-text-weight-bold'>Device class: </span>
            <span>{deviceAttachedList[0].deviceClass}</span>
            <span className='ml-3 mr-3'>|</span>
            <span className='has-text-weight-bold'>Device number: </span>
            <span>{deviceAttachedList[0].deviceNumber}</span>
          </div>

          <table className='table mt-5'>
            <thead>
              <tr>
                <th>Attached at</th>
                <th>User</th>
                <th>Number of studies</th>
              </tr>
            </thead>
            <tbody>
              {deviceAttachedList.map((deviceAttach) => (
                <tr key={deviceAttach.clinic.id}>
                  <td>
                    {dayjs(deviceAttach.attachedAt).format(
                      'YYYY-MM-DD HH:mm:ss'
                    )}
                  </td>
                  <td>{`${deviceAttach.clinic.owner.settings.displayName} (${deviceAttach.clinic.owner.email})`}</td>
                  <td>{deviceAttach.numOfStudies}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  )
}

export default AdminDevices

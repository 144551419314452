if (typeof window !== 'undefined') {

/*
    NHANESIIIPredictor - calculate predicted values
    https://www.cdc.gov/niosh/topics/spirometry/nhanes.html
*/
var NHANESIIIPredictor = {
    _boundValues: {
        male: {
            age: {
                min: 16,
                max: 80
            },
            height: {
                min: 147,
                max: 207
            }
        },
        female: {
            age: {
                min: 18,
                max: 80
            },
            height: {
                min: 145,
                max: 189
            }
        }
    },
    
    /*
        Calculate all the predicted values
    */
    getPredictedValues: function(patientDetails) {
         var predicted = {
            FVC: 0,
            FEV1: 0,
            PEF: 0     
        };
        
        if (this.canCalculatePrediction(patientDetails)) {
            var genderKey = SpirometerPrediction.getGenderKey(patientDetails.gender);
            var minAge = this._boundValues[genderKey].age.min;
            var minHeight = this._boundValues[genderKey].height.min;
            
            predicted.FVC = NHANESIIIPredData.FVC[genderKey][patientDetails.height-minHeight][patientDetails.age-minAge];
            predicted.FEV1 = NHANESIIIPredData.FEV1[genderKey][patientDetails.height-minHeight][patientDetails.age-minAge];
            predicted.PEF = NHANESIIIPredData.PEF[genderKey][patientDetails.height-minHeight][patientDetails.age-minAge];
        }
        
        return predicted;
    },
    
    /*
        Indicates if the predicted values can be calculated
    */
    canCalculatePrediction: function(patientDetails) {
        if (!(patientDetails.gender ||
              patientDetails.age ||
              patientDetails.height)) {
            return false;
        }
        
        if (patientDetails.gender === spiroViewer._gender.UNSPECIFIED) return false;
        
        var genderKey = SpirometerPrediction.getGenderKey(patientDetails.gender);
        return ((patientDetails.height >= this._boundValues[genderKey].height.min) &&
                (patientDetails.height <= this._boundValues[genderKey].height.max) &&
                (patientDetails.age >= this._boundValues[genderKey].age.min) &&
                (patientDetails.age <= this._boundValues[genderKey].age.max));
    }
};

window.NHANESIIIPredictor = NHANESIIIPredictor;

}
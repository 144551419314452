if (typeof window !== 'undefined') {

/*
    bcSpiroViwerApi - Allow the viewer to send\get events from the app\web page
*/
var bcSpiroViewerApi = function(bcSpiroViewer) {
    this._bcSpiroViewer = bcSpiroViewer;
    this._apiReady = false;
    
    this._spirometerLogType = 32;
    
    this._init();
};

/*
    init the api
*/
bcSpiroViewerApi.prototype._init = function() {
    this._apiReady = true;

    var e = {
        type: 'spiroViewerApiReady',
        api: this
    };
    
    $(document).trigger(e);
    if (window.frameElement) {
        window.parent.$(window.parent.document).trigger(e);
    }
};

/*
    trigger api events only when api is ready
*/ 
bcSpiroViewerApi.prototype._triggerWhenReady = function(eventType, data) {
    if (this._apiReady) {
        var e = {};
        e.type = eventType;
        $.extend(e, data);

        $(document).trigger(e);
        if (window.frameElement) {
            window.parent.$(window.parent.document).trigger(e);
        }
    } else {
        $(document).on("spiroViewerApiReady", $.proxy(function() {
            this._triggerWhenReady(eventType, data);
        }, this));
    }
};

/*
    Handle window unload
*/
bcSpiroViewerApi.prototype.onWindowUnload = function() {
    this._bcSpiroViewer.onWindowUnload();
};

/*
    Notify on timeout
*/
bcSpiroViewerApi.prototype.onTimeout = function() {
    this._triggerWhenReady('onSpirometerTimeout');
};

/*
    Notify on finished
*/
bcSpiroViewerApi.prototype.onFinished = function(saveSuccess, recId) {
    this._triggerWhenReady('onSpirometerFinished', {
        saveSuccess: saveSuccess,
        recId: recId
    });
};

/*
    Write to the app log
*/
bcSpiroViewerApi.prototype.writeToLogFile = function(str) {
    this._triggerWhenReady('writeToLog', {
        str: str,
        logType: this._spirometerLogType
    });
};

/*
    Return the tests count
*/
bcSpiroViewerApi.prototype.getTestsCount = function(str) {
    return this._bcSpiroViewer._resultsManager.getTestsCount();
};


/*
    Cancel the current test
*/
bcSpiroViewerApi.prototype.cancelTest = function(str) {
    return this._bcSpiroViewer.cancelTest();
};


/*
    trigger onStartMonitoringError
*/
bcSpiroViewerApi.prototype.onStartMonitoringError = function() {
    this._triggerWhenReady('onStartMonitoringError', {});
};

/*
    trigger onSpirometerSave
*/
bcSpiroViewerApi.prototype.onSave = function() {
    this._triggerWhenReady('onSpirometerSave', {});
};

/*
    trigger onSpirometerReadyToPrint
*/
bcSpiroViewerApi.prototype.onReadyToPrint = function() {
    this._triggerWhenReady('onSpirometerReadyToPrint', {});
};

window.bcSpiroViewerApi = bcSpiroViewerApi;

}
if (typeof window !== 'undefined') {

bcViewer.eventsInfo = {};

bcViewer.eventsInfo.rest = {
    2: {
        ANNOTATION: 'P',
        PRIORITY: 6,
        COLOR: 'EVENT_C',
        EXTRA_INFO: ['timestamp']
    },
    3: {
        ANNOTATION: 'B',
        PRIORITY: 6,
        COLOR: 'EVENT_C', 
        EXTRA_INFO: ['timestamp']
    },
    4: {
        ANNOTATION: 'Q',
        PRIORITY: 6,
        COLOR: 'EVENT_G', 
        EXTRA_INFO: [] 
    },
    5: {
        ANNOTATION: 'A',
        PRIORITY: 6,
        COLOR: 'EVENT_G', 
        EXTRA_INFO: ['duration'], 
        GROUP: 4 
    },
    6: {
        ANNOTATION: 'N1',
        PRIORITY: 6,
        COLOR: 'EVENT_G', 
        EXTRA_INFO: [],
        GROUP: 4 
    },
    7: {
        ANNOTATION: 'N2',
        PRIORITY: 6,
        COLOR: 'EVENT_G', 
        EXTRA_INFO: [], 
        GROUP: 4 
    },
    8: {
        ANNOTATION: 'N3',
        PRIORITY: 6,
        COLOR: 'EVENT_G', 
        EXTRA_INFO: [], 
        GROUP: 4 
    },
    9: {
        ANNOTATION: '.',
        PRIORITY: 5,
        COLOR: 'EVENT_G',
        EXTRA_INFO: ['duration'] 
    },
    10: {
        ANNOTATION: 'T',
        PRIORITY: 5,
        SECONDARY_PRIORITY: 1,
        COLOR: 'EVENT_R',
        EXTRA_INFO: ['duration', 'hr'], 
        GROUP: 9 
    },
    11: {
        ANNOTATION: 'B',
        PRIORITY: 5,
        SECONDARY_PRIORITY: 3,
        COLOR: 'EVENT_B',
        EXTRA_INFO: ['duration', 'hr'],
        GROUP: 9 
    },
    12: {
        ANNOTATION: 'R',
        PRIORITY: 5,
        SECONDARY_PRIORITY: 2,
        COLOR: 'EVENT_O', 
        EXTRA_INFO: ['duration', 'hr'],
        GROUP: 9 
    },
    14: {
        ANNOTATION: '.',
        PRIORITY: 3,
        COLOR: 'EVENT_GR', 
        EXTRA_INFO: []
    },
    15: {
        ANNOTATION: 'P1',
        PRIORITY: 3,
        SECONDARY_PRIORITY: 2,
        COLOR: 'EVENT_GR',
        EXTRA_INFO: [],
        GROUP: 14 
    },
    16: {
        ANNOTATION: 'P2',
        PRIORITY: 3,
        SECONDARY_PRIORITY: 1,
        COLOR: 'EVENT_GR', 
        EXTRA_INFO: [],
        GROUP: 14 
    },
    17: {
        ANNOTATION: 'PRR',
        PRIORITY: 3,
        SECONDARY_PRIORITY: 3,
        COLOR: 'EVENT_GR',
        EXTRA_INFO: [], 
        GROUP: 14 
    },
    19: {
        ANNOTATION: '.',
        PRIORITY: 2,
        COLOR: 'EVENT_G',
        EXTRA_INFO: [] 
    },
    20: {
        ANNOTATION: 'S1',
        PRIORITY: 2,
        SECONDARY_PRIORITY: 2,
        COLOR: 'EVENT_B', 
        EXTRA_INFO: [],
        GROUP: 19 
    },
    21: {
        ANNOTATION: 'SBi',
        PRIORITY: 2,
        SECONDARY_PRIORITY: 5,
        COLOR: 'EVENT_B',
        EXTRA_INFO: ['duration'],
        GROUP: 19 
    },
    22: {
        ANNOTATION: 'STr',
        PRIORITY: 2,
        SECONDARY_PRIORITY: 6,
        COLOR: 'EVENT_B',
        EXTRA_INFO: ['duration'],
        GROUP: 19 
    },
    23: {
        ANNOTATION: 'S2',
        PRIORITY: 2,
        SECONDARY_PRIORITY: 4,
        COLOR: 'EVENT_B',
        EXTRA_INFO: [],
        GROUP: 19 
    },
    24: {
        ANNOTATION: 'S3',
        PRIORITY: 2,
        SECONDARY_PRIORITY: 3,
        COLOR: 'EVENT_B',
        EXTRA_INFO: [],
        GROUP: 19 
    },
    25: {
        ANNOTATION: 'S>3',
        PRIORITY: 2,
        SECONDARY_PRIORITY: 1,
        COLOR: 'EVENT_B',
        EXTRA_INFO: ['hr'],
        GROUP: 19 
    },
    27: {
        ANNOTATION: '.',
        PRIORITY: 1,
        COLOR: 'EVENT_G',
        EXTRA_INFO: []
    },
    28: {
        ANNOTATION: 'V1',
        PRIORITY: 1,
        SECONDARY_PRIORITY: 2,
        COLOR: 'EVENT_R',
        EXTRA_INFO: [],
        GROUP: 27 
    },
    29: {
        ANNOTATION: 'VBi',
        PRIORITY: 1,
        SECONDARY_PRIORITY: 5,
        COLOR: 'EVENT_R',
        EXTRA_INFO: ['duration'],
        GROUP: 27 
    },
    30: {
        ANNOTATION: 'VTr',
        PRIORITY: 1,
        SECONDARY_PRIORITY: 6,
        COLOR: 'EVENT_R',
        EXTRA_INFO: ['duration'],
        GROUP: 27 
    },
    31: {
        ANNOTATION: 'V2',
        PRIORITY: 1,
        SECONDARY_PRIORITY: 4,
        COLOR: 'EVENT_R',
        EXTRA_INFO: [],
        GROUP: 27 
    },
    32: {
        ANNOTATION: 'V3',
        PRIORITY: 1,
        SECONDARY_PRIORITY: 3,
        COLOR: 'EVENT_R',
        EXTRA_INFO: [],
        GROUP: 27 
    },
    33: {
        ANNOTATION: 'V>3',
        PRIORITY: 1,
        SECONDARY_PRIORITY: 1,
        COLOR: 'EVENT_R',
        EXTRA_INFO: ['hr'],
        GROUP: 27 
    },
    34: {
        ANNOTATION: 'RonT',
        PRIORITY: 1,
        SECONDARY_PRIORITY: 7,
        COLOR: 'EVENT_R',
        EXTRA_INFO: [],
        GROUP: 27 
    },
    35: {
        ANNOTATION: '.',
        PRIORITY: 4,
        COLOR: 'EVENT_G',
        EXTRA_INFO: ['duration'] 
    },
    36: {
        ANNOTATION: 'ST1',
        PRIORITY: 4,
        SECONDARY_PRIORITY: 1,
        COLOR: 'EVENT_GR', 
        EXTRA_INFO: ['duration', 'hr'],
        GROUP: 35 
    },
    37: {
        ANNOTATION: 'ST2',
        PRIORITY: 4,
        SECONDARY_PRIORITY: 2,
        COLOR: 'EVENT_GR',
        EXTRA_INFO: ['duration', 'hr'],
        GROUP: 35 
    },
    38: {
        ANNOTATION: 'ST3',
        PRIORITY: 4,
        SECONDARY_PRIORITY: 3,
        COLOR: 'EVENT_GR',
        EXTRA_INFO: ['duration', 'hr'],
        GROUP: 35 
    },
    39: {
        ANNOTATION: 'Mis',
        PRIORITY: 1,
        SECONDARY_PRIORITY: 1,
        COLOR: 'EVENT_R',
        EXTRA_INFO: [],
        GROUP: 27 
    },
    40: {
        ANNOTATION: 'Ab',
        PRIORITY: 6,
        COLOR: 'EVENT_G', 
        EXTRA_INFO: [] 
    },
    41: {
        ANNOTATION: 'F',
        PRIORITY: 6,
        COLOR: 'EVENT_G', 
        EXTRA_INFO: [] 
    },
    42: {
        ANNOTATION: 'P',
        PRIORITY: 6,
        COLOR: 'EVENT_G', 
        EXTRA_INFO: [] 
    },
    43: {
        ANNOTATION: 'Act',
        PRIORITY: 6,
        COLOR: 'EVENT_G', 
        EXTRA_INFO: [] 
    }
};

}
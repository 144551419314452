import React, { useState } from 'react'
import { useQuery } from '@apollo/client'
import { GET_REFERRALS_CONFIG, GET_REFERRALS_CONFIGS } from '../queries'
import { useLocalization } from '../LocalizationProvider'
import Loader from '../Loader'
import { REFERRAL_TYPES_CONVERTER } from '../../../utils/utils'
import AddReferralConfig from './AddReferralConfig'
import UpdateReferralConfig from './UpdateReferralConfig'
import RemoveFromReferralConfig from './RemoveFromReferralConfig'

const GraphqlSchemaEnums = JSON.parse(process.env.GraphqlSchemaEnums)

const AdminReferralsConfig = () => {
  const { locale } = useLocalization()
  const [group, setGroup] = useState(-1)
  const [isAddConfigMode, setAddConfigMode] = useState(false)
  const [configToUpdate, setConfigToUpdate] = useState(null)
  const [configToRemove, setConfigToRemove] = useState(null)

  const {
    loading: configsLoading,
    error: configsError,
    data: configsData
  } = useQuery(GET_REFERRALS_CONFIGS, {})

  const {
    loading: configLoading,
    error: configError,
    data: configData
  } = useQuery(GET_REFERRALS_CONFIG, {
    variables: { referralsConfigId: group }
  })

  const protocolsInUse = ['Rest', 'Holter']
  return (
    <div className='mt-5'>
      {(configError || configsError) && <div> error... </div>}

      {isAddConfigMode && (
        <AddReferralConfig
          referralConfigsData={configsData}
          onComplete={() => setAddConfigMode(false)}
          onExit={() => setAddConfigMode(false)}
        />
      )}
      {configToUpdate && (
        <UpdateReferralConfig
          group={group}
          configToEdit={configToUpdate}
          onComplete={() => setConfigToUpdate(null)}
          onExit={() => setConfigToUpdate(null)}
        />
      )}
      {configToRemove && (
        <RemoveFromReferralConfig
          group={group}
          configToRemove={configToRemove}
          onComplete={() => setConfigToRemove(null)}
          onExit={() => setConfigToRemove(null)}
        />
      )}

      {configsLoading ? (
        <Loader />
      ) : (
        <div className='level'>
          <div className='level-left'>
            <div className='level-item'>
              <div className='select'>
                <select
                  value={group}
                  onChange={(e) => setGroup(e.target.value)}
                >
                  <option disabled='disabled' value='-1'>
                    Select group
                  </option>
                  {configsData.referralsConfigs.map(({ id: groupId }) => (
                    <option value={groupId} key={groupId}>
                      {groupId}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className='level-right'>
            <div className='level-item'>
              <a
                className='button is-primary'
                href='#'
                onClick={() => {
                  setAddConfigMode(true)
                }}
              >
                Add
              </a>
            </div>
          </div>
        </div>
      )}

      {group !== -1 &&
        (configLoading ? (
          <Loader />
        ) : (
          GraphqlSchemaEnums.StudyProtocol.filter((protocol) =>
            protocolsInUse.includes(protocol)
          ).map((protocol) => {
            return (
              <div key={protocol} className='mt-5'>
                <article className='panel'>
                  <p className='panel-heading'>{protocol}</p>

                  <div className='panel-block'>
                    <table className='table is-fullwidth'>
                      <thead>
                        <tr>
                          <th>Caregiver</th>
                          <th>Queue</th>
                          <th>Type</th>
                          <th>Price</th>
                          <th>Currency</th>
                          <th>Unavailable until</th>
                          <th>Edit</th>
                          <th>Remove</th>
                        </tr>
                      </thead>
                      <tbody>
                        {configData.referralsConfig
                          .filter((config) => config.studyProtocol === protocol)
                          .map((config) => (
                            <tr key={config.id + group}>
                              <td>{`${config.caregiver.name}${
                                !config.isQueue
                                  ? ` (${config.caregiver.email})`
                                  : ''
                              }`}</td>
                              <td>{config.isQueue ? 'Yes' : 'No'}</td>
                              <td>
                                {
                                  locale.studies.reports[
                                    REFERRAL_TYPES_CONVERTER[
                                      config.referralType
                                    ]
                                  ]
                                }
                              </td>
                              <td>{config.price}</td>
                              <td>{config.currency}</td>
                              <td>{config.unavailableUntil}</td>
                              <td>
                                <a
                                  className='button'
                                  href='#'
                                  onClick={() => {
                                    setConfigToUpdate(config)
                                  }}
                                >
                                  Edit
                                </a>
                              </td>
                              <td>
                                <a
                                  className='button'
                                  href='#'
                                  onClick={() => {
                                    setConfigToRemove(config)
                                  }}
                                >
                                  Remove
                                </a>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </article>
              </div>
            )
          })
        ))}
    </div>
  )
}

export default AdminReferralsConfig

import React, { useState } from 'react'
import clsx from 'clsx'
import AdminUserSearch from './AdminUserSearch'
import NonActiveUsers from './NonActiveUsers'
import NonConfiguredUsers from './NonConfiguredUsers'

const AdminUsersManagement = () => {
  const [tab, setTab] = useState(null)

  return (
    <div>
      <div>
        <div className='mt-5'>
          <div className='tabs'>
            <ul>
              <li
                className={clsx({
                  'is-active': tab === 'userSearch' || !tab
                })}
              >
                <a onClick={() => setTab('userSearch')}>User search</a>
              </li>
              <li
                className={clsx({
                  'is-active': tab === 'nonActiveUsers'
                })}
              >
                <a onClick={() => setTab('nonActiveUsers')}>Non active users</a>
              </li>
              <li
                className={clsx({
                  'is-active': tab === 'nonConfiguredUsers'
                })}
              >
                <a onClick={() => setTab('nonConfiguredUsers')}>
                  Non configured users
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div>
          {(!tab || tab == 'userSearch') && <AdminUserSearch></AdminUserSearch>}
          {tab == 'nonActiveUsers' && <NonActiveUsers></NonActiveUsers>}
          {tab == 'nonConfiguredUsers' && (
            <NonConfiguredUsers></NonConfiguredUsers>
          )}
        </div>
      </div>
    </div>
  )
}

export default AdminUsersManagement

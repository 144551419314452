if (typeof window !== 'undefined') {

/*
    bcViwerApi
    TBD: add details
*/
bcViewer.bcViewerApi = function(bcViewer) {
    this._bcViewer = bcViewer;
    this._apiReady = false;
    this._viewerId = bcGlobals.viewerId;

    this._init();
};

/*
    init the api
*/
bcViewer.bcViewerApi.prototype._init = function() {
    this._apiReady = true;

    var e = {
        type: 'viewerApiReady',
        viewerId: this._viewerId,
        api: this
    };

    $(document).trigger(e);
    if (this._shouldNotifyParent()) {
        window.parent.$(window.parent.document).trigger(e);
    }
};

/*
    trigger api events only when api is ready
*/
bcViewer.bcViewerApi.prototype._triggerWhenReady = function(eventType, data) {
    if (this._apiReady) {
        var e = {};
        e.type = eventType;
        e.viewerId = this._viewerId;
        $.extend(e, data);

        $(document).trigger(e);
        if (this._shouldNotifyParent()) {
            window.parent.$(window.parent.document).trigger(e);
        }
    } else {
        $(document).on("viewerApiReady", $.proxy(function() {
            this._triggerWhenReady(eventType, data);
        }, this));
    }
};

/*
    trigger eventsDataReady
*/
bcViewer.bcViewerApi.prototype.onEventsDataReady = function(eventsData) {
    this._triggerWhenReady('eventsDataReady', {
                                eventsData: eventsData
                            });
};

/*
    trigger summaryParametersReady
*/
bcViewer.bcViewerApi.prototype.onSummaryParametersReady = function(data) {
    this._triggerWhenReady('summaryParametersReady', {
                                summaryParameters: data
                            });
};


/*
    trigger getRealtimeDataError
*/
bcViewer.bcViewerApi.prototype.onGetRealtimeDataError = function() {
    this._triggerWhenReady('getRealtimeDataError', { });
};

/*
    trigger waitingForEcgDataChanged
*/
bcViewer.bcViewerApi.prototype.onWaitingForEcgDataChanged = function(isWaiting) {
    this._triggerWhenReady('waitingForEcgDataChanged', {
                                isWaiting: isWaiting
                            });
};

/*
    trigger ViewerReadyToPrint
*/
bcViewer.bcViewerApi.prototype.onViewerReadyToPrint = function() {
    this._triggerWhenReady('ViewerReadyToPrint');
    window.dispatchEvent(new Event('ViewerReadyToPrintVanilla'));
};

/*
    Return screenshot of the main ECG canvas
*/
bcViewer.bcViewerApi.prototype.getECGScreenshot = function() {
    var url = $('.bcv_main_canvas')[0].toDataURL(/*'image/jpeg', 0.3*/);
    return url;
};

/*
    Jump to the next visible window
*/
bcViewer.bcViewerApi.prototype.jumpToNextWindow = function() {
    return this._bcViewer.jumpToNextWindow();
};

/*
    Jump to time position
*/
bcViewer.bcViewerApi.prototype.jumpToTimePosition = function(timePosition) {
  this._bcViewer.jumpToTimePosition(timePosition);
}

/*
    return the number of channels
*/
bcViewer.bcViewerApi.prototype.getNumberOfChannels = function() {
    return this._bcViewer._metadata.NumberOfChannels;
};

/*
    Return viewer's state and some more parameters
*/
bcViewer.bcViewerApi.prototype.getState = function() {
    var state = $.extend({}, this._bcViewer._state);

    var timeFormat = bcGlobals.locale.timeFormat;
    var startTime = this._bcViewer._metadata.StartTime;
    var startOffset = this._bcViewer._viewers.main._view._visibleWindowTimeParams.startOffset;
    state.visibleStartTime = (startTime === 0) ?
                             this._bcViewer._utils.formatTimeDuration(startOffset, timeFormat) :
                             this._bcViewer._utils.formatTime(startTime + startOffset, timeFormat);

    var mainViewerView = this._bcViewer._viewers.main._view;
    state.zoom = mainViewerView._currentViewDetails.zoom;
    state.ampl = mainViewerView._currentViewDetails.ampl;

    state.currentViewString = $(mainViewerView._currentViewDetailsTemplate).tmpl(mainViewerView._currentViewDetails).text();
    return state;
};

/*
    Change baseline correction filter state
*/
bcViewer.bcViewerApi.prototype.changeBaselineCorrectionState = function(activate) {
    return this._bcViewer.onStateChangeRequest( {baselineCorrection: activate} );
};

/*
    Change smooth filter state
*/
bcViewer.bcViewerApi.prototype.changeSmoothState = function(activate) {
    return this._bcViewer.onStateChangeRequest( {smooth: activate} );
};

/*
    Convert mm to pixels
 */
bcViewer.bcViewerApi.prototype.mmToPixels = function(mm) {
    return mm * this._bcViewer._config.PIXEL_PER_MM;
};

/*
    Stop realtime mode
*/
bcViewer.bcViewerApi.prototype.stopRealtimeMode = function() {
    this._bcViewer._allViewerDo('stopRealtimeMode');
};

/*
    Handle window unload
*/
bcViewer.bcViewerApi.prototype.onWindowUnload = function() {
    this._bcViewer._allViewerDo('onWindowUnload');
};

/*
    Start realtime mode
*/
bcViewer.bcViewerApi.prototype.startRealtimeMode = function(realtimeModeType) {
    this._bcViewer.realtimeModeType = realtimeModeType;
    this._bcViewer._allViewerDo('initRealtimeMode');
};

/*
    trigger onRealtimeModeChanged when realtime mode has changed
*/
bcViewer.bcViewerApi.prototype.onRealtimeModeChanged = function(isRealtimeActive) {
    this._triggerWhenReady('onRealtimeModeChanged', {
                                isRealtimeActive: isRealtimeActive
                            });
};


/*
    trigger onRealtimeGotData when got realtime data
*/
bcViewer.bcViewerApi.prototype.onRealtimeGotData = function(rtData, rtSamplingRate, rtUnitsPerMV, electrodesPosition) {
    this._triggerWhenReady('onRealtimeGotData', {
                                rtData: rtData,
                                rtSamplingRate: rtSamplingRate,
                                rtUnitsPerMV: rtUnitsPerMV,
                                electrodesPosition: electrodesPosition
                            });
};

/*
    trigger onGotRecordData when the datafetcher got the record data (only impelmented in standaloneSataFetcher for now)
    Use when you want to init the viewer with differnet parameters but you dont want to download the data file again
    Pass the data along with the event data to the viewer on the next init
*/
bcViewer.bcViewerApi.prototype.onGotRecordData = function(data) {
  this._triggerWhenReady('onGotBCViewerRecordData', {
                              recordData: data
                          });
};

/*
    trigger onGotEventsData when the datafetcher got the evebts data (only impelmented in standaloneSataFetcher for now)
    Use when you want to init the viewer with differnet parameters but you dont want to download the events file again
    Pass the data along with the record data to the viewer on the next init
*/
bcViewer.bcViewerApi.prototype.onGotEventsData = function(data) {
  this._triggerWhenReady('onGotBCViewerEventsData', {
                              eventsData: data
                          });
};

/*
  Indicate if the api should fire the event on the parent element (in case the viewer is in an iframe)
  In the app the viewer is in an iframe and we want to fire events on the parent element
 */
bcViewer.bcViewerApi.prototype._shouldNotifyParent = function () {
    return window.frameElement;
};


}

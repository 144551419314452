import React, { useState } from 'react'
import Modal from '../Modal'
import { gql, useMutation } from '@apollo/client'
import clsx from 'clsx'
import { objectToFieldsConverter } from '../../../utils/utils'
import { GET_REFERRALS_CONFIGS } from '../queries'
import { useQuery } from '@apollo/client'
import Loader from '../Loader'

const UPDATE_USER_GROUP = gql`
  mutation UPDATE_USER_GROUP($input: UserUpdateInput!) {
    userUpdate(input: $input) {
      id
    }
  }
`

export default function UpdateUserGroup({ user, onExit, onComplete }) {
  const [inProgress, setInProgress] = useState(false)
  const [group, setGroup] = useState(user.group || '')

  const { loading: configsLoading, data: configsData } = useQuery(
    GET_REFERRALS_CONFIGS
  )

  const [updateUserGroup] = useMutation(UPDATE_USER_GROUP)

  async function submit() {
    setInProgress(true)
    await updateUserGroup({
      variables: {
        input: {
          id: user.id,
          updatedAt: new Date().toISOString(),
          group
        }
      },
      update: (cache) => {
        cache.modify({
          id: cache.identify(user),
          fields: objectToFieldsConverter({
            group
          })
        })
      }
    })
    setInProgress(false)
    onComplete()
  }

  return (
    <Modal onClose={onExit} onSubmit={submit} inProgress={inProgress}>
      <label className='label is-medium'>Set user group</label>
      <hr />
      {configsLoading ? (
        <Loader />
      ) : (
        <div>
          <div className='field is-horizontal'>
            <div className='field-label'>
              <label className='label has-text-left'>Group:</label>
            </div>
            <div className='field-body'>
              <div className='field'>
                <div className='control'>
                  <div className='select'>
                    <select
                      value={group}
                      onChange={(e) => setGroup(e.target.value)}
                    >
                      {user.group &&
                      !configsData.referralsConfigs.find(
                        ({ id: groupId }) => user.group === groupId
                      ) ? (
                        <option value={user.group} key={user.group} disabled>
                          {user.group} - not exist in DB - please select other
                          group
                        </option>
                      ) : null}
                      <option value=''>No group</option>
                      {configsData.referralsConfigs.map(({ id: groupId }) => (
                        <option value={groupId} key={groupId}>
                          {groupId}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='field'>
            <div className='level'>
              <div className='level-left'>
                <div className='level-item'></div>
              </div>
              <div className='level-right'>
                <div className='level-item'>
                  <button
                    className={clsx('button is-primary level-item', {
                      'is-loading': inProgress
                    })}
                    onClick={async () => submit()}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Modal>
  )
}

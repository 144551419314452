import React, { useState, useContext } from 'react'
import AuthContext from '../AuthContext'
import { useQuery } from '@apollo/client'
import { GET_NON_CONFIGURED_USERS } from '../queries'
import { DATE_FORMAT_CONVERTER } from '../../../utils/utils'
import dayjs from 'dayjs'
import useMemoryPagination, {
  prev,
  next
} from '../../hooks/useMemoryPagination'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import AdminConfigUser from './AdminConfigUser'

const FIRST = 20

const NonConfiguredUsers = () => {
  const currentUser = useContext(AuthContext)
  const [paginationState, dispatch] = useMemoryPagination()
  const [userToConfigure, setUserToConfigure] = useState(null)

  const { loading, error, data } = useQuery(GET_NON_CONFIGURED_USERS, {
    variables: { first: FIRST, after: paginationState }
  })

  return (
    <div className='mt-5'>
      {loading && <p>Loading...</p>}
      {error && <div> error... </div>}

      {userToConfigure && (
        <AdminConfigUser
          user={userToConfigure}
          onComplete={() => {
            setUserToConfigure(false)
            alert('please refresh to see the results')
          }}
          onExit={() => setUserToConfigure(false)}
        />
      )}

      {data && (
        <div>
          <table className='table is-fullwidth is-hoverable'>
            <thead>
              <tr>
                <th>Created at</th>
                <th>Email</th>
                <th>Type</th>
                <th>Cardiologist</th>
                <th>Configure</th>
              </tr>
            </thead>
            <tbody>
              {data.admin.nonConfiguredUsers.edges.map(({ node: user }) => (
                <tr key={user.id}>
                  <td>
                    {dayjs(user.createdAt).format(
                      `${
                        DATE_FORMAT_CONVERTER[currentUser.settings.dateFormat]
                      } HH:mm:ss`
                    )}
                  </td>
                  <td>{user.email}</td>
                  <td>{user.userType}</td>
                  <td>
                    {user.isCardiologist && <FontAwesomeIcon icon={faCheck} />}
                  </td>
                  <td>
                    <button
                      className='button'
                      onClick={() => {
                        setUserToConfigure(user)
                      }}
                    >
                      Configure
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <nav className='pagination' role='navigation' aria-label='pagination'>
            <a
              className='pagination-previous'
              disabled={!data.admin.nonConfiguredUsers.pageInfo.hasPreviousPage}
              onClick={() => {
                if (data.admin.nonConfiguredUsers.pageInfo.hasPreviousPage) {
                  dispatch(prev())
                }
              }}
            >
              Previous
            </a>
            <a
              className='pagination-next'
              disabled={!data.admin.nonConfiguredUsers.pageInfo.hasNextPage}
              onClick={() => {
                if (data.admin.nonConfiguredUsers.pageInfo.hasNextPage) {
                  dispatch(
                    next(data.admin.nonConfiguredUsers.pageInfo.endCursor)
                  )
                }
              }}
            >
              Next
            </a>
          </nav>
        </div>
      )}
    </div>
  )
}

export default NonConfiguredUsers

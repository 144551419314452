import React from 'react'
import Modal from '../Modal'
import CreateAccount from '../CreateAccount'
import { ANONYMOUS_USER_ID } from '../../../utils/utils'

const AdminConfigUser = ({ user, onComplete, onExit }) => {
  return (
    <Modal onClose={onExit}>
      <CreateAccount
        id={user.id}
        firstName={
          user.firstName === ANONYMOUS_USER_ID ? undefined : user.firstName
        }
        lastName={
          user.lastName === ANONYMOUS_USER_ID ? undefined : user.lastName
        }
        isAdminConfigure={true}
        onComplete={onComplete}
      />
    </Modal>
  )
}

export default AdminConfigUser

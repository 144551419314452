if (typeof window !== 'undefined') {

/*
    Calculate the predicted values.
    This is a static class
*/
var SpirometerPrediction = {
    predictors: {
        0: HankinsonPredictor,
        1: NHANESIIIPredictor,
        2: GLIPredictor
    },
    
    /*
        Create predictor by type
    */
    _createPredictor: function(predictorType) {
        var predictor;
        
        if (typeof this.predictors[predictorType] !== 'undefined') {
            return this.predictors[predictorType];
        }
        
        // default
        return HankinsonPredictor;
    },
    
    /*
        Get the predicted values
    */
    getPredictedValues: function(predictorType, testResults, patientDetails) {
        var predictor = this._createPredictor(predictorType);

         var prediction = {
            FVC: '',
            FEV1: '',
            PEF: '',
            ratio: ''     
        };
        
        if (predictor.canCalculatePrediction(patientDetails)) {
            var predictionValues = predictor.getPredictedValues(patientDetails);
    
            prediction.FVC = Number(parseFloat((testResults.FVC/predictionValues.FVC)*100).toFixed(2));
            prediction.FEV1 = Number(parseFloat((testResults.FEV1/predictionValues.FEV1)*100).toFixed(2));
            prediction.PEF = predictionValues.PEF ? Number(parseFloat((testResults.PEF/predictionValues.PEF)*100).toFixed(2)) : '';
            prediction.ratio = ((predictionValues.RATIO) || ((prediction.FVC !== 0) ? (prediction.FEV1 / prediction.FVC) : 0));
        }
        
        return prediction;    
    },
    
    /*
        Get gender key for calculations
    */
    getGenderKey: function(gender) {
        return (gender === spiroViewer._gender.MALE) ? 'male' : 'female';
    }
};

window.SpirometerPrediction = SpirometerPrediction;

}
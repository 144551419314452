import React, { useState } from 'react'
import Modal from './Modal'
import { gql, useMutation } from '@apollo/client'
import clsx from 'clsx'
import dayjs from 'dayjs'
import { useLocalization } from './LocalizationProvider'
import { isRTL, REFERRAL_TYPES_CONVERTER } from '../../utils/utils'

const REFERAL_CANCEL = gql`
  mutation REFERAL_CANCEL($input: StudyReferralCancelInput!) {
    studyReferralCancel(input: $input) {
      id
    }
  }
`

const ReferralCancel = ({
  referral,
  isCancelByTarget,
  onComplete,
  onExit,
  refetchQueries = [],
  onUpdate = () => {}
}) => {
  const [inProgress, setProgress] = useState(false)
  const { locale, interpolate } = useLocalization()
  const [message, setMessage] = useState('')

  const [referalCancel, { error }] = useMutation(REFERAL_CANCEL, {
    refetchQueries,
    awaitRefetchQueries: true,
    update: onUpdate
  })

  async function submit() {
    setProgress(true)
    try {
      await referalCancel({
        variables: {
          input: {
            id: referral.id,
            studyId: referral.studyId,
            updatedAt: new Date().toISOString(),
            message,
            sourceCaregiver: {
              id: referral.sourceCaregiver.id,
              name: referral.sourceCaregiver.name,
              email: referral.sourceCaregiver.email
            },
            targetCaregiver: referral.targetCaregiver
              ? {
                  id: referral.targetCaregiver.id,
                  name: referral.targetCaregiver.name,
                  email: referral.targetCaregiver.email
                }
              : undefined,
            targetQueue: referral.targetQueue
              ? {
                  id: referral.targetQueue.id,
                  name: referral.targetQueue.name
                }
              : undefined,
            patient: {
              id: referral.patient.id,
              name: referral.patient.name,
              owner: referral.patient.owner || undefined
            },
            referralType: referral.referralType,
            referralCreatedAt: referral.createdAt,
            referralStatus: referral.status
          }
        }
      })
      onComplete()
    } catch (e) {
      void 0
    } finally {
      setProgress(false)
    }
  }

  return (
    <Modal onClose={onExit} onSubmit={submit} inProgress={inProgress}>
      <div className='field'>
        <label className='label'>{locale.report_requests.cancel_order}</label>
        <p>
          {interpolate(
            locale.report_requests.cancel_order_by_target_description,
            {
              type: locale.studies.reports[
                REFERRAL_TYPES_CONVERTER[referral.referralType]
              ],
              name: isCancelByTarget
                ? referral.sourceCaregiver.name
                : (referral.targetQueue || referral.targetCaregiver).name,
              date:
                locale.date.abbr_month_names[
                  parseInt(dayjs(referral.createdAt).format('M'), 10) - 1
                ] +
                ' ' +
                dayjs(referral.createdAt).format('D, YYYY')
            }
          )}
        </p>
        {isCancelByTarget && (
          <div className='field mt-4'>
            <div className='control'>
              <textarea
                className='textarea'
                placeholder={locale.report_requests.enter_optional_message}
                value={message}
                style={{
                  direction: message && isRTL(message[0]) ? 'rtl' : 'ltr',
                  resize: 'none'
                }}
                onChange={(e) => setMessage(e.target.value)}
                readOnly={inProgress}
              />
            </div>
          </div>
        )}
        <div className='is-flex is-justify-content-end is-align-items-center mt-4'>
          {error && <p className='help is-danger'>{locale.error}</p>}
          <button
            className='button is-text mr-4'
            disabled={inProgress}
            onClick={onExit}
          >
            {locale.cancel}
          </button>
          <button
            className={clsx('button is-primary', {
              'is-loading': inProgress
            })}
            disabled={inProgress}
            onClick={submit}
          >
            {locale.ok}
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default ReferralCancel

if (typeof window !== 'undefined') {

/*
    bcSpiroViewerTestManager - Handle spirometer test
*/
var calibrationManager = function(spiroViewer, dataFetcher, pitchManager) {
    this._spiroViewer = spiroViewer;
    this._dataFetcher = dataFetcher;
    this._pitchManager = pitchManager;
    
    this._flows = [];
    this._calibrationStarted = false;
    this._calibrationDuration = 5000;
    
    this._view = new calibrationView(spiroViewer, this);
};

/*
    Start spiroemter calibration
*/
calibrationManager.prototype.start = function() {    
    this._dataFetcher.startSpirometer(this._pitchManager.shouldPitchFromServer());
    this._pitchManager.start($.proxy(this._onTimeout, this));
    this._dataFetcher.getSpirometerData($.proxy(this.onGotData, this));
};

/*
    Handle getting flow array from the server
*/
calibrationManager.prototype.onGotData = function(results) {
    if (!results.data) return;
   
    var sum=0;
    for (var i=0; i < results.data.length; i++) {
        var flow = results.data[i];
        sum += flow;
        if (this._calibrationStarted) {
            this._flows.push(flow);
        }
    }
    
    if (results.data.length != 0) {
        this._view.update(sum / results.data.length);
    }
    
    this._getDataTimeout = setTimeout($.proxy(function() {
        this._dataFetcher.getSpirometerData($.proxy(this.onGotData, this));
    }, this), 70);
};

calibrationManager.prototype.startCalibration = function() {
    this._flows = [];
    this._calibrationStarted = true;
    
    setTimeout($.proxy(this._onCalibrationEnded, this), this._calibrationDuration);
},

calibrationManager.prototype._onCalibrationEnded = function() {
    var sum = 0;
    var max = this._flows[0];
    var min = this._flows[0];
    for (var i=0;i<this._flows.length;i++) {
        if (this._flows[i] > max) {
            max = this._flows[i];
        }
        if (this._flows[i] < min) {
            min = this._flows[i];
        }
        sum += this._flows[i];
    }
    var avg = sum/this._flows.length;
    this._flows = [];
    
    this._view.onCalibrationEnded({
        avg: avg,
        max: max,
        min: min
    });
},

/*
    Handle window unload event
*/
calibrationManager.prototype.onWindowUnload = function() {
    this._dataFetcher.stopSpirometer();
};

calibrationManager.prototype._onTimeout = function() {
    this._dataFetcher.stopSpirometer();

    this._spiroViewer.onTestTimeout();
};

window.calibrationManager = calibrationManager;

}
import React, { useState } from 'react'
import Modal from '../Modal'
import { gql, useMutation } from '@apollo/client'
import clsx from 'clsx'
import { objectToFieldsConverter } from '../../../utils/utils'

const SERVICE_PLAN_UPDATE_BILLING_DATE = gql`
  mutation SERVICE_PLAN_UPDATE_BILLING_DATE(
    $input: ServicePlanUpdateBillingDateInput!
  ) {
    servicePlanUpdateBillingDate(input: $input) {
      id
    }
  }
`

const UpdateServicePlanBillingDate = ({ servicePlan, onComplete, onExit }) => {
  const [inProgress, setProgress] = useState(false)
  const [
    servicePlanBillingDateInputValue,
    setServicePlanBillingDateInputValue
  ] = useState(servicePlan.contract.nextBillingDate)

  const [servicePlanUpdateBillingDate] = useMutation(
    SERVICE_PLAN_UPDATE_BILLING_DATE
  )

  async function submit() {
    setProgress(true)
    await servicePlanUpdateBillingDate({
      variables: {
        input: {
          id: servicePlan.id,
          updatedAt: new Date().toISOString(),
          nextBillingDate: servicePlanBillingDateInputValue
        }
      },
      update: (cache) => {
        cache.modify({
          id: cache.identify(servicePlan),
          fields: objectToFieldsConverter({
            contract: {
              nextBillingDate: servicePlanBillingDateInputValue
            }
          })
        })
      }
    })
    setProgress(false)
    onComplete()
  }

  return (
    <Modal onClose={onExit} onSubmit={submit} inProgress={inProgress}>
      <div className='field'>
        <label className='label'>Next billing date</label>
        <div className='control'>
          <input
            className='input'
            type='date'
            value={servicePlanBillingDateInputValue}
            onChange={(e) =>
              setServicePlanBillingDateInputValue(e.target.value)
            }
            readOnly={inProgress}
          />
        </div>
        <div className='has-text-right'>
          <button
            className={clsx('button is-primary mt-4', {
              'is-loading': inProgress
            })}
            disabled={inProgress}
            onClick={submit}
          >
            OK
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default UpdateServicePlanBillingDate

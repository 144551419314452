import React, { useState } from 'react'
import { gql, useMutation } from '@apollo/client'
import clsx from 'clsx'
import { v4 as uuidv4 } from 'uuid'
import dayjs from 'dayjs'
import Modal from './Modal'
import { useLocalization } from './LocalizationProvider'
import { REFERRAL_TYPES_CONVERTER, isRTL } from '../../utils/utils'

const STUDY_REFERRAL_CREATE = gql`
  mutation STUDY_REFERRAL_CREATE(
    $studyId: ID!
    $patient: PatientConnectionInput!
    $sourceClinic: ClinicConnectionInput!
    $sourceCaregiver: CaregiverConnectionInput!
    $targetClinic: ClinicConnectionInput
    $targetCaregiver: CaregiverConnectionInput
    $targetQueue: ConnectionInput
    $input: StudyReferralCreateInput!
    $referralId: ID!
    $getCheckoutPage: Boolean!
    $shouldReferralPay: Boolean!
    $paymentTokenId: ID!
    $price: Float!
    $targetId: ID!
  ) {
    studyReferralCreate(
      studyId: $studyId
      patient: $patient
      sourceClinic: $sourceClinic
      sourceCaregiver: $sourceCaregiver
      targetClinic: $targetClinic
      targetCaregiver: $targetCaregiver
      targetQueue: $targetQueue
      input: $input
      isPayingWithToken: $shouldReferralPay
    ) {
      id
    }
    studyReferralPay(
      referralId: $referralId
      paymentTokenId: $paymentTokenId
      studyId: $studyId
      targetId: $targetId
      price: $price
    ) @include(if: $shouldReferralPay) {
      id
    }
    query {
      referral(id: $referralId) @include(if: $getCheckoutPage) {
        checkoutPage
      }
    }
  }
`

const STUDY_REFERRAL_PAY = gql`
  mutation STUDY_REFERRAL_PAY(
    $referralId: ID!
    $getCheckoutPage: Boolean!
    $paymentTokenId: ID!
    $studyId: ID!
    $targetId: ID!
    $price: Float!
  ) {
    studyReferralPay(
      referralId: $referralId
      paymentTokenId: $paymentTokenId
      studyId: $studyId
      targetId: $targetId
      price: $price
    ) @skip(if: $getCheckoutPage) {
      id
    }
    query {
      referral(id: $referralId) @include(if: $getCheckoutPage) {
        checkoutPage
      }
    }
  }
`

const DiagnosisReferralForm = ({
  user,
  clinic,
  patient,
  studyId,
  study,
  diagnosticReferralCaregivers,
  selectedCaregiverId,
  selectedReferralId,
  selectedReferralType,
  isPaymentRequest,
  onClose
}) => {
  const { locale, interpolate } = useLocalization()
  const [isUnavailableConfirmed, setUnavailableConfirmed] = useState(false)
  const [selectedDoctor, setSelectedDoctor] = useState(
    selectedCaregiverId && selectedReferralType
      ? diagnosticReferralCaregivers.reduce(
          (selected, current) =>
            selected ||
            (current.caregiver.id === selectedCaregiverId &&
            current.referralType === selectedReferralType
              ? current
              : null),
          null
        ) || null
      : null
  )
  const [selectedPaymentMethod, setPaymentMethod] = useState('new')
  const [message, setMessage] = useState('')
  const [studyReferralCreate, { loading }] = useMutation(STUDY_REFERRAL_CREATE)
  const [studyReferralPay, { loading: loadingPay }] =
    useMutation(STUDY_REFERRAL_PAY)

  return (
    <Modal onClose={onClose}>
      <div className='level'>
        <div className='level-left'>
          <div className='level-item has-text-weight-bold'>
            {locale.report_requests.order_from}
          </div>
          <div className='level-item'>
            <div className='select'>
              <select
                value={selectedDoctor ? selectedDoctor.caregiver.id : '-1'}
                onChange={(e) =>
                  setSelectedDoctor(
                    diagnosticReferralCaregivers.reduce(
                      (selected, current) =>
                        selected ||
                        (current.caregiver.id === e.target.value
                          ? current
                          : null),
                      null
                    )
                  )
                }
                disabled={isPaymentRequest || loading}
              >
                <option disabled='disabled' value='-1'>
                  {locale.report_requests.select_doctor}
                </option>
                {diagnosticReferralCaregivers
                  .reduce(
                    (distinctRequests, current) =>
                      distinctRequests.some(
                        (e) => e.caregiver.id === current.caregiver.id
                      )
                        ? distinctRequests
                        : [...distinctRequests, current],
                    []
                  )
                  .map((config) => {
                    const min = Math.min(
                      ...diagnosticReferralCaregivers
                        .filter((d) => d.caregiver.id === config.caregiver.id)
                        .map((d) => parseFloat(d.price))
                    )
                    const max = Math.max(
                      ...diagnosticReferralCaregivers
                        .filter((d) => d.caregiver.id === config.caregiver.id)
                        .map((d) => parseFloat(d.price))
                    )
                    const opt =
                      max != 0
                        ? min !== max
                          ? interpolate(
                              locale.report_requests
                                .doctor_name_and_price_range,
                              {
                                name: config.caregiver.name,
                                price_low: min,
                                price_high: max,
                                currency: config.currency
                              }
                            )
                          : interpolate(
                              locale.report_requests.doctor_name_and_price,
                              {
                                name: config.caregiver.name,
                                price: max,
                                currency: config.currency
                              }
                            )
                        : config.caregiver.name
                    return (
                      <option
                        value={config.caregiver.id}
                        key={config.caregiver.id}
                      >
                        {opt}
                      </option>
                    )
                  })}
              </select>
            </div>
          </div>
        </div>
      </div>
      {selectedDoctor &&
        diagnosticReferralCaregivers
          .filter(
            (current) =>
              current.caregiver.id === selectedDoctor.caregiver.id &&
              (!isPaymentRequest ||
                selectedReferralType === current.referralType)
          )
          .map((current) => (
            <div key={current.referralType} className='field'>
              <div className='control'>
                <label className='radio'>
                  <input
                    type='radio'
                    name='answer'
                    checked={
                      selectedDoctor.referralType === current.referralType
                    }
                    onChange={() => setSelectedDoctor(current)}
                    readOnly={
                      selectedDoctor.referralType === current.referralType
                    }
                  />{' '}
                  {parseFloat(current.price) !== 0
                    ? `${
                        locale.studies.reports[
                          REFERRAL_TYPES_CONVERTER[current.referralType]
                        ]
                      } - ${current.price} ${current.currency}`
                    : `${
                        locale.studies.reports[
                          REFERRAL_TYPES_CONVERTER[current.referralType]
                        ]
                      }`}
                </label>
              </div>
            </div>
          ))}
      {(clinic.paymentToken &&
        selectedDoctor &&
        parseFloat(selectedDoctor.price) !== 0 && (
          <>
            <div className='level'>
              <div className='level-left'>
                <div className='level-item has-text-weight-bold'>
                  {locale.report_requests.paying_method}
                </div>
              </div>
            </div>
            <div className='field' key={clinic.paymentToken.id}>
              <div className='control'>
                <label className='radio'>
                  <input
                    type='radio'
                    name='paymentToken'
                    checked={selectedPaymentMethod === clinic.paymentToken.id}
                    onChange={() => setPaymentMethod(clinic.paymentToken.id)}
                  />{' '}
                  {`${clinic.paymentToken.creditCard.name} - ${clinic.paymentToken.creditCard.type} ${clinic.paymentToken.creditCard.number} ${clinic.paymentToken.creditCard.month}/${clinic.paymentToken.creditCard.year}`}
                </label>
              </div>
            </div>
            <div className='field'>
              <div className='control'>
                <label className='radio'>
                  <input
                    type='radio'
                    name='paymentToken'
                    checked={selectedPaymentMethod === 'new'}
                    onChange={() => setPaymentMethod('new')}
                  />{' '}
                  {locale.report_requests.payment_option_credit_card}
                </label>
              </div>
            </div>
          </>
        )) ||
        null}
      {!isPaymentRequest && (
        <div className='field'>
          <div className='control'>
            <textarea
              className='textarea'
              placeholder={locale.report_requests.enter_optional_message}
              value={message}
              style={{
                direction: message && isRTL(message[0]) ? 'rtl' : 'ltr'
              }}
              onChange={(e) => setMessage(e.target.value)}
              readOnly={loading}
            />
          </div>
        </div>
      )}
      <div className='is-flex is-align-items-center'>
        {isPaymentRequest && selectedReferralId ? (
          <button
            className={clsx('button is-primary', {
              'is-loading': loadingPay
            })}
            disabled={
              !selectedDoctor ||
              (selectedDoctor.unavailableUntil && !isUnavailableConfirmed) ||
              loadingPay
            }
            onClick={async () => {
              const result = await studyReferralPay({
                variables: {
                  referralId: selectedReferralId,
                  getCheckoutPage:
                    selectedPaymentMethod === 'new' &&
                    parseFloat(selectedDoctor.price) !== 0,
                  paymentTokenId: selectedPaymentMethod,
                  studyId: study.id,
                  targetId: selectedDoctor.caregiver.id,
                  price: selectedDoctor.price
                },
                update(cache) {
                  cache.modify({
                    id: cache.identify(study),
                    fields: {
                      referrals: (existingReferrals = []) => {
                        let existingReferralsCopy = JSON.parse(
                          JSON.stringify(existingReferrals)
                        )
                        let ind = existingReferrals.findIndex(
                          (e) => e.id === selectedReferralId
                        )
                        existingReferralsCopy[ind].status = 'Pending'
                        existingReferralsCopy[
                          ind
                        ].authorizedActionsWrapper.authorizedActions.allow.push(
                          'Referral:invoicePage'
                        )
                        existingReferralsCopy[
                          ind
                        ].authorizedActionsWrapper.authorizedActions.deny = existingReferralsCopy[
                          ind
                        ].authorizedActionsWrapper.authorizedActions.deny.filter(
                          (v) => v !== 'Referral:invoicePage'
                        )
                        return existingReferralsCopy
                      }
                    }
                  })
                  cache.modify({
                    id: cache.identify(clinic),
                    fields: {
                      sourceReferrals: ({ edges, ...rest }) => {
                        let edgesCopy = JSON.parse(JSON.stringify(edges))
                        let ind = edgesCopy.findIndex(
                          ({ node }) => node.id === selectedReferralId
                        )
                        edgesCopy[ind].node.status = 'Pending'
                        return {
                          edges: edgesCopy,
                          ...rest
                        }
                      }
                    }
                  })
                }
              })
              if (
                selectedPaymentMethod === 'new' &&
                parseFloat(selectedDoctor.price) !== 0
              ) {
                window.location = result.data.query.referral.checkoutPage
              } else if (result.data.studyReferralPay.id) {
                onClose({ isSuccess: true })
              } else {
                onClose({ isError: true })
              }
            }}
          >
            {locale.report_requests.order}
          </button>
        ) : (
          <button
            className={clsx('button is-primary', {
              'is-loading': loading
            })}
            disabled={
              !selectedDoctor ||
              (selectedDoctor.unavailableUntil && !isUnavailableConfirmed) ||
              loading
            }
            onClick={async () => {
              const referralId = uuidv4()
              const createdAt = new Date().toISOString()
              const result = await studyReferralCreate({
                variables: {
                  studyId,
                  patient: {
                    id: patient.id,
                    name: patient.name,
                    owner: patient.owner,
                    externalId: patient.externalId
                  },
                  sourceClinic: {
                    id: study.clinic.id,
                    name: study.clinic.name
                  },
                  sourceCaregiver: {
                    id: user.id,
                    name: user.name,
                    email: user.email
                  },
                  targetClinic: !selectedDoctor.isQueue
                    ? {
                        id: selectedDoctor.clinic.id,
                        name: selectedDoctor.clinic.name
                      }
                    : undefined,
                  targetCaregiver: !selectedDoctor.isQueue
                    ? {
                        id: selectedDoctor.caregiver.id,
                        name: selectedDoctor.caregiver.name,
                        email: selectedDoctor.caregiver.email
                      }
                    : undefined,
                  targetQueue: selectedDoctor.isQueue
                    ? {
                        id: selectedDoctor.caregiver.id,
                        name: selectedDoctor.caregiver.name
                      }
                    : undefined,
                  input: {
                    id: referralId,
                    createdAt,
                    referralType: selectedDoctor.referralType,
                    message,
                    price: selectedDoctor.price,
                    currency: selectedDoctor.currency
                  },
                  referralId,
                  getCheckoutPage:
                    selectedPaymentMethod === 'new' &&
                    parseFloat(selectedDoctor.price) !== 0,
                  shouldReferralPay:
                    selectedPaymentMethod !== 'new' &&
                    parseFloat(selectedDoctor.price) !== 0,
                  paymentTokenId: selectedPaymentMethod,
                  targetId: selectedDoctor.caregiver.id,
                  price: selectedDoctor.price
                },
                update(cache) {
                  const status =
                    selectedPaymentMethod === 'new' &&
                    parseFloat(selectedDoctor.price) !== 0
                      ? 'PaymentPending'
                      : 'Pending'
                  cache.modify({
                    id: cache.identify(study),
                    fields: {
                      referrals: (existingReferrals = []) => {
                        let existingReferralsCopy = JSON.parse(
                          JSON.stringify(existingReferrals)
                        )
                        existingReferralsCopy.push({
                          id: referralId,
                          studyId: study.id,
                          createdAt,
                          message,
                          status,
                          referralType: selectedDoctor.referralType,
                          price: selectedDoctor.price,
                          targetCaregiver: !selectedDoctor.isQueue
                            ? {
                                id: selectedDoctor.caregiver.id,
                                name: selectedDoctor.caregiver.name,
                                email: selectedDoctor.caregiver.email,
                                __typename: 'CaregiverConnectionLink'
                              }
                            : null,
                          patient: {
                            id: patient.id,
                            name: patient.name,
                            owner: patient.owner || null,
                            __typename: 'PatientConnectionLink'
                          },
                          sourceCaregiver: {
                            id: user.id,
                            name: user.name,
                            email: user.email,
                            __typename: 'CaregiverConnectionLink'
                          },
                          targetQueue: selectedDoctor.isQueue
                            ? {
                                id: selectedDoctor.caregiver.id,
                                name: selectedDoctor.caregiver.name,
                                __typename: 'QueueConnectionLink'
                              }
                            : null,
                          authorizedActionsWrapper: {
                            authorizedActions: {
                              allow: ['Mutation:studyReferralCancel'].concat(
                                status !== 'PaymentPending' &&
                                  selectedDoctor.price > 0
                                  ? ['Referral:invoicePage']
                                  : []
                              ),
                              deny: [],
                              __typename: 'AuthorizedActions'
                            },
                            __typename: 'AuthorizedActionsWrapper'
                          },
                          __typename: 'ReferralData'
                        })
                        return existingReferralsCopy
                      }
                    }
                  })
                  cache.modify({
                    id: cache.identify(clinic),
                    fields: {
                      sourceReferrals: ({ edges, ...rest }) => {
                        let edgesCopy = JSON.parse(JSON.stringify(edges))
                        edgesCopy.unshift({
                          node: {
                            id: referralId,
                            studyId: study.id,
                            reportId: null,
                            createdAt,
                            patient: {
                              id: patient.id,
                              name: patient.name,
                              owner: patient.owner || null,
                              __typename: 'PatientConnectionLink'
                            },
                            sourceCaregiver: {
                              id: user.id,
                              name: user.name,
                              email: user.email,
                              __typename: 'CaregiverConnectionLink'
                            },
                            targetCaregiver: !selectedDoctor.isQueue
                              ? {
                                  id: selectedDoctor.caregiver.id,
                                  name: selectedDoctor.caregiver.name,
                                  email: selectedDoctor.caregiver.email,
                                  __typename: 'CaregiverConnectionLink'
                                }
                              : null,
                            targetQueue: selectedDoctor.isQueue
                              ? {
                                  id: selectedDoctor.caregiver.id,
                                  name: selectedDoctor.caregiver.name,
                                  __typename: 'QueueConnectionLink'
                                }
                              : null,
                            status,
                            referralType: selectedDoctor.referralType,
                            price: selectedDoctor.price,
                            __typename: 'ReferralData'
                          },
                          cursor: 'tempcurson',
                          __typename: 'ReferralEdge'
                        })
                        return {
                          edges: edgesCopy,
                          ...rest
                        }
                      }
                    }
                  })
                }
              })
              if (
                selectedPaymentMethod === 'new' &&
                parseFloat(selectedDoctor.price) !== 0
              ) {
                window.location = result.data.query.referral.checkoutPage
              } else if (
                parseFloat(selectedDoctor.price) === 0 &&
                result.data.studyReferralCreate.id
              ) {
                onClose({ isSuccess: true })
              } else if (
                parseFloat(selectedDoctor.price) !== 0 &&
                result.data.studyReferralPay.id
              ) {
                onClose({ isSuccess: true })
              } else {
                onClose({ isError: true })
              }
            }}
          >
            {locale.report_requests.order}
          </button>
        )}
        {selectedDoctor && selectedDoctor.unavailableUntil && (
          <div className='field has-text-weight-bold ml-4'>
            <div className='control'>
              <label className='checkbox'>
                <input
                  type='checkbox'
                  checked={isUnavailableConfirmed}
                  onChange={() =>
                    setUnavailableConfirmed(!isUnavailableConfirmed)
                  }
                />{' '}
                {interpolate(locale.report_requests.unavailable_until, {
                  name: selectedDoctor.caregiver.name,
                  date: dayjs(
                    selectedDoctor.unavailableUntil,
                    'YYYY-MM-DD'
                  ).format(user.dateFormat)
                })}
              </label>
            </div>
          </div>
        )}
      </div>
    </Modal>
  )
}

export default DiagnosisReferralForm

import React, { useContext, useState /*, useRef */ } from 'react'
// import clsx from 'clsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye } from '@fortawesome/free-regular-svg-icons'
import AuthContext from './AuthContext'
import { useAdmin } from './AdminProvider'
import AdminUserSearchInput from './Admin/AdminUserSearchInput'
// import Notifications from './Notifications'
import SignOut from './Auth/SignOut'
import Modal from './Modal'

const LayoutActions = ({ children }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { name } = useContext(AuthContext)
  const { currentAdmin, viewAsUser, setViewAsUser } = useAdmin()
  // const [isNotificationsOpen, toggleNotifications] = useState(false)
  // const node = useRef()
  return (
    <>
      {isModalOpen && (
        <Modal onClose={() => setIsModalOpen(false)}>
          <div style={{ height: 'calc(100vh - 128px)' }}>
            <div className='field'>
              <label className='label'>User search</label>
              <AdminUserSearchInput
                onSelect={(user) => {
                  setViewAsUser(user)
                  setIsModalOpen(false)
                }}
              />
            </div>
          </div>
        </Modal>
      )}
      {/* <div
        className={clsx('navbar-item', 'has-dropdown', {
          'is-active': isNotificationsOpen
        })}
      >
        <a
          className='navbar-link is-arrowless'
          onClick={() => toggleNotifications(!isNotificationsOpen)}
        >
          <span className='icon is-medium'>
            <span className='fa-layers fa-fw fa-2x'>
              <FontAwesomeIcon icon={faBell} />
            </span>
          </span>
        </a>
        <div className='navbar-dropdown is-right' ref={node}>
          {isNotificationsOpen && (
            <Notifications
              node={node}
              onClose={() => toggleNotifications(!isNotificationsOpen)}
            />
          )}
        </div>
      </div> */}
      {viewAsUser && (
        <div className='navbar-item'>
          <span className='tag is-warning is-medium'>
            Admin as {viewAsUser.name} ({viewAsUser.email})
            <button
              className='delete ml-2'
              onClick={() => location.reload()}
            ></button>
          </span>
        </div>
      )}
      <div className='navbar-item has-dropdown is-hoverable'>
        <a className='navbar-link'>{name}</a>
        <div className='navbar-dropdown is-right'>
          {children}
          {currentAdmin && !viewAsUser && <hr className='navbar-divider' />}
          {currentAdmin && !viewAsUser && (
            <a
              className='navbar-item'
              onClick={() => {
                setIsModalOpen(true)
              }}
            >
              <span className='icon'>
                <FontAwesomeIcon icon={faEye} />
              </span>
              <span>View as a user</span>
            </a>
          )}
          <hr className='navbar-divider' />
          <SignOut />
        </div>
      </div>
    </>
  )
}

export default LayoutActions
